import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class AbsentURLsService{

  private readonly unmarkedBaseApi = '/api/attendances';
  private readonly user = '/user';
  private readonly calendar = '/user/status';
  private readonly userId_param = 'userId';
  private readonly attendanceStatus='/attendanceStatus'
  private readonly attendancestatus_param = 'attendanceStatus';

  constructor(
    
  ) { }

  getBaseUrl(){
    return this.unmarkedBaseApi;
  }
  getAttendanceStatus(attendanceStatus){
    return this.unmarkedBaseApi + this.calendar + '/' + attendanceStatus;
  } 
   getByFieldUrl(field)
  {
     return this.unmarkedBaseApi + this.getByField(field);
  }
 private getByField(field){
  return '/'+field;
  }
}
