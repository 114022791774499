import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserUrlService {
  
 
  private readonly userBaseApi = '/api/users';
  private readonly search = '/search'
  private readonly authorities = '/authorities';
  private readonly userName = '/userName';
  private readonly empsort = 'empsort';
  private readonly directReportees = '/careerManager/directReportees';
  private readonly userBaseApi1 = '/api/users/getDropDownUsers';

  private readonly activated='/activated';
  private readonly activatedParamFormStatus = '/v';
  private readonly validateEmail = '/validateEmail';
  private readonly validateEmployeeId = '/validateEmployeeId';
  private readonly allUsersDetails='/employee-export';

  constructor(private restApi:RestAPIService) { 
    
  }

  getValidateEmailUrl(email) {
    return this.userBaseApi + this.validateEmail + this.getByField(email);
  }

  getValidateEmployeeIdUrl(employeeId) {
    return this.userBaseApi + this.validateEmployeeId + this.getByField(employeeId)
  }

  getBaseUrl(){
    return this.userBaseApi;
  }

  getUserNameUrl(id){
    return this.userBaseApi + this.userName + this.getByField(id)
  }

  getPaginationUrl(pageCount,pageSize,sort){
    pageCount-=1;
    return this.userBaseApi+'?'+this.empsort+'='+sort+'&'+'page='+pageCount+'&size='+pageSize;
  }

  getAuthorityUrl(){
    return this.userBaseApi+this.getByField(this.authorities);
  }

  //This will be used for get by id and delete by id
  getByFieldUrl(field){
    return this.userBaseApi+ this.getByField(field)
  }
  getConfirmationDate(id){
    return this.userBaseApi+'/confirmationDate/'+ id
  }
  getBaseUrlForDropDown(){
    return this.userBaseApi1;
  }

  getDirectReportees(careerManagerId){
    return this.userBaseApi+this.directReportees+this.getByField(careerManagerId)
  }

  getUserByActivatedStatusUrl(activated:boolean){
    return this.userBaseApi+this.activated+'?'+this.activatedParamFormStatus+'='+activated
  }

  getByField(field){
    return '/'+field;
  }

  getSearchUrl(searchText){
    return this.getBaseUrl()+this.search+this.getByField(searchText)
  }

  getDeleteUrl(id) {
   return this.getBaseUrl()+this.getByField(id)
  }

  getAllUsersDetails(): any {
    return this.getBaseUrl()+this.allUsersDetails;
  }

  
}
