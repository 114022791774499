import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubGoalDTO } from 'src/app/dtos/SubGoalDTO';
import { SubgoalService } from 'src/app/services/subgoal/subgoal.service';
import { ToastrService } from 'ngx-toastr';
import { GoalObjectivesService } from 'src/app/services/goal-objectives/goal-objectives.service';
@Component({
  selector: 'app-assign-sub-goal',
  templateUrl: './assign-sub-goal.component.html',
  styleUrls: ['./assign-sub-goal.component.scss']
})
export class AssignSubGoalComponent implements OnInit {
  AssignSubGoal: FormGroup;
  subGoalDto = new SubGoalDTO();
  descriptor: any;
  successCriteria: any;
  blueCare: any;
  subGoalObjective: any = GoalObjectivesService.getGoalObjectives();
  constructor(public fb: FormBuilder,
    public subgoalservice: SubgoalService,
    public dialogRef: MatDialogRef<any>,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public subGoal: any
  ) {
    this.AssignSubGoal = this.fb.group({
      subGoalName: ['', [Validators.required]],
      currentValue: ['', [Validators.required, Validators.pattern('^[1-9]?[0-9]{1}$|^100$')]],
      description: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.setformdata()
    for (let i = 0; i < this.subGoalObjective.length; i++) {
      if (this.subGoal[0].goalType == this.subGoalObjective[i].name) {
        this.descriptor = this.subGoalObjective[i].descriptor;
        this.successCriteria = this.subGoalObjective[i].successCriteriaAndMeasurement;
        this.blueCare = this.subGoalObjective[i].blueCareValues;
      }
    }

}
  setformdata() {
    if (this.subGoal[1].id) {

      this.AssignSubGoal.get('subGoalName').setValue(this.subGoal[1].subgoalName ? this.subGoal[1].subgoalName : '');
      this.AssignSubGoal.get('currentValue').setValue(this.subGoal[1].currentValue ? this.subGoal[1].currentValue : 0);
      this.AssignSubGoal.get('description').setValue(this.subGoal[1].description ? this.subGoal[1].description : '');
    }
  }
  closeDialog() {
    this.dialogRef.close()
  }
  onSubmit() {
    if (this.AssignSubGoal.valid) {
      this.subGoalDto.subgoalName = this.AssignSubGoal.get('subGoalName').value;
      this.subGoalDto.currentValue = this.AssignSubGoal.get('currentValue').value;
      this.subGoalDto.description = this.AssignSubGoal.get('description').value;
      this.subGoalDto.userGoalId = this.subGoal[0].id;
      if (this.subGoal[1]) {
        if (this.subGoal[1].id) this.subGoalDto.id = this.subGoal[1].id
        this.subgoalservice.update(this.subGoalDto).subscribe(res => {
          this.subgoalservice.filter("registered click");
          this.toast.success('SubGoal Updated Success', 'Success');
          this.dialogRef.close()
        })
      }
      else {
        this.subgoalservice.create(this.subGoalDto).subscribe(res => {
          this.toast.success('New SubGoal Added', 'Success');
          this.subgoalservice.filter("registered click");
          this.dialogRef.close()
        })
      }
    }
  }
  checkError = (controlName: string, errorName: string) => {
    return this.AssignSubGoal.controls[controlName].hasError(errorName);
  }

}
