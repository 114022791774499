import { Component, Input, OnChanges, OnInit} from '@angular/core';
import { fadeInItems } from '@angular/material/menu';
import { ToastrService } from 'ngx-toastr';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';

@Component({
  selector: 'app-dynamic-form-view',
  templateUrl: './dynamic-form-view.component.html',
  styleUrls: ['./dynamic-form-view.component.scss'],
  
  
})
export class DynamicFormViewComponent implements OnInit,OnChanges{
  @Input() public parentData;
  @Input() public isDisable;
  @Input() public disableEdit;
  
  constructor(public formSchemaService:FormsSchemaService,
    public toastr: ToastrService,){
  }
  ngOnChanges() {
   
  }
  ngOnInit() {
  
  }
  copyDescriptionToClipboard(item) {
    const tempInputElement = document.createElement('textarea');
    tempInputElement.value = item;
    document.body.appendChild(tempInputElement);

    tempInputElement.select();
    tempInputElement.setSelectionRange(0, 99999); // For mobile devices

    document.execCommand('copy');

    document.body.removeChild(tempInputElement);
    this.toastr.success('Copied successfully!', '', {
    });

  }

  getAnswer(item){
    let answer = "";
    if(item.type == 'radio'){
      item.values.forEach((v)=>{
        if(v.value == item.value){
          answer = v.label;
        }
      })
    }
    return answer;
  }


}
