import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-profiles',
  templateUrl: './edit-profiles.component.html',
  styleUrls: ['./edit-profiles.component.scss']
})
export class EditProfilesComponent implements OnInit {
  matTabsClickedIndex = 0
  constructor(public dialogRef: MatDialogRef<EditProfilesComponent>,
    ) { }

  ngOnInit(): void {
  }
  closeDialog() {
    this.dialogRef.close()
  }
  onTabChanged($event) {
    this.matTabsClickedIndex = $event.index;
  }
}
