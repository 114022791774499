import { User } from "../models/user";
import { AccountTypeDTO  } from "./AccountTypeDTO";


export class BankDetailsDTO {
    public accountNumer: String;
    public bankName: String;
    public ifsc: String;
    public accountType: String;
    public userId: User;
    public id: string;
    public docBase :string
    constructor() {
        this.accountNumer = ""
        this.bankName = ""
        this.ifsc = ""
        this.docBase=""
    }
}