import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseType } from '@microsoft/microsoft-graph-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestAPIService {
  baseUrl = environment.BASE_API_URL_BACKEND;

  constructor(private http: HttpClient) { }

  public get(apiURL): Observable<any> {
    return this.http.get<any>(this.baseUrl + apiURL, { observe: 'response' });
  }

  // public depulicateGet(apiURL): Observable<any> {
  //   return this.http.request<any>('GET',this.baseUrl + apiURL,  { observe: 'body', responseType: "text"})
  // }

  // public jsonServerGet(apiURL): Observable<any> {
  //   return this.http.get<any>('http://localhost:3000' + apiURL, {
  //     observe: 'response',
  //   });
  // }

  public post(apiURL, payload): Observable<any> {
    // console.log(apiURL, payload);
    return this.http.post<any>(this.baseUrl + apiURL, payload, {
      observe: 'response',
    });
  }

  public put(apiURL, payload): Observable<any> {
    return this.http.put<any>(this.baseUrl + apiURL, payload, {
      observe: 'response',
    });
  }

  public delete(apiURL): Observable<any> {
    return this.http.delete<any>(this.baseUrl + apiURL, {
      observe: 'response',
    });
  }
}
