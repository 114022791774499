<div class="container-fluid mobile">
  <!-- <div class="row mt-4">
    <div class="col-md-12">
      <app-notificationsCarousel></app-notificationsCarousel>
    </div>
  </div> -->

  <div class="row mt-3 mb-3">
    <div class="col-lg-8 col-md-6 col-sm-12">
      <div class="my_task_div">
        <app-my-task></app-my-task>
      </div>
    </div>
    <div class="col-lg-4 col-md-6">
      <div class="upcoming-events">
        <app-upcoming-events></app-upcoming-events>
      </div>
    </div>
    <div class="col-lg-8 col-md-12 first_main_row">
      <div class="row">
        <div class="col-md-6">
          <app-leave-summary></app-leave-summary>
        </div>
        <div class="col-md-6">
          <app-upcoming-holidays></app-upcoming-holidays>
        </div>
        <!-- <div class="col-md-12 mt-3">
          <app-my-projects  [projects]="projectList"></app-my-projects>
        </div> -->
        <div class="col-md-12 mt-3">
          <app-my-projects></app-my-projects>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-12 first_main_row">
      <app-my-team></app-my-team>
    </div>
  </div>
  <!--Temporary Asset Management Access Button-->
  <!-- <div class="row mt-3 mb-3">
    <div class="col-lg-4 col-md-6">
      <div class="upcoming-events">
        <app-asset-management-card></app-asset-management-card>
      </div>
    </div>
    <div class="col-lg-4 col-md-6">
      <div class="upcoming-events">
        <app-employee-import></app-employee-import>
      </div>
    </div> -->
  </div>