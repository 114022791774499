import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { RedirectService } from 'src/app/services/redirect.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import * as taskActions from '../../store/actions/task.action';
import * as fromTask from '../../store/reducers/task.reducer';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  pendingTasks$ = this.store.pipe(select(fromTask.selectPendingTasks));
  dueTasks$ = this.store.pipe(select(fromTask.selectDueTasks));
  completedTasks$ = this.store.pipe(select(fromTask.selectCompletedTasks));
  inprogressTasks$ = this.store.pipe(select(fromTask.selectInprogressTasks));  
  pendingTasksCount$ = this.store.pipe(select(fromTask.selectPendingTasksCount));
  dueTasksCount$ = this.store.pipe(select(fromTask.selectDueTasksCount));
  completedTasksCount$ = this.store.pipe(select(fromTask.selectCompletedTasksCount));
  inprogressTasksCount$ = this.store.pipe(select(fromTask.selectInprogressTasksCount));
  taskState$ = this.store.pipe(select(fromTask.selectTask))
  index: any;
  constructor(private store: Store<any>,private add:RedirectService, private feedbackService: FeedbackService) {
  this.add.shareDataSubject.subscribe(result=>{
    this.index=result;
  })
  }
  ngOnInit(): void {
    // this.subgoalservice.listen().subscribe((m:any)=>
    // {
    //   console.log(m);
    //   this.ngOnInit();
    // });
    // console.log(this.pendingTasksCount$)
   
    this.store.dispatch(taskActions.loadTasks());
    
     this.feedbackService.listen().subscribe((m:any)=>{
        this.ngOnInit()
    })
  }
}
