<div class="main_header_div_lConfig">
    <div class="child_Config">
        <h1 class="header_name ">Leave Configuration</h1>
    </div>
    <div>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>
    </div>
</div>

<!-- <hr> -->
<form [formGroup]="newLeaveConfiguration">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Year<span class="contentRed"></span></mat-label>
                    <mat-select formControlName="year" [(ngModel)]="selectedYear" >
                        <mat-option *ngFor="let year of years" [value]="year">
                            {{year}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Leave Type<span class="contentRed"></span></mat-label>
                    <mat-select formControlName="leaveType" [(value)]="selectedLeaveType">
                        <mat-option *ngFor="let leaveType of this.leavetypes" [value]="leaveType">
                            {{leaveType | underScoreToSpace}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Days<span class="contentRed"></span></mat-label>
                <input type="number" min="1" max="200" formControlName="compOffDay" matInput>
              </mat-form-field>
            </div>
          </div> 
          <div class="row">
            <div class="col-md">
              <mat-card class="mat_card_leave_config">
                <div class="search_main_leave_config">
                  <input formControlName="remark" class="search_input_leave_config" type="text" placeholder="Remark">
                </div>
              </mat-card>
            </div>
          </div>
        <div class="row">
            <div class="col-md">
                <div class="submit_button_main_lConfig">
                    <button class="assign_lConfig_btn" type="submit" (click)="onSubmit()"> <span>Assign</span>
                    </button>
                </div>
            </div>
        </div>

    </div>
</form>