
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressDetailsService } from 'src/app/services/address-details/address-details.service';
import { AddressDetailsDTO } from 'src/app/dtos/AddressDetailsDTO';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { EmergencycontactService } from 'src/app/services/emergency-contact/emergencycontact.service';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-permanent-address',
  templateUrl: './permanent-address.component.html',
  styleUrls: ['./permanent-address.component.scss']
})
export class PermanentAddressComponent implements OnInit {
  permanentAddress: FormGroup;
  address = new AddressDetailsDTO();
  userDto = new UserDTO();
  constructor(public fb: FormBuilder,
    public userDetailService: UserDetailsService,
    public userService: UserService,
    public toaster: ToastrService,
    public addressdetailservice: AddressDetailsService,
    public dialogRef: MatDialogRef<PermanentAddressComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.permanentAddress = this.fb.group({
      addressLineOne: ['', [Validators.required]],
      addressLineTwo: [''],
      country: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      city: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      state: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      pincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{6}$'),
        ]),
      ],
    });
  }

  ngOnInit(): void {
    //this.data contains userid
    // this.userDto = this.userDetailService.getUserDetails();

    // this.userDetailService.getUserDetails().dateOfBirth;
    // let userid = this.userDetailService.getUserDetails().id;

    this.addressdetailservice
      .getaddressbyuserId('PERMANENT', this.data)
      .subscribe((res) => {
        this.address = res.body;
        this.permanentAddress.patchValue(this.address);
      });
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: 'Closed' })
  }
  checkError1 = (controlName: string, errorName: string) => {
    const formgrp = this.permanentAddress as FormGroup;
    return formgrp.controls[controlName].hasError(errorName);
  };
  onSubmit() {

    if (this.permanentAddress.valid) {
      let permanentAddressDto = new AddressDetailsDTO();
      permanentAddressDto.addressLineOne =
        this.permanentAddress.get('addressLineOne').value;
      permanentAddressDto.addressLineTwo =
        this.permanentAddress.get('addressLineTwo').value;
      permanentAddressDto.city = this.permanentAddress.get('city').value;
      permanentAddressDto.pincode = this.permanentAddress.get('pincode').value;
      permanentAddressDto.state = this.permanentAddress.get('state').value;
      permanentAddressDto.country = this.permanentAddress.get('country').value;
      permanentAddressDto.addressCategory = 'PERMANENT';
      permanentAddressDto.userId = this.userDetailService.getUserDetails().id;
      permanentAddressDto.userId = this.data;
      if (this.address.id != null) {
        if (this.address.addressCategory == 'PERMANENT') {
          permanentAddressDto.id = this.address.id;
          this.addressdetailservice
            .putdocument(permanentAddressDto)
            .subscribe((res) => {
              this.toaster.success("Successfully Saved")
              this.dialogRef.close({ event: 'Closed' })
              //this.notificationService.sucess("User Data Edited")
            });
        }
      } else {
        this.addressdetailservice
          .postdocument(permanentAddressDto)
          .subscribe((res) => {
            this.toaster.success("Successfully Saved")
            this.dialogRef.close({ event: 'Closed' })
            //this.notificationService.sucess("User Data Edited")
          });
      }
    }

  }
}
