import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, AbstractControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { AuthoritiesConstants, Departments, JobTitles, ProbationPeriod, Gender, GeoLocation } from 'src/app/services/user/user-enum';
import { ProjectService } from 'src/app/services/project.service';
import { CareerManagerTeamService } from 'src/app/services/careerManager.service';
import { CareerManagerDTO } from 'src/app/dtos/CareerManagerDTO';
import { CareerManagerTeam } from 'src/app/models/careerManagerTeam';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { TitleService } from 'src/app/services/title-service';
import { addDays } from 'date-fns';
import { AddEmployeeProbationService } from 'src/app/services/getProbationPeriod/get-probation-period.service';
import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { state } from '@angular/animations';
import { debug } from 'console';
import { SpecifiInfoUser } from 'src/app/models/SpecificInfoUser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-employee-card',
  templateUrl: './add-employee-card.component.html',
  styleUrls: ['./add-employee-card.component.scss']
})
export class AddEmployeeCardComponent implements OnInit {

  newEmployeeDetails: FormGroup;
  userDto = new UserDTO();
  jobTitles = [];
  departments = [];
  careerManagerDto: CareerManagerDTO = new CareerManagerDTO();
  careerManagerTeam: CareerManagerTeam;
  filteredCareerManager: Observable<any[]>;
  filteredTalentBuddy: Observable<any[]>;
  rolesList: string[] = [];
  authoritiesEnumRef: AuthoritiesConstants;
  email: AbstractControl;
  emailError: string
  disbleInputField: boolean
  documentsToUpload: Array<File>;
  photo: any;
  base64textString: any;
  imageError: string;
  binaryImgString: string;
  allTitleList = [];
  probationPeriods = [];
  genders = [];
  locations: string[] = ['Pune', 'Bangalore'];
  probationPeriodDetails: any = []
  saveBtnLabel = "Save";
  countrySelectEvent: any;
  geoLocations: any = [];
  baseLocation: any;
  officeLocation: any;
  isValidEmail = true;
 

  constructor(
    public userService: UserService,
    public userDetailService: UserDetailsService,
    public fb: FormBuilder,
    private notificationService: NotificationService,
    public projectService: ProjectService,
    public careerManagerService: CareerManagerTeamService,
    private titleService: TitleService,
    private probationService: AddEmployeeProbationService,
    public dialogRef: MatDialogRef<AddEmployeeCardComponent>,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: User) {

    this.newEmployeeDetails = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      employeeId: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9]+$')]],
      department: ['', [Validators.required]],
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      // mobileNo: ['', Validators.compose([Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])],
      mobileNo: ['', [Validators.required]],
      jobTitle: ['', [Validators.required]],
      careerManager: ['', [Validators.required]],
      dateOfJoining: ['', [Validators.required]],
      roles: [['Role User'], [Validators.required]],
      probationPeriod: ['', [Validators.required]],
      confirmationDate: ['', [Validators.required]],
      imageBase: [''],
      gender: ['', [Validators.required]],
      talentBuddy: ['', [Validators.required]],
      geoLocation: ['', [Validators.required]],
      baseLocation: ['', [Validators.required]],
      officeLocation: ['', [Validators.required]]
    },
    )
  }

  ngOnInit(): void {

    //this.getTitleData()

    // if (this.userService.allUserData.length == 0) { //needed only to populate career manager names
    //   this.userService.getAllUser()
    // }

    // this.userService.getAllEmployeeNames();
    this.userService.getAllUserForDropDown().subscribe(res => {

      this.userService.setUsersToPopulateCareerManagerAndTalentBuddyLists(res.body)
    }
    );

    // Binding Edit Data to Form
    this.setFormData();
    this.processEnumValuesToArray();
    //this.projectService.getProjects();
    //this.userService.getUser("Asc");
    //this.getProbationPeriodDetails();

    // on Career Manager search
    this.filteredCareerManager = this.newEmployeeDetails.get('careerManager').valueChanges.pipe(
      startWith(''),
      map(state => this.filterManager(state))
    )

    this.filteredTalentBuddy = this.newEmployeeDetails.get('talentBuddy').valueChanges.pipe(
      startWith(''),
      map(state => this.filterManager(state))
    )

    this.displayFnCareerManager(new UserDTO);
  }

  getTitleData() {
    this.titleService.getAllTitleList().subscribe(data => {
      data.body.forEach(item => {
        this.allTitleList.push(item)
      });
    })
  }

  jobTitleList(data) {
    const probationValue = this.probationPeriodDetails.filter(x => x.jobTitle === data)[0]?.probationPeriod;
    this.newEmployeeDetails.get('probationPeriod').setValue(
      probationValue ? probationValue : ''
    )
  }

  setDefaultImg() {
    this.photo = false;
    this.binaryImgString = "";
    this.newEmployeeDetails.patchValue({
      imageBase: null
    });
  }

  getProbationPeriodDetails() {
    this.probationService.getProbationPeriodData().subscribe(response => {

      response.body.forEach(item => {
        this.probationPeriodDetails.push(item)
      });
    })
  }

  checkError = (controlName: string, errorName: string) => {
    return this.newEmployeeDetails.controls[controlName].hasError(errorName);
  }

  processEnumValuesToArray() {
    for (let jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle)))
        this.jobTitles.push(jobTitle)
    }
    for (let authoritieslist of Object.values(AuthoritiesConstants)) {
      if (isNaN(Number(authoritieslist)))
        this.rolesList.push(authoritieslist)
    }

    for (let department of Object.values(Departments)) {
      if (isNaN(Number(department)))
        this.departments.push(department)
    }

    for (let probationPeriod of Object.values(ProbationPeriod)) {
      if ((Number(probationPeriod)))
        this.probationPeriods.push(probationPeriod)
    }

    for (let gender of Object.values(Gender)) {
      if (isNaN(Number(gender)))
        this.genders.push(gender)
    }

    for (let geoLocation of Object.values(GeoLocation)) {
      if (isNaN(Number(geoLocation)))
        this.geoLocations.push(geoLocation)
    }
  }

  readURL(event: Event): void {

    this.photo = false;
    this.imageError = '';
    if (event && event[0]) {
      const file = event[0];
      const reader = new FileReader();
      const max_size = 1000000;
      const max_height = 15200;
      const max_width = 25600;

      if (event[0].size > max_size) {
        this.imageError = 'Maximum allowed size is ' + max_size / 1000000 + 'MB';
        return;
      }
      reader.onload = (e) => {
        this.photo = true;
        this.binaryImgString = e.target.result as string;
        this.base64textString = btoa(this.binaryImgString);
        this.newEmployeeDetails.patchValue({
          imageBase: this.binaryImgString.split(",")[1]
        });
      }
      reader.readAsDataURL(file);
    }
  }

  onEmailTextTyped(event) {
    this.userService.validateEmail(event).subscribe(res => {
      this.isValidEmail = true;
    }, (error) => {
      this.isValidEmail = false;
      if (error.error.errorKey == "emailexists") {
        this.setFormControlError('email', 'InvalidEmail', 'Email already Exist');
      } else {
        this.setFormControlError('email', 'InvalidEmail', 'required');
      }
    })
  }

  onEmployeeIdTextTyped(event) {
    this.userService.validateEmployeeId(event).subscribe(res => {
    }, (error) => {
      if (error.error.errorKey == "employeeIdExist") {
        this.setFormControlError('employeeId', 'InvalidEmployeeId', 'Employee Id Already Exist');
      } else {
        this.setFormControlError('employeeId', 'InvalidEmployeeId', 'required');
      }
    })
  }


  setFormData() {
    console.log(this.data)
// debugger
    if (this.data) {
      this.newEmployeeDetails.get('firstName').setValue(
        this.data.firstName ? this.data.firstName : ''
      )

      this.newEmployeeDetails.get('lastName').setValue(
        this.data.lastName ? this.data.lastName : ''
      )

      var jobTitle = this.getEnumValueByEnumKey(JobTitles, this.data.jobTitle);
      this.newEmployeeDetails.get('jobTitle').setValue(
        this.data.jobTitle ? jobTitle : ''
      )

      var gender = this.getEnumValueByEnumKey(Gender, this.data.gender);

      this.newEmployeeDetails.get('gender').setValue(
        this.data.gender ? gender : ''
      )

      var department = this.getEnumValueByEnumKey(Departments, this.data.department);
      this.newEmployeeDetails.get('department').setValue(
        this.data.department ? department : ''
      )

      var geoLocation = this.getEnumValueByEnumKey(GeoLocation, this.data.geoLocation);
      this.newEmployeeDetails.get('geoLocation').setValue(
        this.data.geoLocation ? geoLocation : ''
      )

      var tempauthorities = this.getauthorityValueByEnumKeyArray(AuthoritiesConstants, this.data.authorities)
      this.newEmployeeDetails.get('roles').setValue(
        this.data.authorities ? tempauthorities : ''
      )

      this.newEmployeeDetails.get('email').setValue(
        this.data.email ? this.data.email : ''
      )

      this.newEmployeeDetails.get('mobileNo').setValue(
        this.data.mobileNumber ? this.data.mobileNumber : ''
      )
      this.newEmployeeDetails.controls["mobileNo"].setValidators([Validators.maxLength(this.newEmployeeDetails.get('mobileNo').value.length)]);

      if (this.data.careerManagerId) {
        var careerManagerRecord = this.userService.usersDto.find((obj) => {
          return obj.id == this.data.careerManagerId;
        })
        this.newEmployeeDetails.get('careerManager').setValue(new User(careerManagerRecord));

        // this.userService.getUserByID(this.data.careerManagerId).subscribe(res => {
        //   this.newEmployeeDetails.get('careerManager').setValue(new User(res.body))
        // })
      }

      if (this.data.talentBuddyId) {

        var talentBuddyRecord = this.userService.usersDto.find((obj) => {
          return obj.id == this.data.talentBuddyId;
        })
        this.newEmployeeDetails.get('talentBuddy').setValue(new User(talentBuddyRecord));

        // this.userService.getUserByID(this.data.talentBuddyId).subscribe(res => {
        //   this.newEmployeeDetails.get('talentBuddy').setValue(new User(res.body))
        // })
      }
      this.newEmployeeDetails.get('employeeId').setValue(
        this.data.employeeId ? this.data.employeeId : ''
      )

      this.newEmployeeDetails.get('dateOfJoining').setValue(
        this.data.joiningDate ? new Date(this.data.joiningDate) : ''
      )

      var probationPeriod = this.getEnumValueByEnumKey(ProbationPeriod, this.getEnumKeyByEnumValue(ProbationPeriod, this.data.probationPeriod.toString()));
      this.newEmployeeDetails.get('probationPeriod').setValue(
        this.data.probationPeriod ? probationPeriod : ''
      )
      // this.probationService.getProbationPeriodData().subscribe(res=>{
      //   res.body.forEach(item => {
      //     if(item.jobTitle === jobTitle){
      //       this.newEmployeeDetails.get('probationPeriod').setValue(
      //         item.probationPeriod
      //       )
      //     }
      //   });
      // })

      this.newEmployeeDetails.get('confirmationDate').setValue(
        this.data.confirmationDate ? new Date(this.data.confirmationDate) : ''
      )

      this.newEmployeeDetails.get('baseLocation').setValue(
        this.data.baseLocation ? this.data.baseLocation : ''
      )

      this.newEmployeeDetails.get('officeLocation').setValue(
        this.data.officeLocation ? this.data.officeLocation : ''
      )


      if (this.data.userImageThumbnail !== null) {
        this.photo = true;
        this.binaryImgString = this.data.userImageThumbnail;
      }
      else {
        this.photo = false;
      }

      this.newEmployeeDetails.controls['email'].disable();

      if (this.newEmployeeDetails.get('employeeId').value !== "") {
        this.newEmployeeDetails.controls['employeeId'].disable();
      }
    }
  }

  onSubmit() {

    if (this.newEmployeeDetails.valid) {

      this.userDto.activated = true;
      this.userDto.createdBy = this.userDetailService.getUserDetails().id
      this.userDto.createdDate = new Date()
      this.userDto.lastModifiedBy = this.userDto.createdBy
      this.userDto.lastModifiedDate = this.userDto.lastModifiedDate
      this.userDto.firstName = this.newEmployeeDetails.get('firstName').value
      this.userDto.lastName = this.newEmployeeDetails.get('lastName').value
      this.userDto.email = this.newEmployeeDetails.get('email').value
      this.userDto.jobTitle = this.getEnumKeyByEnumValue(JobTitles, this.newEmployeeDetails.get('jobTitle').value);
      this.userDto.gender = this.getEnumKeyByEnumValue(Gender, this.newEmployeeDetails.get('gender').value);
      this.userDto.talentBuddyId = this.newEmployeeDetails.get('talentBuddy').value.userID;
      if (this.userDto.talentBuddyId === undefined) {
        this.userDto.talentBuddyId = this.newEmployeeDetails.get('talentBuddy').value.id;
      }
      this.userDto.authorities = this.getauthoritiesKeyByEnumValueArray(AuthoritiesConstants, this.newEmployeeDetails.get('roles').value);
      this.userDto.login = this.userDto.email
      this.userDto.microsoftId = 'asldkfjasdgnak23rafa9'
      this.userDto.mobileNumber = this.newEmployeeDetails.get('mobileNo').value
      this.userDto.langKey = "en"
      this.userDto.careerManagerId = this.newEmployeeDetails.get('careerManager').value.userID;
      if (this.userDto.careerManagerId === undefined) {
        this.userDto.careerManagerId = this.newEmployeeDetails.get('careerManager').value.id;

      }
      this.userDto.department = this.getEnumKeyByEnumValue(Departments, this.newEmployeeDetails.get('department').value)
      this.userDto.employeeId = this.newEmployeeDetails.get('employeeId').value
      this.userDto.joiningDate = this.newEmployeeDetails.get('dateOfJoining').value
      this.userDto.probationPeriod = parseInt(this.newEmployeeDetails.get('probationPeriod').value);
      this.userDto.confirmationDate = this.newEmployeeDetails.get('confirmationDate').value;
      this.userDto.geoLocation = this.getEnumKeyByEnumValue(GeoLocation, this.newEmployeeDetails.get('geoLocation').value)
      this.userDto.baseLocation = this.newEmployeeDetails.get('baseLocation').value;
      this.userDto.officeLocation = this.newEmployeeDetails.get('officeLocation').value;

      if (this.photo) {
        this.newEmployeeDetails.patchValue({
          imageBase: this.binaryImgString.split(",")[1]
        })
        if (this.data && (this.newEmployeeDetails.get('imageBase').value === this.data.userImageThumbnail)) {
          this.userDto.userImage = "Unchanged";
        }
        else {
          this.userDto.userImage = this.newEmployeeDetails.get('imageBase').value
        }
      }
      else {
        this.userDto.userImage = "";
      }

      if (this.data) {

        if (this.data.id) this.userDto.id = this.data.id
        this.saveBtnLabel = "Saving...";
        this.userService.updateUser(this.userDto).subscribe(res => {
          this.userService.getUser("Asc");
          // this.notificationService.sucess("User Data Edited");
          this.toast.success("User Data Edited", 'Success');
          this.dialogRef.close();

          this.saveBtnLabel = "Save";
        },(e)=>{          
          console.log(e);
        })
      } else {
        this.saveBtnLabel = "Saving...";
        this.userService.addUser(this.userDto).subscribe(res => {
          this.userService.getUser("Asc");
          //this.notificationService.sucess("New User Created");
          this.toast.success("New User Created", 'Success');
          this.dialogRef.close();
          this.saveBtnLabel = "Save";
        })
      }
    } else {
      // this.setFormControlError('email', 'InvalidEmail', 'required');
      // this.setFormControlError('employeeId', 'InvalidEmployeeId', 'required');
      this.notificationService.invalidFormData()
    }
  }

  setFormControlError(controlName: string, controlError: string, errorMsg: string) {

    this.newEmployeeDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.newEmployeeDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.newEmployeeDetails.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg;
  }

  closeDialog() {
    this.dialogRef.close()
  }

  displayFnCareerManager(user): string {
    return user && (user.firstName || user.lastName) ? user.firstName + ' ' + user.lastName : '';
  }

  filterManager(name: string) {
    
    if (typeof name !== 'object')
      // return this.userService.allUserData.filter(user =>  
      //    if(a.toLowerCase().indexOf(query.toLowerCase()) >-1){

      return this.userService.usersDtoForCMTBList.filter(user =>
        user.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0 ||
        user.lastName.toLowerCase().indexOf(name.toLowerCase()) === 0)
    else
      return
  }

  getJobTitle(item) {
    if (isNaN(Number(item))) {
      return item
    }
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  // working -----
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }

  getauthoritiesKeyByEnumValueArray(myEnum, enumValue) {
    return Object.keys(myEnum).filter(k => enumValue.includes(myEnum[k]));
  }

  getauthorityValueByEnumKeyArray(myEnum, enumKeyArr) {
    return enumKeyArr ? enumKeyArr.map(k => myEnum[k]) : '';
  }

  // onJoiningDateChange(event : any) : void {
  //   this.newEmployeeDetails.get('confirmationDate').setValue( addDays(event.value, this.newEmployeeDetails.get('probationPeriod').value));
  // }

  onProbationPeriodSelect(event: any): void {
    this.newEmployeeDetails.get('confirmationDate').setValue(addDays(this.newEmployeeDetails.get('dateOfJoining').value, parseInt(event)))
  }

  countryChangedEvent($event) {
    this.countrySelectEvent = $event;
    this.newEmployeeDetails.controls["mobileNo"].setValidators([Validators.maxLength(this.countrySelectEvent.placeHolder.length)]);

  }

  focusOutMobileField() {

    if (this.newEmployeeDetails.get('mobileNo').value.length === 0) {
      this.setFormControlError('mobileNo', 'ZeroLength', 'required');
    }

    else if (this.newEmployeeDetails.get('mobileNo').value.length !== this.countrySelectEvent.placeHolder.length) {
      this.setFormControlError('mobileNo', 'InvalidNumber', 'Invalid Pattern');
    }

    else {
    }
  }

}
