import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-on-leave-reportee',
  templateUrl: './on-leave-reportee.component.html',
  styleUrls: ['./on-leave-reportee.component.scss']
})
export class OnLeaveReporteeComponent implements OnInit {
  onLeaveData:any;
  allOnLeaveData:any=[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OnLeaveReporteeComponent>,
    public userservice: UserService
    
    ) {}

  ngOnInit(): void {
    // console.log(this.data)
    this.allOnLeaveData=[];
    this.onLeaveData=this.data;
    for (let key of Object.keys(this.data)) {
      // console.log(this.data[key].desc);
      // console.log(this.data[key]);
       for(let i=0;i<this.data[key].desc.length;i++){
          // console.log(this.data[key].desc[i])
          this.userservice.getUserByID(this.data[key].desc[i]).subscribe((result) =>{
           let currData={
            name: result.body.firstName + ' ' + result.body.lastName,
            date: this.data[key].date 
           }
           this.allOnLeaveData.push(currData)
          })
        }
        

      }
    
    
    // console.log(this.data.event._def)
    // console.log(this.data.event._instance)
    // console.log(this.data.event._instance.range.start)
    // console.log(this.data.event._instance.range.end)
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }

}
