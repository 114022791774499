<div class="container-fluid laptop-view">
    <div class="row mt-4">
        <div class="col-md-3">
            <label class="goaltype">{{this.goalTypeMapping[usergoaldto.goalType]}}</label>
        </div>
        <div class="col-md-2 admin_label">
            {{this.username}}
        </div>
        <div class="col-md-4">
            <div class="main-header">
                <div class="on-track-bar">
                    <div class="on-track-lbl">
                        <span class="sub-goal-count">No. of Subgoal</span>
                        <span>{{goal.length}}</span>
                    </div>
                </div>
                <div>
                    <button class="close_label" (click)="closeDialog()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <hr class="hr">
    <div class="container-fluid">
        <div class="row goal_div_border" *ngFor="let goal of this.goal;let i=index">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3">
                        <label class="employee_lable">{{goal.subgoalName}}</label>
                    </div>
                    <div class="col-md-1">
                        <label class="percentage-lbl">{{goal.currentValue}}</label>
                    </div>
                    <div class="col-md-8">
                        <p class="desc">{{goal.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
<div class="container-fluid mobile-views">
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="main_header_div1">
                <div class="child">
                    <label class="employee_lable">{{this.goalTypeMapping[usergoaldto.goalType]}}</label>
                </div>
                <div class="child_close_btn">
                    <button class="close_lbl" (click)="closeDialog()">Close</button>
                </div>
            </div>
            <div class="admin_label">
                {{this.username}}
            </div>
            <div class="on-track-bar">
                <div class="on-track-lbl">
                    <span class="sub-goal-count">No. of Subgoal</span>
                    <span>{{goal.length}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-1">
                <button class="close_label" (click)="closeDialog()">
                    Close
                </button>
            </div>
        </div>
    </div>
    <hr class="hr">
    <div class="container-fluid">
        <div class="row laptop_div_border" *ngFor="let goal of this.goal;let i=index">
            <div class="col-md-12">
                <div class="main_header_div3">
                    <div class="child">
                        <label class="goal_label">{{goal.subgoalName}}</label>
                    </div>
                    <div class="child_close_btn">
                        <label class="currentvalue_label">{{goal.currentValue}}</label>
                    </div>
                </div>
                <div class=" mt-3">
                    <p class="desc">{{goal.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>