import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class PfdetailsUrlService {
  private readonly pfBaseApi = '/api/provident-fund-details';
  private readonly user = '/user';
  private readonly userId_param = 'userId'
  constructor(private restApi:RestAPIService) { }
  getBaseUrl(){
    return this.pfBaseApi;
  }
  getUserUrl(userId) {
    return this.pfBaseApi + this.user
      + '?' + this.userId_param + '=' + userId
  }
  getByFieldUrl(field){
    return this.pfBaseApi+ this.getByField(field)
  }
  private getByField(field){
    return '/'+field;
  }
}
