import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Skill } from 'src/app/models/skill';
import { SkillDTO } from 'src/app/dtos/SkillDTO';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { EditProfilesComponent } from '../edit-profiles.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SkillService } from 'src/app/services/skill/skill.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { EducationDetailsService } from 'src/app/services/education-details/education-details.service';
import { Education } from 'src/app/models/educationdetails';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import { PreviousEmployment } from 'src/app/models/previousEmployment';
import { Certificate } from 'src/app/models/certificate';
import { CertificationService } from 'src/app/services/certification/certification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import generatePdfThumbnails from 'pdf-thumbnails-generator';
import { UserDocumentDTO } from 'src/app/dtos/UserDocumentDTO';
import { UserDocumentService } from 'src/app/services/user-document.service';
import { UserCertificateDTO } from 'src/app/dtos/UserCertificateDTO';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-tab2-spotlight',
  templateUrl: './tab2-spotlight.component.html',
  styleUrls: ['./tab2-spotlight.component.scss'],
})
export class Tab2SpotlightComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  editSpotlightDetails: FormGroup;
  aboutDetails: FormGroup;
  professionalSkillsDetails: FormGroup;
  employmentHistoryDetails: FormGroup;
  educationDetails: FormGroup;
  panelOpenState = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  panelOpenState4 = false;
  userDto = new UserDTO();
  disabledskill = true;
  certCount = 0;
  //Skills: any;
  cardImageBase64: string;
  certificatenames: string;
  skill: FormArray;
  binary = [];
  flag = []
  Educationgroup: FormArray;
  preEmployment: FormArray;
  UploadCertificate: FormArray;
  certificatedocument = new UserDocumentDTO();
  removedSkill = [];
  removedEducation = [];
  removedPreviousemployment = [];
  removecertificates = [];
  certificate = [];
  certificatename = [];
  certificateType = [];
  multiCertificates: any = [];
  isCertificateSaved: boolean[];
  imageError: string;
  binaryDocString: any;
  base64RelevantDocString: any;
  UploadCert: FormArray;
  thumbnails: any;
  loadedfile: any;
  cardBase64: string
  bytes;
  today = new Date();
  saved: Boolean = false;
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<EditProfilesComponent>,
    public userDetailService: UserDetailsService,
    public userService: UserService,
    public skillservice: SkillService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    public educationservice: EducationDetailsService,
    public empservice: PreviousEmploymentService,
    public certificateservice: CertificationService,
    private cf: ChangeDetectorRef,
    public toastr: ToastrService,
    public userdocumentservice: UserDocumentService,
    public toaster: ToastrService,

    @Inject(MAT_DIALOG_DATA) public data: User
  ) {
    this.editSpotlightDetails = this.fb.group({
      // about: ['', [Validators.required]],
      // skills: this.fb.array([]),
      // education: this.fb.array([]),
      // previousEmployment: this.fb.array([]),
      uploadCertificates: this.fb.array([]),
    });

    this.aboutDetails = this.fb.group({
      about: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')]],
    });
    this.professionalSkillsDetails = this.fb.group({
      skills: this.fb.array([]),
    });

    this.employmentHistoryDetails = this.fb.group({
      previousEmployment: this.fb.array([]),
    });

    this.educationDetails = this.fb.group({
      education: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.setFormData();
    this.userService.getUserAbout(this.userDetailService.getUserDetails().id).subscribe(res => {
      if (res.body.about == undefined || res.body.about == null) {
        this.userDto.about = '';
      }
      else {
        this.userDto.about = res.body.about;
      }
      this.userDto.id = this.userDetailService.getUserDetails().id;
    })
    // this.userDto = this.userDetailService.getUserDetails();

    this.skillservice
      .getskillByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        if (res.body[0] == null) {
          this.addSkill();
        }
        this.setSkillFormArray(res.body);
      });
    this.educationservice
      .getskillByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.setEducationFormArray(res.body);
      });

    this.certificateservice
      .getCertByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {

        if (res.body[0] == null) {
          //this.addCertificate();
        }
        this.setCertificateFormArray(res.body);
      });
    this.empservice
      .getprevempByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.setPrevempFormArray(res.body);
      });
  }
  checkError = (controlName: string, errorName: string) => {
    return this.aboutDetails.controls[controlName].hasError(errorName);
  };
  setFormData() {

    this.userService.getUserAbout(this.userDetailService.getUserDetails().id).subscribe(res => {
      if (res.body.about == undefined || res.body.about == null) {
        this.userDto.about = '';
      }
      else {
        this.userDto.about = res.body.about;
      }
      this.userDto.id = this.userDetailService.getUserDetails().id;
      this.aboutDetails.get('about').setValue(this.userDto.about);
    })

    // if (this.data) {
    //   this.aboutDetails.get('about').setValue(this.data.about);
    // }
  }
  //Add Skill Form starts here
  createskill() {
    this.Skills;
    return this.fb.group(new Skill());
  }
  setSkillFormArray(response) {
    this.skill = this.professionalSkillsDetails.get('skills') as FormArray;
    let skillList: Skill[] = this.skillservice.setSkillResponse(response);
    for (let s of skillList) {
      this.skill.push(this.fb.group(s));
    }
  }
  get Skills(): FormArray {
    return this.professionalSkillsDetails.get('skills') as FormArray;
  }
  addSkill() {
    this.skill = this.professionalSkillsDetails.get('skills') as FormArray;
    this.skill.insert(0, this.createskill());
  }
  removeskill(id: any, i) {
    this.removedSkill.push(id);
    this.Skills.removeAt(i);


  }

  //Add Skill Form ends here

  //Previous Employeement Details Form starts
  createpreEmp() {
    let formGroup = this.fb.group(new PreviousEmployment());
    formGroup.controls.comapanyName.setValidators([Validators.required]);
    formGroup.controls.jobTitle.setValidators([Validators.required]);
    return formGroup;
  }

  getMinEndDate(i, formArrayName) {
    // var endDate = this.editSpotlightDetails.get('previousEmployment').get('startDate')
    var endDate = this.employmentHistoryDetails.get(formArrayName)['controls'][i].get('startDate').value
    return endDate
  }

  processDate(previousEmployment) {
    let experience;

    if (previousEmployment.controls.endDate.valid) {
      let startDate: Date = previousEmployment.controls.startDate.value;
      let endDate: Date = previousEmployment.controls.endDate.value;
      let todaydate: Date = new Date();
      let diff2 = todaydate > endDate;
      if (todaydate >= endDate) {
      } else {
      }
    }
  }
  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }
  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
  setPrevempFormArray(response) {
    let data: number;
    this.preEmployment = this.employmentHistoryDetails.get(
      'previousEmployment'
    ) as FormArray;
    let empList: PreviousEmployment[] =
      this.empservice.setPrevempResponse(response);
    for (let s of empList) {
      let formGroup: FormGroup = this.fb.group(s);
      formGroup.controls.experiencemonth.setValue(this.processDate(formGroup));
      this.preEmployment.push(formGroup);
    }
  }
  addEvent(type: string, previousEmployment: FormGroup) {
    previousEmployment.controls.experiencemonth.setValue(
      this.processDate(previousEmployment)
    );
  }
  PreviousEmp(): FormArray {
    return this.employmentHistoryDetails.get('previousEmployment') as FormArray;
  }
  addPreEmployment() {

    this.preEmployment = this.employmentHistoryDetails.get(
      'previousEmployment'
    ) as FormArray;
    this.preEmployment.insert(0, this.createpreEmp());
  }
  removePreEmployment(id, i) {
    this.removedPreviousemployment.push(id);
    this.PreviousEmp().removeAt(i);
  }
  //Previous Employeement Details Form ends

  //Education Details Form starts
  createeducation() {
    let formGroup = this.fb.group(new Education());
    formGroup.controls.courseName.setValidators([Validators.required]);
    formGroup.controls.yearofCompletion.setValidators([Validators.required]);
    return formGroup;
  }
  setEducationFormArray(response) {

    this.Educationgroup = this.educationDetails.get('education') as FormArray;
    let eduList: Education[] =
      this.educationservice.setEducationResponse(response);
    for (let s of eduList) {

      let v: Education = new Education().bind(s);
      let educationGroup = this.createeducation();
      educationGroup.controls.courseName.setValue(v.courseName);
      educationGroup.controls.yearofCompletion.setValue(v.yearofCompletion);
      educationGroup.controls.id.setValue(v.id);
      this.Educationgroup.insert(0, educationGroup);
    }
  }

  Education(): FormArray {
    return this.educationDetails.get('education') as FormArray;
  }
  addEducation() {
    this.Educationgroup = this.educationDetails.get('education') as FormArray;
    this.Educationgroup.insert(0, this.createeducation());
  }
  removeeducation(id, i: number) {
    this.removedEducation.push(id);
    this.Education().removeAt(i);
  }

  //Education Details Form ends

  //Add Certificate Details Starts here
  createcertificate() {
    return this.fb.group(new Certificate());
  }
  removecertificate(id: any, i) {

    this.removecertificates.push(id);
    this.Certificate.removeAt(i);

    this.multiCertificates.splice(i, 1);
    this.certificatename.splice(i, 1);
    this.certCount = this.certCount - 1;
  }

  getMinEndDateCert(i) {
    // var endDate = this.editSpotlightDetails.get('previousEmployment').get('startDate')
    var endDate = this.editSpotlightDetails.get('uploadCertificates')['controls'][i].get('dateOfIssue').value
    return endDate
  }

  setCertificateFormArray(response) {

    this.UploadCertificate = this.editSpotlightDetails.get(
      'uploadCertificates'
    ) as FormArray;
    let certList: Certificate[] =
      this.certificateservice.setCertResponse(response);
    for (let s of certList) {

      if (s.docBase == undefined) {
        this.multiCertificates.push("undefined");
        this.certificateType.push("undefined");
        this.binary.push("undefined");
      }
      else {
        var temp = atob(s.docBase.toString());
        var cerType = this.getDocumentType(temp);
        this.multiCertificates.push(temp);
        this.certificateType.push(cerType);
        this.binary.push(s.docBase.toString());
      }

      this.certCount = this.certCount + 1;
      this.flag.push(true);
      this.UploadCertificate.push(this.fb.group(s));
    }
  }
  getDocumentType(temp): string {
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
    for (let type of allowed_types) {
      if (temp.indexOf(type) != -1) {
        return type;
      }
    }
  }
  get Certificate(): FormArray {
    return this.editSpotlightDetails.get('uploadCertificates') as FormArray;
  }
  addCertificate() {

    this.UploadCertificate = this.editSpotlightDetails.get(
      'uploadCertificates'
    ) as FormArray;
    this.flag.push(true);
    this.UploadCertificate.insert(this.certCount, this.createcertificate());
    this.certCount = this.certCount + 1;
  }

  uploadCertificate(fileInput: any, i) {

    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      this.certificatename.push(fileInput.target.files[0].name);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgBase64Path: String = e.target.result;
        let imageContent = imgBase64Path.split(',')[1];
        let imageMetaData = imgBase64Path.split(',')[0];
        let imageContentType = imageMetaData.substring(
          imageMetaData.lastIndexOf(':') + 1,
          imageMetaData.lastIndexOf(';')
        );

        var temp = imageMetaData + ',' + imageContent;
        this.cardBase64 = imageMetaData + ',' + imageContent;
        if (this.multiCertificates[i] == null) {


          this.multiCertificates.push(temp);
          this.certificateType.push(imageContentType);
          // this.binary.push(btoa(this.cardBase64));
        } else {
          this.multiCertificates[i] = temp;
          this.certificateType[i] = imageContentType;

        }
        this.binary[i] = btoa(this.cardBase64);
        // this.multiCertificates.push(imageMetaData + ',' + imageContent);
        // this.cardBase64 = imageMetaData + ',' + imageContent;
        // this.binary= btoa(this.cardBase64);

      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  removeImage(i) {
    this.multiCertificates.splice(i, 1);
  }

  open(content, i) {
    
    this.multiCertificates[i]
    if (this.flag[i] == false) {
      this.multiCertificates[i] = atob(this.multiCertificates[i]);
      this.flag[i] = true;
    }
    else {

    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

  onSubmit() {

    if (this.aboutDetails.valid && (this.userDto.about !== this.aboutDetails.get('about').value)) {

      this.userDto.about = this.aboutDetails.get('about').value;
      this.userService.setUserAbout(this.userDto.id, this.userDto.about).subscribe(() => {
        this.userDetailService.setUserAbout(this.userDto.about);
        this.toaster.success("Successfully Saved", '');
      });

      // if (this.data) {
      //   if (this.data.id) this.userDto.id = this.data.id;
      //   this.userService.updateUser(this.userDto).subscribe((res) => {
      //     this.userService.getUser('Asc');
      //     this.saved = true;
      //   });
      // } else {
      //   this.userService.addUser(this.userDto).subscribe((res) => {
      //     this.userService.getUser('Asc');
      //     this.saved = true;
      //   });
      // }
    }
  }
  onSubmit1() {
    if (this.professionalSkillsDetails.valid) {
      for (let skill of this.professionalSkillsDetails.value.skills) {
        if (skill && !skill.id && skill.technology != '') {
          //skill.userId = this.data;
          skill.userId = this.userDetailService.getUserDetails().id;
          this.skillservice.create(skill).subscribe((res) => {
            this.toaster.success('Successfully Saved', '')

            this.saved = true;
          });
        } else if (skill && skill.id && skill.technology != '') {
          this.skillservice.update(skill).subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')

            this.saved = true;
          });
        }
      }

    }
    if (this.removedSkill.length > 0) {

      for (let i in this.removedSkill) {

        this.skillservice.delete(this.removedSkill[i]).subscribe(res => {


        });

      }
    }
    //this.toaster.success('Successfully Deleted' ,'')
  }
  onSubmit2() {
    if (this.employmentHistoryDetails.valid) {
      for (let prevemp of this.employmentHistoryDetails.value
        .previousEmployment) {
        if (
          prevemp &&
          !prevemp.id &&
          prevemp.comapanyName != null &&
          prevemp.jobTitle != null &&
          prevemp.startDate != undefined &&
          prevemp.endDate != undefined
        ) {
          prevemp.userId = this.userDetailService.getUserDetails().id;
          this.empservice.create(prevemp).subscribe((res) => {
            this.toaster.success('Successfully Saved', '')
          });
        } else if (
          prevemp &&
          prevemp.id &&
          prevemp.comapanyName != null &&
          prevemp.jobTitle != null &&
          prevemp.startDate != undefined &&
          prevemp.endDate != undefined
        ) {
          this.empservice.update(prevemp).subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')
          });
        }
      }

    }
    for (let i in this.removedPreviousemployment) {
      this.empservice.delete(this.removedPreviousemployment[i]);
    }
  }
  onSubmit3() {
    if (this.educationDetails.valid) {
      for (let edu of this.educationDetails.value.education) {
        if (
          edu &&
          !edu.id &&
          edu.courseName != '' &&
          edu.yearofCompletion != undefined
        ) {
          edu.userId = this.userDetailService.getUserDetails().id;
          this.educationservice.create(edu).subscribe((res) => {
            this.toastr.success('Successfully ', 'Saved')
            this.saved = true;
          });
        } else if (
          edu &&
          edu.id &&
          edu.courseName != '' &&
          edu.yearofCompletion != undefined
        ) {
          edu.userId = this.userDetailService.getUserDetails().id;
          this.educationservice.update(edu).subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')
            this.saved = true;
          });
        }
      }

    }
    for (let i in this.removedEducation) {
      this.educationservice.delete(this.removedEducation[i]);
    }
  }
  onSubmit4() {

    if (this.editSpotlightDetails.valid) {
      let i = 0;
      for (let cert of this.editSpotlightDetails.value.uploadCertificates) {
        let userdocument = new UserCertificateDTO();
        userdocument.docBase = this.binary[i];
        userdocument.documentName = cert.documentName;
        // userdocument.documentContentType = this.certificatenames;
        userdocument.userId = this.userDetailService.getUserDetails().id;
        if (
          cert &&
          !cert.id &&
          cert.dateOfIssue != undefined &&
          cert.documentName != ''
        ) {
          cert.docBase = this.binary[i];
          cert.userId = this.userDetailService.getUserDetails().id;
          this.certificateservice.create(cert).subscribe((res) => {

            this.toastr.success('Successfully ', 'Saved', {
            });
            this.saved = true;
          });
        } else if (
          cert &&
          cert.id &&
          cert.dateOfIssue != undefined &&
          cert.documentName != '' &&
          cert.docBase != ''
        ) {
          this.certificateservice.update(cert).subscribe((res) => {

            this.toastr.success('Successfully Updated', 'Saved', {
            });
            this.saved = true;
          });
        }
      }
      i++;
    }
    for (let i in this.removecertificates) {
      this.certificateservice.delete(this.removecertificates[i]);
    }
  }
}
