<div class="close-div">
    
    
        <button (click)="closeDialog()" class="close-button">
            Close
        </button>
    
</div>
<div class="container">
    <div class="img-container">
        <img src="assets\no-data-available.png" alt="" class="error-img">
    </div>
    <div class="warning-text">
        No feedback available
    </div>

    <!-- <button class="home" routerLink="/home">Back to home</button> -->
</div>