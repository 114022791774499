import { Injectable } from '@angular/core';
import { BankdetailsUrlService } from '../restAPI/bankDetailsURLs/bankdetails-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class BankDetailsService {
  constructor(private restApiService: RestAPIService,
    private bankurl : BankdetailsUrlService
  ) { }
  create(payload) {
    return this.restApiService.post(
      this.bankurl.getBaseUrl(),
      payload
    )
  }
  update(payload){
    return this.restApiService.put(
      this.bankurl.getBaseUrl(),
      payload
    )
  }
  getbankByUserIdSubscribe(userId) {
    return this.restApiService.get(
      this.bankurl.getUserUrl(userId)
    )
  }
  delete(id: any) {
    this.restApiService.delete(
      this.bankurl.getByFieldUrl(id)
    ).subscribe(res=>{

    })
  }
}
