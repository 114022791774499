import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttandanceRegularisationUrlService {
 //private readonly baseUrl=environment.BASE_API_URL_BACKEND;
 private readonly attendanceApi = '/api/attendance-regularization';
  private readonly acceptOrReject = '/acceptOrReject';
  private readonly regularizeDates = '/regularizeDates';
  constructor(private restApi: RestAPIService) { }

  getBaseUrl() {
    return this.attendanceApi;
  }

  getAcceptRejectUrl(){
    return this.getBaseUrl() + this.acceptOrReject;
  }

  getRegularizaDatesUrl(){
    return this.getBaseUrl() + this.regularizeDates;
  }
  
}
