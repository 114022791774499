<div class="container-fluid ">
    <div class="main_header_div">
        <div class="child">
            <!-- <h1 class="header_name">Feedback Forms/Consolidated</h1> -->
            <label class="header_name">Feedback Form</label>
            <label class="reporteename">{{ this.directReporteeId.directReporteeDetail.firstName + ' ' +
                this.directReporteeId.directReporteeDetail.lastName}}
            </label>
        </div>

        <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
            <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>

        </div>
    </div>
    <hr class="hr-row">
    <form [formGroup]="feedbackDetails">
        <div class="row">
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Feedback Type</mat-label>
                    <mat-select formControlName="feedbackType" (selectionChange)="loadForm($event)"
                        [disabled]="!this.isEdit">
                        <mat-option *ngFor="let form of  this.projects" [value]="form">
                            {{form | underScoreToSpace |
                            titlecase}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="this.isEdit">
                <ng-template [ngIf]="!this.performancevisible">
                    <mat-form-field appearance="outline" class="example-full-width ">
                        <mat-label>Select a Project</mat-label>
                        <mat-select formControlName="projectId" (selectionChange)="getProjectID($event.value)"
                            [disabled]="!this.isEdit">
                            <mat-option *ngFor="let pro of this.projectList" [value]="pro">
                                {{pro | underScoreToSpace |
                                titlecase}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="checkError">Required</mat-error>
                    </mat-form-field>
                </ng-template>

                <ng-template [ngIf]="this.performancevisible ">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Title</mat-label>
                        <input formControlName="title" matInput autocomplete="off" [disabled]="!this.isEdit"
                            pattern="^[a-zA-Z ]*$">
                        <!-- <mat-error *ngIf="checkError">Required</mat-error> -->
                        <mat-error *ngIf="checkError('title', 'required')">Required</mat-error>
                        <mat-error *ngIf="checkError('title','pattern')">Only alphabets are allowed</mat-error>
                    </mat-form-field>
                </ng-template>
                
            </div>
            <div class="col-md-3" *ngIf="!this.isEdit">
                <ng-template [ngIf]="!this.performancevisible">
                    <mat-form-field appearance="outline" class="example-full-width ">
                        <mat-label>Select a Project</mat-label>
                        <input formControlName="projectId" matInput autocomplete="off" readonly>

                        <mat-error *ngIf="checkError">Required</mat-error>
                    </mat-form-field>
                </ng-template>
                <ng-template [ngIf]="this.performancevisible">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Title</mat-label>
                        <input formControlName="title" matInput autocomplete="off" readonly>
                        <mat-error *ngIf="checkError">Required</mat-error>
                    </mat-form-field>
                </ng-template>
                
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Year</mat-label>
                    <mat-select formControlName="year" [disabled]="!this.isEdit">
                        <mat-option *ngFor="let yearr of years" [value]="yearr">{{ yearr }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Weightage</mat-label>
                    <input formControlName="groupWeightage" matInput autocomplete="off"
                        (ngModelChange)="onWeightagetyped($event)" required>
                    <mat-error *ngIf="this.feedbackDetails.get('groupWeightage').errors?.InvalidgroupWeightage">
                        {{emailError ? emailError:'required'}}
                    </mat-error>
                    <mat-error *ngIf="this.feedbackDetails.get('groupWeightage').errors?.required">
                        {{emailError ? emailError:'required'}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-1 mt-2 mb-2" *ngIf="this.directReporteeId.dataFeedback!=null">
                <div (click)="deleteFeedbackGroup()" class="delete-btn">Delete</div>
            </div>
            <ng-template [ngIf]="selectedFormName ==='form3'">
            </ng-template>
        </div>
        <div style="display:flex;" >
            <h1 class="add-heading">Add Opinion</h1>
            <button class="add-btn" (click)="addComponent()">Add</button>
            <button class="add-btn" (click)="addRealtimeFeedback()">Add Realtime Feedback</button>
        </div>
       
        <div class="row" [formGroup]="feedbackOpinion" *ngFor="let feedback of this.opinion().controls; let i = index">
            <div *ngIf='!isVisible[i]' class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Employee</mat-label>
                    <mat-select formControlName="feedbackFrom" (selectionChange)="getUserID($event.value,i)">
                        <input class="myInput" #myInput matInput focused="'true'" type="text"
                            (keyup)="search($event.target.value,i)" (focusout)="focusOutEvent(i)" autocomplete="off"
                            placeholder="search name">
                        <mat-option *ngFor="let user of this.displaySelectedArea[i]" [value]="user">
                            {{user}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="feedbackDetails.value.feedbackArray[i].feedbackRole=='SELF_EVALUATION'"
                style="padding-top: 15px;text-transform: capitalize;" class="col-md-3">
                {{directReporteeId.directReporteeDetail.firstName}} {{directReporteeId.directReporteeDetail.lastName}}
            </div>
            <div class="myInput"
                *ngIf="isVisible[i] && feedbackDetails.value.feedbackArray[i].feedbackRole!=='SELF_EVALUATION'"
                class="col-md-3" [formGroupName]="i">

                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Employee</mat-label>
                    <mat-select formControlName="feedbackFrom" (selectionChange)="getUserID($event.value,i)"
                        disabled="true">
                        <mat-option *ngFor="let user of this.uList" [value]="user">
                            {{user}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>
            <!-- <div class="myInput" *ngIf='isVisible[i]' class="col-md-3" [formGroupName]="i">
                    <input type="text" formControlName="feedbackFrom" disabled="true">
                  </div> -->
            <div *ngIf="!isVisible[i]  && feedbackDetails.value.feedbackArray[i].feedbackRole!=='SELF_EVALUATION'"
                class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="feedbackRole" (selectionChange)="selectedRole($event.value,i)">
                        <mat-option *ngFor="let role of this.FeedbackRole" [value]="role">
                            {{role | underScoreToSpace |
                            titlecase}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf='isVisible[i]' class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="feedbackRole" (selectionChange)="selectedRole($event.value,i)"
                        disabled="true">
                        <mat-option *ngFor="let role of this.FeedbackRole" [value]="role">
                            {{role | underScoreToSpace |
                            titlecase}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf='!isVisible[i]' class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Weightage</mat-label>
                    <input formControlName="weightage" matInput autocomplete="off"
                        (ngModelChange)="onOpinionWeightagetyped($event,i)"  pattern="[0-9]*">
                    <!-- <mat-error *ngIf="feedback.get('weightage').errors?.InvalidWeightage">
                        {{Error?Error:'Enter percentage' }}
                        
                    </mat-error> -->
                    <mat-error *ngIf="feedback.get('weightage').errors">
                        {{feedback.get('weightage')==(''|| null)? 'required' : Error?Error:'Enter percentage'}}
                        <!-- Total waitage sum should be 100 -->
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="isVisible[i] " class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Weightage</mat-label>
                    <input formControlName="weightage" matInput autocomplete="off" [readonly]="true"
                        (ngModelChange)="onOpinionWeightagetyped($event,i)">
                    <mat-error *ngIf="feedback.get('weightage').errors?.InvalidWeightage">
                        {{Error}}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <div *ngIf="isVisible[i] " class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Weightage</mat-label>
                    <input formControlName="weightage" matInput autocomplete="off"
                        (ngModelChange)="onOpinionWeightagetyped($event,i)">
                    <mat-error *ngIf="feedback.get('weightage').errors?.InvalidWeightage">
                        {{Error}}
                    </mat-error>
                </mat-form-field>
            </div> -->

            <div *ngIf="feedback.value.feedbackSubmissionStatus!='SUBMITTED'  || feedback.value.feedbackType === 'RealTime'"
                (click)="removefeedbackopinion(feedback.value.id,i)" class="delete-btn">Delete</div>
            <div *ngIf="feedback.value.feedbackSubmissionStatus==='SUBMITTED' && feedback.value.feedbackType != 'RealTime'"
                class="submitted-btn">SUBMITTED</div>

        </div>
    </form>
    <div class="div_submit_btn">
        <button mat-raised-button (click)="onSubmit()" class="submit-btn" type="submit">Submit</button>
    </div>
</div>