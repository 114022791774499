<div>
    <mat-card class="main-mat-card">
        <div class="row att-history">
            <div>
                <mat-card class="calendar-card">
                    <full-calendar class=" full-calendar" #fullCalendar [options]="calendarOptions"></full-calendar>
                </mat-card>

                <div class="color-legend">
                    <div class="colour-main-div">
                        <div class="Leave colour-div"></div>

                        <div class="colour-div-label">
                            <label>On Leave</label>
                        </div>
                    </div>

                </div>
            </div>


            <div class="col-md-5 flex-col">

                <div class="container-1">
                    <div>
                        <div>
                            <h2>Reportees On Leave</h2>
                        </div>
                    </div>
                    <hr>
                    <div>
                        <div>
                            <div>
                                <p class="month-name">Today</p>
                            </div>
                            <div *ngIf="todayReporteeLeaveData.length==0"> No Reportees On Leave Today!</div>
                            <div *ngFor="let reportee of todayReporteeLeaveData">
                                <div class="single-reportee-detail-container">
                                    <div class="img-container-div">
                                        <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
                                        <div class="dot-blue"></div>
                                    </div>
                                    <div class="name-container">
                                        {{reportee.name | titlecase }}
                                    </div>
                                    <div class="leave-status">
                                        {{reportee.date | titlecase }}
                                    </div>
                                    <div>
                                    </div>
                                    <div class="button-container">
                                        <button class="detail-button" (click)="viewAttendance(reportee)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20"
                                                viewBox="0 0 16 20" fill="none">
                                                <path
                                                    d="M5.89997 14.5435C5.81108 14.4046 5.76386 14.2518 5.7583 14.0851C5.75275 13.9185 5.79997 13.7726 5.89997 13.6476L8.8333 9.98096L5.8833 6.29346C5.79441 6.18235 5.75275 6.03305 5.7583 5.84555C5.76386 5.65805 5.81108 5.50874 5.89997 5.39763C6.01108 5.25874 6.13052 5.19277 6.2583 5.19971C6.38608 5.20666 6.49997 5.27263 6.59997 5.39763L9.91664 9.54346C9.97219 9.61291 10.0111 9.68235 10.0333 9.7518C10.0555 9.82124 10.0666 9.89763 10.0666 9.98096C10.0666 10.0643 10.0555 10.1407 10.0333 10.2101C10.0111 10.2796 9.97219 10.349 9.91664 10.4185L6.61664 14.5435C6.51664 14.6685 6.39997 14.7275 6.26664 14.7205C6.1333 14.7136 6.01108 14.6546 5.89997 14.5435Z"
                                                    fill="#0045B9" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <p class="month-name">This Month</p>
                                </div>
                                <div class="reportees-group">
                                    <div *ngIf="monthReporteeLeaveData.length==0"> No Reportees On Leave This Month!</div>
                                    <div *ngFor="let reportee of monthReporteeLeaveData | orderBy:'date'">
                                        <div class="single-reportee-detail-container">
                                            <div class="img-container-div">
                                                <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
                                                <div class="dot-blue"></div>
                                            </div>
                                            <div class="name-container">
                                                {{reportee.name | titlecase }}
                                            </div>
                                            <div class="leave-status">
                                                {{reportee.date | date:'d MMMM' }}
                                            </div>
                                            <div>

                                            </div>
                                            <div class="button-container">
                                                <button class="detail-button" (click)="viewAttendance(reportee)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20"
                                                        viewBox="0 0 16 20" fill="none">
                                                        <path
                                                            d="M5.89997 14.5435C5.81108 14.4046 5.76386 14.2518 5.7583 14.0851C5.75275 13.9185 5.79997 13.7726 5.89997 13.6476L8.8333 9.98096L5.8833 6.29346C5.79441 6.18235 5.75275 6.03305 5.7583 5.84555C5.76386 5.65805 5.81108 5.50874 5.89997 5.39763C6.01108 5.25874 6.13052 5.19277 6.2583 5.19971C6.38608 5.20666 6.49997 5.27263 6.59997 5.39763L9.91664 9.54346C9.97219 9.61291 10.0111 9.68235 10.0333 9.7518C10.0555 9.82124 10.0666 9.89763 10.0666 9.98096C10.0666 10.0643 10.0555 10.1407 10.0333 10.2101C10.0111 10.2796 9.97219 10.349 9.91664 10.4185L6.61664 14.5435C6.51664 14.6685 6.39997 14.7275 6.26664 14.7205C6.1333 14.7136 6.01108 14.6546 5.89997 14.5435Z"
                                                            fill="#0045B9" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="container3 ">
                    <div class="reportee-container">
                        <div>
                            <h2>My Reportees</h2>
                        </div>
                    </div>
                    <hr>
                    <div *ngIf="allReporteeLeaveBalance.length==0"> No Reportees Data Available</div>
                    <div *ngFor="let reportee of allReporteeLeaveBalance">
                        <div class="single-reportee-detail-container">
                            <div class="img-container-div">
                                <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
                                <div *ngIf="reportee.status=='PRESENT'" class="dot-green"></div>
                                <div *ngIf="reportee.status=='Unmarked'" class="dot-yellow"></div>
                                <div *ngIf="reportee.status=='LEAVE'" class="dot-blue"></div>
                            </div>
                            <div class="name-container">
                                {{reportee.reporteeName | titlecase }}
                            </div>
                            <div class="leave-status">
                                {{reportee.status | titlecase }}
                            </div>
                            <div>

                            </div>
                            <div class="button-container">
                                <button class="detail-button" (click)="viewDetails(reportee)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20"
                                        fill="none">
                                        <path
                                            d="M5.89997 14.5435C5.81108 14.4046 5.76386 14.2518 5.7583 14.0851C5.75275 13.9185 5.79997 13.7726 5.89997 13.6476L8.8333 9.98096L5.8833 6.29346C5.79441 6.18235 5.75275 6.03305 5.7583 5.84555C5.76386 5.65805 5.81108 5.50874 5.89997 5.39763C6.01108 5.25874 6.13052 5.19277 6.2583 5.19971C6.38608 5.20666 6.49997 5.27263 6.59997 5.39763L9.91664 9.54346C9.97219 9.61291 10.0111 9.68235 10.0333 9.7518C10.0555 9.82124 10.0666 9.89763 10.0666 9.98096C10.0666 10.0643 10.0555 10.1407 10.0333 10.2101C10.0111 10.2796 9.97219 10.349 9.91664 10.4185L6.61664 14.5435C6.51664 14.6685 6.39997 14.7275 6.26664 14.7205C6.1333 14.7136 6.01108 14.6546 5.89997 14.5435Z"
                                            fill="#0045B9" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </mat-card>
</div>