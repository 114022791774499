import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CareermanagerUrlService {
  

  public readonly careerManagerBaseApi = '/api/career-manager-teams';
  private readonly search = '/search'
  private readonly exists = '/exists'

  constructor() { }

  
  // //This will be used for get by id and delete by id
  // getByAttribute(field){
  //   return '/'+field;
  // }

 
  getBaseUrl(){
    return this.careerManagerBaseApi;
  }

  getPaginationUrl(pageCount,pageSize){
    pageCount-=1;
    return this.careerManagerBaseApi+'?eagerload=false&'+'page='+pageCount+'&size='+pageSize;
  }

  getSearchUrl(searchText:string){
    return this.careerManagerBaseApi+this.search+this.getByField(searchText)
  }

  getIfCareerManagerExistsUrl(id) {
    return this.careerManagerBaseApi+this.exists+this.getByField(id)
  }

  //This will be used for get by id and delete by id
  getByFieldUrl(field){
    return this.careerManagerBaseApi+ this.getByField(field)
  }

  private getByField(field){
    return '/'+field;
  }
}
