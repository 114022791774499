import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debug } from 'console';
import { AssetDTO } from 'src/app/dtos/AssetDTO';
import { BankDetailsDTO } from 'src/app/dtos/BankDetailsDTO';
import { PanCardDTO } from 'src/app/dtos/panCardDTO';
import { PassportDetailsDTO } from 'src/app/dtos/PassportDetailsDTO';
import { PfDetailsDTO } from 'src/app/dtos/PfDetailsDTO';
import { UserDocumentDTO } from 'src/app/dtos/UserDocumentDTO';
import { AssetCategories } from 'src/app/services/asset-management/asset-enum';
import { AssetManagementService } from 'src/app/services/asset-management/asset-management.service';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { PanCardService } from 'src/app/services/pan-card/pan-card.service';
import { PassportDetailsService } from 'src/app/services/passport-details/passport-details.service';
import { PfDetailsService } from 'src/app/services/pf-details/pf-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserDocumentService } from 'src/app/services/user-document.service';
import { UserService } from 'src/app/services/user/user.service';
import { AccountDetailsComponent } from './account-details/account-details.component';

@Component({
  selector: 'app-vault',
  templateUrl: './vault.component.html',
  styleUrls: ['./vault.component.scss']
})
export class VaultComponent implements OnInit {
  bankdto: BankDetailsDTO[] = []
  pfdto: PanCardDTO[] = []
  passport = new PassportDetailsDTO()
  passpprtdto = new PassportDetailsDTO()
  pandtoo: PanCardDTO[] = []
  assetdto: AssetDTO[] = []
  assetcategories: any
  pandocument = new UserDocumentDTO();
  panBase64: string;
  userid: any;
  isPanSaved: boolean;
  panname: string;
  employeeId: any;
  @Output() dummyfunc = new EventEmitter();

flag=false;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  constructor(public modalService: NgbModal,
    public dialog: MatDialog, public passportservice: PassportDetailsService,
    public bankservice: BankDetailsService,
    public panservice: PanCardService,
    public userdocumentservice: UserDocumentService,
    private route: ActivatedRoute,
    public userService: UserService,
    public pfservice: PfDetailsService, public userDetailService: UserDetailsService, public assetservice: AssetManagementService) { }

  ngOnInit(): void {
    this.assetcategories = AssetCategories
    // this.userid = this.userDetailService.getUserDetails().id
    this.userid = this.route.snapshot.paramMap.get('id');
    if (this.userid == null) {
      this.userid = this.userDetailService.getUserDetails().id;
    }

    // this.bankservice.getbankByUserIdSubscribe(this.userid).subscribe(res => {
    //   this.bankdto = res.body
    // })
    // this.pfservice.getpfByUserIdSubscribe(this.userid).subscribe(res => {
    //   this.pfdto = res.body
    // })
    // this.passportservice.getpassByUserIdSubscribe(this.userid).subscribe(res => {
    //   this.passpprtdto = res.body
    // })
    // this.panservice.getpanByUserIdSubscribe(this.userid).subscribe(res => {
    //   this.pandtoo = res.body
    // })

    // this.openpdf()
    this.getAccountDetails();

      this.assetservice.getAssetDetailsForEmployeeFromUserId(this.userid).subscribe(result =>{
        
       
          this.assetdto = result.body;
        })

  }

  getAccountDetails(){
    this.bankservice.getbankByUserIdSubscribe(this.userid).subscribe(res => {
      if(this.flag==false && res.body.id )
      {
      this.dummyfunc.emit(25);
      this.flag=true;
      }
      this.bankdto = res.body
    })
    this.pfservice.getpfByUserIdSubscribe(this.userid).subscribe(res => {
      this.pfdto = res.body
    })
    this.passportservice.getpassByUserIdSubscribe(this.userid).subscribe(res => {
      this.passpprtdto = res.body
    })
    this.panservice.getpanByUserIdSubscribe(this.userid).subscribe(res => {
      this.pandtoo = res.body
    })

    this.openpdf()
  }
  editAccountDetailsDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '62%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = "accountdetails-class";
    //dialogConfig.data=this.userdto;
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog.open(AccountDetailsComponent, dialogConfig)
    .afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          //this.ngOnInit();
          this.getAccountDetails();
        }
      });;
  }
  openpdf() {
    this.userdocumentservice
      .getuserdocumentbyuserId(
        'Pan',
        this.userDetailService.getUserDetails().id
      )
      .subscribe((res) => {
        this.pandocument = res.body
        this.panBase64 = this.pandocument.documentBase;
        this.panname = this.pandocument.documentContentType;
        if (this.pandocument.documentBase) {
          this.isPanSaved = true;
        }
      });
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }
}
