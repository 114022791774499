import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BulkEmployeeComponent } from '../bulk-employee/bulk-employee.component';

@Component({
  selector: 'app-bulk-summary',
  templateUrl: './bulk-summary.component.html',
  styleUrls: ['./bulk-summary.component.scss']
})
export class BulkSummaryComponent implements OnInit {

  public summaryContent:any;

  constructor(public dialogRef: MatDialogRef<BulkSummaryComponent>, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data:any) {
     
    }

  ngOnInit(): void {
    
  }

  closeDialog(): void {
    //Close mat-dialog and erase data upon clicking close button
    //this.data = null;
    this.dialogRef.close();
  }

}
