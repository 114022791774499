import { TypeOfLeave, ApprovalStatus, PostApprovalLeaveCancellationStatus} from "../services/leaveManagment/leave-enum";
import { CompOffLeaveDetails } from "./compOffLeaveDetails";

export class LeaveApplicationDTO {
    public id: string;
    public userId: string;
    public careerManagerId:String;
    public projectManagerId:String;
    public typeOfLeave:String;
    public startDate: Date;
    public endDate: Date;
    public startSession:Number;
    public endSession:Number;
    public transactionDateTime: number;
    public approvalStatus:ApprovalStatus;
    public approvedBy:String;
    public contactNumber:String;
    public reasonForLeave:String;
    public ccMentions:String[];
    public postApprovalLeaveCancellationStatus: PostApprovalLeaveCancellationStatus;
    public reasonForCancellation:String;
    // public compOfLeaveStartDate: Date;
    // public compOfLeaveEndDate: Date;
    // public compOfLeaveStartSession: number;
    // public compOfLeaveEndSession: number;
    // public compOffLeaveDetails:CompOffLeaveDetails[];
    // public isAdvancedLeaveApplied: number;
    public doc_base: string;

    constructor() {
        // this.id="temp";
        this.userId=undefined;
        this.careerManagerId=undefined;
        this.projectManagerId=undefined;
        this.typeOfLeave=undefined;
        this.startDate=undefined;
        this.endDate=undefined;
        this.startSession=undefined;
        this.endSession=undefined;
        this.transactionDateTime=undefined;
        this.approvalStatus=ApprovalStatus.PENDING;
        this.approvedBy="";
        this.contactNumber="";
        this.reasonForLeave="";
        this.ccMentions=[];
        this.postApprovalLeaveCancellationStatus=PostApprovalLeaveCancellationStatus.PENDING;
        this.reasonForCancellation="";
        this.doc_base=undefined;
        // this.compOfLeaveStartDate=undefined;
        // this.compOfLeaveEndDate=undefined;
        // this.compOfLeaveStartSession=undefined;
        // this.compOfLeaveEndSession=undefined;
        // this.compOffLeaveDetails= [];
        // this.isAdvancedLeaveApplied= 0.0;
        // this.advancedLeaveApplied=0.0;
    }

    // constructor() {
    //     this.id="";
    //     this.userId="";
    //     this.careerManagerId="";
    //     this.projectManagerId="";
    //     // this.typeOfLeave=undefined;
    //     // this.startDate=undefined;
    //     // this.endDate=undefined;
    //     // this.startSession=undefined;
    //     // this.endSession=undefined;
    //     // this.transactionDateTime=undefined;
    //     // this.approvalStatus=undefined;
    //     this.approvedBy="";
    //     this.contactNumber='';
    //     this.reasonForLeave="";
    //     this.ccMentions=[];
    //     // this.postApprovalLeaveCancellationStatus=undefined;
    //     this.reasonForCancellation='';
    //     // this.compOfLeaveStartDate=undefined;
    //     // this.compOfLeaveEndDate=undefined;
    //     // this.compOfLeaveStartSession=undefined;
    //     // this.compOfLeaveEndSession=undefined;
    //     this.compOffLeaveDetails= [];
    //     // this.isAdvancedLeaveApplied= undefined;
    // }
}