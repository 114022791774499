import { Component, Inject, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],

})
export class AboutComponent implements OnInit {


userDto=new UserDTO();
  public aboutDetails: FormGroup;
  userid: any;

  constructor(
    public toaster:ToastrService,
  public userService: UserService,
    public fb: FormBuilder,
    public toastr:ToastrService,
    private route: ActivatedRoute,
    public userDetailService:UserDetailsService,
    private dialogref: MatDialogRef<AboutComponent>,
    @Inject(MAT_DIALOG_DATA) public data){
    this.aboutDetails = this.fb.group({
      about: ['', [Validators.required]]

      
     
    })
  }



  ngOnInit(): void {


    this.userDto.id = this.data[1];
    this.userDto.about = this.data[0];

    this.setFormData();

    // this.userDto = this.userDetailService.getUserDetails().id;
    // this.userDto = this.userDetailService.getUserDetails();

    // this.userid = this.route.snapshot.paramMap.get('id');

    // if (this.userid == null){
    //   this.userid = this.userDetailService.getUserDetails().id;

    // }
    // else{

    // }

    // this.userService.getUserAbout(this.userid).subscribe(res => {
    //   this.userDto = res.body;
    //   this.setFormData();
    // })


  }

closeDialog(event:Event) {
   // this.dialogref.close();
    this.dialogref.close({ event: 'Closed'})
}
setFormData() {

  // if(this.data){
  //   this.aboutDetails.get('about').setValue(
  //     this.data.about?this.data.about:''
  //   )
  // }

    this.aboutDetails = this.fb.group({
      about: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9 !(),.":]+$/)]]
    });
  
  this.aboutDetails.get('about').setValue(
    this.userDto.about?this.userDto.about:''
  )
}
onSubmit(){
  if(this.aboutDetails.valid && (this.userDto.about !== this.aboutDetails.get('about').value))
  {
    this.userDto.about = this.aboutDetails.get('about').value;

        this.userService.setUserAbout(this.userDto.id, this.userDto.about).subscribe(() => {
          this.userDetailService.setUserAbout(this.userDto.about);
           this.toaster.success("Successfully Saved", '')
          this.dialogref.close({ event: 'Closed'})

        });

    }
}

}


