<div class="container">

    <div class="main_header_div">
        <div class="child" *ngIf="!this.data.isProbation">
            <label class="header_name"> Close annual performance review for {{this.user.firstName}}
                {{this.user.lastName}}</label>

        </div>
        <div class="child" *ngIf="this.data.isProbation">
            <label class="header_name"> Close Probation Feedback review for {{this.user.firstName}}
                {{this.user.lastName}}</label>

        </div>
        <div class="child_close_btn">
            <mat-icon class="closeButton" (click)=" matDialogClose()">close</mat-icon>
        </div>
    </div>
    <div class="section">
        <div class="to-cc">
            <div>
                To: {{this.user.email}}, {{this.cmemail}}, {{this.DLemail}}
            </div>
            <div class="cc">

                <mat-form-field class="example-chip-list" appearance="outline">

                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let ccemail of emails" [selectable]="selectable" [removable]="removable"
                            (removed)="remove(ccemail)">
                            {{ccemail}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Add Cc : " #EmailInput [formControl]="emailCtrl"
                            [matAutocomplete]="emailAuto" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur">
                    </mat-chip-list>
                    <mat-autocomplete #emailAuto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let ccemail of filteredEmails | async" [value]="ccemail">

                            {{ccemail}}

                        </mat-option>
                    </mat-autocomplete>

                </mat-form-field>




            </div>


        </div>
        <div class="note" *ngIf="!this.data.isProbation">
            The annual performance review will be closed and the summary will be shared with above recipients.<br>
            This action cannot be undone.<br>
            Are you sure to close annual performance review?
        </div>
        <div class="note" *ngIf="this.data.isProbation">
            The Probation Feedback review will be closed and the summary will be shared with above recipients.<br>
            This action cannot be undone.<br>
            Are you sure to close Probation review?
        </div>

        <div class="main_button_div">
            <button mat-button class="delete-button" (click)="closefeedback()">Close and Share Feedback</button>
        </div>
    </div>
</div>