import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../alerts.service';
@Injectable({
  providedIn: 'root'
})
export class BulkHolidaysImportService {
  public response:any;
  constructor(private restApi: RestAPIService) { 
    this.response = null;
  }

  uploadBulkHolidays(formData,year){
   return this.restApi.post(`/api/bulk-import-leaves/${year}`, formData);
  }
  
}