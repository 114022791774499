<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Certifications</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>

    </div>
</div>
<hr>
<div class="container-fluid">

    <div class="row">
        <div class="col-md-12 ">
            <div class="add_button_fix">
                <button class="add_button" (click)="addCertificate()">+Add</button>
                <button class="plus_button mt-4" (click)="addCertificate()"><span>+Add</span></button>
            </div>
            <form [formGroup]="editSpotlightDetails">

                <div class="row" formArrayName="uploadCertificates"
                    *ngFor="let cert of this.Certificate.controls; let i = index">
                    <div class="col-md-4" [formGroupName]="i">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Course Name</mat-label>
                            <input required type="text" matInput formControlName="documentName" autocomplete="off">
                            <mat-error *ngIf="editSpotlightDetails.get('uploadCertificates')['controls'][i].get('documentName').hasError('required')">
                                Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4" [formGroupName]="i">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Issue Date <span class="contentRed"></span></mat-label>
                            <input required [max]="today" matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy"
                                formControlName="dateOfIssue">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="editSpotlightDetails.get('uploadCertificates')['controls'][i].get('dateOfIssue').hasError('required')">
                                Required
                            </mat-error>
                            <!-- <mat-error *ngIf="editSpotlightDetails.get('uploadCertificates')['controls'][i].get('dateOfIssue').hasError('pattern')">
                                Invalid Date Format.
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                    <div class="col-md-4" [formGroupName]="i">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Valid Upto <span class="contentRed"></span></mat-label>
                            <input required [min]="getMinEndDate(i)" matInput [matDatepicker]="picker2" placeholder="mm/dd/yyyy"
                                formControlName="validUpto">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                            <mat-error *ngIf="editSpotlightDetails.get('uploadCertificates')['controls'][i].get('validUpto').hasError('required')">
                                Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 certificate" [formGroupName]="i">
                        <img class="img_size" src="assets/Certificate.png">

                        <label for="my_file" *ngIf="this.multiCertificates[i]==null" class="certificate_label">Attach
                            Certificate Copy</label>
                        <input type="file"
                            (click)="uploadCertificate(i, $event)"
                            *ngIf="this.multiCertificates[i]==null"
                            id="my_file" style="display: none;" multiple formControlName="docBase"
                            accept=".png , .jpg, .pdf" (change)="uploadCertificate(i, $event)" />


                        <ng-container *ngIf="this.multiCertificates[i]!=null;else elseBlock ">

                            <label for="my_file2" *ngIf="this.multiCertificates[i]=='undefined'" class="certificate_label">
                                No Certificate Attached
                            </label>
                            <input type="file"
                            (click)="uploadCertificate(i, $event)"
                            *ngIf="this.multiCertificates[i]=='undefined'"
                            id="my_file2" style="display: none;" multiple formControlName="docBase2"
                            accept=".png , .jpg, .pdf" (change)="uploadCertificate(i, $event)" />


                            <label *ngIf="this.multiCertificates[i] != 'undefined'" class="certificate_label"
                                    (click)="open(content10,i)">
                                    Preview Certificate Copy<span>{{cert.documentName}}</span>
                            </label>

                        </ng-container>
                        <ng-template #content10 let-modal>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="modal-body">
                                <pdf-viewer *ngIf="this.certificateType[i] == 'application/pdf'"
                                    [src]="this.multiCertificates[i]" [rotation]="0" [fit-to-page]="false"
                                    [original-size]="false" [show-all]="true">
                                </pdf-viewer>
                                <img *ngIf="this.certificateType[i] == 'image/png' || this.certificateType[i] == 'image/jpeg'"
                                    class="preview-img" [src]="this.multiCertificates[i]">
                            </div>
                        </ng-template>
                        <ng-template #elseBlock>

                        </ng-template>
                    </div>
                    <div class="col-md-3 mt-2">
                        <button class="remove_label" (click)="removecertificate(cert.value.id,i)">Remove</button>

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>
                </div>



            </form>
        </div>
    </div>
</div>
