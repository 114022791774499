import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { LeaveApplicationUrlService } from '../restAPI/leaveApplicationURLs/leaveApplication-url.service';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';
@Injectable({
  providedIn: 'root'
})
export class LeaveApplicationService {
 
  constructor(private restApiService: RestAPIService,
    private leaveurl : LeaveApplicationUrlService
  ) { }

  create(payload:LeaveApplicationDTO,taskId?:string) {
    //
    // if(taskId){
    //   return this.restApiService.post(this.leaveurl.getBaseUrl()+"/"+taskId,payload)
    // }
    if(taskId != null){
      return this.restApiService.post(this.leaveurl.getApplyLeaveFromTaskUrl(taskId),payload);
    }

    return this.restApiService.post(this.leaveurl.getApplyLeaveUrl(),payload);
    
    // return this.restApiService.post(
    //  this.leaveurl.getBaseUrl() + '/' + taskId,
    //  payload
  //  )
 }
  createRestricted(payload:LeaveApplicationDTO) {
    //
    // if(taskId){
    //   return this.restApiService.post(this.leaveurl.getBaseUrl()+"/"+taskId,payload)
    // }
    return this.restApiService.post(
     this.leaveurl.getLeaveApplicationUrl(),
     payload
   )
 }
 
  update(payload){
    return this.restApiService.put(
      this.leaveurl.getBaseUrl(),
      payload
    ) 
  }
  
  delete(id: any) {
    this.restApiService.delete(
      this.leaveurl.getLeaveApplicationByIdUrl(id)
    ).subscribe(res=>{
      
    })
  }

  getAllLeaveApplication(){
    return this.restApiService.get(
      this.leaveurl.getAllLeaveApplicationByUserId()
    )
  }

  getDetaildLeave(typeOfLeave,year,userId){
    return this.restApiService.get(
      this.leaveurl.getDetailLeave(typeOfLeave,year,userId)
    )
  }

  revokeLeaveApplicationbyID(payload){
    return this.restApiService.put(
      this.leaveurl.revokeLeaveApplication(),payload
    )
  }

  getLeaveTotalCount(payload:LeaveApplicationDTO){
    return this.restApiService.post(
      "/api/leave_count",
      payload
    )
  }

  editLeaveApplication(payload,leaveApplicationId){
    return this.restApiService.put(
      this.leaveurl.editLeaveApplication(leaveApplicationId),payload
    )
  }

}
