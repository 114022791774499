import { Injectable } from '@angular/core';
import { Family } from 'src/app/models/familydetails';
import { FamilyDetailsUrlService } from '../restAPI/familyDetailsURLs/familydetails-url.service';

import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class FamilyDetailsService {

  constructor(private restApiService: RestAPIService,
    private familyurl : FamilyDetailsUrlService) { }


    create(payload) {
      return this.restApiService.post(
        this.familyurl.getBaseUrl(),
        payload
      )
    }
  
    update(payload){
      return this.restApiService.put(
        this.familyurl.getBaseUrl(),
        payload
      )
    }

    getfamilydetailbyuserIdSubscribe(userId) {
      return this.restApiService.get(
        this.familyurl.getUserUrl(userId)
      )
    }
  
    setFamilyResponse(response):Family[]{
 
      let familyList:Family[] = []
      for(let data of response){
          let family:Family = new Family()
            familyList.push(
            family.bind(data)          
          )
      }
  
      return familyList
    }
  
    delete(id: any) {
      this.restApiService.delete(
        this.familyurl.getByFieldUrl(id)
      ).subscribe(res=>{

      })
    }
  
  

  


}
