import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';

@Component({
  selector: 'app-revoke-leave-application',
  templateUrl: './revoke-leave-application.component.html',
  styleUrls: ['./revoke-leave-application.component.scss']
})

export class RevokeLeaveApplicationComponent implements OnInit {

  rejectionDetails: FormGroup;
  userId: any;
  leaveApplications: any = new LeaveApplicationDTO;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RevokeLeaveApplicationComponent>,
    private leaveApplicationService: LeaveApplicationService,
    private userDetailsService: UserDetailsService
  ) {
    this.rejectionDetails = new FormGroup({
      reasonForRejection: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.userId = this.userDetailsService.getUserDetails().id;
    console.log(this.userId)
  }

  revokeLeave() {
    const reason = this.rejectionDetails.get('reasonForRejection').value;
    this.leaveApplicationService.revokeLeaveApplicationbyID(this.data.leaveDetail.id).subscribe((res) => {
      this.closeDialog();
    }, () => {
      this.closeDialog();
    })
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}