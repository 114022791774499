import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BankDetailsDTO } from 'src/app/dtos/BankDetailsDTO';
import { PanCardDTO } from 'src/app/dtos/panCardDTO';
import { PfDetailsDTO } from 'src/app/dtos/PfDetailsDTO';
import { UserDocumentDTO } from 'src/app/dtos/UserDocumentDTO';
import { User } from 'src/app/models/user';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { PanCardService } from 'src/app/services/pan-card/pan-card.service';
import { PfDetailsService } from 'src/app/services/pf-details/pf-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserDocumentService } from 'src/app/services/user-document.service';
import { UserService } from 'src/app/services/user/user.service';
import { EditProfilesComponent } from '../edit-profiles.component';

@Component({
  selector: 'app-tab4-vault',
  templateUrl: './tab4-vault.component.html',
  styleUrls: ['./tab4-vault.component.scss'],
})
export class Tab4VaultComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  editVaultDetails: FormGroup;
  pfdtoo = new PfDetailsDTO();
  pfdto = new PfDetailsDTO();
  pandto = new PanCardDTO();
  bankdtoo = new BankDetailsDTO();
  pandtoo = new PanCardDTO();
  bankdto = new BankDetailsDTO();
  binary;
  pandocument = new UserDocumentDTO();
  panDocumentType: string;
  panBase64: string;
  imageError: string;
  isPanSaved: boolean;
  panname: string;
  temp: string;

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<EditProfilesComponent>,
    public userDetailService: UserDetailsService,
    public userService: UserService,
    public pfservice: PfDetailsService,
    public bankservice: BankDetailsService,
    public panservice: PanCardService,
    public toastr: ToastrService,
    public userdocumentservice: UserDocumentService,
    private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {
    this.editVaultDetails = this.fb.group({
      accountNumer: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{9,18}'),
        ]),
      ],
      bankName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z ]*$'),
        ]),
      ],
      ifsc: ['', [Validators.required]],
      pfNumber: ['', Validators.compose([Validators.required])],
      uanNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{12}'),
        ]),
      ],
      pancardNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.setFormData();
    this.processEnumValuesToArray();
    this.userdocumentservice
      .getuserdocumentbyuserId('Pan', this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.pandocument = res.body;
        this.panBase64 = this.pandocument.documentBase;
        this.panname = this.pandocument.documentContentType;
        if (this.pandocument.documentBase) {
          this.isPanSaved = true;
        }
      });
    this.pfservice
      .getpfByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.pfdtoo = res.body;
        this.editVaultDetails.get('pfNumber').setValue(this.pfdtoo.pfNumber);
        this.editVaultDetails.get('uanNumber').setValue(this.pfdtoo.uanNumber);
      });
  }

  checkError = (controlName: string, errorName: string) => {
    return this.editVaultDetails.controls[controlName].hasError(errorName);
  };

  processEnumValuesToArray() {}

  setFormData() {
    this.bankservice
      .getbankByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.isPanSaved = !!res.body.docBase;
        this.temp = atob(res.body.docBase);
        this.panDocumentType = this.findDocumentType(this.temp);
        this.bankdtoo = res.body;
        this.editVaultDetails.get('accountNumer').setValue(this.bankdtoo.accountNumer);
        this.editVaultDetails.get('bankName').setValue(this.bankdtoo.bankName);
        this.editVaultDetails.get('accountType').setValue(this.bankdtoo.accountType);
        this.editVaultDetails.get('ifsc').setValue(this.bankdtoo.ifsc);
      });

    this.panservice
      .getpanByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.pandtoo = res.body;
        this.editVaultDetails.get('pancardNumber').setValue(this.pandtoo.pancardNumber);
      });
  }

  findDocumentType(temp: string): string {
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
    for (const type of allowed_types) {
      if (temp.indexOf(type) !== -1) {
        return type;
      }
    }
    return null;
  }

  uploadpan(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      this.panname = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgBase64Path: String = e.target.result;
        let imageContent = imgBase64Path.split(',')[1];
        let imageMetaData = imgBase64Path.split(',')[0];
        let imageContentType = imageMetaData.substring(
          imageMetaData.lastIndexOf(':') + 1,
          imageMetaData.lastIndexOf(';')
        );

        this.panBase64 = imageMetaData + ',' + imageContent;
        this.temp = this.panBase64;
        this.panDocumentType = imageContentType;
        this.binary = btoa(this.panBase64);
        this.isPanSaved = true;
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  removepan() {
    this.isPanSaved = false;
    this.panBase64 = null;
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  onSubmit() {
    if (this.editVaultDetails.valid) {
      const observables = [];

      this.bankdto.accountNumer = this.editVaultDetails.get('accountNumer').value;
      this.bankdto.bankName = this.editVaultDetails.get('bankName').value;
      this.bankdto.ifsc = this.editVaultDetails.get('ifsc').value;
      this.bankdto.userId = this.userDetailService.getUserDetails().id;
      this.bankdto.accountType = this.editVaultDetails.get('accountType').value;
      this.bankdto.docBase = this.binary;
      if (!this.bankdtoo.id) {
        this.bankdto.userId = this.userDetailService.getUserDetails().id;
        observables.push(this.bankservice.create(this.bankdto));
      } else {
        this.bankdto.id = this.bankdtoo.id;
        this.bankdto.userId = this.userDetailService.getUserDetails().id;
        observables.push(this.bankservice.update(this.bankdto));
      }

      this.pfdto.pfNumber = this.editVaultDetails.get('pfNumber').value;
      this.pfdto.uanNumber = this.editVaultDetails.get('uanNumber').value;
      this.pfdto.userId = this.userDetailService.getUserDetails().id;
      if (!this.pfdtoo.id) {
        this.pfdto.userId = this.userDetailService.getUserDetails().id;
        observables.push(this.pfservice.create(this.pfdto));
      } else {
        this.pfdto.userId = this.userDetailService.getUserDetails().id;
        this.pfdto.id = this.pfdtoo.id;
        observables.push(this.pfservice.update(this.pfdto));
      }

      const pandto = new PanCardDTO();
      pandto.pancardNumber = this.editVaultDetails.get('pancardNumber').value;
      pandto.userId = this.userDetailService.getUserDetails().id;
      if (!this.pandtoo.id) {
        pandto.userId = this.userDetailService.getUserDetails().id;
        observables.push(this.panservice.create(pandto));
      } else {
        pandto.id = this.pandtoo.id;
        observables.push(this.panservice.update(pandto));
      }

      forkJoin(observables)
        .subscribe(() => {
          this.toastr.success('Successfully Uploaded', 'Saved');
          this.dialogRef.close({ event: 'Closed' });
        });
    }
  }

  removeskill() {
    this.isPanSaved = false;
    this.panBase64 = '';
  }
}
