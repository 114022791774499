import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { NewFeedback } from 'src/app/models/feedbacknew';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { JsonFormComponent } from '../../json-form/json-form.component';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { FeedbackNotesComponent } from '../feedback-notes/feedback-notes.component';
import { CmGoalSheetComponent } from '../goal-sheet/cm-goal-sheet/cm-goal-sheet.component';
import { TooltipPosition } from '@angular/material/tooltip';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FeedbackClosureComponent } from '../feedback-closure/feedback-closure.component';
import { FeedbackClosureNoteComponent } from '../feedback-closure-note/feedback-closure-note.component';
import { FeedbackNotesService } from 'src/app/services/feedback-notes/feedback-notes.service';
import { PreviewFeedbackComponent } from '../preview-feedback/preview-feedback.component';
import { ProbationDialogComponent } from '../probation-dialog/probation-dialog/probation-dialog.component';
import { ProbationFeedbackClosureComponent } from '../probation-feedback-closure/porbation-feedback-closure/probation-feedback-closure.component';
import { NewProbationFeedbackGroupServiceService } from 'src/app/services/probation-ending/new-probation-feedback-group-service.service';
import { ProbationJsonFormComponent } from '../../json-form/probation-json-form/probation-json-form/probation-json-form.component';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
import * as ExcelJS from 'exceljs';
@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss']
})
export class ProfileDetailComponent implements OnInit {

  selected
  calibratedScoreForm: FormGroup;
  selectedYear: number;
  directReporteeId
  employee: any;
  feedbackgroupdata = []
  realTimefeedbackgroupdata = []
  probationFeedbackGroupdata = []
  years: number[] = [];
  Error: string
  opiniondata: FormArray;
  weightedScore
  opendiv = false;
  openProbationdiv = false;
  closedate = []
  scoreSum = 0;
  probationScoreSum = 0;
  userIdNameMapping = {}
  reg = new RegExp('^[0-9]$');
  projectIDMapping = {};
  invalidPercentage: boolean = false
  weightArray = []
  getScreenWidth: any;
  getScreenHeight: any;
  userdto: any;
  user: UserDTO
  feedbackarr: FormArray;
  mobileview: boolean = false
  hide: boolean = true;
  hideProbation: boolean = true;
  hideRealTime: boolean = true;
  userid;
  year;
  temp: any;
  disablefeedbackForm: boolean = false
  disableProbationfeedbackForm: boolean = false
  projectName: any;
  actualscorediv = [];
  reminderStatus: Boolean[] = [];
  weightSum = 0;
  weitatageScore = [];
  isOpen: any = false;
  isProbationOpen: any = false;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  isHideConfigureFeedbackBtn = true;
  selectIndexForTab: Number;
  isFeedbackReviewMeetingNoteVisible = false;
  switchedTab: any;
  @ViewChild(CmGoalSheetComponent) cmGoalSheetComponent: CmGoalSheetComponent;
  ifManager: any = false;
  feedbackdto: any;
  feedbackClosureNotes: any;
  userFeedbackDTO: any;
  excelArray = [];
  excelArrayOrphan = [];
  cmid: any;
  cmDetails: any;
  reportingManager: any;
  reviewerName: any;
  ifHR: any = false;
  showHideBtn: boolean = false;
  isRealtimeScore: boolean = false;
  isHideProbationFeedbackBtn: boolean = false;
  probationScore: any;
  hideYear: boolean;
  hideConfigureProbation: boolean = false;
  isRedirected: boolean = false;
  probationCloseYear: number = new Date().getFullYear();
  probationTabVisible: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public fb: FormBuilder,
    public toastr: ToastrService,
    private projectService: ProjectService,
    public feedbackgroupservice: NewFeedbackGroupService,
    private feedbackService: FeedbackService,
    private feedbackgroup: NewFeedbackGroupService,
    private probationFeedbackGroup: NewProbationFeedbackGroupServiceService,
    private userservice: UserService,
    public userDetailService: UserDetailsService,
    public feedbackNotesService: FeedbackNotesService,
    public usergoalService: UserGoalService) {
    this.calibratedScoreForm = this.fb.group({
      array: this.fb.array([
        this.fb.group(new NewFeedback())
      ])
    })
    this.feedbackgroup.getRefresh().subscribe(res => {
      if (res == "refresh") {
        this.ngOnInit();
      }
    })
  }

  ngOnInit(): void {
    this.reminderStatus.length = 0;
    this.ifManager = this.userDetailService.hasRole(['ROLE_CAREER_MANAGER']);
    this.ifHR = this.userDetailService.hasRole(['ROLE_HUMAN_RESOURCE']);
    // this.userservice.getUserByID(this.route.snapshot.paramMap.get('id')).subscribe(res=>{
    //   // this.probationTabVisisble=true;

    // })
    const today = new Date();
    this.userservice.getConfirmationDate(this.route.snapshot.paramMap.get('id')).subscribe((res) => {
      const joiningDate = new Date(res.body.joiningDate);
      const confirmationDate = new Date(res.body.confirmationDate);
      if (today >= joiningDate && today <= confirmationDate) {
        this.probationTabVisible = true;
      } else {
        this.probationTabVisible = false;
      }

      this.feedbackService.getIndex().subscribe(res => {
        this.selectIndexForTab = res;
        if (res == 3) {
          this.probationTabVisible = true;
        }
      })
    })

    // this.userservice.getUserByID(this.route.snapshot.paramMap.get('id')).subscribe(res => {
    //   const joiningDate = new Date(res.body.joiningDate);
    //   const confirmationDate = new Date(res.body.confirmationDate);

    //   if (today >= joiningDate && today <= confirmationDate) {
    //     this.probationTabVisible = true;
    //   } else {
    //     this.probationTabVisible = false;
    //   }
    // });


    this.feedbackService.getIndex().subscribe(res => {
      this.selectIndexForTab = res;
      if (res == 3) {
        this.hideYear = true;
        this.isRedirected = true;
        this.getProbationFeedbackGroup(2);
        this.switchedTab = 'PROBATION';
        let redirectedData = this.feedbackService.getObjectForProbation();
        this.probationScore = redirectedData.overallScore;
        this.probationCloseYear = redirectedData.year;
      }
    })
    this.usergoalService.getCurrentYear().subscribe(res => {
      this.selected = res;
    })
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth < 1023) {
      this.mobileview = true
    }
    if (this.getScreenWidth > 1023) {
      this.mobileview = false
    }
    this.temp = 0;
    this.usergoalService.changeYear(new Date().getFullYear().toString());
    this.getprojectList();

    this.getuserList();
    this.directReporteeId = this.route.snapshot.paramMap.get('id');
    this.getdirectreporteedetails()
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2022; year--) {
      this.years.push(year);
    }
    this.userid = this.directReporteeId;
    this.isfeedbackReviewMeetingNotesPresent();
    this.getfeedbackgroup();
    this.getProbationFeedbackGroup(2);
    this.getoverallstaus();
    this.getoverallstausforProbation();
    this.setFormData();
    this.getQuestionsRatingAdhoc();
    this.getQuestionsRatingOrphan();
  }

  setFormData() {

    this.feedbackNotesService.getFeedbackSummary(this.directReporteeId, this.selected).subscribe(res => {

      this.feedbackdto = res.body
      this.feedbackClosureNotes = this.feedbackdto.feedbackSummary
      //  this.feedbackNotes.get('note').setValue();

    })
  }
  getoverallstaus() {

    this.disablefeedbackForm = false
    this.feedbackgroup.getFeedbackOverallstatus(this.directReporteeId, this.selected).subscribe(res => {


      if (res.body == true) {
        this.disablefeedbackForm = true
        this.closedate.push(this.selected)
      }
    })
  }
  getoverallstausforProbation() {

    this.disableProbationfeedbackForm = false
    this.feedbackgroup.getProbationFeedbackOverallstatus(this.directReporteeId, this.selected).subscribe(res => {

      if (res.body == true) {

        this.disableProbationfeedbackForm = true
        this.closedate.push(this.selected)
      }
    })
  }

  changeToProbation(switchedTab) {
    //console.log(switchedTab);
    this.switchedTab = switchedTab;
    this.hideYear = false;
    this.hideConfigureProbation = false
    this.userdto.totalScore = 0;
    if (switchedTab == 'PROBATION') {
      this.hideYear = true;
      this.probationFeedbackGroup.getfeedbackbyuser(this.directReporteeId).subscribe(res => {
        this.userdto.totalScore = res.body.overallScore;
        this.probationScore = res.body.overallScore;
        this.hideConfigureProbation = true;
      })
    } else if (switchedTab == 'FEEDBACK') {
      this.feedbackgroup.getdirectreporteebyyear(this.selected).subscribe(res => {
        for (let i of res.body)
          if (this.directReporteeId == i.userId) {
            this.userdto = i
          }
        if (this.userdto == null) {
          this.feedbackgroupservice.getdirectreporteebyyearforHr(this.selected).subscribe(res => {
            for (let i of res.body)
              if (this.directReporteeId == i.userId) {
                this.userdto = i
              }
          });
        }
      });
    }
    else if (switchedTab == 'REAL_TIME') {
      this.switchedTab = switchedTab
    }
  }

  getdirectreporteedetails() {
    this.userdto = null
    this.feedbackgroup.getdirectreporteebyyear(this.selected).subscribe(res => {

      for (let i of res.body)
        if (this.directReporteeId == i.userId) {
          this.userdto = i
        }
      if (this.userdto == null) {
        this.feedbackgroupservice.getdirectreporteebyyearforHr(this.selected).subscribe(res => {

          for (let i of res.body)
            if (this.directReporteeId == i.userId) {
              this.userdto = i
            }
        });
      }
    });
  }
  getfeedbackgroup() {

    this.temp = 0;
    this.feedbackgroupdata.length = 0
    this.realTimefeedbackgroupdata.length = 0
    this.weightArray.length = 0
    this.feedbackgroup.getfeedbackbyuserandyear(this.directReporteeId, this.selected).subscribe(res => {
      for (let i of res.body) {
        if (i.feedbackType != 'ORPHAN') {
          this.feedbackgroupdata.push(i);
          let tempGroupWeightage=Number(i.groupWeightage.toFixed(3));

          this.temp = this.temp + tempGroupWeightage * 100
          this.weightArray.push(i.groupWeightage);
          this.scoreSum = i.overallScore;
        } else {
          this.addToRealtimeData(i);
        }
        // else if(i.feedbackFrom[0].assigned){
        //   this.feedbackgroupdata.push(i);

        // }

      }
      //console.log(this.realTimefeedbackgroupdata);
    })
  }
  addToRealtimeData(feedback) {
    this.userservice.getUserByID(feedback.createdBy).subscribe(res => {
      //console.log(res.body);
      feedback.createdByName = res.body.firstName + " " + res.body.lastName;
      feedback.userImageThumbnail = res.body.userImageThumbnail;
      feedback.feedbackRole = feedback.feedbackFrom[0].feedbackRole;
      if (feedback.feedbackFrom[0].feedbackSubmissionStatus == 'SUBMITTED') {
        this.realTimefeedbackgroupdata.push(feedback);
      }

    })
  }


  getProbationFeedbackGroup(index) {

    this.probationFeedbackGroupdata.length = 0
    this.probationFeedbackGroup.getfeedbackbyuser(this.directReporteeId).subscribe(res => {
      this.probationFeedbackGroupdata = res.body;
      this.probationScoreSum = res.body.overallScore;
      this.probationCloseYear = res.body.year;

    })
  }

  isfeedbackReviewMeetingNotesPresent() {
    this.feedbackNotesService.getFeedbackSummary(this.directReporteeId, this.selected).subscribe(res => {
      if (res.body.feedbackSummary !== '') {
        this.isFeedbackReviewMeetingNoteVisible = true;
      }
    })
  }
  backToProject() {
    this.usergoalService.changeYear(new Date().getFullYear().toString());
    this.router.navigate(['careerManager']);
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth < 1023) {
      this.mobileview = true
    }
    if (this.getScreenWidth > 1023) {
      this.mobileview = false
    }
  }
  getprojectList() {
    this.projectService.getProjectsSubscribe().subscribe(proList => {
      proList.body.forEach(element => {
        this.projectIDMapping[element.id] = element.projectName;
      });
    });
  }
  getProjectID(projectid) {
    this.projectName = this.projectIDMapping[projectid];
    return this.projectName
  }
  getuserList() {

    this.userservice.getAllUserForDropDown().subscribe(usrData => {
      usrData.body.forEach(element => {
        this.userIdNameMapping[element.userID] = element.firstName + " " + element.lastName
      });
    });
  }
  onOpinionWeightagetype(i) {

    let temp = 0
    for (let feedback of this.calibratedScoreForm.value.array) {
      temp = temp + parseInt(feedback.weightage)
      if (temp > 100) {
        this.invalidPercentage = true
        this.setOpinionFormControlError('weightage', 'InvalidWeightage', 'Enter Valid Percentage', i, true);
      }
    }
    let t = 0;
    for (let feedback of this.calibratedScoreForm.value.array) {
      t = t + parseInt(feedback.weightage)
    }
    if (t <= 100) {
      this.weitatageScore[i] = (this.calibratedScoreForm.value.array[i].calibratedScore * this.calibratedScoreForm.value.array[i].weightage) / 100
      this.invalidPercentage = true
      this.setOpinionFormControlError('weightage', 'InvalidWeightage', 'Enter Valid Percentage', i, false);
    }
  }
  setOpinionFormControlError(controlName: string, controlError: string, errorMsg: string, i: number, flag) {
    this.feedbackarr = this.calibratedScoreForm.get('array') as FormArray;
    if (flag) {
      this.feedbackarr.controls[i].get(controlName).markAsTouched({ onlySelf: true });
      this.feedbackarr.controls[i].get(controlName).markAsDirty({ onlySelf: true });
      this.feedbackarr.controls[i].get(controlName).setErrors({ [controlError]: true });
      this.Error = errorMsg;
    }
    else {
      let j = 0;
      for (let feedback of this.calibratedScoreForm.value.array) {
        if (true) {
          this.feedbackarr.controls[j].get(controlName).markAsTouched({ onlySelf: false });
          this.feedbackarr.controls[j].get(controlName).markAsDirty({ onlySelf: false });
          this.feedbackarr.controls[j].get(controlName).setErrors({ [controlError]: false });
        }
        j++;
        this.Error = null;
      }
      this.calibratedScoreForm.status;
    }
  }
  onOpinionWeightagetype1(i) {

    this.weitatageScore[i] = (this.calibratedScoreForm.value.array[i].calibratedScore * this.calibratedScoreForm.value.array[i].weightage) / 100;
  }
  open(data) {

    this.isOpen = false;
    this.opendiv = true;
    this.isProbationOpen = true;
    this.opiniondata = this.calibratedScoreForm.get('array') as FormArray;
    this.opiniondata.clear()
    let i = 0
    for (let s of data.feedbackFrom) {
      //console.log(s)
      if (s.feedbackFrom.length == 24 && this.reg.test(s.feedbackFrom[0])) {

        s.feedbackFrom = this.userIdNameMapping[s.feedbackFrom]
      }
      s.weightage = Math.floor(s.weightage * 10000) / 100;
      this.weitatageScore[i] = (s.calibratedScore * s.weightage) / 100
      if (s.score > 0) {
        this.actualscorediv[i] = true
      }
      else {
        this.actualscorediv[i] = false
      }
      if (s.feedbackRole == "REVERSE_PROBATION_FEEDBACK" && s.feedbackSubmissionStatus == 'SUBMITTED') {
        this.actualscorediv[i] = true;
      }
      if (s.remindedAt !== null) {
        let reviewerName = (new Date()).toString();
        let diffInMs = Date.parse(reviewerName) - Date.parse(s.remindedAt);
        const diffInHours = diffInMs / 1000 / 60 / 60;

        if (diffInHours > 24) {
          this.reminderStatus.push(false);
        }
        else {
          this.reminderStatus.push(true);
        }
      }
      else {
        this.reminderStatus.push(false);

      }
      this.opiniondata.push(
        this.fb.group(s)
      )

      this.weightSum += s.weightage;
      i++;
    }
  }
  showQuestionBtn(data) {
    for (let s of data.feedbackFrom) {
      if (s.score > 0) {
        this.showHideBtn = true;
        // this.showHide(true);
      }
    }
  }
  close(data) {

    this.weightSum = 0
    this.opendiv = false
    if (!this.isOpen) {
      this.temp = 0;
      this.ngOnInit();
    }
  }
  closeProbationDiv(data) {
    this.openProbationdiv = false
    if (!this.isProbationOpen) {
      // this.temp = 0;
      this.ngOnInit();
    }
  }
  async view(f: any) {
    //console.log(this.opinion().controls)
    if (f.controls.feedbackSubmissionStatus.value == 'SUBMITTED') {
      await this.feedbackService.getFeedBackByIdAsync(f.controls.id.value);
      var resOfFeedBackGroupAfterParse = await this.feedbackService.resOfFeedBackGroupAfterParse;
      var timeFrame = resOfFeedBackGroupAfterParse.year;
      await this.userservice.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
      var assinedUserAfterParse = await this.userservice.assinedUserAfterParse;
      var assinedManagerUseAfterParse = await this.userservice.assinedManagerUseAfterParse;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = [f.controls.submission.value, false, assinedUserAfterParse.firstName + " " + assinedUserAfterParse.lastName, false, assinedManagerUseAfterParse.firstName + " " + assinedManagerUseAfterParse.lastName, timeFrame];
      dialogConfig.panelClass = "feedbackform-overlay";
      const dialogRef = this.dialog.open(JsonFormComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else {
      this.toastr.error("Feedback Not Submitted")
    }
  }


  async viewRealTime(f: any) {
    //console.log(this.opinion().controls)

    if (f.feedbackFrom[0].feedbackSubmissionStatus == 'SUBMITTED') {
      await this.feedbackService.getFeedBackByIdAsync(f.feedbackFrom[0].id);
      var resOfFeedBackGroupAfterParse = await this.feedbackService.resOfFeedBackGroupAfterParse;
      var timeFrame = resOfFeedBackGroupAfterParse.year;
      await this.userservice.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
      var assinedUserAfterParse = await this.userservice.assinedUserAfterParse;
      var assinedManagerUseAfterParse = await this.userservice.assinedManagerUseAfterParse;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = [f.feedbackFrom[0].submission, false, assinedUserAfterParse.firstName + " " + assinedUserAfterParse.lastName, false, assinedManagerUseAfterParse.firstName + " " + assinedManagerUseAfterParse.lastName, timeFrame];
      dialogConfig.panelClass = "feedbackform-overlay";
      const dialogRef = this.dialog.open(JsonFormComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else {
      this.toastr.error("Feedback Not Submitted")
    }
  }
  async viewProbation(f: any) {
    if (f.controls.feedbackSubmissionStatus.value == 'SUBMITTED') {
      await this.feedbackService.getProbationFeedBackByIdAsync(f.controls.id.value);
      var resOfProbationFeedBackGroupAfterParse = await this.feedbackService.resOfProbationFeedBackGroupAfterParse;
      var timeFrame = resOfProbationFeedBackGroupAfterParse.year;
      await this.userservice.getUserByIdAsync(resOfProbationFeedBackGroupAfterParse.feedbackFor);
      var assinedUserAfterParse = await this.userservice.assinedUserAfterParse;
      var assinedManagerUseAfterParse = await this.userservice.assinedManagerUseAfterParse;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = "auto";
      dialogConfig.height = "auto";
      dialogConfig.data = [f.controls.submission.value, false, assinedUserAfterParse.firstName + " " + assinedUserAfterParse.lastName, false, assinedManagerUseAfterParse.firstName + " " + assinedManagerUseAfterParse.lastName, timeFrame, f.value.score];
      dialogConfig.panelClass = "feedbackform-overlay";

      if (f.value.feedbackRole == 'REVERSE_PROBATION_FEEDBACK') {
        const dialogRef = this.dialog.open(JsonFormComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
        });
      } else {
        const dialogRef = this.dialog.open(ProbationJsonFormComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
        });
      }

    }
    else {
      this.toastr.error("Feedback Not Submitted")
    }
  }
  loadForm(event) {
    this.usergoalService.setCurrentYear(event.value);
    this.usergoalService.changeYear(event.value);
    this.cmGoalSheetComponent.getgoalStatus();
    this.disablefeedbackForm = false
    this.getoverallstaus()
    this.feedbackgroupdata.length = 0;
    this.realTimefeedbackgroupdata.length = 0;
    this.getfeedbackgroup();
    this.getdirectreporteedetails();
    this.probationFeedbackGroupdata = [];
    this.getProbationFeedbackGroup(2);
    this.getQuestionsRatingOrphan();
    this.getQuestionsRatingAdhoc();
  }
  openFeedbackDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90%";
    dialogConfig.height = "60%";
    dialogConfig.data = {
      directReporteeId: this.directReporteeId,
      weightArray: this.weightArray,
      closedate: this.closedate,
      directReporteeDetail: this.userdto
    }
    const dialogRef = this.dialog.open(FeedbackDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getfeedbackgroup()
      this.getdirectreporteedetails()
    });
  }

  openProbationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.height = "60%";
    dialogConfig.data = {
      directReporteeId: this.directReporteeId,
      weightArray: this.weightArray,
      closedate: this.closedate,
      directReporteeDetail: this.userdto
    }
    const dialogRef = this.dialog.open(ProbationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getProbationFeedbackGroup(2);
      this.ngOnInit();
      this.getdirectreporteedetails();
    }, () => { });
  }

  EditFeedbackNotes() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      directReporteeId: this.directReporteeId,
      selected: this.selected,
      switchedTab: this.switchedTab
    }
    const dialogRef = this.dialog.open(FeedbackNotesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  editFeedbackMeetingNotes() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      directReporteeId: this.directReporteeId,
      selected: this.selected
    }
    const dialogRef = this.dialog.open(FeedbackClosureNoteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  EditFeedbackDialog(dataFeedback) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90%";
    dialogConfig.height = "60%";
    dialogConfig.data = {
      weightArray: this.weightArray,
      dataFeedback: dataFeedback,
      directReporteeId: this.directReporteeId,
      closedate: this.closedate,
      directReporteeDetail: this.userdto
    }
    const dialogRef = this.dialog.open(FeedbackDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getfeedbackgroup()
      this.getdirectreporteedetails()
      this.weightSum = 0
    });
  }

  EditProbationDialog(dataFeedback) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.height = "60%";
    dialogConfig.data = {
      weightArray: this.weightArray,
      dataFeedback: dataFeedback,
      directReporteeId: this.directReporteeId,
      closedate: this.closedate,
      directReporteeDetail: this.userdto
    }
    const dialogRef = this.dialog.open(ProbationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      this.getProbationFeedbackGroup(2);
      // this.ngOnInit();
      this.getdirectreporteedetails();
    }, () => { });
  }

  CloseFeedback() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      directReporteeId: this.directReporteeId,
      selected: this.selected,
      isclosed: this.disablefeedbackForm,
      userdto: this.userdto
    }
    const dialogRef = this.dialog.open(FeedbackClosureComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.weightSum = 0
      this.getoverallstaus()
      this.ngOnInit();
    });
  }

  //for probation
  closeProbationFeedback() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "80%";
    dialogConfig.data = {
      directReporteeId: this.directReporteeId,
      selected: this.selected,
      isclosed: this.disablefeedbackForm,
      userdto: this.userdto,
      score: this.probationScore
    }
    const dialogRef = this.dialog.open(ProbationFeedbackClosureComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      this.getoverallstaus()
      this.ngOnInit();
    }, () => {
      this.toastr.success('Did not closed', '', {
      });
    });
  }


  opinion(): FormArray {
    return this.calibratedScoreForm.get('array') as FormArray;
  }
  onSubmit() {
    this.scoreSum = 0;
    this.weitatageScore.length = 0; let j = 0;
    for (let feedback of this.calibratedScoreForm.value.array) {
      this.weitatageScore[j] = (feedback.calibratedScore * feedback.weightage) / 100;
      this.weitatageScore[j] = Math.round(this.weitatageScore[j] * 10) / 10;
      j++;
    }
    for (let i = 0; i < this.weitatageScore.length; i++) {
      this.scoreSum += this.weitatageScore[i];
    }
    for (let feedback of this.calibratedScoreForm.value.array) {
      feedback.weightage = feedback.weightage / 100;
    }
    this.scoreSum = Math.round(this.scoreSum * 10) / 10;

    try {

      let temp = 0;
      for (let feedback of this.calibratedScoreForm.value.array) {
        let x = Math.round((100 * (parseFloat(feedback.weightage))))
        temp = temp + x;
      }
      if (temp <= 100) {
        this.feedbackgroup.updateCalibratedScore(this.calibratedScoreForm.value.array[0].groupId, this.calibratedScoreForm.value.array).subscribe(res => {
          this.weightSum = 0
          this.temp = 0;
          this.ngOnInit();
          this.toastr.success('Successfully Uploaded', 'Saved', {
          });
        })
      }
      else {
        this.temp = 0;
        this.toastr.error('Enter Correct Percentage', 'Failed', {

        });
      }
    }
    catch (e) {
      this.temp = 0;
      this.toastr.error('Enter Correct Percentage', 'Failed', {

      });
    }


  }
  onTabChanged(e: any) {
    if (e.index == 0) {
      this.isHideConfigureFeedbackBtn = true;
      this.changeToProbation('GOAL');
    }
    else if (e.index == 1) {
      this.isHideConfigureFeedbackBtn = false;
      this.isHideProbationFeedbackBtn = false;
      this.changeToProbation('FEEDBACK');
    }
    if (e.index == 3) {
      this.isHideConfigureFeedbackBtn = true;
      this.isHideProbationFeedbackBtn = true;
      this.getProbationFeedbackGroup(e.index);
      this.changeToProbation('PROBATION');
    }
    else if (e.index == 2) {
      this.isHideConfigureFeedbackBtn = false;
      this.isHideProbationFeedbackBtn = false;
      this.changeToProbation('REAL_TIME');

    }
  }

  remind(feedbackId, i) {

    this.feedbackService.sendRemainder(feedbackId).subscribe(res => {

      this.reminderStatus[i] = true;
      this.toastr.success('Successfully done', 'succes')
    })

  }


  remindProbation(feedbackId, i) {
    this.feedbackService.sendProabtionFeedbackRemainder(feedbackId).subscribe(res => {
      this.reminderStatus[i] = true;
      this.toastr.success('Successfully reminded', 'sent')
    })

  }

  resubmit(feedbackId) {
    this.feedbackService.reSubmit(feedbackId).subscribe(res => {
      this.ngOnInit();
    })
  }

  resubmitProbation(feedbackId) {
    this.feedbackService.reProbationSubmit(feedbackId).subscribe(res => {
      this.ngOnInit();
    })
  }
  viewDetails() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80rem";
    dialogConfig.height = "600px";
    dialogConfig.data =
    {
      directReporteeId: this.directReporteeId,
      selected: this.selected,
      userdto: this.userdto,
      isClosedVisible: true
    }
    const dialogRef = this.dialog.open(PreviewFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  showHide(show: boolean) {

    this.showHideBtn = show;
  }
  getQuestionsRatingAdhoc() {
    this.excelArray = [];
    this.feedbackgroup.getfeedbackbyuserandyear(this.directReporteeId, this.selected).subscribe(async res => {
      this.userFeedbackDTO = res.body;
      let first_row_of_excel = [];
      first_row_of_excel.push('Sr.No.');
      first_row_of_excel.push('Employee Number');
      first_row_of_excel.push('Employee Name');
      first_row_of_excel.push('Job Title');
      first_row_of_excel.push('Department');
      first_row_of_excel.push('Reporting Manager Name');
      first_row_of_excel.push('Reviewer Name');
      first_row_of_excel.push('Reviewer Type Name');
      first_row_of_excel.push('Question');
      first_row_of_excel.push('Rating');
      first_row_of_excel.push('Performance Comments');
      this.excelArray.push(first_row_of_excel);
      let reportingManager = 1;

      for (let i of res.body) {
        if (i.feedbackType == "ADHOC" || i.feedbackType == "PROJECT") {
          this.employee = await this.getNameFromID(i.feedbackFor);
          this.reportingManager = await this.getNameFromID(this.employee.careerManagerId);

          for (let temp1 of i.feedbackFrom) {
            this.reviewerName = await this.getNameFromID(temp1.feedbackFrom);
            //console.log(temp1.feedbackFrom)
            var obj = JSON.parse(temp1.submission);
            for (let x of obj) {
              if (x.hasOwnProperty('attributes')) {
                for (let y of x.attributes) {
                  let excelArrayTemp = [];
                  excelArrayTemp.push(reportingManager++);
                  this.getemailAdhoc(excelArrayTemp, y, temp1)
                }
              }
            }
          }
        }
      }
    })
  }
  getQuestionsRatingOrphan() {
    this.excelArrayOrphan = [];
    this.feedbackgroup.getfeedbackbyuserandyear(this.directReporteeId, this.selected).subscribe(async res => {
      this.userFeedbackDTO = res.body;
      let first_row_of_excel = [];
      first_row_of_excel.push('Sr.No.');
      first_row_of_excel.push('Sr.No.');
      first_row_of_excel.push('Employee ID');
      first_row_of_excel.push('Employee Name');
      first_row_of_excel.push('Job Title');
      first_row_of_excel.push('Career Manager');
      first_row_of_excel.push('Capability');
      first_row_of_excel.push('Reviewer Name');
      first_row_of_excel.push('Reviewer Type');
      first_row_of_excel.push('Feedback Title');
      first_row_of_excel.push('Questions');
      first_row_of_excel.push('Rating');
      first_row_of_excel.push('Comments');
      first_row_of_excel.push('Additional Feedback');
      this.excelArrayOrphan.push(first_row_of_excel);
      let reportingManager = 1;
      let feedbackNo = 1;

      for (let i of res.body) {
        if (i.feedbackType == "ORPHAN") {
          this.isRealtimeScore = true;
        }
        if (i.feedbackType == "ORPHAN" && i.feedbackFrom[0].feedbackSubmissionStatus == 'SUBMITTED') {
          this.employee = await this.getNameFromID(i.feedbackFor);
          this.reportingManager = await this.getNameFromID(this.employee.careerManagerId);
          let feedbackTitle = i.title;
          let addedSerialNumber = false;

          for (let temp1 of i.feedbackFrom) {
            this.reviewerName = await this.getNameFromID(temp1.feedbackFrom);
            var obj = JSON.parse(temp1.submission);
            for (let [index, x] of obj.entries()) {
              if (x.hasOwnProperty('attributes')) {
                let indexY = 0;
                for (let y of x.attributes) {
                  let excelArrayTemp = [];
                  excelArrayTemp.push(reportingManager++);
                  if (!addedSerialNumber) {
                    excelArrayTemp.push(feedbackNo++);
                    addedSerialNumber = true; // Set the flag to true after adding the serial number
                  } else {
                    excelArrayTemp.push(""); // Leave the serial number column empty
                  }
                  if (index == 0 && indexY == 0) {
                    excelArrayTemp.push(this.employee.employeeId);
                    excelArrayTemp.push(this.employee.firstName + ' ' + this.employee.lastName);
                    excelArrayTemp.push(this.formatString(this.employee.jobTitle));
                    excelArrayTemp.push(this.reportingManager.firstName + ' ' + this.reportingManager.lastName);
                    if(this.employee.department == "API_MANAGEMENT_AND_INTEGRATION")
                      excelArrayTemp.push("API Management & Integration");
                    else if(this.employee.department == "DATA_SCIENCE_AND_AI")
                    excelArrayTemp.push("Data Science & AI");
                    else
                      excelArrayTemp.push(this.formatString(this.employee.department));
                    excelArrayTemp.push(this.reviewerName.firstName + ' ' + this.reviewerName.lastName);
                    excelArrayTemp.push(this.formatString(temp1.feedbackRole));
                    excelArrayTemp.push(feedbackTitle);
                  } else {
                    excelArrayTemp.push("");
                    excelArrayTemp.push("");
                    excelArrayTemp.push("");
                    excelArrayTemp.push("");
                    excelArrayTemp.push("");
                    excelArrayTemp.push("");
                    excelArrayTemp.push("");
                    excelArrayTemp.push("");
                  }
                  excelArrayTemp.push(y.label);
                  excelArrayTemp.push(parseFloat(y.value));
                  excelArrayTemp.push(y.description);
                  excelArrayTemp.push("");
                  this.excelArrayOrphan.push(excelArrayTemp);
                  //this.getemailOrphan(excelArrayTemp, y, temp1, feedbackTitle)
                  indexY++;
                }
              }
              if (x.hasOwnProperty('question')) {
                for (let y of x.question) {
                  let ans = "";
                  if (y.ans) {
                    ans = y.ans;
                  }
                  let excelArrayTemp = [];
                  excelArrayTemp.push(reportingManager++);
                  if (!addedSerialNumber) {
                    excelArrayTemp.push(feedbackNo++);
                    addedSerialNumber = true; // Set the flag to true after adding the serial number
                  } else {
                    excelArrayTemp.push(""); // Leave the serial number column empty
                  }
                  excelArrayTemp.push("");
                  excelArrayTemp.push("");
                  excelArrayTemp.push("");
                  excelArrayTemp.push("");
                  excelArrayTemp.push("");
                  excelArrayTemp.push("");
                  excelArrayTemp.push("");
                  excelArrayTemp.push("");
                  excelArrayTemp.push(y.que);
                  excelArrayTemp.push("");
                  excelArrayTemp.push("");
                  excelArrayTemp.push(ans);
                  this.excelArrayOrphan.push(excelArrayTemp);
                }
              }
            }
            let excelArrayTemp = [];
            excelArrayTemp.push(reportingManager++);
            if (!addedSerialNumber) {
              excelArrayTemp.push(feedbackNo++);
              addedSerialNumber = true; // Set the flag to true after adding the serial number
            } else {
              excelArrayTemp.push(""); // Leave the serial number column empty
            }
            excelArrayTemp.push("");
            excelArrayTemp.push("");
            excelArrayTemp.push("");
            excelArrayTemp.push("");
            excelArrayTemp.push("");
            excelArrayTemp.push("");
            excelArrayTemp.push("");
            excelArrayTemp.push("");
            excelArrayTemp.push("Score");
            excelArrayTemp.push(temp1.score);
            excelArrayTemp.push("");
            excelArrayTemp.push("");
            this.excelArrayOrphan.push(excelArrayTemp);
          }
        }
      }
    })
  }

  formatString(input: string): string {
    return input
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b(Sr)\b/gi, 'Sr.')
      .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  async getNameFromID(userId) {
    const myPromise = new Promise((resolve, rejects) => {

      this.userservice.getUserByID(userId).subscribe((res) => {

        resolve(res.body)

      })
    })

    var abc = await myPromise
    return abc;

  }
  async getemailAdhoc(excelArrayTemp, y, temp1) {
    let response;
    excelArrayTemp.push(this.employee.employeeId);
    excelArrayTemp.push(this.employee.firstName + ' ' + this.employee.lastName);
    excelArrayTemp.push(this.employee.jobTitle);
    excelArrayTemp.push(this.employee.department);
    excelArrayTemp.push(this.reportingManager.firstName + ' ' + this.reportingManager.lastName);
    excelArrayTemp.push(this.reviewerName.firstName + ' ' + this.reviewerName.lastName);
    excelArrayTemp.push(temp1.feedbackRole);
    excelArrayTemp.push(y.label);
    excelArrayTemp.push(y.value);
    excelArrayTemp.push(y.description);
    this.excelArray.push(excelArrayTemp);
  }
  async getemailOrphan(excelArrayTemp, y, temp1) {
    let response;
    excelArrayTemp.push(this.employee.employeeId);
    excelArrayTemp.push(this.employee.firstName + ' ' + this.employee.lastName);
    excelArrayTemp.push(this.employee.jobTitle);
    excelArrayTemp.push(this.reviewerName.department);
    excelArrayTemp.push(this.reportingManager.firstName + ' ' + this.reportingManager.lastName);
    excelArrayTemp.push(this.reviewerName.firstName + ' ' + this.reviewerName.lastName);
    excelArrayTemp.push(temp1.feedbackRole);
    excelArrayTemp.push(y.label);
    excelArrayTemp.push(y.value);
    excelArrayTemp.push(y.description);
    this.excelArrayOrphan.push(excelArrayTemp);
  }
  fileAdhoc() {
    var csvString = '';
    var temp;
    const yearRT = this.selected;
    const fileName = `Questions_Rating_Report_${yearRT}.csv`;

    temp = this.excelArray;

    for (let i = 1; i < temp.length; i++) {
      temp[i][0] = i;
    }
    temp.forEach((RowItem: any, RowIndex: any) => {
      RowItem.forEach((colItem: any, colIndex: any) => {


        csvString += "\"" + colItem + "\"" + ',';
      });
      csvString += '\r\n';
    });
    csvString = 'data:application/csv, ' + encodeURIComponent(csvString);
    var x = document.createElement('A');
    x.setAttribute('href', csvString);
    x.setAttribute('download', fileName);
    document.body.appendChild(x);
    x.click();
  }

  //import * as ExcelJS from 'exceljs';

fileOrphan() {
  const yearRT = this.selected;
  const fileName = `RT_Questions_Rating_Report_${yearRT}.xlsx`;

  // Create a new workbook and add a worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  // Define styles for the header row
  const headerStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '051151' }, // Background color
    },
    font: {
      color: { argb: 'ffffff' }, // Text color
      name: 'Nunito', // Font name
      size: 11, // Font size
    },
    border: {
      top: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on top
      right: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on right
      bottom: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on bottom
      left: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on left
    },
  };

  const feedbackRecordFirstRowStyle = {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D9EBF9' }, // Background color for the first row of each feedback record
    },
    font: {
      name: 'Nunito',
      size: 11,
    },
    border: {
      top: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on top
      right: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on right
      bottom: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on bottom
      left: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on left
    },
  };

  // Add the header row data and apply styles
  const headerRow = this.excelArrayOrphan[0].slice(1);
  worksheet.addRow(headerRow);
  const headerCells = worksheet.getRow(1);

  headerCells.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '051151' }, // Background color
    };
    cell.font = headerStyle.font;
    cell.border = {
      top: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on top
      right: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on right
      bottom: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on bottom
      left: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on left
    };
  });

  // Add data rows
  for (let i = 1; i < this.excelArrayOrphan.length; i++) {
    const dataRow = this.excelArrayOrphan[i].slice(1); // Exclude the first element (Sr.No.)
    
    // Check if it's the first row of a feedback record and apply the style
    if (dataRow[headerRow.indexOf('Feedback Title')] !== '') {
      // If 'Feedback Title' is not empty, it's the first row of a feedback record
      const firstRowCells = worksheet.addRow(dataRow);
      
      // Apply the background color to all cells in the first row including the last cell
      firstRowCells.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9EBF9' }, // Background color for the first row of each feedback record
        };
        cell.font =  feedbackRecordFirstRowStyle.font;
        cell.border = {
          top: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on top
          right: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on right
          bottom: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on bottom
          left: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on left
        };
      });
    } else if (dataRow[headerRow.indexOf('Questions')] === 'Score') {
      // Check if it's the last row of a feedback record and 'Questions' is 'Score'
      const lastRowCells = worksheet.addRow(dataRow);
  
      // Apply background color to cells 10 and 11
      lastRowCells.getCell(10).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF8396' }, // Background color for cells 10
      };
      lastRowCells.getCell(11).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF8396' }, // Background color for cells 11
      };
      lastRowCells.eachCell((cell) => {
        cell.font = feedbackRecordFirstRowStyle.font;
        cell.border = {
          top: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on top
          right: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on right
          bottom: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on bottom
          left: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on left
        };
      });
    }else {
      const middleRowCells = worksheet.addRow(dataRow);
      middleRowCells.eachCell((cell) =>{
        cell.font = feedbackRecordFirstRowStyle.font;
        cell.border = {
          top: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on top
          right: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on right
          bottom: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on bottom
          left: { style: 'thin', color: { argb: 'D3D3D3' } }, // Light gray border on left
        };
      })
    }
  }


  // Set column widths (optional)
  worksheet.columns.forEach((column, i) => {
    column.width = 15; // Adjust the width as needed
  });

  // Generate the XLSX file
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  });

  // Optionally, perform additional actions like getting data or handling the download
  this.getQuestionsRatingOrphan();
}


  // fileOrphan() {
  //   var csvString = '';
  //   var temp;
  //   const yearRT = this.selected;
  //   const fileName = `RT_Questions_Rating_Report_${yearRT}.csv`;

  //   temp = this.excelArrayOrphan;

  //   temp.forEach((RowItem: any) => {
  //     RowItem.shift(); // Remove the first element (row number)
  //     RowItem.forEach((colItem: any) => {
  //       csvString += "\"" + colItem + "\"" + ',';
  //     });
  //     csvString += '\r\n';
  //   });
    
  //   csvString = 'data:application/csv, ' + encodeURIComponent(csvString);
  //   var x = document.createElement('A');
  //   x.setAttribute('href', csvString);
  //   x.setAttribute('download', fileName);
  //   document.body.appendChild(x);
  //   x.click();
  //   this.getQuestionsRatingOrphan()
  // }

}




