import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getOverlappingDaysInIntervals } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { CmReportDTO } from 'src/app/dtos/CmReportDTO';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { GeoLocation } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';

@Component({
  selector: 'app-cm-report',
  templateUrl: './cm-report.component.html',
  styleUrls: ['./cm-report.component.scss'],
})
export class CmReportComponent implements OnInit {
  overallstatus;
  nodata: Boolean = true;
  userdto;
  selected;
  selectedYear: number;
  years: number[] = [];
  cmReportDTO: CmReportDTO = new CmReportDTO();
  cmReportDTOList: any=[];
  selectedGeolocation: String = '';
  geo: any = [];
  geoLoc;


  flag = 0;
  reportee = [];
  d = new Date();
  y = this.d.getFullYear();
  view = [200, 200];
  colorScheme = {
    domain: [' #FFA900', '#28A745', '#F93154'],
  };
  excelArray = [];

  doughnut = false;
  showLegend = true;
  p: number = 1;
  itemsPerPage = 8;
  searchText: string;
  openCountPercentage = 0;
  notInitiatedPercentage = 0;
  closeCountPercentage = 0;
  openCount = 0;
  closeCount = 0;
  notInitiatedCount = 0;
  directReporteesCount = 0;

  showLabels: boolean = true;
  legendPosition: string = 'right';
  loggedInUserGeoLoc: any;
  constructor(
    private router: Router,
    public userGoalService: UserGoalService,
    public feedbackgroupservice: NewFeedbackGroupService,
    private userService: UserService,
    public toastr: ToastrService,
    public userDetailService: UserDetailsService,
  ) { }

  ngOnInit(): void {
    this.selected = new Date().getFullYear();
    this.geoLoc = GeoLocation;
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2015; year--) {
      this.years.push(year);
    }

    for (let geoLocation of Object.keys(GeoLocation)) {
      if (isNaN(Number(geoLocation))) this.geo.push(geoLocation);
    }
    this.loggedInUserGeoLoc=this.userDetailService.getUserDetails().geoLocation;
    this.selectedGeolocation=this.loggedInUserGeoLoc;
    this.getAllCmReport(this.loggedInUserGeoLoc,this.selected);
  }
  ngAfterViewChecked(): void {
    this.itemsPerPage = 10;
    if (window.innerWidth > 960) {
      this.itemsPerPage = 6;
    }
    if (window.innerWidth > 1187) {
      this.itemsPerPage = 9;
    }
    if (window.innerWidth > 1530) {
      this.itemsPerPage = 12;
    }
    if (window.innerWidth > 1877) {
      this.itemsPerPage = 15;
    }
  }

  file() {
    var csvString = '';
    var temp;

    temp = this.excelArray;

    for (let i = 1; i < temp.length; i++) {
      temp[i][0] = i;
    }
    temp.forEach((RowItem: any, RowIndex: any) => {
      RowItem.forEach((colItem: any, colIndex: any) => {
        csvString += colItem + ',';
      });
      csvString += '\r\n';
    });
    csvString = 'data:application/csv, ' + encodeURIComponent(csvString);
    var x = document.createElement('A');
    x.setAttribute('href', csvString);
    x.setAttribute('download', 'CM_Report.csv');
    document.body.appendChild(x);
    x.click();
  }
  cmReportee(id) {
    this.router.navigate(['cm-reportees', id]);
  }
  loadForm(event) {
    this.userGoalService.setCurrentYear(event.value);
    this.selected = event.value;
    this.feedbackgroupservice
      .getdirectreporteebyyear(event.value)
      .subscribe((res) => {
        if (res.body.length != 0) {
          this.nodata = false;
        }
        this.userdto = null;
        this.userdto = res.body;
      });
    if (this.flag == 1) {
      this.getAllCmReport(this.selectedGeolocation, this.selected);
    }
  }

  loadGeoLocation(event) {
    this.selectedGeolocation = event.value;
    this.flag = 1;
    if (this.flag == 1) {
      this.getAllCmReport(this.selectedGeolocation, this.selected);
    }
  }

  getAllCmReport(geolocation, year) {
    this.excelArray = [];
    this.cmReportDTOList = [];
    this.feedbackgroupservice
      .getAllCmReport(geolocation, year)
      .subscribe((res) => {
        this.cmReportDTOList = res.body;


        this.cmReportDTOList.forEach((element) => {
          this.cmReportDTO.id = element.id;
          this.cmReportDTO.firstName = element.firstName;
          this.cmReportDTO.lastName = element.lastName;
          this.cmReportDTO.employeeId = element.employeeId;
          this.cmReportDTO.directReportees = element.directReportees;

          this.cmReportDTO.openCount = element.openCount;
          this.cmReportDTO.closeCount = element.closeCount;
          this.cmReportDTO.notInitiated = element.notInitiated;
          this.openCount = 0;
          this.closeCount = 0;
          this.notInitiatedCount = 0;
          this.directReporteesCount = 0;
          this.openCount += element.openCount;
          this.closeCount += element.closeCount;
          this.notInitiatedCount += element.notInitiated;
          this.directReporteesCount += element.directReportees.length;

          this.openCountPercentage =
            (this.openCount * 100) / this.directReporteesCount;
          this.closeCountPercentage =
            (this.closeCount * 100) / this.directReporteesCount;
          this.notInitiatedPercentage =
            (this.notInitiatedCount * 100) / this.directReporteesCount;



        });

        let first_row_of_excel = [];
        first_row_of_excel.push('Sr.No');
        first_row_of_excel.push('Career Manager');
        first_row_of_excel.push('Open Count');
        first_row_of_excel.push('Close Count');
        first_row_of_excel.push('Not Initiated');

        first_row_of_excel.push('Year');
        first_row_of_excel.push('Geo Location');
        this.excelArray.push(first_row_of_excel);
        let c = 1;

        for (let cmReportDTOList of res.body) {
          let excelArrayTemp = [];
          excelArrayTemp.push(c++);

          excelArrayTemp.push(
            cmReportDTOList.firstName + ' ' + cmReportDTOList.lastName
          );
          excelArrayTemp.push(cmReportDTOList.openCount);
          excelArrayTemp.push(cmReportDTOList.closeCount);
          excelArrayTemp.push(cmReportDTOList.notInitiated);
          excelArrayTemp.push(this.selected);
          excelArrayTemp.push(this.selectedGeolocation);
          this.excelArray.push(excelArrayTemp);
        }
      }, (e) => {
        this.excelArray = [];
        this.cmReportDTOList = [];
        this.toastr.warning("You are not authorized to access this content!!")
      });
  }
}

