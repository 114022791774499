import { Project } from "./project";

export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  imageUrl?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: string[];
  // roles?: string[];
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  password?: string;
  jobTitle?: string;
  mobileNumber?: string;
  officeLocation?: string;
  baseLocation?: string;
  microsoftId?: string;
  project?: Project[];
  careerManagerId?:string;
  department?:string;
  employeeId?:string;
  joiningDate?:Date;
  dateOfBirth?:Date
  imageBase?:string
  confirmationDate?:Date;
  probationPeriod?:number;
  gender?: string;
  talentBuddyId?: string;
  userImageThumbnail: string;
  geoLocation?: string;
}

export class User {
  about(about: any) {
    throw new Error('Method not implemented.');
  }
  id: any;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  activated: boolean;
  langKey: string;
  authorities: string[];
  // roles: string[];
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  password: string;
  jobTitle: string;
  mobileNumber: string;
  officeLocation: string;
  baseLocation: string;
  microsoftId: string;
  project: Project[];
  careerManagerId:string;
  department:string;
  employeeId:string;
  joiningDate:Date;
  imageUrl:string;
  dateOfBirth:Date
  imageBase:string
  confirmationDate:Date;
  probationPeriod: number;
  gender: string;
  talentBuddyId: string;
  userImageThumbnail: string;
  geoLocation: string;

  constructor(user: IUser) {
    if (user && user.id) {
      this.id = user.id;
    }
    this.login = user && user.login || null;
    this.firstName = user && user.firstName || null;
    this.lastName = user && user.lastName || null;
    this.email = user && user.email || null;
    this.activated = user && user.activated || null;
    this.langKey = user && user.langKey || null;
    this.authorities = user && user.authorities || null;
    this.createdBy = user && user.createdBy || null;
    this.createdDate = user && user.createdDate || null;
    this.lastModifiedBy = user && user.lastModifiedBy || null;
    this.lastModifiedDate = user && user.lastModifiedDate || null;
    this.password = user && user.password || null;
    this.jobTitle = user && user.jobTitle || null;
    this.mobileNumber = user && user.mobileNumber || null;
    this.officeLocation = user && user.officeLocation || null;
    this.baseLocation = user && user.baseLocation || null;
    this.microsoftId = user && user.microsoftId || null;
    this.careerManagerId = user && user.careerManagerId || null ;
    this.department = user && user.department || null
    this.employeeId = user && user.employeeId || null
    this.joiningDate = user && user.joiningDate || null;
    this.imageUrl = user && user.imageUrl || null;
    this.dateOfBirth = user && user.dateOfBirth || null;
    this.imageBase=user && user.imageBase || null
    this.confirmationDate= user && user.confirmationDate || null;
    this.probationPeriod = user && user.probationPeriod || 0;
    this.gender = user && user.gender || null;
    this.talentBuddyId = user && user.talentBuddyId || null;
    this.userImageThumbnail = user && user.userImageThumbnail || null;
    this.geoLocation = user && user.geoLocation || null;
    if(user && user.project){
      for (let userProject of user.project){
        this.project.push(new Project(userProject))
      }
    }
  }
}
