import { Injectable } from '@angular/core';
import { RestAPIService } from './rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class UserDocumentURLService {
  private readonly userDocumentBaseApi = '/api/user-documents';
  private readonly search = '/search';
  private readonly user = '/user';
  private readonly document = '/document';
  private readonly userId_param = 'userId'
  private readonly document_name = 'documentName'
  constructor(private restApi:RestAPIService) { 
  }
  getBaseUrl(){
    return this.userDocumentBaseApi;
  }
  getUserUrl(documentName,userId) {
    return this.userDocumentBaseApi + this.user
      + '?' + this.document_name + '=' + documentName+'&'+ this.userId_param + '=' + userId
  }
  getUserdoclist(documentName,userId) {
    return this.userDocumentBaseApi + this.document
      + '?' + this.document_name + '=' + documentName+'&'+ this.userId_param + '=' + userId
  }
}