



import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { ViewChild, ElementRef } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { TaskComponent } from '../tasks/task/task.component';
import { ExitChecklistDTO } from 'src/app/dtos/ExitChecklistDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ExitChecklist } from 'src/app/services/exit-checklist/exit-checklist.service';
import { exitChecklistStages } from 'src/app/dtos/ExitChecklistDTO';
@Component({
  selector: 'app-exit-checklist',
  templateUrl: './exit-checklist.component.html',
  styleUrls: ['./exit-checklist.component.scss']
})
export class ExitChecklistComponent implements OnInit {
  @ViewChild('divedit') divedit: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  userList;
  userNameIDMapping = {};
  options = ["Sam", "Varun", "Jasmine"];
  userFormGroup: FormGroup;
  stages: any = {};
  hrFormGroup: FormGroup;
  oo1FormGroup: FormGroup;
  oo2FormGroup: FormGroup;
  foFormGroup: FormGroup;
  exitDTO = new ExitChecklistDTO();
  cmFormGroup: FormGroup;
  filteredOptions;
  contentEditable: boolean;
  exitChecklistStages:any= {
    "stages": {
        "USER_STAGE": {
        },
        "HR_STAGE": {
        },
        "OPERATIONS_STAGE": {
        },
        "CARRER_MANAGER_STAGE": {
        },
        "FINANCE_STAGE": {
        }
    }

}

  displayColumns: string[] = ['Sr. no.', 'Action', 'Must be Completed by', 'Action Completion Date', 'Comments'];
  UserCheckList = [
    {
      action: "Read Exit Policy - Link ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Read the training/incentive bonus policy - Training & ,  Certification Bonus", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Downloaded financial documents from greytHR - Link", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Knowledge Transfer initiated?", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Laptop / Access card/ Other devices handover - Link", mustCompleted: "", actionCompletionDate: "", comments: ""
    }
  ];
  HrCheckList = [
    {
      action: "Signatures on any leaving/exit records ", mustCompleted: "", actionCompletionDate: "", comments: "Completed"
    },
    {
      action: "Signatures on the counter-copies of letters", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Signatures on the counter-copies of letters", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Knowledge Transfer initiated?", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Addition to Alumni List ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Exit Mailer ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Miscellaneous if any (please specify)", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Signature on any agreements/bonds as relevant", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Exit Interview", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Training/ Certifications/ If any", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Exit Formalities Sign off", mustCompleted: "", actionCompletionDate: "", comments: ""
    }
  ];
  OOCheckList1 = [
    {
      action: "Back Up Required", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Reset Mail password", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Signatures on the counter-copies of letters", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Revoke VPN certificate if created VPN account", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Remove from All-list and other mailing lists / aliases / account from SharePoint, Public servers if created ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Revoke COE access ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Laptop / Access card/ Other devices handover", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Revoke Atlassian access (Confluence/ Jira)", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Batch Handover", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Check and revoke SharePoint (Sole access) ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Remove from Harvest / Slack", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Remove from GITlab", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Cloud Access (GCP/ AWS/ Azure)", mustCompleted: "", actionCompletionDate: "", comments: ""
    }
  ];
  OOCheckList2 = [
    {
      action: "Keys to the lockers/ cupboard of the employee", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Identity card", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Business cards ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Parking Card", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Snacks Amount ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Miscellaneous if any (please specify)", mustCompleted: "", actionCompletionDate: "", comments: ""
    }
  ];
  FOCheckList = [
    {
      action: "Encashment of leave", mustCompleted: "", actionCompletionDate: "", comments: "Completed"
    },
    {
      action: "Settlement of salary", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Settlement of certification /bonus amount due ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Deactivate Medical Insurance", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "PF & Gratuity Settlement", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Settlement of loan/advance if any taken from the company", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Allocate career manager for data science team", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Payslips/ Form 16 or anything to be downloaded before last working day", mustCompleted: "", actionCompletionDate: "", comments: ""
    }
  ];
  CMCheckList = [
    {
      action: "Back Up Required", mustCompleted: "", actionCompletionDate: "", comments: "Completed"
    },
    {
      action: "Knowledge Transfer complete", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Handover of confidential project related documents -Account HierarchyAccount Lookup Intel Other SF material ", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Email ID/Machine to be kept alive for any particular duration?", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Customer is communicated about the exit and all the customer related activities are taken care of. e.g. mail and VPN account disabled, laptop returned", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Residual knowledge capture in the form of documents, case studies, white papers", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Have you approved employee’s all leaves?", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Update time sheet to Harvest and Client - with approval", mustCompleted: "", actionCompletionDate: "", comments: ""
    },
    {
      action: "Exit message to Leads/company - 15 days in advance", mustCompleted: "", actionCompletionDate: "", comments: ""
    }
  ];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskComponent>,
    private userService: UserService,
    public userDetailService: UserDetailsService,
    public exitService: ExitChecklist,

    private fb: FormBuilder) { }
  ngOnInit(): void {
    console.log(this.data)
    this.exitService.createChecklist(this.exitChecklistStages, this.data).subscribe(res => {
      this.stages=res.body.stages;
    })

    this.initForm();
    this.getNames();
    this.contentEditable = false;
    this.userService.getAllUsers().subscribe(usrData => {
      usrData.body.forEach(element => {
        this.userNameIDMapping[element.firstName + " " + element.lastName] = element.id;
      });
      this.userList = Object.keys(this.userNameIDMapping);
    });
  }



  stepClick(index) {

  }

  assignUser() {
    let currentExitDTO = {
      "stages": {
        "USER_STAGE": {
          // "owner": this.userDetailService.getUserDetails().id
        }
    }
    }
    const user  = this.userDetailService.getUserDetails();
    this.exitService.updateChecklist(currentExitDTO,this.data).subscribe(res =>{
      this.closeDialog();
    })
  }

  assignHr() {
    if (this.hrFormGroup.get(['hr']).status) {
    }
  }
  assignOO1() {
    if (this.oo1FormGroup.get(['op_owner1']).status) {
    }
  }
  assignOO2() {
    if (this.oo2FormGroup.get(['op_owner2']).status) {
    }
  }
  assignFinanceOwner() {
    if (this.foFormGroup.get(['finance_owner']).status) {

    }
  }
  assignCarreerManager() {
    if (this.cmFormGroup.get(['cm']).status) {
    }
  }
  changeEditable() {
    document.getElementById("divedit").contentEditable = "true";
  }
  closeDialog() {
    this.dialogRef.close();
  }
  initForm() {
    this.userFormGroup = this.fb.group({
      'user': ['', Validators.required]
    })
    this.hrFormGroup = this.fb.group({
      'hr': ['', Validators.required]
    })
    this.oo1FormGroup = this.fb.group({
      'op_owner1': ['', Validators.required]
    })
    this.oo2FormGroup = this.fb.group({
      'op_owner2': ['', Validators.required]
    })
    this.foFormGroup = this.fb.group({
      'finance_owner': ['', Validators.required]
    })
    this.cmFormGroup = this.fb.group({
      'cm': ['', Validators.required]
    })
    this.userFormGroup.get('user').valueChanges.subscribe(response => {
      this.filterData(response);
    })
    this.hrFormGroup.get('hr').valueChanges.subscribe(response => {
      this.filterData(response);
    })
    this.oo1FormGroup.get('op_owner1').valueChanges.subscribe(response => {
      this.filterData(response);
    })
    this.oo2FormGroup.get('op_owner2').valueChanges.subscribe(response => {
      this.filterData(response);
    })
    this.foFormGroup.get('finance_owner').valueChanges.subscribe(response => {
      this.filterData(response);
    })
    this.cmFormGroup.get('cm').valueChanges.subscribe(response => {
      this.filterData(response);
    })
  }
  filterData(enteredData) {
    this.filteredOptions = this.options.filter(item => {
      return item.toLowerCase().indexOf(enteredData.toLowerCase()) > -1
    })
  }
  getNames() {
    this.userService.getData().subscribe(response => {

      this.options = response;
      this.filteredOptions = response;
    })
  }
}
