import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-activate-employee',
  templateUrl: './activate-employee.component.html',
  styleUrls: ['./activate-employee.component.scss']
})
export class ActivateEmployeeComponent implements OnInit {

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<ActivateEmployeeComponent>,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) { }

  ngOnInit(): void {
  }

  matDialogClose() {
    this.dialogRef.close()
  }

  activateEmployee() {
    
    this.userService.activateUser(this.data.id).subscribe(res=>{
    });
    this.matDialogClose();
    this.toastr.success(this.data.firstName + " is now activated", "Success");
  }

}
