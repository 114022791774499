import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { onMainContentChange } from './animations/animation';
import { LoadingService } from './services/loading.service';
import { LoginService } from './services/login.service';
import { SidenavService } from './services/sidenav.service';
import { StorageService } from './services/storage.service';
import { UserDetailsService } from './services/user-details.service';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange],
})
export class AppComponent implements OnInit, AfterViewInit {
  isExpanded: boolean = false;
  opened: boolean = false;
  loader: boolean = false;
  public onSideNavChange: boolean;

  @ViewChild('leftSidenav') public sidenav: MatSidenav;

  constructor(
    private _sidenavService: SidenavService,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private loginService: LoginService,
    private storageService: StorageService,
    public userDetail: UserDetailsService,
    private sidenavService: SidenavService,
    private router: Router
  ) {
    this._sidenavService.sideNavState$.subscribe((res) => {
      this.onSideNavChange = res;
    });
  }
  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnInit() {
    if(localStorage.length == 1 || localStorage.length == 0){
      this.router.navigate(['/login']);
  }
  else{
    this.loginService.updateLoggedinUserInfo();
    this.loadingService.onLoaderChange().subscribe((res) => {
      this.loader = res;
      this.changeDetectorRef.detectChanges();
    });
  }
  }
}
