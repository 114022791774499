import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LeaveApprovalDTO } from 'src/app/dtos/leaveApprovalDTO';
import { ApprovalStatus } from 'src/app/services/leaveManagment/leave-enum';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cm-leave-reject',
  templateUrl: './cm-leave-reject.component.html',
  styleUrls: ['./cm-leave-reject.component.scss']
})
export class CmLeaveRejectComponent implements OnInit {
  // leaveApplications = new LeaveApprovalDTO();
  rejectionDetails: FormGroup;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CmLeaveRejectComponent>,
    private notificationService: NotificationService,
    private leaveApprovalservice: LeaveApprovalService,
    public toaster: ToastrService,

  ) {
    this.rejectionDetails = new FormGroup({
      reasonForRejection: new FormControl(),
      
    });
  }

  ngOnInit(): void {
    // console.log(this.data)
  }

  rejectLeave() {
    const reason = this.rejectionDetails.get('reasonForRejection').value;
    console.log(reason)
    // this.leaveApplications.leaveApplId=this.data.leave.leaveId;
    // this.leaveApplications.approvalStatus=ApprovalStatus.REJECTED;
    // this.leaveApplications.reasonForRejection=reason;
    console.log(this.data.leave.leaveId)
    const leaveReject = {
      leaveApplicationId : this.data.leave.leaveId,
      approvalStatus : (this.data.leave.approvalStatus == "PENDING")? ApprovalStatus.REJECTED:"REVOKE_REQUEST_REJECTED",
      reasonForRejection : reason
    }
    this.leaveApprovalservice.update(leaveReject).subscribe((res) => {
      this.toaster.success('Leave Rejected', 'Saved', {});
      this.closeDialog();
    }, () => { 
      this.toaster.success('Cannot Reject Try Again', '', {});
      this.closeDialog();
    })
  }


  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
