import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserService } from 'src/app/services/user/user.service';
import { element } from 'protractor';
import { User } from 'src/app/models/user';
import { JsonFormComponent } from '../../json-form/json-form.component';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-realtime-feedback',
  templateUrl: './add-realtime-feedback.component.html',
  styleUrls: ['./add-realtime-feedback.component.scss']
})
export class AddRealtimeFeedbackComponent implements OnInit {
  selectedYear: number;
  realTimeFeedback = []
  userIdDetailsMapping = []
  listOfSelectedRealtimeFeedback = []
  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private feedbackservice: FeedbackService,
    private userService: UserService, private dialog: MatDialog,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public directReporteeData
  ) { }

  ngOnInit(): void {
    this.realTimeFeedback.length = 0;
    debugger
    this.selectedYear = new Date().getFullYear();
    this.feedbackservice.getRealTimeFeedbackByUserIdAndYear(this.directReporteeData.directReporteeDetails.directReporteeId, this.selectedYear).subscribe(res => {
      debugger
    console.log(res.body)
      for (let i of res.body) {
        if(i.feedbackType  === 'ORPHAN'){
          if (i.feedbackFrom[0].feedbackSubmissionStatus === 'SUBMITTED' && (!i.feedbackFrom[0].isAssigned)) {
            this.realTimeFeedback.push(i);
          }
        }
      }
      this.realTimeFeedback.forEach(element => {


        this.userService.getUserByID(element.createdBy).subscribe(res => {
          this.userIdDetailsMapping[element.createdBy] = res.body
        })
      })
    })
  }

  onChange(feedback, isChecked: boolean) {
    if (isChecked) {
      this.listOfSelectedRealtimeFeedback.push(feedback.feedbackFrom[0]);
    }
    else{
      const index = this.listOfSelectedRealtimeFeedback.indexOf(feedback.feedbackFrom[0]);
      if (index !== -1) {
        this.listOfSelectedRealtimeFeedback.splice(index, 1);
      }
    }
   
  }
  onSubmit() {
    if(!this.filterControls(this.listOfSelectedRealtimeFeedback)){
      this.dialogRef.close(this.listOfSelectedRealtimeFeedback)
    }
    else{
      this.toastr.error('Cannot add duplicate entries', 'Error', {
      });
    }
  }
  closeDialog() {
    this.dialogRef.close()
  }
  
  async view(f) {
    console.log(f)
    await this.feedbackservice.getFeedBackByIdAsync(f.id);
    var resOfFeedBackGroupAfterParse = await this.feedbackservice.resOfFeedBackGroupAfterParse;
    var timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.userService.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    var assinedUserAfterParse = await this.userService.assinedUserAfterParse;
    var assinedManagerUseAfterParse = await this.userService.assinedManagerUseAfterParse;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = [f.submission, false, assinedUserAfterParse.firstName + " " + assinedUserAfterParse.lastName, false, assinedManagerUseAfterParse.firstName + " " + assinedManagerUseAfterParse.lastName, timeFrame];
    dialogConfig.panelClass = "feedbackform-overlay";
    const dialogRef = this.dialog.open(JsonFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  filterControls(feedbackArray: any[]) {

    const duplicateFeedbackArray = [];

    feedbackArray.forEach((feedback, index) => {

      const isDuplicate = feedbackArray.some((item, i) =>
        i !== index &&
        (item.feedbackFrom === feedback.feedbackFrom) &&
        item.feedbackRole === feedback.feedbackRole
      );
      if (isDuplicate) {
        duplicateFeedbackArray.push(feedback);
      }
    });

    if (duplicateFeedbackArray.length !== 0) {
     return true
    } else {
     return false
    }

  }
  
}
