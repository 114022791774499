import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { HolidaysEventService } from 'src/app/services/leaveManagementCalendar/holidays-event.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ApplyHolidayComponent } from '../../leave-management/apply-holiday/apply-holiday/apply-holiday.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-upcoming-holidays',
  templateUrl: './upcoming-holidays.component.html',
  styleUrls: ['./upcoming-holidays.component.scss']
})
export class UpcomingHolidaysComponent implements OnInit {
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;

  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  userid: any;
  restrictedStatus: string = 'RESTRICTED_HOLIDAY';

  constructor(
    private eventService: HolidaysEventService,
    private userDetailService: UserDetailsService,
    public dialog: MatDialog,
  ) { }


  ngOnInit(): void {
    this.userid = this.userDetailService.getUserDetails().id;
    this.eventService.getRestictedHolidaysForApplyLeave(this.selectedYear).subscribe(res => {
      this.restrictedHolidays = [];
      const response = res.body.leaveDates;

      for (let i = 0; i < response.length; i++) {
        const dateObj = new Date(response[i].date);
        const monthName = dateObj.toLocaleString('default', { month: 'long' });

        this.eventService.getHolidayStatus(this.userid, { "date": response[i].date }).subscribe(result => {
          if (response[i].calendarLeaveType == this.restrictedStatus && monthName.toLowerCase() == this.selectedMonth.toLowerCase()) {
            const currentHoliday = {
              date: moment(response[i].date).format('DD MMMM YYYY'),
              desc: response[i].leaveDescription,
              originalDate: response[i].date,
              status: result.body
            }

            const todayDate = new Date().setHours(0,0,0,0);
            const holidayDate = new Date(currentHoliday.originalDate).setHours(0,0,0,0);

            if(holidayDate >= todayDate){
              this.restrictedHolidays.push(currentHoliday);
            }
            this.restrictedHolidays.sort((a, b) => {
              const dateA = new Date(a.originalDate).getTime();
              const dateB = new Date(b.originalDate).getTime();
              return dateA - dateB;
            });
          }
        })

        //console.log(this.restrictedHolidays)

      }
    }, (e) => { })
  }

  restrictedHolidays: { date: any, desc: any, originalDate: any }[] = [];

 

  applyRestrictedHoliday(description, date) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '65%';
    dialogConfig.width = '60%';
    dialogConfig.panelClass = "manage-class";
    dialogConfig.data = { holidayDescription: description, holidayDate: date };
    const dialogRef = this.dialog.open(ApplyHolidayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      
    });



  }

}
