import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Attendance } from 'src/app/models/attendance';
import { AttendanceUrlService } from '../attendanceURLs/attendance-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';


@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  public attendanceDto: Attendance[];

  constructor(private restApi: RestAPIService,
    private http: HttpClient, private attendanceUrl: AttendanceUrlService) { }

  /* public getAttendance():Observable<Attendance[]>{
     return this.http.get<Attendance[]>('/api/attendances');
   }*//*public getAttendance(): Observable<any>{
  return this.restApiService.get('/api/attendances/date/status');
  
*/
  getAttendance(attendanceStatus, date) {
    
    return this.restApi.get(
      this.attendanceUrl.getAttendanceUrl(attendanceStatus, date)
    )
  }
  getAttendanceById(attendanceId){
    return this.restApi.get(this.attendanceUrl.getAttendanceByIdUrl(attendanceId));
  }
}