import { Injectable } from '@angular/core';
import { AddHolidaysURLsService } from '../restAPI/addHolidaysURLs/add-holidays-urls.service';
import { RestAPIService } from '../restAPI/rest-api.service';


@Injectable({
  providedIn: 'root'
})
export class AddHolidayService {

  constructor(private restApiService: RestAPIService,
    private holidayURL : AddHolidaysURLsService) { }
  addHoliday(payload,year) {
    return this.restApiService.post(
      this.holidayURL.updateLeavesByYear(year),payload
    )
  }

  editHoliday(payload,year,Id){
    return this.restApiService.put(
      this.holidayURL.editLeavesById(year,Id),payload
    )
  }
}
