import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ResignationServiceService } from 'src/app/services/resignationService/resignation-service.service';

@Component({
  selector: 'app-resignation-reject',
  templateUrl: './resignation-reject.component.html',
  styleUrls: ['./resignation-reject.component.scss']
})
export class ResignationRejectComponent implements OnInit {

  rejectionDetails: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResignationRejectComponent>,
    private resignationService:ResignationServiceService,
    public toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    console.log(this.data.taskTransactionId)

  }

  rejectResignation(){
    this.resignationService.AcceptRejectResignation(false, this.data.taskTransactionId, 0).subscribe(res => {
      this.toastr.success('Resignation Rejected', '', {
      });
      this.closeDialog();
    }, () => {
      this.toastr.success('Resignation cannot Reject', '', {
      });
    })
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
