import { UserDTO } from '../dtos/UserDTO';
interface IPositionDetails
{
id?:string;
positionStartDate?:Date;
positionEndDate?:Date;
capabilityId?:string;
titleId?:string;
locationId?:string;
userId?:string;

}
export class PositionDetails{
    id:string;
    positionStartDate:Date;
    positionEndDate:Date;
    capability:string;
    jobTitle:string;
    locationId:string;
    userId:string;
    
    // constructor(positionDetails:IPositionDetails)
    // {
    //     if(positionDetails && positionDetails.id)
    //     {
    //         this.id=positionDetails.id;
    //     }
    //         this.positionStartDate = positionDetails && positionDetails.positionStartDate || null;
    //         this.positionEndDate = positionDetails && positionDetails.positionEndDate || null;
    //         this.capabilityId = positionDetails && positionDetails.capabilityId || null;
    //         this.titleId = positionDetails && positionDetails.titleId || null;
    //         this.locationId = positionDetails && positionDetails.locationId || null;
    //         this.userId = positionDetails && positionDetails.userId || null;

    // }
    constructor() {
        this.id = undefined
        this.userId = undefined
        this.capability = ""
        this.positionEndDate = undefined
        this.positionStartDate = undefined
        this.jobTitle=""

    }
    bind(httpResponse: any) {
        this.id = httpResponse && httpResponse.id || undefined
        this.positionStartDate = httpResponse && new Date(httpResponse.startDate) || undefined
        this.positionEndDate = httpResponse && new Date(httpResponse.endDate) || undefined
        this.jobTitle = httpResponse && httpResponse.jobTitle || undefined
        this.capability= httpResponse && httpResponse.jobTitle || undefined
        this.userId = httpResponse && httpResponse.userId || undefined
        return this;
    }
}