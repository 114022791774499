import { Injectable } from '@angular/core';
import { PancardUrlService } from '../restAPI/panCardURLs/pancard-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class PanCardService {
  constructor(private restApiService: RestAPIService,
    private panurl : PancardUrlService
  ) { }
  create(payload) {
    return this.restApiService.post(
      this.panurl.getBaseUrl(),
      payload
    )
  }
  update(payload){
    return this.restApiService.put(
      this.panurl.getBaseUrl(),
      payload
    )
  }
  getpanByUserIdSubscribe(userId) {
    return this.restApiService.get(
      this.panurl.getUserUrl(userId)
    )
  }
  delete(id: any) {
    this.restApiService.delete(
      this.panurl.getByFieldUrl(id)
    ).subscribe(res=>{
     
    })
  }
}
