<div class="main_header_div">
    <h3 mat-dialog-title>Reason for Bulk Regularization</h3>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
    </div>
  </div>
   <form>
    <div class="dates">
      <div *ngFor="let item of selectedItems">
        <span style="display: inline-block">{{ item.attendanceDateTime | date }}</span>
      </div>
    </div>
    <div [formGroup]="regularizationDetails" class="col">
        <mat-form-field
         appearance="outline"
          class="example">
            <textarea matInput formControlName="reasonForRegularization"
              cdkTextareaAutosize #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5.8"
              cdkAutosizeMaxRows="16" placeholder="Reason in words" >
          </textarea>
        </mat-form-field>
    </div>
  </form>
  <div class="submit_button_main">
    <button class="add_Emp_btn" type="submit" (click)="applyRegularization()" >
      <span>Submit</span>
    </button>
  </div>
