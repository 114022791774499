import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';

import { Family } from 'src/app/models/familydetails';
import { FamilyDetailsService } from 'src/app/services/familyDetails/family-details.service';

@Component({
  selector: 'app-nominee-details',
  templateUrl: './nominee-details.component.html',
  styleUrls: ['./nominee-details.component.scss']
})
export class NomineeDetailsComponent implements OnInit {
  familyForm: FormGroup;
  
  Familygroup: FormArray;
  today = new Date();
  removedFamily = [];
  



  constructor(public dialogRef: MatDialogRef<NomineeDetailsComponent>,
    public familyservice: FamilyDetailsService,
    public userDetailService: UserDetailsService,
    public userService: UserService,
    public toaster:ToastrService,
   
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.familyForm = this.fb.group({
      family: this.fb.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    //this.data contains userid
    this.familyservice
      .getfamilydetailbyuserIdSubscribe(
        this.data
      )
      .subscribe((res) => {
        this.setFamilyFormArray(res.body);
        res.body.length==0? this.addFamily():'';
      });
     
  }
  createfamily() {
    this.Family;
    // return this.fb.group(new Family());
    let formGroup = this.fb.group(new Family());
    formGroup.controls.firstName.setValidators([Validators.required]);
    // formGroup.controls.lastName.setValidators([Validators.required]);
    formGroup.controls.relation.setValidators([Validators.required]);
    formGroup.controls.dateOfBirth.setValidators([Validators.required]);
    formGroup.controls.nominees.setValidators([Validators.required]);
    formGroup.controls.location.setValidators([Validators.required]);
    return formGroup;
  }

  setFamilyFormArray(response) {
    this.Familygroup = this.familyForm.get('family') as FormArray;
    let familyList: Family[] = this.familyservice.setFamilyResponse(response);
    for (let s of familyList) {
      this.Familygroup.push(this.fb.group(s));
    }
   
  }
  Family(): FormArray {
    return this.familyForm.get('family') as FormArray;
  }
  addFamily() {
    this.Familygroup = this.familyForm.get('family') as FormArray;
    this.Familygroup.insert(0, this.createfamily());
    // this.Familygroup.push(this.createfamily());
  }

  removefamily(id, i) {
    this.removedFamily.push(id);
    this.Family().removeAt(i);
  }
  closeDialog(event:Event) {
    this.dialogRef.close({ event: 'Closed'})
  }
  onSubmit()
  {
    if (this.familyForm.valid) {
      for (let familyy of this.familyForm.value.family) {
        if (
          familyy &&
          !familyy.id &&
          familyy.firstName != '' &&
          familyy.relation != '' &&
          familyy.location != ''
        ) {
          familyy.userId = this.data;
          this.familyservice.create(familyy).subscribe((res) => {
            this.dialogRef.close({ event: 'Closed'})
            this.toaster.success("Successfully Saved")
            // this.notificationService.sucess("done")
          });
        } else if (
          familyy &&
          familyy.id &&
          familyy.firstName != '' &&
          familyy.relation != '' &&
          familyy.location != ''
        ) {
          familyy.userId = this.data;
          this.familyservice.update(familyy).subscribe((res) => {
            this.dialogRef.close({ event: 'Closed'})
            this.toaster.success("Successfully Saved")
            //   this.notificationService.sucess("Updated")
          });
        }
      }

}
for (let i in this.removedFamily) {
  this.familyservice.delete(this.removedFamily[i]);
}
  }
}
