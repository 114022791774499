import { Component, Inject, OnChanges, OnInit, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { TypeOfLeave, FirstnSecondHalf, ApprovalStatus } from 'src/app/services/leaveManagment/leave-enum';
import { ToastrService } from 'ngx-toastr';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MatSelectChange } from '@angular/material/select';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TalentService } from 'src/app/services/talent/talent.service';
import { log } from 'console';

@Component({
  selector: 'app-apply-newleave',
  templateUrl: './apply-newleave.component.html',
  styleUrls: ['./apply-newleave.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplyNewleaveComponent implements OnInit {
  newLeaveDetails: FormGroup;
  userId: any;
  public careerManagerEmailId: any;
  leavetypes = []
  firstsecondhalf = []
  allEmails: string[] = [];
  filteredAllEmails: string[];
  selectedEmailOptions: string[];
  allProjectManagerEmails: string[] = [];
  filteredPMEmails: string[];
  leaveapplication = new LeaveApplicationDTO;
  leaveBalance = new Number;
  typedLADays = new Number;
  maxDateOfYear: Date;
  minDateofYear: Date;
  currentYear: number;
  dayAString: string;
  dayBString: string;
  checkEndDate: boolean;
  checkLBandLAerror: boolean;
  errorMessage: string;
  submitButtonDisabled: boolean = false;
  loadingflag: number = 4;// as 4 async functions are running in ngoninit and 1 for all other process
  // updateFlag: number=
  public isAttachmentSaved: boolean;
  attachmentBase64: string;
  imageError: string;
  attachmentname: string;
  attachmentnameExtension: string;
  binary;
  existingLeave = new LeaveApplicationDTO;
  isExistingLeave: any;
  isDisableInput: boolean = false;
  attachmentType: string;
  leavebalanceData: any;

  // validationMessages = {
  //   'fullName': {
  //     'required': 'Full Name is required.',
  //     'minlength': 'Full Name must be greater than 2 characters.',
  //     'maxlength': 'Full Name must be less than 10 characters.'
  // }I
  // 'skillName': {
  // 'required': 'Skill Name is required.',
  // },
  // 'experienceInYears': {
  // 'required': 'Experience is required.',
  // },
  // 'proficiency': {
  // 'required': 'Proficiency is required.',
  // },
  // };


  constructor(public leaveapplicationservice: LeaveApplicationService, public userService: UserService, public fb: FormBuilder,
    public dialogRef: MatDialogRef<ApplyNewleaveComponent>,
    public toaster: ToastrService,
    public userDetailService: UserDetailsService,
    private leaveBalanceService: LeaveBalanceService,
    private talentService: TalentService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.newLeaveDetails = this.fb.group({

      typeOfLeave: ['', [Validators.required]],
      careerManagerId: ['', [Validators.required]],
      projectManagerId: ['', [Validators.required]],
      startDate: [, [Validators.required]],
      endDate: [, [Validators.required]],
      startSession: ['', [Validators.required]],
      endSession: ['', [Validators.required]],
      ccMentions: [[]],
      reasonForLeave: [''],
    });

  }


  ngOnInit() {
    // console.log(this.data)
    this.leaveBalance = null;
    this.isAttachmentSaved = false;
    this.typedLADays = null;
    this.userId = this.userDetailService.getUserDetails().id;
    this.currentYear = new Date().getFullYear();
    this.minDateofYear = new Date(this.currentYear, 0, 1);
    this.maxDateOfYear = new Date(this.currentYear, 11, 31);
    this.checkLBandLAerror = null;
    this.errorMessage = "";
    this.getCareerManagerEmailId();
    this.getAllEmpEmail();
    this.getAllPMEmail();
    this.getAllLeaveBalances(this.currentYear);
    if (this.data.isDisableDate) {
      this.isDisableInput = true;
    }

  }
  setExistingLeave() {
    //existing leave block
    this.existingLeave = this.data.existingLeave;
    if (this.existingLeave.doc_base) {
      // console.log(this.data.existingLeave.doc_base);
      this.existingLeave.doc_base = atob(this.data.existingLeave.doc_base).toString();
      this.attachmentType = this.getDocumentType(this.existingLeave.doc_base.toString());
      this.isAttachmentSaved = this.existingLeave.doc_base ? true : false;
      this.attachmentBase64 = this.existingLeave.doc_base;
    }

    // console.log(this.existingLeave.doc_base);
    this.isExistingLeave = this.data.existingLeave;
    if (this.data && this.data.existingLeave) {

      var typeOfLeaves = this.getEnumValueByEnumKey(TypeOfLeave, this.data.existingLeave.typeOfLeave);
      this.newLeaveDetails.get('typeOfLeave').setValue(
        this.existingLeave.typeOfLeave ? typeOfLeaves : ''
      );

      var first = this.data.existingLeave.startSession === 1 ? "FIRST_HALF" : "SECOND_HALF";
      var firstHalf = this.getEnumValueByEnumKey(FirstnSecondHalf, first);
      this.newLeaveDetails.get('startSession').setValue(
        this.existingLeave.startSession ? firstHalf : ''
      );

      var second = this.data.existingLeave.endSession === 1 ? "FIRST_HALF" : "SECOND_HALF";
      var secondHalf = this.getEnumValueByEnumKey(FirstnSecondHalf, second);
      this.newLeaveDetails.get('endSession').setValue(
        this.existingLeave.endSession ? secondHalf : ''
      );
      const sDate = new Date(this.existingLeave.startDate);
      const eDate = new Date(this.existingLeave.endDate);
      this.newLeaveDetails.get('startDate').setValue(sDate);
      this.newLeaveDetails.get('endDate').setValue(eDate);
      this.newLeaveDetails.patchValue({
        //typeOfLeave: this.existingLeave.typeOfLeave,
        careerManagerId: this.existingLeave.careerManagerId,
        projectManagerId: this.existingLeave.projectManagerId,
        // startDate: this.existingLeave.startDate,
        // endDate: this.existingLeave.endDate,
        //startSession: this.existingLeave.startSession,
        //endSession: this.existingLeave.endSession,
        ccMentions: this.existingLeave.ccMentions,
        reasonForLeave: this.existingLeave.reasonForLeave,
        contactNumber: this.existingLeave.contactNumber,
        transactionDateTime: this.existingLeave.transactionDateTime,
        doc_base: this.existingLeave.doc_base,
      });// console.log("heyy5"+JSON.stringify(this.leavebalanceData));
      this.setLeaveBalance();
      this.setApplyingDays();
      Object.values(this.newLeaveDetails.controls).forEach(control => {
        control.markAsTouched();
      });
    }
  }
  getDocumentType(temp): string {
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
    for (let type of allowed_types) {
      if (temp.indexOf(type) != -1) {
        return type;
      }
    }
  }

  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }

  getCareerManagerEmailId() {
    this.userService.getCareerManagerEmailId().subscribe(data => {
      this.careerManagerEmailId = data.body.email;
      this.loadingflag--;
      this.cd.detectChanges();
    }
    );
  }

  processEnumValuesToArray() {

    this.leavetypes.splice(0, this.leavetypes.length);
    for (let leaveType of Object.values(TypeOfLeave)) {
      if (leaveType == "Paternity Leave" || leaveType == "Comp Off" || leaveType == "Maternity Leave" || leaveType == "Special Leave") {
        if (this.leavebalanceData.balanceLeave[this.getEnumKeyByEnumValue(TypeOfLeave, leaveType)]) {
          if (this.leavebalanceData.balanceLeave[this.getEnumKeyByEnumValue(TypeOfLeave, leaveType)] != 0) {
            if (isNaN(Number(leaveType))) this.leavetypes.push(leaveType);
          }
        }
      }
      else if (leaveType == "Restricted Holiday") {
        // don't push in drop down as it will not applied from here
      }
      else {
        if (isNaN(Number(leaveType))) this.leavetypes.push(leaveType);
      }
    }
    // console.log(this.leavetypes);
    for (let firstnsecondhalf of Object.values(FirstnSecondHalf)) {
      if (isNaN(Number(firstnsecondhalf))) this.firstsecondhalf.push(firstnsecondhalf);
    }

  }
  onAddCCSelectionChange(event: MatSelectChange) {
    this.selectedEmailOptions = event.value;
  }
  searchAllEmails(query: string) {
    let result: string[] = [];
    for (let a of this.allEmails) {
      if (a.toLowerCase().indexOf(query.toLowerCase()) > -1 || this.selectedEmailOptions.includes(a)) {
        result.push(a)
      }
    }
    this.filteredAllEmails = result;
  }
  searchAllPMEmails(query: string) {
    let result: string[] = [];
    for (let a of this.allProjectManagerEmails) {
      if (a.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a)
      }
    }
    this.filteredPMEmails = result;
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  getAllEmpEmail() {
    this.userService.getAllUsersMailId().subscribe((res) => {
      this.allEmails = res.body;
      this.filteredAllEmails = this.allEmails;
      this.loadingflag--;
      this.cd.detectChanges();
    })
  }
  getAllPMEmail() {
    this.talentService.getProjectManagerEmails().subscribe((res) => {
      this.allProjectManagerEmails = res.body;
      this.allProjectManagerEmails.unshift('NA');
      this.allProjectManagerEmails = this.allProjectManagerEmails.filter((value, index, self) => self.indexOf(value) === index);

      this.filteredPMEmails = this.allProjectManagerEmails;
      this.loadingflag--;
      this.cd.detectChanges();
    })
  }

  closeDialog(event: Event) {
    this.dialogRef.close({ event: 'Closed' })
  }

  onStartDateChange(date: Date) {

    let year = date.getFullYear();
    // if (year != this.currentYear) {
    //   this.getAllLeaveBalances(year);
    // }

    // this.getAllLeaveBalances(year);
    // .then(()=>{
    //   });

    this.setLeaveBalance();
    if (this.newLeaveDetails.get('endDate').value) {
      this.setApplyingDays();
    }
    // this.minDateofYear = new Date(year, 0, 1);
    // this.maxDateOfYear = new Date(year, 11, 31);
  }
  checkLBandLAerrorFcn() {
    const endSessionControl = this.newLeaveDetails.get('endSession');
    const endDateControl = this.newLeaveDetails.get('endDate');
    const startDate1 = this.newLeaveDetails.get('startDate').value;
    const endDate1 = this.newLeaveDetails.get('endDate').value;
    const startSession1 = (this.newLeaveDetails.get('startSession').value == "First Half") ? 1 : 0.5;
    const endSession1 = (this.newLeaveDetails.get('endSession').value == "First Half") ? 0.5 : 1;

    if ((this.leaveBalance != null &&
      this.typedLADays != null &&
      this.leaveBalance < this.typedLADays)) {
      this.checkLBandLAerror = true;
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = "Don't have enough Leave Balance";
      this.submitButtonDisabled = true;
    }
    else if ((startDate1 != null && endDate1 != null) && (startDate1 > endDate1 || (startDate1 == endDate1 && startSession1 == 0.5 && endSession1 == 0.5))) {
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      console.log("startDate1" + startDate1);
      console.log("endDate1" + endDate1);


      this.errorMessage = "Start date should not be more than End date";
      this.submitButtonDisabled = true;
    }
    else if (this.typedLADays == 0) {
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = "Applying leave for zero days";
      this.submitButtonDisabled = true;
    }
    else if (this.typedLADays == 50) { // as response from backend is fifty if applying days limit excides
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = "Leave applying days limit exceeds";
      this.submitButtonDisabled = true;
      this.typedLADays = null;
      this.dayAString = null;
      this.cd.detectChanges();
    }
    else {
      this.checkLBandLAerror = false;
      endSessionControl.clearValidators();
      endSessionControl.setValidators([Validators.required]);
      endDateControl.clearValidators();
      endDateControl.setValidators([Validators.required]);
      this.submitButtonDisabled = false;
    }

    endSessionControl.updateValueAndValidity();
    endDateControl.updateValueAndValidity();
  }

  getAllLeaveBalances(year) {
    this.leaveBalanceService.getLeaveBalanceByIdAndYear(this.userId, year).subscribe(res => {
      this.leavebalanceData = res.body

      this.processEnumValuesToArray();
      if (this.data && this.data.existingLeave) {
        this.setExistingLeave();
      }
      this.loadingflag--;
      this.cd.detectChanges();
    },
      (err) => {
        this.leaveBalance = null;
        console.log("No Leave Balance details available for " + this.userDetailService.getUserDetails().firstName + " for the year " + year);

      });
  }
  onLeaveTypeChange() {
    this.setLeaveBalance();
  }
  setLeaveBalance() {
    let typeOfLeaveValue, typeOfLeaveKey;

    typeOfLeaveValue = this.newLeaveDetails.get('typeOfLeave').value;
    if (typeOfLeaveValue) {
      typeOfLeaveKey = this.getEnumKeyByEnumValue(TypeOfLeave, typeOfLeaveValue);
      if (this.leavebalanceData.balanceLeave[typeOfLeaveKey] == null) {
        this.leaveBalance = 0;
        this.cd.detectChanges();
      }
      else if (this.leavebalanceData.isEnabled[typeOfLeaveKey] == false) {
        this.leaveBalance = 0;
        this.cd.detectChanges();

      }
      else {
        this.leaveBalance = this.leavebalanceData.balanceLeave[typeOfLeaveKey];
        this.cd.detectChanges();
      }
      this.dayBString = (this.leaveBalance == 1) ? "Day" : "Days";
      this.checkLBandLAerrorFcn();

    }
  }

  onSessionChange() {// also run for endDate change
    console.log("this.newLeaveDetails.get('endDate').value =" + this.newLeaveDetails.get('endDate').value);


    if (this.newLeaveDetails.get('endDate').value && this.newLeaveDetails.get('startDate').value) {
      this.setApplyingDays();
    }
    // this.checkLBandLAerrorFcn();
  }
  setApplyingDays() {
    let startDate1 = new Date(this.newLeaveDetails.get('startDate').value);// as date zone issue : previous date was saved in backend
    let endDate1 = new Date(this.newLeaveDetails.get('endDate').value);
    let startSession1 = (this.newLeaveDetails.get('startSession').value == "First Half") ? 1 : 0.5;// for calculation
    let endSession1 = (this.newLeaveDetails.get('endSession').value == "First Half") ? 0.5 : 1;
    let startSession = (this.newLeaveDetails.get('startSession').value == "First Half") ? 1 : 2;//to send to backend
    let endSession = (this.newLeaveDetails.get('endSession').value == "First Half") ? 1 : 2;
    // add condition is sd is more than ed
    if (startDate1 && endDate1) {// by default startSession and endSession is set so not added condition
      if (startDate1 > endDate1 || (startDate1 == endDate1 && startSession1 == 0.5 && endSession1 == 0.5)) {
        this.typedLADays = null;
        this.dayAString = null;
        this.checkLBandLAerrorFcn();
        this.cd.detectChanges();
      }
      else {
        let tempLeaveDto = new LeaveApplicationDTO();
        tempLeaveDto.startDate = this.correctZoneOffset(startDate1);
        tempLeaveDto.endDate = this.correctZoneOffset(endDate1);
        tempLeaveDto.startSession = startSession;
        tempLeaveDto.endSession = endSession;
        tempLeaveDto.userId = this.userDetailService.getUserDetails().id
        // this.typedLADays = (endDate1.valueOf() - startDate1.valueOf()) / 86400000 - 1 + startSession1 + endSession1;
        // this.typedLADays= this.leaveapplicationservice.getLeaveTotalCount(tempLeaveDto);
        this.leaveapplicationservice.getLeaveTotalCount(tempLeaveDto).subscribe(
          (res) => {
            this.typedLADays = res.body;
            this.cd.detectChanges();
            if (this.typedLADays == 50) { //hard coded days if apply for more than a month in backend

              this.checkLBandLAerrorFcn();
            }
            else {// to remove error
              this.dayAString = (this.typedLADays == 1) ? "Day" : "Days";
              this.checkLBandLAerrorFcn();
            }

          },
          (error: any) => {
            // console.log("more 30 error: "+error);
          }
        );
      }
    }
  }

  //leave balance

  uploadAttachment(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      this.attachmentname = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgBase64Path: String = e.target.result;
        let imageContent = imgBase64Path.split(',')[1];
        let imageMetaData = imgBase64Path.split(',')[0];
        this.isAttachmentSaved = true;
        this.cd.detectChanges();
        this.attachmentBase64 = imageMetaData + ',' + imageContent;
        this.binary = btoa(this.attachmentBase64);

      };
      // to read extension      
      this.attachmentnameExtension = this.attachmentname.split('.').pop();
      if (this.attachmentnameExtension == 'pdf') {
        this.attachmentType = 'application/pdf';
      } else {
        this.attachmentType = this.attachmentnameExtension;
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  removeAttachment() {
    this.isAttachmentSaved = false;
    this.attachmentBase64 = null;
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }
  correctZoneOffset(date: Date) {
    const timezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffset);
    return date;
  }

  onSubmit() {
    debugger
    Object.values(this.newLeaveDetails.controls).forEach(control => {
      control.markAsTouched();
    });

    // if (this.newLeaveDetails.valid) {
    if (this.data && this.data.existingLeave && !this.data.isDisableDate) {
      // const existingLeave = this.existingLeave;


      this.existingLeave.typeOfLeave = this.getEnumKeyByEnumValue(TypeOfLeave, this.newLeaveDetails.get('typeOfLeave').value);
      this.existingLeave.careerManagerId = this.userDetailService.getUserDetails().careerManagerId;
      this.existingLeave.projectManagerId = this.newLeaveDetails.get('projectManagerId').value;
      let startDate = new Date(this.newLeaveDetails.get('startDate').value);
      let endDate = new Date(this.newLeaveDetails.get('endDate').value);
      this.existingLeave.startDate = this.correctZoneOffset(startDate);
      this.existingLeave.endDate = this.correctZoneOffset(endDate);
      this.existingLeave.startSession = (this.newLeaveDetails.get('startSession').value == "First Half") ? 1 : 2;
      this.existingLeave.endSession = (this.newLeaveDetails.get('endSession').value == "First Half") ? 1 : 2;
      this.existingLeave.ccMentions = this.newLeaveDetails.get('ccMentions').value;
      this.existingLeave.reasonForLeave = this.newLeaveDetails.get('reasonForLeave').value;
      this.existingLeave.contactNumber = this.userDetailService.getUserDetails().mobileNumber;
      this.existingLeave.transactionDateTime = new Date().getTime();
      this.existingLeave.doc_base = this.binary;

      // Update the existing leave using the leaveapplicationservice
      this.leaveapplicationservice.editLeaveApplication(this.existingLeave, this.existingLeave.id).subscribe((res) => {
        this.toaster.success('Successfully Updated', 'Saved', {});
        this.dialogRef.close();
      }, (error) => {
        // If other error, display generic error message
        if (error.status == 409) {
          // If bad request, display specific error message
          this.toaster.error('Leave already applied in selected dates range.', 'Error', {});
        } else {
          // If other error, display generic error message
          this.toaster.error('Something went wrong. Please try again later.', 'Error', {});
        }
      }
      );
    } else {
      // Logic for creating a new leave

      let currentLeaveDto = new LeaveApplicationDTO();
      currentLeaveDto.userId = this.userDetailService.getUserDetails().id;
      currentLeaveDto.typeOfLeave = this.getEnumKeyByEnumValue(TypeOfLeave, this.newLeaveDetails.get('typeOfLeave').value);
      currentLeaveDto.careerManagerId = this.userDetailService.getUserDetails().careerManagerId;
      currentLeaveDto.projectManagerId = this.newLeaveDetails.get('projectManagerId').value;
      var startDate = new Date(this.newLeaveDetails.get('startDate').value)
      var endDate = new Date(this.newLeaveDetails.get('endDate').value)
      currentLeaveDto.startDate = this.correctZoneOffset(startDate);
      currentLeaveDto.endDate = this.correctZoneOffset(endDate);
      currentLeaveDto.startSession = (this.newLeaveDetails.get('startSession').value == "First Half") ? 1 : 2;
      currentLeaveDto.endSession = (this.newLeaveDetails.get('endSession').value == "First Half") ? 1 : 2;
      currentLeaveDto.ccMentions = this.newLeaveDetails.get('ccMentions').value;
      currentLeaveDto.reasonForLeave = this.newLeaveDetails.get('reasonForLeave').value;
      currentLeaveDto.contactNumber = this.userDetailService.getUserDetails().mobileNumber;
      currentLeaveDto.transactionDateTime = new Date().getTime();
      currentLeaveDto.doc_base = this.binary;
      if (
        currentLeaveDto.userId != undefined &&
        currentLeaveDto.typeOfLeave != undefined &&
        currentLeaveDto.careerManagerId != undefined &&
        currentLeaveDto.projectManagerId != '' &&
        currentLeaveDto.startDate != undefined &&
        currentLeaveDto.endDate != undefined &&
        currentLeaveDto.startSession != undefined &&
        currentLeaveDto.endSession != undefined &&
        // currentLeaveDto.ccMentions !=undefined && 
        // currentLeaveDto.reasonForLeave !=undefined && 
        currentLeaveDto.transactionDateTime != undefined
        // currentLeaveDto.contactNumber != undefined
      ) {
        {
          console.log(currentLeaveDto)
          this.leaveapplicationservice.create(currentLeaveDto, (this.data && this.data.taskId) ? this.data.taskId : null).subscribe((res) => {
            this.toaster.success('Successfully Applied', 'Saved', {
            });
            this.dialogRef.close();
          }, (error) => {

            // console.log(JSON.stringify(error));
            // console.log("hellod" + error.error.detail);

            if (error.status == 409) {
              // If bad request, display specific error message
              this.toaster.error('Leave already applied for this date', 'Error', {});
            } else {
              // If other error, display generic error message
              this.toaster.error('Something went wrong. Please try again later.', 'Error', {});
            }
          })
        }
      }
    }
    // }
  }
}
