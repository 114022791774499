import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bulk-holidays-summary',
  templateUrl: './bulk-holidays-summary.component.html',
  styleUrls: ['./bulk-holidays-summary.component.scss']
})
export class BulkHolidaysSummaryComponent implements OnInit {

  
  public summaryContent:any;

  constructor(public dialogRef: MatDialogRef<BulkHolidaysSummaryComponent>, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data:any) {
     
    }

  ngOnInit(): void {
    
  }

  closeDialog(): void {
    //Close mat-dialog and erase data upon clicking close button
    //this.data = null;
    this.dialogRef.close();
  }

  

}
