<!-- header starts here -->
<div class="total">
    <mat-toolbar class="mat_toolbar" [class.hidden]="toggleSearch">
        <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
            <mat-icon class="hamburger" *ngIf="!sidenav.opened">
                menu
            </mat-icon>
            <mat-icon class="hamburger" *ngIf="sidenav.opened">
                close
            </mat-icon>
        </button>
        <div class="main_logo_div">
            <img class="Company_logo" src="/assets/BA_Logo_Icon.png" alt="">
            <span class="Company_name">Blue Pulse</span>
        </div>
        <span class="example-spacer"></span>
        <!-- <div>
            <mat-icon [matMenuTriggerFor]="menu" class="search-icon2">search</mat-icon>
            <mat-menu #menu="matMenu">
                <input class="search_input" type="search" placeholder="Search">
            </mat-menu>
        </div> -->

        <div class="search_main mt-0.5">
            <mat-icon class="search-icon ml-1 mr-2">search</mat-icon>
            <input class="search_input" [matAutocomplete]="userAuto" type="search" placeholder="Search"
                [formControl]="userControl">
            <mat-autocomplete #userAuto="matAutocomplete" (optionSelected)="selectUser($event.option.value)">
                <mat-option *ngFor="let user of filteredUsers | async | filter:userControl.value" [value]="user">
                     <span style="margin-left: 10px;">
                        <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
                        <!-- <img class="noemployee_img" [(src)]="user.userImageThumbnail!==null?user.userImageThumbnail:assets/default-avatar.jpg" alt="">  -->
                        {{ user.firstName + ' ' + user.lastName | titlecase}} </span>
                </mat-option>
            </mat-autocomplete>
        </div>
        <!-- <button mat-icon-button class="search_main2" (click)="openSearch()">
            <mat-icon>search</mat-icon>
        </button> -->
        <div class="attendance_main ml-4 ">
            <div *ngIf="mark_Attendance" style="display: flex;">
                <div>
                    <label class="attendance">Mark Attendance</label>
                </div>
                <a>
                    <img src="/assets/markattendance.png" class="attendance-img unmarked" alt=""
                        (click)="markAttendance()" [disabled]="disableState">
                </a>
            </div>
            <div *ngIf="attendance_Marked" style="display: flex;">
                <div>
                    <label class="attendance">Attendance Marked</label>
                </div>
                <a>
                    <img src="/assets/attendancemarked.png" class="attendance-img marked" alt="">
                </a>
            </div>
        </div>
        <!-- commenting the notification bell icon -->
        <!-- <div>
            <mat-icon class="header_notification mt-2 ml-5">notifications</mat-icon>
        </div> -->
        <div class="ml-5 ">
            <button mat-icon-button *ngIf="this.userDetailService.getUserDetails().userImageThumbnail !== ''"><img
                    id="profile_avatar" [(src)]="this.userDetailService.getUserDetails().userImageThumbnail"
                    [matMenuTriggerFor]="beforeMenu" alt="">
            </button>
            <button mat-icon-button *ngIf="this.userDetailService.getUserDetails().userImageThumbnail == ''"><img
                    id="profile_avatar" src="assets/default-avatar.jpg" [matMenuTriggerFor]="beforeMenu" alt="">
            </button>
            <mat-menu class="matmenu" #beforeMenu="matMenu">
                <button mat-menu-item (click)="editEmployeeProfileDialog(this.userdata)">
                    <mat-icon><img class="group_logo" src="assets/Group.png"></mat-icon>
                    <label class="profile_edit">Profile Edit</label>
                </button>
                <button mat-menu-item (click)="logOut()">
                    <mat-icon> <img class="logout_logo" src="assets/Logout.png"></mat-icon>
                    <label class="logout">Logout</label>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar>
    <mat-toolbar class="search-block mat-elevation-z4" [class.active]="toggleSearch">
        <mat-toolbar-row style="padding: 0 5px;">
            <button class="search-icon" mat-icon-button disabled>
                <mat-icon>search</mat-icon>
            </button>
            <input class="search-control" type="text" placeholder="Search" [(ngModel)]="searchText" #searchbar>
            <button mat-button mat-icon-button (click)="searchClose()">
                <mat-icon class="close_icon">close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- header ends here -->
    <!-- Sidebar Starts here -->



    <mat-sidenav-container>
        <mat-sidenav #sidenav id="sidenav" [disableClose]="true">
            <app-sidebar-menu></app-sidebar-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="main-content">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>


</div>
<!-- Sidebar Ends here -->