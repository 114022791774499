<div class="container-fluid">
  <div class="main_header_div">
    <div class="child">
      <h3 class="header_name"> {{this.data.leaveType | titlecase | underScoreToSpace}}</h3>
    </div>
    <div class="child_close_btn">
      <button class="closeButton" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>
<div class="row mt-0">
  <div class="col-md-12">
    <div class="table-fixed">
      <div class="bg" *ngIf="leavedetails.length==0">
        <div>No details available</div>
      </div>
      <table class="table-borderless" *ngIf="leavedetails.length!=0">
        <thead>
          <tr>
            <th class="details_name">Sr. No.</th>
            <th class="details_request">Requested On</th>
            <th class="details_from">From</th>
            <th class="details_to">To</th>
            <th class="details_days">No.of Days</th>
            <!-- <th class="details_day">Day</th> -->
            <th class="details_status">Status</th>
          </tr>
        </thead>

        <tbody>

          <tr class="bg" *ngFor="let details of leavedetails ">
            <td class="detail">
              {{ details.number }}
            </td>
            <td>

              {{ details.Requestedon | date}}

            </td>
            <td>

              {{ details.From | date}}

            </td>
            <td>

              {{ details.To | date }}

            </td>
            <td>

              {{ details.days}}

            </td>
            <!-- <td>
              
                  {{ details.Day }}
               
              </td> -->
            <td>

              {{ details.Status | titlecase}}

            </td>

          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>