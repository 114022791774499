interface IPassportDetails {
   id: string;
   country: string;
   passportNumber: String;
   expiryDate: Date;
   userId: string;
  //public passportbase:string
}

  export class PassportDetails{
    public id: string;
    public country: string;
    public passportNumber: String;
    public expiryDate: Date;
    public userId: string;
public passportb:string
  
  constructor() {
    this.id = undefined;
    this.country = '';
    this.passportNumber = '';
    this.expiryDate = undefined;
    this.userId = undefined;
    this.passportb = '';
  }
  bind(httpResponse: any) {
    this.id = (httpResponse && httpResponse.id) || undefined;
    this.country = httpResponse && httpResponse.country || undefined;
    this.passportNumber = httpResponse && httpResponse.passportNumber || undefined;
    this.expiryDate = httpResponse && httpResponse.expiryDate || undefined;
    this.userId = httpResponse && httpResponse.userId || undefined;
    this.passportb = httpResponse && httpResponse.passportb || undefined;
    return this;
  }
}
