import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MasterTableDataUrlsService {
  private readonly masterTableBaseUrl = '/api/get_master_leave';
  private readonly masterLeaveUrl= '/api/master_leave';
  constructor() { }

  getmasterTableDataForCalendar(designation){
    // return this.masterTableBaseUrl+'/'+designation;
    return this.masterTableBaseUrl+'/{designation}?designation='+designation;
  }
  createMasterLeaveUrl(){
    return this.masterLeaveUrl;
  }
}
