import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import {
  BloodGroup,
  Relation,
  RelationshipStatus,
} from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent implements OnInit {
  personalDetails: FormGroup;
  // userDto = new UserDTO();
  userDto: any;
  relationshipstatus = [];
  bloodgroup = [];
  relation = [];
  constructor(
    public userService: UserService,
    public userDetailService: UserDetailsService,
    public fb: FormBuilder,
    public toaster:ToastrService,
    public dialogRef: MatDialogRef<PersonalInformationComponent>,
  
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.personalDetails = this.fb.group({
      blood_group: ['', Validators.compose([Validators.required])],
      dob: ['', [Validators.required]],
      place_of_birth: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z ]*$'),
        ]),
      ],
      marital_status: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {

    //this.data[0] contains userid, and this.data[1] contains userDto will dob, place of birth, relationstatus and blood group details
    // this.userDto = this.userDetailService.getUserDetails();
    // let userid = this.userDetailService.getUserDetails().id;
    // this.userDetailService.getUserDetails().dateOfBirth;
    this.userDto = this.data[1];

    this.setFormData();
    this.processEnumValuesToArray();
  }
  setFormData() {
   
    // if (this.data) {
      this.personalDetails
        .get('dob')
        .setValue(
          this.userDto.dateOfBirth ? new Date(this.userDto.dateOfBirth) : ''
        );

      this.personalDetails
        .get('place_of_birth')
        .setValue(this.userDto.placeOfBirth);
      var bg = this.getEnumValueByEnumKey(BloodGroup, this.userDto.bloodGroup);
      this.personalDetails
        .get('blood_group')
        .setValue(this.userDto.bloodGroup ? bg : '');
      var ms = this.getEnumValueByEnumKey(
        RelationshipStatus,
        this.userDto.relationshipStatus
      );
      this.personalDetails
        .get('marital_status')
        .setValue(this.userDto.relationshipStatus ? ms : '');
    
  }

  processEnumValuesToArray() {
    for (let relation of Object.values(RelationshipStatus)) {
      if (isNaN(Number(relation))) this.relationshipstatus.push(relation);
    }
    for (let bloodGroup of Object.values(BloodGroup)) {
      if (isNaN(Number(bloodGroup))) this.bloodgroup.push(bloodGroup);
    }
    for (let relation of Object.values(Relation)) {
      if (isNaN(Number(relation))) this.relation.push(relation);
    }
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  getauthoritiesKeyByEnumValueArray(myEnum, enumValue) {
    return Object.keys(myEnum).filter((k) => enumValue.includes(myEnum[k]));
  }
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }
  getauthorityValueByEnumKeyArray(myEnum, enumKeyArr) {
    return enumKeyArr ? enumKeyArr.map((k) => myEnum[k]) : '';
  }
  
  checkError2 = (controlName: string, errorName: string) => {
    return this.personalDetails.controls[controlName].hasError(errorName);
  };
  closeDialog(event:Event) {
    this.dialogRef.close({ event: 'Closed'})
  }
  onSubmit() {
    
    if (this.personalDetails.valid) {
      this.userDto.bloodGroup = this.getEnumKeyByEnumValue(
        BloodGroup,
        this.personalDetails.get('blood_group').value
      );

      this.userDto.relationshipStatus = this.getEnumKeyByEnumValue(
        RelationshipStatus,
        this.personalDetails.get('marital_status').value
      );
      this.userDto.placeOfBirth =
        this.personalDetails.get('place_of_birth').value;
      this.userDto.dateOfBirth = this.personalDetails.get('dob').value;

      if (this.userDto) {
        
        // if (this.userDto.id) this.userDto.id = this.userDto.id;
        // this.userService.updateUser(this.userDto).subscribe((res) => {
          this.userService.setUserPersonalInformationDetails(this.data[0], this.userDto).subscribe((res) => {
         
          this.toaster.success("Successfully Saved")
          this.dialogRef.close({ event: 'Closed'})
          //this.notificationService.sucess("User Data Edited")
        });
       } 
      //  else {
      //   this.userDto.id = this.data[0];
      //   this.userService.addUser(this.userDto).subscribe((res) => {
      //     this.toaster.success("Successfully Saved")
      //     this.dialogRef.close({ event: 'Closed'})
      //     //this.notificationService.sucess("New User Created")
      //   });
      // }
    }
  }
}
