<div *ngIf="this.mobileview==false" class="col-md-12">
    <div class="topRow" *ngIf="(switchedTab=='REAL_TIME')">
        <div >
            <button class="space-btn" >
                <span class="add_em_name"></span>
            </button>
        </div>
        <div style="display: flex; align-items: center;" >
            <div *ngIf="this.isRealtimeScore">
                <button class=" cs-download" (click)="fileOrphan()">
                    <img src="assets/download.png" class="download-img">
                    <label class="text">
                        Real-time Feedback Rating Report
                    </label>
                </button>
            </div>
        </div>
    </div>
    <div class="topRow" *ngIf="!(switchedTab=='REAL_TIME')">
        <!-- <div class="back-col">
            ##commented_BackButton
            <figure (click)="backToProject()">
                <mat-icon><img class="upload_img" src="assets/back.png" alt=""></mat-icon>
                <label class="upload_text">Back</label>
            </figure>
        </div> -->
        <div *ngIf="!isHideConfigureFeedbackBtn">
            <button class="add_Emp_btn" (click)=" openFeedbackDialog()">
                <span class="add_em_name">Configure Feedback</span>
            </button>
        </div>
        <div *ngIf="isHideProbationFeedbackBtn && !disableProbationfeedbackForm && !hideConfigureProbation">
            <button class="probation-btn" (click)="openProbationDialog()">
                <span class="add_em_name">Configure Probation Feedback</span>
            </button>
        </div>

        <div style="display: flex; align-items: center;" *ngIf="!isHideConfigureFeedbackBtn">
            <div *ngIf="showHideBtn">
                <button class=" cs-download" (click)="fileAdhoc()">
                    <img src="assets/download.png" class="download-img">
                    <label class="text">
                        Questions Rating Report
                    </label>
                </button>
            </div>

            <button class="close-feedback " (click)="CloseFeedback()" *ngIf="this.disablefeedbackForm==false">
                <span class="add_em_name">Close Feedback</span>
            </button>
        </div>
        <div *ngIf="isHideProbationFeedbackBtn && !disableProbationfeedbackForm &&  hideConfigureProbation">
            <button class="close-prob-feedback" (click)="closeProbationFeedback()">
                <span class="add_em_name">Close Probation Feedback</span>
            </button>
        </div>
    </div>

</div>
<div *ngIf="this.mobileview==true">
    <div class="row button-mobile" *ngIf="!(switchedTab=='REAL_TIME')">
        <div class="col
         back-col">
            <!-- <figure (click)="backToProject()">
                <mat-icon><img class="upload_img" src="assets/back.png" alt=""></mat-icon>
                <label class="upload_text">Back</label>
            </figure> -->
        </div>
        <div *ngIf="isHideProbationFeedbackBtn && !disableProbationfeedbackForm && !hideConfigureProbation">
            <button class="probation-btn" (click)="openProbationDialog()">
                <span class="add_em_name">Configure Probation Feedback</span>
            </button>
        </div>

        <div *ngIf="isHideProbationFeedbackBtn && !disableProbationfeedbackForm && hideConfigureProbation">
            <button class="close-prob-feedback" (click)="closeProbationFeedback()">
                <span class="add_em_name">Close Probation Feedback</span>
            </button>
        </div>

        <div *ngIf="this.disablefeedbackForm==false && !isHideConfigureFeedbackBtn">
            <button class="add_Emp_btn" (click)=" openFeedbackDialog()"> <span class="add_em_name">Configure
                    Feedback</span>
            </button>
            <button class="close-feedback " (click)="CloseFeedback()"> <span class="add_em_name">Close Feedback</span>
            </button>
        </div>
        <div *ngIf="!isHideConfigureFeedbackBtn">
            <button class=" cs-download" (click)="file()">
                <img src="assets/download.png" class="download-img">
                <label class="text">
                    Questions Rating Report
                </label>
            </button>

        </div>

    </div>
</div>
<div *ngIf="this.mobileview==false" class="col-md-12">
    <mat-card class="matcardprofiledetail ">
        <mat-card-content>
            <div class="row ">
                <div class=" coloums ">
                    <img src="assets/default-avatar.jpg" class="img-container">
                </div>
                <div class=" col">
                    <h5 class="reportee-name">{{this.userdto.firstName + ' ' + this.userdto.lastName}}</h5>
                    <p class="count"> ID {{this.userdto.employeeId}}</p>
                </div>
                <div class="col" *ngIf="!isHideConfigureFeedbackBtn && !(this.switchedTab=='REAL_TIME')">
                    <label *ngIf="this.temp<100" class="count green"> {{this.temp | number:'1.2-2'}}%</label>
                    <label *ngIf="this.temp>100" class="count red"> {{this.temp | number:'1.2-2'}}%</label>
                    <label *ngIf="this.temp==100" class="count grey"> {{this.temp | number:'1.2-2'}}%</label>
                </div>
                <div class="col" *ngIf="!hideYear && !(this.switchedTab=='REAL_TIME')">
                    <label class="asset-common">Score </label>
                    <p *ngIf="this.hide==!true" class="reportee-score">{{
                        this.userdto.totalScore | number:'1.2-2'}}/5
                    </p>
                    <p *ngIf="this.hide==true" class="hide">●●●</p>
                </div>
                <div class="col" *ngIf="hideYear && !(this.switchedTab=='REAL_TIME')">
                    <label class="asset-common">Score </label>
                    <p *ngIf="this.hideProbation==!true" class="reportee-score">
                        {{this.probationScore | number:'1.2-2'}}/5
                    </p>
                    <p *ngIf="this.hideProbation==true" class="hide">●●●</p>
                </div>
                <div *ngIf="this.ifManager" (click)=" EditFeedbackNotes()" class="col notes-div ">
                    <div>
                        <img class="notes-img" src="assets/notes.png" />
                    </div>
                    <div>
                        <label class="notes">Notes </label>
                    </div>
                </div>
                <div>
                    <div class="col show" *ngIf="!hideYear && !(this.switchedTab=='REAL_TIME')">
                        <label>
                            <button mat-icon-button matSuffix type="button" (click)="this.hide = !this.hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                                class="asset-common">{{hide? "Show Scores": "Hide Scores"}}
                                <mat-icon Pass through>{{ hide ? "visibility_off" : "visibility"
                                    }}
                                </mat-icon>
                            </button>
                        </label>
                    </div>
                    <div class="col" *ngIf="hideYear">
                        <label>
                            <button mat-icon-button matSuffix type="button"
                                (click)="this.hideProbation = !this.hideProbation" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hideProbation" class="asset-common">
                                {{hideProbation? "Show Scores": "Hide Scores"}}
                                <mat-icon style="color: #999999" Pass through>
                                    {{ hideProbation ? "visibility_off" : "visibility" }}
                                </mat-icon>
                            </button>
                        </label>
                    </div>
                </div>
                <div class="col" *ngIf="!hideYear&&(this.switchedTab=='REAL_TIME')">
                    <label>
                        <button mat-icon-button matSuffix type="button"
                            (click)="this.hideRealTime = !this.hideRealTime" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hideProbation" class="asset-common">
                            {{hideRealTime? "Show Scores": "Hide Scores"}}
                            <mat-icon style="color: #999999" Pass through>
                                {{ hideRealTime ? "visibility_off" : "visibility" }}
                            </mat-icon>
                        </button>
                    </label>
                </div>
                <div class="col  year-div">
                    <div class="left-margin" *ngIf="!hideYear">
                        <label class="asset-common">Year </label>
                        <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
                            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                        </mat-select>
                    </div>
                    <!-- porbation closed year -->
                    <!-- <div class="left-margin closeYear" *ngIf="hideYear && isRedirected ">
                        <label class="asset-common">Closed Year </label>
                        <mat-select class="disabled-year" [(value)]="selected" (selectionChange)="loadForm($event)" [disabled]="true">
                            <mat-option *ngFor="let year of years" [value]="year">{{ probationCloseYear }}</mat-option>
                        </mat-select>
                    </div> -->
                    <div class="left-margin" *ngIf="isHideProbationFeedbackBtn && disableProbationfeedbackForm">
                        <!--  <label class="asset-common">Probation Feedback Closed  </label>
                         <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
                            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                        </mat-select> -->
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div *ngIf="this.mobileview==true">
    <mat-card class="matcardprofiledetail">
        <mat-card-content>
            <div class="row1 profile-mobile">
                <div>
                    <img src="assets/default-avatar.jpg" class="img-container">
                </div>
                <div class="user-details">
                    <div>
                        <h5 class="reportee-name">{{this.userdto.firstName + ' ' + this.userdto.lastName}}</h5>
                        <label class="count"> ID {{this.userdto.employeeId}}</label>

                    </div>
                </div>
                <div>
                    <label class="asset-common1">Year </label>
                    <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
                        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="flex">
                <div class="col coloums">
                    <h5 class="asset-common">Weightage</h5>
                </div>
                <div class="col">
                    <p class="asset-common">
                        <label *ngIf="this.temp<100" class="asset-common green"> {{this.temp | number:'1.2-2'}}%</label>
                        <label *ngIf="this.temp>100" class="asset-common red"> {{this.temp | number:'1.2-2'}}%</label>
                        <label *ngIf="this.temp==100" class="asset-common grey"> {{this.temp | number:'1.2-2'}}%</label>
                    </p>
                </div>
            </div>
            <div class="flex">
                <div class="col coloums">
                    <h5 class="asset-common">Score</h5>
                </div>
                <div class="col" *ngIf="!hideYear">
                    <label *ngIf="this.hide==!true" class="asset-common1"> {{this.userdto.totalScore |
                        number:'1.2-2'}}/5</label>
                    <label *ngIf="this.hide==true" class="hide">●●●</label>
                </div>
                <div class="col" *ngIf="hideYear">
                    <label *ngIf="this.hideProbation==!true" class="asset-common1">
                        {{this.probationScore | number:'1.2-2'}}/5</label>
                    <label *ngIf="this.hideProbation==true" class="hide">●●●</label>
                </div>
            </div>
            <div class="flex">
                <div class="col coloums">
                    <div class="notes-div">
                        <div>
                            <img class="notes-img" src="assets/notes.png" (click)=" EditFeedbackNotes()" />
                        </div>
                        <div>
                            <p class="notes" (click)=" EditFeedbackNotes()">Notes </p>
                        </div>
                    </div>
                </div>
                <div class="col" *ngIf="!hideYear">
                    <label>
                        <button mat-icon-button matSuffix type="button" (click)="this.hide = !this.hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" class="asset-common">{{hide?
                            "Show Scores": "Hide Scores"}}
                            <mat-icon style="color: #999999" Pass through>{{ hide ? "visibility_off" : "visibility"
                                }}
                            </mat-icon>
                        </button>
                    </label>
                </div>
                <div class="col" *ngIf="hideYear">
                    <label>
                        <button mat-icon-button matSuffix type="button"
                            (click)="this.hideProbation = !this.hideProbation" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hideProbation" class="asset-common">
                            {{hideProbation? "Show Scores": "Hide Scores"}}
                            <mat-icon style="color: #999999" Pass through>
                                {{ hideProbation ? "visibility_off" : "visibility" }}
                            </mat-icon>
                        </button>
                    </label>
                </div>
                <div class="col" *ngIf="(this.switchedTab=='REAL_TIME')">
                    <label>
                        <button mat-icon-button matSuffix type="button"
                            (click)="this.hideRealTime = !this.hideRealTime" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hideProbation" class="asset-common">
                            {{hideRealTime? "Show Scores": "Hide Scores"}}
                            <mat-icon style="color: #999999" Pass through>
                                {{ hideRealTime ? "visibility_off" : "visibility" }}
                            </mat-icon>
                        </button>
                    </label>
                </div>
            </div>
            <!-- <div class="notes-div">
            <div *ngIf="this.ifManager" class="notes-div">
                <div>
                    <img class="notes-img" src="assets/notes.png" (click)=" EditFeedbackNotes()" />
                </div>
                <div>
                    <p class="notes" (click)=" EditFeedbackNotes()">Notes </p>
                </div>
            </div> -->
        </mat-card-content>
    </mat-card>
</div>
<hr>

<div class="col-md-12">
    <div class=" content">
        <mat-tab-group class="tab-group" [selectedIndex]="selectIndexForTab"
            (selectedTabChange)="onTabChanged($event);">
            <mat-tab class="mat-tab-label-active">
                <ng-template mat-tab-label>
                    <mat-icon class="projects">description</mat-icon>
                    Goal Sheet
                </ng-template>
                <div class="">
                    <app-cm-goal-sheet [userid]="userid"></app-cm-goal-sheet>
                </div>
            </mat-tab>
            <mat-tab class="mat-tab-label-active">
                <ng-template mat-tab-label class="tab-2">

                    <mat-icon class="projects">rate_review</mat-icon>

                    Annual Feedback
                </ng-template>
                <div class="  mainContent">
                    <div>
                        <mat-accordion>
                            <mat-expansion-panel class="border" *ngFor="let data of this.feedbackgroupdata"
                                (opened)="open(data)" (closed)="close(data)" hideToggle="this.mobileview">
                                {{showQuestionBtn(data)}}
                                <mat-expansion-panel-header class="matcardprojectdetail">
                                    <div *ngIf="this.mobileview==false" class="mystyle">
                                        <div class="goal-icon-div top-margin ">
                                            <mat-icon class="mat-icon2">
                                                description
                                            </mat-icon>
                                        </div>
                                        <div class="col ">
                                            <p class="feedback-name">
                                                {{data.title}}{{this.projectIDMapping[data.projectId]}}
                                            </p>
                                        </div>
                                        <div class="col coloums ">
                                            <h5 class="client-common">Weightage </h5>
                                            <p class="allocation">
                                                <label *ngIf="(data.groupWeightage*100)<100" class="asset-common green">
                                                    {{data.groupWeightage*100 | number:'1.2-2'}}%</label>
                                                <label *ngIf="(data.groupWeightage*100)>100" class="asset-common red">
                                                    {{data.groupWeightage*100 | number:'1.2-2'}}%</label>
                                                <label *ngIf="(data.groupWeightage*100)==100" class="asset-common grey">
                                                    {{data.groupWeightage*100 | number:'1.2-2'}}%</label>
                                            </p>
                                        </div>
                                        <div class="col coloums">
                                            <h5 class="client-common">Score </h5>
                                            <p *ngIf="this.hide==!true" class="count">{{data.overallScore |
                                                number:'1.2-2'}}/5</p>
                                            <p *ngIf="this.hide==true" class="hide">●●●</p>
                                        </div>
                                        <div class="col coloums  " *ngIf="this.disablefeedbackForm==false">
                                            <button class="client-common1"
                                                (click)="EditFeedbackDialog(data,true)">Manage
                                                Feedback</button>
                                        </div>
                                        <!-- <div class="col coloums center">
                                            <label class="client-common1" (click)="onSubmit()"
                                                *ngIf="this.disablefeedbackForm==false">Save</label>
                                        </div> -->
                                        <div class="dropdown">
                                            <button class="lbl"> Calibrated Board
                                                <div>
                                                </div>
                                                <mat-icon class="mat-icon" *ngIf="!this.opendiv">
                                                    expand_more
                                                </mat-icon>
                                                <mat-icon class="mat-icon" *ngIf="this.opendiv">
                                                    expand_less
                                                </mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="this.mobileview==true">
                                        <div class="flex">
                                            <div class="goal-icon-div top-margin ">
                                                <mat-icon class="mat-icon2">
                                                    description
                                                </mat-icon>
                                            </div>
                                            <div class="col ">
                                                <p class="feedback-name">
                                                    {{data.title}}{{this.projectIDMapping[data.projectId]}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="col coloums">
                                                <h5 class="asset-common">Weightage</h5>
                                            </div>
                                            <div class="col">
                                                <p class="asset-common">
                                                    <label *ngIf="this.temp<100" class="asset-common green">
                                                        {{this.temp}}%</label>
                                                    <label *ngIf="this.temp>100" class="asset-common red">
                                                        {{this.temp}}%</label>
                                                    <label *ngIf="this.temp==100" class="asset-common grey">
                                                        {{this.temp}}%</label>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="col coloums">
                                                <h5 class="asset-common">Score</h5>
                                            </div>
                                            <div class="col">
                                                <p *ngIf="this.hide==!true" class="asset-common1">
                                                    {{data.overallScore | number:'1.2-2'}}/5</p>
                                                <p *ngIf="this.hide==true" class="hide">●●●</p>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="col coloums mb-3" *ngIf="this.disablefeedbackForm==false">
                                                <button class="asset-common2"
                                                    (click)="EditFeedbackDialog(data,this.isOpen)">
                                                    Manage
                                                    Feedback</button>
                                            </div>

                                        </div>
                                        <div class="flex mb-4">

                                            <div class="col">
                                                <mat-icon *ngIf="!this.opendiv">
                                                    expand_more
                                                </mat-icon>
                                                <mat-icon *ngIf="this.opendiv">
                                                    expand_less
                                                </mat-icon>
                                            </div>
                                        </div>

                                    </div>

                                </mat-expansion-panel-header>
                                <form [formGroup]="calibratedScoreForm">

                                    <table class="table-borderless mystyle1 table">
                                        <thead>
                                            <tr class="title">
                                                <th class="emp-title ml-2" style="width: 200px;">Employee</th>
                                                <th class="emp-title " style="width: 180px;">Role</th>
                                                <th class="emp-title">Actual Score </th>
                                                <th class="emp-title input">Calibrated Score</th>
                                                <th class="emp-title mt-5" id="tooltip">
                                                    <label *ngIf="weightSum<100" class="green1 ">
                                                        <div class="tooltipdiv " [matTooltipPosition]="position.value"
                                                            [matTooltipClass]="position.value"
                                                            matTooltip="Weightage sum should be 100">i</div>
                                                        Weightage
                                                    </label>
                                                    <label *ngIf="weightSum>100" class="red1">
                                                        <div [matTooltipPosition]="position.value"
                                                            [matTooltipClass]="position.value"
                                                            matTooltip="Weightage not greater than 100">i
                                                        </div>
                                                        Weightage
                                                    </label>
                                                    <label *ngIf="weightSum===100" class="grey1">
                                                        Weightage</label>
                                                </th>
                                                <th class="emp-title">Weightage Score</th>
                                                <th class="emp-title"></th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            <tr class="title" formArrayName="array"
                                                *ngFor="let feedback of this.opinion().controls; let i = index">

                                                <td [formGroupName]="i" data-label="Employee">
                                                    <label>

                                                        <input class=" user_email " matInput
                                                            formControlName="feedbackFrom" readonly>
                                                    </label>
                                                </td>
                                                <td [formGroupName]="i" data-label="Role">
                                                    <!-- <input class="user_email " matInput formControlName="feedbackRole" 
                                                        readonly [value]="feedbackRole" > {{feedbackRole | underScoreToSpace |
                                                            titlecase}} -->

                                                    <label class="user_email">{{feedback.value.feedbackRole |
                                                        underScoreToSpace |
                                                        titlecase}}</label>
                                                </td>
                                                <!-- <td [formGroupName]="i" data-label="Role">
                                                    <label class="user_email " matInput 
                                                    > {{feedbackRole | underScoreToSpace |
                                                            titlecase}}</label>
                                                </td> -->
                                                <td [formGroupName]="i" data-label="Actual Score"
                                                    *ngIf="calibratedScoreForm.value.array[i].feedbackSubmissionStatus!=='DECLINED'">

                                                    <div *ngIf="this.actualscorediv[i]">
                                                        <input *ngIf="this.hide==!true" class="user_email" matInput
                                                            formControlName="score" readonly>
                                                        <input *ngIf="this.hide==true" class="hide user_email" matInput
                                                            readonly value="●●●">

                                                    </div>
                                                    <div *ngIf="!this.actualscorediv[i]">
                                                        <label class="user_email1 decline-row">
                                                            <div>
                                                                Pending

                                                            </div>
                                                            <div>
                                                                <button class="remind"
                                                                    (click)="remind(feedback.value.id,i)"
                                                                    [disabled]="this.reminderStatus[i]">
                                                                    <mat-icon class="notification-icon">
                                                                        circle_notifications</mat-icon>
                                                                    Remind
                                                                </button>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td [formGroupName]="i" data-label="Actual Score"
                                                    *ngIf="calibratedScoreForm.value.array[i].feedbackSubmissionStatus=='DECLINED'">

                                                    <div *ngIf="this.actualscorediv[i]">
                                                        <input class=" hide" matInput formControlName="score" readonly>
                                                    </div>
                                                    <div *ngIf="!this.actualscorediv[i]">
                                                        <label class="user_email1 decline-row">
                                                            <div>
                                                                Declined


                                                            </div>
                                                            <div class=" ">

                                                                <img src="assets\yellow_tool_tip.png"
                                                                    class="yellow_tooltip"
                                                                    [matTooltipPosition]="position.value"
                                                                    [matTooltipClass]="position.value"
                                                                    [matTooltip]="calibratedScoreForm.value.array[i].feedbackDeclinedReason">
                                                            </div>
                                                        </label>

                                                    </div>
                                                    <!-- <label *ngIf="weightSum<100" class="green1 mt-3">
                                                        <div class="tooltipdiv "
                                                            matTooltip="Weightage sum should be 100"
                                                            matTooltipPosition="above">i</div>
                                                        Weightage
                                                    </label> -->
                                                </td>
                                                <td [formGroupName]="i" data-label="Calibrated Score">
                                                    <mat-form-field appearance="outline" class=" input user_email "
                                                        *ngIf="this.disablefeedbackForm==false&&this.actualscorediv[i]">
                                                        <input *ngIf="this.hide==!true" matInput
                                                            formControlName="calibratedScore" type="number">
                                                        <input class="hide " *ngIf="this.hide==true" matInput readonly
                                                            value="●●●">
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class=" input user_email "
                                                        *ngIf="this.disablefeedbackForm==false&&!this.actualscorediv[i]">
                                                        <input class="hide" *ngIf="this.hide==!true " matInput
                                                            formControlName="calibratedScore" readonly type="number">
                                                        <input class="hide" *ngIf="this.hide==true" matInput readonly
                                                            value="●●●">
                                                    </mat-form-field>

                                                    <mat-form-field appearance="outline" class=" input user_email "
                                                        *ngIf="this.disablefeedbackForm==true">
                                                        <input *ngIf="this.hide==!true" matInput
                                                            formControlName="calibratedScore" readonly type="number">
                                                        <input class="hide" *ngIf="this.hide==true" matInput readonly
                                                            value="●●●">
                                                    </mat-form-field>
                                                </td>
                                                <td [formGroupName]="i" data-label="Weightage">
                                                    <mat-form-field appearance="outline" class="user_email"
                                                        *ngIf="!this.disablefeedbackForm && calibratedScoreForm.value.array[i].feedbackRole != 'SELF_EVALUATION'">
                                                        <input matInput formControlName="weightage" type="number"
                                                            (ngModelChange)="onOpinionWeightagetype(i)">
                                                        <mat-error
                                                            *ngIf="feedback.get('weightage').errors?.InvalidWeightage">
                                                            {{Error}}
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <mat-form-field appearance="outline" class="  user_email"
                                                        *ngIf="this.disablefeedbackForm ||  calibratedScoreForm.value.array[i].feedbackRole == 'SELF_EVALUATION'">
                                                        <input matInput formControlName="weightage" readonly>
                                                        <mat-error
                                                            *ngIf="feedback.get('weightage').errors?.InvalidWeightage">
                                                            {{Error}}
                                                        </mat-error>
                                                    </mat-form-field>
                                                </td>
                                                <td data-label="Weightage Score">
                                                    <!-- <input *ngIf="this.hide==!true" class="user_email table-score"
                                                        matInput [value]="weitatageScore[i]" readonly> -->
                                                    <label *ngIf="this.hide==!true"
                                                        class="user_email table-score">{{weitatageScore[i] |
                                                        number:'1.2-2'}}</label>
                                                    <label class="hide user_email" *ngIf="this.hide==true">
                                                        ●●●</label>
                                                </td>
                                                <td class="mob-view-dots">
                                                    <div class="dots-img">

                                                        <img *ngIf="this.actualscorediv[i]" src="assets/3dots.png"
                                                            [matMenuTriggerFor]="beforeMenu" class="threedots ">
                                                    </div>

                                                    <mat-menu class="matmenu" #beforeMenu="matMenu" xPosition="before">

                                                        <button mat-menu-item (click)="view(feedback)"
                                                            *ngIf="calibratedScoreForm.value.array[i].feedbackSubmissionStatus==='SUBMITTED'">
                                                            <div>
                                                                <label class="view">View</label>
                                                            </div>
                                                        </button>
                                                        <button mat-menu-item (click)="resubmit(feedback.value.id)"
                                                            *ngIf="this.ifHR">
                                                            <span class="view">Re-Submit</span>
                                                        </button>
                                                    </mat-menu>
                                                </td>



                                            </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                        <div class="button-div">
                                            <button [disabled]="this.disablefeedbackForm" class="cancel button"
                                                (click)="close(data)">Cancel</button>
                                            <button [disabled]="this.disablefeedbackForm" class="save button"
                                                (click)="onSubmit()">Save</button>
                                        </div>
                                    </div>
                                    <!-- <button class="client-common2" (click)="onSubmit()"
                                    *ngIf="this.disablefeedbackForm==false">Save</button> -->
                                </form>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </mat-tab>

            <!-- realTime starts here -->
            <mat-tab class="mat-tab-label-active" onclick="changeToProbation('REAL_TIME')">
                <ng-template mat-tab-label>
                    <mat-icon class="projects">rate_review</mat-icon>
                    Real-time Feedback
                </ng-template>
                <div class="  mainContent">
                    <div>
                        <div class="no-data" *ngIf="realTimefeedbackgroupdata.length==0">
                            No Real-time Feedback Available
                        </div>
                        <div>
                            <div class="border-box" *ngFor="let data of this.realTimefeedbackgroupdata">

                                <div class="matcardprojectdetail">
                                    <div class="mystyle">
                                        <div class="goal-icon-div top-margin ">
                                            <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
                                        </div>
                                        <div class="col ">
                                            <p class="feedback-name">
                                                {{data.createdByName}}
                                            </p>
                                        </div>
                                        <div class="col ">
                                            <h5 class="client-common">Feedback title </h5>
                                            <p class="count"> {{data.title }}</p>
                                        </div>

                                        <div class="col coloums">
                                            <h5 class="client-common">Score </h5>
                                            <p *ngIf="this.hideRealTime==!true" class="count">{{data.feedbackFrom[0].score |
                                                number:'1.2-2'}}/5</p>
                                            <p *ngIf="this.hideRealTime==true" class="hide">●●●</p>
                                        </div>

                                        <div class="col coloums">
                                            <h5 class="client-common">Given feedback as </h5>
                                            <p class="count"> {{data.feedbackRole | underScoreToSpace| titlecase }}</p>
                                        </div>

                                        <div class="col coloums">
                                            <button class="view-btn" (click)="viewRealTime(data)">View</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <!-- Probation starts @here -->
            <!-- <mat-tab class="mat-tab-label-active" onclick="changeToProbation('PROBATION')" *ngIf="this.probationTabVisible">
                <ng-template mat-tab-label>
                    <mat-icon class="projects">rate_review</mat-icon>
                    Probation End Feedback
                </ng-template>
                <div class="">

                    <div class="  mainContent">
                        <div>
                            <div class="no-data" *ngIf="probationFeedbackGroupdata.length==0">
                                No Probation Feedback Available
                            </div>

                            <mat-accordion>
                                <mat-expansion-panel class="border" *ngIf="probationFeedbackGroupdata.length!=0"
                                    (opened)="open(probationFeedbackGroupdata)"
                                    (closed)="closeProbationDiv(probationFeedbackGroupdata)"
                                    hideToggle="this.mobileview">
                                    <mat-expansion-panel-header class="matcardprojectdetail">
                                        <div *ngIf="this.mobileview==false" class="mystyle">
                                            <div class="goal-icon-div top-margin ">
                                                <mat-icon class="mat-icon2">
                                                    description
                                                </mat-icon>
                                            </div>
                                            <div class="col ">
                                                <p class="feedback-name">
                                                    Probation Feedback
                                                </p>
                                            </div>

                                            <div class="col coloums">
                                                <h5 class="client-common">Score </h5>
                                                <p *ngIf="this.hideProbation==!true" class="count">
                                                    {{probationFeedbackGroupdata.overallScore |
                                                    number:'1.2-2'}}/5</p>
                                                <p *ngIf="this.hideProbation==true" class="hide">●●●</p>
                                            </div>
                                            <div class="col coloums  " *ngIf="this.disableProbationfeedbackForm==false">
                                                <button class="client-common1"
                                                    (click)="EditProbationDialog(probationFeedbackGroupdata,true)">Manage
                                                    Feedback</button>
                                            </div>

                                            <div class="dropdown">
                                                <button class="lbl"> Calibrated Board
                                                    <div>
                                                    </div>
                                                    <mat-icon class="mat-icon" *ngIf="!this.openProbationdiv">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-icon class="mat-icon" *ngIf="this.openProbationdiv">
                                                        expand_less
                                                    </mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="this.mobileview==true">
                                            <div class="flex">
                                                <div class="goal-icon-div top-margin ">
                                                    <mat-icon class="mat-icon2">
                                                        description
                                                    </mat-icon>
                                                </div>
                                                <div class="col ">
                                                    <p class="feedback-name">
                                                        Probation Feedback
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="flex">
                                                <div class="col coloums">
                                                    <h5 class="asset-common">Score</h5>
                                                </div>
                                                <div class="col">
                                                    <p *ngIf="this.hideProbation==!true" class="asset-common1">
                                                        {{probationFeedbackGroupdata.overallScore | number:'1.2-2'}}/5
                                                    </p>
                                                    <p *ngIf="this.hideProbation==true" class="hide">●●●</p>
                                                </div>
                                            </div>
                                            <div class="flex">
                                                <div class="col coloums mb-3"
                                                    *ngIf="this.disableProbationfeedbackForm==false">
                                                    <button class="asset-common2"
                                                        (click)="EditProbationDialog(probationFeedbackGroupdata,this.isProbationOpen)">
                                                        Manage
                                                        Feedback</button>
                                                </div>
                                                <div class="col coloums mb-3" *ngIf="this.disableProbationfeedbackForm">
                                                    <button class="asset-common2" class="disabled-button">
                                                        Manage Probation Feedback</button>
                                                </div>

                                            </div>
                                            <div class="flex mb-4">

                                                <div class="col">
                                                    <mat-icon *ngIf="!this.openProbationdiv">
                                                        expand_more
                                                    </mat-icon>
                                                    <mat-icon *ngIf="this.openProbationdiv">
                                                        expand_less
                                                    </mat-icon>
                                                </div>
                                            </div>

                                        </div>

                                    </mat-expansion-panel-header>
                                    <form [formGroup]="calibratedScoreForm">

                                        <table class="table-borderless mystyle1 table">
                                            <thead>
                                                <tr class="title">
                                                    <th class="emp-title ml-2" style="width: 200px;">Employee</th>
                                                    <th class="emp-title " style="width: 180px;">Role</th>
                                                    
                                                    <th class="emp-title">Actual Score </th>

                                                    <th class="emp-title"></th>
                                                </tr>
                                            </thead>

                                            <tbody>

                                                <tr class="title" formArrayName="array"
                                                    *ngFor="let feedback of this.opinion().controls; let i = index">

                                                    <td [formGroupName]="i" data-label="Employee">
                                                        <label>

                                                            <input class=" user_email " matInput
                                                                formControlName="feedbackFrom" readonly>
                                                        </label>
                                                    </td>
                                                    <td [formGroupName]="i" data-label="Role">
                                                       

                                                        <label class="user_email">{{feedback.value.feedbackRole |
                                                            underScoreToSpace | titlecase}}</label>
                                                    </td>
                                                    
                                                    <td [formGroupName]="i" data-label="Actual Score"
                                                        *ngIf="calibratedScoreForm.value.array[i].feedbackSubmissionStatus!=='DECLINED'">

                                                        <div
                                                            *ngIf="this.actualscorediv[i] && feedback.value.feedbackRole!='REVERSE_PROBATION_FEEDBACK'">
                                                            <input *ngIf="this.hideProbation==!true" class="user_email"
                                                                matInput formControlName="score" readonly>
                                                            <input *ngIf="this.hideProbation==true"
                                                                class="hide user_email" matInput readonly value="●●●">

                                                        </div>
                                                        <div *ngIf="!this.actualscorediv[i]">
                                                            <label class="user_email1 decline-row">
                                                                <div>
                                                                    Pending
                                                                </div>
                                                                <div>
                                                                    <button class="remind"
                                                                        (click)="remindProbation(feedback.value.id,i)"
                                                                        [disabled]="this.reminderStatus[i]">
                                                                        <mat-icon class="notification-icon">
                                                                            circle_notifications</mat-icon>
                                                                        Remind
                                                                    </button>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td [formGroupName]="i" data-label="Actual Score"
                                                        *ngIf="calibratedScoreForm.value.array[i].feedbackSubmissionStatus=='DECLINED'">

                                                        <div *ngIf="this.actualscorediv[i]">
                                                            <input class=" hide" matInput formControlName="score"
                                                                readonly>
                                                        </div>
                                                        <div *ngIf="!this.actualscorediv[i]">
                                                            <label class="user_email1 decline-row">
                                                                <div>
                                                                    Declined


                                                                </div>
                                                                <div class=" ">

                                                                    <img src="assets\yellow_tool_tip.png"
                                                                        class="yellow_tooltip"
                                                                        [matTooltipPosition]="position.value"
                                                                        [matTooltipClass]="position.value"
                                                                        [matTooltip]="calibratedScoreForm.value.array[i].feedbackDeclinedReason">
                                                                </div>
                                                            </label>

                                                        </div>

                                                    </td>

                                                    <td class="mob-view-dots">
                                                        <div class="dots-img">

                                                            <img *ngIf="this.actualscorediv[i]" src="assets/3dots.png"
                                                                [matMenuTriggerFor]="beforeMenu" class="threedots ">
                                                        </div>

                                                        <mat-menu class="matmenu" #beforeMenu="matMenu"
                                                            xPosition="before">
                                                            
                                                            <button mat-menu-item (click)="viewProbation(feedback)"
                                                                *ngIf="calibratedScoreForm.value.array[i].feedbackSubmissionStatus==='SUBMITTED'">
                                                                <div>
                                                                    <label class="view">View</label>
                                                                </div>
                                                            </button>
                                                            <button mat-menu-item
                                                                (click)="resubmitProbation(feedback.value.id)"
                                                                *ngIf="this.ifHR">
                                                                <span class="view">Re-Submit</span>
                                                            </button>
                                                        </mat-menu>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>
            </mat-tab> -->

            <!-- probation ends @here -->

            <mat-tab class="mat-tab-label-active" *ngIf="this.disablefeedbackForm">
                <!-- *ngIf="this.disablefeedbackForm==true"> -->
                <ng-template mat-tab-label class="tab-3">

                    <svg class="sum-icon" width="16" height="20" viewBox="0 0 16 20" fill="#999999"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 9.75H12V8.25H4V9.75ZM4 5.75H12V4.25H4V5.75ZM15.95 18.9L11.7 13.35C11.4333 13.0167 11.1083 12.75 10.725 12.55C10.3417 12.35 9.93333 12.25 9.5 12.25H0V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H14.5C14.9 0 15.25 0.15 15.55 0.45C15.85 0.75 16 1.1 16 1.5V18.5C16 18.5667 15.9958 18.6333 15.9875 18.7C15.9792 18.7667 15.9667 18.8333 15.95 18.9ZM1.5 20C1.1 20 0.75 19.85 0.45 19.55C0.15 19.25 0 18.9 0 18.5V13.75H9.5C9.7 13.75 9.8875 13.7958 10.0625 13.8875C10.2375 13.9792 10.3917 14.1083 10.525 14.275L14.875 19.95C14.8083 19.9667 14.7458 19.9792 14.6875 19.9875C14.6292 19.9958 14.5667 20 14.5 20H1.5Z" />
                    </svg>
                    Summary
                </ng-template>

                <div class="card-container1">


                    <div class="matcardprojectdetail1 " style="line-height: 1;">
                        <div class="section">
                            <svg width="32" height="40" viewBox="0 0 16 20" fill="#0045b9"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4 9.75H12V8.25H4V9.75ZM4 5.75H12V4.25H4V5.75ZM15.95 18.9L11.7 13.35C11.4333 13.0167 11.1083 12.75 10.725 12.55C10.3417 12.35 9.93333 12.25 9.5 12.25H0V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H14.5C14.9 0 15.25 0.15 15.55 0.45C15.85 0.75 16 1.1 16 1.5V18.5C16 18.5667 15.9958 18.6333 15.9875 18.7C15.9792 18.7667 15.9667 18.8333 15.95 18.9ZM1.5 20C1.1 20 0.75 19.85 0.45 19.55C0.15 19.25 0 18.9 0 18.5V13.75H9.5C9.7 13.75 9.8875 13.7958 10.0625 13.8875C10.2375 13.9792 10.3917 14.1083 10.525 14.275L14.875 19.95C14.8083 19.9667 14.7458 19.9792 14.6875 19.9875C14.6292 19.9958 14.5667 20 14.5 20H1.5Z" />
                            </svg>
                            <div class="section-header">
                                Annual Performance Review Summary
                                <div class="perf-year">
                                    Year: {{this.selected}}
                                </div>

                            </div>

                        </div>
                        <div class="notes-data" [innerHTML]="feedbackClosureNotes">
                        </div>


                    </div>
                </div>
            </mat-tab>

            
        </mat-tab-group>
    </div>
</div>