import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserGoalDTO } from 'src/app/dtos/UserGoalDTO';
import { GoalTypeLabel } from 'src/app/services/goalSheet/goal-sheet-enum';
import { SubgoalService } from 'src/app/services/subgoal/subgoal.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { CareerManagerComponent } from '../../career-manager.component';
import { GoalObjectivesService } from 'src/app/services/goal-objectives/goal-objectives.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-assign-goal',
  templateUrl: './assign-goal.component.html',
  styleUrls: ['./assign-goal.component.scss']
})
export class AssignGoalComponent implements OnInit {
  form: FormGroup;
  userid: string;
  Goal: Array<any> = [];
  goal: any[] = [];
  goalAllReadyPresent: any[] = [];
  usergoal: any = new UserGoalDTO();
  data = new Map<any, boolean>();
  keys;
  values;
  goalObjectives: any;


  @ViewChild(CareerManagerComponent) careermanagerComponent: CareerManagerComponent;
  constructor(private fb: FormBuilder,
    private usergoalservice: UserGoalService,
    public dialogRef: MatDialogRef<any>,
    public subgoalservice: SubgoalService,
    private toast: ToastrService,
    private goalObjService: GoalObjectivesService,
    @Inject(MAT_DIALOG_DATA) public dataActual: any
  ) {
    this.form = this.fb.group({ checkArray: this.fb.array([]) });
  }
  ngOnInit(): void {
    this.goalObjectives = GoalObjectivesService.getGoalObjectives();
    this.goal.length = 0;
    this.data.clear();
    this.dataActual[0].forEach((values, keys) => {
      this.data.set(keys.goalType, values)
    });
    this.userid = this.dataActual[1]
    this.data.forEach((values, keys) => {
      const checkArray: FormArray = this.form.get('checkArray') as FormArray;
      if (values == false) {
        checkArray.push(new FormControl(keys));
        this.goal.push(keys);
      }
      else {
        checkArray.push(new FormControl(keys));
      }
    });
    this.initializeGoal();
  }
  async initializeGoal() {
    this.keys = Object.keys(GoalTypeLabel);
    this.values = Object.values(GoalTypeLabel);
    for (let i = 0; i < this.keys.length; i++) {
      let desc = '';
      let succ = '';
      let blueCare = '';
      for (let j = 0; j < this.goalObjectives.length; j++) {
        if (this.goalObjectives[j].name == this.keys[i]) {
          desc = this.goalObjectives[j].descriptor;
          succ = this.goalObjectives[j].successCriteriaAndMeasurement;
          blueCare = this.goalObjectives[j].blueCareValues;
        }
      }

      this.Goal.push({ name: this.values[i], description: desc, success: succ, blue: blueCare, value: this.keys[i], check: this.data.has(this.keys[i]), isDisable: this.ifSubGoal(this.keys[i]) });
    }
  }
  ifSubGoal(goalType) {
    if (this.data.get(goalType) == undefined) {
      return false;
    }
    return this.data.get(goalType);
  }
  onBoxChange(e: any) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
      this.goal.push((e.target.value));
    }
    else {
      let i: number = 0;
      checkArray.controls.forEach(async (items: any) => {
        if (items.value == e.target.value) {
          checkArray.removeAt(i);
          this.goal = this.goal.filter(e => e !== items.value);
          return;
        }
        i++;
      });
    }
  }
  onSubmit() {
    let temp: any = [];
    this.dataActual[0].forEach((values, keys) => {
      if (this.goal.includes(keys.goalType) == false && values == false) {
        this.usergoalservice.delete(keys.id);
      }
    });
    for (let t of this.goal) {
      if (this.data.has(t)) {
        temp.push(t)
      }
    }
    for (let t of temp) {
      this.goal = this.goal.filter(e => e !== t);
    }
    for (let goal of this.goal) {
      this.usergoal.goalType = goal
      this.usergoal.userId = this.userid
      this.usergoal.createddate = new Date();
      this.usergoal.progressValue = 0
      this.usergoal.year = new Date().getFullYear().toString();
      this.usergoalservice.create(this.usergoal).subscribe(res => {
        this.toast.success("Goal Created Successfully", 'Success');
      }, err => {
        this.toast.error("Goal Creation failed", 'Failed');
      });
    }
    this.dialogRef.close({ event: 'Closed' })
    this.goal.length = 0;
    this.data.clear();
    this.usergoalservice.filter("refresh");
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
