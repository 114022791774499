<div class="row">
    <div class=" main-div-reportees">
        <mat-card class="calendar-card-one">
            <div class="month-year-divs">
                <div class="month-select">
                    <mat-select class="year-month-selects" [(ngModel)]="selectedMonth"
                        (ngModelChange)="onMonthYearChange()">
                        <mat-option *ngFor="let month of months" [value]="month">
                            {{month}}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="year-selects">
                    <mat-select class="year-month-selects" [(ngModel)]="selectedYear"
                        (ngModelChange)="onMonthYearChange()">
                        <mat-option *ngFor="let year of years" [value]="year">
                            {{year}}
                        </mat-option>
                    </mat-select>
                </div>

            </div>
            <full-calendar class="full-calendarOne" #fullCalendar [options]="calendarOptions"></full-calendar>
        </mat-card>

        <div class="color-legends">
            <div class="colour-main-divs">
                <div class="Leave colour-divs"></div>

                <div class="colour-div-label">
                    <label>On Leave</label>
                </div>
            </div>

            <div class="colour-main-divs">
                <div class="general colour-divs"></div>

                <div class="colour-div-label">
                    <label>Present</label>
                </div>
            </div>

            <div class="colour-main-divs">
                <div class="Restricted colour-divs"></div>

                <div class="colour-div-label">
                    <label>Regularized</label>
                </div>
            </div>

            <div class="colour-main-divs">
                <div class="Unmarked colour-divs"></div>

                <div class="colour-div-label">
                    <label>Absent</label>
                </div>
            </div>

            <div class="colour-main-divs">
                <div class="Client colour-divs"></div>

                <div class="colour-div-label">
                    <label>Holiday</label>
                </div>
            </div>

        </div>
    </div>
    <div class="col-md-5 flex-col">
        <div class="container-1">
            <div>
                <div>
                    <h2> On Leave </h2>
                </div>
            </div>
            <hr>
            <div>
                <div>
                    <div>
                        <p class="month-name">Today</p>
                    </div>
                    <div *ngIf="this.todayReporteeLeaveData.length==0"> Not on leave Today!</div>

                    <div *ngFor="let reportee of this.todayReporteeLeaveData">
                        <div class="single-reportee-detail-container" *ngIf="isSameMonth(reportee.date, this.selectedMonth)">
                            <div class="img-container-div">
                                <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
                                <div class="dot-blue"></div>
                            </div>
                            <div class="name-container">
                                {{reportee.name}}
                            </div>
                            <div class="leave-status">
                                {{reportee.date | date:'d MMMM'  }}
                            </div>
                            <div>
                            </div>
                            <div class="button-container">
                                <button class="detail-button" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20"
                                        viewBox="0 0 16 20" fill="none">
                                        <path
                                            d="M5.89997 14.5435C5.81108 14.4046 5.76386 14.2518 5.7583 14.0851C5.75275 13.9185 5.79997 13.7726 5.89997 13.6476L8.8333 9.98096L5.8833 6.29346C5.79441 6.18235 5.75275 6.03305 5.7583 5.84555C5.76386 5.65805 5.81108 5.50874 5.89997 5.39763C6.01108 5.25874 6.13052 5.19277 6.2583 5.19971C6.38608 5.20666 6.49997 5.27263 6.59997 5.39763L9.91664 9.54346C9.97219 9.61291 10.0111 9.68235 10.0333 9.7518C10.0555 9.82124 10.0666 9.89763 10.0666 9.98096C10.0666 10.0643 10.0555 10.1407 10.0333 10.2101C10.0111 10.2796 9.97219 10.349 9.91664 10.4185L6.61664 14.5435C6.51664 14.6685 6.39997 14.7275 6.26664 14.7205C6.1333 14.7136 6.01108 14.6546 5.89997 14.5435Z"
                                            fill="#0045B9" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <p class="month-name">This Month</p>
                        </div>
                        <div class="reportees-group">
                            <div *ngIf="this.monthReporteeLeaveData.length==0"> No on leave this month!</div>
                            <div *ngFor="let reportee of this.monthReporteeLeaveData">
                                <div class="single-reportee-detail-container" *ngIf="isSameMonth(reportee.date, this.selectedMonth)">
                                    <div class="img-container-div">
                                        <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
                                        <div class="dot-blue"></div>
                                    </div>
                                    <div class="name-container">
                                        {{reportee.name}}
                                    </div>
                                    <div class="leave-status">
                                        {{reportee.date | date:'d MMMM'  }}
                                    </div>
                                    <div>

                                    </div>
                                    <div class="button-container">
                                        <button class="detail-button" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20"
                                                viewBox="0 0 16 20" fill="none">
                                                <path
                                                    d="M5.89997 14.5435C5.81108 14.4046 5.76386 14.2518 5.7583 14.0851C5.75275 13.9185 5.79997 13.7726 5.89997 13.6476L8.8333 9.98096L5.8833 6.29346C5.79441 6.18235 5.75275 6.03305 5.7583 5.84555C5.76386 5.65805 5.81108 5.50874 5.89997 5.39763C6.01108 5.25874 6.13052 5.19277 6.2583 5.19971C6.38608 5.20666 6.49997 5.27263 6.59997 5.39763L9.91664 9.54346C9.97219 9.61291 10.0111 9.68235 10.0333 9.7518C10.0555 9.82124 10.0666 9.89763 10.0666 9.98096C10.0666 10.0643 10.0555 10.1407 10.0333 10.2101C10.0111 10.2796 9.97219 10.349 9.91664 10.4185L6.61664 14.5435C6.51664 14.6685 6.39997 14.7275 6.26664 14.7205C6.1333 14.7136 6.01108 14.6546 5.89997 14.5435Z"
                                                    fill="#0045B9" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>