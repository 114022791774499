<!-- <div class="container">
    <div class="text-center" style="margin-top: 20px;">
        <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" (selectedTabChange) = "onClickSubmittedTab($event)" [backgroundColor]="background">
            <mat-tab label="Pending Feedbacks"> 
               <app-feedback-tab-content [feedbacksList]="pendingFeedbacksList" [disableEdit]="disableEdit"></app-feedback-tab-content>    
            </mat-tab>
            <mat-tab label="Submitted Feedbacks"> 
               <app-feedback-tab-content [feedbacksList]="submittedFeedbacksList" [disableEdit]="!disableEdit"></app-feedback-tab-content> 
            </mat-tab>
        </mat-tab-group>
    </div>
</div> -->

<div class="container">
    <div class="row mt-2">
        <div class="col mt-1">

            <mat-tab-group>
                <mat-tab label="Pending Feedback">
                    <app-feedback-tab-content [feedbacksList]="this.feedbackService.feedbackPendingList" [disableEdit]="disableEdit"></app-feedback-tab-content>
                </mat-tab>
                <mat-tab label="Submitted Feedback">
                    <app-feedback-tab-content [feedbacksList]="this.feedbackService.feedbackSubmittedList" [disableEdit]="!disableEdit"></app-feedback-tab-content>
                </mat-tab>
            </mat-tab-group>

        </div>
    </div>
</div>