<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Family/Nominee Details</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>

    </div>
</div>
<hr>


<div class="container-fluid">

    <div class="row">

        <div class="col-md-12 form_main_div">
            <div class="add_button_fix">
                <button class="add_button" (click)="addFamily()">+Add</button>
                <button class="plus_button mt-4" (click)="addFamily()"><span>+Add</span></button>
            </div>
            <form [formGroup]="familyForm">
                <div formArrayName="family" *ngFor="let familyy of this.Family().controls; let i=index">
                    <div class="row">
                        <div class="col-md-3 nominee_details" [formGroupName]="i">
                            <ngb-rating formControlName="nominees" class="ngb-rating" [max]="1" [readonly]="false">
                                <button (click)="removefamily(familyy.value.id,i)" class="remove_label">Remove</button>

                            </ngb-rating>

                            <label class="nominee_label">Mark As Nominee</label>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Name<span class="contentRed"></span></mat-label>
                                <input required type="text" class="inputName" pattern="^[A-Za-z\s]+$" matInput formControlName="firstName" autocomplete="off">
                                <mat-error *ngIf="familyForm.get('family')['controls'][i].get('firstName').hasError('required')">
                                    Required
                                </mat-error>
                                <mat-error *ngIf="familyForm.get('family')['controls'][i].get('firstName').hasError('pattern')">
                                    Invalid. Only Alphabets allowed.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Relationship Name<span class="contentRed"></span></mat-label>
                                <input required matInput formControlName="relation" autocomplete="off" pattern="^[a-zA-Z\s]*$">
                                <mat-error *ngIf="familyForm.get('family')['controls'][i].get('relation').hasError('required')">
                                    Required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Location<span class="contentRed"></span></mat-label>
                                <input required matInput formControlName="location" autocomplete="off" pattern="^[a-zA-Z\s]*$">
                                <mat-error *ngIf="familyForm.get('family')['controls'][i].get('location').hasError('required')">
                                    Required
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Date of Birth <span class="contentRed"></span></mat-label>
                                <input required [max]="today"  matInput [matDatepicker]="picker18"  placeholder="mm/dd/yyyy"
                                    formControlName="dateOfBirth">
                                <mat-datepicker-toggle matSuffix [for]="picker18"></mat-datepicker-toggle>
                                <mat-datepicker #picker18></mat-datepicker>
                                <mat-error *ngIf="familyForm.get('family')['controls'][i].get('dateOfBirth').hasError('required')">
                                    Required
                                </mat-error>
                            </mat-form-field>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-md-3">

                            <button class="remove_label" (click)="removefamily(familyy.value.id,i)">Remove</button>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>

    </div>
</div>