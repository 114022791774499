import { HomeComponent } from "src/app/components/home/home.component";
export enum JobTitles{
    INTERN = "Intern",
    ANALYST = "Analyst",
    CONSULTANT = "Consultant",
    SR_CONSULTANT = "Sr. Consultant",
    MANAGER = "Manager",
    SR_MANAGER = "Sr. Manager",
    DIRECTOR = "Director",
    SR_DIRECTOR = "Sr. Director",
    MANAGING_DIRECTOR = "Managing Director",
    CEO = "CEO"
}
export enum AuthoritiesConstants {
    ROLE_ADMIN = "Role Admin",
    ROLE_USER = "Role User",
    ROLE_CAREER_MANAGER = "Role Career Manager",
    ROLE_SUPER_ADMIN = "Role Super Admin",
    ROLE_ASSET_ADMIN = "Role Asset Admin",
    ROLE_PROJECT_ADMIN = "Role Project Admin",
    ROLE_PROJECT_CLIENT = "Role Project Client",
    ROLE_EMPLOYEE_ADMIN = "Role Employee Admin",
    ROLE_CLIENT_ADMIN = "Role Client Admin",
    ROLE_HUMAN_RESOURCE = "Role Human Resource",
    
}
export enum Departments{
    API_MANAGEMENT_AND_INTEGRATION = "API Management & Integration",
    CLIENT_SUCCESS = "Client Success",
    DIGITAL_APPLICATION_DEVELOPMENT = "Digital Application Development",
    DATA_SCIENCE_AND_AI = "Data Science & AI",
    DATA_MANAGEMENT = "Data Management",
    SUPPORT_FUNCTIONS = "Support Functions",
    GEOGRAPHY = "Geography",
}
export enum RelationshipStatus {
    Single = "Single",
    Married ="Married",
    Widowed= "Widowed",
    Separated="Separated",
    Divorced="Divorced"
}
export enum BloodGroup{
    A_POSITIVE = "A Positive",
    A_NEGATIVE = "A Negative",
    B_POSITIVE = "B Positive",
    B_NEGATIVE="B Negative",
    AB_POSITIVE="AB Positive",
    AB_NEGATIVE="AB Negative",
    O_POSITIVE="O Positive",
    O_NEGATIVE= "O Negative"
}
export enum Gender {
    MALE = "Male",
    FEMALE = "Female",
    OTHERS = "Others"
}
export enum NomineeStatus {
    Yes,
    No
    }
export enum Relation{
    Brother,
    Sister,
    Mother,
    Father,
    Other
}
export enum CustodianType{
    Spouse,
    Guardian
}
export enum ProbationPeriod{
    Sixty = "60",
    Ninety = "90"
}
export enum ProjectRole{
    PROJECT_MANAGER, SOLUTION_ARCHITECT, SUBJECT_MATTER_EXPERT, BUSINESS_ANALYST, DEVELOPER, QA_ENGINEER
}
export enum ProjectCategory{
    BILLABLE, NON_BILLABLE
}

export enum ProjectType{
    Internal, External
}
//PROJECT, , 
export enum FeedbackType{
    PROJECT="PROJECT_REVIEW_FEEDBACK",ADHOC="REGULAR_FEEDBACK", PROBATION="PROBATION"
    // ,REAL_TIME_FEEDBACK="REAL_TIME_FEEDBACK"
}

export enum TypeOfTask{
    PROBATION_FEEDBACK="PROBATION_FEEDBACK",
    REVERSE_PROBATION_FEEDBACK="REVERSE_PROBATION_FEEDBACK"
}

export enum GeoLocation{
    INDIA = "India",
    USA = "USA"
}

export enum LeaveType{
    Loss_Of_Pay,Unplanned_Leave,Paid_Leave
}

export enum FirstnSecondHalf{
    First_Half=1,
    Second_Half=2
}
