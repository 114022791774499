import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ResignationemailComponent } from '../../resignationemail/resignationemail.component';
import { StatusResignationService } from 'src/app/services/status-resignation/status-resignation.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'app-reliving-process',
  templateUrl: './reliving-process.component.html',
  styleUrls: ['./reliving-process.component.scss']
})
export class RelivingProcessComponent implements OnInit {

  disableAgreement: boolean = true;

  constructor(
    public dialog: MatDialog,
    public statusResignationService: StatusResignationService,
    public userDetailService: UserDetailsService
  ) { }

  userId: any;
  status: any[] = [];
  taskColor: string;
  statusColor: string

  ngOnInit(): void {
    this.userId = this.userDetailService.getUserDetails().id;
    console.log(this.userId);
    this.statusResignationService.getStatusResignation().subscribe(res=>{
      this.status = res.body;
      for(let s of this.status){
        if (s === 'APPLIED') {this.taskColor = 'applied_task'; this.statusColor='applied_status';}
        else if (s === 'ACCEPTED') { this.taskColor = 'accepted_task'; this.statusColor='accepted_status';}
        else if (s === 'REJECTED') {this.taskColor = 'rejected_task'; this.statusColor='rejected_status';}
        else if (s === 'KNOWLEDGE_TRANSFER_STARTED') {this.taskColor = 'KT_task'; this.statusColor='KT_status';}
      }
      console.log(this.status)
    })
    
  }

  relivingEnable(event) {
    this.disableAgreement = !event.checked;
  }

  open() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "95%";
    const dialogRef = this.dialog.open(ResignationemailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
}
