import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class RegularizationURLService {

  private readonly subGoalBaseApi = '/api/attendance-regularizations';

  constructor(private restApi: RestAPIService) { }

  getBaseUrl() {
    return this.subGoalBaseApi;
  }
}
