import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubgoalService } from 'src/app/services/subgoal/subgoal.service';

@Component({
  selector: 'app-delete-subgoal',
  templateUrl: './delete-subgoal.component.html',
  styleUrls: ['./delete-subgoal.component.scss']
})
export class DeleteSubgoalComponent implements OnInit {

  constructor(
    public subGoalService:SubgoalService,
    public dialogRef: MatDialogRef<DeleteSubgoalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit(): void {
  }
  matDialogClose() {
    this.dialogRef.close()
  }

  deleteGoal() {
    this.subGoalService.delete(this.data.id);
    this.dialogRef.close()
    this.subGoalService.filter("Delete Subgoal");
    this.subGoalService.filter("");
  }
}
