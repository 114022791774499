import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Form } from 'src/app/models/forms';
import { FormSchemaUrlService } from '../restAPI/formSchemaURLs/form-schema-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})

export class FormsSchemaService {


  formType = [];
  public pageCount = 1
  public pageSize = 6
  public totalCount;
  public page: PageEvent
  public forms = [];
  public currentFormCategory;

  constructor(private restApi: RestAPIService,
    private formUrl: FormSchemaUrlService) { }


  setFormCategory(category) {
    this.currentFormCategory = category
  }

  checkIfFormExists(payload) {
    payload = this.getPayload(payload);

    return this.restApi.get(this.formUrl.getSchemaIfExists(payload.formType));
  }

  addNewFormSchemaSubscribe(payload) {
    payload = this.getPayload(payload);
    return this.restApi.post(this.formUrl.getBaseUrl(), payload)
  }

  addNewFormSchema(payload) {
    payload = this.getPayload(payload);
    this.restApi.post(this.formUrl.getBaseUrl(), payload).subscribe()
  }


  getAllForms() {
    this.restApi.get(
      this.formUrl.getBaseUrl()
    ).subscribe(res => {
      this.setForms(res.body)
    })
  }
  getAllFormsSubcribe() {
    return this.restApi.get(
      this.formUrl.getBaseUrl()
    )
  }
  getFormByIdSubscribe(id) {
    return this.restApi.get(
      this.formUrl.getByFieldUrl(id)
    )
  }

  processFormType(response) {

  }


  getFormByProjectIdSubscribe(id) {
    return this.restApi.get(
      this.formUrl.getProjectByIdUrl(id)
    )
  }

  getProjectBySearch(searchText) {
    this.restApi.get(
      this.formUrl.getSearchUrl(searchText)
    )
      .subscribe(res => {
        this.setForms(res.body);
      });
  }

  getFormByCareerManagerTeamIdSubscribe(id) {
    return this.restApi.get(
      this.formUrl.getCareerManagerByIdUrl(id)
    )
  }



  //get form template by project id and formType
  // /api/form
  getFormByProjectIdAndFormTypeSubscribe(id, formType) {
    return this.restApi.get(
      this.formUrl.getFormSchemaByProjectIdAndFormType(id, formType)
    )
  }

  getFormByCareerManagerTeamIdAndFormTypeSubscribe(id, formType) {
    return this.restApi.get(
      this.formUrl.getFormSchemaByCareerManagerIdAndFormType(id, formType)
    )
  }


  getFormByCareerMangerIdSubscribe(id) {
    return this.restApi.get(
      this.formUrl.getCareerManagerByIdUrl(id)
    )
  }

  getFormByCareerMangerIdAndFormTypeSubscribe(id, formType) {
    return this.restApi.get(
      this.formUrl.getFormSchemaByCareerManagerTeamId(id, formType)
    )
  }



  private getPayload(payload) {

    return {
      "formTemplate": payload.formTemplate,
      //"projectId": payload.projectId,
      "formType": payload.formType,
      //"careerManagerTeamId": payload.careerManagerTeamId,
      "category": payload.category,
      "jobTitle": payload.jobTitle,
      "feedbackRole": payload.feedbackRole,
      "formName": payload.formName
    }
  }

  private getPayloadUpdate(payload) {
    return {
      "id": payload.id,
      "formTemplate": payload.formTemplate,
      //"projectId": payload.projectId == '' ? null : payload.projectId,
      "formType": payload.formType,
      //"careerManagerTeamId": payload.careerManagerTeamId == '' ? null : payload.careerManagerTeamId,
      "category": payload.category,
      "jobTitle": payload.jobTitle,
      "feedbackRole": payload.feedbackRole,
      "formName": payload.formName
    }
  }

  getFormPagination() {
    this.restApi.get(
      this.formUrl.getPaginationUrl(this.pageCount, this.pageSize),
    )
      .subscribe(res => {
        this.setForms(res.body);
        this.totalCount = Number(res.headers.getAll('X-Total-Count'));

      })
  }

  setForms(formsResponse) {
    this.forms = [];
    for (let form of formsResponse) {

      this.forms.push(new Form(form));

    }

  }

  updateFeedbackForm(payload) {
    payload = this.getPayloadUpdate(payload);
    this.restApi.put(
      this.formUrl.getBaseUrl(),
      payload
    ).subscribe(res => {
      this.getFormPagination();
    })
  }

  deleteFeedbackForm(id) {
    this.restApi.delete(
      this.formUrl.getDeleteUrl(id)
    ).subscribe(res => {
      this.getFormPagination()
    })
  }
  getFormSchemaByRoleAndJobTitle(role, jobTitle) {
    return this.restApi.get(
      this.formUrl.getFormSchemaByRoleAndJobTitle(role, jobTitle)
    )
  }
  formIdAfterParse
  formSchemaAfterParse
  async getFormSchemaByRoleAndJobTitleAsync(role, jobTitle) {
    let promise4 = new Promise((resolve, reject) => {
      this.getFormSchemaByRoleAndJobTitle(role, jobTitle).subscribe(formId => {
        resolve(formId.body)
      })

    })
    var formId = await promise4;
    var formIdAsString = JSON.stringify(formId);
    this.formIdAfterParse = JSON.parse(formIdAsString);
    let promise5 = new Promise((resolve, reject) => {
      this.getFormByIdSubscribe(this.formIdAfterParse.id).subscribe(form => {
        resolve(form.body)
      })
    })
    var formSchema = await promise5;
    var formSchemaAsString = JSON.stringify(formSchema);
    this.formSchemaAfterParse = JSON.parse(formSchemaAsString);
  }

  // for probation
  getProbationFormSchema() {
    return this.restApi.get(
      this.formUrl.getFormSchemaForProbation()
    )
  }
  getFormSchemaForReverseProbation() {
    return this.restApi.get(
      this.formUrl.getFormSchemaForReverseProbation()
    )
  }

  async getProbationFormSchemaAsync() {
    let promise4 = new Promise((resolve, reject) => {
      this.getProbationFormSchema().subscribe(formId => {
        resolve(formId.body)
      })

    })
    var formId = await promise4;
    var formIdAsString = JSON.stringify(formId);
    this.formIdAfterParse = JSON.parse(formIdAsString);
    let promise5 = new Promise((resolve, reject) => {
      this.getFormByIdSubscribe(this.formIdAfterParse.id).subscribe(form => {
        resolve(form.body)
      })
    })
    var formSchema = await promise5;
    var formSchemaAsString = JSON.stringify(formSchema);
    this.formSchemaAfterParse = JSON.parse(formSchemaAsString);
  }
  
  async getReverseProbationFormSchemaAsync() {
    let promise4 = new Promise((resolve, reject) => {
      this.getFormSchemaForReverseProbation().subscribe(formId => {
        resolve(formId.body)
      })

    })
    var formId = await promise4;
    var formIdAsString = JSON.stringify(formId);
    this.formIdAfterParse = JSON.parse(formIdAsString);
    let promise5 = new Promise((resolve, reject) => {
      this.getFormByIdSubscribe(this.formIdAfterParse.id).subscribe(form => {
        resolve(form.body)
      })
    })
    var formSchema = await promise5;
    var formSchemaAsString = JSON.stringify(formSchema);
    this.formSchemaAfterParse = JSON.parse(formSchemaAsString);
  }
}
