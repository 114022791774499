import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RealTimeFeedbackRole } from 'src/app/services/feedback/feedback-enum';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-add-real-time-feedback',
  templateUrl: './add-real-time-feedback.component.html',
  styleUrls: ['./add-real-time-feedback.component.scss']
})
export class AddRealTimeFeedbackComponent implements OnInit {
  FeedbackRole = [];
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddRealTimeFeedbackComponent>, public userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data,
    private feedbackService: FeedbackService,
    private router: Router,
    public toastr: ToastrService,
    private fb: FormBuilder


  ) {
    this.form = this.fb.group({
      feedbackTitle: ['', Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/), Validators.maxLength(32)],
      selectedYear: ['', Validators.required],
      selectedRole: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.processEnumValuesToArray();
  }
  feedbackTitle: string = '';
  role: string = '';
  years: number[] = this.generateYearList();
  selectedYear: number = new Date().getFullYear();

  generateYearList(): number[] {
    const currentYear = new Date().getFullYear();
    // const yearRange = 10;
    // return Array.from({ length: yearRange }, (_, index) => currentYear - index);
    let years = []
    for (let i = 2022; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  }

  submitForm() {
    this.feedbackTitle = this.form.get('feedbackTitle').value;
    this.role = this.form.get('selectedRole').value;
    this.selectedYear = this.form.get('selectedYear').value;
    if (this.feedbackTitle != '' && this.role != '') {
      const jsonObject = {
        feedbackFor: this.data,
        feedbackFrom: [
          {
            feedbackFrom: this.userDetailService.getUserDetails().id,
            feedbackRole: this.role
          }
        ],
        feedbackType: 'ORPHAN',
        title: this.feedbackTitle,
        year: this.selectedYear
      };

      this.feedbackService.addRealTimeFeedBack(jsonObject).subscribe(res => {
        this.closeDialogue();
        this.router.navigate(['/task']);
      }, e => {
        this.toastr.error(e.status == 400 ? 'Feedback alredy closed for this user' : " Enter Valid Details")
      })
    } else {
      this.form.markAllAsTouched();
      this.toastr.error("Please Enter Valid Details")
    }

  }
  closeDialogue() {
    this.dialogRef.close()

  }
  processEnumValuesToArray() {

    for (let role of Object.values(RealTimeFeedbackRole)) {
      if (isNaN(Number(role))) {
        this.FeedbackRole.push(role);
      }
    }

  }
  selectedRole(role) {

  }
}
