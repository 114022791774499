<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Personal Information</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>

    </div>
</div>
<hr>
<div class="container-fluid">
    <div class="row">

        <div class="col-md-12 form_main_div">
            <form [formGroup]="personalDetails">
                <div class="row mt-3">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width" style="width:330px;">
                            <mat-label>Blood Group *<span class="contentRed"></span></mat-label>
                            <mat-select formControlName="blood_group">
                                <mat-option *ngFor="let bloodGroup of this.bloodgroup" [value]="bloodGroup">
                                    {{bloodGroup}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError2('blood_group', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width" style="width: 330px;">
                            <mat-label>Date of Birth  <span class="contentRed"></span></mat-label>
                            <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy" formControlName="dob">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="checkError2('dob', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Place of Birth *<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="place_of_birth" autocomplete="off">
                            <mat-error *ngIf="checkError2('place_of_birth', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError2('place_of_birth', 'pattern')">Invalid Place of
                                Birth</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Relationship Status</mat-label>
                            <mat-select formControlName="marital_status">
                                <mat-option *ngFor="let relation of this.relationshipstatus" [value]="relation">
                                    {{relation}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError2('marital_status', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>


                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>