import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AboutComponent } from './about/about.component';

import {
  MatDialog,
  MatDialogConfig,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ProfessionalSkillsComponent } from './professional-skills/professional-skills.component';
import { EmploymentHistoryComponent } from './employment-history/employment-history.component';
import { EducationalQualificationsComponent } from './educational-qualifications/educational-qualifications.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { Education } from 'src/app/models/educationdetails';
import { EducationDetailsService } from 'src/app/services/education-details/education-details.service';
import { CertificationService } from 'src/app/services/certification/certification.service';
import { SkillService } from 'src/app/services/skill/skill.service';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import { PreviousEmployment } from 'src/app/models/previousEmployment';
import { Skill } from 'src/app/models/skill';
import { Certificate } from 'src/app/models/certificate';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
@Component({
  selector: 'app-spot-light',
  templateUrl: './spot-light.component.html',
  styleUrls: ['./spot-light.component.scss'],
})
export class SpotLightComponent implements OnInit {
  dataSubscription: any;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public userService: UserService,
    public userDetailService: UserDetailsService,
    public educationdetailsservice: EducationDetailsService,
    public skillservice: SkillService,
    public certificateservice: CertificationService,
    public educationservice: EducationDetailsService,
    public empservice: PreviousEmploymentService,
    private route: ActivatedRoute,
    public feedbackService: FeedbackService
  ) { 
    this.dataSubscription = this.feedbackService.data$.subscribe(data => {
      this.userid = data;
      this.ngOnInit();
      
    });
  }

  userdto = new UserDTO();
  about: any;
  educationdetailsdto: Education[] = [];
  userid;
  skilldto: Skill[] = [];
  certificatedto: Certificate[] = [];

  preempdto: PreviousEmployment[] = [];
  // prevexp = new PreviousEmployment();
  preempexp = {};
  isVisible = false;
  month = [];
  year = [];
  addedyear: number;
  remainmonths: number;
  overall = 0;
  skills_loading = false
  employment_history_loading = false
  educational_qualifications_loading = false
  certifications_loading = false
  profilecompletionstatus: any;
  @Output() dummyfunc = new EventEmitter();
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  userAbout: any = '';
  flag = [false, false, false];
  allowEditToOthers: boolean = false;
  ngOnInit(): void {
    // debugger


    this.profilecompletionstatus = 0;
    // this.userid = this.userDetailService.getUserDetails().id;

    this.userid = this.route.snapshot.paramMap.get('id');


    if (this.userid == null) {
      this.userid = this.userDetailService.getUserDetails().id;
      this.allowEditToOthers = (this.userid == this.userDetailService.getUserDetails().id);
    }

    this.allowEditToOthers = (this.userid == this.userDetailService.getUserDetails().id);

    // this.userService.getUserAbout(this.userid).subscribe(res => {
    //   if (res.body.about == undefined || res.body.about == null ){
    //     this.userAbout = '';
    //   }
    //   else{
    //   this.userAbout = res.body.about;
    // }
    // })
    this.getAbout();

    // this.skillservice
    //   .getskillByUserIdSubscribe(this.userid)
    //   .subscribe((res) => {
    //     this.skills_loading=true
    //     //if (res.body.length <= 1) {
    //     this.skilldto = this.skillservice.setSkillResponse(res.body);
    //   //  this.educationdetailsservice.setEducationResponse(res.body);
    //      if (this.skilldto.length == 1) {
    //       // if(this.flag == 0)
    //       // {
    //      this.profilecompletionstatus += 6.67;


    //     }
    //     this.dummyfunc.emit(this.profilecompletionstatus);
    //   });
    this.getProfessionalSkills();


    // this.educationdetailsservice
    //   .getskillByUserIdSubscribe(this.userid)
    //   .subscribe((res) => {
    //     this.educational_qualifications_loading=true
    //    // if(res.body.length )
    //     { 
    //     this.educationdetailsdto =
    //       this.educationdetailsservice.setEducationResponse(res.body);
    //      if (this.skilldto.length == 1) {
    //       // if(this.flag == 0)
    //       // {
    //      this.profilecompletionstatus += 6.67;


    //     } this.dummyfunc.emit(this.profilecompletionstatus);
    //   }
    //   });
    this.getEducationalQualification();

    // this.empservice
    //   .getprevempByUserIdSubscribe(this.userid)
    //   .subscribe((res) => {
    //     this.employment_history_loading=true
    //     this.preempdto = this.empservice.setPrevempResponse(res.body);
    //     //this.educationdetailsdto=this.educationdetailsservice.setEducationResponse(res.body);
    //     if (this.preempdto.length == 1) {

    //     this.profilecompletionstatus += 6.67;


    //    }
    //    this.dummyfunc.emit(this.profilecompletionstatus);
    //   });


    // this.empservice
    //   .getprevempByUserIdSubscribe(this.userid)
    //   .subscribe((res) => {
    //     this.employment_history_loading=true;

    //     this.preempdto = this.empservice.setPrevempResponse(res.body);
    //     //this.educationdetailsdto=this.educationdetailsservice.setEducationResponse(res.body);
    //     if (this.preempdto.length == 1) {
    //     this.profilecompletionstatus += 6.67;
    //    }
    //    this.dummyfunc.emit(this.profilecompletionstatus);


    //     for (let r of res.body) {
    //       this.preempexp[r.id] = this.processDate(r);
    //     }
    //   });
    this.getPreviousEmployeement();
    //       this.certificatedto.length=0;
    //       this.certificateservice.getCertByUserIdSubscribe(this.userid).subscribe((res) =>{
    //         this.certifications_loading=true
    // this.certificatedto = this.certificateservice.setCertResponse(res.body)
    //       })
    this.getCertifications();

  }

  getAbout() {
    this.userService.getUserAbout(this.userid).subscribe(res => {
      if (res.body.about == undefined || res.body.about == null) {
        this.userAbout = '';
      }
      else {
        this.userAbout = res.body.about;

        if (this.flag[0] == false) {
          this.dummyfunc.emit(8);
          this.flag[0] = true;
        }

      }
    })
  }
  getProfessionalSkills() {
    this.skillservice
      .getskillByUserIdSubscribe(this.userid)
      .subscribe((res) => {
        this.skills_loading = true

        //if (res.body.length <= 1) {
        this.skilldto = this.skillservice.setSkillResponse(res.body);
        //  this.educationdetailsservice.setEducationResponse(res.body);
        if (res.body.length > 0) {
          // if(this.flag == 0)
          // {

          if (this.flag[1] == false) {
            this.dummyfunc.emit(8);
            this.flag[1] = true;
          }
        }
        if (res.body.length == 0) {
          if (this.flag[1]) {
            this.dummyfunc.emit(-8);
            this.flag[1] = false;
          }
        }
        if (this.skilldto.length == 1) {
          // if(this.flag == 0)
          // {
        }
      });
  }
  getPreviousEmployeement() {
    this.empservice
      .getprevempByUserIdSubscribe(this.userid)
      .subscribe((res) => {
        this.employment_history_loading = true;

        this.preempdto = this.empservice.setPrevempResponse(res.body);
        //this.educationdetailsdto=this.educationdetailsservice.setEducationResponse(res.body);

        for (let r of res.body) {
          this.preempexp[r.id] = this.processDate(r);
        }
      });
  }
  getEducationalQualification() {
    this.educationdetailsservice
      .getskillByUserIdSubscribe(this.userid)
      .subscribe((res) => {
        this.educational_qualifications_loading = true
        // if(res.body.length )
        {
          this.educationdetailsdto =
            this.educationdetailsservice.setEducationResponse(res.body);
          if (res.body.length > 0) {
            if (this.flag[2] == false) {
              this.dummyfunc.emit(9);
              this.flag[2] = true;
            }
          }
          if (res.body.length == 0) {
            if (this.flag[2]) {
              this.dummyfunc.emit(-9);
              this.flag[2] = false;
            }
          }
          if (this.skilldto.length == 1) {
            // if(this.flag == 0)
            // {
          }
        }
      });
  }

  getCertifications() {
    this.certificatedto.length = 0;
    this.certificateservice.getCertByUserIdSubscribe(this.userid).subscribe((res) => {
      this.certifications_loading = true
      this.certificatedto = this.certificateservice.setCertResponse(res.body)
    // console.log(this.certificatedto);

    });
    
  }

  editAboutDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'about-class';
    // dialogConfig.data = this.userdto;
    let aboutData = [this.userAbout, this.userid];
    // dialogConfig.data = this.userDetailService.getUserDetails();
    dialogConfig.data = aboutData;
    const dialogRef = this.dialog
      .open(AboutComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          //this.ngOnInit();
          this.getAbout();
        }
      });
  }
  editProfessionalskillsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'professional-class';
    // dialogConfig.data = this.userdto;
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(ProfessionalSkillsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          //this.ngOnInit();
          this.getProfessionalSkills();
        }
      });
  }

  editEmploymentHistoryDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'employment-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(EmploymentHistoryComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          //this.ngOnInit();
          this.getPreviousEmployeement()
        }
      });
  }
  editEducationalQualificationsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'education-class';
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(EducationalQualificationsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          //this.ngOnInit();
          this.getEducationalQualification();
        }
      });
  }
  editCertificationsDialog() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'certifications-class';
    // dialogConfig.data = this.userdto;
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog
      .open(CertificationsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          //this.ngOnInit();
          this.getCertifications();
        }
      });
  }

  processDate(i) {
    let experience;

    if (i.startDate && i.endDate) {
      let startDate = new Date(i.startDate);
      let endDate = new Date(i.endDate);
      let diff = endDate.getTime() - startDate.getTime();
      let days = diff / (1000 * 60 * 60 * 24);

      this.overall += days;

      let year = this.getYearFromDays(days);
      let months = this.getMonthFromDays(days);
      experience = year > 0 ? year + ' Year ' : '';
      experience += months > 0 ? months + ' Months' : '';
      if (months < 1 && year < 1) {
        experience = '< 1 Month';
      }
    }

    return experience;
  }

  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }
  getYearFromDays(days) {
    return Math.floor(days / 365);
  }


}
