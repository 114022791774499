<div class="container-fluid ">
    <div class="main_header_div">
        <div class="child">
            <!-- <h1 class="header_name">Feedback Forms/Consolidated</h1> -->
            <label class="header_name">Probation Ending Feedback</label>
            <label class="reporteename">{{ this.directReporteeId.directReporteeDetail.firstName + ' ' +
                this.directReporteeId.directReporteeDetail.lastName}}
            </label>
        </div>

        <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
            <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>

        </div>
    </div>
    <hr class="hr-row">
    <form [formGroup]="feedbackDetails">
        <div class="row">
            <div class="col-md-5">
                <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Feedback Type</mat-label>
                    <mat-select formControlName="feedbackType" [disabled]="!this.isEdit">
                        <mat-option *ngFor="let form of  this.projects" [value]="form">
                            {{form | underScoreToSpace |
                            titlecase}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>


            <div class="col-md-5"> <!--removed year by requirements from demo-->
                <!-- <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Year</mat-label>
                    <mat-select formControlName="year" [disabled]="!this.isEdit">
                        <mat-option *ngFor="let yearr of years" [value]="yearr">{{ yearr }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field> -->
            </div>

            <div class="col-md-1 mt-2 mb-2" *ngIf="this.directReporteeId.dataFeedback!=null">
                <div (click)="deleteFeedbackGroup()" class="delete-btn">Delete</div>
            </div>
            <ng-template [ngIf]="selectedFormName ==='form3'">
            </ng-template>
        </div>
        <div style="display:flex;">
            <h1 class="add-heading">Add Opinion</h1>
            <button class="add-btn" (click)="addComponent()">Add</button>
        </div>
        <div class="row" [formGroup]="feedbackOpinion" *ngFor="let feedback of this.opinion().controls; let i = index">
            <div *ngIf='!isVisible[i]' class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Employee</mat-label>
                    <mat-select formControlName="feedbackFrom" (selectionChange)="getUserID($event.value,i)">
                        <input class="myInput" #myInput matInput focused="'true'" type="text"
                            (keyup)="search($event.target.value,i)" (focusout)="focusOutEvent(i)" autocomplete="off"
                            placeholder="search name">
                        <mat-option *ngFor="let user of this.displaySelectedArea[i]" [value]="user">
                            {{user}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="feedbackDetails.value.feedbackArray[i].feedbackRole=='SELF_EVALUATION' || feedbackDetails.value.feedbackArray[i].feedbackRole=='CAREER_MANAGER'"
                style="text-transform: capitalize;" class="col-md-3 self-user-div">
                
                    {{feedbackDetails.value.feedbackArray[i].feedbackRole=='SELF_EVALUATION' ? (directReporteeId.directReporteeDetail.firstName+" "+directReporteeId.directReporteeDetail.lastName): (this.assinedManagerUseAfterParse.firstName+" "+this.assinedManagerUseAfterParse.lastName )}}
                
            </div>
            <div class="myInput"
                *ngIf="isVisible[i] && !checkRole(feedbackDetails.value.feedbackArray[i].feedbackRole) "
                class="col-md-5" [formGroupName]="i">
            <!-- <div *ngIf="checkRole(feedbackDetails.value.feedbackArray[i].feedbackRole)" > -->
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Employee</mat-label>
                    <mat-select formControlName="feedbackFrom" (selectionChange)="getUserID($event.value,i)"
                        disabled="true">
                        <mat-option *ngFor="let user of this.userList" [value]="user">
                            {{user}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            <!-- </div> -->
                
            </div>
            <!-- <div class="myInput" *ngIf='isVisible[i]' class="col-md-3" [formGroupName]="i">
                    <input type="text" formControlName="feedbackFrom" disabled="true">
                  </div> -->
            <div *ngIf="!isVisible[i]  && !checkRole(feedbackDetails.value.feedbackArray[i].feedbackRole) "
                class="col-md-5" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="feedbackRole" (selectionChange)="selectedRole($event.value,i)">
                        <mat-option *ngFor="let role of this.FeedbackRole" [value]="role" [disabled]="role === 'REVERSE_PROBATION_FEEDBACK'">
                            {{role | underScoreToSpace |
                            titlecase}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>
            <div *ngIf='isVisible[i]' class="col-md-3" [formGroupName]="i">
                <mat-form-field appearance="outline" class="example-full-width ">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="feedbackRole" (selectionChange)="selectedRole($event.value,i)"
                        disabled="true">
                        <mat-option *ngFor="let role of this.FeedbackRole" [value]="role">
                            {{role | underScoreToSpace |
                            titlecase}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError">Required</mat-error>
                </mat-form-field>
            </div>



            <div *ngIf="feedback.value.feedbackSubmissionStatus!='SUBMITTED'"
                (click)="removefeedbackopinion(feedback.value.id,i)" class="delete-btn">Delete</div>
            <div *ngIf="feedback.value.feedbackSubmissionStatus==='SUBMITTED'" class="submitted-btn">SUBMITTED</div>

        </div>
    </form>
    <div class="div_submit_btn">
        <button mat-raised-button (click)="onSubmit()" class="submit-btn" type="submit">Submit</button>
    </div>
</div>