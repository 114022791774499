<div class="row ml-4">
    
        <figure class="back-btn" routerLink="/project">
            <!-- ##commented_BackButton -->
            <mat-icon><img class="upload_img" src="assets/back.png" alt=""></mat-icon>
            <label class="upload_text">Back</label>

        </figure>
   
</div>
<div class="col-md-12" *ngIf="this.mobileview==false">
    <mat-card class="matcardclientdetail" fxFlex.lt-sm="0 1 calc(33.3% - 32px)">
        <mat-card-content>
            <div class="row">
                <div class="col coloums">
                    <div class="img" *ngIf="this.clientDto.clientImageThumbnail !== null ">
                        <img class="img-box" src="data:image/png;base64,{{this.clientDto.clientImageThumbnail}}" />
                    </div>

                    <div class="img" *ngIf="this.clientDto.clientImageThumbnail === null ">
                        <img class="img-box" src="assets/blue_altair_logo.png" />
                    </div>
                </div>
                <div class="col coloums">
                    <h5 class="client-common">{{this.clientDto.clientName}} </h5>
                    <h5 class="client-name">{{this.clientDto.clientDescription}} </h5>
                </div>
                <!-- <div class="row">
                            <h5 class="client-common"> </h5>
                        </div> -->
                <div class="col coloums">
                    <h5 class="client-fields">Client ID  </h5>
                    <p class="client-name">{{this.clientDto.clientId}}</p>
                </div>
                <div class="col coloums">
                    <h5 class="client-fields">Client Location </h5>
                    <p class="client-name">{{this.clientDto.clientLocation}}</p>
                </div>
                <!-- <div class="col coloums">
                    <h5 class="client-fields">Onboarding Date </h5>
                    <p class="client-name">{{this.clientDto.createdDate | date : "dd-MM-yyyy"}}</p>
                </div> -->
                <!-- <div class="col coloums">
                    <label class="count" (click)="AddEditClientDialog()">Edit</label>
                </div> -->
                <div class="col coloums">
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div class="col-md-12" *ngIf="this.mobileview==true">
    <mat-card class="matcardclientdetail">
        <mat-card-content>
            <div class="row ml-2">
                <div class="img" *ngIf="this.clientDto.clientImageThumbnail !== null ">
                    <img class="img-box" src="data:image/png;base64,{{this.clientDto.clientImageThumbnail}}" />

                </div>
                <div class="img" *ngIf="this.clientDto.clientImageThumbnail === null ">
                    <img class="img-box" src="assets/blue_altair_logo.png" />
                </div>


            </div>
            <div class="row">
                <div class="col coloums">
                    <h5 class="client-common">{{this.clientDto.clientName | underScoreToSpace |
                        titlecase}} </h5>
                </div>
                <div class="col ">
                    <p class="client-name">{{this.clientDto.clientDescription}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col coloums">
                    <h5 class="client-common">Client ID </h5>
                </div>
                <div class="col">
                    <p class="client-name">{{this.clientDto.clientId}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col coloums">
                    <h5 class="client-common">Client Location </h5>
                </div>
                <div class="col">
                    <p class="client-name">{{this.clientDto.clientLocation | underScoreToSpace |
                        titlecase}}</p>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col coloums">
                    <h5 class="client-common"> Onboarding Date </h5>
                </div> -->
                <div class="col">
                    <p class="client-name">{{this.clientDto.createdDate | date : "dd-MM-yyyy"}}</p>
                </div>
            </div>
            <!-- <div class="row ">
                <div class="col   ">
                    <label class="count" (click)="AddEditClientDialog()">Edit</label>
                </div>
            </div> -->
        </mat-card-content>
    </mat-card>
</div>
<div class="proj-list">
    <label>Project List</label>
</div>
<div *ngIf="this.mobileview==false" class="col-md-12">
    <div class="card-container">
        <ng-container *ngIf="this.nodata == true; else elseTemplate">
            <img class="no-project-img" src="assets/No-project-data.png">
        </ng-container>
        <ng-template #elseTemplate>
            <mat-card class="matcardprojectdetail" [ngClass]="(todayDate  > project.endDate ) ? 'inactive' : 'active'"
                *ngFor="let project of this.projectList">
                <mat-card-content (click)="onViewProject(project)">
                    <div class="row">
                        <div class="col">
                            <h5 class="project-name">Project Name </h5>
                            <p>{{project.projectName | underScoreToSpace |
                                titlecase}}</p>
                        </div>
                        <div class="col">
                            <h5 class="project-name">Client Name </h5>
                            <p>{{this.clientDto.clientName}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h5 class="project-name">Start Date </h5>
                            <p>{{project.startDate | date:
                                "dd-MM-yyyy" }}</p>
                        </div>
                        <div class="col">
                            <h5 class="project-name">End Date </h5>
                            <p>{{project.endDate | date:
                                "dd-MM-yyyy" }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h5 class="project-name">Project Category </h5>
                            <p>{{project.projectCategory | underScoreToSpace |
                                titlecase}}</p>
                        </div>
                    </div>
                    <div class="members-div">
                        <div class="row  members" *ngFor="let  temp of map.get(project.id)">
                            <img src="assets/default-avatar.jpg" class="img-container  "
                                [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                [matTooltip]="temp">
                        </div>
                        <!-- <div class="number" (click)="addTalent(project)">
                            <mat-icon class="person_add">person_add</mat-icon>
                        </div> -->
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-template>
    </div>
    <pagination-controls id="asset_id" class="pagination" (pageChange)="p = $event"></pagination-controls>
</div>
<div *ngIf="this.mobileview==true" class="col-md-12">
    <div class="card-container ">
        <ng-container *ngIf="this.nodata == true; else elseTemplate">
            <img class="no-project-img" src="assets/No-project-data.png">
        </ng-container>
        <ng-template #elseTemplate>
            <mat-card class="matcardprojectdetail" [ngClass]="(todayDate  > project.endDate ) ? 'inactive' : 'active'"
                *ngFor="let project of this.projectList">
                <mat-card-content>
                    <div class="row mt-3">
                        <div class="col">
                            <h5 class="project-name">Project Name</h5>
                        </div>
                        <div class="col">
                            <p>{{project.projectName | underScoreToSpace |
                                titlecase}}</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <h5 class="project-name">Client Name </h5>
                        </div>
                        <div class="col">
                            <p>{{this.clientDto.clientName}}</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <h5 class="project-name">End Date </h5>
                        </div>
                        <div class="col">
                            <p>{{project.endDate | date:
                                "dd-MM-yyyy" }}</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <h5 class="project-name">Start Date </h5>
                        </div>
                        <div class="col">
                            <p>{{project.startDate | date:
                                "dd-MM-yyyy" }}</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <h5 class="project-name">Project Category </h5>
                        </div>
                        <div class="col">
                            <p>{{project.projectCategory | underScoreToSpace |
                                titlecase}}</p>
                        </div>
                    </div>
                    <div class="members-div">
                        <div class="row  members" *ngFor="let  temp of map.get(project.id)">
                            <img src="assets/default-avatar.jpg" class="img-container  "
                                [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                [matTooltip]="temp">
                        </div>
                        <!-- <div class="number" (click)="addTalent(project)">
                            <mat-icon class="person_add">person_add</mat-icon>
                        </div> -->
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-template>
    </div>
    <pagination-controls class="pagination" (pageChange)="p = $event">
    </pagination-controls>
</div>