import { Component, Inject, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplyRegularizationDTO } from 'src/app/dtos/ApplyRegularizationDTO';
import { RegularizationService } from 'src/app/services/regularization/regularization.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

import { AbsentService } from 'src/app/services/absent/absent.service';

@Component({
  selector: 'app-regularize',
  templateUrl: './regularize.component.html',
  styleUrls: ['./regularize.component.scss']
})
export class RegularizeComponent implements OnInit {
  regularizationDetails: FormGroup;
  applyRegulrizationDTO = new ApplyRegularizationDTO();
  val:any[]=[];
  dat:any;
  userId: any;
  finalDates: any[] = [];
  appliedForRegularization:boolean=false;


  constructor(
    public http: HttpClient,
    public fb: FormBuilder,
    private notificationService: NotificationService,
    private userDetailsService: UserDetailsService,
    public dialogRef: MatDialogRef<RegularizeComponent>,
    public regularizationService: RegularizationService,
    private userdetails :UserDetailsService,
    private absentService: AbsentService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.regularizationDetails = this.fb.group({
      reasonForRegularization: ['', [Validators.required]],
    })
  }
  ngOnInit(): void {
    const user  = this.userdetails.getUserDetails();
    this.getStatus(user.id,'UNMARKED_ATTENDANCE');
    console.log("this is data")
    console.log(this.data);

  }
  getStatus(userId,attendanceStatus){
    const user  = this.userdetails.getUserDetails();
    this.absentService.getstatusbyuser('UNMARKED_ATTENDANCE').subscribe(res => {
      for(let i=0;i<res.body.length;i++) {
      const details ={
        attendanceId:res.body[i].id,
        regularizeDateTime:res.body[i].attendanceDateTime,

      }
      this.val.push(details);
      }

    },(e)=>{
      console.log(e);
    }
    )

    return this.val;
  }


  applyRegularization() {
    if (!this.regularizationDetails.valid) return;
    const user = this.userDetailsService.getUserDetails();
    this.applyRegulrizationDTO.reasonForRegularization = this.regularizationDetails.value.reasonForRegularization;
    this.applyRegulrizationDTO.userId = user.id;
    const selectedData = [this.data.item];
    const regularizeDates = selectedData.map(item => {
      return {
        attendanceId: item.id,
        id:undefined,
        regularizeDateTime: item.attendanceDateTime,
      };
    });

    this.applyRegulrizationDTO.regularizeDates = regularizeDates;
    this.applyRegulrizationDTO.appliedForRegularization=true;
    this.regularizationService.applyForRealization(this.applyRegulrizationDTO).subscribe(res => {
      this.notificationService.sucess("Applied for regularization");
      this.appliedForRegularization=true;
      this.closeDialog();
    },
    error => {
      this.notificationService.sucess("Error while applying for regularization");
      if (error.status == 403){
        this.closeDialog();
      }
    });
  }


  closeDialog() {
    this.dialogRef.close({ event: 'Closed'});
  }
}
