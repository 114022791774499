<mat-card class="main-card">
  <div class="col-md-12">
    <ul class="nav nav-pills nav-fill nav-justified" id="pills-tab" role="tablist">
      <li class="nav-item mt-2">
        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
          aria-controls="pills-home" aria-selected="true">Birthdays</a>
      </li>
      <li class="nav-item mt-2">
        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
          aria-controls="pills-profile" aria-selected="false">Work Anniversary</a>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent ">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        <table class="table table-borderless">
          <tbody>
            <tr *ngFor="let user of Birthdaylist; let i = index">


              <td>
                <img class="img" [(src)]="user.userImageThumbnail" alt="...">
              <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              matTooltip=" {{user.firstName}}&nbsp;{{user.lastName}}" class="bday_label">&nbsp; {{user.firstName}}&nbsp;{{user.lastName}}</label>  
              </td>



              <td ><label class="bday_date">{{user.dateOfBirth.split("T")[0] | date: "d MMMM EEEE "}}</label></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <table class="table table-borderless">
          <tbody>
            <tr *ngFor="let user of Anniversarylist ; let i = index">

              <td *ngIf="user.isImage == true">
                <img class="img" [(src)]="user.userImageThumbnail" alt="...">
               <label  [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
               matTooltip=" {{user.firstName}}&nbsp;{{user.lastName}}" class="bday_label2">&nbsp; {{user.firstName}}&nbsp;{{user.lastName}}</label> 
              </td>

              <td *ngIf="user.isImage == false">
                <img class="img" matListAvatar src="assets/default-avatar.jpg" alt="...">
                <label  [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                matTooltip=" {{user.firstName}}&nbsp;{{user.lastName}}" class="bday_label2">&nbsp; {{user.firstName}}&nbsp;{{user.lastName}}</label> 
              </td>

              <td><label class="bday_date ml-2">{{user.message}}</label></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</mat-card>