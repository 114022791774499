<!-- Sidebar menus -->

<div class="nav-icons">
    <div [routerLinkActive]="['is-active']">
        <mat-list-item class="nav-list" routerLink="/home">
            <div class="main-div">
                <div>

                    <svg class="svg-icon" width="20" height="15" viewBox="0 0 20 23"  xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.875 22.0313C1.35417 22.0313 0.911458 21.849 0.546875 21.4844C0.182292 21.1198 0 20.6771 0 20.1563V7.96875C0 7.67708 0.0677082 7.39583 0.203125 7.125C0.338542 6.85417 0.520833 6.63542 0.75 6.46875L8.875 0.375C9.04167 0.25 9.21875 0.15625 9.40625 0.0937501C9.59375 0.0312501 9.79167 0 10 0C10.2083 0 10.4062 0.0312501 10.5938 0.0937501C10.7813 0.15625 10.9583 0.25 11.125 0.375L19.25 6.46875C19.4792 6.63542 19.6615 6.85417 19.7969 7.125C19.9323 7.39583 20 7.67708 20 7.96875V20.1563C20 20.6771 19.8177 21.1198 19.4531 21.4844C19.0885 21.849 18.6458 22.0313 18.125 22.0313H12.5V13.2813H7.5V22.0313H1.875Z" />
                        </svg>
                        
                </div>
                <div class="mt-2">
                    <span class="caption mat-icon-color" >Dashboard</span>
                </div>
            </div>
        </mat-list-item>
    </div>
    <div [routerLinkActive]="['is-active']">
        <mat-list-item class="nav-list" routerLink="/task">
            <div class="main-div">
                <div>
                    <mat-icon class="mat-icon mat-icon-color">task</mat-icon>
                </div>
                <div>
                    <span class="caption mat-icon-color">Tasks</span>
                </div>
            </div>
        </mat-list-item>
    </div>
    <div [routerLinkActive]="['is-active']">
        <mat-list-item class="nav-list" routerLink="/careerManager">
            <div class="main-div">
                <div>



                    <svg class="svg-icon "  width="16" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.69167 11.2292L10.4125 12.95C10.5681 13.1056 10.7674 13.1833 11.0104 13.1833C11.2535 13.1833 11.4625 13.1056 11.6375 12.95L15.4583 9.12917V10.5292C15.4583 10.7625 15.541 10.9618 15.7062 11.1271C15.8715 11.2924 16.0806 11.375 16.3333 11.375C16.5667 11.375 16.7708 11.2924 16.9458 11.1271C17.1208 10.9618 17.2083 10.7528 17.2083 10.5V7C17.2083 6.76667 17.1208 6.5625 16.9458 6.3875C16.7708 6.2125 16.5667 6.125 16.3333 6.125H12.8042C12.5708 6.125 12.3715 6.2125 12.2062 6.3875C12.041 6.5625 11.9583 6.76667 11.9583 7C11.9583 7.25278 12.041 7.46181 12.2062 7.62708C12.3715 7.79236 12.5806 7.875 12.8333 7.875H14.2042L11.025 11.0833L9.30417 9.3625C9.12917 9.1875 8.92014 9.1 8.67708 9.1C8.43403 9.1 8.23472 9.1875 8.07917 9.3625L4.52083 12.9208C4.34583 13.0958 4.25833 13.3049 4.25833 13.5479C4.25833 13.791 4.34583 13.9903 4.52083 14.1458C4.69583 14.3208 4.90486 14.4083 5.14792 14.4083C5.39097 14.4083 5.59028 14.3208 5.74583 14.1458L8.69167 11.2292ZM1.75 21C1.28333 21 0.875 20.825 0.525 20.475C0.175 20.125 0 19.7167 0 19.25V1.75C0 1.28333 0.175 0.875 0.525 0.525C0.875 0.175 1.28333 0 1.75 0H19.25C19.7167 0 20.125 0.175 20.475 0.525C20.825 0.875 21 1.28333 21 1.75V19.25C21 19.7167 20.825 20.125 20.475 20.475C20.125 20.825 19.7167 21 19.25 21H1.75Z" />
                    </svg>
                </div>
                <div class="mt-2">
                    <span class="caption mt-2 mat-icon-color" style="margin-left:11px">Performance</span>
                </div>
            </div>
        </mat-list-item>
    </div>
<!-- PMS commented for Production -->

    <!-- <div [routerLinkActive]="['is-active']" *ngIf="this.hasResourceAccessRights">
        <mat-list-item class="nav-list" routerLink="/project">
            <div class="main-div">
                <div>
                    <mat-icon class="mat-icon mat-icon-color">assignment</mat-icon>
                </div>
                <div>
                    <span class="caption ml-1 mat-icon-color">Resources</span>
                </div>
            </div>
        </mat-list-item>
    </div> -->

    <div [routerLinkActive]="['is-active']" *ngIf="this.hasEmployeeManagementRights">
        <mat-list-item class="nav-list" routerLink="/employee">
            <div class="main-div">
                <div>
                    <mat-icon class="people mat-icon-color">people</mat-icon>

                </div>
                <div>
                    <span class="caption mat-icon-color">Employees</span>
                </div>
            </div>
        </mat-list-item>
    </div>
    <div [routerLinkActive]="['is-active']" *ngIf=" this.flag">
        <mat-list-item class="nav-list" routerLink="/myprofile">
            <div class="main-div" (click)="reloadProfile()">
                <div>
                    <mat-icon class="mat-icon mat-icon-color">person</mat-icon>
                </div>
                <div>
                    <span class="caption mat-icon-color">Profile</span>
                </div>
            </div>
        </mat-list-item>
    </div>
<!-- LMS commented for Production -->
    <!-- <div [routerLinkActive]="['is-active']">

        <mat-list-item class="nav-list" routerLink="/leaveManagement">
            <div class="main-div">
                <div>
                    <mat-icon class="mat-icon">calendar_month </mat-icon>
                </div>
                <div>
                    <span class="caption mat-icon-color">Leave</span>
                </div>
            </div>
        </mat-list-item>
    </div> -->
    <!--   <div [routerLinkActive]="['is-active']">
                    <mat-list-item class="nav-list" routerLink="/formGeneration">
                        <div class="main-div">
                            <div>
                                <mat-icon class="mat-icon ">insert_drive_file</mat-icon>
                            </div>
                            <div>
                                <span class="caption">Feedback</span>
                            </div>
                        </div>
                    </mat-list-item>
                </div>
                <div [routerLinkActive]="[]">
                    <mat-list-item class="nav-list">
                        <div class="main-div">
                            <div>
                                <mat-icon class="mat-icon ">help</mat-icon>
                            </div>
                            <div>
                                <span class="caption">Help</span>
                            </div>
                        </div>
                    </mat-list-item>
                </div> -->
                <!-- Configure commented for Production -->

                <!-- <div [routerLinkActive]="['is-active']" *ngIf="this.hasResourceAccessRightsHr">

                    <mat-list-item class="nav-list" routerLink="/configure-menu">
                        <div class="main-div">
                            <div>
                                <mat-icon class="mat-icon">settings</mat-icon>
                            </div>
                            <div>
                                <span class="caption mat-icon-color">Configure</span>
                            </div>
                        </div>
                    </mat-list-item>
                </div> -->
            
    <div class="feedback">
        <mat-list-item class="nav-list ">
            <div class="main-div">
                <a href="https://forms.microsoft.com/r/i47FDefWws" target="_blank" rel="noopener noreferrer">
                    <div>
                        <mat-icon>
                            <img class="feedback_logo" src="assets\report_bug.png">
                        </mat-icon>
                    </div>
                    <div>
                        <span class="feedback_label ml-1"> Report<br />a Bug</span>

                    </div>
                </a>
            </div>
        </mat-list-item>
    </div>
    <div class="logout ml-1">
        <mat-list-item class="nav-list " (click)="logOut()">
            <div class="main-div">
                <a href="/login">
                    <div>
                        <mat-icon>
                            <img class="logout_logo" src="assets/Logout.png">
                        </mat-icon>
                    </div>
                    <div>
                        <span class="logout-label"> Logout</span>

                    </div>
                </a>
            </div>
        </mat-list-item>
    </div>

</div>