<mat-accordion class="example-headers-align">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Visas Available
            </mat-panel-title>

            <mat-panel-description (click)="$event.stopPropagation();">
                <ng-container *ngIf="this.panelOpenState==true;else elseTemplate">
                    <button class="add_button" (click)="addVisa()">+Add Visa Details</button>
                </ng-container>
                <ng-template #elseTemplate>

                </ng-template>

            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="container-fluid">
            <div class="row">
                <button class="plus_button" (click)="addVisa()"><span class="mr-2">+Add</span></button>

            </div>
            <div class="row">

                <div class="col-md-12 form_main_div">
                    <form [formGroup]="editBusinesstravel">
                        <div formArrayName="visa" *ngFor="let visass of this.Visa().controls; let i = index">
                            <div class="row">
                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Visa Type<span class="contentRed"></span></mat-label>
                                        <input matInput formControlName="visaType" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Country<span class="contentRed"></span></mat-label>
                                        <mat-select Required formControlName="country" >
                                            <mat-option *ngFor="let option of countries" [value]="option">{{option}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Issue Date <span class="contentRed"></span></mat-label>
                                        <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy"
                                            formControlName="validFrom">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>

                                </div>



                            </div>
                            <div class="row">
                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Expiration Date <span class="contentRed"></span></mat-label>
                                        <input matInput [min]="editBusinesstravel.controls.visa.controls[i].controls.validFrom.value" [matDatepicker]="picker2" placeholder="mm/dd/yyyy"
                                            formControlName="validTo" [disabled]="!editBusinesstravel.controls.visa.controls[i].controls.validFrom.value">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>

                                </div>
                               
                                <div class="col-md-2" [formGroupName]="i">
                                    <mat-form-field appearance="none">
                                        <input class="active_label" matInput formControlName="active" readonly>
                                    </mat-form-field>
                                </div>
                               








                            </div>
                         
                                <button (click)="removevisa(visass.value.id,i)"
                                    class="visa_remove_label">Remove</button>
                          
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>

</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Passport Details
            </mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation();">
                <ng-container *ngIf="this.panelOpenState1==true;else elseTemplate">
                    <button class="add_button" (click)="addPassport()">+Add Passport Details</button>


                </ng-container>
                <ng-template #elseTemplate>

                </ng-template>

            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="container-fluid">
            <div class="row"> <button class="plus_button" (click)="addPassport()"><span
                        class="mr-2">+Add</span></button>
            </div>
            <div class="row">

                <div class="col-md-12 form_main_div">
                    <form [formGroup]="editBusinesstravel1">
                        <div class="row" formArrayName="passports"
                            *ngFor="let pass of this.PassportDetails().controls; let i = index">

                            <div class="col-md-3" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Expiration Date <span class="contentRed"></span></mat-label>
                                    <input matInput [matDatepicker]="picker3" placeholder="mm/dd/yyyy"
                                        formControlName="expiryDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Passport Number<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="passportNumber" autocomplete="off" (input)="onInputToUppercase($event)">
                                    <mat-error>
                                        Must be alphanumeric
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Country</mat-label>
                                    <mat-select Required formControlName="country" >
                                        <mat-option *ngFor="let option of countries" [value]="option">{{option}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>




                            <!-- <div class="col-md-4 certificate"> -->
                                <!-- <label for="my_file" class="certificate_label">Attach Relevant Document</label>
                               -->
                                <!-- <ng-container [formGroupName]="i"> <input  type="file" id="my_file"
                                        (change)="onChange1($event,i)" name="passportb">
                                    <ng-container *ngIf="passportb && passportb.length else elseBlock "
                                        formArrayName="passportb">

                                       
                                       

                                        <div *ngFor="let image of passportb.controls; let i = index">
                                            <pdf [src]="image.value">
                                               </pdf>
                                            
                                        </div>

                                  
                                        
                                      
                                    </ng-container>  
                                    <ng-template #elseBlock>
                                            <p class="certificate_label">No file Selected</p>

                                        </ng-template>
                                </ng-container> -->
                            

                                <!-- <img class="img_size" src="assets/Certificate.png" >
                                <label for="my_file" class="certificate_label">Attach the Passport copy</label> -->

                                <!-- <input type="file" id="my_file" style="display:none" type="file" #fileInput
                                    (change)="onChange1($event,i)" >
                                <ng-container  *ngIf="this.passportbase64array[i]!=null">
                                    <label *ngIf="!this.passportbase64array[i]!=null"class="preview_pdf_label" (click)="open(content)">Watch Preview</label>

                                    <ng-template #content let-modal>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div class="modal-body">
                                            <pdf-viewer [src]="this.passportbase64array[i]" [rotation]="0"
                                                [fit-to-page]="true" [original-size]="true" [show-all]="true"
                                                style="display: block;">
                                            </pdf-viewer>
                                        </div>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        <p class="certificate_label">No file Selected</p>

                                    </ng-template>
                                </ng-container> -->
 

                            <!-- </div> -->

                            <div class="col-md-4 visa_properties">

                                <button (click)="removepassport(pass.value.id,i)"
                                    class="remove_passport_label">Remove</button>



                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit2()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>

</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Travel with BlueAltair
            </mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation();">
                <ng-container *ngIf="this.panelOpenState2==true;else elseTemplate">
                    <button class="add_button" (click)="addbluealtairtravel()">+Add BlueAltair Travel</button>

                </ng-container>
                <ng-template #elseTemplate>

                </ng-template>

            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="container-fluid">

            <div class="row">
                <button class="plus_button" (click)="addbluealtairtravel()"><span class="mr-2"> +Add </span></button>

            </div>
            <div class="row">

                <div class="col-md-12 form_main_div">
                    <form [formGroup]="editBusinesstravel2">
                        <div formArrayName="bluealtairarray"
                            *ngFor="let bluetravel of this.BlueAltairTravel().controls; let i = index">

                            <div class="row">

                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>From Country<span class="contentRed"></span></mat-label>
                                        <mat-select Required formControlName="fromCounry" >
                                            <mat-option *ngFor="let option of countries" [value]="option">{{option}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>To Country<span class="contentRed"></span></mat-label>
                                        <mat-select Required formControlName="toCountry" >
                                            <mat-option *ngFor="let option of countries" [value]="option">{{option}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <!---------------------------------------------------------------------------->
                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Travel Date <span class="contentRed"></span></mat-label>
                                        <input matInput [max]="today" [matDatepicker]="picker4" placeholder="mm/dd/yyyy"
                                            formControlName="travelDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                        <mat-datepicker #picker4></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Reason For Travel<span class="contentRed"></span></mat-label>
                                        <input matInput formControlName="reasonOfTravel" autocomplete="off">
                                    </mat-form-field>

                                </div>



                            </div>
                            <div class="row">
                                <div class="col-md-6" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="8" placeholder="Additional Info"
                                            formControlName="additionalInfo"></textarea>

                                    </mat-form-field>

                                </div>
                                <div class="col-md-4" class="visa_properties">
                                    <button class="remove_label" (click)="removebluealtair(bluetravel.value.id,i)">
                                        Remove</button>
                                </div>

                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit3()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>

</mat-accordion>