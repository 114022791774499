import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UnmarkedAttendanceUrlService {
  private readonly unmarekdAttendanceBaseUrl = '/api/attendances/calendar-attendance';


  constructor() { }

  getBaseUrl() {
    return this.unmarekdAttendanceBaseUrl;
  }


  getUnmarkedAttendance(userId, year) {
    return this.unmarekdAttendanceBaseUrl + '/' + userId + '/' + year;
  }

  getOnLeave(userId, year){
    return this.unmarekdAttendanceBaseUrl + '/' + userId + '/' + year;
  }

  getUnmarkedAndLeave(userId, year){
    return this.unmarekdAttendanceBaseUrl + '/' + userId + '/' + year;
  }
}
