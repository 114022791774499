<div class="row ">
    <div class="col-lg-6 col-md-12 ">
        <mat-card class="main-card" *ngIf="this.userDTO.length !== 0">
            <div class="header">
                <div>
                    <h2>Current Employment </h2>
                </div>
                <div *ngIf="hasEmployeeManagementRights">
                    <mat-icon (click)="editCurrentEmployment()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
                </div>
            </div>
            <div class="current_employment_mat_body mt-2">
                <div>
                    <span class="current_employment_heder"> Date of Joining </span>
                    <br>
                    <label class="current_employment_name mt-1">{{this.userDTO.joiningDate | date :
                        'dd-MM-yyyy'}}</label>
                </div>
                <div>
                    <span class="current_employment_heder"> Confirmation Date</span>
                    <br>
                    <label class="current_employment_name mt-1">{{this.userDTO.confirmationDate | date :
                        'dd-MM-yyyy'}}</label>
                </div>
            </div>
            <div class="current_employment_mat_body mt-2">
                <div>
                    <span class="current_employment_heder"> Base Location </span>
                    <br>
                    <label class="current_employment_name mt-1">{{this.userDTO.baseLocation | titlecase}}</label>
                </div>
                <div>
                    <span class="current_employment_heder mr-4"> Current Location</span>
                    <br>
                    <label class="current_employment_name mt-1">{{this.userDTO.officeLocation | titlecase}}</label>
                </div>
            </div>
            <div class="current_employment_mat_body mt-2">
                <div>
                    <span class="current_employment_heder"> Probation Period </span>
                    <br>
                    <label class="current_employment_name mt-1">{{this.userDTO.probationPeriod}} Days </label>
                </div>
            </div>
        </mat-card>
        <mat-card class="main-card" *ngIf="this.userDTO.length == 0">
            <div class="header">
                <div>
                    <h2>Current Employment </h2>
                </div>
            </div>
            <div class="no_data_div">
                <img class="img" src="assets/no-data-available.png">
                <label class="no_data_label mt-2">No Data Available</label>
            </div>

        </mat-card>
    </div>
    <div class="col-lg-6 col-md-12">
        <mat-card class="main-card" *ngIf="this.userDTO.length !== 0">
            <div class="header">
                <div>
                    <h2>Position Details </h2>
                </div>
                <div>
                    <mat-icon (click)="editPositionDetails()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
                </div>
            </div>
            <div class="content_scroll">
                <div class="position_details_mat_body mt-2" *ngFor="let p of  this.positionHistory">

                    <label class="position_details_name mt-1">{{this.jobTitle[p.jobTitle]}}</label>
                    <div><span class="position_details_heder">
                            <svg *ngIf='p.endDate===null' class="star" width="12" height="11" viewBox="0 0 12 11"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z"
                                    fill="url(#paint0_radial_1891_6521)" />
                                <defs>
                                    <radialGradient id="paint0_radial_1891_6521" cx="0" cy="0" r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(6 6) rotate(90) scale(5)">
                                        <stop stop-color="#0045B9" />
                                        <stop offset="1" stop-color="#FAA29A" />
                                    </radialGradient>
                                </defs>
                            </svg>
                            {{p.startDate | date : 'dd-MM-yyyy'}}</span>~
                        <span *ngIf='p.endDate!==null' class="position_details_heder">
                            <svg *ngIf='p.endDate===null' class="star" width="12" height="11" viewBox="0 0 12 11"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z"
                                    fill="url(#paint0_radial_1891_6521)" />

                                <defs>
                                    <radialGradient id="paint0_radial_1891_6521" cx="0" cy="0" r="1"
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(6 6) rotate(90) scale(5)">
                                        <stop stop-color="#0045B9" />
                                        <stop offset="1" stop-color="#FAA29A" />
                                    </radialGradient>
                                </defs>
                            </svg>
                            {{p.endDate | date : 'dd-MM-yyyy'}}</span>
                        <br>
                    </div>

                </div>
            </div>

            <!-- <div class="position_details_mat_body mt-2">
                    <div>
                        <span class="position_details_heder">Start -02-09-2021</span>
                        <br>
                        <label class="position_details_header mt-1">{{this.jobTitle[this.userDTO.jobTitle]}}</label>
                    </div>
                    <div>
                        <span class="position_details_heder">End -02-09-2021</span>
                    </div>
                </div> -->
        </mat-card>
        <mat-card class="main-card" *ngIf="this.userDTO.length == 0">
            <div class="header">
                <div>
                    <h2>Position Details </h2>
                </div>
            </div>
            <div class="no_data_div">
                <img class="img" src="assets/no-data-available.png">
                <label class="no_data_label mt-2">No Data Available</label>
            </div>

        </mat-card>
    </div>
    <!-- <div class="col-lg-4 col-md-12">
        <mat-card class="main-card" *ngIf="this.userDTO.length !== 0">
            <div class="header">
                <div>
                    <h2>Capability Details </h2>
                </div>
                <div>
                    <mat-icon (click)="editCapabilityDetails()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
                </div>
            </div>
            <div class="content_scroll">
                <div class="position_details_mat_body mt-2" *ngFor="let p of  this.capabilityDetails">
                    <label class="position_details_name mt-1">{{this.department[p.capability]}}</label>

                    <div><span class="position_details_heder">

                            {{p.startDate | date : 'dd-MM-yyyy'}}</span>~

                        <span class="position_details_heder">
                            {{p.endDate | date : 'dd-MM-yyyy'}}</span>


                        <br>
                    </div>
                </div>
            </div>
            
        </mat-card>
        <mat-card class="main-card" *ngIf="this.userDTO.length == 0">
            <div class="header">
                <div>
                    <h2>Capability Details </h2>
                </div>
            </div>
            <div class="no_data_div">
                <img class="img" src="assets/no-data-available.png">
                <label class="no_data_label mt-2">No Data Available</label>
            </div>

        </mat-card>
    </div> -->
</div>