<div class="container-fluid ml-4 mt-4">
    <div class=" row back-btn" (click)="backClicked()">
        <figure >
            <mat-icon> <img class="upload_img" src="assets/back_arrow.png" alt=""></mat-icon>
            <label class="upload_text">Home</label>
        </figure>
    </div>
   
    

    <div class="container">
        <h1>Unauthorized</h1>
    
    <p>You are seeing this page because you requested an unauthorized content</p>
    <p>If you think this is a mistake, then contact the site admin</p>
    </div>
    
</div>


