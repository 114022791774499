<div class="container-fluid">
    <div class="main_header_div">
        <div class="child">
            <h1 class="header_name">Bulk Holiday of {{data.HolidayYear}} Import Summary</h1>
        </div>
        <div class="child_close_btn">
            <!-- <button class="closeButton" (click)="closeDialog()">Close</button> -->
            <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>

        </div>
    </div>
    <hr />

    <!-- Show bulk import summary in table form in a mat-dialog popup -->


    <div class="row form_main_div">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4">
                    <label class="imports_title">Total Holidays in Excel</label>
                    <br>
                    <span class="input_data mt-1">{{data.Failed++data.Successful+data.dbDuplicates+data.excelDuplicates}}</span>
                </div>
                <div class="col-md-4">
                    <label class="imports_title">Successful Imports</label>
                    <br>
                    <span class="input_data mt-1">{{data.Successful}}</span>
                </div>
                <div class="col-md-4">
                    <label class="imports_title">Holidays Not Selected</label>
                    <br>
                    <span class="input_data mt-1">{{data.NotSelected}}</span>
                </div>
            </div>
            <div class="row mt-4" >
                <div class="col-md-4">
                    <label class="imports_title">Already Existing Holidays</label>
                    <br>
                    <span class="input_data mt-2">{{data.dbDuplicates}}</span>
                </div>
                <div class="col-md-4">
                    <label class="imports_title">Duplicate Holidays in Excel</label>
                    <br>
                    <span class="input_data mt-2">{{data.excelDuplicates}}</span>
                </div>
                <div class="col-md-4">
                    <label class="imports_title">Holidays of different Year</label>
                    <br>
                    <span class="input_data mt-2">{{data.Failed}}</span>
                    <!-- <label class="imports_title">Total Holidays for {{data.HolidayYear}}</label>
                    <br>
                    <span class="input_data mt-2">{{data.TotalHolidaysforThatYear}}</span> -->
                </div>
            </div>
        </div>
    </div>
</div>
