import { Component, Inject, OnInit,ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import { PreviousEmployment } from 'src/app/models/previousEmployment';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-employment-history',
  templateUrl: './employment-history.component.html',
  styleUrls: ['./employment-history.component.scss']
})
export class EmploymentHistoryComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
   preEmployment: FormArray;
  editSpotlightDetails: FormGroup;
  today = new Date();
  removedPreviousemployment = [];

  noOnlySpecialCharactersValidator():ValidatorFn {
    
   
    const onlySpecialCharsRegex = /^[!#$%^&*(),.<>?":@?\{}|<_ /]+$/;

    return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;

    if (onlySpecialCharsRegex.test(value)) {
      return { noOnlySpecialCharacters: true };
    }

    return null;
  };
  }
  

  constructor(public userDetailService: UserDetailsService,
    public userService: UserService,
    
    public toaster:ToastrService,
    public fb: FormBuilder,public empservice: PreviousEmploymentService,
    public dialogRef: MatDialogRef<EmploymentHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User) {
      this.editSpotlightDetails = this.fb.group({
        previousEmployment: this.fb.array([])
     });
     
    }


  ngOnInit(): void {

    //this.data will have userid
    this.empservice
    // .getprevempByUserIdSubscribe(this.userDetailService.getUserDetails().id)
    .getprevempByUserIdSubscribe(this.data)
    .subscribe((res) => {
      if (res.body[0] == null) {
        this.addPreEmployment();
      }
      this.setPrevempFormArray(res.body);
    });

  }

  getMinEndDate(i){
    // var endDate = this.editSpotlightDetails.get('previousEmployment').get('startDate')
    var endDate = this.editSpotlightDetails.get('previousEmployment')['controls'][i].get('startDate').value
     return endDate
  }


  createpreEmp() {
    let formGroup = this.fb.group(new PreviousEmployment());
    formGroup.controls.comapanyName.setValidators([Validators.required,this.noOnlySpecialCharactersValidator()]);
    formGroup.controls.jobTitle.setValidators([Validators.required,this.noOnlySpecialCharactersValidator()]);
    formGroup.controls.startDate.setValidators([Validators.required]);
    formGroup.controls.endDate.setValidators([Validators.required]);

     
    
    return formGroup;
  }

  processDate(previousEmployment) {
    let experience;
    if (previousEmployment.controls.startDate.valid && previousEmployment.controls.endDate.valid) {
      let startDate: Date = previousEmployment.controls.startDate.value
      let endDate: Date = previousEmployment.controls.endDate.value
      let diff = endDate.getTime() - startDate.getTime()
      let days = diff / (1000 * 60 * 60 * 24)
      let year = this.getYearFromDays(days);
      let months = this.getMonthFromDays(days)
      experience = year > 0 ? year + ' Year' : ''
      experience += months > 0 ? months + ' Months' : ''
      if (months < 1 && year < 1) {
        experience = "< 1 Month"
      }
    }
    return experience
  }
  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30)
  }
  getYearFromDays(days) {
    return Math.floor(days / 365)
  }

  checkError = (controlName: string, errorName: string) => {
    return this.editSpotlightDetails.controls[controlName].hasError(
      errorName
    );
  };

  isValidEndDate():Boolean{
    let formGroup = this.fb.group(new PreviousEmployment());
    if(formGroup.controls.startDate < formGroup.controls.endDate){ 
        return true;
    }
    return false;
  }


  setPrevempFormArray(response) {
    let data: number;
    this.preEmployment = this.editSpotlightDetails.get(
      'previousEmployment'
    ) as FormArray;
    let empList: PreviousEmployment[] =
      this.empservice.setPrevempResponse(response);
    for (let s of empList) {
      let formGroup: FormGroup = this.fb.group(s);
      formGroup.controls.experiencemonth.setValue(this.processDate(formGroup));
      this.preEmployment.push(formGroup);
    }
  }
  addEvent(type: string, previousEmployment: FormGroup) {
    previousEmployment.controls.experiencemonth.setValue(
      this.processDate(previousEmployment)
    );
  }
  PreviousEmp(): FormArray {
    return this.editSpotlightDetails.get('previousEmployment') as FormArray;
  }
  addPreEmployment() {
    this.preEmployment = this.editSpotlightDetails.get(
      'previousEmployment'
    ) as FormArray;
    this.preEmployment.insert(0, this.createpreEmp());
  }
  removePreEmployment(id, i) {
    this.removedPreviousemployment.push(id);
    this.PreviousEmp().removeAt(i);
  }
  //Previous Employeement Details Form ends

  closeDialog(event:Event) {
    this.dialogRef.close({ event: 'Closed'})
  }



onSubmit()
{  if (this.editSpotlightDetails.valid) {
  for (let prevemp of this.editSpotlightDetails.value.previousEmployment) {
    

    if (
      
      prevemp &&
      !prevemp.id &&
      prevemp.comapanyName != null &&
      prevemp.jobTitle != null &&
      prevemp.startDate != undefined &&
      prevemp.endDate != undefined
      
    ) {
      // prevemp.userId = this.userDetailService.getUserDetails().id;
      prevemp.userId = this.data;
      this.empservice.create(prevemp).subscribe((res) => {
        // this.toaster.success("Successfully Saved")
        this.empservice.setexperience('refresh_experience')
        this.dialogRef.close({ event: 'Closed'})
       // this.notificationService.sucess('done');
       this.toaster.success("Successfully Saved")
      });
    } else if (
      prevemp &&
      prevemp.id &&
      prevemp.comapanyName != null &&
      prevemp.jobTitle != null &&
      prevemp.startDate != undefined &&
      prevemp.endDate != undefined
    ) {
      this.empservice.update(prevemp).subscribe((res) => {
        // this.toaster.success("Successfully Saved")
        this.empservice.setexperience('refresh_experience')
                this.dialogRef.close({ event: 'Closed'})
       // this.notificationService.sucess('Updated');
       this.toaster.success("Successfully Saved")
      });
    } else {
      
    }
  }
}
if(this.removedPreviousemployment.length > 0){
for (let i in this.removedPreviousemployment) {
  this.empservice.delete(this.removedPreviousemployment[i]);
  // this.toaster.success("Successfully Saved")
  this.dialogRef.close({ event: 'Closed'})
}
}

}

}
