import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientDTO } from 'src/app/dtos/ClientDTO';
import { ClientService } from 'src/app/services/client/client.service';
import { ProjectService } from 'src/app/services/project.service';
import { AddEditClientComponent } from './add-edit-client/add-edit-client.component';
import { SharePointService } from 'src/app/services/sharePoint/sharePoint.service';
import { ToastrService } from 'ngx-toastr';
import { ButtonStateService } from 'src/app/services/dataSync/sync-data.service';
@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {
  nodata:Boolean=true
  clientList=[]
  projectcount={};
  searchText: string;
  p :number=1;
  public getScreenWidth: any;
  public getScreenHeight: any;
  mobileview:boolean=false
  clientDto: ClientDTO
  syncButtonDisabled = false;
  
  constructor( public clientservice: ClientService,public router:Router,
    public projectService:ProjectService,public dialog: MatDialog,private sharePointService: SharePointService,private toasterService: ToastrService,private buttonStateService: ButtonStateService) { 
   
      this.clientservice.listen().subscribe((m:any)=>
      {
        this.clientList.length=0;
        this.callfunc();
      })
      
    }
  ngOnInit(): void {
    this.callfunc();
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.getScreenWidth>960){
      this.mobileview=false
    }
    if(this.getScreenWidth<960){
      this.mobileview=true
    }
    this.buttonStateService.getSyncButtonDisabledState().subscribe(disabled => {
      this.syncButtonDisabled = disabled;
    });
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.getScreenWidth>960){
      this.mobileview=false
    }
    if(this.getScreenWidth<960){
      this.mobileview=true
    }
  }
  viewClient(client) {
    this.router.navigate(['client_details', client.id]);
  }
  callfunc(){
     
    this.clientservice.getClientsSubscribe().subscribe((res)=>{


      if(res.body?.length != 0){
        this.nodata=false
      }
      for(let r of res.body){
       
        this.clientList.push(r);
        this.projectService.getprojectfromclient(r.id).subscribe(response=>{
          this.projectcount[r.id]=response.body.length;
         
        })
      }
    })
  }

  AddEditClientDialog(client) {
     
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '95%';
    dialogConfig.data = client;
    const dialogRef = this.dialog.open(AddEditClientComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      
    this.callfunc();
    });
  }


onClickButton(){
  this.buttonStateService.setSyncButtonDisabledState(true);
  this.getSharePointData();
}
  getSharePointData() {
      //  this.syncButtonDisabled = true;
       this.sharePointService.getSharePointData().subscribe(
       () => {
        
         setTimeout(() => {
           this.syncButtonDisabled = false;
           this.toasterService.info("'Data synced successfully.")
           
           setTimeout(() => {
            location.reload();
           }, 2000); 
         }, 60000); 
       },
       (error) => {
         
         console.log("error in getting data from SharePoint", error)
         this.syncButtonDisabled = false;
          ;
          this.toasterService.error("Sync failed. Please try again.")
       }
     );
   }
   
}