import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { LeaveBalancesServiceUrl } from '../restAPI/leaveBalancesURLs/leave-balances-url.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveBalanceService {

  constructor(
    private restApiService: RestAPIService,
    private leavebalancesrUrlService: LeaveBalancesServiceUrl
  ) { }
  getLeaveBalanceByIdAndYear(userId,year){
    return this.restApiService.get(
      this.leavebalancesrUrlService.getLeaveBalances(userId,year)
    )
  }

  isLeaveBalanceEnableOrDisable(leaveType,userid,isEnabled,year){
    return this.restApiService.put(
      this.leavebalancesrUrlService.isleaveBalanceEnableOrDisable(leaveType,userid,isEnabled,year),null
    )
  }

  addLeaveBalanceByHR(leaveType,count,userId,year){
    return this.restApiService.put(
      this.leavebalancesrUrlService.addLeaveBalanceByHRUrl(leaveType,count,userId,year),null
    )
  }
  bulkConfigureByHR(leaveType,leaveCount,year){
    return this.restApiService.put(
      this.leavebalancesrUrlService.bulkConfigureByHRUrl(leaveType,leaveCount,year),null
    )
  }

  bulkExcelUploadbyHR(year,payload){
    return this.restApiService.post(
      this.leavebalancesrUrlService.bulkExcelUploadByHRUrl(year),payload
    )
  }
}
