import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CalibratedReportComponent } from '../calibrated-report.component';

@Component({
  selector: 'app-no-data-available',
  templateUrl: './no-data-available.component.html',
  styleUrls: ['./no-data-available.component.scss']
})
export class NoDataAvailableComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CalibratedReportComponent>) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close()
  }

}
