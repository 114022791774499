<div class="container-fluid mt-2">
    <div class="row">
        <div class="col-md-12 close-icon">
            <mat-icon class="close-btn" (click)="matDialogClose()">close</mat-icon>
        </div>
    </div>
    <!-- <img class="main-card-destop" src="\assets\Image close.png"> -->
    <div class="row  main_container" *ngIf="!this.isProbation">
        <div class="col-md-12">
            <h1 class="confirmation_text">Are you sure?</h1>
            <h3 class="confirmation_text-2">Do you really want to reopen Feedback?</h3>
            <h3 class="confirmation_text-3">This process cannot be undone.</h3>
        </div>
    </div>
    <div class="row  main_container" *ngIf="this.isProbation">
        <div class="col-md-12">
            <h1 class="confirmation_text">Are you sure?</h1>
            <h3 class="confirmation_text-2">Do you really want to reopen <br> Probation Feedback?</h3>
            <h3 class="confirmation_text-3">This process cannot be undone.</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="main_button_div">
                <button mat-button class="cancel-btn"  (click)="matDialogClose()">No</button>
                <button mat-button class="delete-button" (click)="reopen()">Yes</button>
            </div>
        </div>
    </div>
</div>