import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { PassportDetailsDTO } from 'src/app/dtos/PassportDetailsDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { VisaDetailsDTO } from 'src/app/dtos/VisaDetailsDTO';
import { BlueAltairTravel } from 'src/app/models/bluealtairtravel';
import { PassportDetails } from 'src/app/models/passportdetails';
import { Visa } from 'src/app/models/visa';
import { BluealtairtravelService } from 'src/app/services/blue-altair-travel/bluealtairtravel.service';
import { PassportDetailsService } from 'src/app/services/passport-details/passport-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { VisaService } from 'src/app/services/visa/visa.service';
import { VisaDetailsService } from 'src/app/services/visadetails/visadetails.service';
import { PassportDetailsComponent } from './passport-details/passport-details.component';
import { TravelComponent } from './travel/travel.component';
import { Countries,} from 'src/app/services/country/country-enum';
import { VisaDetailsComponent } from './visa-details/visa-details.component';

@Component({
  selector: 'app-business-travel',
  templateUrl: './business-travel.component.html',
  styleUrls: ['./business-travel.component.scss']
})
export class BusinessTravelComponent implements OnInit {
  travelhistorydto:BlueAltairTravel[]=[]
  visadto :  Visa[]=[]
  visastatus={}
  passport = new PassportDetails()
  passportdto: PassportDetailsDTO[]=[]
  passportstatus = {}
  userdto = new UserDTO()
  userid: any;
  @Output() dummyfunc = new EventEmitter();
  flag=false
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  countriesEnum=Countries;
  constructor(public userDetailService: UserDetailsService,
    public dialog:MatDialog,public passportservice:PassportDetailsService,
    public visadetailservice:VisaService,public travelhistoryservice:BluealtairtravelService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.userid = this.route.snapshot.paramMap.get('id');

    if (this.userid == null){
      this.userid = this.userDetailService.getUserDetails().id;
    }
    this.passportservice
    .getpassByUserIdSubscribe(this.userid)
    .subscribe((res) => {
      this.passportdto = this.passportservice.setPassportResponse(res.body);
      for (let r of res.body) {
        if(this.flag==false&&res.body.length>0)
      {
      this.dummyfunc.emit(25);
      this.flag=true;
      }
        this.passportstatus[r.id] = this.processDate(r);
      
      }
    });
    // this.passportservice
    // .getpassByUserIdSubscribe(this.userid)
    // .subscribe((res) => {
    //   for (let r of res.body) {
    //     this.passportstatus[r.id] = this.processDate(r);
      
    //   }
    // })
      this.visadetailservice.getvisaByUserIdSubscribe(this.userid)
    .subscribe((res)=>{
      this.visadto =this.visadetailservice.setVisaResponse(res.body);
      for(let r of res.body)
      {
      this.visastatus[r.id] = this.processDate2(r);
      }
    });


    // this.visadetailservice
    // .getvisaByUserIdSubscribe(this.userDetailService.getUserDetails().id)
    // .subscribe((res) => {
    //   for(let r of res.body)
    //   {
    //   this.visastatus[r.id] = this.processDate2(r);
    //   }
    // });

    this.travelhistoryservice.gettravelByUserIdSubscribe(this.userid)
    .subscribe((res)=>{
      this.travelhistorydto =this.travelhistoryservice.settravelResponse(res.body)
    })

  }
  
  editPassportDetailsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = "passport-class";
    //dialogConfig.data=this.userdto;
    dialogConfig.data=this.userid;
    const dialogRef = this.dialog.open(PassportDetailsComponent, dialogConfig) .afterClosed()
    .subscribe((res) => {
      if (res.event == 'Closed') {
        this.ngOnInit();
      }
    });
  }
  editVisaDetails() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = "visadetails-class";
   dialogConfig.data=this.userid;
    const dialogRef = this.dialog.open(VisaDetailsComponent, dialogConfig) .afterClosed()
    .subscribe((res) => {
      if (res.event == 'Closed') {
        this.ngOnInit();
      }
    });
  }
  editBAtravelDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = "travelba-class";
    //dialogConfig.data=this.userdto;
    dialogConfig.data=this.userid;
    const dialogRef = this.dialog.open(TravelComponent, dialogConfig) .afterClosed()
    .subscribe((res) => {
      if (res.event == 'Closed') {
        this.ngOnInit();
      }
    });
  }


  processDate(i) {
   
    let status;
  
    if (i.expiryDate) {
      let validFrom = new Date(i.validFrom)
      let expiryDate = new Date(i.expiryDate)
      let todaydate :Date = new Date()
      let diff2= todaydate >=  expiryDate
      if(todaydate >=expiryDate)
      {
        status = "Inactive"
       
      }
      else
      {  status = "Active"
        
      }
    }
    return status
  }
  processDate2(i) {
  
    let status;
  
    if (i.validTo) {
      let validTo = new Date(i.validTo)
   
      let todaydate :Date = new Date()
      let diff2= todaydate >=  validTo
      if(todaydate >=validTo)
      {
        status = "Inactive"
       
      }
      else
      {  status = "Active"
        
      }
    }
    return status
  }
}
