import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PassportDetailsDTO } from 'src/app/dtos/PassportDetailsDTO';
import { UserDocumentDTO } from 'src/app/dtos/UserDocumentDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { PassportDetails } from 'src/app/models/passportdetails';
import { User } from 'src/app/models/user';
import { PassportDetailsService } from 'src/app/services/passport-details/passport-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserDocumentService } from 'src/app/services/user-document.service';
import { Countries,} from 'src/app/services/country/country-enum';

@Component({
  selector: 'app-passport-details',
  templateUrl: './passport-details.component.html',
  styleUrls: ['./passport-details.component.scss']
})
export class PassportDetailsComponent implements OnInit {
  editBusinesstravel1: FormGroup;
  passportbase64array=[];
  passportdocument = new UserDocumentDTO();
  passportdto = new PassportDetailsDTO();
  passport = new PassportDetailsDTO();
  userDto = new UserDTO();
  passports1: FormArray;
  isPassportSaved: boolean;
  passportname: string;
  removedpassport = [];
  imageError: string;
  bytes: string;
  countries=[];
  constructor(    public fb: FormBuilder, public userDetailService: UserDetailsService,
    private modalService: NgbModal,public toaster:ToastrService,
    public userdocumentservice: UserDocumentService, public passportservice: PassportDetailsService,
    public dialogRef: MatDialogRef<PassportDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.editBusinesstravel1 = this.fb.group({
        // passportb:this.fb.array([]),
         passports: this.fb.array([], [Validators.required]),
       });
     }

  ngOnInit(): void {
    //this.data contains userid
    // this.userDto = this.userDetailService.getUserDetails();
    this.countries=Object.values(Countries);
    this.passportservice
    .getpassByUserIdSubscribe(this.data)
    .subscribe((res) => {
      if (res.body[0] == null) {
        this.addPassport();
      }
      this.setPassportFormArray(res.body);
    });

  }
  onInputToUppercase(event: any) {
    const newValue = event.target.value.toUpperCase();
    event.target.value = newValue;
  }


  PassportDetails(): FormArray {
    return this.editBusinesstravel1.get('passports') as FormArray;
    
    
  }
  addPassport() {
    
 
    
    this.passports1 = this.editBusinesstravel1.get('passports') as FormArray;
    this.passports1.insert(0,this.createpassport());
   
    
  }
  createpassport() {
    let formGroup = this.fb.group(new PassportDetails());
    formGroup.controls.country.setValidators([Validators.required]);
    formGroup.controls.expiryDate.setValidators([Validators.required]);
   formGroup.controls.passportNumber.setValidators([Validators.required,Validators.pattern('^[A-Z0-9]*')]);
    formGroup.controls.passportb;
  
    return formGroup;
  }

  setPassportFormArray(response) {
    this.passports1 = this.editBusinesstravel1.get('passports') as FormArray;
    let passportList: PassportDetails[] =
      this.passportservice.setPassportResponse(response);
    for (let s of passportList) {
      let v: PassportDetails = new PassportDetails().bind(s);
      let passportdetails = this.createpassport();
      passportdetails.controls.id.setValue(v.id);
      passportdetails.controls.country.setValue(Countries[v.country]);
      passportdetails.controls.expiryDate.setValue(v.expiryDate);
      passportdetails.controls.passportNumber.setValue(v.passportNumber);
      passportdetails.controls.passportb.setValue(v.passportNumber);
    
      this.passports1.insert(0, passportdetails);
    }
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  open(content) {

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  
  onChange1(fileInput: any,index:number) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      this.passportname = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
      const imgBase64Path: String = e.target.result;
      
        
       //this.createImage(e.target.result)
       this.passportbase64array.push(imgBase64Path)
       this.isPassportSaved = true;
      //  this.editBusinesstravel1.patchValue({
      //  passportbase:this.passportbase64array
      // }) 
    };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

 
  removepassport(id, i: number) {
    
    //this.removedPassport
    // this.cardImageBase64pdf = null;
    // this.isPassportSaved = false;
    this.removedpassport.push(id);
    this.PassportDetails().removeAt(i);
  }
  //passport details code ends here
  
  closeDialog(event:Event) {
    this.dialogRef.close({ event: 'Closed'})
  }
  onSubmit() {
    if (this.editBusinesstravel1.valid) {
      for (let passport of this.editBusinesstravel1.value.passports) {
        passport.country = this.getEnumKeyByEnumValue(Countries,passport.country);
        if (
          passport &&
          !passport.id &&
          passport.country != '' &&
          passport.expiryDate != '' &&
          passport.passportNumber != undefined 
          //passport.passportb != ''
   
        ) {
          passport.userId = this.data;
          this.passportservice.create(passport).subscribe((res) => {
            this.toaster.success("Successfully Saved")
             this.dialogRef.close({ event: 'Closed'})
          });
        } else if (
          passport &&
          passport.id &&
          passport.country != '' &&
          passport.expiryDate != '' &&
          passport.passportNumber != undefined 
         // passport.passportb != ''
        ) {
          passport.userId = this.data;
          this.passportservice.update(passport).subscribe((res) => {
            this.toaster.success("Successfully Saved")
               this.dialogRef.close({ event: 'Closed'})
          });
        }
      }

      for (let i in this.removedpassport) {
        this.passportservice.delete(this.removedpassport[i]);
      }

      //this.Personalsaved = true
    
    }
  }
}
