<div class="header">
    <div class="main_header ">
        <img class="logo-imgs" src="/assets/BA_Logo_Icon.png" alt="" />
        <div class="pulse ml-2 ">Blue Pulse</div>
    </div>
</div>

<div>
    <div class="img-container">
        <img src="assets\session_timeout.png" alt="" class="error-img">
    </div>

    <div class="warning-text">
        <h1>Your session has expired.</h1>
        <p class="err-para">Please re-login</p>
        <a href="/login"><button class="login">
                Back to Login</button></a>

    </div>
    <!-- <button class="home" routerLink="/home">Back to home</button> -->
</div>