
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { EmergencycontactService } from 'src/app/services/emergency-contact/emergencycontact.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { EmergencyContact1Component } from '../emergency-contact1/emergency-contact1.component';

@Component({
  selector: 'app-emergency-contact2',
  templateUrl: './emergency-contact2.component.html',
  styleUrls: ['./emergency-contact2.component.scss']
})
export class EmergencyContact2Component implements OnInit {
  emergencycontacttwo = new EmergencyContact();
  emergencyContactTwo: FormGroup;
  userDto = new UserDTO();
  constructor(public fb: FormBuilder,
    public toaster:ToastrService,
    public userDetailService: UserDetailsService,
    public userService: UserService,public dialogRef: MatDialogRef<EmergencyContact2Component>,
     public emergencycontactservice: EmergencycontactService,
     @Inject(MAT_DIALOG_DATA) public data: any) { 
  
    this.emergencyContactTwo = this.fb.group({
      
      name: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z ]*')]
      )],
      relationship: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z -]*')]
      )],
      location: ['', [Validators.required]],
      phoneNumber: ['', [
        Validators.required,
        Validators.pattern(/^[\d\s+]+$/),
        Validators.maxLength(25)
      ]]

    });
   }


    
  ngOnInit(): void {
    //this.data contains userid
    // this.userDto = this.userDetailService.getUserDetails();
    // let userid = this.userDetailService.getUserDetails().id;
    this.emergencycontactservice
    .getemerbyuserId('Emergencycontacttwo', this.data)
    .subscribe((res) => {
      this.emergencycontacttwo = res.body;
      this.emergencyContactTwo.patchValue(this.emergencycontacttwo);
    });
  }
  closeDialog( event:Event) {
    this.dialogRef.close({ event: 'Closed'})
  }
  checkError = (controlName:string,errorName:string) =>{
    return this.emergencyContactTwo.controls[controlName].hasError(errorName)
  }
  onSubmit()
  {
    if(this.emergencyContactTwo.valid){
      let emergencyContacttwo = new EmergencyContact();
     
      emergencyContacttwo.emergencyContactType = 'Emergencycontacttwo';
      emergencyContacttwo.location =
        this.emergencyContactTwo.get('location').value;
      emergencyContacttwo.name = this.emergencyContactTwo.get('name').value;
      emergencyContacttwo.phoneNumber =
        this.emergencyContactTwo.get('phoneNumber').value;
      emergencyContacttwo.relationship =
        this.emergencyContactTwo.get('relationship').value;
      emergencyContacttwo.userId = this.data;
      if (this.emergencycontacttwo.id != null) {
        if (
          this.emergencycontacttwo.emergencyContactType == 'Emergencycontacttwo'
        ) {
          emergencyContacttwo.id = this.emergencycontacttwo.id;
          this.emergencycontactservice
            .putdocument(emergencyContacttwo)
            .subscribe((res) => {
              this.toaster.success("Successfully Saved")
              this.dialogRef.close({ event: 'Closed'})
              //  this.notificationService.sucess("User Data Edited")
            });
        }
      } else {
        this.emergencycontactservice
          .postdocument(emergencyContacttwo)
          .subscribe((res) => {
            this.toaster.success("Successfully Saved")
            this.dialogRef.close({ event: 'Closed'})
            //  this.notificationService.sucess("User Data Edited")
          });
      }
    }
    
    
}
}
