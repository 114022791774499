import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BulkImportHolidaysComponent } from './bulk-import-holidays/bulk-import-holidays.component';
import { AddholidaysComponent } from './addholidays/addholidays.component';
import { BluealtairHolidaysComponent } from './bluealtair-holidays/bluealtair-holidays.component';
import { ClientHolidaysComponent } from './client-holidays/client-holidays.component';
@Component({
  selector: 'app-configure',
  templateUrl: './configure.component.html',
  styleUrls: ['./configure.component.scss']
})
export class ConfigureComponent implements OnInit {
  @ViewChild('baHoliday') baHoliday : BluealtairHolidaysComponent;
  @ViewChild('clientHoliday') clientHoliday : ClientHolidaysComponent;

  constructor(private dialog: MatDialog,) { }
  matTabsClickedIndex = 0;
  ngOnInit(): void {
  }

 
  bulkImportDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.height = "75%";

    dialogConfig.panelClass="bulk-holidays";
    const dialogRef = this.dialog.open(BulkImportHolidaysComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => { 
    });
  }

  addNewHoliday(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "50%";
    const dialogRef = this.dialog.open(AddholidaysComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => { 
      this.baHoliday.onLeaveTypeChange();
      this.clientHoliday.onClientYearChange();
    });
  }
 
  onTabChanged($event) {
    this.matTabsClickedIndex = $event.index;
  }
}
