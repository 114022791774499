<div class="main_header_div">
  <h3 mat-dialog-title>Applying for Restricted Holiday </h3>
  <div class="child_close_btn">
    <button class="closeButton" (click)="closeDialog()"><mat-icon>close</mat-icon></button>
  </div>
</div>
<hr />

<div class="row main-desc-div">
  <div class="col-md-5 desc-sub-div">Applying For: {{this.data.holidayDescription}}</div>
  <div class="col-md-5 desc-sub-div">Leave Balance: {{restrictedHolidays}}</div>
  <div class="col-md-5 desc-sub-div">To: <p class="blue">{{careerManagerEmailId}}</p>
  </div>
  <div class="col-md-5 desc-sub-div">Date: {{this.data.holidayDate | date}}</div>
  <div class="col-md-11 ">
    <textarea matInput [(ngModel)]="myRemark" type="text" class="remark-input" placeholder="Remark"></textarea>
  </div>

  <div class="submit_button_main " *ngIf="this.restrictedHolidays>0">
    <button class="add_Emp_btn" type="submit" (click)="onSubmit()">
      <span>Submit</span>
    </button>
  </div>
  <div *ngIf="this.restrictedHolidays<=0">
    <p>Not enough leave balance.</p>
  </div>
</div>