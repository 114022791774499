import { Component, OnInit } from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';

@Component({
  selector: 'app-configure-menu',
  templateUrl: './configure-menu.component.html',
  styleUrls: ['./configure-menu.component.scss']
})
export class ConfigureMenuComponent implements OnInit {

  selectedOption: string;
  constructor() { }

  ngOnInit(): void {
    this.selectedOption='optionPerformance';
  }

  onTabChanged($event) {
    // this.matTabsClickedIndex = $event.index;
  }
  

toggleOption(option: string) {
  this.selectedOption = option;
}
}
