import { Component, OnInit, ViewChild } from '@angular/core';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { Departments, JobTitles, ProbationPeriod } from 'src/app/services/user/user-enum';
import { User } from 'src/app/models/user';
import { ActivatedRoute } from '@angular/router';
import { ProfileEmployeeComponent } from '../profile-employee.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PositionDetailsComponent } from './position-details/position-details.component';
import { CapabilityDetailsComponent } from './capability-details/capability-details.component';
import { AddEmployeeCardComponent } from '../../employee/add-employee-card/add-employee-card.component';
@Component({
  selector: 'app-tenure',
  templateUrl: './tenure.component.html',
  styleUrls: ['./tenure.component.scss']
})
export class TenureComponent implements OnInit {
  userid: string;
  userDTO: UserDTO[] = [];
  positionHistory = [];
  capabilityDetails = [];
  joiningDate: Date;
  confirmationDate: Date;
  jobTitle: any;
  probationPeriod: any;
  department: any;
  officeLocation: any;
  baseLocation: any;
  @ViewChild(ProfileEmployeeComponent) profileEmployeeComponent: ProfileEmployeeComponent;
  hasEmployeeManagementRights: boolean = false;

  constructor(
    public userDetailService: UserDetailsService,

    public userService: UserService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // this.userid = this.userDetailService.getUserDetails().id;

    this.hasEmployeeManagementRights = this.userDetailService.hasRole(['ROLE_SUPER_ADMIN', 'ROLE_HUMAN_RESOURCE']);

    this.userid = this.route.snapshot.paramMap.get('id');

    if (this.userid == null) {
      this.userid = this.userDetailService.getUserDetails().id;
    }
    this.setPostionHistory();
    this.setcapabilityHistory();
    this.getUserData(this.userid);
    this.department = Departments
    this.jobTitle = JobTitles
    this.probationPeriod = ProbationPeriod
  }
  setcapabilityHistory() {
    this.userService.getcapabilityDetails(this.userid).subscribe(res => {

      for (let i of res.body.capabilityDetails) {
        this.capabilityDetails.push(i);
      }
    })
  }
  setPostionHistory() {
    this.userService.getpositionHistroy(this.userid).subscribe(res => {
      for (let i of res.body.positionHistory) {
        this.positionHistory.push(i);
      }
    })
  }
  getUserData(userid) {
    this.userService.getUserByID(userid).subscribe(res => {
      this.userDTO = res.body;

    })

  }
  editCurrentEmployment() {
    this.getUserData(this.userid);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "98%";
    dialogConfig.data = this.userDTO;

    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getUserData(this.userid);
      
    }, () => {
      
    });

  }
  editPositionDetails() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'position-details';
    dialogConfig.data = this.userDTO;
    const dialogRef = this.dialog
      .open(PositionDetailsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == 'success') {
          //this.ngOnInit();
          this.positionHistory.length = 0;
          this.setPostionHistory();
        }
      });
  }
  editCapabilityDetails() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'capability-details';

    const dialogRef = this.dialog
      .open(CapabilityDetailsComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.event == 'success') {
          //this.ngOnInit();
          this.capabilityDetails.length = 0;
          this.setcapabilityHistory();
        }
      });
  }
}


