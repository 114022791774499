import { Injectable } from '@angular/core';
import { AbsentURLsService } from '../restAPI/absentURLs/absent-urls.service';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class AbsentService {

  constructor(private restApiService: RestAPIService,
    private status : AbsentURLsService) { }

    
    getstatusbyuser(attendanceStatus){
      return this.restApiService.get(
        this.status.getAttendanceStatus(attendanceStatus)
      )
    }
}