<div class="row no-goal-div" *ngIf="this.goalCount==0">
    <div class="col-md-12">
        <div class="performance_status_year col-lg-4 col-md-6 col-sm-10" *ngIf="!this.yearcheck">
            <div class="status-lbl">
                <span>Performance </span>
            </div>
            <div class="fff">
                <label class="reportee-common">Year</label>
                <div>
                    <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
                        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>

    <div class *ngIf="this.yearcheck">
    </div>
    <div class="no-goal ">
        <img class="no-goal-img" src="\assets\no-goal.png" alt="">
        <div class="no-goal-assign" *ngIf="!this.useridcheck">
            No goal is assigned to you..
        </div>
        <div class="no-goal-assign" *ngIf="this.useridcheck">
            <button class="assign-goal-btn" mat-raised-button (click)="openAssignGoalDialogue()">
                <span>Assign a Goal</span>
            </button>
        </div>
    </div>
</div>
<div *ngIf="this.goalCount>0">
    <div class="performance_status_year" *ngIf="!this.yearcheck">
        <div class="status-lbl">
            <span>Perfomance </span>
        </div>
        <div class="fff">
            <label class="reportee-common">Year </label>
            <div>
                <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
                    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</div>


<div class="row " *ngIf="this.goalCount>0">

    <div class="col-lg-4 col-md-6 assign-goal-m">

        <!-- <div class="" style="height: 43px;" *ngIf="this.yearcheck">
        </div> -->
        <div class="main-card  first">
            <mat-card class="sub-card status-card" *ngIf="this.subgoalcount>0">
                <div class=""></div>
                <div class="half1">
                    <div class="status-lbl">
                        <span>Status Report</span>
                    </div>

                    <div class="pie-chart">
                        <ngx-charts-pie-chart [view]="view" [results]="[
                        { name: 'At Risk', value: (this.tempGoalStatus[2]/this.subgoalcount)*100 },
                        { name: 'Need Attention', value: (this.tempGoalStatus[3]/this.subgoalcount)*100 },
                        { name: 'Not Started', value: (this.tempGoalStatus[1]/this.subgoalcount)*100 },
                        { name: 'On Track', value: (this.tempGoalStatus[0]/this.subgoalcount)*100 },
                        { name: 'Closed', value: (this.tempGoalStatus[4]/this.subgoalcount)*100 }
                      ]" [doughnut]="true" [scheme]="colorScheme">
                        </ngx-charts-pie-chart>
                    </div>
                    <div> <label class="performance_year">{{ subgoalcount}}</label></div>


                </div>
                <div class="bar-div goal-status">

                    <div class="status-progress ">
                        <div class="risk-bar">
                            <div>
                                <mat-progress-bar class="risk-progressbar" mode="determinate"
                                    [value]="(this.tempGoalStatus[2]/this.subgoalcount)*100">
                                </mat-progress-bar>
                            </div>
                            <div class="risk-lbl">
                                <span> At Risk</span>
                            </div>
                        </div>
                        <div class="need-attention-bar">
                            <div>
                                <mat-progress-bar class="need-attention-progressbar" mode="determinate"
                                    [value]="(this.tempGoalStatus[3]/this.subgoalcount)*100">
                                </mat-progress-bar>
                            </div>
                            <div class="need-attention-lbl">
                                <span> Need Attention</span>
                            </div>
                        </div>
                        <div class="not-started-bar">
                            <div>
                                <mat-progress-bar class="not-started-progressbar" mode="determinate"
                                    [value]="(this.tempGoalStatus[1]/this.subgoalcount)*100">
                                </mat-progress-bar>
                            </div>
                            <div class="not-started-lbl">
                                <span> Not Started</span>
                            </div>
                        </div>
                        <div class="on-track-bar">
                            <div>
                                <mat-progress-bar class="on-track-progressbar" mode="determinate"
                                    [value]="(this.tempGoalStatus[0]/this.subgoalcount)*100">
                                </mat-progress-bar>
                            </div>
                            <div class="on-track-lbl">
                                <span>On Track</span>
                            </div>
                        </div>
                        <div class="close-status-bar">
                            <div>
                                <mat-progress-bar class="status-progressbar" mode="determinate"
                                    [value]="(this.tempGoalStatus[4]/this.subgoalcount)*100">
                                </mat-progress-bar>
                            </div>
                            <div class="closed-lbl">
                                <span> Closed</span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card *ngIf="this.subgoalcount==0" class="sub-card sub-goal status-card">
                <p class="no-subgoal-lbl">
                    Think about the results you want to see. Start creating your sub goals.
                </p>
            </mat-card>
        </div>
    </div>

    <div class="col-lg-2 col-md-3 half mt-2 assign-goal-card">

        <mat-card class="main-card2 second1">

            <div class="assign_goal">
                <div class="assign_goal_icon">
                    <img class="goal_logo" src="assets/goal_logo.png">
                </div>
            </div>

            <div class="add_Emp_btn">
                <button class="add_Emp_btn" mat-raised-button (click)="openAssignGoalDialogue()">
                    <span class="add_em_name ">Assign a Goal</span>
                </button>
            </div>

        </mat-card>
    </div>
    <div class="col-lg-2 col-md-3  half mt-2 assign-goal-card" *ngFor="let goal of this.goal;let i=index">
        <mat-card class="main-card2 second1">
            <div class="sub-card ">
                <div class="goal-name mt-4">
                    <div class="goal-icon-div">
                        <mat-icon>
                            description
                        </mat-icon>
                    </div>
                    <div class="goal-name-lbl ml-2">
                        <span>{{goalTypeMapping[goal.goalType]}}</span>
                    </div>
                </div>
                <div class="mt-3 goal-owner-name">
                    <span>{{userIdName[goal.userId]}}</span>
                </div>
                <div class="mt-3 goal-owner-name">
                    <span class="sub-goal-count">Subgoal Count</span>
                    <span>{{this.subgoalLength[goal.id]}}</span>
                </div>
            </div>

            <div class="add_Emp_btn manage_sub_goals" *ngIf="this.assignCheck">
                <button class="add_Emp_btn" (click)="managesubgoals(goal)">
                    <span class="add_em_name ">Manage Sub Goals</span>
                </button>
            </div>
            <div class="add_Emp_btn manage_sub_goals" *ngIf="!this.assignCheck">
                <button class="add_Emp_btn" (click)="viewSubgoals(goal)">
                    <span class="add_em_name ">View Sub Goals</span>
                </button>
            </div>

        </mat-card>
    </div>
</div>