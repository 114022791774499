import { Component, OnInit } from '@angular/core';
import { ClientHolidayService } from 'src/app/services/clientHoliday/client-holiday.service';
import { ClientService } from 'src/app/services/client/client.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditHolidayComponent } from '../edit-holiday/edit-holiday.component';
import { AddHolidayService } from 'src/app/services/addHoliday/add-holiday.service';
import { AddholidaysComponent } from '../addholidays/addholidays.component';



@Component({
  selector: 'app-bluealtair-holidays',
  templateUrl: './bluealtair-holidays.component.html',
  styleUrls: ['./bluealtair-holidays.component.scss']
})
export class BluealtairHolidaysComponent implements OnInit {

  years: number[] = [];
  LeaveType = [];
  selectedYear: number = new Date().getFullYear();
  selectedLeaveType: string = "ALL";
  totalHoliday: any = [];
  daysOfWeek: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


  constructor(public clientHolidaysService: ClientHolidayService,
    public client: ClientService, private dialog: MatDialog,
    public addHoliday: AddHolidayService) { }

  ngOnInit(): void {
    this.onLeaveTypeChange();
    this.selectedYear = new Date().getFullYear();
    for (let year = 2030; year >= 2015; year--) {
      this.years.push(year);
    }
    this.LeaveType = ['ALL', 'GENERAL_HOLIDAY', 'RESTRICTED_HOLIDAY'];

  }


  // getClients() {
  //   this.client.getClientsSubscribe().subscribe((res) => {
  //     const details = res.body

  //   })
  // }

  onLeaveTypeChange() {
    // console.log(this.selectedLeaveType)
    if (this.selectedLeaveType == 'GENERAL_HOLIDAY' || this.selectedLeaveType == 'RESTRICTED_HOLIDAY') {
      this.onClientYearChange();
    } else {
      // console.log("hello");
      this.getBothHolidays();
    }
  }
  editHoliday(holiday) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "50%";
    dialogConfig.data = {
      existingHoliday: holiday
    };

    const dialogRef = this.dialog.open(EditHolidayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.onLeaveTypeChange();
    })
  }

  onClientYearChange() {
    this.totalHoliday = [];
    this.clientHolidaysService.getHolidaysByIdandYear('BA', this.selectedYear, this.selectedLeaveType).subscribe((res) => {
      for (let i = 0; i < res.body.length; i++) {
        // console.log(res.body[i]);

        const today: Date = new Date(res.body[i].date);
        const daysOfWeek: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayOfWeek: string = daysOfWeek[today.getDay()];

        const currentLeave = {
          date: res.body[i].date,
          leaveDescription: res.body[i].leaveDescription,
          day: dayOfWeek,
          selectedLeaveType: this.selectedLeaveType,
          clientId: 'BA',
          id: res.body[i].id,
        };

        //console.log(currentLeave);

        this.totalHoliday.push(currentLeave);
      }
      console.log(this.totalHoliday)
    },
      (e) => {
        console.log("No Holidays")
      }

    );
  }

  getBothHolidays() {
    this.totalHoliday = [];
    this.clientHolidaysService.getBothHolidaysbyyear(this.selectedYear).subscribe((res) => {
      for (let i = 0; i < res.body.length; i++) {
        // console.log(res.body[i]);

        const today: Date = new Date(res.body[i].date);
        const daysOfWeek: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayOfWeek: string = daysOfWeek[today.getDay()];

        const currentLeave = {
          date: res.body[i].date,
          leaveDescription: res.body[i].leaveDescription,
          day: dayOfWeek,
          selectedLeaveType: res.body[i].calendarLeaveType,
          clientId: 'BA',
          id: res.body[i].id,
        };

        //console.log(currentLeave);

        this.totalHoliday.push(currentLeave);
      }
    },
      (e) => {
        console.log("No Holidays")
      })
  }


}
