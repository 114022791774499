
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressDetailsService } from 'src/app/services/address-details/address-details.service';
import { AddressDetailsDTO } from 'src/app/dtos/AddressDetailsDTO';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { EmergencycontactService } from 'src/app/services/emergency-contact/emergencycontact.service';
import { UserDTO } from 'src/app/dtos/UserDTO';
import {
  BloodGroup,
  Relation,
  RelationshipStatus,
} from 'src/app/services/user/user-enum';
import { Family } from 'src/app/models/familydetails';
import { FamilyDetailsService } from 'src/app/services/familyDetails/family-details.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-current-address',
  templateUrl: './current-address.component.html',
  styleUrls: ['./current-address.component.scss']
})
export class CurrentAddressComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  editCorrespondenceDetails: FormGroup;
  userDto = new UserDTO();
  addresscurr = new AddressDetailsDTO();
  address = new AddressDetailsDTO();
  constructor(public dialogRef: MatDialogRef<CurrentAddressComponent>,
    public fb: FormBuilder,
    public toaster:ToastrService,
   
    public userDetailService: UserDetailsService,
    public userService: UserService,
    public addressdetailservice: AddressDetailsService,
    public emergencycontactservice: EmergencycontactService,
    public familyservice: FamilyDetailsService,

    @Inject(MAT_DIALOG_DATA) public data: any) {
      
    this.editCorrespondenceDetails = this.fb.group({
      addressLineOne: ['', [Validators.required]],
      addressLineTwo: [''],
      country: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('^[a-zA-Z\\s]*$')]
      )],
      city: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('^[a-zA-Z\\s]*$')]
      )],
      state: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('^[a-zA-Z\\s]*$')]
      )],
      pincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{6}$'),
        ]),
      ],
    });
     }

  ngOnInit(): void {
    // this.userDto = this.userDetailService.getUserDetails();
    // let userid = this.userDetailService.getUserDetails().id;
    //this.data contains userid

    this.addressdetailservice
    .getaddressbyuserId('CURRENT', this.data)
    .subscribe((res) => {
      this.addresscurr = res.body;
      this.editCorrespondenceDetails.patchValue(this.addresscurr);
    });
  }
  
  closeDialog(event:Event) {
    this.dialogRef.close({ event: 'Closed'})
  }
  checkError = (controlName: string, errorName: string) => {
    return this.editCorrespondenceDetails.controls[controlName].hasError(
      errorName
    );
  };
  onSubmit()
  {
      if(this.editCorrespondenceDetails.valid){
        let currentAddressDto = new AddressDetailsDTO();
        currentAddressDto.addressLineOne =
          this.editCorrespondenceDetails.get('addressLineOne').value;
        currentAddressDto.addressLineTwo =
          this.editCorrespondenceDetails.get('addressLineTwo').value;
        currentAddressDto.city = this.editCorrespondenceDetails.get('city').value;
        currentAddressDto.pincode =
          this.editCorrespondenceDetails.get('pincode').value;
        currentAddressDto.state =
          this.editCorrespondenceDetails.get('state').value;
        currentAddressDto.country =
          this.editCorrespondenceDetails.get('country').value;
        currentAddressDto.addressCategory = 'CURRENT';
        // currentAddressDto.userId = this.userDetailService.getUserDetails().id;
        currentAddressDto.userId = this.data;
        
        if (this.addresscurr.id != null) {
          if (this.addresscurr.addressCategory == 'CURRENT') {
            currentAddressDto.id = this.addresscurr.id;
            this.addressdetailservice
              .putdocument(currentAddressDto)
              .subscribe((res) => {
                this.toaster.success("Successfully Saved")
                this.dialogRef.close({ event: 'Closed'})
                //  this.notificationService.sucess("User Data Edited")
              });
          }
        } else {
          this.addressdetailservice
            .postdocument(currentAddressDto)
            .subscribe((res) => {
              this.toaster.success("Successfully Saved")
              this.dialogRef.close({ event: 'Closed'})
              //  this.notificationService.sucess("User Data Edited")
            });
        }
      }
    
}
}
