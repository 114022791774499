import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BulkHolidaysImportService } from 'src/app/services/bulkImport/bulk-holidays-import.service';
import { BulkImportSummaryComponent } from '../../bulk-employee-import/bulk-import-summary/bulk-import-summary.component';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { HolidayDTO } from 'src/app/dtos/HolidayDTO';
import * as XLSX from 'xlsx';
import { utils, write, WorkBook, Sheet } from 'xlsx';
import { BulkHolidaysSummaryComponent } from './bulk-holidays-summary/bulk-holidays-summary.component';
import { error, table } from 'console';
import { retryWhen } from 'rxjs/operators';


// interface HolidayData {
//   Date: Date;
//   HolidayType: string;
//   ClientID: string;
//   Description: string;
// }
// variables to activate particular div in html
export class BooleanFlags {
  flag1: boolean; // import page
  flag11: boolean;// in import page initial
  flag12: boolean;// in import page error
  flag2: boolean; // formarray page
  flag21: boolean;  // are you sure page
  flag22: boolean;  // Summary page

  constructor() {
    this.flag1 = true;
    this.flag11 = true;
    this.flag12 = false;
    this.flag2 = false;
    this.flag21 = false;
    this.flag22 = false;
  }

  setFlag(f1: boolean, f11: boolean, f12: boolean, f2: boolean, f21: boolean, f22: boolean) {
    this.flag1 = f1;
    this.flag11 = f11;
    this.flag12 = f12;
    this.flag2 = f2;
    this.flag21 = f21;
    this.flag22 = f22;
  }
  // setOneFlag(flagString: string): void {
  //   this.flag1 = false;
  //   this.flag2 = false;
  //   this.flag3 = false;
  //   this.flag4 = false;

  //   if (flagString == "flag1") this.flag1 = true;
  //   else if (flagString == "flag2") this.flag2 = true;
  //   else if (flagString == "flag3") this.flag3 = true;
  //   else if (flagString == "flag4") this.flag4 = true;
  // }
}
@Component({
  selector: 'app-bulk-import-holidays',
  templateUrl: './bulk-import-holidays.component.html',
  styleUrls: ['./bulk-import-holidays.component.scss']
})
export class BulkImportHolidaysComponent implements OnInit {

  // variables to activate particular div in html
  public flags: BooleanFlags;
  public fileName = "";
  public clients: string[] = [];
  public holidaysType: any = ['GENERAL_HOLIDAY', 'RESTRICTED_HOLIDAY', 'CLIENT_HOLIDAY'];
  public holidays: HolidayDTO[] = [];
  public uniqueMonthYearArray: Date[] = []; // for storing only one date of unique month
  public trackMonthYearDate = 0;
  public monthYearDate = new Date();
  public finalExcelFile: Blob;
  public holidayYear: Number;
  public myExcel: FormGroup;
  public holidayFormArray: FormArray;
  public excelErrorText = "";
  public totalHolidaysInExcel: number;
  public notSelectedHolidays=0;

  public xlFile: File;
  public response: any;
  public allfileContent: any;
  public badFileFormat: boolean;
  public displayStyle = "none";
  public confirmImportFlag: boolean;
  public uploadBtnLabel = "Select File";
  public hidebutton = true;
  public loading = false;


  constructor(

    public fb: FormBuilder,
    public bulkService: BulkHolidaysImportService,
    private dialogref: MatDialogRef<BulkImportSummaryComponent>,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }


  ngOnInit(): void {
    this.myExcel = this.fb.group({
      clientSelected: ['', Validators.required],
      monthYear: ['', Validators.required],
      holidaysForm: this.fb.array([])
    });
    this.holidayFormArray = this.myExcel.get('holidaysForm') as FormArray;


    this.flags = new BooleanFlags();
  }

  createHoliday(holiday: HolidayDTO): FormGroup {
    const holidayDetailsValue = holiday.description + ', ' + holiday.date.getDate();
    return this.fb.group({
      isChecked: [true],
      holidayDetails: [holidayDetailsValue, Validators.required],

      holidayType: [holiday.holidayType, Validators.required],
      date: [holiday.date, Validators.required],
      clientID: [holiday.clientID, Validators.required],
      description: [holiday.description, Validators.required],
    });

    // let formGroup = this.fb.group(new HolidayDTO());
    // formGroup.controls.description.setValidators([null, Validators.required]);
    // formGroup.controls.holidaysType.setValidators([null, Validators.required]);
    // // formGroup.controls.holidayDate.setValidators([Validators.required]);
    // return formGroup;
  }
  setMyExcelForm() {

    for (let holiday of this.holidays) {
      this.holidayFormArray.push(this.createHoliday(holiday));

      console.log("group pushed:" + this.fb.group.toString);
    }
  }
  changeMonthYear(num: number) {
    console.log("changeMonthYear" + num);

    if (this.trackMonthYearDate + num == this.uniqueMonthYearArray.length) { this.trackMonthYearDate = 0; }
    else if (this.trackMonthYearDate + num < 0) { this.trackMonthYearDate = this.uniqueMonthYearArray.length - 1; }
    else { this.trackMonthYearDate += num; }

    this.monthYearDate = this.uniqueMonthYearArray[this.trackMonthYearDate];
    // this.cdr.detectChanges();
    console.log(this.trackMonthYearDate);


  }
  getMonthName(monthIndex: number): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthIndex];
  }
  changeDateFormate(date: Date) {
    // const date = new Date('2024-03-12');
    const day = date.getDate();
    const month = this.getMonthName(date.getMonth());
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    console.log(formattedDate);
    return formattedDate;

  }
  onFileChange(event) {

    this.excelErrorText = ""; // to reset when comes from wrong excel upload
    this.fileName = this.fileName.split('\\').pop(); // Extract file name as this.fileName is declaired in [(ngModel)]="this.fileName"
    this.fileName = this.fileName ? this.fileName : '';
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const holidayData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      // const date = XLSX.SSF.format('yyyy-mm-dd', new Date((json[2][0] - (25567+2)) * 86400 * 1000));
      //add excel data to holidays and add all the clients
      for (let i = 1; i < holidayData.length; i++) {
        let holiday = new HolidayDTO(); // = { Date: null, HolidayType: null, ClientID: null, Description: null };

        const row = holidayData[i];
        if (typeof row[0] === 'number') {
          holiday.date = new Date((row[0] - (25567 + 2)) * 86400 * 1000);
        } else if (typeof row[0] === 'string') {
          holiday.date = new Date(row[0]);
        }

        holiday.clientID = row[1];
        holiday.holidayType = (holiday.clientID == 'BA') ? row[2] : 'CLIENT_HOLIDAY';
        // holiday.holidayType = row[2];
        holiday.description = row[3];

        // checking error in data
        if (isNaN(holiday.date.getTime())) {
          this.excelErrorText = "Error in Date in Row " + (i + 1);
        }
        else if (!holiday.clientID) {
          this.excelErrorText = "Error in ClientID in Row " + (i + 1);
        }
        else if (!this.holidaysType.includes(holiday.holidayType)) {
          this.excelErrorText = "Error in LeaveType in Row " + (i + 1);
        }
        else if (!holiday.description) {
          this.excelErrorText = "Error in Description in Row " + (i + 1);
        }
        if (this.excelErrorText) { // clearing excel data
          console.log("this.excelErrorText" + this.excelErrorText);

          this.flags.setFlag(true, false, true, false, false, false);
          this.fileName = "";
          this.holidays = [];
          return;
        }
        this.holidays.push(holiday);




      };
      // console.table(this.holidays);

      // this.clients.shift();// to remove previous value created in contructor
      this.totalHolidaysInExcel=this.holidays.length;
      console.table(this.holidays);
      this.processHolidays();

      console.table(this.holidays);
      this.changeMonthYear(0);

      // this.myExcel.get('monthYear').setValue(this.monthYearDate);
      this.myExcel.get('clientSelected').setValue(this.clients[0]);


      this.setMyExcelForm();
      this.flags.setFlag(false, false, false, true, true, false); // showing form group
      // console.log("flag2: " + JSON.stringify(this.flags));


      console.log(this.myExcel.value);

      //segregate according to clients
      // for (const holiday of this.holidays) {
      //   if (holiday.ClientID) {
      //     if (this.holidaysByClient[holiday.ClientID]) {
      //       this.holidaysByClient[holiday.ClientID].push(holiday);
      //     } else {
      //       this.holidaysByClient[holiday.ClientID] = [holiday];
      //     }
      //   }
      // }
      // // Print out the holidays for each client
      // for (const clientId in this.holidaysByClient) {
      //   console.table(this.holidaysByClient[clientId]);
      // }

    };
    reader.readAsArrayBuffer(file);
  }
  processHolidays() {
    this.holidays.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    this.holidayYear = this.holidays[0].date.getFullYear();// selecting year of smallest date of excel , and stopping next year data
    this.holidays = this.holidays.filter(holiday => holiday.date.getFullYear() === this.holidayYear);

    let accumulator = [];
    for (const holiday of this.holidays) {

      //getting unique clients
      if (this.clients.indexOf(holiday.clientID) == -1) {
        this.clients.push(holiday.clientID);
      }

      const monthYear1 = holiday.date.getMonth() + '-' + holiday.date.getFullYear();

      if (!accumulator.includes(monthYear1)) { // we can also break if next year touch
        accumulator.push(monthYear1);
        this.uniqueMonthYearArray.push(holiday.date);// saving any date of unique month year
      }
    }
  }


  HolidayFormField(): FormArray {//will be used to delete particular field
    return this.myExcel.get('holidaysForm') as FormArray;
  }


  //On clicking upload button, create form data with the selected xl file, and call the service to trigger the API
  convertFormtoExcel() {

    const holidaysDataForExcel: any[] = [];


    console.log("finalexcel data");
    for (let holidayField of this.myExcel.value.holidaysForm) {
      if (holidayField.isChecked) { // put checkbox condition

        const holidayData = {
          Date: this.changeDateFormate(holidayField.date),
          ClientID: holidayField.clientID,
          LeaveType: holidayField.holidayType,
          Desc: holidayField.description
        };
        console.log(holidayData);

        holidaysDataForExcel.push(holidayData);
      }
      else{
        // this.totalHolidaysInExcel--;
        this.notSelectedHolidays++;
      }
    }

    // this.saveFormArrayToExcel2(holidaysDataForExcel, this.fileName);
    this.finalExcelFile = this.generateExcelFile(holidaysDataForExcel);
    console.table(this.finalExcelFile);
    // this.saveAsExcelFile( this.finalExcelFile,this.fileName); //to download excel
    this.uploadFile();
  }
  generateExcelFile(formArrayData: any[]): Blob {
    const worksheet = utils.json_to_sheet(formArrayData);
    const workbook: WorkBook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Template');
    const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/octet-stream' });
  }

  // saveFormArrayToExcel2(formArrayData: any[], filename: string) {
  //   const worksheet = XLSX.utils.json_to_sheet(formArrayData);
  //   const workbook = utils.book_new();
  //   utils.book_append_sheet(workbook, worksheet, 'Template');
  //   const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
  //   this.saveAsExcelFile(excelBuffer, filename);
  // }


  saveAsExcelFile(buffer: any, filename: string) { // to download excel
    const data = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  uploadFile() {

    // this.confirmImportFlag = false;
    const formData = new FormData();
    formData.append("file", this.finalExcelFile);

    this.bulkService.uploadBulkHolidays(formData, this.holidayYear).subscribe(

      data => {
        console.log("inside log", data);

        let result = data.body.message;
        console.log("data.body.message" + data.body.message);
        //data.body.message{"excelDuplicates":null,"addedHolidays":0,"dbDuplicates":9,"alreadyExisitingHolidays":9}  
        this.response = JSON.parse(result);
        console.log("this.response " + JSON.stringify(this.response));


        let filecontent = {
          "excelDuplicates": '0',
          "dbDuplicates": '0',
          "alreadyExisitingHolidays": '0',
          "Successful": '0',
          "Failed": 0,
          "NotSelected": 0,
          "TotalHolidaysforThatYear": 0,

          // "HolidayYear": any

        }


        filecontent.excelDuplicates = this.response.excelDuplicates;
        filecontent.dbDuplicates = this.response.dbDuplicates;
        filecontent.alreadyExisitingHolidays = this.response.alreadyExisitingHolidays;
        filecontent.Successful = this.response.savedHolidaysFromExcel;
        filecontent.Failed = this.totalHolidaysInExcel-
          parseInt(this.response.savedHolidaysFromExcel) -
          parseInt(this.response.excelDuplicates) -
          parseInt(this.response.dbDuplicates);
        // filecontent.TotalHolidaysforThatYear = (parseInt(this.response.savedHolidaysFromExcel)+parseInt(this.response.alreadyExisitingHolidays)).toString();
        filecontent.NotSelected=this.notSelectedHolidays;
        filecontent.TotalHolidaysforThatYear = parseInt(this.response.savedHolidaysFromExcel) + parseInt(this.response.alreadyExisitingHolidays);

        // filecontent.HolidayYear = this.holidayYear;
        this.allfileContent = filecontent;
        // console.log("this.allfileContent" + JSON.stringify(this.allfileContent));



        this.openDialog();
        this.uploadBtnLabel = "Select File";
        this.fileName = "";

      },
      error => {
        //To display Bad File Format message on the screen
        this.badFileFormat = true;
        this.loading = false
        this.uploadBtnLabel = "Select File";
        this.fileName = "";
      });


    //Reset variables
    this.allfileContent = "";
    this.response = "";
    this.xlFile = null;
    this.loading = true


  }

  onHolidayChange() {

  }

  openDialog(): void {
    this.closeDialog()

    //Set Mat-dialog configuration, assign data to it and display it
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {};

    dialogConfig.data = {

      excelDuplicates: this.allfileContent.excelDuplicates,
      dbDuplicates: this.allfileContent.dbDuplicates,
      alreadyExisitingHolidaysinDB: this.allfileContent.alreadyExisitingHolidays,
      Successful: this.allfileContent.Successful,
      Failed: this.allfileContent.Failed,
      NotSelected: this.allfileContent.NotSelected,
      TotalHolidaysforThatYear: this.allfileContent.TotalHolidaysforThatYear,
      HolidayYear: this.holidayYear
      // excelDuplicates :6,
      // dbDuplicates :5,
      // alreadyExisitingHolidaysinDB :33,
      // Successful :6,
      // Failed :5,
      // TotalHolidaysforThatYear :56,
      // HolidayYear:this.holidayYear
    };

    dialogConfig.height = '60%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'bulk-employee'
    const dialogRef = this.dialog.open(BulkHolidaysSummaryComponent, dialogConfig);
  }

  openPopup() {
    this.hidebutton = false;

  }
  loadinglogo() {
    this.loading = true;
  }

  closeDialog() {
    // this.dialogref.close();
    this.dialogref.close()
  }
}
