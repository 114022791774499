import { Injectable } from '@angular/core';
import { EventInput } from '@fullcalendar/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { HolidayEventUrlService } from '../restAPI/holidayEventURLs/holiday-event-url.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { AsyncAction } from 'rxjs/internal/scheduler/AsyncAction';
import { catchError, map } from 'rxjs/operators';
import { UnmarkedAttendanceService } from '../UnmarkedAttendance/unmarked-attendance.service';
import { UserDetailsService } from '../user-details.service';




@Injectable({
  providedIn: 'root'
})
export class HolidaysEventService {


  constructor(private restApiService: RestAPIService,
    private holidayUrlService: HolidayEventUrlService,
    private userDetailService: UserDetailsService,

  ) { }

  status: string = 'UNMARKED_ATTENDANCE';
  userId = this.userDetailService.getUserDetails().id;


  getHolidayEvents(userId, year): Observable<EventInput[]> {
    return this.restApiService.get(
      // this.holidayUrlService.getHolidayEvents(year)
      this.holidayUrlService.getHolidaysByUserandYear(userId, year)
    ).pipe(
      map(res => {
        const newEvents = res.body.leaveDates;
        const events: EventInput[] = []

        for (let i = 0; i < newEvents.length; i++) {
          const event = newEvents[i];
          const formattedDate = moment(event.date).format('YYYY-MM-DD');
          let bgColor = 'black';

          if (event.calendarLeaveType == 'GENERAL_HOLIDAY') {
            bgColor = '#28A745';
          }
          else if (event.calendarLeaveType == 'RESTRICTED_HOLIDAY') {
            bgColor = '#FFA900';
          }
          // else if (event.calendarLeaveType == 'UNMARKED_ATTENDANCE') {
          //   bgColor = '#EB0000';
          // }
          // else if (event.calendarLeaveType.title == 'ON_LEAVE') {
          //   bgColor = '#0045B9';
          // }
          else if (event.calendarLeaveType == 'CLIENT_HOLIDAY') {
            bgColor = '#FAA8A9';
          }

          const currentEvent: EventInput = {
            title: '',
            start: formattedDate,
            end: formattedDate,
            backgroundColor: bgColor
          };
          events.push(currentEvent);
        }
        return events;
      })
    );
  }
  restrictedHolidays: { date: any, desc: any }[] = [];
  getRestictedHolidaysForApplyLeave(year): any {
    return this.restApiService.get(
      this.holidayUrlService.getHolidayEvents(year)
    )
  }
  getHolidayStatus(id, payload) {
    return this.restApiService.post(
      this.holidayUrlService.getHolidayStatus(id),
      payload
    )
  }

}
