import { Component, HostListener, OnInit } from '@angular/core';
import { AddAssetComponent } from '../../add-asset/add-asset/add-asset.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AssetManagementService } from 'src/app/services/asset-management/asset-management.service';
import { ViewAssetComponent } from '../../view-asset/view-asset/view-asset.component';
import { UserService } from 'src/app/services/user/user.service';
import { AssignAssetComponent } from '../../assign-asset/assign-asset/assign-asset.component';
import { RevokeAssetComponent } from '../../revoke-asset/revoke-asset/revoke-asset.component';
import { Router } from '@angular/router';
import { AssetCategories } from 'src/app/services/asset-management/asset-enum';

@Component({
  selector: 'app-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss']
})

export class AssetCardComponent implements OnInit {
  allAssetDetails;
  allEmployeeIdsWithNames = {};
  searchText: string;
  p: number = 1;
  nodata: Boolean = true
  public getScreenWidth: any;
  public getScreenHeight: any;
  mobileview: boolean = false
  assetEnumCategories: any;

  constructor(public dialog: MatDialog,
    private userService: UserService,
    private router: Router,
    private assetService: AssetManagementService) { }

  ngOnInit(): void {
this.assetEnumCategories = AssetCategories
    this.assetService.getAllAssetListing().subscribe(data => {
      if (data.body?.length != 0) {
        this.nodata = false
      }
      this.allAssetDetails = data.body;
    });

    this.userService.getAllAssetUsers().subscribe(data => {
      data.body.forEach(element => {
        this.allEmployeeIdsWithNames[element.employeeId] = element.firstName + ' ' + element.lastName;
      });
    },
      error => {
      });

      this.getScreenWidth = window.innerWidth;
      this.getScreenHeight = window.innerHeight;
      if(this.getScreenWidth>960){
        this.mobileview=false
      }
      if(this.getScreenWidth<960){
        this.mobileview=true
      }

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 960) {
      this.mobileview = false
    }
    if (this.getScreenWidth < 960) {
      this.mobileview = true
    }
  }

  viewAssetDialog(assetDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.data = assetDetail;
    const dialogRef = this.dialog.open(ViewAssetComponent, dialogConfig).afterClosed().
      subscribe(() => this.ngOnInit());
  }

  openAssignAssetDialog(assetDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { 'assetDto': assetDetail }
    const dialogRef = this.dialog.open(AssignAssetComponent, dialogConfig).afterClosed().
      subscribe(() => this.ngOnInit());
  }

  openRevokeAssetDialog(assetDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.data = { 'assetDto': assetDetail }
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RevokeAssetComponent, dialogConfig).afterClosed().
      subscribe(result => {
        //this.data = result.data;
        this.ngOnInit();
      }
      )
  }

  redirectToViewAsset(id) {
    this.router.navigate(['/viewAsset/' + id]);
  }
}
