import { Component, HostListener, OnInit } from '@angular/core';
import { EmployeeReviewService } from 'src/app/services/employeeReview/employee-review.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { OneOnOneMeetingService } from 'src/app/services/oneOnOneMeeting/one-on-one-meeting.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
@Component({
  selector: 'app-direct-reportees-cm',
  templateUrl: './direct-reportees-cm.component.html',
  styleUrls: ['./direct-reportees-cm.component.scss']
})
export class DirectReporteesCMComponent implements OnInit {
  public employeeReviewCompleted: boolean = false;
  userdto;
  probationStatus:boolean = false;
  p: number = 1;
  searchText: string;
  public getScreenWidth: any;
  public getScreenHeight: any;
  selectedYear: number;
  nodata: Boolean = true
  selected;
  hide: boolean = true;
  years: number[] = [];
  mobileview: boolean = false
  constructor(public userService: UserService,
    public userDetailService: UserDetailsService,
    public initialAvatarService: InitialAvatarService,
    public employeeReviewService: EmployeeReviewService,
    public oneOnOneMeetingService: OneOnOneMeetingService,
    public feedbackService: FeedbackService,
    public userDetailsService: UserDetailsService,
    public feedbackgroupservice: NewFeedbackGroupService,
    public userGoalService: UserGoalService,
    private router: Router,
    private feedbackgroup: NewFeedbackGroupService,
  ) {

    this.feedbackService.listen().subscribe((m: any) => {

      if (m == 'Reopened-feedback') {

        console.log(m);
        this.getMyReportee()
      }
    })
  }
  ngOnInit(): void {

    this.selected = new Date().getFullYear();
    this.selectedYear = new Date().getFullYear();
    // this.userGoalService.setCurrentYear(this.selected);
    for (let year = this.selectedYear; year >= 2015; year--) {
      this.years.push(year);
    }

    this.getMyReportee();
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 800) {
      this.mobileview = false
    }
    if (this.getScreenWidth < 800) {
      this.mobileview = true
    }

  }
  getMyReportee() {
    this.feedbackgroupservice.getdirectreporteebyyear(this.selected).subscribe(res => {

      if (res.body.length != 0) {
        this.nodata = false
      }
      this.userdto = res.body
      // console.log(res.body)
      
    });
  }
  onViewReportee(id) {
    this.feedbackService.setIndex(0);
    this.router.navigate(['profiledetail', id]);
  }
  processDesignation(user) {
    let designation;
    if (user && user.firstName)
      return designation = user.jobTitle
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 800) {
      this.mobileview = false
    }
    if (this.getScreenWidth < 800) {
      this.mobileview = true
    }
  }
  loadForm(event) {
    this.userGoalService.setCurrentYear(event.value);
    this.feedbackgroupservice.getdirectreporteebyyear(event.value).subscribe(res => {
      if (res.body.length != 0) {
        this.nodata = false
      }
      this.userdto = null
      this.userdto = res.body
    });
  }




}