
<div class="search-yearfilter-container">

    <div class="col-md-12 pl-0 pr-0">
        <mat-card class="mat_card_leave">
            <div class="search_main_leave ">
                <mat-icon class="search_icon_leave">search</mat-icon>
                <input class="search_input_leave" type="search" placeholder="Search" [(ngModel)]="searchText" value="">
            </div>
        </mat-card>
    </div>

    <div class="year-select-leaveDetails">
        <label >Year</label>
        
        <mat-select class="year-select-field" [(ngModel)]="selectedYear" (ngModelChange)="getAllLeaves()">
          <mat-option *ngFor="let year of years" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
    </div>

</div>

<ng-container class="no-leave-container" *ngIf="LeaveDetailsList.length == 0; else elseTemplate">
    <div class="no-leave no-leave-container">
        <img class="no-leave-img" src="\assets\no-leave.png" alt="">
        <div class="no-leave-assign">
            <button class="assign-leave-btn" (click)="applyNewLeave()">
                <span>Apply New Leave</span>
            </button>
        </div>
    </div>
</ng-container>





<ng-template #elseTemplate>

    <div class="card-container-lm">
        <mat-card class="matcardleavedetail"
            *ngFor="let detail of this.LeaveDetailsList  | filter:searchText | orderBy:'startDate' | paginate: {id: 'leave-detail-pagination', itemsPerPage: 9, currentPage: p } index as i">
            <mat-card-content >
                <div >
                    <div class="row first-row">
                        <div class="col">
                            <h5 class="leave-detail-name">Number of Days </h5>
                            <p class="leave-detail-content">{{detail.no_ofDays}}</p>
                        </div>
                        <div class="col">
                            <div class="leavetype-container">
                                <h5 class="leave-detail-name">Leave Type </h5>
                                <mat-icon *ngIf="this.detail.approvalStatus == 'PENDING'" ><img class="edit_logo_lm" src="assets/edit.png" (click)="editLeave(detail)"></mat-icon>
                            </div>
                            <p class="leave-detail-content">{{detail.typeOfLeave | underScoreToSpace | titlecase}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h5 class="leave-detail-name">Start Date </h5>
                            <p class="leave-detail-content">{{detail.startDate | date}}</p>
                        </div>
                        <div class="col">
                            <h5 class="leave-detail-name">End Date </h5>
                            <p class="leave-detail-content">{{detail.endDate | date}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h5 class="leave-detail-name">Status </h5>
                            <p class="leave-detail-content" [ngClass]="{'status-approved': detail.approvalStatus === 'APPROVED', 'status-rejected': detail.approvalStatus === 'REJECTED', 'status-pending': detail.approvalStatus === 'PENDING'}">{{detail.approvalStatus | underScoreToSpace | titlecase}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                          <h5 class="leave-detail-name">Reason</h5>
                          <p class="leave-detail-content reasonLeave">{{detail.reasonForLeave}}</p>
                        </div>
                      </div>
                    
                      <div class="row revoke-btn-container" *ngIf="this.detail.approvalStatus == 'PENDING' || (this.detail.approvalStatus == 'APPROVED' && isDateYetToPass(detail.startDate))">
                        <button class="revoke-btn" (click)="revokeBox(detail)">Revoke</button>
                      </div>
                      <div class="row revoke-btn-container" *ngIf="this.detail.approvalStatus != 'PENDING' && !(this.detail.approvalStatus == 'APPROVED' && isDateYetToPass(detail.startDate))">
                        <button class="revoke-btn-disabled" [disabled]="true">Revoke</button>
                      </div>
                      
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <pagination-controls class="pagination" (pageChange)="p = $event" id="leave-detail-pagination">
    </pagination-controls>

    
    <!-- <table class="table-fixed-leave">
        <thead>
            <tr class="header_lm">
                <th class="emp-title-leave font_lm"></th>
                <th class="emp-title-leave font_lm">Leave Type</th>
                <th class="emp-title-leave font_lm">Start Date</th>
                <th class="emp-title-leave font_lm">End date</th>
                <th class="emp-title-leave font_lm">Number of Days</th>
            </tr>
        </thead>
        <tbody class="no-more-tables-lm">
            <tr *ngFor="let detail of details | filter:searchText | paginate:{ id:'employee_id' ,itemsPerPage: 8, currentPage: p }" class="leave-details-container" data-title="Employee">
                <td class="NameandId">
                    <div class="row nam-id-inline">
                        <div *ngIf="detail.img === null">
                            <img class="noemployee_img_leave" src="../../../../assets/default-avatar.jpg" alt="">
                        </div>
                        <div *ngIf="detail.img !== null">
                            <img class="employee_img_leave" [(src)]="detail.img" alt="">
                        </div>
                        <div class=" col emp-nameblock-leave">
                            <label class="font_lm">{{detail.name}} </label>
                            <br>
                            <label class="idemp-leave font_lm">ID {{detail.id}}</label>
                        </div>
                    </div>
                </td>
                <td data-title="Leave Type">
                    <label class="font_lm td_items_lm"> {{detail.leaveType}}</label>
                </td>
                <td data-title="Start Date">
                    <label class="font_lm td_items_lm">{{detail.startDate}}</label>
                </td>
                <td data-title="End Date">
                    <label class="font_lm td_items_lm">{{detail.endDate}}</label>
                </td>
                <td data-title="No. Of Days" class="numOfDays-container">
                    <label class="numOdays-leave font_lm td_items_lm">{{detail.numOfDays | number:'2.0'}}</label>
                </td>
                <td class="rewoke-container-leave">
                    <button class="rewoke-btn-leave">Rewoke</button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination_container">
        <pagination-controls id="employee_id" class="pagination" (pageChange)="p = $event"></pagination-controls>
    </div> -->
    
</ng-template>

