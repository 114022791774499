<mat-accordion class="example-headers-align">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Current Address
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-12 form_main_div">
                    <form [formGroup]="editCorrespondenceDetails">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Adderss Line 1<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="addressLineOne" autocomplete="off">
                                    <mat-error *ngIf="checkError('addressLineOne', 'required')">Required</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Pincode<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="pincode" autocomplete="off">
                                    <mat-error *ngIf="checkError('pincode', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('pincode', 'pattern')">6 digit number</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>City<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="city" autocomplete="off">
                                    <mat-error *ngIf="checkError('city', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('city', 'pattern')">Invalid City</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Adderss Line 2 <span class="contentRed">(Optional)</span></mat-label>
                                    <input matInput formControlName="addressLineTwo" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>State<span class="contentRed"></span></mat-label>
                                    <input matInput matInput formControlName="state" autocomplete="off">
                                    <mat-error *ngIf="checkError('state', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('state', 'pattern')">Invalid State</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Country<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="country" autocomplete="off">
                                    <mat-error *ngIf="checkError('country', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('country', 'pattern')">Invalid Country</mat-error>
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit2()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>

</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Permanent Address
            </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-12 form_main_div">
                    <form [formGroup]="permanentAddress">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Adderss Line 1<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="addressLineOne" autocomplete="off">

                                    <mat-error *ngIf="checkError1('addressLineOne', 'required')">Required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Pincode<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="pincode" autocomplete="off">
                                    <mat-error *ngIf="checkError1('pincode', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError1('pincode', 'pattern')">6 digit number</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>City<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="city" autocomplete="off">
                                    <mat-error *ngIf="checkError1('city', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError1('city', 'pattern')">Invalid City</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Adderss Line 2 (Optional)</mat-label>
                                    <input matInput formControlName="addressLineTwo" autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>State<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="state" autocomplete="off">
                                    <mat-error *ngIf="checkError1('state', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError1('state', 'pattern')">Invalid State</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Country<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="country" autocomplete="off">
                                    <mat-error *ngIf="checkError1('country', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError1('country', 'pattern')">Invalid Country</mat-error>
                                </mat-form-field>
                            </div>
                        </div>





                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit3()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

    </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Personal Information
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid">
            <div class="row">

                <div class="col-md-12 form_main_div">
                    <form [formGroup]="personalDetails">
                        <div class="row">
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width" style="width:330px;">
                                    <mat-label>Blood Group<span class="contentRed"></span></mat-label>
                                    <mat-select formControlName="blood_group">
                                        <mat-option *ngFor="let bloodGroup of this.bloodgroup" [value]="bloodGroup">
                                            {{bloodGroup}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="checkError2('blood_group', 'required')">Required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width" style="width: 330px;">
                                    <mat-label>Date of Birth <span class="contentRed"></span></mat-label>
                                    <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy"
                                        formControlName="dob">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                    <mat-error *ngIf="checkError2('dob', 'required')">Required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Place of Birth<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="place_of_birth" autocomplete="off">
                                    <mat-error *ngIf="checkError2('place_of_birth', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError2('place_of_birth', 'pattern')">Invalid</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Relationship Status</mat-label>
                                    <mat-select formControlName="marital_status">
                                        <mat-option *ngFor="let relation of this.relationshipstatus" [value]="relation">
                                            {{relation}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="checkError2('marital_status', 'required')">Required</mat-error>
                                </mat-form-field>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Emergency Contact 1
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12 form_main_div">
                    <form [formGroup]="emergencyContactOne">
                        <div class="row">

                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Name<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="name" autocomplete="off">\
                                    <mat-error *ngIf="checkError3('name','required')">Required</mat-error>
                                    <mat-error *ngIf="checkError3('name', 'pattern')">Invalid Name</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Contact Details</mat-label>
                                    <input matInput formControlName="phoneNumber" autocomplete="off">
                                    <mat-error *ngIf="checkError3('phoneNumber', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError3('phoneNumber', 'pattern')">Invalid Mobile Number</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Relationship</mat-label>
                                    <input matInput formControlName="relationship" autocomplete="off">
                                    <mat-error *ngIf="checkError3('relationship', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError3('relationship', 'pattern')">Invalid</mat-error>
                                </mat-form-field>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Address Line</mat-label>
                                    <input matInput formControlName="addressLine" autocomplete="off">
                                    <mat-error *ngIf="checkError3('addressLine', 'required')">Required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit4()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

    </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Emergency Contact 2
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid">
            <div class="row">


                <div class="col-md-12 form_main_div">
                    <form [formGroup]="emergencyContactTwo">
                        <div class="row">

                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Name<span class="contentRed"></span></mat-label>
                                    <input matInput formControlName="name" autocomplete="off">
                                    <mat-error *ngIf="checkError4('name','required')">Required</mat-error>
                                    <mat-error *ngIf="checkError4('name', 'pattern')">Invalid Name</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Contact Details</mat-label>
                                    <input matInput formControlName="phoneNumber" autocomplete="off">
                                    <mat-error *ngIf="checkError4('phoneNumber', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError4('phoneNumber', 'pattern')">Valid Mobile Number</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Relationship</mat-label>
                                    <input matInput formControlName="relationship" autocomplete="off">
                                    <mat-error *ngIf="checkError4('relationship', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError4('relationship', 'pattern')">Invalid</mat-error>
                                </mat-form-field>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Address Line</mat-label>
                                    <input matInput formControlName="addressLine" autocomplete="off">
                                    <mat-error *ngIf="checkError4('addressLine', 'required')">Required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit5()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>


    </mat-expansion-panel>

</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                Family / Nominee Details
            </mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation();">
                <ng-container *ngIf="this.panelOpenState==true;else elseTemplate">
                    <button class="add_button" (click)="addFamily()">+Add Family Details</button>

                </ng-container>
                <ng-template #elseTemplate>

                </ng-template>

            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="container-fluid">
            <div class="row">
                <button class="plus_button" (click)="addFamily()"><span class="mr-2">+Add</span></button>

            </div>
            <div class="row">

                <div class="col-md-12 form_main_div">
                    <form [formGroup]="familyForm">
                        <div formArrayName="family" *ngFor="let familyy of this.Family().controls; let i=index">
                            <div class="row">
                                <div class="col-md-3 nominee_details" [formGroupName]="i" >


                                    <ngb-rating formControlName="nominees" class="ngb-rating" [max]="1" [readonly]="false">
                                        <button (click)="removefamily(familyy.value.id,i)" class="remove_label">Remove</button>

                                    </ngb-rating>

                                    <label class="nominee_label">Mark As Nominee</label>
                                                                </div>

                          </div>
                            <div class="row">
                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Name<span class="contentRed"></span></mat-label>
                                        <input matInput formControlName="firstName" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Relationship Name<span class="contentRed"></span></mat-label>
                                        <input matInput  formControlName="relation" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Location<span class="contentRed"></span></mat-label>
                                        <input matInput formControlName="location" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Date of Birth <span class="contentRed"></span></mat-label>
                                        <input matInput [matDatepicker]="picker18" placeholder="mm/dd/yyyy"
                                            formControlName="dateOfBirth">
                                        <mat-datepicker-toggle matSuffix [for]="picker18"></mat-datepicker-toggle>
                                        <mat-datepicker #picker18></mat-datepicker>
                                    </mat-form-field>
                                </div>


                            </div>


                                <button class="remove_label" (click)="removefamily(familyy.value.id,i)">Remove</button>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit1()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </div>

    </mat-expansion-panel>

</mat-accordion>
