<div class="container-fluid lap-view">
    <div class="admin-div" *ngIf="this.hasRoleBased">

        <div class="col-md-4 inner-div">
            <div (click)="backToEmployees()">
                <figure class="mt-3">
                    <mat-icon> <img class="upload_img" src="assets/back_arrow.png"></mat-icon>
                    <label class="upload_text">Employees</label>
                </figure>
            </div>

        </div>
        <button (click)="changeRelievedStatus()" *ngIf="this.hasRoleBased && this.user_details.isRelieved==false"
            class="btn-relieve ">
            Relieve the Employee
        </button>

    </div>
    <!-- <div class="main_div" *ngIf="(user_details$ | async)?.loading">
        <img class="loading_logo" src="assets/loading_logo.png">
        <label class="loading_label mt-5">Loading...</label>
    </div> -->

    <div *ngIf="this.loading_data" class="profile_display_card">
        <div class="details-div mt-2">
            <div class="example-card">
                <div class="employee_profile">
                    <div class="col-2">
                        <div class="upload_img_maindiv mt-3">
                            <figure>
                                <!--(click)="fileInput.click()"-->
                                <div class="upload_img_cation_alignment">
                                    <img class="upload_img"
                                        [(src)]="this.userDto.userImageThumbnail"
                                        *ngIf="this.loggedInProfile && (this.userDto.userImageThumbnail !== '')" />
                                    <img class="upload_img" src="assets/blue_altair_logo.png" alt=""
                                        *ngIf="this.loggedInProfile && (this.userDto.userImageThumbnail == '')">
                                    <img class="upload_img"
                                        [(src)]="this.userDto.userImageThumbnail"
                                        *ngIf="!this.loggedInProfile && (this.userDto.userImageThumbnail !== '')" />
                                    <img class="upload_img" src="assets/blue_altair_logo.png" alt=""
                                        *ngIf="!this.loggedInProfile && (this.userDto.userImageThumbnail == '')">
                                    <label class="remove_img_text"
                                        *ngIf="this.imageError && !this.photo">{{this.imageError}}</label>
                                    <figcaption class="upload_text"
                                        *ngIf="this.userDto.userImageThumbnail == ''">
                                    </figcaption>

                                </div>

                            </figure>
                        </div>
                    </div>

                    <div class="col-10">
                        <div class="row mt-2">
                            <div class="emp_name_id col-3">
                                <div class="mt-2 first_div">
                                    <label class="emp-name cap-lbl"> {{this.user_details.firstName + " " +
                                        this.user_details.lastName}}</label>

                                    <div>

                                        <span class="emp-id">ID : </span>
                                        <label class="emp-id">{{this.user_details.employeeId | uppercase}}</label>
                                    </div>


                                </div>

                            </div>


                            <div class="emp_name_id col-2">
                                <div class="mt-2 second-div1  xm">
                                    <label class="l-lbl">Career Manager</label>
                                    <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                        matTooltip="{{this.careermanager}}"
                                        class="m-lbl cap-lbl2">{{this.careermanager}}</label>
                                </div>
                            </div>

                            <div class="emp_name_id col-2">
                                <div class="mt-2 second-div">




                                    <label class="l-lbl ">Capability</label>

                                    <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                        matTooltip="{{this.department[this.user_details.department]}}"
                                        class="m-lbl cap-lbl">{{this.department[this.user_details.department]}}</label>
                                </div>
                            </div>

                            <div class="emp_name_id col-2">
                                <div class="mt-2 second-div ">
                                    <label class="l-lbl ">Title</label>

                                    <label class="m-lbl cap-lbl">{{this.jobTitle[this.user_details.jobTitle]}}</label>
                                </div>
                            </div>
                            <div class="emp_name_id col-2">
                                <div class="mt-2 second-div">
                                    <label class="l-lbl slider-lbl ">Profile Completion Status</label>
                                    <p-slider [(ngModel)]="this.maindata " [min]="0" [max]="100" disabled>

                                    </p-slider>
                                    <label>{{this.maindata}}%</label>
                                    <!--{{this.maindata | number:'1.0-0' }}-->
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="emp_name_id col-3 ">

                                <div class="mt-2 second-div">
                                    <label class="l-lbl">Official Email</label>
                                    <label class="m-lbl" matTooltip="{{this.user_details.email}}"
                                        [matTooltipPosition]="position.value"
                                        [matTooltipClass]="position.value">{{this.user_details.email}}</label>

                                </div>
                            </div>
                            <div class="emp_name_id col-2">
                                <div class="mt-2 second-div">
                                    <label class="l-lbl ">Mobile Number</label>

                                    <label class="m-lbl cap-lbl1">{{this.user_details.mobileNumber}}</label>
                                    <!--  -->
                                </div>
                            </div>


                            <div class="emp_name_id col-2" *ngIf="this.user_details.fgJoiningDate !== null">
                                <div class="mt-2 second-div">
                                    <label class="l-lbl">Date of Joining FG</label>

                                    <label class="m-lbl2">{{this.user_details.fgJoiningDate | date:
                                        "dd-MM-yyyy" }}</label>
                                </div>
                            </div>



                            <div class="emp_name_id col-2">
                                <div class="mt-2 second-div">
                                    <label class="l-lbl">Date of Joining BA</label>

                                    <label class="m-lbl">{{this.user_details.joiningDate |
                                        date:"dd-MM-yyyy"}}</label>
                                </div>
                            </div>
                            <div class="emp_name_id col-2">
                                <div class="mt-2 second-div">
                                    <label class="l-lbl">Overall Experience</label>

                                    <label class="m-lbl">{{overAllExperince}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!this.loading_data" class="main_div mt-5" >
        <mat-spinner strokeWidth="7" [diameter]="170"></mat-spinner>
        <label class="loading_label mt-4 mb-5">Loading...</label>
    
    
    </div>
    
</div>









<div class="container-fluid mt-3 mob-view">
    <div class="mt-2 admin-div" *ngIf="this.hasRoleBased">

        <div class="col-md-4 inner-div">
            <div (click)="backToEmployees()">
                <figure class="mt-3">
                    <mat-icon> <img class="upload_img" src="assets/back_arrow.png"></mat-icon>
                    <label class="upload_text">Employees</label>
                </figure>
            </div>


        </div>
        <button (click)="changeRelievedStatus()" class="btn-relieve mt-2"
            *ngIf="this.hasRoleBased && this.user_details.isRelieved==false">
            Relieve the Employee
        </button>

    </div>
    <div>
        <div class="emp-display user-details">
            <div>
                <div class="upload_img_maindiv ">
                    <figure>
                        <!--(click)="fileInput.click()"-->
                        <div class="upload_img_cation_alignment">
                            <img class="upload_img"
                                        [(src)]="this.userDto.userImageThumbnail"
                                        *ngIf="this.loggedInProfile && (this.userDto.userImageThumbnail !== '')" />
                                    <img class="upload_img" src="assets/blue_altair_logo.png" alt=""
                                        *ngIf="this.loggedInProfile && (this.userDto.userImageThumbnail == '')">
                                    <img class="upload_img"
                                        [(src)]="this.userDto.userImageThumbnail"
                                        *ngIf="!this.loggedInProfile && (this.userDto.userImageThumbnail !== '')" />
                                    <img class="upload_img" src="assets/blue_altair_logo.png" alt=""
                                        *ngIf="!this.loggedInProfile && (this.userDto.userImageThumbnail == '')">
                                    <label class="remove_img_text"
                                        *ngIf="this.imageError && !this.photo">{{this.imageError}}</label>
                                    <figcaption class="upload_text"
                                        *ngIf="this.userDto.userImageThumbnail == ''">
                                    </figcaption>
                            <!-- <input type="file" id="my_file" style="display: none;" accept="image/*"
                                (change)="readURL($event.target.files)" #fileInput /> -->
                        </div>
                        <!-- <figcaption class="remove_img_text" *ngIf="this.userDetailService.getUserDetails().userImageThumbnail !== ''" (click)="setDefaultImg()">Remove File
                        </figcaption>
                         -->
                    </figure>
                </div>
            </div>
            <div>
                <div class="mt-2 first_div">
                    <label class="emp-name">{{this.user_details.firstName +" "+ this.user_details.lastName}}</label>
                    <label class="emp-id">{{this.user_details.employeeId | uppercase }}</label>
                </div>
            </div>
            <div>
                <div class="mt-2  slider-div">
                    <label class="l-lbl  ">Profile Completion Status</label>
                    <p-slider [(ngModel)]="this.maindata " [min]="0" [max]="100" disabled>

                    </p-slider>
                    <label>{{this.maindata}}%</label>
                </div>

            </div>
        </div>
        <div class="emp-display">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <table class="moblile-emp-details">
                        <tr>
                            <td class="w-1"> Title</td>
                            <td class="w-2"><label class="m-lbl">{{this.jobTitle[this.user_details.jobTitle]}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="w-1">Capability</td>
                            <td class="w-2"><label
                                    class="m-lbl">{{this.department[this.user_details.department]}}</label></td>
                        </tr>
                        <tr>
                            <td class="w-1">Career Manager</td>
                            <td class="w-2"><label class="m-lbl">{{this.careermanager}}</label></td>
                        </tr>
                        <tr>
                            <td class="w-1">Official Email</td>
                            <td class="w-2"><label class="m-lbl">{{this.user_details.email}}</label></td>
                        </tr>

                    </table>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>
<div class="container-fluid mt-3 mob-view2">
    <div class="mt-2 admin-div" *ngIf="this.hasRoleBased">

        <div class="col-md-4 inner-div">
            <div (click)="backToEmployees()">
                <figure class="mt-3">
                    <mat-icon> <img class="upload_img" src="assets/back_arrow.png"></mat-icon>
                    <label class="upload_text">Employees</label>
                </figure>
            </div>


        </div>
        <button (click)="changeRelievedStatus()" class="btn-relieve mt-2"
            *ngIf="this.hasRoleBased && this.user_details.isRelieved==false">
            Relieve the Employee
        </button>

    </div>
    <div>
        <div class="emp-display user-details">
            <div>
                <div>
                    <div class="upload_img_maindiv mt-3">
                        <figure>
                            <!--(click)="fileInput.click()"-->
                            <div class="upload_img_cation_alignment">
                                <img class="upload_img"
                                [(src)]="this.userDto.userImageThumbnail"
                                *ngIf="this.loggedInProfile && (this.userDto.userImageThumbnail !== '')" />
                            <img class="upload_img" src="assets/blue_altair_logo.png" alt=""
                                *ngIf="this.loggedInProfile && (this.userDto.userImageThumbnail == '')">
                            <img class="upload_img"
                                [(src)]="this.userDto.userImageThumbnail"
                                *ngIf="!this.loggedInProfile && (this.userDto.userImageThumbnail !== '')" />
                            <img class="upload_img" src="assets/blue_altair_logo.png" alt=""
                                *ngIf="!this.loggedInProfile && (this.userDto.userImageThumbnail == '')">
                            <label class="remove_img_text"
                                *ngIf="this.imageError && !this.photo">{{this.imageError}}</label>
                            <figcaption class="upload_text"
                                *ngIf="this.userDto.userImageThumbnail == ''">
                            </figcaption>
                                <!-- <input type="file" id="my_file" style="display: none;" accept="image/*"
                                    (change)="readURL($event.target.files)" #fileInput /> -->
                            </div>
                            <!-- <figcaption class="remove_img_text" *ngIf="this.userDetailService.getUserDetails().userImageThumbnail !== ''" (click)="setDefaultImg()">Remove File
                            </figcaption>
                             -->
                        </figure>
                    </div>
                </div>
            </div>
            <div>
                <div class="mt-2 first_div">
                    <label class="emp-name">{{this.user_details.firstName +" "+ this.user_details.lastName}}</label>
                    <label class="emp-id">{{this.user_details.employeeId | uppercase}}</label>
                </div>
            </div>
            <div>
                <div class="mt-2  slider-div">
                    <label class="l-lbl  ">Profile Completion Status</label>
                    <p-slider [(ngModel)]="this.maindata " [min]="0" [max]="100" disabled>

                    </p-slider>
                    <label>{{this.maindata}}%</label>
                </div>

            </div>
        </div>
        <div class="emp-display">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                    <table class="mobile-emp-details">
                        <tr>
                            <td class="w-1"> Title</td>
                            <td class="w-2"><label class="m-lbl">{{this.jobTitle[this.user_details.jobTitle]}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td class="w-1">Capability</td>
                            <td class="w-2"><label
                                    class="m-lbl">{{this.department[this.user_details.department]}}</label></td>
                        </tr>
                        <tr>
                            <td class="w-1">Career Manager</td>
                            <td class="w-2"><label class="m-lbl">{{this.careermanager}}</label></td>
                        </tr>
                        <tr>
                            <td class="w-1">Official Email</td>
                            <td class="w-2"><label class="m-lbl">{{this.user_details.email}}</label></td>
                        </tr>
                        <tr>
                            <td class="w-1">Mobile Number</td>
                            <td class="w-2"><label class="m-lbl">{{this.user_details.mobileNumber}}</label></td>
                        </tr>

                        <tr *ngIf="this.user_details.fgJoiningDate !== null">
                            <td class="w-1">Date of Joining FG</td>
                            <td class="w-2"><label class="m-lbl2 ">{{this.user_details.fgJoiningDate | date:
                                    "dd-MM-yyyy"}}</label></td>
                        </tr>

                        <tr>
                            <td class="w-1">Date of Joining BA</td>
                            <td class="w-2"><label class="m-lbl">{{this.user_details.joiningDate |
                                    date:"dd-MM-yyyy"}}</label></td>
                        </tr>
                        <tr>
                            <td class="w-1">Overall Experience</td>
                            <td class="w-2"><label class="m-lbl">{{overAllExperince }}</label></td>
                        </tr>

                    </table>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="tab-div">
                <mat-tab-group (selectedTabChange)="onTabChanged($event)">
                    <mat-tab *ngIf="(this.isrelieved==true) && this.loggedInProfile">
                        <ng-template mat-tab-label>
                            <div class="lbl ">
                                <label>Relieving Process</label>
                            </div>
                        </ng-template>
                        <div>
                            <app-reliving-process></app-reliving-process>
                        </div>
                    </mat-tab>
                    <mat-tab >
                        <ng-template mat-tab-label>
                            <div class="lbl">
                                <label>Spotlight</label>
                            </div>
                        </ng-template>
                        <div>
                            <app-spot-light (dummyfunc)="dummyfunc($event)" #spotLight></app-spot-light>
                        </div>
                    </mat-tab>
                    <mat-tab >
                        <ng-template mat-tab-label>
                            <div class="lbl ">
                                <label>Real-time Feedback</label>
                            </div>
                        </ng-template>
                        <div>
                            <app-public-profile [dataFromParent]="this.userid"></app-public-profile>
                        </div>
                    </mat-tab>
                    
                    <mat-tab *ngIf="this.loggedInProfile" >
                        <ng-template mat-tab-label>
                            <div class="lbl ">
                                <label>Correspondence</label>
                            </div>
                        </ng-template>
                        <div>
                            <app-correspondence (dummyfunc)="dummyfunc($event)"></app-correspondence>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="this.loggedInProfile">
                        <ng-template mat-tab-label>
                            <div class="lbl ">
                                <label>Tenure</label>
                            </div>
                        </ng-template>
                        <div>
                            <app-tenure></app-tenure>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="this.loggedInProfile">
                        <ng-template mat-tab-label>
                            <div class="lbl ">
                                <label>Vault</label>
                            </div>
                        </ng-template>
                        <div>
                            <app-vault (dummyfunc)="dummyfunc($event)"></app-vault>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="this.loggedInProfile">
                        <ng-template mat-tab-label>
                            <div class="lbl ">
                                <label>Business Travel</label>
                            </div>
                        </ng-template>
                        <div>
                            <app-business-travel (dummyfunc)="dummyfunc($event)"></app-business-travel>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>