import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApplyNewleaveComponent } from './apply-newleave/apply-newleave.component';
import { LeaveConfigurationComponent } from './leave-configuration/leave-configuration.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CmReporteeComponent } from './cm-reportee/cm-reportee.component';
import { LeaveDetailsComponent } from './leave-details/leave-details.component';


@Component({
  selector: 'app-leave-management',
  templateUrl: './leave-management.component.html',
  styleUrls: ['./leave-management.component.scss']
})

export class LeaveManagementComponent implements OnInit {
  @ViewChild('leaveDetail') leaveDetailComponent: LeaveDetailsComponent;
  rolesList: string[] = [];
  @ViewChild('childComponent') cmReporteeComponent: CmReporteeComponent;

  constructor(private dialog: MatDialog,
    public userDetailService: UserDetailsService,
  ) { }

  matTabsClickedIndex = 0;

  ngOnInit(): void {
    this.isCareerManager();
  }

  onTabChanged($event) {
    this.matTabsClickedIndex = $event.index;
    this.cmReporteeComponent.onClick();

  }

  applyNewLeaveDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "90%"
    dialogConfig.panelClass = "apply-leave"
    const dialogRef = this.dialog.open(ApplyNewleaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.leaveDetailComponent.getAllLeaves();
    });
  }

  isCareerManager() {
    this.rolesList = this.userDetailService.getUserDetails().authorities;
    for (let role of this.rolesList) {
      if (role == "ROLE_CAREER_MANAGER") {
        return true;
      }
    }
    return false;
  }

}
