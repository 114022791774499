<div class="main_header_div mb-2">
    <div class="child">
        <h1 class="header_name ">Edit Emergency Contact 1</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>
   
    </div>
</div>
<hr>
<div class="container-fluid">
    <div class="row">


        <div class="col-md-12 form_main_div">
            <form [formGroup]="emergencyContactOne">
                <div class="row mt-3">

                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Name *<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="name" autocomplete="off">
                            <mat-error *ngIf="checkError('name', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('name', 'pattern')">Invalid Name</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Contact Details</mat-label>
                            <input matInput formControlName="phoneNumber" autocomplete="off">
                            <mat-error *ngIf="checkError('phoneNumber', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('phoneNumber', 'pattern')">Invalid Mobile Number</mat-error>
                     
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Relationship *</mat-label>
                            <input matInput formControlName="relationship" autocomplete="off">
                            <mat-error *ngIf="checkError('relationship', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('relationship','pattern')">Invalid</mat-error>
                        </mat-form-field>
                    </div>


                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Address Line</mat-label>
                            <input matInput formControlName="location" autocomplete="off">
                            <mat-error *ngIf="checkError('location', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>