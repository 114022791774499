import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Family } from 'src/app/models/familydetails';
import { AddressDetailsUrlService } from '../restAPI/addressDetailsURLs/addressdetails-url.services';
import { FamilyDetailsUrlService } from '../restAPI/familyDetailsURLs/familydetails-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class FamilyDetailsService {
familydetails:Family[]=[];
  constructor(private restApi:RestAPIService,private familydetailurlservice:FamilyDetailsUrlService,private http:HttpClient)
   { }
   create(payload) {
    return this.restApi.post(
      this.familydetailurlservice.getBaseUrl(),
      payload
    )
  }
   update(payload){
    return this.restApi.put(
      this.familydetailurlservice.getBaseUrl(),
      payload
    )
  }
   getfamilyByUserIdSubscribe(userId){
    return this.restApi.get(
    this.familydetailurlservice.getUserUrl(userId))
    }

   
    setfamilyResponse(response):Family[]{
        let familyList:Family[] = []
        for(let data of response){
       
            let familydata:Family= new Family()
            familyList.push(
            familydata.bind(data)
                  
            )
               
        }
        return familyList
      }
      delete(id: any) {
        this.restApi.delete(
          this.familydetailurlservice.getByFieldUrl(id)
        ).subscribe(res=>{
         
        })
      }
}
