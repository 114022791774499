import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef , MAT_DIALOG_DATA} from '@angular/material/dialog';
import {LeaveApplicationService} from 'src/app/services/leave-application/leave-application.service';
import { UserService } from 'src/app/services/user/user.service';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space'; 
@Component({
  selector: 'app-detail-leave',
  templateUrl: './detail-leave.component.html',
  styleUrls: ['./detail-leave.component.scss']
})
export class DetailLeaveComponent implements OnInit {

  leavedetails:any=[];

  constructor(public dialogRef: MatDialogRef<DetailLeaveComponent>,
    public detailLeave:LeaveApplicationService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
 
  ngOnInit(): void {
this.detailsLeave();
  }

  closeDialog() {
    this.dialogRef.close();
 }

 detailsLeave(){
  this.leavedetails=[];
  this.detailLeave.getDetaildLeave(this.data.leaveType,2023,this.data.id).subscribe((res)=>{
   
    for(let i=0;i<res.body.length;i++){
      console.log(res.body[i]);
      const details ={
        "number":i+1,
         "Requestedon":res.body[i].requestedDateTime,
         "From":res.body[i].startDate,
         "To":res.body[i].endDate,
         "days": res.body[i].leaveCount,
         "Status":res.body[i].approvalStatus,
  
      }
      this.leavedetails.push(details);
    }
  })
   
}
}
