interface IEDUCATION {
    courseName:  string;
  
    yearofCompletion : Date;
   
    userId:String
    id:string
}

export class Education{
    public courseName: String;
 
    public  yearofCompletion: Date
    public  yearOfBeginning: Date

    public userId :string
    public id:string

    constructor(){
        this.courseName = ''
     
        this.id = undefined
        this.userId = undefined
        this.yearofCompletion=undefined
        this.yearOfBeginning=undefined
      
       
    }

    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.courseName = httpResponse && httpResponse.courseName || undefined
        this.yearofCompletion = httpResponse && httpResponse.yearofCompletion || undefined
        this.yearOfBeginning = httpResponse && httpResponse.yearOfBeginning || undefined

       
        this.userId = httpResponse && httpResponse.userId || undefined 
        return this;     
    }
}