export enum TypeOfLeave {
    PAID_LEAVE="Paid Leave", 
    COMP_OFF="Comp Off",
    UNPLANNED_LEAVE="Unplaned Leave", 
    CONTINGENCY_LEAVE="Contingency Leave", 
    SPECIAL_LEAVE="Special Leave",
    PATERNITY_LEAVE="Paternity Leave",
    MATERNITY_LEAVE="Maternity Leave",
    RESTRICTED_HOLIDAY="Restricted Holiday",
    LOSS_OF_PAY="Loss Of Pay"
}
export enum ApprovalStatus {
    PENDING="PENDING",
    APPROVED="Approved",
    REVOKED_BY_USER="Revoked By User",
    REVOKE_REQUEST_BY_USER="Revoke Request By User",
    REVOKE_REQUEST_REJECTED="Revoke Request Rejected",
    REJECTED="REJECTED",
}
export enum PostApprovalLeaveCancellationStatus {
    PENDING="PENDING",
    APPROVED="Approved",
    REJECTED="Rejected",
}
export enum FirstnSecondHalf{
    FIRST_HALF="First Half",
    SECOND_HALF="Second Half",
}