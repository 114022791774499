import { createReducer, createSelector, on } from '@ngrx/store';
import * as TaskActions from '../actions/task.action';
import { Task } from '../../models/task';
import { Observable } from 'rxjs';
import { TaskStatus } from 'src/app/services/task/task-enums';

export interface TaskState {
  taskList: Task[] | null;
  loading: Boolean;
  error: String | null;
}

interface AppState {
  tasks: TaskState;
}

const initalTaskState: TaskState = {
  taskList: null,
  loading: false,
  error: null,
};

export const taskReducer = createReducer(
  initalTaskState,
  on(TaskActions.loadTasks, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TaskActions.loadTasksError, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(TaskActions.loadTasksSuccess, (state, { data }) => ({
    ...state,
    loading: false,
    taskList: data,
    error: null,
  }))
);

export const selectTask = (state: AppState) => state.tasks;

export const selectTaskList = createSelector(
  selectTask,
  (taskState) => taskState.taskList
);

export const selectPendingTasks = createSelector(selectTaskList, (tasks) =>
  tasks?.filter((task) => task.taskStatus === TaskStatus.PENDING)
);

export const selectPendingTasksCount = createSelector(selectPendingTasks, (tasks) =>
  tasks?.length
);

export const selectInprogressTasks = createSelector(selectTaskList, (tasks) =>
  tasks?.filter((task) => task.taskStatus === TaskStatus.INPROGRESS)
);

export const selectInprogressTasksCount = createSelector(selectInprogressTasks, (tasks) =>
  tasks?.length
);

export const selectCompletedTasks = createSelector(selectTaskList, (tasks) =>
  tasks?.filter((task) => ((task.taskStatus === TaskStatus.COMPLETED))).sort((t1, t2) => t1.created_on > t2.created_on ? -1 : 1 )
)
export const selectCompletedTasksCount = createSelector(selectCompletedTasks, (tasks) =>
  tasks?.length
);

export const selectDueTasks = createSelector(selectTaskList, (tasks) =>
  tasks?.filter((task) => task.taskStatus === TaskStatus.DUE)
);

export const selectDueTasksCount = createSelector(selectDueTasks, (tasks) =>
  tasks?.length 
);
