import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { PositionDetails } from 'src/app/models/positionDetails';
import { User } from 'src/app/models/user';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { Departments, JobTitles } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-capability-details',
  templateUrl: './capability-details.component.html',
  styleUrls: ['./capability-details.component.scss']
})
export class CapabilityDetailsComponent implements OnInit  {
  preEmployment: FormArray;
  editSpotlightDetails: FormGroup;
  
  removedPreviousemployment = [];
  jobTitles = [];
userDto
departments=[]
  userid: any;
isEndDatePresent=[];
  constructor(public userDetailService: UserDetailsService,
    public userService: UserService,
    
    public toaster:ToastrService,
    public fb: FormBuilder,public empservice: PreviousEmploymentService,
    public dialogRef: MatDialogRef<CapabilityDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User) {
      this.editSpotlightDetails = this.fb.group({
        previousEmployment: this.fb.array([]),
     });}
ngOnInit(): void {
  
  this.empservice
  this.userDto=this.userDetailService.getUserDetails();
  this.userid=this.userDetailService.getUserDetails().id;
  this.processEnumValuesToArray();
  this.userService.getcapabilityDetails(this.userid).subscribe(res=>{
      

    for(let i of res.body.capabilityDetails)
        {
          
          i.jobTitle=this.getEnumValueByEnumKey(JobTitles,i.jobTitle);
          i.capability=this.getEnumValueByEnumKey(Departments,i.capability);

        }
        
     this.setPrevempFormArray(res.body.capabilityDetails)
    })
    }

createpreEmp() {
    
  let formGroup = this.fb.group(new PositionDetails());
  formGroup.controls.capability.setValidators([Validators.required]);
   formGroup.controls.jobTitle.setValidators([Validators.required]);
  return formGroup;
}
setPrevempFormArray(response) {
  let data: number;
  
  this.preEmployment = this.editSpotlightDetails.get(
    'previousEmployment'
  ) as FormArray;
  let empList: PositionDetails[] =
    this.empservice.setpostionDetails(response);
  for (let s of empList) {
    
      if(s.positionEndDate==null)
           {
               this.isEndDatePresent.push(false);
           }
           else 
           {
            this.isEndDatePresent.push(true);
           }
    let formGroup: FormGroup = this.fb.group(s);
    
    // formGroup.controls.experiencemonth.setValue(this.processDate(formGroup));
    this.preEmployment.push(formGroup);
  }
}
PreviousEmp(): FormArray {
  return this.editSpotlightDetails.get('previousEmployment') as FormArray;
}
addPreEmployment() {
  
  this.preEmployment = this.editSpotlightDetails.get(
    'previousEmployment'
  ) as FormArray;
  this.preEmployment.insert(0, this.createpreEmp());
}
removePreEmployment(id, i) {
  this.removedPreviousemployment.push(id);
  this.PreviousEmp().removeAt(i);
}
closeDialog(event:Event) {
  this.dialogRef.close({ event: 'Closed'})
}

getEnumKeyByEnumValue(myEnum, enumValue) {
  let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}
getEnumValueByEnumKey(myEnum, enumKey: string) {
  return myEnum[enumKey];
}
processEnumValuesToArray() {
  
  for (let department of Object.values(Departments)) {
    if (isNaN(Number(department)))
      this.departments.push(department)
  }
  for (let jobTitle of Object.values(JobTitles)) {
    if (isNaN(Number(jobTitle)))
      this.jobTitles.push(jobTitle)
  }
}
onSubmit()
{ 
  let data=[];
  if (this.editSpotlightDetails.valid) {
      for (let prevemp of this.editSpotlightDetails.value.previousEmployment) {
        
        var obj ={};

              obj['startDate']= prevemp.positionStartDate;
              if(prevemp.positionEndDate==null)
               {
                obj['endDate']='';

               }
               else
               obj['endDate']=prevemp.positionEndDate;
               obj['jobTitle']=this.getEnumKeyByEnumValue(JobTitles,prevemp.jobTitle)
     
              obj['capability']=this.getEnumKeyByEnumValue(Departments,prevemp.capability)
            data.push(obj)
  }
  
  this.userDto['capabilityDetails']=data;
  this.userService.setcapabilityHistory(this.userid,this.userDto).subscribe(res=>{
      this.dialogRef.close({ event: 'success'})
  })
}
}
}