import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmReportDTO } from 'src/app/dtos/CmReportDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { GeoLocation } from 'src/app/services/user/user-enum';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { User } from 'src/app/models/user';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-cm-reportees',
  templateUrl: './cm-reportees.component.html',
  styleUrls: ['./cm-reportees.component.scss'],
})
export class CmReporteesComponent implements OnInit {
  openCount;
  closeCount;
  notInitiatedCount;
  selected;
  selectedYear: number;
  years: number[] = [];

  cmReportDTO: CmReportDTO = new CmReportDTO();
  cmReportDTOList: any;
  geo: any = [];
  geoLoc;
  selectedGeolocation: String = '';
  count;
  p: number = 1;
  itemsPerPage = 8;
  searchText: string;
  nodata: Boolean = true;
  userdto: UserDTO;
  userdto2 = [];
  userdto3: UserDTO;
  directReportees;
  cmId;
  userid: UserDTO;
  constructor(
    public userGoalService: UserGoalService,
    public feedbackgroupservice: NewFeedbackGroupService,
    public feedbackService: FeedbackService,
    private feedbackgroup: NewFeedbackGroupService,
    private route: ActivatedRoute,
    private userservice: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.cmId = this.route.snapshot.paramMap.get('id');

    this.userservice.getUserByID(this.cmId).subscribe((res) => {
      this.userdto3 = res.body;
    });

    this.selected = 2022;
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2015; year--) {
      this.years.push(year);
    }

    //    this.getdirectreporteedetails();
    this.getMyReportee();
    //this.getMyReportee();
  }
  ngAfterViewChecked(): void {
    this.itemsPerPage = 10;
    if (window.innerWidth > 960) {
      this.itemsPerPage = 6;
    }
    if (window.innerWidth > 1187) {
      this.itemsPerPage = 9;
    }
    if (window.innerWidth > 1530) {
      this.itemsPerPage = 12;
    }
    if (window.innerWidth > 1877) {
      this.itemsPerPage = 15;
    }
  }

  getMyReportee() {
    this.openCount = 0;
    this.closeCount = 0;
    this.notInitiatedCount = 0;
    this.feedbackgroupservice
      .getdirectreporteebyuserId(this.cmId, this.selected)
      .subscribe((res) => {
        if (res.body.length != 0) {
          for (let i of res.body) {
            if (i.closed) {
              this.closeCount += 1;
            } else {
              if (i.feedbackGroupCount == 0) {
                this.notInitiatedCount += 1;
              } else {
                this.openCount += 1;
              }
            }

            this.nodata = false;
          }
        }
        this.directReportees = res.body.length;

        this.userdto2 = null;
        this.userdto2 = res.body;
      });
  }

  loadForm(event) {
    this.userGoalService.setCurrentYear(event.value);

    this.selected = event.value;
    this.getMyReportee();
  }
  onViewReportee(id) {
    this.feedbackService.setIndex(1);
    this.router.navigate(['profiledetail', id]);
  }

  // getdirectreporteedetails() {
  //   this.userdto = null;
  //   this.feedbackgroup
  //     .getdirectreporteebyuserId(this.cmId, this.selected)
  //     .subscribe((res) => {
  //       for (let i of res.body) this.userdto = i;
  //       this.userdto;
  //     });
  // }
}
