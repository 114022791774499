<mat-card class="main-card pr-0 pl-0">
  <label class="head ml-4" (click)="navigate(0)">Tasks</label>

  <div class="parents mt-4">
    <div class="child">
      <div class="progressbar-container" (click)="navigate(0)">
        <img class="progress_img" src="/assets/pending_task.png" alt="">
        <span class="centered pending_tast_num">{{this.taskStatus[0]}} </span>
      </div>
      <label class="task_name">Pending Tasks</label>
    </div>

    <div class="child">
      <div class="progressbar-container" (click)="navigate(1)">
        <img class="progress_img" src="/assets/Inprogress_task.png" alt="">
        <span class="centered Inprogress_tast_num"> {{this.taskStatus[1]}} </span>
      </div>
      <label class="task_name">Inprogress Tasks</label>
    </div>
    <div class="child mobile_view_overdue" (click)="navigate(2)">
      <div class="progressbar-container">
        <img class="progress_img" src="/assets/Overdue_tasks.png" alt="">
        <span class="centered Overdue_tasks_num"> {{this.taskStatus[2]}} </span>
      </div>
      <label class="task_name">Overdue Tasks</label>
    </div>

    <div class="child mobile_view_completed_task" (click)="navigate(3)">
      <div class="progressbar-container">
        <img class="progress_img" src="/assets/Completed_task.png" alt="">
        <span class="centered Completed_task_num"> {{this.taskStatus[3]}} </span>
      </div>
      <label class="task_name">Completed Tasks</label>
    </div>
  </div>
</mat-card>