<!-- <div class=" flex page-row-top"> -->
<div>
    <mat-card class="main-mat-card">
        <div class="row">
            <button class="top-btn" [ngClass]="{'active': regularizationTab}" (click)="onBtnClick()">Unmarked
                Attendance</button>
            <button class="top-btn" [ngClass]="{'active': attendanceHistoryTab}" (click)="onBtnOneClick()">Attendance
                History</button>
        </div>

        <div *ngIf="regularizationTab" class="regulrization">
            <div *ngIf="val.length > 0; else noItems">
                <div class="attendance">
                    <section class="example-section" *ngFor="let item of val | orderBy : 'attendanceDateTime'">
                        <p><mat-checkbox [fontSize]="'4px'" class="prop" (change)="onCheckboxClick($event, item)"
                                *ngIf="item.appliedForRegularization==false">
                                {{item.attendanceDateTime | date}}
                            </mat-checkbox>
                            <mat-checkbox [fontSize]="'4px'" class="prop" (change)="onCheckboxClick($event, item)"
                                *ngIf="item.appliedForRegularization==true" [disabled]="true">
                                {{item.attendanceDateTime | date}}
                            </mat-checkbox>
                        </p>
                        <div class="blue" *ngIf="item.appliedForRegularization==false">
                            <div class="apply-leave" (click)="applyLeave(item)">
                                Apply Leave
                            </div>
                            <div class="blur-divider">
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                            </div>
                            <div>
                                <button class="regular" (click)="openDialog(item)">
                                    Regularization
                                </button>
                            </div>
                        </div>
                        <div class="blue" *ngIf="item.appliedForRegularization==true">
                            <div class="regular pending-regular">Pending</div>
                        </div>
                    </section>
                    <div>
                        <button class="btn top fix" (click)="openDial()" [disabled]="selectedItems.length < 2">
                            Apply Regularization
                        </button>
                    </div>
                </div>
                
            </div>
            <ng-template #noItems>
                <div class="no-leave no-leave-container">
                    <img class="no-leave-img" src="\assets\no-regularization.png" alt="">
                    <div class="no-leave-assign">
                        <div class="no-leave-assign">No data</div>
                    </div>
                </div>
            </ng-template>
        </div>


        <div *ngIf="attendanceHistoryTab" class="row att-history">
            <div>
                <mat-card class="calendar-card">
                    <div class="month-year-div">
                        <div class="month-select">
                            <mat-select class="year-month-select" [(ngModel)]="selectedMonth"
                                (ngModelChange)="onMonthYearChange()">
                                <mat-option *ngFor="let month of months" [value]="month">
                                    {{month}}
                                </mat-option>
                            </mat-select>
                        </div>

                        <div class="year-select">
                            <mat-select class="year-month-select" [(ngModel)]="selectedYear"
                                (ngModelChange)="onMonthYearChange()">
                                <mat-option *ngFor="let year of years" [value]="year">
                                    {{year}}
                                </mat-option>
                            </mat-select>
                        </div>

                    </div>
                    <full-calendar class=" full-calendar" #fullCalendar [options]="calendarOptions"></full-calendar>
                </mat-card>

                <div class="color-legend">
                    <div class="colour-main-div">
                        <div class="Leave colour-div"></div>

                        <div class="colour-div-label">
                            <label>On Leave</label>
                        </div>
                    </div>

                    <div class="colour-main-div">
                        <div class="general colour-div"></div>

                        <div class="colour-div-label">
                            <label>Present</label>
                        </div>
                    </div>

                    <div class="colour-main-div">
                        <div class="Restricted colour-div"></div>

                        <div class="colour-div-label">
                            <label>Regularized</label>
                        </div>
                    </div>

                    <div class="colour-main-div">
                        <div class="Unmarked colour-div"></div>

                        <div class="colour-div-label">
                            <label>Absent</label>
                        </div>
                    </div>

                    <div class="colour-main-div">
                        <div class="Client colour-div"></div>

                        <div class="colour-div-label">
                            <label>Holiday</label>
                        </div>
                    </div>

                </div>
            </div>


            <div class="col-md-5 flex-col">

                <div class="container-3 ">
                    <h2>History</h2>
                    <hr>
                    <div *ngIf="historyTabData.length==0" class="heading heading-1">
                        <div class="heading heading-1">
                            No Attendance History Available
                        </div>
                    </div>
                    <div *ngFor="let history of historyTabData | orderBy:'date'">

                        <div class="flex" *ngIf="history.status=='Pending'">
                            <div class="heading heading-1">
                                {{history.date}}
                                Unmarked Attendants
                            </div>
                            <div class="blue">
                                {{history.status}}
                            </div>
                        </div>

                        <div class="flex" *ngIf="history.status=='Approved'">
                            <div class="heading heading-1">
                                {{history.date}}
                                Unmarked Attendants
                            </div>
                            <div class="green">
                                {{history.status}}
                            </div>

                        </div>
                        <hr>
                    </div>
                </div>

            </div>
        </div>

    </mat-card>
</div>