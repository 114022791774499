import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';

// import { TypeOfLeave } from 'src/app/services/leaveBalances/typeOfLeave-enum';

@Component({
  selector: 'app-configure-employee-leave-dialog',
  templateUrl: './configure-employee-leave-dialog.component.html',
  styleUrls: ['./configure-employee-leave-dialog.component.scss']
})
export class ConfigureEmployeeLeaveDialogComponent implements OnInit {

  leaveConfigureFormGroup: FormGroup;
  leaveTypes: string[] = Object.keys(TypeOfLeave);
  constructor(public dialogRef: MatDialogRef<ConfigureEmployeeLeaveDialogComponent>,
    public fb: FormBuilder,    
    private leaveBalanceService: LeaveBalanceService,
    public toaster: ToastrService,

    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.leaveConfigureFormGroup = this.fb.group({
      leaveType: ['', [Validators.required]],
      creditOrDebit: ['', [Validators.required]],
      numberOfDays: [, [Validators.required,this.multipleOfValidator(0.25)]],
    });
  }

  ngOnInit(): void {
  }


  closeDialog() {
    this.dialogRef.close();
  }
  multipleOfValidator(divisor: number): ValidatorFn {
    return (control: FormControl) => {
      const value = control.value;
      if (value % divisor !== 0) {
        return {
          multipleOf: {
            valid: false,
            divisor: divisor
          }
        };
      }
      return null;
    };
  }
  submit() {
    console.log("sum=bmit");
    // /leave_balances/addLeaveBalanceByHR/{typeOfLeave}/{count}/{userId}/{year}    
    const leaveType = this.leaveConfigureFormGroup.get('leaveType').value;
    const creditOrDebit = this.leaveConfigureFormGroup.get('creditOrDebit').value;
    const numberOfDays = this.leaveConfigureFormGroup.get('numberOfDays').value;  
    const count= creditOrDebit =="credit" ? numberOfDays : numberOfDays*(-1);  
    console.log(this.data.userId);
    console.log(creditOrDebit);
    console.log(count);
    this.leaveBalanceService.addLeaveBalanceByHR(leaveType,count,this.data.userId,this.data.year).subscribe((res) => {
      this.toaster.success('Successful', 'Configured', {
      });
      this.dialogRef.close();
    }, (error) => {
      this.toaster.error('Something went wrong', 'Error', {});
      
      // if (error.status == 409) {
      //   this.toaster.error('Leave already applied in selected dates range.', 'Error', {});
      // } else {
      //   this.toaster.error('Something went wrong. Please try again later.', 'Error', {});
      // }
    })
    
  
  }
}

