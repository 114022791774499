import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SkillService } from 'src/app/services/skill/skill.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { Skill } from 'src/app/models/skill';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-professional-skills',
  templateUrl: './professional-skills.component.html',
  styleUrls: ['./professional-skills.component.scss'],
})
export class ProfessionalSkillsComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  editSpotlightDetails: FormGroup;
  skill: FormArray;
  saved:Boolean = false
  removedSkill = [];
  constructor(
    public userDetailService: UserDetailsService,
    public skillservice: SkillService,
    public fb: FormBuilder,
    public toaster:ToastrService,
    public dialogRef: MatDialogRef<ProfessionalSkillsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {
    this.editSpotlightDetails = this.fb.group({
      skills: this.fb.array([]),
    });
  }

  ngOnInit(): void {

    //this.data will have user id

    this.skillservice
      // .getskillByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .getskillByUserIdSubscribe(this.data)
      .subscribe((res) => {
        if (res.body[0] == null) {
          this.addSkill();
        }
        this.setSkillFormArray(res.body);
      });
  }

  //Add Skill Form starts here
  createskill() {
    this.Skills;
    return this.fb.group(new Skill());
  }
  setSkillFormArray(response) {
    this.skill = this.editSpotlightDetails.get('skills') as FormArray;
    let skillList: Skill[] = this.skillservice.setSkillResponse(response);
    for (let s of skillList) {
      this.skill.push(this.fb.group(s));
    }
  }
  get Skills(): FormArray {
    return this.editSpotlightDetails.get('skills') as FormArray;
  }
  addSkill() {
    // this.disabledskill = false;
    this.skill = this.editSpotlightDetails.get('skills') as FormArray;
    this.skill.insert(0, this.createskill());
  }
  removeskill(id: any, i) {
    this.removedSkill.push(id);
    this.Skills.removeAt(i);
  }

  closeDialog(event:Event) {
   
    this.dialogRef.close({ event: 'Closed'})
  }
  onSubmit() {
    if (this.editSpotlightDetails.valid) {
      let allSkillsValid = true;
  
      for (let skill of this.editSpotlightDetails.value.skills) {
        skill.technology = skill.technology.trim();
        if (skill && !skill.id && skill.technology !== '') {
          // skill.userId = this.userDetailService.getUserDetails().id;
          skill.userId = this.data;
          this.skillservice.create(skill).subscribe((res) => {
            this.dialogRef.close({ event: 'Closed' });
          });
        } else if (skill && skill.id && skill.technology !== '') {
          this.skillservice.update(skill).subscribe((res) => {
            this.dialogRef.close({ event: 'Closed' });
          });
        } else {
          allSkillsValid = false;
        }
      }
  
      if (this.removedSkill.length > 0) {
        for (let i in this.removedSkill) {
          this.skillservice.delete(this.removedSkill[i]).subscribe((res) => {
            this.dialogRef.close({ event: 'Closed' });
          });
        }
      }
  
      if (allSkillsValid) {
        this.toaster.success('Successfully Saved', '');
      }
    }
  }
}  