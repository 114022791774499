import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatGridList, MatGridListModule } from '@angular/material/grid-list';
import { Router } from '@angular/router';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { Project } from 'src/app/models/project';
import { ClientService } from 'src/app/services/client/client.service';
import { ImageService } from 'src/app/services/Images/image.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ProjectService } from 'src/app/services/project.service';
import { AddProjectCardComponent } from '../add-project-card/add-project-card.component';
import { TalentService } from 'src/app/services/talent/talent.service';
import { TransferDetailsService } from 'src/app/services/transferDetails/transfer-details.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserService } from 'src/app/services/user/user.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ProjectCategory } from 'src/app/services/user/user-enum';
import { User } from 'src/app/models/user';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
import { AddTalentComponent } from '../project-detail-view/add-talent/add-talent/add-talent.component';
import { Activity } from 'src/app/services/activity/activity-enum';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { SharePointService } from 'src/app/services/sharePoint/sharePoint.service';
import { HttpClient } from '@angular/common/http';
import { __await } from 'tslib';
import { delay, shareReplay, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { timingSafeEqual } from 'crypto';
import { ButtonStateService } from 'src/app/services/dataSync/sync-data.service';
@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
  providers: [PipeUnderScoreToSpace]
})
export class ProjectCardComponent implements OnInit {
  //@Input() projects
  searchText: string;
  p: number = 1;
  userid: any;
  empDetail: User[];
  projectNameIdMapping: any;
  temp = [];
  nodata: Boolean = false
  isDataPresent = false;
  category: typeof ProjectCategory;
  projectListIfPresent: any;
  todayDate = new Date().toISOString()
  constructor(
    public projectService: ProjectService,
    private observableMedia: MediaObserver,
    public talentservice: TalentService,
    public projectmemberservice: TalentService,
    public clientService: ClientService,
    public transferDetails: TransferDetailsService,
    public imageService: ImageService,
    private dialog: MatDialog,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    public userService: UserService, private userDetailService: UserDetailsService, private talentService: TalentService, private projectservice: ProjectService,
    private sharePointService: SharePointService, private http: HttpClient,private toasterService: ToastrService,private buttonStateService: ButtonStateService
  ) { }
  projectData = new ProjectDTO();
  projectLogo;
  sendClient = []
  breakpoint: number;
  projectList = [];
  clientIdName = {};
  projectidtalent = {};
  projecttalentname = {};
  clientName;
  public projectId;
  userIdName = {};
  public talentData = [];
  talentImages = {}
  map = new Map<Number, String[]>();
  public getScreenWidth: any;
  public flag: any = [];
  public getScreenHeight: any;
  mobileview: boolean = false;
  project_loading = true
  syncButtonDisabled = false;
  tooltipMessage = 'Syncing data... Please wait.';
  positionOptions: TooltipPosition[] = ['below'];
  position = new FormControl(this.positionOptions[0]);
  async ngOnInit(): Promise<void> {
    
    this.callfunc();
    this.category = ProjectCategory
    await this.projectService.projectPresentOrNot();
    this.projectListIfPresent = await this.projectService.resOfFeedBackAfterParse;
    
 
    if (this.projectListIfPresent.length === 0) {
      this.nodata = true
    }
    this.getTalent()
    this.callfunc();
    if (this.projectService.projects.length != 0) {
      this.nodata = false
    }
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 768) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 768) {
      this.mobileview = true;
    }
    this.userid = this.userDetailService.getUserDetails().id;
    this.userService.getUserSubscribe().subscribe(res => {
      this.userService.setUsersDto(res.body);
      this.empDetail = this.userService.usersDto;
    });
    if (this.userService.allUserData.length == 0) {
      this.userService.getAllUser()
    }

    this.buttonStateService.getSyncButtonDisabledState().subscribe(disabled => {
      this.syncButtonDisabled = disabled;
    });
  



    // this.getTeam();
    //  this.getProject();
  }
  // @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 768) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 768) {
      this.mobileview = true;
    }
  }

  
  callfunc() {   

    this.projectService.getProjectPagination();    
    this.projectService.getAllProjects().subscribe((response) => {
     
     
      if (response.length !== 0)

        this.isDataPresent = true;

      if (this.projectService.projects.length != 0) {
        this.nodata = false
      }
      let j = 0
      for (let r in response) {

        if (this.userDetailService.getUserDetails().authorities == "ROLE_SUPER_ADMIN" || "ROLE_PROJECT_ADMIN") {
          this.projectList[r] = response[r];
          
        }
        this.clientService.getClientByIdSubscribe(response[r].clientId).subscribe(resp => {
          this.clientName = resp.body.clientName;
          this.clientIdName[response[r].clientId] = this.clientName;
          this.transferDetails.saveIds(resp.body)
        })
        this.talentservice.getTalentByProjectIdSubscribe(response[r].id).subscribe(res => {
         
         
          this.projectidtalent[response[r].id] = res.body.length;
          this.temp = []
          for (let i of res.body) {
            //this.projecttalentname[j]=i.talentName;j++;

            this.temp.push(i.talentName);
          }
          this.map.set(response[r].id, this.temp);
          j++;
        });
      }
    });

  }
  

  
  

onClickButton(){
  this.buttonStateService.setSyncButtonDisabledState(true);
  this.getSharePointData();
}

getSharePointData() {
 
this.syncButtonDisabled = true;
this.sharePointService.getSharePointData().subscribe((res)=>{


setTimeout(() => {
  this.syncButtonDisabled = false;
  this.toasterService.info("'Data synced successfully.")
  
  setTimeout(() => {
   location.reload();
  }, 2000); 
}, 120000); 

})

}

  isEndDateInFuture(endDate: Date): boolean {

    return endDate > new Date();
  }


  getTalentImages(talentData) {

    for (let talent of talentData) {
      this.userService.getUserThumbnailFromUserId(talent.userId).subscribe(res => {
        if (res.body.userImageThumbnail !== "") {
          this.talentImages[talent.userId] = 'data:image/png;base64,' + res.body.userImageThumbnail;
        }
        else {
          this.talentImages[talent.userId] = "";
        }
      })
    }
  }
  onEdit(project) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '70%';
    dialogConfig.height = '60%';
    dialogConfig.data = project;
    const dialogRef = this.dialog.open(AddProjectCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => { });
  }
  addTalent(project) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      projectId: project.id,
      activityType: Activity.ADD,
      endDate: project.endDate
    }
    const dialogRef = this.dialog.open(AddTalentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit()
    });
  }
  getTalent() {

    this.flag.length = 0;
    let talentlocal = []
    this.talentservice.getTalentByProjectIdSubscribe(this.projectId).subscribe(resptalent => {
      let j = 0;
      for (let i of resptalent.body) {
        talentlocal.push(i)
        let toDaysDate = new Date();
        let endDateInZoneFormat = new Date(i.endDate);
        if ((endDateInZoneFormat) < (toDaysDate)) {
          this.flag.push(false);
        }
        else {
          this.flag.push(true)
        }
        this.userService.getUserByID(i.userId).subscribe(response => {
          this.userIdName[i.userId] = response.body.employeeId;
        })
      }
      this.talentData = talentlocal;

      this.getTalentImages(this.talentData);
    });
  }
  onViewProject(project) {
    this.router.navigate(['project/projectDetailView', project.id]);
  }
  deleteProject(id) {
    this.projectService.delete(id);
  }
  processLogo(project) {
    this.clientService
      .getClientByIdSubscribe(project.clientId)
      .subscribe((res) => {
        return res.body.imageId
          ? this.setProjectLogo(res.body.imageId)
          : 'assets/download.png';
      });
  }
  setProjectLogo(imageId) {
    return this.imageService.getImage(imageId);
  }
}
