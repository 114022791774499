<div class="row">
    <div class="col-md-12 pl-0">
        <mat-card class="mat_card">
            <div class="search_main ">
                <mat-icon class="search-icon  mt-1 mr-4">search</mat-icon>

                <input class="search_input" type="search" placeholder="Search" [(ngModel)]="searchText" value="">

            </div>


            <div class="year-div ">

                <div class="col geolocation-year">
                    <label class="reportee-common ">Geo Location </label>
                    <mat-select (selectionChange)="loadGeoLocation($event)" [(value)]="selectedGeolocation">
                        <mat-option *ngFor="let g of this.geo" [value]="g">
                            {{this.geoLoc[g]}}
                        </mat-option>
                    </mat-select>
                </div>
                <button class="col cs-download" (click)="file()" *ngIf="cmReportDTOList.length!=0">
                    <img src="assets/download.png" class="download-img">
                    <label class="text">
                        Export Report
                    </label>
                </button>

                <div class="col geolocation-year ml-3">
                    <label class="reportee-common ">Year</label>



                    <mat-select [(value)]="selected" (selectionChange)="loadForm($event)">
                        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </div>

            </div>

        </mat-card>

    </div>
   
</div>


<div>

    <div class="card-container" #cardcontainer>
        <div class="col-md-12">
            <div *ngIf="this.selected==y" class=" pie-chart">
                <label class="text2">Overall Report</label>
                <ngx-charts-pie-chart [view]="view" [results]="[
            { name: 'Not Initiated', value: this.notInitiatedPercentage },
            { name: 'Open Count', value:this.openCountPercentage },
            { name: 'Close Count', value: this.closeCountPercentage }
           
            ]" [doughnut]="doughnut" [legend]="showLegend" [legendPosition]="legendPosition" [scheme]="colorScheme">
                </ngx-charts-pie-chart>
            </div>

        </div>




        <div class="matcardprojectdetail " (click)="cmReportee(cmReportDTO.id)"
            *ngFor="let cmReportDTO of cmReportDTOList | filter:searchText  | paginate: { id:'cmReportDTO_id', itemsPerPage: itemsPerPage, currentPage: p }"
            >
            <div>
                <div class="col-name">
                    <div>
                        <img src="assets/default-avatar.jpg" class="img-container">
                    </div>
                    <div class="name-div">
                        <label class="emp-name">
                            {{cmReportDTO.firstName + ' ' + cmReportDTO.lastName}}
                        </label>
                        <div class="emp-id">

                            <label>
                                ID {{cmReportDTO.employeeId}}
                            </label>

                        </div>
                    </div>
                </div>
                <div class=" coloums-div color">
                    <div class="col-3 project-name">
                        Reportees
                    </div>

                    <div class="col-3 count blue">
                        {{cmReportDTO.directReportees.length}}
                    </div>
                </div>
                <div class=" coloums-div ">
                    <div class="col project-name ">
                        Open
                    </div>

                    <div class="col-3 count green">
                        {{cmReportDTO.openCount}}
                    </div>
                </div>
                <div class="coloums-div color ">
                    <div class="col-3  project-name red">
                        Closed
                    </div>

                    <div class="col-3 count">
                        <div class="count red">{{cmReportDTO.closeCount}}</div>
                    </div>
                </div>
                <div class="coloums-div ">
                    <div class="col project-name ">
                        Not Initiated
                    </div>

                    <div class="col-3 count">
                        <div class="count yellow">{{cmReportDTO.notInitiated}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <pagination-controls id="cmReportDTO_id" class="pagination" (pageChange)="p = $event"></pagination-controls>

</div>
