import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CalendarOptions, EventInput } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid';
import { ViewLeavePolicyComponent } from '../view-leave-policy/view-leave-policy.component';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { HolidaysEventService } from 'src/app/services/leaveManagementCalendar/holidays-event.service';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { UnmarkedAttendanceService } from 'src/app/services/UnmarkedAttendance/unmarked-attendance.service';
import * as moment from 'moment';
import { OnLeaveService } from 'src/app/services/onLeave/on-leave.service';
import { ApplyHolidayComponent } from '../apply-holiday/apply-holiday/apply-holiday.component';
import { UserService } from 'src/app/services/user/user.service';
import { MasterTableServiceService } from 'src/app/services/masterTableService/master-table-service.service';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';
@Component({
  selector: 'app-leave-calendar',
  templateUrl: './leave-calendar.component.html',
  styleUrls: ['./leave-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeaveCalendarComponent implements OnInit {
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  leaveBalanceYear: number = new Date().getFullYear();

  constructor(
    public dialog: MatDialog,
    private eventService: HolidaysEventService,
    private leaveBalanceService: LeaveBalanceService,
    private userDetailService: UserDetailsService,
    private unmarkedAttendance: UnmarkedAttendanceService,
    private userService: UserService,
    private masterTableService: MasterTableServiceService
  ) { }

  perYearLeaves: any = '-';
  availableLeaves: any = '-';
  userid: any;
  leavebalanceData: any;
  val: any;

  // FOR AVAILABLE
  paidLeave: any = 0;
  unplannedLeave: any = 0;
  lossOfPay: any = 0;
  compOff: any = 0;
  contigencyLeave: any = 0;
  workFromHome: any = 0;
  paternityLeave: any = 0;
  availableadvancesLeave: any = 0;
  advancedLeave: any = 0;
  restrictedHolidaysLeaves: any = 0;
  maternityLeave: any = 0;
  specialLeave: any = 0;

  // FOR TOTAL 
  paidLeaveTotal: any = 0;
  unplannedLeaveTotal: any = 0;
  lossOfPayTotal: any = 0;
  compOffTotal: any = 0;
  contigencyLeaveTotal: any = 0;
  workFromHomeTotal: any = 0;
  paternityLeaveTotal: any = 0;
  maternityLeaveTotal: any = 0;

  leaveTypeOne: string = 'No Leaves Available';
  leaveTypeTwo: string = 'No Leaves Available';
  amountOfLeaveOne: any = '-';
  amountOfLeaveTwo: any = '-';

  UnmarkStatus: string = 'UNMARKED_ATTENDANCE';
  LeaveStatus: string = 'LEAVE';
  restrictedStatus: string = 'RESTRICTED_HOLIDAY';

  designation: any;


  ngOnInit(): void {
    this.userid = this.userDetailService.getUserDetails().id;
    // this.userService.getUserByID(this.userid).subscribe((res) => {
    //   this.designation = res.body.jobTitle;
    //   this.masterTableService.getMasterTableDataCalendar(res.body.jobTitle).subscribe((result) => {
    //     let data = result.body;
    //     const balanceLeaves: number[] = Object.values(data.balanceLeave);
    //     const totalBalanceLeaves: number = balanceLeaves.reduce(
    //       (a: number, b: number) => a + b
    //     );
    //     this.perYearLeaves = totalBalanceLeaves;

    //   }, (e) => {
    //     console.log(e);
    //   })
    // })


    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }

    this.onLeaveBalanceChange();
    this.apiCallForHolidays();
  }
  eventData: EventInput[] = []
  restrictedHolidays: { date: any, desc: any, originalDate: any }[] = [];


  apiCallForHolidays(): EventInput[] {
    this.eventService.getHolidayEvents(this.userid, this.selectedYear)
      .subscribe(
        (res) => {
          this.eventData = res;
          this.calendarOptions.events = this.eventData;
        }, () => {
          return [];
        });
    // this.eventService.getHolidayEvents(this.selectedYear)
    //   .subscribe(
    //     (res) => {
    //       this.eventData = res;
    //       this.calendarOptions.events = this.eventData;
    //     }, () => {
    //       return [];
    //     });
    return this.eventData;
  }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    initialDate: new Date(),
    //events: this.newEvents,
    weekends: true,
    firstDay: 1,
    datesSet: event => {
      const dateObj = new Date(event.end);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      this.selectedMonth = month == 0 ? 'December' : this.months[month - 1];
      this.selectedYear = month == 0 ? year - 1 : year;

      this.calendarOptions.events = this.apiCallForHolidays();

      this.eventService.getRestictedHolidaysForApplyLeave(this.selectedYear).subscribe(res => {
        this.restrictedHolidays = [];
        const response = res.body.leaveDates;

        for (let i = 0; i < response.length; i++) {
          const dateObj = new Date(response[i].date);
          const monthName = dateObj.toLocaleString('default', { month: 'long' });

          this.eventService.getHolidayStatus(this.userid, { "date": response[i].date }).subscribe(result => {
            if (response[i].calendarLeaveType == this.restrictedStatus && monthName.toLowerCase() == this.selectedMonth.toLowerCase()) {
              const currentHoliday = {
                date: moment(response[i].date).format('ddd, D MMM,'),
                desc: response[i].leaveDescription,
                originalDate: response[i].date,
                status: result.body
              }

              this.restrictedHolidays.push(currentHoliday)
            }
          })

        }
      }, (e) => { })
    },
    headerToolbar: {
      start: '',
      // center: 'title',
      // end: 'today next nextYear'
      end: 'today prev next'
    },
  };


  onMonthYearChange() {
    if (this.fullCalendar) {
      const date = new Date(this.selectedYear, this.months.indexOf(this.selectedMonth), 1);
      this.fullCalendar.getApi().gotoDate(date);
    }
  }

  onLeaveBalanceChange() {
    this.leaveTypeOne = 'No Leaves Available';
    this.leaveTypeTwo = 'No Leaves Available';
    this.amountOfLeaveOne = '-';
    this.amountOfLeaveTwo = '-';

    this.leaveBalanceService.getLeaveBalanceByIdAndYear(this.userid, this.leaveBalanceYear).subscribe(res => {
      this.leavebalanceData = res.body.balanceLeave

      let transactionDataList = res.body.transactionHistory


      let transactionCountMapForAvailed = {}

      let typeOfLeaveValues = Object.values(TypeOfLeave)

      typeOfLeaveValues.forEach((key, index) => {

        let keyEnum = Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(key)]

        transactionCountMapForAvailed[keyEnum] = 0
      });

      // let groupTransactionsByLeaveType = {}
      // let paidLeaveList = new Array()
      // let unplannedLeaveList = new Array()
      // let lossOfPayList = new Array()
      // let compOffList = new Array()
      // let contingencyList = new Array()
      // let maternityList = new Array()
      // let paternityList = new Array()
      // let workFromHomeList = new Array()




      if (transactionDataList != null) {
        transactionDataList.forEach(transactionObj => {
          let transCountMap: any = transactionObj.transactionCount

          for (let typeOfLeaveKey in transCountMap) {

            if (typeOfLeaveKey === Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.PAID_LEAVE)]) {
              // paidLeaveList.push(transactionObj)
              // groupTransactionsByLeaveType[typeOfLeaveKey] = paidLeaveList
              if (transactionObj.transactionType == 'ANNUAL_CREDIT' || transactionObj.transactionType == 'MONTHLY_CREDIT' || transactionObj.transactionType == 'CARRY_FORWARD' || transactionObj.transactionType == 'HR_CREDIT') {
                this.paidLeaveTotal = this.paidLeaveTotal + Math.abs(transCountMap[typeOfLeaveKey])
              }

            } else if (typeOfLeaveKey === Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.UNPLANNED_LEAVE)]) {
              // unplannedLeaveList.push(transactionObj)
              // groupTransactionsByLeaveType[typeOfLeaveKey] = unplannedLeaveList
              if (transactionObj.transactionType == 'ANNUAL_CREDIT' || transactionObj.transactionType == 'MONTHLY_CREDIT' || transactionObj.transactionType == 'CARRY_FORWARD' || transactionObj.transactionType == 'HR_CREDIT') {
                this.unplannedLeaveTotal = this.unplannedLeaveTotal + Math.abs(transCountMap[typeOfLeaveKey])
              }

              // }else if(typeOfLeaveKey === Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.LOSS_OF_PAY)]){
              //   // lossOfPayList.push(transactionObj)
              //   // groupTransactionsByLeaveType[typeOfLeaveKey] = lossOfPayList
              //   if(transactionObj.transactionType == 'ANNUAL_CREDIT' || transactionObj.transactionType == 'MONTHLY_CREDIT' || transactionObj.transactionType == 'CARRY_FORWARD' || transactionObj.transactionType == 'HR_CREDIT'){
              //     this.lossOfPayTotal = this.lossOfPayTotal + Math.abs(transCountMap[typeOfLeaveKey])
              //   }

              // }else if(typeOfLeaveKey === Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.COMP_OFF)]){
              // compOffList.push(transactionObj)
              // groupTransactionsByLeaveType[typeOfLeaveKey] = compOffList
              if (transactionObj.transactionType == 'ANNUAL_CREDIT' || transactionObj.transactionType == 'MONTHLY_CREDIT' || transactionObj.transactionType == 'CARRY_FORWARD' || transactionObj.transactionType == 'HR_CREDIT') {
                this.compOffTotal = this.compOffTotal + Math.abs(transCountMap[typeOfLeaveKey])
              }

            } else if (typeOfLeaveKey === Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.CONTINGENCY_LEAVE)]) {
              // contingencyList.push(transactionObj)
              // groupTransactionsByLeaveType[typeOfLeaveKey] = contingencyList
              if (transactionObj.transactionType == 'ANNUAL_CREDIT' || transactionObj.transactionType == 'MONTHLY_CREDIT' || transactionObj.transactionType == 'CARRY_FORWARD' || transactionObj.transactionType == 'HR_CREDIT') {
                this.contigencyLeaveTotal = this.contigencyLeaveTotal + Math.abs(transCountMap[typeOfLeaveKey])
              }

            } else if (typeOfLeaveKey === Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.MATERNITY_LEAVE)]) {
              // maternityList.push(transactionObj)
              // groupTransactionsByLeaveType[typeOfLeaveKey] = maternityList
              if (transactionObj.transactionType == 'ANNUAL_CREDIT' || transactionObj.transactionType == 'MONTHLY_CREDIT' || transactionObj.transactionType == 'CARRY_FORWARD' || transactionObj.transactionType == 'HR_CREDIT') {
                this.maternityLeaveTotal = this.maternityLeaveTotal + Math.abs(transCountMap[typeOfLeaveKey])
              }

            } else if (typeOfLeaveKey === Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.PATERNITY_LEAVE)]) {
              // paternityList.push(transactionObj)
              // groupTransactionsByLeaveType[typeOfLeaveKey] = paternityList
              if (transactionObj.transactionType == 'ANNUAL_CREDIT' || transactionObj.transactionType == 'MONTHLY_CREDIT' || transactionObj.transactionType == 'CARRY_FORWARD' || transactionObj.transactionType == 'HR_CREDIT') {
                this.paternityLeaveTotal = this.paternityLeaveTotal + Math.abs(transCountMap[typeOfLeaveKey])
              }

            }//else if(typeOfLeaveKey === Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.WORK_FROM_HOME)]){
            //   // workFromHomeList.push(transactionObj)
            //   // groupTransactionsByLeaveType[typeOfLeaveKey] = workFromHomeList
            //   if(transactionObj.transactionType == 'ANNUAL_CREDIT' || transactionObj.transactionType == 'MONTHLY_CREDIT' || transactionObj.transactionType == 'CARRY_FORWARD' || transactionObj.transactionType == 'HR_CREDIT'){
            //     this.workFromHomeTotal = this.workFromHomeTotal + Math.abs(transCountMap[typeOfLeaveKey])
            //   }

            // }



            // let valueInTransactionCount = transCountMap[typeOfLeaveKey]



            // if (valueInTransactionCount < 0) {
            //   let earlierNumber: number = transactionCountMapForAvailed[typeOfLeaveKey]
            //   transactionCountMapForAvailed[typeOfLeaveKey]= ((((-1) * valueInTransactionCount) + earlierNumber))
            // }

          }


          // transCountMap.forEach((valueInTransactionCount: number, typeOfLeaveKey: TypeOfLeave) => {
          //   if (valueInTransactionCount < 0) {
          //     let earlierNumber: number = transactionCountMapForAvailed[typeOfLeaveKey]
          //     transactionCountMapForAvailed[typeOfLeaveKey]= ((((-1) * valueInTransactionCount) + earlierNumber))
          //   }
          // });
        });
      }




      // AVAILABLE LEAVE BALANCE

      this.paidLeave = this.leavebalanceData.PAID_LEAVE;
      this.unplannedLeave = this.leavebalanceData.UNPLANNED_LEAVE;
      this.lossOfPay = this.leavebalanceData.LOSS_OF_PAY;
      this.compOff = this.leavebalanceData.COMP_OFF;
      this.contigencyLeave = this.leavebalanceData.CONTINGENCY_LEAVE;
      this.workFromHome = this.leavebalanceData.WORK_FROM_HOME;
      this.paternityLeave = this.leavebalanceData.PATERNITY_LEAVE;
      this.availableadvancesLeave = this.leavebalanceData.AVAILABLE_ADVANCED_LEAVE;
      this.advancedLeave = this.leavebalanceData.ADVANCED_LEAVE;
      this.restrictedHolidaysLeaves = this.leavebalanceData.RESTRICTED_HOLIDAY;
      this.maternityLeave = this.leavebalanceData.MATERNITY_LEAVE;
      this.specialLeave = this.leavebalanceData.SPECIAL_LEAVE;

      // AVAILED + AVAILABLE = TOTAL

      //this.paidLeaveTotal = this.paidLeave + transactionCountMapForAvailed[Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.PAID_LEAVE)]]
      // this.unplannedLeaveTotal = this.unplannedLeave + transactionCountMapForAvailed[Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.UNPLANNED_LEAVE)]]
      // this.lossOfPayTotal = this.lossOfPay + transactionCountMapForAvailed[Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.LOSS_OF_PAY)]]
      // this.compOffTotal = this.compOff + transactionCountMapForAvailed[Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.COMP_OFF)]]
      // this.contigencyLeaveTotal = this.contigencyLeave + transactionCountMapForAvailed[Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.CONTINGENCY_LEAVE)]]
      // this.workFromHomeTotal = this.workFromHome + transactionCountMapForAvailed[Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.WORK_FROM_HOME)]]
      // this.paternityLeaveTotal = this.paternityLeave + transactionCountMapForAvailed[Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.PATERNITY_LEAVE)]]
      // this.maternityLeaveTotal = this.maternityLeave + transactionCountMapForAvailed[Object.keys(TypeOfLeave)[Object.values(TypeOfLeave).indexOf(TypeOfLeave.MATERNITY_LEAVE)]]


      const leaveTypes = {
        paidLeave: 'Paid Leave',
        unplannedLeave: 'Unplanned Leave',
        compOff: 'Comp Off',
        workFromHome: 'Work from Home',
        lossOfPay: 'Loss of Pay',
        contigencyLeave: 'Contingency Leave',
        paternityLeave: 'Paternity Leave',
        availableadvancesLeave: 'Available Advanced Leave',
        advancedLeave: 'Advanced Leave',
        restrictedHolidaysLeaves: 'Restricted Holiday',
        maternityLeave: 'Maternity Leave',
        specialLeave: 'Special Leave'
      };
      let sum = 0;
      sum += this.unplannedLeave || 0;
      sum += this.lossOfPay || 0;
      sum += this.compOff || 0;
      sum += this.contigencyLeave || 0;
      sum += this.workFromHome || 0;
      sum += this.availableadvancesLeave || 0;
      sum += this.advancedLeave || 0;
      sum += this.restrictedHolidaysLeaves || 0;
      this.availableLeaves = sum;

      for (const l1 in leaveTypes) {
        if (!(this[l1] <= 0)) {
          this.leaveTypeOne = leaveTypes[l1];
          this.amountOfLeaveOne = this[l1];
          for (const l2 in leaveTypes) {
            if (!(this[l2] <= 0) && leaveTypes[l2] != this.leaveTypeOne) {
              this.leaveTypeTwo = leaveTypes[l2];
              this.amountOfLeaveTwo = this[l2];
              break;
            }
          }
          break;
        }
      }
    },
      () => {
        this.leaveTypeOne = 'No Leaves Available'
        this.leaveTypeTwo = 'No Leaves Available'
        this.amountOfLeaveOne = 0;
        this.amountOfLeaveTwo = 0;
        this.paidLeave = 0;
        this.unplannedLeave =  0;
        this.lossOfPay =  0;
        this.compOff =  0;
        this.contigencyLeave =  0;
        this.workFromHome =  0;
        this.paternityLeave = 0;
        this.availableadvancesLeave =  0;
        this.advancedLeave =  0;
        this.restrictedHolidaysLeaves =  0;
        this.maternityLeave =  0;
        this.specialLeave = 0;
      });
  }

  applyRestrictedHoliday(description, date) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '65%';
    dialogConfig.width = '60%';
    dialogConfig.panelClass = "manage-class";
    dialogConfig.data = { holidayDescription: description, holidayDate: date };
    const dialogRef = this.dialog.open(ApplyHolidayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      this.onMonthYearChange();
    });



  }


  openLeavePolicy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '85%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = "manage-class";
    const dialogRef = this.dialog.open(ViewLeavePolicyComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(res => { });
  }

  getCurrentDenominator(): number {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 to get month starting from 1
    return currentMonth < 7 ? 11 : 10;
  }


}

