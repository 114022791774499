export class UserCertificateDTO {
    public id : String
     public documentName: string;
     public docBase :string
     public dateOfIssue:Date
     public dateOfValid:Date
     public userId :string
     constructor() {
         this.userId="";
     }
 }     
 
 // {
 //     "dateOfIssue": "2022-12-21T07:46:51.713Z",
 //     "docBase": "string",
 //     "documentName": "string",
 //     "id": "string",
 //     "userId": "string",
 //     "userLogin": "string",
 //     "validUpto": "2022-12-21T07:46:51.713Z"
 //   }