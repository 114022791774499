<div class="main_header_div">
  <div class="child">
      <h1 class="header_name ">Edit About</h1>
  </div>
  <div>
      <button class="closeButton" (click)="closeDialog($event)">Close</button>
      <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>
  </div>
</div>
<hr>

<div class="container-fluid">
  <div class="row">
      <div class="col-md-12 form_main_div">
          <form [formGroup]="aboutDetails">
              <div class="row">
                  <div class="col-md-12">
                      <mat-form-field appearance="outline" class="example-full-width">
                          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="7" cdkAutosizeMaxRows="15" formControlName="about"></textarea>
                          <mat-error *ngIf="aboutDetails.get('about').hasError('required') && aboutDetails.get('about').touched">
                              This field is required.
                          </mat-error>
                          <mat-error *ngIf="aboutDetails.get('about').hasError('pattern')">
                              Only alphabets and specified special characters are allowed.
                          </mat-error>
                      </mat-form-field>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <div class="submit_button_main">
                          <button class="save_Emp_btn" type="submit" (click)="onSubmit()">
                              <span>Save</span>
                          </button>
                      </div>
                  </div>
              </div>
          </form>
      </div>
  </div>
</div>
