import { Component, OnInit } from '@angular/core';
import { ClientHolidayService } from 'src/app/services/clientHoliday/client-holiday.service';
import { ClientService } from 'src/app/services/client/client.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditHolidayComponent } from '../edit-holiday/edit-holiday.component';

@Component({
  selector: 'app-client-holidays',
  templateUrl: './client-holidays.component.html',
  styleUrls: ['./client-holidays.component.scss']
})
export class ClientHolidaysComponent implements OnInit {
  years: number[] = [];
  selectedYear: number;
  clients:any=[];
  clientDetails: any=[]
  selectedClient:any;
  totalHoliday: any = [];
  daysOfWeek: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  clientDataLoaded = false;

  constructor(public clientHolidaysService:ClientHolidayService,
    public clientService:ClientService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.selectedClient = this.clients[0];
    this.clientService.getClientsSubscribe().subscribe((res)=>{
      const clientRes=res.body;
      for(let i=0;i<clientRes.length;i++) {
        this.clients.push(clientRes[i].clientName)
        this.clientDetails.push(clientRes[i]);
      }
      this.selectedClient=this.clients[0];
      this.clientDataLoaded = true;
      this.onClientYearChange();
    })
 
    this.selectedYear = new Date().getFullYear();
    for (let year = 2030
      ; year >= 2015; year--) {
      this.years.push(year);
    }
    this.onClientYearChange();
  }

  editHoliday(holiday){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "50%";
    dialogConfig.data = {
      existingHoliday: holiday
    };
    const dialogRef = this.dialog.open(EditHolidayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => { 
      this.onClientYearChange();
    });
  }
  onClientYearChange() {
    this.totalHoliday=[];
    var clientId;
    for(let i = 0;i < this.clientDetails.length;i++) {
      if(this.clientDetails[i].clientName === this.selectedClient){
        clientId = this.clientDetails[i].clientId;
      }
    }
    this.clientHolidaysService.getHolidaysByIdandYear(clientId, this.selectedYear, 'CLIENT_HOLIDAY').subscribe((res) => {
      
      for (let i = 0; i < res.body.length; i++) {
        // console.log(res.body[i]);
  
        const today: Date = new Date(res.body[i].date);
        const daysOfWeek: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayOfWeek: string = daysOfWeek[today.getDay()];
  
        const currentLeave = {
          date: res.body[i].date,
          leaveDescription: res.body[i].leaveDescription,
          day: dayOfWeek,
          selectedLeaveType:'CLIENT_HOLIDAY',
          clientId:this.selectedClient,
          id: res.body[i].id,
        };
  
        //console.log(currentLeave);
  
        this.totalHoliday.push(currentLeave);
      }
      // console.log(this.totalHoliday)
    });
  }


  
}