import { Injectable } from '@angular/core';
import { MasterTableDataUrlsService } from '../restAPI/masterTableData/master-table-data-urls.service';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class MasterTableServiceService {

  constructor(private masterTableUrl: MasterTableDataUrlsService,
    private restApiService: RestAPIService,
    ) { }

  getMasterTableDataCalendar(designation){
    let res= this.restApiService.get(
      this.masterTableUrl.getmasterTableDataForCalendar(designation)
    )
    // console.log( this.masterTableUrl.getmasterTableDataForCalendar(designation));
    return res;
  }
  createMasterLeave(payload){
    return this.restApiService.post(
      this.masterTableUrl.createMasterLeaveUrl(),payload
    )
  }

}
