import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserGoalDTO } from 'src/app/dtos/UserGoalDTO';
import { GoalTypeLabel } from 'src/app/services/goalSheet/goal-sheet-enum';
import { SubgoalService } from 'src/app/services/subgoal/subgoal.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';

@Component({
  selector: 'app-view-subgoal',
  templateUrl: './view-subgoal.component.html',
  styleUrls: ['./view-subgoal.component.scss']
})
export class ViewSubgoalComponent implements OnInit {

  goal=[];
  username;
  goalTypeMapping:any;
  usergoaldto:UserGoalDTO;
  constructor(public subgoalservice:SubgoalService,
    public usergoalservice:UserGoalService,
    public userService:UserService,@Inject(MAT_DIALOG_DATA) public usergoalid: any,
    public dialogRef: MatDialogRef<ViewSubgoalComponent>) { }

  ngOnInit(): void {
    this.goalTypeMapping=GoalTypeLabel;
    this.getgoal();
    this.getsubgoal();
  }

  getgoal()
  {
    this.usergoalservice.getGoalByID(this.usergoalid.id).subscribe(res=>{
      this.usergoaldto=res.body
      this.userService.getUserByID(this.usergoaldto.userId).subscribe(response => {
        this.username = response.body.firstName+" "+response.body.lastName;
      })
    })
  }
  getsubgoal()
  {
    let g=[];
    this.subgoalservice.getSubgoalByUserGoal(this.usergoalid.id).subscribe(res=>{
      for(let i of res.body)
      {
      g.push(i)
      } 
    })
  this.goal=g
  }
  closeDialog() {
    this.dialogRef.close()
    this.usergoalservice.filter("");
  }
}
