<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Current Address</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>
   
    </div>
</div>
<hr>
 <div class="container-fluid">
    <div class="row">

        <div class="col-md-12 form_main_div">
            <form [formGroup]="editCorrespondenceDetails">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Adderss Line 1 <span class="contentRed"></span></mat-label>
                            <input matInput formControlName="addressLineOne" autocomplete="off">
                            <mat-error *ngIf="checkError('addressLineOne', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Pincode *<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="pincode" autocomplete="off">
                            <mat-error *ngIf="checkError('pincode', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('pincode', 'pattern')">Required 6 digit number </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>City *<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="city" autocomplete="off">
                            <mat-error *ngIf="checkError('city', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('city', 'pattern')">Invalid City Name</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Adderss Line 2 <span class="contentRed">(Optional)</span></mat-label>
                            <input matInput formControlName="addressLineTwo" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>State *<span class="contentRed"></span></mat-label>
                            <input matInput matInput formControlName="state" autocomplete="off">
                            <mat-error *ngIf="checkError('state', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('state', 'pattern')">Invalid State Name</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Country *<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="country" autocomplete="off">
                            <mat-error *ngIf="checkError('country', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('state', 'pattern')">Invalid Country Name</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div> 