import { Component, Inject, OnInit,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DeleteEmployeeComponent } from '../components/employee/delete-employee/delete-employee.component';
import { FeedbackService } from '../services/feedback/feedback.service';

@Component({
  selector: 'app-reopened-confirmation-screen',
  templateUrl: './reopened-confirmation-screen.component.html',
  styleUrls: ['./reopened-confirmation-screen.component.scss'],
})
export class ReopenedConfirmationScreenComponent implements OnInit {
isProbation:boolean = false;
  constructor(private feedbackService:FeedbackService,    public dialogRef: MatDialogRef<DeleteEmployeeComponent>,
    public toastr: ToastrService
    ,@Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit(): void {
    this.isProbation=this.data[2];
    console.log(this.isProbation);
  }
reopen()
{
  if(this.isProbation){
    this.feedbackService.reopendProbationFeedback(this.data[0],this.data[1]).subscribe(res=>{
       
      this.feedbackService.filter('Reopened-feedback');
      this.toastr.success("Feedback Reopened")
      this.dialogRef.close('Success')

    },error=>{
          
         console.log(error)
    });
  } else{
    this.feedbackService.reopendFeedback(this.data[0],this.data[1]).subscribe(res=>{
       
      this.feedbackService.filter('Reopened-feedback');
      this.toastr.success("Feedback Reopened")
      this.dialogRef.close('Success')


      //this.ngOnInit()
    },error=>{
          
         console.log(error)
    });
  } 
  
}
matDialogClose() {
  this.dialogRef.close('simpleclose')
}
}
