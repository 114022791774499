import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
    
})
export class FamilyDetailsUrlService
{
    private readonly familyDetailsBaseApi = '/api/family-details';
    private readonly user = '/user';
    private readonly userId_param = 'userId';
   constructor()
   {}

   getBaseUrl()
   {
       return this.familyDetailsBaseApi;
   }
  getUserUrl(userId)
  {
    return this.familyDetailsBaseApi + this.user + '?' + this.userId_param + '=' + userId
  }  
  getByFieldUrl(field)
   {
       return this.familyDetailsBaseApi + this.getByField(field);
   }
   private getByField(field){
    return '/'+field;
  }



}