<div class="row  ">
  <div class=" col-lg-4 col-md-6 ">
    <mat-card class=" main-card" *ngIf="this.userAbout.length !== 0 ">
      <div class="header">
        <div>
          <h2>About</h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editAboutDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="aboutscroll">
        <!-- <p class="about">{{this.userdto.about}}</p> -->
        <p class="about">{{this.userAbout}} </p>
      </div>

    </mat-card>

    <!-- <mat-card class="main-card" *ngIf="this.userdto.about.length == 0"> -->
    <mat-card class="main-card" *ngIf="this.userAbout.length == 0">

      <div class="header">
        <div>
          <h2>About</h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editAboutDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>


      <!-- <div *ngIf="this.userDetailService.getUserDetails().about.length == 0" class="no_data_div"> -->
      <div *ngIf="this.userAbout.length == 0" class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-3">No Data Available</label>
      </div>
      <div>
        <label *ngIf="this.userAbout.length !== 0" class="no_data_label">{{this.userAbout}}</label>
      </div>





    </mat-card>
  </div>


  <div class="col-lg-4 col-md-6">
    <mat-card class="main-card" *ngIf="this.skilldto.length !== 0">
      <div class=" header">
        <div>
          <h2>Professional Skills</h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editProfessionalskillsDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="contentscroll">
        <div *ngFor="let e of skilldto" class="professiona_mat_body mt-2 mr-2">
          <div>
            <label class="Professional_name">{{e.technology | titlecase}}</label>
          </div>
          <div>
            <ngb-rating class="ngb-rating" [max]="5" [(rate)]="e.rate" [readonly]="true">
            </ngb-rating>
          </div>
        </div>
      </div>





    </mat-card>
    <!-- <mat-card *ngIf="!this.skills_loading" class="main-card">
      <div class=" header">
        <div>
          <h2>Professional Skills</h2>
        </div>
        <div>
          <mat-icon (click)="editProfessionalskillsDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="main_div">
        <img class="loading_logo" src="assets/loading_logo.png">
        <label class="loading_label mt-4 mb-5">Loading...</label>
      </div>

    </mat-card> -->

    <mat-card class="main-card" *ngIf="this.skilldto.length == 0 ">
      <div class=" header">
        <div>
          <h2>Professional Skills</h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editProfessionalskillsDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>

    </mat-card>
  </div>


  <div class="col-lg-4 col-md-6">
    <mat-card class="main-card" *ngIf="this.preempdto.length !== 0">
      <div class="header">
        <div>
          <h2>Employment History </h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editEmploymentHistoryDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="contentscroll1">
        <div *ngFor="let e of preempdto" class="history_mat_body mt-2 mr-2">
          <div>
            <span class="role_heder">Role</span>
            <br>
            <label class="role_name mt-1">{{e.jobTitle | titlecase}}</label>
          </div>
          <div>
            <span class="role_heder1"> {{e.comapanyName | titlecase}}</span>
            <br>
            <label class="role_name mt-1">{{this.preempexp[e.id]}} </label>
          </div>
        </div>

      </div>

    </mat-card>
    <!-- <mat-card class="main-card" *ngIf="!this.employment_history_loading">
      <div class="header">
        <div>
          <h2>Employment History </h2>
        </div>
        <div>
          <mat-icon (click)="editEmploymentHistoryDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="main_div">
        <img class="loading_logo" src="assets/loading_logo.png">
        <label class="loading_label mt-4 mb-5">Loading...</label>
      </div>
    </mat-card> -->
    <mat-card class="main-card" *ngIf="this.preempdto.length == 0">
      <div class="header">
        <div>
          <h2>Employment History </h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editEmploymentHistoryDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>

    </mat-card>
  </div>

  <div class="col-lg-4 col-md-6 ">
    <mat-card class="main-card" *ngIf="this.educationdetailsdto.length !== 0">
      <div class="header">
        <div>
          <h2>Educational Qualification</h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editEducationalQualificationsDialog()"><img class="edit_logo" src="assets/edit.png">
          </mat-icon>
        </div>
      </div>
      <div class="contentscroll1">

        <div *ngFor="let e of educationdetailsdto" class="history_mat_body">
          <div>
            
            <span class="role_heder">{{ e.yearOfBeginning | date: "yyyy" }} - {{ e.yearofCompletion | date: "yyyy" }}</span>
            <br>
            <br>
            <label class="role_name mt-1">{{ e.courseName | titlecase}}</label>
          </div>
        </div>


      </div>


    </mat-card>
    <!-- <mat-card class="main-card" *ngIf="!this.educational_qualifications_loading">
      <div class="header">
        <div>
          <h2>Educational Qualification</h2>
        </div>
        <div>
          <mat-icon (click)="editEducationalQualificationsDialog()"><img class="edit_logo" src="assets/edit.png">
          </mat-icon>
        </div>
      </div>
      <div class="main_div">
        <img class="loading_logo" src="assets/loading_logo.png">
        <label class="loading_label mt-4 mb-5">Loading...</label>
      </div>


    </mat-card> -->
    <mat-card class="main-card" *ngIf="this.educationdetailsdto.length == 0">
      <div class="header">
        <div>
          <h2>Educational Qualification</h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editEducationalQualificationsDialog()"><img class="edit_logo" src="assets/edit.png">
          </mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>

    </mat-card>
  </div>

  <div class="col-lg-6 col-md-12 ">
    <mat-card class="main-card" *ngIf="this.certificatedto.length !== 0">
      <div class="header">
        <div>
          <h2>Certifications</h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editCertificationsDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="contentscroll2">

        <div class="row mt-2 border_div" *ngFor="let e of certificatedto">
          <div class="col-md-2 ">
            <img class="logo_img" src="assets/certificate_spotlight.png">
          </div>
          <div class="col-md-4 certicate_header_lables">
            <label class="certificate_label">Certificate Name</label>
            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              matTooltip="{{e.documentName}}" class="certificate_name">{{e.documentName}}</label>
          </div>

          <div class="col-md-3">
            <div>
              <label class="certificate_label">Date of Issue</label>
              <label class="certificate_name">{{e.dateOfIssue | date: "dd-MM-yyyy"}}</label>
            </div>

          </div>
          <div class="col-md-3">
            <div>
              <label class="certificate_label">Valid Upto</label>
              <label class="certificate_name">{{e.validUpto | date: "dd-MM-yyyy"}}</label>
            </div>
          </div>

        </div>

      </div>
    </mat-card>
    <mat-card class="main-card" *ngIf="this.certificatedto.length == 0">
      <div class="header">
        <div>
          <h2>Certifications</h2>
        </div>
        <div *ngIf="allowEditToOthers">
          <mat-icon (click)="editCertificationsDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>


      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>

    </mat-card>
    <!-- <mat-card class="main-card" *ngIf="!this.certifications_loading">
      <div class="header">
        <div>
          <h2>Certifications</h2>
        </div>
        <div>
          <mat-icon (click)="editCertificationsDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="main_div">
        <img class="loading_logo" src="assets/loading_logo.png">
        <label class="loading_label mt-4 mb-5">Loading...</label>
      </div>
    </mat-card> -->

  </div>

</div>