import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import { PreviousEmployment } from 'src/app/models/previousEmployment';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EmploymentHistoryComponent } from '../../spot-light/employment-history/employment-history.component';
import { Departments, JobTitles } from 'src/app/services/user/user-enum';
import { PositionDetails } from 'src/app/models/positionDetails';

@Component({
  selector: 'app-position-details',
  templateUrl: './position-details.component.html',
  styleUrls: ['./position-details.component.scss']
})
export class PositionDetailsComponent implements OnInit {
  preEmployment: FormArray;
  editSpotlightDetails: FormGroup;

  removedPreviousemployment = [];
  jobTitles = [];
  userDto
  departments = []
  userid: any;
  isEndDatePresent = [];
  constructor(public userDetailService: UserDetailsService,
    public userService: UserService,

    public toaster: ToastrService,
    public fb: FormBuilder, public empservice: PreviousEmploymentService,
    public dialogRef: MatDialogRef<PositionDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User) {
    this.editSpotlightDetails = this.fb.group({
      previousEmployment: this.fb.array([]),
    });
  }


  ngOnInit(): void {
    //this.data will have userid of current employee
    this.empservice
    this.userDto = this.data;
    this.userid = this.data.id
    this.processEnumValuesToArray();

    this.userService.getpositionHistroy(this.userid).subscribe(res => {


      for (let i of res.body.positionHistory) {

        i.jobTitle = this.getEnumValueByEnumKey(JobTitles, i.jobTitle);
        i.capability = this.getEnumValueByEnumKey(Departments, i.capability);

      }
      if(res.body.positionHistory.length<1){
        this.addPreEmployment();
      }

      this.setPrevempFormArray(res.body.positionHistory)
    })

  }

  createpreEmp() {

    let formGroup = this.fb.group(new PositionDetails());
    formGroup.controls.capability.setValidators([Validators.required]);
    formGroup.controls.jobTitle.setValidators([Validators.required]);
    return formGroup;
  }

  processDate(previousEmployment) {
    let experience;
    if (previousEmployment.controls.startDate.valid && previousEmployment.controls.endDate.valid) {
      let startDate: Date = previousEmployment.controls.startDate.value
      let endDate: Date = previousEmployment.controls.endDate.value
      let diff = endDate.getTime() - startDate.getTime()
      let days = diff / (1000 * 60 * 60 * 24)
      let year = this.getYearFromDays(days);
      let months = this.getMonthFromDays(days)
      experience = year > 0 ? year + ' Year' : ''
      experience += months > 0 ? months + ' Months' : ''
      if (months < 1 && year < 1) {
        experience = "< 1 Month"
      }
    }
    return experience
  }

  processEnumValuesToArray() {
    for (let jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle)))
        this.jobTitles.push(jobTitle)
    }
    for (let department of Object.values(Departments)) {
      if (isNaN(Number(department)))
        this.departments.push(department)
    }
  }
  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30)
  }
  getYearFromDays(days) {
    return Math.floor(days / 365)
  }
  setPrevempFormArray(response) {
    let data: number;

    this.preEmployment = this.editSpotlightDetails.get(
      'previousEmployment'
    ) as FormArray;
    let empList: PositionDetails[] =
      this.empservice.setpostionDetails(response);
    for (let s of empList) {

      if (s.positionEndDate == null) {
        this.isEndDatePresent.push(false);
      }
      else {
        this.isEndDatePresent.push(true);
      }
      let formGroup: FormGroup = this.fb.group(s);

      // formGroup.controls.experiencemonth.setValue(this.processDate(formGroup));
      this.preEmployment.push(formGroup);
    }
  }
  // addEvent(type: string, previousEmployment: FormGroup) {
  //   previousEmployment.controls.experiencemonth.setValue(
  //     this.processDate(previousEmployment)
  //   );
  // }
  PreviousEmp(): FormArray {
    return this.editSpotlightDetails.get('previousEmployment') as FormArray;
  }
  addPreEmployment() {

    this.preEmployment = this.editSpotlightDetails.get(
      'previousEmployment'
    ) as FormArray;
    this.preEmployment.insert(0, this.createpreEmp());
  }
  removePreEmployment(id, i) {
    this.removedPreviousemployment.push(id);
    this.PreviousEmp().removeAt(i);
  }

  closeDialog(event: Event) {
    this.dialogRef.close({ event: 'Closed' })
  }

  onSubmit() {
    let data = [];
    if (this.editSpotlightDetails.valid) {
      for (let prevemp of this.editSpotlightDetails.value.previousEmployment) {

        var obj = {};

        obj['startDate'] = prevemp.positionStartDate;
        if (prevemp.positionEndDate == null) {
          obj['endDate'] = '';

        }
        else
          obj['endDate'] = prevemp.positionEndDate;

        obj['jobTitle'] = this.getEnumKeyByEnumValue(JobTitles, prevemp.jobTitle)
        obj['capability'] = this.getEnumKeyByEnumValue(Departments, prevemp.capability)
        data.push(obj)
      }

      this.userDto['positionHistory'] = data;
      this.userService.setpositionHistroy(this.userid, this.userDto).subscribe(res => {
        this.dialogRef.close({ event: 'success' })
      })
    }
    //    if (this.editSpotlightDetails.valid) {
    //   for (let prevemp of this.editSpotlightDetails.value.previousEmployment) {
    //     if (
    //       prevemp &&
    //       !prevemp.id &&
    //       prevemp.comapanyName != null &&
    //       prevemp.jobTitle != null &&
    //       prevemp.startDate != undefined &&
    //       prevemp.endDate != undefined
    //     ) {
    //       // prevemp.userId = this.userDetailService.getUserDetails().id;
    //       prevemp.userId = this.data;
    //       this.empservice.create(prevemp).subscribe((res) => {
    //         // this.toaster.success("Successfully Saved")
    //         this.dialogRef.close({ event: 'Closed'})
    //        // this.notificationService.sucess('done');
    //       });
    //     } else if (
    //       prevemp &&
    //       prevemp.id &&
    //       prevemp.comapanyName != null &&
    //       prevemp.jobTitle != null &&
    //       prevemp.startDate != undefined &&
    //       prevemp.endDate != undefined
    //     ) {
    //       this.empservice.update(prevemp).subscribe((res) => {
    //         // this.toaster.success("Successfully Saved")
    //         this.dialogRef.close({ event: 'Closed'})
    //        // this.notificationService.sucess('Updated');
    //       });
    //     }
    //   }
    // }
    // if(this.removedPreviousemployment.length > 0){
    // for (let i in this.removedPreviousemployment) {
    //   this.empservice.delete(this.removedPreviousemployment[i]);
    //   // this.toaster.success("Successfully Saved")
    //   this.dialogRef.close({ event: 'Closed'})
    // }
    // }
    // this.toaster.success("Successfully Saved")
    // }
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }
}
