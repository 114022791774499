import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { PreviousEmploymentService } from 'src/app/services/previousEmployment/previous-employment.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { Departments, JobTitles } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { __await } from 'tslib';
import { SpotLightComponent } from './spot-light/spot-light.component';
import { Subscription } from 'rxjs';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';

@Component({
  selector: 'app-profile-employee',
  templateUrl: './profile-employee.component.html',
  styleUrls: ['./profile-employee.component.scss'],
})
export class ProfileEmployeeComponent implements OnInit {
  
  @ViewChild('spotLight') spotLightComponent: SpotLightComponent;
  user_details = new UserDTO();
  userDtos: User[] = [];
  imageform: FormGroup;
  isrelieved;
  exp;
  hasRoleBased: boolean;
  hasRoleBased2: boolean;
  userid;
  firstName;
  lastName;
  employeeId;
  department: any;
  jobTitle: any;
  totalexperienceinBA: any;
  joiningdate: any;
  totaltimeinBA: any;
  remainingdays: any;
  remainmonths: number;
  isImageSaved: boolean;

  // userDto = new UserDTO();
  userDto: any;
  cardImageBase64: string;
  imageError: string;
  photo: any;
  totalDaysinBA;
  saved: Boolean = false;
  base64textString: any;
  binaryImgString: string;
  careermanager: any;
  loggedInProfile: any;
  data2: string;
  data: any;
  careerManagerIdName: any;
  maindata: number = 0;
  userDetails: any;
  loading_data = false;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  private dataSubscription: Subscription;
  matTabsClickedIndex: any;
  overAllExperince:any=0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public userService: UserService,
    public empservice: PreviousEmploymentService,
    public userDetailService: UserDetailsService,
    public fb: FormBuilder,
    public apiService: ApiService,
    private feedbackService :FeedbackService

  )
   {

    this.dataSubscription = this.feedbackService.data$.subscribe(data => {
      this.userid = data;
      if(data) this.ngOnInit();
      
    });
    this.imageform = this.fb.group({ imageBase: [''] });
    this.empservice.getexperience().subscribe(res=>
      {
        debugger
        if(res=='refresh_experience')
        {
          this.overallexperience();
        }
      })
  }
  ngOnInit() {

    if(this.userid == null)
    this.userid = this.route.snapshot.paramMap.get('id');
    
    if (this.userid == null) {
      this.userid = this.userDetailService.getUserDetails().id;
      this.loggedInProfile = true;
    } else {
      this.loggedInProfile = false;
    }
    if(this.userid==this.userDetailService.getUserDetails().id)
       {
        this.loggedInProfile=true;
       }
    this.userService;
    this.department = Departments;
    this.jobTitle = JobTitles;
    this.maindata = 0;

    //this.userid = this.userDetailService.getUserDetails().id

    this.userService.getUserByID(this.userid).subscribe((res) => {
      this.loading_data = true;
      this.user_details = res.body;
      this.userDto = this.user_details;
      this.userDetailService.hasRole(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])?(this.loggedInProfile=true):((this.userid==this.userDetailService.getUserDetails().id)?this.loggedInProfile=true:this.loggedInProfile=false);;

      if (this.userDto.userImageThumbnail !== '') {
        this.userDto.userImageThumbnail =
          'data:image/png;base64,' + this.userDto.userImageThumbnail;
      }
      this.userService
        .getUserByID(this.user_details.careerManagerId)
        .subscribe((res) => {
          this.careermanager = res.body.firstName + ' ' + res.body.lastName;
        });

      this.joiningdate = this.userDto.joiningDate;
      
    });

    this.hasRoleBased = this.userDetailService.hasRole(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']);

    if (this.userid != null) {
      this.userService.getUserRelievedStatus(this.userid).subscribe((res) => {
        this.isrelieved = res.body;
      });
    }
  

    this.setFormData();

      this.overallexperience();
  }

  onTabChanged($event) {
    this.matTabsClickedIndex = $event.index;

    this.spotLightComponent.ngOnInit();

  }
  changeRelievedStatus() {
    this.userService
      .changeRelievedStatus(this.user_details.id)
      .subscribe(() => {
        this.ngOnInit();
      });
  }

  setFormData() {
    if (this.loggedInProfile) {
      this.userDetails = null;

      this.userDetails = this.userDetailService.getUserDetails();

      if (this.userDetails.userImageThumbnail !== '') {
        this.photo = true;
      } else {
        this.photo = false;
      }
    } else {
      this.userDto = this.user_details;
    }

  }

  dummyfunc(a) {
    this.maindata += a;
  }
  
  backToEmployees() {
    this.router.navigate(['employee']);
  }

  //code for calculating overall experience starts her

  overallexperience() {
    this.overAllExperince=0;
    let totalYears: number = 0;
    let totalMonths: number = 0;
    this.empservice.getprevempByUserIdSubscribe(this.userid).subscribe((res) => {
      for (let r of res.body) {
        const startDate = new Date(r.startDate);
        const endDate = new Date(r.endDate);
        
        let yearsDiff = endDate.getFullYear() - startDate.getFullYear();
        let monthsDiff = endDate.getMonth() - startDate.getMonth();
        
        if (monthsDiff < 0) {
          monthsDiff += 12;
          yearsDiff--;
        }
  
        totalYears += yearsDiff;
        totalMonths += monthsDiff;
      }
  
      const currentStartDate = new Date(this.user_details.fgJoiningDate?this.user_details.fgJoiningDate:this.user_details.joiningDate);
      const currentDate1 = new Date();
      let currentYears = currentDate1.getFullYear() - currentStartDate.getFullYear();
      let currentMonths = currentDate1.getMonth() - currentStartDate.getMonth();
      
      if (currentMonths < 0) {
        currentMonths += 12;
        currentYears--;
      }
  
      totalYears += currentYears;
      totalMonths += currentMonths;
  
      if (totalMonths >= 12) {
        const extraYears = Math.floor(totalMonths / 12);
        totalYears += extraYears;
        totalMonths -= extraYears * 12;
      }
  
      this.overAllExperince = `${totalYears} ${totalYears==1?'year':'years'} and ${totalMonths} ${totalMonths==1?'month':'months'}`;
    });
  }
  

  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }
  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
}
