<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Calendar Update and Configuration</h1>
    </div>
    <div>
        <!-- <button class="closeButton" (click)="closeDialog()">Close</button> -->
        <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>

    </div>
</div>
<!-- <hr class="top_line"> -->

<div class="file_select_page" [hidden]="!flags.flag1">
    <div>
        <img *ngIf="!fileName && !badFileFormat " class="bulk_import_img" src="assets/bulk_import.png">
    </div>
    <div>
        <label [hidden]="!flags.flag11" *ngIf="!fileName && !badFileFormat" class="file_name">Make sure you select the
            excel .xlsx file </label>
        </div>

            <div>
        <label [hidden]="!flags.flag12" *ngIf="!fileName && !badFileFormat" class="file_error_text">{{excelErrorText}}
        </label>
        <label *ngIf="!fileName && !loading" class="rectangle_lines center"
            (click)="fileInput.click()">{{this.uploadBtnLabel}}</label>
        <input type="file" id="my_file" [(ngModel)]="this.fileName" style="display: none;" accept=".xlsx"
            (change)="onFileChange($event)" #fileInput />
    </div>

</div>
<div class="main_class2" *ngIf="flags.flag2">

    <!-- <input type="file" id="my_file" [(ngModel)]="this.fileName" style="display: none;" accept=".xlsx"
        (change)="readFile($event.target.files)" #fileInput /> -->

    <!-- Display file name is file is selected, else display no file uploaded.
    Display upload button only when a file is selected and upon clicking it, 
    invoke uploadFile function 
    -->
    <div class="excel_edit_page" >
        <div [hidden]="!flags.flag21">
            <form [formGroup]="myExcel">
                <div class="excel_top row">
                    <div class="col-6 month">
                        <!-- <span>{{ month }} - {{ year }}</span> -->
                        <span>{{ monthYearDate | date:'MMMM-yyyy' }}</span>
                        <!-- <input matInput formControlName="monthYear" [value]="(monthYear.value | date:'MMMM-yyyy')?.toString()"> -->

                    </div>
                    <div class="col-6 text_right">
                        <!-- [ngModel]="clients[0]" (selectionChange)="setHeadingField($event.value)" (ngModelChange)="handleInputChange($event)"-->
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Client<span class="contentRed"></span></mat-label>
                            <!-- <mat-select formControlName="clientSelected" [ngModel]="clients[0]" (ngModelChange)="setMyExcelForm($event.value)"> -->
                            <mat-select formControlName="clientSelected">
                                <mat-option *ngFor="let client of clients" [value]="client">{{client}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div formArrayName="holidaysForm" *ngFor="let holiday of holidayFormArray.controls; let i = index">

                    <div [formGroupName]="i" *ngIf="holiday.get('clientID').value === myExcel.get('clientSelected').value && 
                holiday.get('date').value.getMonth()===monthYearDate.getMonth()">
                        <div class="main-holiday-row">
                            <div class="holidayDetailBox col-md-6 ">
                                <mat-checkbox formControlName="isChecked" class=" custom-checkbox" >

                                </mat-checkbox>
                                <mat-form-field appearance="none" class="example-full-width">
                                    <input matInput disabled formControlName="holidayDetails"
                                        autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="holidayTypeBox col-md-6">
                                <mat-form-field appearance="none" class="example-full-width ">
                                    <mat-select formControlName="holidayType">
                                        <mat-option *ngFor="let option of holidaysType" [value]="option">{{option |
                                            titlecase |
                                            underScoreToSpace}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- hidden items -->
                        <div hidden>
                            <mat-form-field appearance="outline" class="example-full-width">
                                <input matInput formControlName="date" autocomplete="off">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="example-full-width">
                                <input matInput formControlName="clientID" autocomplete="off">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="example-full-width">
                                <input matInput formControlName="description" autocomplete="off">
                            </mat-form-field>
                        </div>

                    </div>
                </div>
            </form>
            <div class="main_arrow_div">
                
                <div class="arrow_btns">
                    <button class="arrow_btn" (click)="changeMonthYear(-1)">
                        <i class='fas fa-angle-double-left'></i>
                        <!-- <span class="upload_btn_name">back</span>  -->
                    </button>

                    <button class="arrow_btn2" (click)="changeMonthYear(1)">

                        <i class='fas fa-angle-double-right'></i>

                    </button>

                </div>
            </div>
            <!-- <div class="col-6">
                    <label *ngIf="hidebutton" class="file_name"> {{fileName}}</label>
                </div> -->
            <div class="d-flex  justify-content-end ">
                <button class="upload_btn" (click)="flags.setFlag(false,false,false,true,false,true)">
                    <span class="upload_btn_name">Upload</span> </button>
            </div>

        </div>
        
    </div>
    <div class="areUSure_page" [hidden]="!flags.flag22">

        <img class="file_import_img" src="assets/file_logo.png">
        <label class="file_name"> {{fileName}}</label>

        <!-- <div *ngIf="flags.flag3">
            <button class="upload_btn" (click)="flags.setFlag(false,true,false,true)"> <span class="upload_btn_name">Upload</span> </button>
        </div> -->
        
            <label class="confirmation_text">Are you sure you want to import the Holidays?</label>

            <div class="button_flex">
                <button class="yes-btn mr-2" (click)="convertFormtoExcel()">Yes
                </button>

                <button class="no-btn ml-2" (click)="closeDialog()">No
                </button>

            </div>
        
    </div>


</div>