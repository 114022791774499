import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AttendanceUrlService {

  private readonly attendanceBaseApi = '/api/attendances'
  private readonly attendanceDateStatusApi = '/date/status';
  private readonly attendanceStatus_param = 'attendanceStatus'
  private readonly reporteeAttendanceStatus = '/api/attendances/user/date'
  private readonly date_param = 'date'
  attendanceStatus: string;
  constructor() { }

  getAttendanceUrl(attendanceStatus, date)
  //{http://localhost:8080/api/attendances/date/status?attendanceStatus=PRESENT&date=2022-06-14
  {
    return this.attendanceBaseApi + this.attendanceDateStatusApi + '?' + this.attendanceStatus_param + '=' + attendanceStatus
      + '&' + this.date_param + '=' + date
  }

  getAttendanceByIdUrl(id) {
    return this.attendanceBaseApi + "/" + id;
  }

  getCurrentDayAttendance(userId, date) {
    return this.reporteeAttendanceStatus + '/' + userId + '/' + date
  }
}