<div class="container-fluid ">
    <div class="main_header_div">
        <div class="child">
            <label class="header_name">Add Real Time Feedback</label>
            <label class="reporteename">

            </label>
        </div>

        <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
            <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
        </div>
    </div>
    <hr class="hr-row">
    <div>
        <div *ngFor="let feedback of this.realTimeFeedback" class="col-12 divrow">
            <div class="col-11" >
                <div class="main-div">
                    <div class="chkbx">
                        <input type="checkbox" (change)="onChange(feedback, $event.target.checked)"   />
                    </div>
                    <div class="col-1">
                        <img src="assets/default-avatar.jpg" alt="" class="userLogo">
                    </div>
                    <div  class="col-2 userName">
                        {{ this.userIdDetailsMapping[feedback.createdBy].firstName }}
                    </div>
                    <div class="col-3 divcol">
                        <div class="title">Feedback Title</div>
                        <div>{{ feedback.title }}</div>
                    </div>
                    <div class="col-2 divcol">
                        <div>Score</div>
                        <div class="value">{{ feedback.feedbackFrom[0].score }}</div>
                    </div>
                    <div class="col-3 divcol ">
                        <div>Role</div>
                        <div class="value">{{ feedback.feedbackFrom[0].feedbackRole  | underScoreToSpace| titlecase }}</div>
                    </div>
                </div>
            </div>
            <div class="col-1 div-view">
                <button class="btn-view" (click)="view(feedback.feedbackFrom[0])">view</button>
            </div>
        </div>
        <div class="col-12 div-add">
            <button (click)="onSubmit()" class="btn-add">Add</button>
        </div>
    </div>
</div>