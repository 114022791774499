interface ISkill {
    technology: string;
    levelId : string
    userId: string
    rate :Number
    id:string
}
export class Skill {
    public technology: String;
    public levelId : string
    public  userId: String
    public rate :Number
    public id :string
    constructor(){
        this.id = undefined
        this.technology = ''
        this.levelId = undefined
        this.userId = undefined
        this.rate = 0
    }
    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.technology = httpResponse && httpResponse.technology || undefined
        this.levelId = httpResponse && httpResponse.levelId || undefined
        this.userId = httpResponse && httpResponse.userId || undefined
        this.rate = httpResponse && httpResponse.rate || undefined  
        return this;     
    }
}