<div class="form-outerdiv">
    <div class=" main-header-div ">
        <div class="col-md-3 caption">
            <img class="upload_img" src="assets/BA-logo.png" alt="">
        </div>
        <div class="col-md-2 caption">
            <label>Review For</label>
            <label class="name">{{this.dataActual[2]}}</label>
        </div>
        <div class="col-md-2 caption">
            <label>Career Manager</label>
            <label class="name">{{this.dataActual[4]}}</label>
        </div>
        <div class="col-md-2 caption ">
            <label>Time Frame</label>
            <label class="name">{{this.dataActual[5]}}</label>
        </div>
        <div class="col-md-2 caption ">
            <label>Score</label>
            <label class="name">{{(this.totalScore/this.numberOfQuestion) | number:'1.2-2'}}/5</label>
        </div>
        <button class="close_label" (click)="closeDialog()">
            Close
        </button>
        <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
    <div class="emp-display">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <table class="mobile-emp-details">
                    <tr>
                        <td class="w-1"> Review For</td>
                        <td class="w-2"><label class="m-lbl ml-2">{{this.dataActual[2]}}</label>
                        </td>
                    </tr>
                    <tr>
                        <td class="w-1">Career Manager</td>
                        <td class="w-2"><label class="m-lbl">{{this.dataActual[4]}}</label></td>
                    </tr>
                    <tr>
                        <td class="w-1">Time Frame</td>
                        <td class="w-2"><label class="m-lbl">{{this.dataActual[5]}}</label></td>
                    </tr>
                    <tr *ngIf="!this.isReverseProbation">
                        <td class="w-1">Score</td>
                        <td class="w-2"><label class="m-lbl">{{(this.totalScore/this.numberOfQuestion) |
                                number:'1.2-2'}}/5</label></td>
                    </tr>
                </table>
            </div>
        </mat-expansion-panel>
    </div>
    <ng-container *ngIf="dataActual[1]">
        <div class="dynamic_form_alignment" id="formAlignment" #formAlignment (scroll)="scrollMe($event)" tabindex="-1">
            <label class="jsonlen">{{inc+1}}/{{this.jsonObjectData.length}}</label>
            <app-dynamic-form [parentData]="this.jsonObjectData[inc]" [isDisable]="dataActual[1]" id="dynamicform"
                (someEvent)="recalculateScore($event)" #dynamicform>
            </app-dynamic-form>
        </div>
        <div class="scroll-btn" *ngIf="showScrollText == true" id="scrollBtn">Scroll For More <i
                class="fas fa-arrow-down"></i></div>
        <div class="form-modal-footer">
            <div class="arrow_alignment">

                <div class="previous">
                    <button class="arrow" *ngIf="this.inc > 0" (click)="onEditPrevious()">
                        <i class="fas fa-arrow-left"></i></button>
                </div>
                <div class="next">
                    <button class="arrow" *ngIf="this.inc <this.jsonObjectData.length-1" (click)="onEditNext()">
                        <i class="fas fa-arrow-right"></i></button>
                </div>

                <div class="save-btns" *ngIf="!isProbation">
                    <div *ngIf="!this.isReverseProbation">
                        <div *ngIf="this.jsonObjectData[inc].hasOwnProperty('attributes') && this.dataActual[1]">
                            <button class="btn1 save" (click)="save1()">Save & Next</button>
                        </div>
                        <div *ngIf="!this.jsonObjectData[inc].hasOwnProperty('attributes') && this.dataActual[1]">
                            <button class="btn1 save mr-4" (click)="save()">Save as Draft</button>
                        </div>
                    </div>

                    <div *ngIf="this.isDisabled">
                        <div *ngIf="!this.jsonObjectData[inc].hasOwnProperty('attributes') && this.dataActual[1]">
                            <button class="btn1 submit" type="submit"
                                [ngClass]="(this.isDisabled) ? 'submit' : 'submit-disabled'"
                                (click)="send()">Submit</button>
                        </div>
                    </div>

                    <div *ngIf="!this.isDisabled">
                        <div *ngIf="!this.jsonObjectData[inc].hasOwnProperty('attributes') && this.dataActual[1]">
                            <button class="btn1 submit" type="submit"
                                [ngClass]="(this.isDisabled) ? 'submit' : 'submit-disabled'"
                                (click)="send1()">Submit</button>
                        </div>
                    </div>
                </div>
             
                <div class="save-btns" *ngIf="isProbation">
                    <button class="btn1 submit" type="submit"
                        [ngClass]="(this.isDisabled) ? 'submit' : 'submit-disabled'"
                        (click)="sendProbationFeedback()">Submit</button>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="dynamic_form_view_alignment">
        <app-dynamic-form-view [parentData]="this.jsonObjectData" *ngIf="!dataActual[1]"></app-dynamic-form-view>
    </div>
</div>