import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { LeaveBalanceDTO } from 'src/app/dtos/LeaveBalanceDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ToastrService } from 'ngx-toastr';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
@Component({
  selector: 'app-leave-configuration',
  templateUrl: './leave-configuration.component.html',
  styleUrls: ['./leave-configuration.component.scss']
})

export class LeaveConfigurationComponent implements OnInit {
  newLeaveConfiguration: FormGroup;
  selectedLeaveType: string;
  maternityLeaveValue: number;
  compOffLeavedays: number[] = []; 
  searchText: string;
  page: number = 1;
  years: number[] = [];
  selectedYear: number = new Date().getFullYear();

  leavetypes = ['Maternity Leave', 'Comp Off','Paternity Leave','Advance Available Leave'];
  cmId: any;
  reportee: any;
  countOfDays:any;
  remark: any;
  leavebalanceData: any;



  constructor(
    public fb: FormBuilder,
    public feedbackgroupservice: NewFeedbackGroupService,
    public dialogRef: MatDialogRef<LeaveConfigurationComponent>,
    public userDetailsService: UserDetailsService,
    public toaster: ToastrService,
    private leaveBalanceService: LeaveBalanceService,
    public leaveConfigurationService: LeaveApprovalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.newLeaveConfiguration = this.fb.group({
      year: ['', [Validators.required]],
      leaveType: ['', [Validators.required]],
      compOffDay: ['', [Validators.required]],
      remark: ['', [Validators.required]]
    });
  }


  ngOnInit(): void {
    this.reportee = this.data.reportee; 
    console.log(this.reportee)
    this.cmId = this.userDetailsService.getUserDetails().id;
    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }

    const currentDay = 0;
    for (let i = 1; i < currentDay + 60; i++) {
      this.compOffLeavedays.push(i);
    }
    console.log(typeof(this.reportee.compOff))

  }

  onSubmit() {
    
    // Create an instance of LeaveBalanceDTO and set its properties
    const leaveBalanceDTO = new LeaveBalanceDTO();
    leaveBalanceDTO.userId = this.reportee.reporteeId;
    leaveBalanceDTO.year = this.newLeaveConfiguration.get('year').value;
    leaveBalanceDTO.id = this.reportee.leaveBalanceId;
    this.remark = this.newLeaveConfiguration.get('remark').value;
    console.log(this.remark);

    this.leaveBalanceService.getLeaveBalanceByIdAndYear(this.reportee.reporteeId, this.newLeaveConfiguration.get('year').value)
    .subscribe(res => {
      this.leavebalanceData = res.body.balanceLeave;
      console.log(this.leavebalanceData);

      // Set balanceLeave based on the selected leave type
      const selectedLeaveType = this.newLeaveConfiguration.get('leaveType').value;
      switch (selectedLeaveType) {
        case 'Maternity Leave':
          leaveBalanceDTO.balanceLeave = {
            "MATERNITY_LEAVE": this.newLeaveConfiguration.get('compOffDay').value + (this.leavebalanceData?.MATERNITY_LEAVE || 0)
          };
          break;
        case 'Comp Off':
          leaveBalanceDTO.balanceLeave = {
            "COMP_OFF": this.newLeaveConfiguration.get('compOffDay').value + (this.leavebalanceData?.COMP_OFF || 0)
          };
          break;
        case 'Paternity Leave':
          leaveBalanceDTO.balanceLeave = {
            "PATERNITY_LEAVE": this.newLeaveConfiguration.get('compOffDay').value + (this.leavebalanceData?.PATERNITY_LEAVE || 0)
          };
          break;
        case 'Advance Available Leave':
          leaveBalanceDTO.balanceLeave = {
            "AVAILABLE_ADVANCED_LEAVE": this.newLeaveConfiguration.get('compOffDay').value + (this.leavebalanceData?.AVAILABLE_ADVANCED_LEAVE || 0)
          };
          break;
        default:
          leaveBalanceDTO.balanceLeave = 0;
          break;
      }

      console.log(leaveBalanceDTO);

      this.leaveConfigurationService.leaveConfigurationByCm(leaveBalanceDTO, this.cmId, this.remark)
        .subscribe(response => {
          this.toaster.success('Successfully Applied', 'Saved', {});
          this.dialogRef.close();
        });
    }, () => {});
  }

  closeDialog(event: Event) {
    this.dialogRef.close({ event: 'Closed' })
  }

}