import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { cpuUsage } from 'process';
import { UserService } from 'src/app/services/user/user.service';

import { UserDetailsService } from 'src/app/services/user-details.service';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss'],
})
export class UpcomingEventsComponent implements OnInit {
  empDetail = [];
  Birthdaylist = [];
  Anniversarylist = [];
  userThumbnails = [];
  workexp = {};
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  image = [];
  overall = 0;
  todaydate = new Date();
  constructor(
    private breakpointObserver: BreakpointObserver,
    public userDetailService: UserDetailsService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.getUserDetails().subscribe((res) => {
      this.userService.setUsersDto(res.body);

      this.empDetail = this.userService.usersDto;
      this.empDetail.forEach((element) => {
        if ((element.dateOfBirth !== null && element.userImageThumbnail !== null) || element.userImageThumbnail === null) {
          let day = element.dateOfBirth;
          let date = new Date(day);
          let date1 = new Date();

          if (date.getDate() === date1.getDate() &&date.getMonth() === date1.getMonth()) {
            if (element.userImageThumbnail !== null) {
              this.image.push(true);
              this.Birthdaylist.push(element);
            } else {
              this.image.push(false);
              this.Birthdaylist.push(element);
            }
          }
        }

        if ((element.joiningDate !== null &&  element.userImageThumbnail !== null) || element.userImageThumbnail === null) {
          let day2 = element.joiningDate;
          let date2 = new Date(day2);
          let date3 = new Date();

          if (date2.getDate() >= date3.getDate() && date2.getMonth() === date3.getMonth()) {
            this.Anniversarylist.push({
              firstName: element.firstName,
              lastName: element.lastName,
              userImageThumbnail: element.userImageThumbnail,
              message: this.processDate(element.joiningDate),
              joiningDate: element.joiningDate,
              isImage: element.userImageThumbnail !== null
            });
            //console.log(this.Anniversarylist)
            // if (element.userImageThumbnail !== null) {
            //   this.image.push(true);
            //   //this.Anniversarylist.push(element);
            // } else {
            //   this.image.push(false);
            //   //this.Anniversarylist.push(element);
            // }
          }
        }
        // let i = 0;
        // for (let r of res.body) {
        //   this.workexp[i] = this.processDate(r);

        //   i++;
        // }
      });

      this.Anniversarylist.sort((a, b) => {
        const dateA = new Date(a.joiningDate);
        const dateB = new Date(b.joiningDate);
        return dateA.getDate() - dateB.getDate();
      });

      console.log(this.Anniversarylist)
    });

    if (this.userService.allUserData.length == 0) {
      this.userService.getAllUser();
    }
  }

  processDate(i) {
    let work_anniversary;
    if (i) {
      let joiningDate = new Date(i);
      let todaydate: Date = new Date();
      let diff = todaydate.getTime() - joiningDate.getTime();
      let days = diff / (1000 * 60 * 60 * 24);
      this.overall += days;
      let year = this.getYearFromDays(days);
      let months = this.getMonthFromDays(days);
      if(joiningDate.getMonth() === todaydate.getMonth() && joiningDate.getDate() > todaydate.getDate()){
        const month = joiningDate.toLocaleString('en-US', { month: 'long' });
        const day = joiningDate.getDate();
        joiningDate.setFullYear(todaydate.getFullYear());
        const weekday = joiningDate.toLocaleString('en-US', { weekday: 'long' });
        const formattedDate = `${month} ${day} ${weekday}`;
        work_anniversary = formattedDate;
      }else if( year > 0){
        let ordinalIndicator = this.getOrdinalIndicator(year);
        work_anniversary = `${year}${ordinalIndicator} Anniversary`;
      }else{
        work_anniversary = '< 1 year';
      }
    }
    return work_anniversary;
  }

  getOrdinalIndicator(year: number): string {
    switch (year % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
  }

  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }

  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
}
