<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Educational Qualifications</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>

    </div>
</div>
<hr>
<div class="container-fluid">

    <div class="row">
        <div class="col-md-12 form_main_div">
            <div class="add_button_fix">
                <button class="add_button" (click)="addEducation()">+Add</button>
                <button class="plus_button mt-4" (click)="addEducation()"><span>+Add</span></button>
            </div>
            <form [formGroup]="editSpotlightDetails">

                    <div class="row" formArrayName="education" *ngFor="let educate of this.Education().controls; let i=index">
                    <div class="col-md-5" [formGroupName]="i">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Start Date <span class="contentRed"></span></mat-label>
                                    <input matInput [matDatepicker]="picker1" formControlName="yearOfBeginning" placeholder="mm/dd/yyyy">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                    <mat-error *ngIf="editSpotlightDetails.get(['education', i, 'yearOfBeginning']).hasError('required')">
                                        Start Date is required
                                      </mat-error>
                                </mat-form-field>
                            </div>
                
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>End Date <span class="contentRed"></span></mat-label>
                                    <input matInput [matDatepicker]="picker2" formControlName="yearofCompletion" placeholder="mm/dd/yyyy">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                    <mat-error *ngIf="editSpotlightDetails.get(['education', i, 'yearofCompletion']).hasError('required')">
                                        End Date is required
                                      </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Course Name</mat-label>
                            <input matInput formControlName="courseName" autocomplete="off">
                            <mat-error *ngIf="editSpotlightDetails.get(['education', i, 'courseName']).hasError('required')">
                                Course name is required
                              </mat-error>
                        </mat-form-field>
                    </div>
                
                    <div class="col-md-2 mt-3">
                        <button class="remove_label" (click)="removeeducation(educate.value.id,i)">Remove</button>
                    </div>
                </div>
                
                
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>