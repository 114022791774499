import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { EmergencycontactService } from 'src/app/services/emergency-contact/emergencycontact.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-emergency-contact1',
  templateUrl: './emergency-contact1.component.html',
  styleUrls: ['./emergency-contact1.component.scss']
})
export class EmergencyContact1Component implements OnInit {
  emergencycontactone = new EmergencyContact();
  emergencyContactOne: FormGroup;
  userDto = new UserDTO();
  constructor(public fb: FormBuilder,
    public userDetailService: UserDetailsService,
    public userService: UserService,
    public toaster: ToastrService,
    public emergencycontactservice: EmergencycontactService,
    public dialogRef: MatDialogRef<EmergencyContact1Component>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.emergencyContactOne = this.fb.group({
      name: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z ]*')]
      )],
      relationship: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z -]*')]
      )],
      location: ['', [Validators.required]],
      phoneNumber: ['', [
        Validators.required,
        Validators.pattern(/^[\d\s+]+$/),
        Validators.maxLength(25)
      ]]

    });
  }

  ngOnInit(): void {
    //this.data contains userid
    // this.userDto = this.userDetailService.getUserDetails();
    // let userid = this.userDetailService.getUserDetails().id;
    this.emergencycontactservice
      .getemerbyuserId('Emergencycontactone', this.data)
      .subscribe((res) => {
        console.log(res.body);
        this.emergencycontactone = res.body;
        this.emergencyContactOne.patchValue(this.emergencycontactone);
      });
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: 'Closed' })
  }
  checkError = (controlName: string, errorName: string) => {
    return this.emergencyContactOne.controls[controlName].hasError(errorName)
  }
  onSubmit() {
    debugger;
    if (this.emergencyContactOne.valid) {
      let emergencyContactone = new EmergencyContact();

      emergencyContactone.emergencyContactType = 'Emergencycontactone';
      emergencyContactone.location =
        this.emergencyContactOne.get('location').value;
      emergencyContactone.name = this.emergencyContactOne.get('name').value;
      emergencyContactone.phoneNumber =
        this.emergencyContactOne.get('phoneNumber').value;
      emergencyContactone.relationship =
        this.emergencyContactOne.get('relationship').value;
      emergencyContactone.userId = this.data;

      if (this.emergencycontactone.id != null) {
        if (
          this.emergencycontactone.emergencyContactType == 'Emergencycontactone'
        ) {
          emergencyContactone.id = this.emergencycontactone.id;
          this.emergencycontactservice
            .putdocument(emergencyContactone)
            .subscribe((res) => {
              this.toaster.success("Successfully Saved")
              this.dialogRef.close({ event: 'Closed' })
              //  this.notificationService.sucess("User Data Edited")
            });
        }
      } else {
        this.emergencycontactservice
          .postdocument(emergencyContactone)
          .subscribe((res) => {
            this.toaster.success("Successfully Saved")
            this.dialogRef.close({ event: 'Closed' })
            //this.notificationService.sucess("User Data Edited")
          });
      }
    }

  }
}
