<div class="col-lg-14 col-md-14 col-sm-14">
    <div class="task_card" *ngIf="taskData != null" [ngClass]="taskColor">
        <mat-card >
            <div class="bar">
            </div>
            <div class="display-flex">
                <div class="main-div">
                    <label class="exit_checklist_lbl">
                        {{taskData.subject}} 
                    </label> 
                    <label class="txt">
                        {{ taskData.description | underScoreToSpace }}
                    </label>
                </div>
                <div class="col-lg-1 col-sm-6">
                    <div class="exit-main-btn" *ngFor="let action of actions?.actions">
                         <button *ngIf="!iscompletedtask"
                            class="btn-exit-checklist" (click)="action.action(taskData.taskTransactionId)">
                            <span class="btn-text" >{{action.text}}
                            </span>
                            <span class="btn-text">{{taskData.transactionId}}
                            </span>
                        </button>
                        <button *ngIf="!iscompletedtask"
                            class="btn-exit-checklist btn-decline"  (click)="action.action1(taskData.taskTransactionId)">
                            <span class="btn-text" >{{action.text1}}
                            </span>
                            <span class="btn-text">{{taskData.transactionId}}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card>
       
    </div>
    <div *ngIf="taskData == null">

    </div>
</div>