<mat-card class="main-card pr-0 pl-0">
  <label class="head ml-4" (click)="openUnderConstructionPopup()"> Leave Balance</label>

  <div class="row leave-balance-div">
    <div class="mt-4"  >
      <div class="progressbar-container" >
        <img (click)="openUnderConstructionPopup()" class="progress_img" src="/assets/Completed_task.png" alt="">
        <span class="centered leave_balance_num"> {{this.totalAvailableBalance}} </span>
      </div>
      <label class="task_name" >Leave Balance</label>
    </div>
    <div class="mt-1 balance-container">
      <table class="table table-striped table-borderless ">
        <tbody>
          <tr class="main_row" *ngIf="this.lossOfPay">
            <td >Loss Of Pay</td>
            <td class="table_secodColumn">{{this.lossOfPay}}</td>
          </tr>
          <tr class="main_row"  *ngIf="this.paidLeave">
            <td >Paid Leave</td>
            <td class="table_secodColumn">{{this.paidLeave}}</td>
          </tr>
          <tr class="main_row" *ngIf="this.unplannedLeave">
            <td >Unplanned Leave</td>
            <td class="table_secodColumn">{{this.unplannedLeave}}</td>
          </tr>
          <tr class="main_row" *ngIf="this.contigencyLeave">
            <td >Contingency Leave</td>
            <td class="table_secodColumn">{{this.contigencyLeave}}</td>
          </tr>
          <tr class="main_row" *ngIf="this.compOff">
            <td >Comp-off Leave</td>
            <td class="table_secodColumn">{{this.compOff}}</td>
          </tr>
          <tr class="main_row" *ngIf="this.restrictedHolidaysLeaves">
            <td >Restricted Leave</td>
            <td class="table_secodColumn">{{this.restrictedHolidaysLeaves}}</td>
          </tr>
          <tr class="main_row" *ngIf="this.specialLeave">
            <td >Special Leave</td>
            <td class="table_secodColumn">{{this.specialLeave}}</td>
          </tr>
          <tr class="main_row" *ngIf="this.maternityLeave">
            <td >Maternity Leave</td>
            <td class="table_secodColumn">{{this.maternityLeave}}</td>
          </tr>
          <tr class="main_row" *ngIf="this.paternityLeave">
            <td >Paternity Leave</td>
            <td class="table_secodColumn">{{this.paternityLeave}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- <div class="row">
    <div class="flex">
    <div class="col-md-12 img_div">
      <div class="parents">
        <div class="child mt-4"  >
          <div class="progressbar-container" >
            <img (click)="openUnderConstructionPopup()" class="progress_img" src="/assets/Completed_task.png" alt="">
            <span class="centered leave_balance_num"> {{this.totalAvailableBalance}} </span>
          </div>
          <label class="task_name" >Leave Balance</label>
        </div>
        <div class="child mt-1 balance-container">
          <table class="table table-striped table-borderless ">
            <tbody>
              <tr class="main_row" *ngIf="this.lossOfPay">
                <td >Loss Of Pay</td>
                <td class="table_secodColumn">{{this.lossOfPay}}</td>
              </tr>
              <tr class="main_row"  *ngIf="this.paidLeave">
                <td >Paid Leave</td>
                <td class="table_secodColumn">{{this.paidLeave}}</td>
              </tr>
              <tr class="main_row" *ngIf="this.unplannedLeave">
                <td >Unplanned Leave</td>
                <td class="table_secodColumn">{{this.unplannedLeave}}</td>
              </tr>
              <tr class="main_row" *ngIf="this.contigencyLeave">
                <td >Contingency Leave</td>
                <td class="table_secodColumn">{{this.contigencyLeave}}</td>
              </tr>
              <tr class="main_row" *ngIf="this.compOff">
                <td >Comp-off Leave</td>
                <td class="table_secodColumn">{{this.compOff}}</td>
              </tr>
              <tr class="main_row" *ngIf="this.restrictedHolidaysLeaves">
                <td >Restricted Leave</td>
                <td class="table_secodColumn">{{this.restrictedHolidaysLeaves}}</td>
              </tr>
              <tr class="main_row" *ngIf="this.specialLeave">
                <td >Special Leave</td>
                <td class="table_secodColumn">{{this.specialLeave}}</td>
              </tr>
              <tr class="main_row" *ngIf="this.maternityLeave">
                <td >Maternity Leave</td>
                <td class="table_secodColumn">{{this.maternityLeave}}</td>
              </tr>
              <tr class="main_row" *ngIf="this.paternityLeave">
                <td >Paternity Leave</td>
                <td class="table_secodColumn">{{this.paternityLeave}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
  </div>
  </div> -->
</mat-card>

