<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-md-12">
            <!-- TODO = Add a good loading UI-->
            <div class="main_div  mt-5" *ngIf="(taskState$ | async)?.loading">

                <mat-spinner strokeWidth="7" [diameter]="170"></mat-spinner>
                <label class="loading_label mt-5">Loading...</label>
            </div>
            <!-- TODO: Add a good error UI -->
            <div *ngIf="(taskState$ | async)?.error">{{(taskState$ | async)?.error}}</div>
            <div *ngIf="!(taskState$ | async)?.loading && !(taskState$ | async)?.error" class="task-list">
                <mat-tab-group dynamicHeight [selectedIndex]="this.index">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="main-div pending-lbl mat-icon-color">
                                <div class="badge">
                                    <span class="lbl">{{pendingTasksCount$ | async}}</span>
                                </div>
                                <label>Pending Tasks</label>
                            </div>
                        </ng-template>
                        <!-- <div *ngIf="(pendingTasksCount$ |async ) ;else imageLoading">
                            <div class="mt-4" *ngFor="let t of pendingTasks$ | async; ">
                                <app-task [taskData]="t" [taskStatus]="'pending'"></app-task>
                            </div>
                        </div> -->
                        <div *ngIf="(pendingTasksCount$ | async); else imageLoading">
                            <div class="mt-4" *ngFor="let t of (pendingTasks$ | async)| orderBy:'t.created_on':'asc'">
                                <app-task [taskData]="t" [taskStatus]="'pending'"></app-task>
                            </div>
                        </div>


                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="main-div inprogress-lbl">
                                <div class="badge">
                                    <span class="lbl">{{inprogressTasksCount$ | async}}</span>
                                </div>
                                <label>
                                    Inprogess Tasks
                                </label>
                            </div>
                        </ng-template>
                        <div *ngIf="(inprogressTasksCount$ |async ) ;else imageLoading">
                            <div class="mt-4" *ngFor="let t of inprogressTasks$ | async; ">
                                <app-task [taskData]="t" [taskStatus]="'inprogress'"></app-task>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="main-div overdue-lbl">
                                <div class="badge">
                                    <span class="lbl">{{dueTasksCount$ | async}}</span>
                                </div>
                                <label>
                                    Overdue Tasks
                                </label>
                            </div>
                        </ng-template>
                        <div *ngIf="(dueTasksCount$ |async ) ;else imageLoading">
                            <div class="mt-4" *ngFor="let t of dueTasks$ | async; ">
                                <app-task [taskData]="t" [taskStatus]="'due'"></app-task>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="main-div completed-lbl">
                                <div class="badge">
                                    <span class="lbl">{{completedTasksCount$ | async}}</span>
                                </div>
                                <label>
                                    Completed Tasks
                                </label>
                            </div>
                        </ng-template>
                        <div *ngIf="(completedTasksCount$ |async ) ;else imageLoading">
                            <div class="mt-4" *ngFor="let t of completedTasks$ | async; ">
                                <app-task [taskData]="t" [taskStatus]="'completed'"></app-task>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>

<ng-template #imageLoading>
    <div class="task_img"><img class="no_task_data" src="assets/no_task_data.png"></div>

</ng-template>