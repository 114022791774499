import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-under-construction-popup',
  templateUrl: './under-construction-popup.component.html',
  styleUrls: ['./under-construction-popup.component.scss']
})
export class UnderConstructionPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UnderConstructionPopupComponent>,) { }

  ngOnInit(): void {
  }
  closeDialog(event:Event) {
    this.dialogRef.close({ event: 'Closed'})
  }
}
