<mat-accordion class="example-headers-align">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">
                About
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid">
           
            <div class="row">
                <div class="col-md-12 form_main_div">
                    <form [formGroup]="aboutDetails">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="7" cdkAutosizeMaxRows="15"
                                        formControlName="about"></textarea>
                                    <!-- <mat-error *ngIf="checkError('about','required')">Required</mat-error> -->
                                    <mat-error *ngIf="aboutDetails.get('about').hasError('pattern')">
                                        Only alphabets and numbers are allowed
                                      </mat-error>
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button mat-flat-button class="save_Emp_btn" type="submit" (click)="onSubmit()">
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>

            <mat-panel-title class="panel-title_label">
                Professional skills
            </mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation();">
                <ng-container *ngIf="this.panelOpenState==true;else elseTemplate">
                    <button class="add_button" (click)="addSkill()">+Add Skill</button>

                </ng-container>
                <ng-template #elseTemplate>

                </ng-template>

            </mat-panel-description>
        </mat-expansion-panel-header>



        <div class="container-fluid">
           
            <div class="row">
                <button class="plus_button" (click)="addSkill()"><span class="mr-2">+Add</span></button>
            </div>
            <div class="row">
                <div class="col-md-12 form_main_div">
                    <form [formGroup]="professionalSkillsDetails">
                        <div class="row" formArrayName="skills"
                            *ngFor="let skill of this.Skills.controls; let i = index">
                            <div class="col-md-4" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Title</mat-label>
                                    <input matInput formControlName="technology" autocomplete="off" pattern="[a-zA-Z ]*">
                                    <mat-error *ngIf="editSpotlightDetails.get('skills').controls[i].get('technology').hasError('pattern')">
                                       Only alphabets and spaces are allowed.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-8" [formGroupName]="i">
                                <ngb-rating formControlName="rate" class="ngb-rating" [max]="5" [readonly]="false">
                                    <button (click)="removeskill(skill.value.id,i)" class="remove_label">Remove</button>

                                </ngb-rating>
                            </div>
                            <div class="col-md-4">

                                <button class="remove_label" (click)="removeskill(skill.value.id,i)">Remove</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit1()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>

</mat-accordion>

<mat-accordion class="example-headers-align">
    <mat-expansion-panel (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">Previous Employment</mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation();">

                <ng-container *ngIf="this.panelOpenState2==true;else elseTemplate">
                    <button class="add_button" (click)="addPreEmployment()">+Add Previous Employment</button>
                    

                </ng-container>
                <ng-template #elseTemplate>

                </ng-template>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="container-fluid">
            
            <div class="row">
                <button class="plus_button" (click)="addPreEmployment()"><span>+Add</span></button>

            </div>
            <div class="row">


                <div class="col-md-12 form_main_div">
                    <form [formGroup]="employmentHistoryDetails">
                        <div formArrayName="previousEmployment"
                            *ngFor="let work of this.PreviousEmp().controls let i=index">
                            <div class="row">

                                <div class="col-md-4" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Start Date <span class="contentRed"></span></mat-label>
                                        <input  matInput [max]="this.today" [matDatepicker]="picker2" placeholder="mm/dd/yyyy"
                                            formControlName="startDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>


                                <div class="col-md-4" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>End Date <span class="contentRed"></span></mat-label>
                                        <input  [min]="getMinEndDate(i,'previousEmployment')" [max]="today" matInput [matDatepicker]="picker3"  placeholder="mm/dd/yyyy"
                                            formControlName="endDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">

                                    <button class="remove_label"
                                        (click)="removePreEmployment(work.value.id,i)">Remove</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Job Title</mat-label>
                                        <input matInput formControlName="jobTitle" autocomplete="off">
                                    </mat-form-field>

                                </div>
                                <div class="col-md-6" [formGroupName]="i">
                                    <mat-form-field appearance="outline" class="example-full-width">
                                        <mat-label>Company</mat-label>
                                        <input matInput formControlName="comapanyName" autocomplete="off">
                                    </mat-form-field>

                                </div>

                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit2()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

    </mat-expansion-panel>
</mat-accordion>

<mat-accordion class="example-headers-align">
    <mat-expansion-panel (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false">
        <mat-expansion-panel-header>
            <mat-panel-title class="panel-title_label">Educational Qualifications</mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation();">
                <ng-container *ngIf="this.panelOpenState3==true;else elseTemplate">
                    <button class="add_button" (click)="addEducation()">+Add Education Details</button>

                </ng-container>
                <ng-template #elseTemplate>

                </ng-template>

            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="container-fluid">
           
            <div class="row">
                <button class="plus_button" (click)="addEducation()"><span>+Add</span></button>
            </div>

            <div class="row">


                <div class="col-md-12 form_main_div">
                    <form [formGroup]="educationDetails">
                        <div class="row" formArrayName="education"
                            *ngFor="let educate of this.Education().controls;  let i=index">

                            <div class="col-md-4" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Year of Completion <span class="contentRed"></span></mat-label>
                                    <input matInput [matDatepicker]="picker4" placeholder="mm/dd/yyyy"
                                        formControlName="yearofCompletion">
                                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                    <mat-datepicker #picker4></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Course Name</mat-label>
                                    <input matInput formControlName="courseName" autocomplete="off">
                                </mat-form-field>

                            </div>
                            <div class="col-md-2">
                                <button class="remove_label"
                                    (click)="removeeducation(educate.value.id,i)">Remove</button>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit3()"> <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false">
        <mat-expansion-panel-header>

            <mat-panel-title class="panel-title_label">Certifications</mat-panel-title>
            <mat-panel-description (click)="$event.stopPropagation();">
                <ng-container *ngIf="this.panelOpenState4==true;else elseTemplate">
                    <button class="add_button" (click)="addCertificate()">+Add Certifications</button>
                </ng-container>
                <ng-template #elseTemplate>

                </ng-template>

            </mat-panel-description>

        </mat-expansion-panel-header>

        <div class="container-fluid">
           
            <div class="row">
                <button class="plus_button" (click)="addCertificate()"><span>+Add</span></button>
             
            </div>
            <div class="row">
                <div class="col-md-12 form_main_div">
                    <form [formGroup]="editSpotlightDetails">
                        <div class="row" formArrayName="uploadCertificates"
                            *ngFor="let cert of this.Certificate.controls; let i = index">

                            <div class="col-md-4" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Year of Completion <span class="contentRed"></span></mat-label>
                                    <input [max]="today" matInput [matDatepicker]="picker5" placeholder="mm/dd/yyyy"
                                        formControlName="dateOfIssue">
                                    <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                    <mat-datepicker #picker5></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Valid Upto <span class="contentRed"></span></mat-label>
                                    <input [min]="getMinEndDateCert(i)" matInput [matDatepicker]="picker2" placeholder="mm/dd/yyyy"
                                        formControlName="validUpto">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" [formGroupName]="i">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Course Name</mat-label>
                                    <input matInput formControlName="documentName" autocomplete="off">
                                </mat-form-field>

                            </div>

                               <div class="col-md-12 certificate"  [formGroupName]="i">
                                    <img class="img_size" src="assets/Certificate.png">
                                    <label for="my_file" class="certificate_label" *ngIf="this.multiCertificates[i]==null">Attach Certificate Copy</label>
                                    <input type="file" id="my_file" *ngIf="this.multiCertificates[i]==null" style="display: none;" multiple formControlName="docBase"
                                        accept=".png , .jpg, .pdf" (change)="uploadCertificate($event,i)" />
                                        <ng-container *ngIf="this.multiCertificates[i]!=null;else elseBlock ">

                                            <label for="my_file2" *ngIf="this.multiCertificates[i]=='undefined'" class="certificate_label">
                                                No Certificate Attached
                                            </label>
                                            <input type="file"
                                            (click)="uploadCertificate($event,i)"
                                            *ngIf="this.multiCertificates[i]=='undefined'"
                                            id="my_file2" style="display: none;" multiple formControlName="docBase2"
                                            accept=".png , .jpg, .pdf" (change)="uploadCertificate($event,i)" />
                                            
                
                                            <label *ngIf="this.multiCertificates[i] != 'undefined'" class="certificate_label"
                                                    (click)="open(content10,i)">
                                                    Preview Certificate Copy<span>{{cert.documentName}}</span>
                                            </label>
                                            
                                        </ng-container>
                                    <ng-template #content10 let-modal>
                                        <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div class="modal-body" >
                                            <pdf-viewer *ngIf="this.certificateType[i] == 'application/pdf'"
                                                [src]="this.multiCertificates[i]" [rotation]="0" [fit-to-page]="false"
                                                [original-size]="false" [show-all]="true">
                                            </pdf-viewer>
                                            <img *ngIf="this.certificateType[i] == 'image/png' || this.certificateType[i] == 'image/jpeg'"
                                                class="preview-img" [src]="this.multiCertificates[i]">
                                        </div>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        <!-- <p class="certificate_label">No file Selected</p> -->
                                    </ng-template>
                                </div>
                            <div class="col-md-1">
                                <button class="remove_label"
                                    (click)="removecertificate(cert.value.id,i)">Remove</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit4()" > <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>