import { Component,Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AddHolidayService } from 'src/app/services/addHoliday/add-holiday.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';

@Component({
  selector: 'app-edit-holiday',
  templateUrl: './edit-holiday.component.html',
  styleUrls: ['./edit-holiday.component.scss']
})
export class EditHolidayComponent implements OnInit {
  LeaveType = [];
  newLeaveDetails: FormGroup;
  clientProject=[];
  clients: any[] = [];
  availableHolidayTypes: string[] = ['CLIENT_HOLIDAY'];

  constructor(public dialogRef: MatDialogRef<EditHolidayComponent>,
    public addHolidayService :AddHolidayService,
    public clientService:ClientService,
    public toaster:ToastrService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) { this.newLeaveDetails = this.fb.group({
      LeaveType: ['', [Validators.required]],
      date: ['', [Validators.required]],
      projectName: ['', [Validators.required]],
      holidayName: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    // console.log(this.data.existingHoliday)
    this.LeaveType = ['CLIENT_HOLIDAY', 'GENERAL_HOLIDAY', 'RESTRICTED_HOLIDAY'];
    this.clientService.getClientsSubscribe().subscribe((res)=>{
      const clientRes=res.body;
      for(let i=0;i<clientRes.length;i++) {
        this.clientProject.push(clientRes[i].clientName)
        this.clients.push(clientRes[i])
      }
    })

      
   
    const existingHolidays = this.data.existingHoliday;
     this.newLeaveDetails.patchValue({
        LeaveType: existingHolidays.selectedLeaveType,
        date: existingHolidays.date,
        holidayName: existingHolidays.leaveDescription,
        projectName:existingHolidays.clientId,
      });
  }
  onClientNameChange(): void {
    const selectedClient = this.newLeaveDetails.get('projectName').value;
    if (selectedClient === 'BA') {
      this.availableHolidayTypes = ['GENERAL_HOLIDAY', 'RESTRICTED_HOLIDAY'];
    } else {
      this.availableHolidayTypes = ['CLIENT_HOLIDAY'];
    }
    // Reset the LeaveType form control
    this.newLeaveDetails.get('LeaveType').setValue('');
  }
  closeDialog() {
    this.dialogRef.close();
  }

  correctZoneOffset(date: Date) {
    const timezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffset);
    return date;
  }

  details(){
    var clientName = this.newLeaveDetails.get('projectName').value;
    var clientIdTemp;
    // console.log(this.clients);
    for(let i = 0; i < this.clients.length;i++) {
      if(this.clients[i].clientName === clientName){
        clientIdTemp = this.clients[i].clientId;
        break;
      }
    }
    let tempDate = new Date(this.newLeaveDetails.get('date').value);
    
    const BACalendar={ 
          calendarLeaveType:this.newLeaveDetails.get('LeaveType').value,
          clientId: clientIdTemp,
          date: this.correctZoneOffset(tempDate),
          id:undefined,
          leaveDescription:this.newLeaveDetails.get('holidayName').value,
    }
    console.log(clientIdTemp);
    console.log(BACalendar)
    this.addHolidayService.editHoliday(BACalendar,BACalendar.date.getFullYear(),this.data.existingHoliday.id).subscribe((e)=>{
      console.log(this.data.existingHoliday.Id);
      this.toaster.success('Successfully Applied', 'Saved', {});
        this.dialogRef.close();
    })
  
 
}
}
