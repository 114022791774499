import { Component, OnInit, Inject } from '@angular/core';
import { AssetManagementService } from 'src/app/services/asset-management/asset-management.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewAssetComponent } from '../../view-asset/view-asset/view-asset.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-revoke-asset',
  templateUrl: './revoke-asset.component.html',
  styleUrls: ['./revoke-asset.component.scss']
})

export class RevokeAssetComponent implements OnInit {

  revokeComment: String;
  newAssetDetails: FormGroup;
  emailError: any;
  revokeInProgress: boolean;
  noOnlySpecialCharactersValidator():ValidatorFn {
    // Regular expression to match only special characters
   
    const onlySpecialCharsRegex =/^[!#$%^&*(),.<>?":@?\{}|<_ /]+$/;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;

    if (onlySpecialCharsRegex.test(value)) {
      return { noOnlySpecialCharacters: true };
    }

    return null;
  };
  }

  constructor(private assetService: AssetManagementService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<ViewAssetComponent>) 
    {
    this.newAssetDetails = this.fb.group({
      comment: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]]
    },);
  }

  

  ngOnInit(): void {
  }
  

  checkError = (controlName: string, errorName: string) => {
    return this.newAssetDetails.controls[controlName].hasError(errorName);
  }

  setFormControlError(controlName: string, controlError: string, errorMsg: string) {
    this.newAssetDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.newAssetDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.newAssetDetails.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg;
  }

  revokeAsset() {
    
    if (this.newAssetDetails.valid){
    this.revokeInProgress = true;
    this.assetService.revokeAsset(this.data.assetDto.id, this.newAssetDetails.get('comment').value).subscribe(res => {
      this.data = res.body;
      this.revokeInProgress = true;
      this.dialogRef.close({ data: res.body });
    });
    error => {
    };;
  }
  else{
    this.notificationService.invalidFormData()
  }
  }

  closeDialog() {
    //this.dialogRef.close({ data: this.data });
    this.dialogRef.close({ event: 'Closed'});
  }
}
