import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { Project } from 'src/app/models/project';
import { Talent } from 'src/app/models/talent';
import { ProjectService } from 'src/app/services/project.service';
import { TalentService } from 'src/app/services/talent/talent.service';
import { ProjectRole } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { MainContentProjectComponent } from '../../../main-content-project/main-content-project.component';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
@Component({
  selector: 'app-add-talent',
  templateUrl: './add-talent.component.html',
  styleUrls: ['./add-talent.component.scss'],
  providers: [PipeUnderScoreToSpace]
})
export class AddTalentComponent implements OnInit {
  newTalentDetails: FormGroup;
  projectRoleList = [];
  talentdto = new Talent();
  Error: string
  projectDto = new ProjectDTO();
  userNameIDMapping = {};
  userNameIDMapping1={};
  public userList
  flag=false;
  mindate
  maxdate
  userID;
  userList1
  selectedAreas: string[];
  userIdNameMapping={};
  constructor(public dialogRef: MatDialogRef<MainContentProjectComponent>,
    public fb: FormBuilder,
    private projectService: ProjectService,
    private talentservice: TalentService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public projectsid) {
    this.newTalentDetails = this.fb.group({
      talentName: ['', [Validators.required]],
      employeeId: ['', [Validators.required]],
      role: ['', [Validators.required]],
      allocationTime: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: [this.projectsid.endDate, [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.getproject(this.projectsid.projectId);
    
    this.processEnumValuesToArray()
    this.getuserList()
    this.setMinandMaxDate()
    this.setFormData()
  }
  search(query: string){
    
    let result = this.select(query)
    this.selectedAreas = result;
  }


  select(query: string):string[]{
    let result: string[] = [];
    for(let a of this.userList){
      if(a.toLowerCase().indexOf(query.toLowerCase()) >-1){
        result.push(a)
      }
    }
    return result
  }
  checkError = (controlName: string, errorName: string) => {
    return this.newTalentDetails.controls[controlName].hasError(errorName);
  }
  closeDialog() {
    this.dialogRef.close()
  }
  processEnumValuesToArray() {
    for (let projectRole of Object.values(ProjectRole)) {
      if (isNaN(Number(projectRole)))
        this.projectRoleList.push(projectRole);
    }
  }
  setFormControlError(controlName: string, controlError: string, errorMsg: string) {
    this.newTalentDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.newTalentDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.newTalentDetails.get(controlName).setErrors({ [controlError]: true });
    this.Error = errorMsg;
  }
  getUserID(username) {
   
    if(!this.flag)
    {
      let temp = this.userNameIDMapping[username];
      this.userID=this.userIdNameMapping[temp]
      this.talentservice.validateEmployeeId(this.userID,this.projectsid.projectId).subscribe(res => {
      }, (error) => {
        if (error.error.errorKey == "employeeIdExist") {
          this.setFormControlError('talentName', 'InvalidEmployeeId', 'Employee Id Already Exist');
        } else {
          this.setFormControlError('talentName', 'InvalidEmployeeId', 'required');
        }
      })
        this.userService.getEmployeeById(temp).subscribe(data => {
          
          let employeeId = data.body.employeeId;
          this.newTalentDetails.get('employeeId').setValue(employeeId);
        },
        error => {
        });
    }
    else 
    {

      this.userID = this.userNameIDMapping[username];
      this.talentservice.validateEmployeeId(this.userID,this.projectsid.projectId).subscribe(res => {
      }, (error) => {
        if (error.error.errorKey == "employeeIdExist") {
          this.setFormControlError('talentName', 'InvalidEmployeeId', 'Employee Id Already Exist');
        } else {
          this.setFormControlError('talentName', 'InvalidEmployeeId', 'required');
        }
      })
        this.userService.getEmployeeById(username).subscribe(data => {
          
          let employeeId = data.body.employeeId;
          this.newTalentDetails.get('employeeId').setValue(employeeId);
        },
        error => {
        });
    }
  
  }
  getuserList() {
    
    this.userService.getAllUserForDropDown().subscribe(res=>{
      
      for(let i in res.body){
       
 // this.userNameIDMapping[res.body[i].id]=res.body[i].firstName + ' '+res.body[i].lastName;
 this.userNameIDMapping[res.body[i].firstName + " " + res.body[i].lastName+"("+res.body[i].employeeId+")"] = res.body[i].userID;
 this.userIdNameMapping[res.body[i].userID] = res.body[i].firstName + " " + res.body[i].lastName;
   this.userNameIDMapping1[res.body[i].userID]=res.body[i].firstName + ' '+res.body[i].lastName+"("+res.body[i].employeeId+")";
     
}


      this.userList = Object.keys(this.userNameIDMapping);let i=0;
      this.userList1= Object.keys(this.userNameIDMapping1);
      this.selectedAreas=this.userList
      // for(let x of this.userList)
      //     {
      //       this.userList[i]=x.toLowerCase();i++;
      //     }   
     })
  }
  
 getproject(projectId){
    this.projectService.getProjectById(projectId).subscribe(res => {
     this.projectDto=res.body
    })
  }
  setMinandMaxDate() {
    if (this.projectsid.startDate) {
      this.projectService.getProjectById(this.projectsid.projectId).subscribe(res => {
        this.mindate = res.body.startDate
        this.maxdate = res.body.endDate
      })
    }
  }
  setFormData() {
 
    
    if (this.projectsid.talent) {
      
      this.flag=true;
      this.newTalentDetails.get('talentName').setValue(this.projectsid.talent.userId ? this.projectsid.talent.userId: '')
      this.userService.getEmployeeById(this.projectsid.talent.userId).subscribe(data => {
        
        let employeeId = data.body.employeeId;
        this.newTalentDetails.get('employeeId').setValue(employeeId);
      })

      this.newTalentDetails.get('role').setValue(this.projectsid.talent.role ? this.projectsid.talent.role : '');
      this.newTalentDetails.get('allocationTime').setValue(this.projectsid.talent.allocationTime ? this.projectsid.talent.allocationTime : '');
      this.newTalentDetails.get('startDate').setValue(this.projectsid.talent.startDate ? this.projectsid.talent.startDate : '');
      this.newTalentDetails.get('endDate').setValue(this.projectsid.talent.endDate ? this.projectsid.talent.endDate : '');
    }
  }
  save() {
    
    if (this.newTalentDetails.valid) 
    {
      // this.userNameIDMapping[username];
      // this.userID=this.userIdNameMapping[temp]
      if(this.flag)
      { 
        this.flag=false;
        this.talentdto.userId = this.newTalentDetails.get('talentName').value
        this.talentdto.talentName = this.userIdNameMapping[this.newTalentDetails.get('talentName').value];
      }
      else
      {
      this.talentdto.userId = this.userNameIDMapping[this.newTalentDetails.get('talentName').value]
      this.talentdto.talentName = this.userIdNameMapping[this.userNameIDMapping[this.newTalentDetails.get('talentName').value]];
      }
      this.talentdto.role = this.newTalentDetails.get('role').value;
      this.talentdto.allocationTime = this.newTalentDetails.get('allocationTime').value;
      this.talentdto.startDate = this.newTalentDetails.get('startDate').value;
      this.talentdto.endDate = this.newTalentDetails.get('endDate').value;
      this.talentdto.projectId = this.projectsid.projectId
      
    

      if (this.projectsid.talent) {
        
        if(this.talentdto.role=='PROJECT_MANAGER'){
          if(this.talentdto.userId!=null){
          this.projectDto.managerId=this.talentdto.userId}
          this.projectDto.id=this.projectsid.projectId
          this.projectService.updateProjectSubscribe(this.projectDto).subscribe(res=>{
            this.dialogRef.close()

          })
        }
        this.talentdto.id = this.projectsid.talent.id
        this.talentservice.update(this.talentdto).subscribe(res => {
          this.dialogRef.close()
        
        });
      }
      else {
        if(this.talentdto.role=="PROJECT_MANAGER"){
          this.projectDto.managerId=this.talentdto.userId
          this.projectDto.clientId=this.projectsid.clientId
          this.projectDto.id=this.projectsid.id
          this.projectService.updateProjectSubscribe(this.projectDto).subscribe(res=>{
            this.dialogRef.close()
          })
        }
        this.talentservice.create(this.talentdto).subscribe(res => {
                this.dialogRef.close()
        });
      }
     // this.dialogRef.close()
    }
  }

  }
function populateEmployeeId(id: any) {
  throw new Error('Function not implemented.');
}

