<div class="container-fluid laptop-view" *ngIf="this.usergoaldto">
    <div class="row mt-4">
        <div class="col-md-3">
            <label class="goaltype">{{goalTypeMapping[usergoaldto.goalType]}}</label>
        </div>
        <div class="col-md-2 admin_label">
            {{this.username}}
        </div>
        <div class="col-md-6">
            <div class="main-header">
                <div class="on-track-bar">
                    <div class="on-track-lbl">
                        <span class="sub-goal-count">No of Sub Goals</span>
                        <span>{{goal.length}}</span>
                    </div>
                </div>
                <div>
                    <button class="close_label" (click)="closeDialog()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12">
            <div class="submit_button_main">
                <button class="add_Emp_btn" type="submit" (click)="openDialogueSubGoal(this.usergoalid,'')"> <span
                        class="add_em_name">New Sub Goal</span> </button>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row goal_div_border" *ngFor="let goal of this.goal;let i=index">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3">
                        <label class="employee_lable">{{goal.subgoalName}}</label>
                    </div>
                    <div class="col-md-1">
                        <label class="percentage-lbl">{{goal.currentValue}}%</label>
                    </div>
                    <div class="col-md-8">
                        <div class="main-header2">
                            <div>
                                <button class="manage_lbl"
                                    (click)="openDialogueSubGoal(this.usergoalid,goal)">Manage</button>
                            </div>
                            <div>
                                <button class="remove_lbl" (click)="openDeleteSubGoal(goal)">Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <p class="desc">{{goal.description}}</p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mobile-views" *ngIf="this.usergoaldto">
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="main_header_div1">
                <div class="child">
                    <label class="employee_lable">{{goalTypeMapping[usergoaldto.goalType]}}</label>
                </div>
                <div class="child_close_btn">
                    <button class="close_lbl" (click)="closeDialog()">Close</button>
                    <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
                </div>
            </div>
            <div class="admin_label">
                {{this.username}}
            </div>
            <div class="on-track-bar">
                <div class="on-track-lbl">
                    <span>{{usergoaldto.progressValue}}</span>
                </div>
                <div>
                    <mat-progress-bar class="on-track-progressbar ml-3" mode="determinate"
                        [value]="usergoaldto.progressValue">
                    </mat-progress-bar>
                </div>
            </div>
            <div class="col-md-3 mt-1">
                <button class="close_label" (click)="closeDialog()">
                    Close
                </button>
            </div>
        </div>
    </div>
    <hr class="hr">
    <div class="row">
        <div class="col-md-12">
            <div class="submit_button_main">
                <button class="add_Emp_btn" type="submit"> <span class="add_em_name"
                        (click)="openDialogueSubGoal(this.usergoalid,'')">Sub goals</span> </button>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row laptop_div_border" *ngFor="let goal of this.goal;let i=index">
            <div class="col-md-12">
                <div class="main_header_div2">
                    <div class="child">
                        <button class="manage_lbl" (click)="openDialogueSubGoal(this.usergoalid,goal)">Manage</button>
                    </div>
                    <div class="child_close_btn">
                        <button class="remove_lbl" (click)="openDeleteSubGoal(goal)">Remove</button>
                    </div>
                </div>
                <div class="main_header_div3">
                    <div class="child">
                        <label class="goal_label">{{goal.subgoalName}}</label>
                    </div>
                    <div class="child_close_btn">
                        <label class="currentvalue_label">{{goal.currentValue}}</label>
                    </div>
                </div>
                <div class=" mt-3">
                    <p class="desc">{{goal.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>