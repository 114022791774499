import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarOptions, EventInput, EventSourceInput } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { AttendanceHistoryService } from 'src/app/services/attendaceHistory/attendance-history.service';
import * as moment from 'moment';
import { UserService } from 'src/app/services/user/user.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { RegularizeComponent } from "../regularize/regularize.component";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AbsentService } from 'src/app/services/absent/absent.service';
import { BulkRegularizeComponent } from '../bulk-regularize/bulk-regularize.component';
//import { AttendanceData } from 'src/app/models/attendanceData';
import { RegularizeDates } from 'src/app/models/RegularizeDates';
import { ApplyNewleaveComponent } from '../apply-newleave/apply-newleave.component';
import { Console } from 'console';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';
import { ApprovalStatus } from 'src/app/services/leaveManagment/leave-enum';


@Component({
  selector: 'app-leave-attendance',
  templateUrl: './leave-attendance.component.html',
  styleUrls: ['./leave-attendance.component.scss']

})
export class LeaveAttendanceComponent implements OnInit {
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  regularizationTab: boolean = true;
  attendanceHistoryTab: boolean = false;
  userId: any;
  HistoryData: EventInput[] = [];
  StatusHistory: any;
  selectedDates: any[] = [];

  val: any[] = [];
  selectedItems: any[] = [];

  item: any[] = [];
  dat: any;
  constructor(
    private attendaceHistory: AttendanceHistoryService,
    private userdetails: UserDetailsService,
    private absentService: AbsentService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): any {
    this.userId = this.userdetails.getUserDetails().id;
    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }
    const user = this.userdetails.getUserDetails();
    this.getStatus(user.id, 'UNMARKED_ATTENDANCE');
  }

  apiCallForStatus(): EventInput[] {
    this.attendaceHistory.getAttendaceHistory(this.selectedYear)
      .subscribe((res) => {
        this.HistoryData = [];
        const attendanceHistory = res.body;

        for (let i = 0; i < attendanceHistory.length; i++) {
          const status = attendanceHistory[i].attendanceStatus;
          if (status == 'PRESENT' || status == 'ABSENT' || status == 'REGULARIZED_PRESENT' || status == 'LEAVE' || status == 'UNMARKED_ATTENDANCE' || status == 'HOLIDAY') {
            const formattedDate = moment(attendanceHistory[i].attendanceDateTime).format('YYYY-MM-DD');
            let bgColor = ''

            if (status == 'PRESENT') {
              bgColor = '#28A745';
            }
            else if (status == 'REGULARIZED_PRESENT') {
              bgColor = '#FFA900';
            }
            else if (status == 'ABSENT' || status == 'UNMARKED_ATTENDANCE') {
              bgColor = '#EB0000';
            }
            else if (status == 'LEAVE') {
              bgColor = '#0045B9';
            } else {
              bgColor = '#FAA8A9';
            }

            const currentEvent: EventInput = {
              title: '',
              start: formattedDate,
              end: formattedDate,
              backgroundColor: bgColor
            };
            this.HistoryData.push(currentEvent);
          }
        }
        this.calendarOptions.events = this.HistoryData;

        return this.HistoryData;
      }, (e) => {
        return this.HistoryData;
      })
    // console.log(this.HistoryData)
    return this.HistoryData;
  }

  historyTabData: { date: any, status: any }[] = [];

  getHistoryTabData() {
    this.attendaceHistory.getAttendaceHistory(this.selectedYear)
      .subscribe((res) => {
        console.log(res.body);
        this.historyTabData = [];
        const attendanceHistory = res.body;
        // console.log(attendanceHistory)

        for (let i = 0; i < attendanceHistory.length; i++) {
          const status = attendanceHistory[i].attendanceStatus;
          const dateObj = new Date(attendanceHistory[i].attendanceDateTime);
          const monthName = dateObj.toLocaleString('default', { month: 'long' });
          // console.log("MonthName: "+monthName);
          // console.log("Date"+dateObj);
          // console.log("SelectedMonth: " + this.selectedMonth)

          if (monthName.toLowerCase() == this.selectedMonth.toLowerCase()) {
            console.log(attendanceHistory[i]);
            if (status == 'REGULARIZED_PRESENT' || (status == 'UNMARKED_ATTENDANCE' && attendanceHistory[i].appliedForRegularization)) {
              const formattedDate = moment(attendanceHistory[i].attendanceDateTime).format('ddd, D MMM,');
              const currStatus = status == 'REGULARIZED_PRESENT' ? 'Approved' : 'Pending';

              const currentData = {
                date: formattedDate,
                status: currStatus
              }
              this.historyTabData.push(currentData);
            }
          }

        }
        console.log(this.historyTabData)
      }, (e) => {
        return this.HistoryData;
      })
  }
  openDialog(item: any[] = []) {
    const dialogRef = this.dialog.open(RegularizeComponent, {
      height: '50%',
      width: '40%',
      data: {
        item: item
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // Refresh the list of unmarked attendance here
      if (result) {
        const user = this.userdetails.getUserDetails();
        this.getStatus(user.id, 'UNMARKED_ATTENDANCE');
      }
      this.item = [];
    });
  }
  openDial() {
    const dialogRef = this.dialog.open(BulkRegularizeComponent, {
      height: '60%',
      width: '45%',
      data: this.selectedItems
    });
    dialogRef.afterClosed().subscribe(result => {
      // Refresh the list of unmarked attendance here
      if (result) {
        const user = this.userdetails.getUserDetails();
        this.getStatus(user.id, 'UNMARKED_ATTENDANCE');
      }
      this.selectedItems = [];
    });
  }



  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    initialDate: new Date(),
    //events: this.newEvents,
    weekends: true,
    firstDay: 1,
    datesSet: event => {
      const dateObj = new Date(event.end);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      this.selectedMonth = month == 0 ? 'December' : this.months[month - 1];
      this.selectedYear = month == 0 ? year - 1 : year;
      this.apiCallForStatus();
      this.getHistoryTabData();
    },
    headerToolbar: {
      
      // start: '',
      // center: 'title',
      // end: 'today next nextYear'
      // end: 'today prev next'
      start: 'prevYear prev',
      center: 'title',
      end: 'today next nextYear'
    },
  };

  public onMonthYearChange() {
    if (this.fullCalendar) {
      const date = new Date(this.selectedYear, this.months.indexOf(this.selectedMonth), 1);
      this.fullCalendar.getApi().gotoDate(date);
    }
  }
  onBtnClick() {
    const user = this.userdetails.getUserDetails();
    this.regularizationTab = true;
    this.attendanceHistoryTab = false;
  }
  onBtnOneClick() {
    this.regularizationTab = false;
    this.attendanceHistoryTab = true;
  }

  getStatus(userId, attendanceStatus) {
    this.val = [];
    const user = this.userdetails.getUserDetails();
    this.absentService.getstatusbyuser('UNMARKED_ATTENDANCE').subscribe(res => {
      for (let i = 0; i < res.body.length; i++) {
        this.val.push(res.body[i]);

      }
    }, (e) => {
      // console.log(e);
    }
    )

    return this.val;
  }

  onCheckboxClick(event: any, item: any) {
    if (event.checked) {
      this.selectedItems.push(item);
    } else {
      const index = this.selectedItems.indexOf(item);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    }
    // console.log("data for selected item" + this.selectedItems)
  }

  applyLeave(item: any) {
    console.log(item)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "90%"
    dialogConfig.panelClass = "apply-leave"

    var leaveApplicationDTO = new LeaveApplicationDTO();
    leaveApplicationDTO.userId = this.userdetails.getUserDetails().id;
    leaveApplicationDTO.careerManagerId = this.userdetails.getUserDetails().careerManagerId;
    leaveApplicationDTO.typeOfLeave = "PAID_LEAVE"
    leaveApplicationDTO.startDate = item.attendanceDateTime;
    leaveApplicationDTO.endDate = item.attendanceDateTime;
    leaveApplicationDTO.startSession = 1
    leaveApplicationDTO.endSession = 2
    leaveApplicationDTO.approvalStatus = ApprovalStatus.PENDING
    dialogConfig.data = {
      existingLeave: leaveApplicationDTO,
      isDisableDate: true
    };
    const dialogRef = this.dialog.open(ApplyNewleaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }


}
