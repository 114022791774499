<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit</h1>
    </div>
    <div class="child_close_btn">
        <button class="closeButton" (click)="closeDialog()">Close</button>
    </div>
</div>
<div class="container-fluid1">

   <div class="col-md-12 laptop">
        <div class="tab-list1">
            <mat-tab-group (selectedTabChange)="onTabChanged($event);">
                <mat-tab class="mat-tab-active-label">
                    <ng-template mat-tab-label>
                        <label class="tab_labels">Profile</label>
                    </ng-template>
                   <app-tab1-profile></app-tab1-profile>
                </mat-tab> 
                <mat-tab class="mat-tab-active-label">
                    <ng-template mat-tab-label>
                        <label class="tab_labels">Spotlight</label>
                    </ng-template>
                    <app-tab2-spotlight></app-tab2-spotlight>
                </mat-tab>
                <mat-tab class="mat-tab-active-label">
                    <ng-template mat-tab-label>
                        <label class="tab_labels">Correspondence</label>
                        
                    </ng-template>
                    <app-tab3-correspondence></app-tab3-correspondence>
                </mat-tab>
                <mat-tab class="mat-tab-active-label">
                    <ng-template mat-tab-label>
                        <label class="tab_labels">Vault</label>
                       
                    </ng-template>
                    <app-tab4-vault></app-tab4-vault>
                </mat-tab>
                <mat-tab class="mat-tab-active-label">
                    <ng-template mat-tab-label>
                        <label class="tab_labels">Business Travel</label>
                    </ng-template>
                    <app-tab5-businesstravel></app-tab5-businesstravel>
                </mat-tab>
            </mat-tab-group>

        </div>

    </div>
</div>