<mat-card class="main-card pr-0 pl-0">
  <div class="row">
    <div class="col-md-12">
      <label class="head ml-3">My Projects</label>
    </div>
  </div>
  <div   class="no_data_div">
    <img class="img" src="assets/no-data-available.png">
    <div class="no_data_label">No projects are assigned to you</div>
  </div>
  <!-- <div *ngIf="projects.length == 0" class="no_data_div">
    <img class="img" src="assets/no-data-available.png">
    <div class="no_data_label">No projects are assigned to you</div>
  </div>  
  <div class="row" *ngIf="projects.length > 0">
    <div class="col-md-12">
      <div class="tableFixHead">
        <div class="row mt-4" *ngFor="let project of projects">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="project_main_div">
              <span class="project_name">{{project.projectName}}</span>
            </div>
          </div>

          <div class="col-8">
            <div class="parents">
              <div class="child">
                <span class="holiday_name ml-3">In Progress</span>
              </div>

              <div class="child2">
                <mat-progress-bar class="example-margin mt-2" [color]="color" [mode]="mode" [min]="projects.endDate"
                  [bufferValue]="bufferValue">
                </mat-progress-bar>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div> -->


</mat-card>