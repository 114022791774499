
<div class="main_header_div" >
    <div class="child">
        <h1 class="header_name">{{ (!isExistingLeave ) ?  'Apply Leave': (isDisableInput ? 'Apply Leave':'Update Leave' )  }}</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>

    </div>
</div>
<hr>
<div class="loading_div " *ngIf="loadingflag >0">
    <mat-spinner strokeWidth="7" [diameter]="170"></mat-spinner>
    <label class="loading_label ">Loading...</label>

</div>

<div [hidden]="loadingflag >0">
    <form [formGroup]="newLeaveDetails">
        <div class="container-fluid" >
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Leave Type<span class="contentRed"></span></mat-label>
                        <mat-select formControlName="typeOfLeave" (selectionChange)="onLeaveTypeChange()">
                            <mat-option *ngFor="let leaveType of this.leavetypes" [value]="leaveType">
                                {{leaveType | underScoreToSpace}}
                            </mat-option>
                        </mat-select>
                        
                        <mat-error *ngIf="newLeaveDetails.get('typeOfLeave').errors?.required">Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3 leave_balance_main">
                    <label class="leave_balance">Leave Balance: {{leaveBalance | number:'1.2-2'}} {{dayBString}} </label>
                    <label class="leave_balance">Applying for: {{typedLADays}} {{dayAString}}</label>
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-4 text-primary">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <input matInput disabled value={{careerManagerEmailId}}>
                        <!-- <mat-card>
                            <mat-card-content>Simple card</mat-card-content>
                        </mat-card> -->

                    </mat-form-field>


                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Start Date <span class="contentRed"></span></mat-label>
                        <input [min]="this.minDateofYear" [max]="this.maxDateOfYear" [disabled]="isDisableInput" matInput [matDatepicker]="picker" formControlName="startDate" placeholder="mm/dd/yyyy"
                            (dateChange)="onStartDateChange($event.value)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="newLeaveDetails.get('startDate').errors?.required">Required</mat-error>
      
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Session<span class="contentRed"></span></mat-label>
                        <mat-select [disabled]="isDisableInput" formControlName="startSession" *ngIf="!isExistingLeave" [ngModel]="'First Half'"
                            (selectionChange)="onSessionChange()">
                            <mat-option *ngFor="let firstnsecondhalf of this.firstsecondhalf" [value]="firstnsecondhalf">
                                {{firstnsecondhalf | underScoreToSpace}}
                            </mat-option>
                        </mat-select>
                        <!-- for existing leave  -->
                        <mat-select [disabled]="isDisableInput" formControlName="startSession" *ngIf="isExistingLeave"
                            (selectionChange)="onSessionChange()">
                            <mat-option *ngFor="let firstnsecondhalf of this.firstsecondhalf" [value]="firstnsecondhalf">
                                {{firstnsecondhalf | underScoreToSpace}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-4">
                    <div class="inputField ">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Add Project Manager</mat-label>
                            <mat-select  formControlName="projectManagerId">
                                <input class="myInput" #myInput matInput focused="'true'"
                                    (keyup)="searchAllPMEmails($event.target.value)" autocomplete="off" placeholder="Search Email">

                                <mat-option *ngFor="let user of this.filteredPMEmails" [value]="user">
                                    {{user}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="newLeaveDetails.get('projectManagerId').errors?.required">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>End Date <span class="contentRed"></span></mat-label>
                        <input matInput [min]="newLeaveDetails.controls.startDate.value" [max]="this.maxDateOfYear"
                            [matDatepicker]="picker2" formControlName="endDate" placeholder="mm/dd/yyyy "
                            [disabled]="!newLeaveDetails.controls.startDate.value || isDisableInput" (dateChange)="onSessionChange()"
                            > 
                            <mat-error *ngIf="newLeaveDetails.controls.endDate.hasError('pattern')">{{errorMessage}}</mat-error>
                            <mat-error *ngIf="newLeaveDetails.get('endDate').errors?.required">Required</mat-error>
      
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Session</mat-label>
                        <mat-select [disabled]="isDisableInput" formControlName="endSession" *ngIf="!isExistingLeave" [ngModel]="'Second Half'"
                            (selectionChange)="onSessionChange()">
                            <mat-option *ngFor="let firstnsecondhalf of this.firstsecondhalf" [value]="firstnsecondhalf">
                                {{firstnsecondhalf | underScoreToSpace}}
                            </mat-option>
                        </mat-select>
                        <!-- for existing leave  -->
                        <mat-select [disabled]="isDisableInput" formControlName="endSession" *ngIf="isExistingLeave" 
                            (selectionChange)="onSessionChange()">
                            <mat-option *ngFor="let firstnsecondhalf of this.firstsecondhalf" [value]="firstnsecondhalf">
                                {{firstnsecondhalf | underScoreToSpace}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-4">
                    <div class="inputField ">
                        <!-- <input type="text" id="cityName" formControlName="ccMentions" placeholder="Add CC (Optional & Multiple)"> -->
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Add CC (Optional & Multiple) </mat-label>
                            <!-- <mat-select (selectionChange)="getAllEmpEmail($event.value)" formControlName="ccMentions" multiple (selectionChange)="onCCSelectionChange($event)"> -->
                            <mat-select formControlName="ccMentions" multiple (selectionChange)="onAddCCSelectionChange($event)">
                                <input class="myInput" #myInput matInput focused="'true'"
                                    (keyup)="searchAllEmails($event.target.value)" autocomplete="off" placeholder="Search Email">
                                <mat-option *ngFor="let user of this.filteredAllEmails" [value]="user">
                                    {{user}}
                                </mat-option></mat-select>
                        </mat-form-field>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <label class="resignation_reason_label">Reason For Leave</label>
                    <div>
                        <textarea matInput type="text" formControlName="reasonForLeave" class="resignation_reason_input">
                    </textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">

                    <div class="submit_button_main">

                        <div class=" certificate">
                            <img class="img_size" src="assets/Certificate.png">
                            <!-- <label for="my_file" class="certificate_label">Add a attachment related to the leave</label> -->
                            <label *ngIf="!isAttachmentSaved" for="my_file" class="certificate_label">Add an attachment related to
                                the leave</label>


                            <input *ngIf="!isAttachmentSaved" type="file" id="my_file" style="display: none;" multiple
                                accept=".png, .jpg, .pdf" (change)="uploadAttachment($event)" #fileInput />


                        <ng-container *ngIf="isAttachmentSaved ; else elseBlock">
                            <label class="certificate_label" (click)="open(content5)">
                                Preview attachment of Leave</label>
                            <button class="remove_label" (click)="removeAttachment()">Remove</button>
                        </ng-container>
                        <ng-template #content5 let-modal>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="modal-body" *ngIf="this.attachmentType === 'application/pdf'; else imageBlock">
                                <ng-container >
                                    <pdf-viewer [src]="attachmentBase64" [rotation]="0" [fit-to-page]="false" [original-size]="false" [show-all]="true"></pdf-viewer>
                                </ng-container>
                            </div>
                            <ng-template  #imageBlock>
                                <img [src]="attachmentBase64" alt="Error" width="100%" height="auto" />
                            </ng-template>
                        </ng-template>
                        <ng-template #elseBlock>
                        </ng-template>
                    </div>
                    <!-- button in not in div , can add below code
                    <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">-->
                    <button class="save_Emp_btn" type="submit" [disabled]=submitButtonDisabled (click)="onSubmit()">
                        <span>{{ isExistingLeave ? 'Save' : 'Apply' }}</span>
                    </button>
                </div>
            </div>

            </div>
        </div>
    </form>
</div>