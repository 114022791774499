import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MainContentProjectComponent } from '../../project/main-content-project/main-content-project.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackType } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { NewFeedbackDTO } from 'src/app/dtos/newFeedbackDTO';
import { FeedbackRole, FeedbackTemplateCategory } from 'src/app/services/feedback/feedback-enum';
import { FeedbackGroup } from 'src/app/models/feedbackGroup';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { ToastrService } from 'ngx-toastr';
import { TalentService } from 'src/app/services/talent/talent.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';

import { filter, first } from 'rxjs/operators';
import { AddRealtimeFeedbackComponent } from '../add-realtime-feedback/add-realtime-feedback.component';
@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  providers: [PipeUnderScoreToSpace]
})
export class FeedbackDialogComponent implements OnInit {
  public formList: FormArray;
  projects = [];
  FeedbackRole = [];
  FeedbackType = [];
  feedbackgroupdto = new FeedbackGroup();
  feedbackgroupupdatedto = [];
  error: boolean = false
  userNameIDMapping = {};
  userIdNameMapping = {}
  projectIDMapping = {};
  projectNameMapping = {}
  temp = {}
  ifhasAlreadyFeedbackGroupId = false;
  selectedYear: number;
  selectedAreas: string[];
  isVisible = [];
  userId = [];
  years: any[] = [];
  projectList;
  feedbackOpinion: FormArray;
  feedbackarr: FormArray;
  feedbackDetails: FormGroup;
  proList
  userList;
  performancevisible: boolean;
  performance:boolean=true;
  userID;
  titleOptions = []; // List of available options
  name;
  projectID;
  removedfeedbackopinion = [];
  selectedFormName: string;
  opiniondata: FormArray
  emailError: string;
  flag: boolean = false;
  Error: string
  uList;
  isEdit = true;
  firstName
  displaySelectedArea = [];
  p = 0;
  realTimeFeedback = [];
  titleSelected = [];
  selectedOptions: string[] = []; // To store selected checkbox values

  currentYear
  isRealTimeFeedback = false;
  tempStoreRealTimeFeedback: any = [];
  selectedTitle: any;
  projectIDMapping1: any = {};
  promise1: Promise<unknown>;
  isDuplicatesPresent = false

  constructor(public dialogRef: MatDialogRef<MainContentProjectComponent>,
    private dialog: MatDialog,
    public talentservice: TalentService, public userDetailService: UserDetailsService, public fb: FormBuilder, private feedbackservice: FeedbackService,
    private userService: UserService, private projectService: ProjectService,
    private feedbackgroupservice: NewFeedbackGroupService,
    public toastr: ToastrService,
    private userGoalService: UserGoalService,
    @Inject(MAT_DIALOG_DATA) public directReporteeId) {

    this.feedbackDetails = this.fb.group({
      groupWeightage: ['', [Validators.required]],
      projectId: ['',],
      year: ['', [Validators.required]],
      title: ['',],
      feedbackType: ['', [Validators.required]],
      feedbackArray: this.fb.array([
      ])
    })
  }
  async ngOnInit() {

    await this.getprojectList1();

    this.performancevisible = false
    this.isRealTimeFeedback = false

    this.userGoalService.getCurrentYear().subscribe(res => {
      this.currentYear = res;
    })
    this.processEnumValuesToArray();

    if (Object.keys(this.directReporteeId).length != 4) {
      this.userService.getAllUserForDropDown().subscribe(usrData => {

        usrData.body.forEach(element => {

          if (element.userID != this.directReporteeId.directReporteeId) {
            this.userNameIDMapping[element.firstName + " " + element.lastName + "(" + element.employeeId + ")"] = element.userID;
            this.userIdNameMapping[element.userID] = element.firstName + " " + element.lastName + "(" + element.employeeId + ")";
            this.temp[element.firstName + " " + element.lastName] = element.userID;
            // this.userId.push(element.employeeId);
          }
        });
        this.userList = Object.keys(this.userNameIDMapping)
        this.addComponent();
      })

      this.feedbackDetails.get('feedbackType').setValue('PROJECT');

    }
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear - 1; year <= this.selectedYear ; year++) {
      if (this.directReporteeId.closedate.length = 0) {
        for (let i of this.directReporteeId.closedate)
          if (year != i) {
            this.years.push(year.toString());
          }
      }
      else {
        this.years.push(year.toString());
      }
    }

    this.getuserList();
    this.getprojectList()
    this.setformdata();
  }
  search(query: string, i) {

    this.flag = true;
    let result = this.select(query)
    if (result.length != 0) {
      this.displaySelectedArea[i] = result;
    }
    this.displaySelectedArea[i] = result;
  }
  select(query: string): string[] {

    let result: string[] = [];
    for (let a of this.userList) {
      if (a.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        result.push(a)
      }
    }
    return result
  }
  focusOutEvent(i) {
    //  this.displaySelectedArea[i]=this.userList;
  }
  toggleCheckbox(option: string): void {

    const index = this.selectedOptions.indexOf(option);
    if (index !== -1) {
      this.selectedOptions.splice(index, 1);
    } else {
      this.selectedOptions.push(option);
    }
    console.log(this.selectedOptions)
    // Update the form control value
  }
  selectOption(option) {
    this.selectedTitle = option;  // Set the selected title

    this.opiniondata = this.feedbackDetails.get('feedbackArray') as FormArray;
    this.opiniondata.clear();
    this.isVisible.length = 0;
    this.p = 0;

    for (let s of this.tempStoreRealTimeFeedback) {
      if (this.selectedTitle === s.title || !this.selectedTitle) {
        this.isVisible[this.p] = true;
        this.p++;
        this.opiniondata.push(this.fb.group(s.feedbackFrom[0]));
      }
    }
  }
  onWeightagetyped(event) {
    let temp :number = 0
    if (this.directReporteeId.dataFeedback != null) {
      for (let i of this.directReporteeId.weightArray) {
        temp = temp + i
      }
      temp = temp * 100
      temp = (temp - (this.directReporteeId.dataFeedback.groupWeightage * 100))
      if ((temp + parseFloat(event)) > 100) {
        this.setFormControlError('groupWeightage', 'InvalidgroupWeightage', 'Please Enter Valid Percentage');
      }
    }
    else {
      for (let i of this.directReporteeId.weightArray) {
        temp = temp + (i * 100)
      }
      if ((temp + parseInt(event)) > 100) {
        this.setFormControlError('groupWeightage', 'InvalidgroupWeightage', 'Please Enter Valid Percentage');
      }
      else if (!parseInt(event)) {
        this.setFormControlError('groupWeightage', 'InvalidgroupWeightage', 'Please Enter Valid Percentage');
      }
    }
  }
  setFormControlError(controlName: string, controlError: string, errorMsg: string) {
    this.feedbackDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.feedbackDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.feedbackDetails.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg ? errorMsg : 'Enter valid percentage';
  }
  setOpinionFormControlError(controlName: string, controlError: string, errorMsg: string, i: number) {

    this.feedbackarr = this.feedbackDetails.get('feedbackArray') as FormArray;
    this.feedbackarr.controls[i].get(controlName).markAsTouched({ onlySelf: true });
    this.feedbackarr.controls[i].get(controlName).markAsDirty({ onlySelf: true });
    this.feedbackarr.controls[i].get(controlName).setErrors({ [controlError]: true });
    this.Error = errorMsg;
  }
  onOpinionWeightagetyped(event, i) {
    let temp = 0
    for (let feedback of this.feedbackDetails.value.feedbackArray) {
      temp = temp + parseInt(feedback.weightage)
      if (temp > 100) {
        this.setOpinionFormControlError('weightage', 'InvalidWeightage', 'Enter Valid Percentage', i);
      }
    }
  }

  loadForm(event) {
    if (event.value == 'REGULAR_FEEDBACK') {

      this.performancevisible = true;


      this.isVisible.length = 0
      this.p = 0;
      this.opiniondata.clear();
    }
    else if (event.value == 'PROJECT_REVIEW_FEEDBACK') {
      this.performancevisible = false

      this.opiniondata.clear();
      this.isVisible.length = 0
      this.p = 0;

    }
    // else if (event.value == 'REAL_TIME_FEEDBACK') {
    //   this.performance= false;
    // }
  }
  setformdata() {

    if (this.directReporteeId.dataFeedback != null) {

      this.isEdit = false;
      if (this.directReporteeId.dataFeedback.title != null) {
        this.performancevisible = true
        this.feedbackDetails.get('title').setValue(this.directReporteeId.dataFeedback.title ? this.directReporteeId.dataFeedback.title : '');
      }
      else if (this.directReporteeId.dataFeedback.projectId != null) {
        this.performancevisible = false
        this.feedbackDetails.get('projectId').setValue(this.projectIDMapping1[this.directReporteeId.dataFeedback.projectId] ? this.projectIDMapping1[this.directReporteeId.dataFeedback.projectId] : '');
      }

      this.feedbackDetails.get('feedbackType').setValue(FeedbackType[this.directReporteeId.dataFeedback.feedbackType] ? FeedbackType[this.directReporteeId.dataFeedback.feedbackType] : '');
      this.feedbackDetails.get('year').setValue(this.directReporteeId.dataFeedback.year ? this.directReporteeId.dataFeedback.year : '');

      this.feedbackDetails.get('groupWeightage').setValue(this.directReporteeId.dataFeedback.groupWeightage ? (this.directReporteeId.dataFeedback.groupWeightage * 100) : '');
    }
    else {
      this.feedbackDetails.get('year').setValue(this.currentYear.toString());

    }
  }

  async getprojectList1() {
    this.promise1 = new Promise((resolve, reject) => {
      this.projectService.getProjectsSubscribe().subscribe(proList => {
        proList.body.forEach(element => {
          this.projectIDMapping1[element.id] = element.projectName;
        });
        resolve(this.projectIDMapping1)
      });
    })
    await this.promise1;
  }
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }
  getprojectList() {
    if (Object.keys(this.directReporteeId).length == 4) {
      this.talentservice.getTalentByUserIdSubscribe(this.directReporteeId.directReporteeId).subscribe(res => {
        for (let r of res.body) {
          this.projectService.getProjectById(r.projectId).subscribe(proList => {
            this.projectIDMapping[proList.body.projectName] = proList.body.id;
            this.projectNameMapping[proList.body.id] = proList.body.projectName;
            this.projectList = Object.keys(this.projectIDMapping);
          })
        }
      })
    }
    else if (this.directReporteeId.dataFeedback != null) {
      this.talentservice.getTalentByUserIdSubscribe(this.directReporteeId.dataFeedback.feedbackFor).subscribe(res => {
        for (let r of res.body) {
          this.projectService.getProjectById(r.projectId).subscribe(proList => {
            proList.body.forEach(element => {
              this.projectIDMapping[element.projectName] = element.id;
              this.projectNameMapping[element.id] = element.projectName;
            })
            this.projectList = Object.keys(this.projectIDMapping);
          })
        }
      });
    }
  }
  deleteFeedbackGroup() {
    this.feedbackgroupservice.delete(this.directReporteeId.dataFeedback.id).subscribe(res => {
      this.dialogRef.close()
      this.toastr.success('Successfully Deleted', '', {
      });
    }, err => {
      this.toastr.error("You cannot delete this group", err.error.title);
    });
  }
  getProjectID(projectname) {

    this.projectID = this.projectIDMapping[projectname];
  }
  changedFieldType(index) {
    let validators = null;
    if (this.getFormGroup(index).controls['type'].value === 'email') {
      validators = Validators.compose([Validators.required, Validators.email]);
    } else {
      validators = Validators.compose([
        Validators.required,
        Validators.pattern(new RegExp('^\\+[0-9]?()[0-9](\\d[0-9]{9})$'))
      ]);
    }
    this.getFormGroup(index).controls['value'].setValidators(
      validators
    );
    this.getFormGroup(index).controls['value'].updateValueAndValidity();
  }
  getFormGroup(index): FormGroup {
    const formGroup = this.formList.controls[index] as FormGroup;
    return formGroup;
  }
  createfeedbackDemo() {

    let formGroup = this.fb.group(new NewFeedbackDTO());
    formGroup.controls.feedbackFrom.setValidators([Validators.required]);
    formGroup.controls.weightage.setValidators([Validators.required]);
    formGroup.controls.feedbackRole.setValidators([Validators.required]);
    return formGroup;
  }
  opinion(): FormArray {
    return this.feedbackDetails.get('feedbackArray') as FormArray;
  }

  addRealtimeFeedback() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90%";
    dialogConfig.height = "60%";
    dialogConfig.data = {
      directReporteeDetails: this.directReporteeId,
    }
    const dialogRef = this.dialog.open(AddRealtimeFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(realTimeFeedbackresult => {


      if (realTimeFeedbackresult.length != 0) {
        this.opiniondata = this.feedbackDetails.get('feedbackArray') as FormArray;

        this.p = 0;
        this.isVisible.length = 0;
        for (let i of this.opiniondata.value) {
          if (i.feedbackSubmissionStatus === 'SUBMITTED' || i.isManaged) {
            this.isVisible[this.p] = true;
          }
          else {
            this.isVisible[this.p] = false;
          }

          this.p++;
        }
        let istrue = false;
        if (this.opiniondata.value.length == 0) {
          this.addComponent();
          istrue = true;

        }
        for (let s of realTimeFeedbackresult) {

          this.isVisible[this.p] = true;
          this.p++;

          if (istrue) {
            this.opinion().removeAt(0);
            istrue = false;
          }
          s.feedbackFrom = this.userIdNameMapping[s.feedbackFrom].split('(')[0];
          s.feedbackType = "RealTime";

          this.opiniondata.push(this.fb.group(s));
        }

        this.isVisible[this.p] = false;
        this.p++;

      }
    });

  }

  addComponent() {

    this.displaySelectedArea
    this.feedbackOpinion = this.feedbackDetails.get('feedbackArray') as FormArray;
    // let t=this.isVisible[0];
    // this.isVisible[0]=false;
    // this.isVisible.push(true);
    this.isVisible.push(this.isVisible[this.isVisible.length - 1]);
    for (let i = this.isVisible.length - 1; i > 0; i--) {
      this.isVisible[i] = this.isVisible[i - 1];
    }
    this.isVisible[0] = false;

    this.displaySelectedArea.push(this.userList);
    this.feedbackOpinion.insert(0, this.createfeedbackDemo());
  }
  processEnumValuesToArray() {

    for (let feedback of Object.values(FeedbackType)) {
      if (isNaN(Number(feedback)) && feedback != 'PROBATION') {
        //probation feedback shouldnt go here
        this.projects.push(feedback);
      }

    }
    for (let role of Object.values(FeedbackRole)) {
      if (isNaN(Number(role))) {
        this.FeedbackRole.push(role);
      }
    }
    for (let type of Object.values(FeedbackTemplateCategory)) {
      if (isNaN(Number(type)))
        this.FeedbackType.push(type);
    }
  }
  closeDialog() {
    this.dialogRef.close()
  }
  removefeedbackopinion(id, i: number) {

    this.removedfeedbackopinion.push(id);
    this.isVisible.splice(i, 1);
    this.opinion().removeAt(i);
    this.p--;
  }
  checkError = (controlName: string, errorName: string) => {
    return this.feedbackDetails.controls[controlName].hasError(errorName);
  }
  getUserID(username, i) {

    this.userID = this.userNameIDMapping[username] || this.temp[username];
    this.displaySelectedArea[i] = this.userList;
  }
  getUserName(id) {

    this.name = this.userIdNameMapping[id];
    return this.name;
  }
  getuserList() {

    this.userService.getAllUserForDropDown().subscribe(usrData => {

      usrData.body.forEach(element => {

        if (element.userID != this.directReporteeId.directReporteeId) {
          this.userNameIDMapping[element.firstName + " " + element.lastName + "(" + element.employeeId + ")"] = element.userID;
          this.userIdNameMapping[element.userID] = element.firstName + " " + element.lastName + "(" + element.employeeId + ")";
          this.temp[element.firstName + " " + element.lastName] = element.userID;
          // this.userId.push(element.employeeId);
        }
      });


      this.userList = Object.keys(this.userNameIDMapping); let i = 0;
      this.selectedAreas = this.userList
      if (this.directReporteeId.dataFeedback != null) {

        if (this.directReporteeId.dataFeedback.feedbackFrom != null) {


          this.addComponent();
          this.opiniondata = this.feedbackDetails.get('feedbackArray') as FormArray;
          this.opiniondata.clear();
          for (let s of this.directReporteeId.dataFeedback.feedbackFrom) {

            this.ifhasAlreadyFeedbackGroupId = true;
            this.isVisible[this.p] = true;
            this.p++;
            s.isManaged = true;
            this.opiniondata.push(

              this.fb.group(s)
            )

            this.displaySelectedArea.push(this.selectedAreas)
          }

        }
      }

      // this.displaySelectedArea=this.selectedAreas;
      usrData.body.forEach(element => {

      })

      this.uList = Object.keys(this.temp);
      // for(let x of this.userList)
      //     {
      //       this.userList[i]=x.toLowerCase();i++;
      //     }
    });
  }

  removeTextInParentheses(inputString) {
    const resultString = inputString.replace(/\([^)]*\)/g, ' ');
    return resultString.trim();
  }

  filterControls(feedbackArray: any[]) {

    const duplicateFeedbackArray = [];

    feedbackArray.forEach((feedback, index) => {

      const isDuplicate = feedbackArray.some((item, i) =>
        i !== index &&
        (item.feedbackFrom === this.removeTextInParentheses(feedback.feedbackFrom)) &&
        item.feedbackRole === feedback.feedbackRole
      );
      if (isDuplicate) {
        duplicateFeedbackArray.push(feedback);
      }
    });

    if (duplicateFeedbackArray.length !== 0) {
     return true
    } else {
     return false
    }

  }
  

  onSubmit() {
    debugger
    this.realTimeFeedback = []
    for (let feedback of this.feedbackDetails.value.feedbackArray) {
      if (feedback.feedbackType == "RealTime") {
        this.realTimeFeedback.push(feedback.id)
        // feedback.feedbackFrom = this.temp[feedback.feedbackFrom]
      }
    }
    // this.filterControls("PEER")
   this.isDuplicatesPresent = this.filterControls(this.feedbackDetails.value.feedbackArray)
    if (!this.isDuplicatesPresent) {

      let t = this.feedbackDetails.get('feedbackType').value;
      const indexOfS = Object.values(FeedbackType).indexOf(t as unknown as FeedbackType);

      const key = Object.keys(FeedbackType)[indexOfS];
      if (this.feedbackDetails.valid) {
        if (this.performancevisible == false) {
          this.feedbackgroupdto.projectId = this.projectID;
        }
        else {
          this.feedbackgroupdto.title = this.feedbackDetails.get('title').value;
        }

        this.feedbackgroupdto.feedbackType = this.feedbackDetails.get('feedbackType').value == 1 ? this.feedbackDetails.get('feedbackType').value : key;
        if (this.directReporteeId.dataFeedback != null) {
          this.feedbackgroupdto.feedbackFor = this.directReporteeId.dataFeedback.feedbackFor
        }
        else {
          this.feedbackgroupdto.feedbackFor = this.directReporteeId.directReporteeId
        }
        this.feedbackgroupdto.year = this.feedbackDetails.get('year').value;
        this.feedbackgroupdto.groupWeightage = this.feedbackDetails.get('groupWeightage').value;
        this.feedbackgroupdto.groupWeightage = this.feedbackgroupdto.groupWeightage / 100;
        this.feedbackgroupdto.groupWeightage=Number(this.feedbackgroupdto.groupWeightage.toFixed(3));
        for (let i of this.removedfeedbackopinion) {
          this.feedbackservice.delete(i).subscribe(res => {
          })
        }
        for (let feedback of this.feedbackDetails.value.feedbackArray) {

        feedback.feedbackFrom = this.userNameIDMapping[feedback.feedbackFrom] || this.temp[feedback.feedbackFrom];
        if (feedback && !feedback.id) {

            feedback.weightage = feedback.weightage / 100;
            this.feedbackgroupdto.feedbackFrom.push(feedback)
            if (feedback.feedbackRole == "SELF_EVALUATION") {

              feedback.feedbackFrom = this.directReporteeId.directReporteeId;
            }
            this.feedbackgroupupdatedto.push(feedback)
          }
        }
        if (this.directReporteeId.dataFeedback != null) {

          this.feedbackgroupservice.update(this.feedbackgroupupdatedto, this.directReporteeId.dataFeedback.id, this.feedbackgroupdto.groupWeightage).subscribe(res => {

            this.feedbackgroupservice.setRealTimeFeedback(res.body.id, this.realTimeFeedback).subscribe(res => {

              this.toastr.success('Successfully uploaded', 'Saved', {
              });
              this.dialogRef.close()
            })

          })
        }
        else {

          this.feedbackgroupservice.create(this.feedbackgroupdto).subscribe(res => {


            this.feedbackgroupservice.setRealTimeFeedback(res.body.id, this.realTimeFeedback).subscribe(res => {

              this.toastr.success('Successfully uploaded', 'Saved', {
              });
              this.dialogRef.close()
            })

          })
        }
      }
      else {
        this.toastr.error('Enter all required fields', 'Error', {
        });
      }
    }
    else{
      this.toastr.error('Duplicate entries are present', 'Error', {
      });
    }
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }


  // this.dataActual[0].forEach((values, keys) => {
  //   if (this.goal.includes(keys.goalType) == false && values == false) {
  //     this.usergoalservice.delete(keys.id);
  //   }
  // });

  selectedRole(role, i) {

    if (role == "SELF_EVALUATION") {
      this.isVisible[i] = true;
      this.feedbackOpinion.controls[i].setValue({ feedbackFrom: "Shubham Wasu(BA000107)", feedbackRole: "SELF_EVALUATION", weightage: 0, score: 0 });
      //this.getUserID('Shubham Wasu(BA00107s)',i)
    }
  }

}