import { Injectable } from '@angular/core';
import { RestAPIService } from './restAPI/rest-api.service';
import { Task } from '../models/task';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaskDTO } from '../dtos/TaskDTO';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private restApiService: RestAPIService) {}
  
  getAll(): Observable<Task[]> {

    return this.restApiService.get('/api/task').pipe(
      map(res => res.body)
    );
  }

  getAllByUserId(userId: String):Observable<Task[]>{
    return this.restApiService.get(`/api/task/user/${userId}`).pipe(
      map(res => res.body)
    );
  }

  updateTask(taskDTO: TaskDTO){
    return this.restApiService.put(`/api/task`,taskDTO)
  }

  // jsonServergetAll(): Observable<Task[]> {

  //   return this.restApiService.jsonServerGet('/tasks').pipe(
  //     map(res => res.body)
  //   );
  // }
}
