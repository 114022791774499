import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { AttendanceHistoryUrlServiceService } from '../restAPI/attendanceHistoryURLs/attendance-history-url-service.service';

@Injectable({
  providedIn: 'root'
})
export class AttendanceHistoryService {

  constructor(private restApiService: RestAPIService,
    private attendaceHistoryUrl:AttendanceHistoryUrlServiceService
    ) { }
  
    getAttendaceHistory(year){
      return this.restApiService.get(
        this.attendaceHistoryUrl.getAttendanceHistoryByUserId(year)
      )
    }
    getOnLeaveReportees(userId,status){
      return this.restApiService.get(
        this.attendaceHistoryUrl.getOnLeaveUrl(status)
      )
    }
}
