import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-leave-policy',
  templateUrl: './view-leave-policy.component.html',
  styleUrls: ['./view-leave-policy.component.scss']
})
export class ViewLeavePolicyComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ViewLeavePolicyComponent>
  ) { }

  ngOnInit(): void {
  }
  closeDialog() {
    this.dialogRef.close()
  }

}
