import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class HolidayEventUrlService {
  private readonly HolidayEventBaseUrl = '/api/get-leaves-by-year';
  private readonly HolidayStatusUrl = '/api/get-restricted-leave-status';
  private readonly userHolidaysUrl = '/api/get_leave_by_year_for_user';


  constructor() { }

  getBaseUrl() {
    return this.HolidayEventBaseUrl;
  }

  getHolidayEvents(year) {
    return this.HolidayEventBaseUrl + '/' + year;
  }
  getHolidaysByUserandYear(userId,year){
    return this.userHolidaysUrl+'/'+userId+'/'+year;
  }
  getHolidayStatus(id) {
    return this.HolidayStatusUrl + '/' + id;
  }




}
