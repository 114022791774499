import { Component, OnInit,Inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbsentService } from 'src/app/services/absent/absent.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import { MatFormField } from '@angular/material/form-field';
import { ApplyRegularizationDTO } from 'src/app/dtos/ApplyRegularizationDTO';
import { RegularizationService } from 'src/app/services/regularization/regularization.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserService } from 'src/app/services/user/user.service';
// import { TaskComponent } from '../../tasks/task/task.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RegularizeDates } from 'src/app/models/RegularizeDates';
@Component({
  selector: 'app-bulk-regularize',
  templateUrl: './bulk-regularize.component.html',
  styleUrls: ['./bulk-regularize.component.scss']
})
export class BulkRegularizeComponent implements OnInit {
  value:any[]=[];
  val:any[]=[];
  dat:any;
  regularizationDetails: FormGroup;
  applyRegulrizationDTO = new ApplyRegularizationDTO();
  constructor(
    public dialogRef: MatDialogRef<BulkRegularizeComponent>,
    private userDetail: UserDetailsService,
    private userdetails :UserDetailsService,
    private absentService: AbsentService,
    public http: HttpClient,
    public fb: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private userDetailsService: UserDetailsService,
    public regularizationService: RegularizationService,
    @Inject(MAT_DIALOG_DATA) public selectedItems: any[]) {
      console.log(this.selectedItems)
    this.regularizationDetails = this.fb.group({
      reasonForRegularization: ['', [Validators.required]],
    })
  }
  ngOnInit(): void {
   
    const user  = this.userdetails.getUserDetails();
    // this.getStatus(user.id,'UNMARKED_ATTENDANCE');
    // this.getState(user.id,'UNMARKED_ATTENDANCE')
  }
  applyRegularization(){
    console.log("api callled")
    if(!this.regularizationDetails.valid) return;
    const user  = this.userDetailsService.getUserDetails()
    this.applyRegulrizationDTO.reasonForRegularization = this.regularizationDetails.value.reasonForRegularization;
    this.applyRegulrizationDTO.userId = user.id;
    const selectedData= this.selectedItems;
    const regularizeDates = selectedData.map(item => {
      return {
        attendanceId: item.id,
        id: undefined,
        regularizeDateTime: item.attendanceDateTime,
      };
    });
    
    this.applyRegulrizationDTO.regularizeDates=regularizeDates;
    this.regularizationService.applyForRealization(this.applyRegulrizationDTO).subscribe(res => {
      this.notificationService.sucess("Applied for regularization");
      this.closeDialog();
    },
    error => {
      if (error.status == 403){
        this.closeDialog();
        this.router.navigate(['/unauthorized']);
      }
    });
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Closed'});
  }
  // getStatus(userId,attendanceStatus){
  //   const user  = this.userdetails.getUserDetails();
  //   this.absentService.getstatusbyuser(user.id,'UNMARKED_ATTENDANCE').subscribe(res => {
  //     for(let i=0;i<res.body.length;i++) {
  //       this.dat=moment(res.body[i].attendanceDateTime).format('MMMM DD-MM-YYYY');
  //       this.val.push(this.dat);
  //     }
  //   }
  //   )
  //   return this.val;
  // }

  // getState(userId,attendanceStatus){
  //   const user  = this.userdetails.getUserDetails();
  //   this.absentService.getstatusbyuser(user.id,'UNMARKED_ATTENDANCE').subscribe(res => {
  //     for(let i=0;i<res.body.length;i++) {
  //     const detail ={
  //       attendanceId:res.body[i].id,
  //       regularizeDateTime:res.body[i].attendanceDateTime,

  //     }
  //     this.value.push(detail);
  //     }

  //   },(e)=>{
  //     console.log(e);
  //   }
  //   )

  //   return this.value;
  // }
  
}





