import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class ClientHolidaysURLsService {

  private readonly clientHolidayBaseApi = '/api/get-leaves-by-client';
  private readonly bothHolidaysApi='/api/get-ba-general-restricted-leaves';

  constructor(private restApi: RestAPIService) { }

  getBaseUrl() { 
    return this.clientHolidayBaseApi;
  }

  getLeavesByClientIdAndYear(clientId,Year,leaveType){
    return this.clientHolidayBaseApi + '/' + clientId + '/' + Year + '/' + leaveType;
  }

  getGeneralandRestricted(year){
    return this.bothHolidaysApi + '/' + year;
  }
}