import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { ToastrService } from 'ngx-toastr';
import { EducationDetailsDTO } from 'src/app/dtos/EducationDetailsDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { Education } from 'src/app/models/educationdetails';
import { User } from 'src/app/models/user';
import { EducationDetailsService } from 'src/app/services/education-details/education-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';

//import { EducationDetailsService } from 'src/app/services/educationDetails/education-details.service';

@Component({
  selector: 'app-educational-qualifications',
  templateUrl: './educational-qualifications.component.html',
  styleUrls: ['./educational-qualifications.component.scss']
})
export class EducationalQualificationsComponent implements OnInit {

  @ViewChild('fileInput') fileInput;

  user_details: any;
  Educationgroup: FormArray;
  userdto = new UserDTO();

  removedEducation = [];
  editSpotlightDetails: FormGroup;
  constructor(public fb: FormBuilder,
    public educationservice: EducationDetailsService,
    public dialog: MatDialog, public userService: UserService,
    public userDetailService: UserDetailsService,
    public toaster: ToastrService,
    private dialogref: MatDialogRef<EducationalQualificationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {
    this.editSpotlightDetails = this.fb.group({

      education: this.fb.array([]),

    })
  }
  ngOnInit(): void {

    //this.data contains userid

    // this.userdto = this.userDetailService.getUserDetails();


    // this.educationservice.getskillByUserIdSubscribe(this.userDetailService.getUserDetails().id).subscribe(res => {
    this.educationservice.getskillByUserIdSubscribe(this.data).subscribe(res => {
      if (res.body[0] == null) {
        this.addEducation();
      }
      this.setEducationFormArray(res.body);
    })
  }


  createeducation() {
    let formGroup = this.fb.group(new Education());
    formGroup.controls.courseName.setValidators([Validators.required]);
    formGroup.controls.yearofCompletion.setValidators([Validators.required]);
    formGroup.controls.yearOfBeginning.setValidators([Validators.required]);

    return formGroup;
  }
  setEducationFormArray(response) {
    this.Educationgroup = this.editSpotlightDetails.get(
      'education'
    ) as FormArray;
    let eduList: Education[] =
      this.educationservice.setEducationResponse(response);
    for (let s of eduList) {
      
      let v: Education = new Education().bind(s);
      let educationGroup = this.createeducation();
      educationGroup.controls.courseName.setValue(v.courseName);
      educationGroup.controls.yearofCompletion.setValue(v.yearofCompletion);
      educationGroup.controls.yearOfBeginning.setValue(v.yearOfBeginning);
      educationGroup.controls.id.setValue(v.id);
      this.Educationgroup.insert(0, educationGroup);
    }
  }

  Education(): FormArray {
    return this.editSpotlightDetails.get('education') as FormArray;
  }
  addEducation() {
    this.Educationgroup = this.editSpotlightDetails.get(
      'education'
    ) as FormArray;
    this.Educationgroup.insert(0, this.createeducation());
  }
  removeeducation(id, i: number) {
    this.removedEducation.push(id);
    this.Education().removeAt(i);
  }

  closeDialog(event: Event) {
    this.dialogref.close({ event: 'Closed' })
  }
  onSubmit() {
    if (this.editSpotlightDetails.valid) {
      console.log("this is the object", this.editSpotlightDetails.value.education)
      for (let edu of this.editSpotlightDetails.value.education) {       
        if (
          edu &&
          !edu.id &&
          edu.courseName != '' &&
          edu.yearofCompletion != undefined &&
          edu.yearOfBeginning != undefined

        ) {         
          // edu.userId = this.userDetailService.getUserDetails().id;
          edu.userId = this.data;
          this.educationservice.create(edu).subscribe((res) => {
            // this.toaster.success("Successfully Saved")
            this.dialogref.close({ event: 'Closed' });
            this.toaster.success("Successfully Saved");
            //this.notificationService.sucess('done');
          });
        } else if (
          edu &&
          edu.id &&
          edu.courseName != '' &&
          edu.yearofCompletion != undefined &&
          edu.yearOfBeginning != undefined
        ) {
          // edu.userId = this.userDetailService.getUserDetails().id;
          edu.userId = this.data;
          this.educationservice.update(edu).subscribe((res) => {
            // this.toaster.success("Successfully Saved")
            this.dialogref.close({ event: 'Closed' });
            this.toaster.success("Successfully Saved");
            //this.notificationService.sucess('Updated');
          });
        }
      }
      for (let i in this.removedEducation) {
        this.educationservice.delete(this.removedEducation[i]);
        this.dialogref.close({ event: 'Closed' })
      }
    }
    //this.toaster.success("Successfully Saved")
  }
}



