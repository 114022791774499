import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddRealTimeFeedbackComponent } from '../add-real-time-feedback/add-real-time-feedback.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { UserService } from 'src/app/services/user/user.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JsonFormComponent } from '../../json-form/json-form.component';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.scss']
})
export class PublicProfileComponent implements OnInit {
  @Input() dataFromParent: string;
  userId: any;
  years: any = [];
  BAstartYear: any = 2022;
  selectedYear: any = new Date().getFullYear();
  feedbackList: any[] = [];
  feedback: any[] = [];
  createdForName: any;
  loggedInProfile: any;
  private dataSubscription: Subscription;
  userid: any;

  constructor(
    private dialog: MatDialog,
    public userDetailService: UserDetailsService,
    public userService: UserService,
    private feedbackService: FeedbackService,
    private user: UserService,
    private formSchema: FormsSchemaService,
    private route: ActivatedRoute,
    private router: Router,
    public tosterService: ToastrService,
  ) {

    this.dataSubscription = this.feedbackService.data$.subscribe(data => {
      this.userid = data;
      this.feedbackList = [];
      this.feedback = [];
      this.ngOnInit();
      this.getFeedbackDetails();
      // other option is to use ? : operator to solve dupluiction issue
    });
  }

  ngOnInit(): void {
    this.years = [];
    for (let i = this.BAstartYear; i <= new Date().getFullYear(); i++) {
      this.years.push(i);
    }

    this.feedbackList = [];

    if (this.userid == null)
      this.userid = this.route.snapshot.paramMap.get('id');

    if (this.userid == null) {
      this.userid = this.userDetailService.getUserDetails().id;
      this.loggedInProfile = true;
    } else {
      this.loggedInProfile = false;
    }
    if (this.userid == this.userDetailService.getUserDetails().id) {
      this.loggedInProfile = true;
    }

    this.userId = this.userDetailService.getUserDetails().id;
    this.getFeedbacks();
    // this.getFeedbackDetails();

  }

  getFeedbacks() {
    this.feedbackService.getAllRealTimeFeedbackByUser(this.userId).subscribe(res => {
      this.feedbackList = [];
      for (let r of res.body) {
        const feedbackForId = r.feedbackFor;
        if (r.year == this.selectedYear) {
          this.userService.getUserByID(feedbackForId).subscribe(resp => {

            const createdForName = resp.body.firstName + " " + resp.body.lastName;
            const userImage = resp.body.userImageThumbnail;
            const title = r.title;
            const score = r.feedbackFrom[0].score;
            const role = r.feedbackFrom[0].feedbackRole;
            const date = this.transform(r.feedbackFrom[0].createdAt);;
            const id = r.feedbackFrom[0].id;
            const updatedAt = r.feedbackFrom[0].updatedAt;
            const isEditable = this.isEditable(updatedAt)

            const feedbackItem = {
              name: createdForName,
              score: score,
              title: title,
              role: role,
              date: date,
              userImageThumbnail: userImage,
              isContinue: r.feedbackFrom[0].feedbackSubmissionStatus === 'READ' ? true : false,
              isSubmitted:r.feedbackFrom[0].feedbackSubmissionStatus === 'SUBMITTED' ? true : false ,
              id: id,
              isEditable: isEditable

            };

            const isDuplicate = this.feedbackList.some(item => (

              item.id === feedbackItem.id
            ));

            if (!isDuplicate) {
              this.feedbackList.push(feedbackItem);
            } else {
              // console.log('duplicate ' + feedbackItem)
            }

          });
        }

      }

    })
  }

  isEditable(updatedAt): boolean {
    const currentTime = new Date();
    const updatedAtTime = new Date(updatedAt);
    const timeDifference = currentTime.getTime() - updatedAtTime.getTime();
    const hoursDifference = timeDifference / (1000 * 3600);
    return hoursDifference < 48;
  }

  getFeedbackDetails() {

    this.feedback.length = 0;
    this.feedbackService.getFeedbackByUserIdandYear(this.userid, this.selectedYear).subscribe(res => {
      this.feedback = [];

      for (let r of res.body) {
        const feedbackForUser = this.userid;
        this.userService.getUserByID(feedbackForUser).subscribe((response) => {

          const createdForName = response.body.firstName + " " + response.body.lastName;
          const userImage = response.body.userImageThumbnail;
          const title = r.title;
          const score = r.feedbackFrom[0].score;
          const role = r.feedbackFrom[0].feedbackRole;
          const date = this.transform(r.feedbackFrom[0].createdAt);;
          const id = r.feedbackFrom[0].id;
          const updatedAt = r.feedbackFrom[0].updatedAt;
          const isEditable = this.isEditable(updatedAt)

          const feedbackItem = {
            name: createdForName,
            score: score,
            title: title,
            role: role,
            userImageThumbnail: userImage,
            isContinue: r.feedbackFrom[0].feedbackSubmissionStatus === 'READ' ? true : false,
            isSubmitted:r.feedbackFrom[0].feedbackSubmissionStatus === 'SUBMITTED' ? true : false ,
            id: id,
            date: date,
            isEditable: isEditable

          };

          this.feedback.push(feedbackItem);
        })
      }
    })
  }

  deleteFeedback(feedback) {
    this.feedbackService.delete(feedback.id).subscribe(res => {
      this.feedbackService.filter('');
      this.tosterService.success('Feedback deleted!')
      this.getFeedbackDetails();
      this.getFeedbacks();
    }, (e) => {
      this.tosterService.error('Saved feedback cannot be deleted!')
    })
  }

  editFeedback(feedback) {
    this.feedbackService.editRealTimeFeedback(feedback.id).subscribe(res => {
      this.OpenJSonForm(feedback.id);


    })
  }
  async OpenJSonForm(feedbackId) {
    await this.feedbackService.getFeedBackByIdAsync(feedbackId);
    var resOfFeedBackAfterParse = await this.feedbackService.resOfFeedBackAfterParse;
    var resOfFeedBackGroupAfterParse = await this.feedbackService.resOfFeedBackGroupAfterParse;
    var timeFrame = resOfFeedBackGroupAfterParse.year;
    await this.user.getUserByIdAsync(resOfFeedBackGroupAfterParse.feedbackFor);
    var assinedUserAfterParse = await this.user.assinedUserAfterParse;
    var assinedManagerUseAfterParse = await this.user.assinedManagerUseAfterParse;
    await this.formSchema.getFormSchemaByRoleAndJobTitleAsync(resOfFeedBackAfterParse.feedbackRole, assinedUserAfterParse.jobTitle);
    var formSchemaAfterParse = await this.formSchema.formSchemaAfterParse;
    var temp;
    var ifSave = false;
    if (resOfFeedBackAfterParse.status == 'READ') {
      temp = resOfFeedBackAfterParse.submission;
      ifSave = true;
    }
    else {
      temp = formSchemaAfterParse.formTemplate;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "95vh";
    dialogConfig.maxWidth = "85%";
    dialogConfig.data = [temp, feedbackId,
      assinedUserAfterParse.firstName + " " + assinedUserAfterParse.lastName,
      ifSave, assinedManagerUseAfterParse.firstName + " " + assinedManagerUseAfterParse.lastName, timeFrame, false, 'FEEDBACK'];
    dialogConfig.panelClass = "feedbackform-overlay";
    const dialogRef = this.dialog.open(JsonFormComponent, dialogConfig);
  }
  trackByFn(index: number, item: any): number {
    return item.id;
  }
  transform(dateString: string): Date {
    return new Date(dateString);
  }

  redirectToTask(feedbackId) {
    this.OpenJSonForm(feedbackId)
    // this.router.navigate(['/task']);
  }
  AssignFeedback() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    dialogConfig.panelClass = "dialog-class";
    dialogConfig.data = this.dataFromParent;
    const dialogRef = this.dialog.open(AddRealTimeFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}