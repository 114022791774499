<div *ngIf="this.temp==true">
    <div class="card-container1">



        <div class="matcardprojectdetail1 ">

            <div class="section">
                <svg width="32" height="40" viewBox="0 0 16 20" fill="#0045b9" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4 9.75H12V8.25H4V9.75ZM4 5.75H12V4.25H4V5.75ZM15.95 18.9L11.7 13.35C11.4333 13.0167 11.1083 12.75 10.725 12.55C10.3417 12.35 9.93333 12.25 9.5 12.25H0V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H14.5C14.9 0 15.25 0.15 15.55 0.45C15.85 0.75 16 1.1 16 1.5V18.5C16 18.5667 15.9958 18.6333 15.9875 18.7C15.9792 18.7667 15.9667 18.8333 15.95 18.9ZM1.5 20C1.1 20 0.75 19.85 0.45 19.55C0.15 19.25 0 18.9 0 18.5V13.75H9.5C9.7 13.75 9.8875 13.7958 10.0625 13.8875C10.2375 13.9792 10.3917 14.1083 10.525 14.275L14.875 19.95C14.8083 19.9667 14.7458 19.9792 14.6875 19.9875C14.6292 19.9958 14.5667 20 14.5 20H1.5Z" />
                </svg>
                <div class="section-header">
                    Annual Performance Review Summary
                    <div class="perf-year">
                        Year: {{this.selected}}
                    </div>


                </div>

            </div>

            <div class="notes-data">No feedback available</div>


        </div>
    </div>
</div>
<div class="card-container1" *ngFor="let summary of this.feedback">



    <div class="matcardprojectdetail1 ">

        <div class="section">
            <svg width="32" height="40" viewBox="0 0 16 20" fill="#0045b9" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4 9.75H12V8.25H4V9.75ZM4 5.75H12V4.25H4V5.75ZM15.95 18.9L11.7 13.35C11.4333 13.0167 11.1083 12.75 10.725 12.55C10.3417 12.35 9.93333 12.25 9.5 12.25H0V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H14.5C14.9 0 15.25 0.15 15.55 0.45C15.85 0.75 16 1.1 16 1.5V18.5C16 18.5667 15.9958 18.6333 15.9875 18.7C15.9792 18.7667 15.9667 18.8333 15.95 18.9ZM1.5 20C1.1 20 0.75 19.85 0.45 19.55C0.15 19.25 0 18.9 0 18.5V13.75H9.5C9.7 13.75 9.8875 13.7958 10.0625 13.8875C10.2375 13.9792 10.3917 14.1083 10.525 14.275L14.875 19.95C14.8083 19.9667 14.7458 19.9792 14.6875 19.9875C14.6292 19.9958 14.5667 20 14.5 20H1.5Z" />
            </svg>
            <div class="section-header">
                Annual Performance Review Summary
                <div class="perf-year">
                    Year: {{this.year}}
                </div>


            </div>

        </div>

        <div *ngIf=" this.isclosed" class="notes-data" [innerHTML]="summary"></div>
        <div *ngIf=" !this.isclosed" class="notes-data">No feedback available</div>


    </div>
</div>