import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Certificate } from 'src/app/models/certificate';
import { CertificationService } from 'src/app/services/certification/certification.service';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserCertificateDTO } from 'src/app/dtos/UserCertificateDTO';
import { FeedbackService } from 'src/app/services/feedback/feedback.service'; 
@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
})
export class CertificationsComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  editSpotlightDetails: FormGroup;
  certificatenames: string;
  certificate = [];
  certificateType = [];
  certificatename = [];
  removecertificates = [];
  multiCertificates: any = [];
  UploadCertificate: FormArray;
  imageError: any;
  cardBase64: string;

  binary = [];
  // binary:string;
  flag = [];
  certCount = 0;
  today = new Date();

  userId: any;
  dataSubscription: any;

  constructor(
    public toaster: ToastrService,
    public userDetailService: UserDetailsService,
    public fb: FormBuilder,
    private modalService: NgbModal,
    public certificateservice: CertificationService,
    public dialogRef: MatDialogRef<CertificationsComponent>,
    public feedbackService: FeedbackService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editSpotlightDetails = this.fb.group({
      uploadCertificates: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    // if(this.userId!=undefined)
    this.userId = this.data ? this.data : this.userDetailService.getUserDetails().id;
    this.certificateservice.getCertByUserIdSubscribe(this.userId).subscribe((res) => {
        if (res.body[0] == null) {
          this.addCertificate();
        }
        this.setCertificateFormArray(res.body);
      });
  }

  getMinEndDate(i) {
    var minEndDate = this.editSpotlightDetails.get('uploadCertificates')['controls'][i].get('dateOfIssue').value;
    return minEndDate;
  }

  // createcertificate() {
  //   return this.fb.group(new Certificate());
  // }
  createcertificate() {
    return this.fb.group(new Certificate());
  }
  // removecertificate(id: any, i) {
  //   this.removecertificates.push(id);
  //   this.Certificate.removeAt(i);
  // }
  removecertificate(id: any, i) {

    console.log(`Removing cert at ${i}`)

    this.removecertificates.push(id);
    this.Certificate.removeAt(i);
this.certificateType.splice(i,1);
    this.multiCertificates.splice(i, 1);
    this.certificatename.splice(i, 1);
    this.certCount = this.certCount - 1;
  }
  // setCertificateFormArray(response) {
  //   this.UploadCertificate = this.editSpotlightDetails.get(
  //     'uploadCertificates'
  //   ) as FormArray;
  //   let certList: Certificate[] =
  //     this.certificateservice.setCertResponse(response);
  //
  //   for (let s of certList) {
  //     this.multiCertificates.push(s.docBase);
  //     this.flag.push(false)
  //     this.UploadCertificate.push(this.fb.group(s));
  //   }
  // }
  setCertificateFormArray(response) {
    this.UploadCertificate = this.editSpotlightDetails.get(
      'uploadCertificates'
    ) as FormArray;
    let certList: Certificate[] =
      this.certificateservice.setCertResponse(response);
    for (let s of certList) {

      if (s.docBase != "undefined" ) {
        var temp = atob(s.docBase.toString());
        var cerType = this.getDocumentType(temp);
        this.multiCertificates.push(temp);
        this.certificateType.push(cerType);
        this.binary.push(s.docBase.toString());
      }
      else {
        this.multiCertificates.push("undefined");
        this.certificateType.push("undefined");
        this.binary.push("undefined");
      }
      this.certCount = this.certCount + 1;
      this.flag.push(true);
      this.UploadCertificate.push(this.fb.group(s));
    }

  }
  get Certificate(): FormArray {
    return this.editSpotlightDetails.get('uploadCertificates') as FormArray;
  }
  // addCertificate() {
  //   this.UploadCertificate = this.editSpotlightDetails.get(
  //     'uploadCertificates'
  //   ) as FormArray;
  //   this.UploadCertificate.insert(0, this.createcertificate());
  // }
  addCertificate() {

    this.UploadCertificate = this.editSpotlightDetails.get(
      'uploadCertificates'
    ) as FormArray;
    this.flag.push(true);
    this.certCount = this.certCount + 1;
    this.UploadCertificate.insert(this.certCount, this.createcertificate());

    this.binary.push("undefined");
    console.log(`Cert lenght on add ${this.UploadCertificate.length}`)
    console.log(`binary lenght ${this.binary.length}`)
  }
  closeDialog(event: Event) {
    this.dialogRef.close({ event: 'Closed' })
  }

  getDocumentType(temp): string {
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
    for (let type of allowed_types) {
      if (temp.indexOf(type) != -1) {
        return type;
      }
    }
  }

  // onSubmit() {

  //   if (this.editSpotlightDetails.valid) {
  //     for (let cert of this.editSpotlightDetails.value.uploadCertificates) {
  //       if (
  //         cert &&
  //         !cert.id &&
  //         cert.dateOfIssue != undefined &&
  //         cert.documentName != ''
  //       ) {
  //         cert.userId = this.userDetailService.getUserDetails().id;
  //         this.certificateservice.create(cert).subscribe((res) => {
  //           this.toaster.success("Successfully Saved")
  //           this.dialogRef.close({ event: 'Closed'})


  //         });
  //       } else if (
  //         cert &&
  //         cert.id &&
  //         cert.dateOfIssue != undefined &&
  //         cert.documentName != ''
  //       ) {
  //         this.certificateservice.update(cert).subscribe((res) => {
  //           this.toaster.success("Successfully Saved")
  //           this.dialogRef.close({ event: 'Closed'})

  //         });
  //       }
  //     }
  //   }
  //   for (let i in this.removecertificates) {
  //     this.certificateservice.delete(this.removecertificates[i]);
  //   }
  // }
  onSubmit() {

    if (this.editSpotlightDetails.valid) {
      let i = 0;
      for (let cert of this.editSpotlightDetails.value.uploadCertificates) {

        let userdocument = new UserCertificateDTO();
        userdocument.docBase = this.binary[i].toString();
        userdocument.documentName = cert.documentName;
        // userdocument.documentContentType = this.certificatenames;
        userdocument.userId = this.userId;
        if (
          cert &&
          !cert.id &&
          cert.dateOfIssue != undefined &&
          cert.documentName != ''
        ) {
          cert.userId = this.userId;
          cert.docBase = this.binary[i].toString();
          this.certificateservice.create(cert).subscribe((res) => {

            this.dialogRef.close({ event: 'Closed' })
            this.toaster.success('done');
            //  this.saved = true;
          });
        } else if (
          cert &&
          cert.id &&
          cert.dateOfIssue != undefined &&
          cert.documentName != '' &&
          cert.docBase != ''
        ) {

          this.certificateservice.update(cert).subscribe((res) => {

            this.dialogRef.close({ event: 'Closed' })
            this.toaster.success('Updated');
            // this.saved = true;
          });
        }
        i++;
      }
    }
    for (let i in this.removecertificates) {
      this.certificateservice.delete(this.removecertificates[i]);
    }

    //     for (let cert of this.editSpotlightDetails.value.uploadCertificates) {
    //   let userdocument = new UserCertificateDTO();
    //  userdocument.docBase = this.binary;
    //   userdocument.documentName =cert.documentName;
    //   userdocument.dateOfIssue = cert.dateOfIssue;

    //  // userdocument.documentContentType = this.certificatenames;
    //   userdocument.userId = this.userDetailService.getUserDetails().id;

    //   if (this.certificatedocument.id == null) {
    //     this.userdocumentservice
    //       .postdocument(userdocument)
    //       .subscribe((res) => {});
    //   } else {
    //     userdocument.id = this.certificatedocument.id;
    //    // userdocument.th = this.binary;
    //     this.userdocumentservice.putdocument(userdocument).subscribe((res) => {
    //       console.log('succsess');
    //     });
    //   }
    // }
  }
  uploadCertificate( i, fileInput) {

    console.log(i)
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      this.certificatename.push(fileInput.target.files[0].name);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgBase64Path: String = e.target.result;
        let imageContent = imgBase64Path.split(',')[1];
        let imageMetaData = imgBase64Path.split(',')[0];
        let imageContentType = imageMetaData.substring(
          imageMetaData.lastIndexOf(':') + 1,
          imageMetaData.lastIndexOf(';')
        );
        var temp = imageMetaData + ',' + imageContent;
        this.cardBase64 = imageMetaData + ',' + imageContent;
        if (this.multiCertificates[i] == null) {


          this.multiCertificates.push(temp);
          this.certificateType.push(imageContentType);
          // this.binary.push(btoa(this.cardBase64));
        } else {
          this.multiCertificates[i] = temp;
          this.certificateType[i] = imageContentType;

        }
        this.binary[i] = btoa(this.cardBase64);
        // this.multiCertificates.push(temp);
        // this.certificateType.push(imageContentType);
        // this.binary = btoa(this.cardBase64);


      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  open(content, i) {

    this.multiCertificates[i]
    if (this.flag[i] == false) {
      this.multiCertificates[i] = atob(this.multiCertificates[i]);
      this.flag[i] = true;
    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }

}
