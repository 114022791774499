import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { notStrictEqual } from 'assert';
import { FeedbackNotesDTO } from 'src/app/dtos/FeedbackNotesDTO';
import { FeedbackNotesService } from 'src/app/services/feedback-notes/feedback-notes.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MainContentProjectComponent } from '../../project/main-content-project/main-content-project.component';
@Component({
  selector: 'app-feedback-notes',
  templateUrl: './feedback-notes.component.html',
  styleUrls: ['./feedback-notes.component.scss']
})
export class FeedbackNotesComponent implements OnInit {
  feedbackNotes: FormGroup;
  feedbackNotesDto = new FeedbackNotesDTO();
  data: any;
  feedbackdto: any
  constructor(public dialogRef: MatDialogRef<MainContentProjectComponent>,
    public userDetailService: UserDetailsService, public fb: FormBuilder,
    public feedbackNotesService: FeedbackNotesService,
    @Inject(MAT_DIALOG_DATA) public notes: any) {
    this.feedbackNotes = this.fb.group({
      note: ['', [Validators.required]],
    })
  }
  ngOnInit(): void {
    if (this.notes.switchedTab == 'PROBATION') {
      this.feedbackNotesService.getProbationnotesbyuserandyear(this.notes.directReporteeId, this.notes.selected).subscribe(res => {
        this.feedbackdto = res.body
      })
    } else {
      this.feedbackNotesService.getnotesbyuserandyear(this.notes.directReporteeId, this.notes.selected).subscribe(res => {
        this.feedbackdto = res.body
      })
    }

    this.setFormData();
  }
  closeDialog() {
    this.dialogRef.close()
  }
  setFormData() {
    if (this.notes.switchedTab == 'PROBATION') {
      this.feedbackNotesService.getProbationnotesbyuserandyear(this.notes.directReporteeId, this.notes.selected).subscribe(res => {
        this.feedbackdto = res.body
        this.feedbackNotes.get('note').setValue(this.feedbackdto.cmNote);
      })
    } else {
      this.feedbackNotesService.getnotesbyuserandyear(this.notes.directReporteeId, this.notes.selected).subscribe(res => {
        this.feedbackdto = res.body
        this.feedbackNotes.get('note').setValue(this.feedbackdto.cmNote);
      })
    }

  }
  save() {

    this.feedbackNotesDto.userId = this.notes.directReporteeId
    this.feedbackNotesDto.cmNote = this.feedbackNotes.get('note').value;
    this.feedbackNotesDto.year = this.notes.selected

    if (this.feedbackdto != null) {
      if (this.notes.switchedTab == 'PROBATION') {
        this.feedbackNotesService.updateProbationNotes(this.feedbackNotesDto).subscribe(res => { })
      } else {
        this.feedbackNotesService.update(this.feedbackNotesDto).subscribe(res => { })
      }

    }
    else {
      if (this.notes.switchedTab == 'PROBATION') {
        this.feedbackNotesService.createProbationNotes(this.feedbackNotesDto).subscribe(res => { })
      } else {
        this.feedbackNotesService.create(this.feedbackNotesDto).subscribe(res => { })
      }

    }
    this.dialogRef.close();
  }
}
function subscribe(arg0: (res: any) => void) {
  throw new Error('Function not implemented.');
}
