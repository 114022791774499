import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
  selector: 'app-reject-leave',
  templateUrl: './reject-leave.component.html',
  styleUrls: ['./reject-leave.component.scss']
})
export class RejectLeaveComponent implements OnInit {
  // leaveApplications = new LeaveApprovalDTO();
  rejectionDetails: FormGroup;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RejectLeaveComponent>,
    private notificationService: NotificationService,
    private leaveApprovalservice: LeaveApprovalService

  ) {
    this.rejectionDetails = new FormGroup({
      reasonForRejection: new FormControl(),

    });
  }

  ngOnInit(): void {
    console.log(this.data)
  }

  rejectLeave() {
    const reason = this.rejectionDetails.get('reasonForRejection').value;
    console.log(reason)
    const leaveReject = {
      leaveApplicationId: this.data.taskTransactionId,
      approvalStatus: (this.data.typeOfTask == "LEAVE_APPROVAL") ? "REJECTED" : "REVOKE_REQUEST_REJECTED",
      reasonForRejection: reason
    }
    this.leaveApprovalservice.update(leaveReject).subscribe((res) => {

      this.closeDialog();
    }, () => {
      this.closeDialog();
    })
  }


  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }

}
