import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { SharePointUrlService } from '../restAPI/sharePointURLs/sharePoint-url.services';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../alerts.service';
import { HttpClient } from '@angular/common/http';
import { Project } from 'src/app/models/project';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharePointService {
  public response:any;
  constructor(private restApi: RestAPIService, private sharePointUrlService: SharePointUrlService,private http: HttpClient) { 
    
  }

  getSharePointData(){
    
    const url = `http://localhost:8080/api/sync_data`
    return  this.http.post(url,{}, {responseType:'text'});
  }

  
}


