<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Travel with BlueAltair</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>
   
    </div>
</div>
<hr>
<div class="container-fluid">

    
    <div class="row">

        <div class="col-md-12 form_main_div">
            <div class="add_button_fix">
                <button class="add_button" (click)="addbluealtairtravel()">+Add</button>
                <button class="plus_button mt-4" (click)="addbluealtairtravel()"><span>+Add</span></button>
            </div>
            <form [formGroup]="editBusinesstravel2">
                <div formArrayName="bluealtairarray"
                    *ngFor="let bluetravel of this.BlueAltairTravel().controls; let i = index">

                    <div class="row">

                        <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>From Country<span class="contentRed"></span></mat-label>
                                <!-- <input matInput formControlName="fromCounry" autocomplete="off"> -->
                                    <mat-select Required formControlName="fromCounry" >
                                        <mat-option *ngFor="let option of countries" [value]="option">{{option}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="checkError('fromCounry', 'required')">Required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>To Country<span class="contentRed"></span></mat-label>
                                <!-- <input matInput formControlName="toCountry" autocomplete="off"> -->
                                    <mat-select Required formControlName="toCountry" >
                                        <mat-option *ngFor="let option of countries" [value]="option">{{option}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="checkError('fromCounry', 'required')">Required</mat-error>
                            </mat-form-field>

                        </div>
                        <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Travel Date <span class="contentRed"></span></mat-label>
                                <input matInput [max]="today" [matDatepicker]="picker4" placeholder="mm/dd/yyyy"
                                    formControlName="travelDate">
                                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                <mat-datepicker #picker4></mat-datepicker>
                                <mat-error *ngIf="checkError('fromCounry', 'required')">Valid date required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Reason For Travel<span class="contentRed"></span></mat-label>
                                <input matInput formControlName="reasonOfTravel" autocomplete="off">
                                <mat-error *ngIf="checkError('fromCounry', 'required')">Required</mat-error>
                            </mat-form-field>

                        </div>



                    </div>
                    <div class="row">
                        <div class="col-md-6" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="3" cdkAutosizeMaxRows="15" placeholder="Additional Info"
                                    formControlName="additionalInfo"></textarea>

                            </mat-form-field>

                        </div>
                        <div class="col-md-4" >
                            <button class="remove_passport_label" (click)="removebluealtair(bluetravel.value.id,i)">
                                Remove</button>
                        </div>

                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>