import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetCardComponent } from '../../asset-card/asset-card/asset-card.component';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AssetDTO } from 'src/app/dtos/AssetDTO';
import { AssetManagementService } from 'src/app/services/asset-management/asset-management.service';
import { AssetCategories, AssetCondition, AssetLocations, AssetStatus } from 'src/app/services/asset-management/asset-enum';

@Component({
  selector: 'app-assign-asset',
  templateUrl: './assign-asset.component.html',
  styleUrls: ['./assign-asset.component.scss']
})
export class AssignAssetComponent implements OnInit {

  newAssetDetails: FormGroup;
  empIds: any;
  minAssignDate: Date;
  emailError: any;
  allEmployeeIdsWithNames = {};
  assetDto = new AssetDTO();
  allEmployeeIds: any=[];
  pageResult: any;
  assignInProgress: boolean;
  userlist;
  noOnlySpecialCharactersValidator():ValidatorFn {
    // Regular expression to match only special characters
    const onlySpecialCharsRegex = /^[!@#$%^&*(),.?":{}|<>-_]+$/;
  
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value as string;
  
      if (onlySpecialCharsRegex.test(value)) {
        return { noOnlySpecialCharacters: true };
      }
  
      return null;
    };
  }
  
  constructor(public dialogRef: MatDialogRef<AssetCardComponent>,
    public fb: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    private assetService: AssetManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.newAssetDetails = this.fb.group({
      employeeId: ['', [Validators.required]],
      employeeName: ['', [Validators.required]],
      assetAssignedDate: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]]
    },
    );
  }

  ngOnInit(): void {

    this.setNewMinAssignDate();
    this.getAllEmployeeNames();
  }

  checkError = (controlName: string, errorName: string) => {
    return this.newAssetDetails.controls[controlName].hasError(errorName);
  }

  setFormControlError(controlName: string, controlError: string, errorMsg: string) {
    this.newAssetDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.newAssetDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.newAssetDetails.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg;
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Closed'});
  }

  populateEmployeeId(id){
    this.userService.getEmployeeById(id).subscribe(data => {
      let employeeId = data.body.employeeId;
      this.newAssetDetails.get('employeeId').setValue(employeeId);
    },
    error => {
    });;
  }

  setNewMinAssignDate() {
    this.minAssignDate = new Date(this.data.assetDto.purchasedOn);
  }

  onSubmit() {
    if (this.newAssetDetails.valid) {
      this.assignInProgress = true;
      this.assetDto.id = this.data.assetDto.id;
      this.assetDto.assetAssignedDate = this.newAssetDetails.get('assetAssignedDate').value;
      this.assetDto.employeeId = this.newAssetDetails.get('employeeId').value;

      this.assetDto.assetLocation = "PUNE";
      this.assetDto.assetCondition = "GOOD";
      this.assetDto.assetCategory = "MONITOR";
      this.assetDto.assetStatus = "ISSUED";

      this.assetService.assignAsset(this.assetDto).subscribe(res => {
        this.notificationService.sucess("Asset Assigned");
        this.pageResult = res;
        this.assignInProgress = false;
        this.dialogRef.close(this.pageResult);
      });
    }
    else {
      this.notificationService.invalidFormData()
    }
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  getAllEmployeeNames(){
    this.userService.getAllUsers().subscribe(res=>{
      for(let i in res.body){
        this.allEmployeeIds[res.body[i].id]=res.body[i].firstName + ' '+res.body[i].lastName;
      }
      this.userlist= Object.keys(this.allEmployeeIds);
    })
  }
}
function noOnlySpecialCharactersValidator() {
  throw new Error('Function not implemented.');
}

