import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class PancardUrlService {
  private readonly panBaseApi = '/api/pan-card-details';
  private readonly user = '/user';
  private readonly userId_param = 'userId'
  constructor(private restApi:RestAPIService) { }
  getBaseUrl(){
    return this.panBaseApi;
  }
  getUserUrl(userId) {
    return this.panBaseApi + this.user
      + '?' + this.userId_param + '=' + userId
  }
  getByFieldUrl(field){
    return this.panBaseApi+ this.getByField(field)
  }
  private getByField(field){
    return '/'+field;
  }
}
