import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { VisaService } from 'src/app/services/visa/visa.service';
import { Visa } from 'src/app/models/visa';
import { ToastrService } from 'ngx-toastr';
import { Countries,} from 'src/app/services/country/country-enum';

@Component({
  selector: 'app-visa-details',
  templateUrl: './visa-details.component.html',
  styleUrls: ['./visa-details.component.scss'],
})
export class VisaDetailsComponent implements OnInit {
  editBusinesstravel: FormGroup;
  visas: FormArray;
  userDto = new UserDTO();
  removedVisa = [];
  countries=[];
  noOnlySpecialCharactersValidator():ValidatorFn {
    
   
    const onlySpecialCharsRegex = /^[!#$%^&*(),.<>?":@?\{}|<_ /]+$/;

    return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;

    if (onlySpecialCharsRegex.test(value)) {
      return { noOnlySpecialCharacters: true };
    }

    return null;
  };
  }

  constructor(
    public fb: FormBuilder,
    public visaservice: VisaService,
    public userDetailService: UserDetailsService,
    public toaster:ToastrService,
    public dialogRef: MatDialogRef<VisaDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editBusinesstravel = this.fb.group({
      visa: this.fb.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    //this.data contains userid
    // this.userDto = this.userDetailService.getUserDetails();
    this.countries=Object.values(Countries);
        this.visaservice
      .getvisaByUserIdSubscribe(this.data)
      .subscribe((res) => {
        if (res.body[0] == null) {
          this.addVisa();
        }
        this.setVisaFormArray(res.body);
      });
  }
  closeDialog(event:Event) {
    this.dialogRef.close({ event: 'Closed' });
  }
  createvisa() {
    let formGroup = this.fb.group(new Visa());
    formGroup.controls.country.setValidators([Validators.required]);
    formGroup.controls.visaType.setValidators([Validators.required,this.noOnlySpecialCharactersValidator()]);
    formGroup.controls.validFrom.setValidators([Validators.required, this.noOnlySpecialCharactersValidator()]);
    formGroup.controls.validTo.setValidators([Validators.required]);       
    formGroup.controls.active;

    return formGroup;
  }
  //  get passportb()
  // {
  //   return this.editBusinesstravel1.controls['passportb'] as FormArray;
  // }

  processDate(visa) {
    let status;

    if (visa.controls.validTo.valid) {
      let validFrom: Date = visa.controls.validFrom.value;
      let validTo: Date = visa.controls.validTo.value;
      let todaydate: Date = new Date();
      let diff2 = todaydate > validTo;
      if (todaydate >= validTo) {
        status = 'Inactive';
      } else {
        status = 'Active';
      }
    }
    return status;
  }
  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }
  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
  setVisaFormArray(response) {
    this.visas = this.editBusinesstravel.get('visa') as FormArray;
    let visaList: Visa[] = this.visaservice.setVisaResponse(response);
    for (let s of visaList) {
      s.country=Countries[s.country];
      let formGroup: FormGroup = this.fb.group(s);

      formGroup.controls.active.setValue(this.processDate(formGroup));
      this.visas.push(formGroup);
    }
  }

  addEvent(type: string, visa: FormGroup) {
    visa.controls.active.setValue(this.processDate(visa));
  }

  Visa(): FormArray {
    return this.editBusinesstravel.get('visa') as FormArray;
  }
  addVisa() {
    this.visas = this.editBusinesstravel.get('visa') as FormArray;
    this.visas.insert(0, this.createvisa());
  }
  removevisa(id, i: number) {
    this.removedVisa.push(id);
    this.Visa().removeAt(i);
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  onSubmit() {
    if (this.editBusinesstravel.valid) {
      for (let visas of this.editBusinesstravel.value.visa) {
        visas.country = this.getEnumKeyByEnumValue(Countries,visas.country);
        if (
          visas &&
          !visas.id &&
          visas.country != '' &&
          visas.visaType != '' &&
          visas.validFrom != undefined &&
          visas.validTo != undefined
          // visas.status != ''
        ) {
          visas.userId = this.data;
          this.visaservice.create(visas).subscribe((res) => {
            this.toaster.success("Successfully Saved")
            this.dialogRef.close({ event: 'Closed' });
          });
        } else if (
          visas &&
          visas.id &&
          visas.country != '' &&
          visas.visaType != '' &&
          visas.validFrom != undefined &&
          visas.validTo != undefined
        ) {
          visas.userId = this.data;
          this.visaservice.update(visas).subscribe((res) => {
            this.toaster.success("Successfully Saved")
            this.dialogRef.close({ event: 'Closed' });
          });
        }
      }
    }
    

    for (let i in this.removedVisa) {
      this.visaservice.delete(this.removedVisa[i]);
    }
  }
  
}
