<mat-card class="main-card pr-0 pl-0">
  <div class="row ">
    <div class="col-md-12">
      <label class="header_name ml-3">My Team</label>
    </div>
  </div>


  <div class="row" *ngIf="this.team.length!==0">
    <div class="col-md-12">
      <mat-tab-group>
        <mat-tab *ngFor="let p of this.team; index as i">
          <div class="tableFixHead">
            <div class="row mt-3" *ngFor="let t of p;">
              <ng-template mat-tab-label class="lbl"> {{this.projectNameIdMapping[t.projectId]}}</ng-template>
              <div *ngIf="t.userId!=this.userid" class="col-12 flex">
                <div class="col-8">
                  <div class="team_main_div">
                    <img class="team_avatar"  src="assets/default-avatar.jpg" >
                   
                    <span class="holiday_name ">{{t.talentName}}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="apply">
                    <a class="text_apply" href="/home">Connect</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div *ngIf="this.team.length==0" class="ml-3 no_data_div">
    <img class="img" src="assets/no-data-available.png">
    <div class="no_data_label mt-2">No team is assigned to you</div>
  </div>
</mat-card>