<div class="main_header_div">
  <div class="child">
    <h1 class="header_name ">Export Employee Details</h1>
  </div>
  <div>
    <button class="close-button" (click)="matDialogClose()">Close</button>
  </div>
</div>
<hr>
<div class="container-fluid">
  <div class="row  main_container body-scroll">
    <div class="checkbox-container" *ngFor="let field of fields">
      <label class="checkbox-label" [style.color]="field.mandatory ? 'gray' : 'inherit'"
        [style.cursor]="field.mandatory ? '' : 'pointer'">
        {{ field.name | underScoreToSpace }}
        <input type="checkbox" [(ngModel)]="field.checked" (change)="updateSelectedFields()"
          [disabled]="field.mandatory" class="checkbox-input">
        <span class="checkbox-checkmark" [style.border]="field.mandatory?'1.4px solid gray':'1.4px solid #0045b9'"></span>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 ">
      <div class="main_button_div">
        <button mat-button [disabled]="generateDisabled" class="yes_button"
          (click)="generateReport()">{{generateButtonText}}</button>
      </div>
    </div>
  </div>
</div>