<div class="main_header_div">

    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>

    </div>
   

</div>
<div>
    <div class="img-container">
        <img src="assets\page_under_construction.png" alt="" class="error-img">
    </div>

    <div class="warning-text">
        This page is under construction
    </div>
</div>