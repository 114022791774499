import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { UnmarkedAttendanceUrlService } from '../restAPI/unmarkedAttendaceURLs/unmarked-attendance-url.service';
import { EventInput } from '@fullcalendar/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class UnmarkedAttendanceService {

  constructor(private restApiService: RestAPIService,
    private unmarkedAttendanceUrlService: UnmarkedAttendanceUrlService
  ) { }

  getUnmarkedAndLeaveByUserIdAndStatus(userId, year): any {

    return this.restApiService.get(
      this.unmarkedAttendanceUrlService.getUnmarkedAndLeave(userId, year)
    ).pipe(
      map(res => {
        const events: EventInput[] = []
        const newEvents = res.body

        for (let i = 0; i < newEvents.length; i++) {
          const event = newEvents[i];
          if (event.attendanceStatus == 'UNMARKED_ATTENDANCE' || event.attendanceStatus =='LEAVE') {
            let bgColor = (event.attendanceStatus == 'UNMARKED_ATTENDANCE' ? '#EB0000' : (event.attendanceStatus == 'LEAVE' ? '#0045B9' : ''));
            const formattedDate = moment(event.attendanceDateTime).format('YYYY-MM-DD');
            const currentEvent: EventInput = {
              title: '',
              start: formattedDate,
              backgroundColor: bgColor
            };
            
            events.push(currentEvent);
          }
          
        }
        return events;
      }),
      catchError(e=> {
        return [];
      })
    )
  }
}
