import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PassportDetailsDTO } from 'src/app/dtos/PassportDetailsDTO';
import { UserDocumentDTO } from 'src/app/dtos/UserDocumentDTO';
import b64toBlob from 'b64-to-blob';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { BlueAltairTravel } from 'src/app/models/bluealtairtravel';
import { User } from 'src/app/models/user';
import { Visa } from 'src/app/models/visa';
import { PassportDetails } from 'src/app/models/passportdetails';
import { BluealtairtravelService } from 'src/app/services/blue-altair-travel/bluealtairtravel.service';
import { PassportDetailsService } from 'src/app/services/passport-details/passport-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserDocumentService } from 'src/app/services/user-document.service';
import { VisaService } from 'src/app/services/visa/visa.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Countries,} from 'src/app/services/country/country-enum';
@Component({
  selector: 'app-tab5-businesstravel',
  templateUrl: './tab5-businesstravel.component.html',
  styleUrls: ['./tab5-businesstravel.component.scss'],
  providers: [DatePipe],
})
export class Tab5BusinesstravelComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  editBusinesstravel: FormGroup;
  editBusinesstravel1: FormGroup;
  editBusinesstravel2: FormGroup;
  visas: FormArray;

  todaydate = new Date();
  blob;
  bytes;
  newDoc
  passportbase64array=[];
  passportdocument = new UserDocumentDTO();
  passportdto = new PassportDetailsDTO();
  passport = new PassportDetailsDTO();
  userDto = new UserDTO();
  panelOpenState = false;
  passports1: FormArray;
  vissa: FormArray;
  base64textString;
  travelBlueAltairArray: FormArray;
  panelOpenState1 = false;
  panelOpenState2 = false;
  minDate: Date;
  maxDate: Date;
  removedVisa = [];

  removebluealtairtravel = [];
  removedpassport = [];
  userdocument;
  bluealtairarray: FormArray;
  cardImageBase64pdf: string;
  isPassportSaved: boolean;
  passportname: string;
  imageError: string;
  pdf:any;
  today;
  countries=[];
  editBusinesstravel3: FormGroup;
  constructor(
    public visaservice: VisaService,
    public userDetailService: UserDetailsService,
    public passportservice: PassportDetailsService,
    public bluealtairtravelservice: BluealtairtravelService,
    public fb: FormBuilder,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private httpClien:HttpClient,
    public toaster:ToastrService,

    public userdocumentservice: UserDocumentService,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {
    this.editBusinesstravel = this.fb.group({
      visa: this.fb.array([], [Validators.required]),
    });
    this.editBusinesstravel1 = this.fb.group({
     
      passports: this.fb.array([], [Validators.required]),
    });
  
    this.editBusinesstravel2 = this.fb.group({
      bluealtairarray: this.fb.array([], [Validators.required]),
    });
  
  }

  ngOnInit(): void {
    // this.checkDateInRange(this.todaydate,this.expiryDate)
    this.today = new Date();
    this.countries=Object.values(Countries);
    this.userDto = this.userDetailService.getUserDetails();
    this.visaservice
      .getskillByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.setVisaFormArray(res.body);
      });
    this.bluealtairtravelservice
      .gettravelByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.setBluealtairtravelFormArray(res.body);
      });

    this.passportservice
      .getpassByUserIdSubscribe(this.userDetailService.getUserDetails().id)
      .subscribe((res) => {
        this.setPassportFormArray(res.body);
      });

    this.userdocumentservice
      .getuserdocumentbyuserId(
        'Passport',
        this.userDetailService.getUserDetails().id
      )
      .subscribe((res) => {
        this.passportdocument = res.body;
        this.passportname = this.passportdocument.documentBase;
        this.passportname = this.passportdocument.documentContentType;
        if (this.passportdocument.documentBase) {
          this.isPassportSaved = true;
        }
      });
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  //visa details starts here
  createvisa() {
    let formGroup = this.fb.group(new Visa());
    formGroup.controls.country.setValidators([Validators.required]);
    formGroup.controls.visaType.setValidators([Validators.required]);
    formGroup.controls.validFrom.setValidators([Validators.required]);
    formGroup.controls.validTo.setValidators([Validators.required]);
    formGroup.controls.active;

    return formGroup;
  }


  processDate(visa) {
   
    let status;
  
    if (visa.controls.validTo.valid) {
      let validFrom: Date = visa.controls.validFrom.value
      let validTo: Date = visa.controls.validTo.value
      let todaydate : Date = new Date()
      let diff2= todaydate >  validTo
      if(todaydate >= validTo)
      {
        status = "Inactive"
       
      }
      else
      {  status = "Active"
        
      }
    }
    return status
  }
  getMonthFromDays(days) {
    return Math.floor((days % 365) / 30);
  }
  getYearFromDays(days) {
    return Math.floor(days / 365);
  }
  setVisaFormArray(response) {
    this.visas = this.editBusinesstravel.get('visa') as FormArray;
    let visaList: Visa[] = this.visaservice.setVisaResponse(response);
    for (let s of visaList) {
      s.country=Countries[s.country];
      let formGroup: FormGroup = this.fb.group(s);

      formGroup.controls.active.setValue(this.processDate(formGroup));
      this.visas.push(formGroup);
    }
   }

  addEvent(type: string, visa: FormGroup) {
    visa.controls.active.setValue(
      this.processDate(visa)
    );
  }
 
  Visa(): FormArray {
    return this.editBusinesstravel.get('visa') as FormArray;
  }
  addVisa() {
    this.visas = this.editBusinesstravel.get('visa') as FormArray;
    this.visas.insert(0, this.createvisa());
  }
  removevisa(id, i: number) {
    this.removedVisa.push(id);
    this.Visa().removeAt(i);
  }
    //visa details code ends here

  //passport details code starts here
  PassportDetails(): FormArray {
    return this.editBusinesstravel1.get('passports') as FormArray;
    
    
  }
  addPassport() {
    
 
    
    this.passports1 = this.editBusinesstravel1.get('passports') as FormArray;
    this.passports1.insert(0,this.createpassport());
   
    
  }
  createpassport() {
    let formGroup = this.fb.group(new PassportDetails());
    formGroup.controls.country.setValidators([Validators.required]);
    formGroup.controls.expiryDate.setValidators([Validators.required]);
    formGroup.controls.passportNumber.setValidators([Validators.required,Validators.pattern('^[A-Z0-9]+')]);
   // formGroup.controls.passportb;
   
    return formGroup;
  }

  setPassportFormArray(response) {
    this.passports1 = this.editBusinesstravel1.get('passports') as FormArray;
    let passportList: PassportDetails[] =
      this.passportservice.setPassportResponse(response);
    for (let s of passportList) {
      let v: PassportDetails = new PassportDetails().bind(s);
      let passportdetails = this.createpassport();
      passportdetails.controls.id.setValue(v.id);
      passportdetails.controls.country.setValue(Countries[v.country]);
      passportdetails.controls.expiryDate.setValue(v.expiryDate);
      passportdetails.controls.passportNumber.setValue(v.passportNumber);
     // passportdetails.controls.passportb.setValue(v.passportNumber);
    
      this.passports1.insert(0, passportdetails);
    }
  }
  open(content) {

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  
  onChange1(fileInput: any,index:number) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
      this.passportname = fileInput.target.files[0].name;
      const reader = new FileReader();
      reader.onload = (e: any) => {
      const imgBase64Path: String = e.target.result;
      
        
      
       this.passportbase64array.push(imgBase64Path)
       this.isPassportSaved = true;
     
    };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

 
  removepassport(id, i: number) {
    
  
    this.removedpassport.push(id);
    this.PassportDetails().removeAt(i);
  }

  
  onInputToUppercase(event: any) {
    const newValue = event.target.value.toUpperCase();
    event.target.value = newValue;
  }
  //passport details code ends here
  
  //travel history with bluealtair code starts here
  createbluealtairtravel() {
    let formGroup = this.fb.group(new BlueAltairTravel());
    formGroup.controls.toCountry.setValidators([Validators.required]);
    formGroup.controls.fromCounry.setValidators([Validators.required]);
    // formGroup.controls.additionalInfo.setValidators([Validators.required]);
    formGroup.controls.travelDate.setValidators([Validators.required]);
    formGroup.controls.reasonOfTravel.setValidators([Validators.required]);
    return formGroup;
  }

  setBluealtairtravelFormArray(response) {
    this.travelBlueAltairArray = this.editBusinesstravel2.get(
      'bluealtairarray'
    ) as FormArray;
    let travelList: BlueAltairTravel[] =
      this.bluealtairtravelservice.settravelResponse(response);
    for (let s of travelList) {
      let v: BlueAltairTravel = new BlueAltairTravel().bind(s);
      let travelGroup = this.createbluealtairtravel();
      travelGroup.controls.toCountry.setValue(Countries[v.toCountry]);
      travelGroup.controls.fromCounry.setValue(Countries[v.fromCounry]);
      travelGroup.controls.additionalInfo.setValue(v.additionalInfo);
      travelGroup.controls.travelDate.setValue(v.travelDate);
      travelGroup.controls.reasonOfTravel.setValue(v.reasonOfTravel);

      travelGroup.controls.id.setValue(v.id);
      this.travelBlueAltairArray.insert(0, travelGroup);
    }
  }
  BlueAltairTravel(): FormArray {
    return this.editBusinesstravel2.get('bluealtairarray') as FormArray;
  }
  addbluealtairtravel() {
    this.travelBlueAltairArray = this.editBusinesstravel2.get(
      'bluealtairarray'
    ) as FormArray;
    this.travelBlueAltairArray.insert(0, this.createbluealtairtravel());
  }
  removebluealtair(id, i: number) {
    this.removebluealtairtravel.push(id);
    this.BlueAltairTravel().removeAt(i);
  }
  //travel history with bluealtair code ends here

  onSubmit() {
   
      if (this.editBusinesstravel.valid) {
        for (let visas of this.editBusinesstravel.value.visa) {
          visas.country = this.getEnumKeyByEnumValue(Countries,visas.country);
        if (

          visas &&
          !visas.id &&
          visas.country != '' &&
          visas.visaType != '' &&
          visas.validFrom != undefined &&
          visas.validTo != undefined 
          // visas.status != ''
        ) {
          visas.userId = this.userDetailService.getUserDetails().id;
          this.visaservice.create(visas).subscribe((res) => {
            this.toaster.success('Successfully Saved' ,'')
    
          });
        } else if (
          visas &&
          visas.id &&
          visas.country != '' &&
          visas.visaType != '' &&
          visas.validFrom != undefined &&
          visas.validTo != undefined
        ) {
          visas.userId = this.userDetailService.getUserDetails().id;
          this.visaservice.update(visas).subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')
          });
        }
      }
    }

    for (let i in this.removedVisa) {
      this.visaservice.delete(this.removedVisa[i]);
    }
  }
  onSubmit3() {
    if (this.editBusinesstravel2.valid) {
      for (let travel of this.editBusinesstravel2.value.bluealtairarray) {
        travel.toCountry = this.getEnumKeyByEnumValue(Countries,travel.toCountry);
        travel.fromCounry = this.getEnumKeyByEnumValue(Countries,travel.fromCounry);
        if (
          travel &&
          !travel.id &&
          travel.fromCounry != '' &&
          travel.toCountry != '' &&
          travel.reasonOfTravel != '' &&
          // travel.additionalInfo != '' &&
          travel.travelDate != undefined
        ) {
          travel.userId = this.userDetailService.getUserDetails().id;
          this.bluealtairtravelservice.create(travel).subscribe((res) => {
            this.toaster.success('Successfully Saved' ,'')
          });
        } else if (
          travel &&
          travel.id &&
          travel.fromCounry != '' &&
          travel.toCountry != '' &&
          travel.reasonOfTravel != '' &&
          // travel.additionalInfo != '' &&
          travel.travelDate != undefined
        )
          travel.userId = this.userDetailService.getUserDetails().id;
        this.bluealtairtravelservice.update(travel).subscribe((res) => {
          this.toaster.success('Successfully Updated', 'Saved')
              });
      }
    }
    for (let i in this.removebluealtairtravel) {
      this.bluealtairtravelservice.delete(this.removebluealtairtravel[i]);
    }
  }
  onSubmit2() {
    
    if (this.editBusinesstravel1.valid) {
      for (let passport of this.editBusinesstravel1.value.passports) {
        passport.country = this.getEnumKeyByEnumValue(Countries,passport.country);
        if (
          passport &&
          !passport.id &&
          passport.country != '' &&
          passport.expiryDate != '' &&
          passport.passportNumber != undefined 
        //  passport.passportb != ''
   
        ) {
          passport.userId = this.userDetailService.getUserDetails().id;
          this.passportservice.create(passport).subscribe((res) => {
            this.toaster.success('Successfully ', 'Saved')
             });
        } else if (
          passport &&
          passport.id &&
          passport.country != '' &&
          passport.expiryDate != '' &&
          passport.passportNumber != undefined 
          //passport.passportb != ''
        ) {
          passport.userId = this.userDetailService.getUserDetails().id;
          this.passportservice.update(passport).subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')
          });
        }
      }

      for (let i in this.removedpassport) {
        this.passportservice.delete(this.removedpassport[i]);
      }

      //this.Personalsaved = true
      // let userdocument = new UserDocumentDTO();
      // userdocument.document = this.bytes;
      // userdocument.documentName = 'Passport';
      // userdocument.creationDate = new Date();
      // userdocument.documentContentType = this.passportname;
      // userdocument.userId = this.userDetailService.getUserDetails().id;

      // if (this.passportdocument.id == null) {
      //   this.userdocumentservice
      //     .postdocument(userdocument)
      //     .subscribe((res) => {});
      // } else {
      //   userdocument.id = this.passportdocument.id;
      //   userdocument.document = this.bytes;
      //   this.userdocumentservice.putdocument(userdocument).subscribe((res) => {
         
      //   });
      // }
    }
  }
}
