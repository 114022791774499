import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

  name: 'underScoreToSpace'

})

export class PipeUnderScoreToSpace implements PipeTransform {

  nvalue: string

  transform(value: string): any {

    if(value == null){
        return "";
    }

    this.nvalue = value.replace(/_/g, ' ');
    this.nvalue = this.nvalue.replace('AND', '&');

    return this.nvalue;

  }

}