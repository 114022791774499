<div>
    <ng-container *ngIf="this.nodata == true; else elseTemplate">
        <div class="nothing-data">
            <img class="no-reportee-img" src="assets/no-data-project.png">
        </div>
    </ng-container>
    <ng-template #elseTemplate>

        <div class="row">
            <div class="col-md-12 pl-0">
                <mat-card class="mat_card">
                    <div class="cal-div">
                        <button autofocus class="sort" [ngClass]="{'sort_focus' : this.index==0}" (click)="all()">
                            All
                        </button>
                        <button class="sort" [ngClass]="{'sort_focus' : this.index==1}" (click)="open()">
                            Open
                        </button>
                        <button class="sort" [ngClass]="{'sort_focus' : this.index==2}" (click)="closed()">
                            Closed
                        </button>
                    </div>
                    <div class=" items">
                        <button class="cs-download" (click)="downloadAllExcel()">
                            <img src="assets/download.png" class="download-img">
                            <label class="text">
                              Rating Report
                            </label>
                        </button>
                        <button class="cs-download" (click)="file()">
                            <img src="assets/download.png" class="download-img">
                            <label class="text">
                                Calibrated Report
                            </label>
                        </button>
                        <div class="search_main ">
                            <mat-icon class="search-icon">search</mat-icon>
                            <input class="search_input" type="search" [(ngModel)]="searchText" placeholder="Search">
                        </div>
                        <div class="col show">
                            <label>
                                <button mat-icon-button matSuffix type="button" (click)="this.hide = !this.hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                                    class="">{{hide? "Show Scores": "Hide Scores"}}
                                    <mat-icon style="color: #999999" Pass through>{{ hide ? "visibility_off" : "visibility"
                                        }}
                                    </mat-icon>
                                </button>
                            </label>
                        </div>
                        <div class="year-div">
                            <div class="reportee-common">
                                Year
                            </div>
                            <div>
                                <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
                                    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                                </mat-select>
                            </div>

                        </div>
                      
                    </div>
                </mat-card>
            </div>
        </div>
        <div *ngIf="this.mobileview==false">
            <div class="card-container" #cardcontainer>


                <div class="matcardprojectdetail "
                    *ngFor="let directreportee of this.userdto | filter:searchText  | paginate: { id:'calibrate-reportee-id1', itemsPerPage: itemsPerPage, currentPage: p }  ">
                <div
                    (click)="onViewReportee(directreportee.userId)">
                    <div class="col-name">
                        <div>
                            <img src="assets/default-avatar.jpg" class="img-container">
                        </div>
                        <div class="style">


                            <div class="name-div">
                                <div class="emp-name">
                                    <label>
                                        {{directreportee.firstName }}
                                        {{directreportee.lastName}}
                                    </label>

                                </div>
                                <div class="emp-id">

                                    <label>
                                        ID {{directreportee.employeeId}}
                                    </label>

                                </div>
                            </div>
                            <div class="col-3 reopen-div" *ngIf="directreportee.closed==true">
                                <div class="reopen" (click)="reopened(directreportee.userId,this.selected);$event.stopPropagation();"> Reopen
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class=" coloums-div color">
                        <div class="col-3 project-name">
                            Score
                        </div>

                        <div *ngIf="this.hide==!true" class="col-3 count">
                            {{directreportee.totalScore}}/5
                        </div>
                        <div *ngIf="this.hide==true" class="col-3 hide">
                            ●●●
                        </div>
                    </div>
                    <div class=" coloums-div ">
                        <div class="col project-name">
                            Feedback Count
                        </div>

                        <div *ngIf="this.hide==!true" class="col-3 count ">
                            {{directreportee.feedbackForms}}
                        </div>
                        <div *ngIf="this.hide==true" class="col-3 hide">
                            ●●●
                        </div>
                    </div>
                    <div class="coloums-div color">
                        <div class="col-3  project-name">
                            Status
                        </div>

                        <div class="col-3">
                            <div *ngIf="directreportee.closed==false" class="count green">Open</div>
                            <div *ngIf="directreportee.closed==true" class="count red">Closed</div>
                        </div>
                    </div>

                </div>
                </div>
            </div>
            <pagination-controls id="calibrate-reportee-id1" class="pagination" (pageChange)="p = $event"></pagination-controls>
        </div>
        <div *ngIf="this.mobileview==true" class="card-container">


            <mat-card class="matcardprojectdetail" (click)="onViewProject(project) "
               
                *ngFor="let directreportee of this.userdto | filter:searchText  | paginate: { id: 'calibrate-reportee-id' ,itemsPerPage: 4, currentPage: p }  ">
                <mat-card-content>
                    <div class="row mt-3">
                        <div class="col">
                            <img src="assets/default-avatar.jpg" class="img-container">
                        </div>
                        <div class="col">
                            {{directreportee.firstName }}
                            {{directreportee.lastName}}
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label class="project-name">Score </label>
                        </div>
                        <div class="col">
                            <p> {{directreportee.totalScore}}/5</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label class="project-name">Feedback Count </label>
                        </div>
                        <div class="col">
                            <p>{{directreportee.feedbackForms}}</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label class="project-name">Status </label>
                        </div>
                        <div class="col">
                            <div *ngIf="directreportee.closed==false" class="count green">Open</div>
                            <div *ngIf="directreportee.closed==true" class="count red">Closed</div>
                        </div>
                    </div>


                </mat-card-content>
            </mat-card>
            <pagination-controls id="calibrate-reportee-id" class="pagination" (pageChange)="p = $event"></pagination-controls>

        </div>

    </ng-template>
</div>