import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { UserService } from 'src/app/services/user/user.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AttendanceService } from 'src/app/services/attendance-extra/attendance.service';
import * as moment from 'moment';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-reportee-attendance',
  templateUrl: './reportee-attendance.component.html',
  styleUrls: ['./reportee-attendance.component.scss']
})
export class ReporteeAttendanceComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: Event) {
    this.router.navigate(['/leaveManagement']);
    event.returnValue = false;
  }
  @Input() reporteeId: any;
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  userId: any;
  calendarOptions: any;
  allReporteeLeaveBalance: any = [];
  status = 'LEAVE';
  cmId: any;
  monthIndex: any;
  onLeaveRespnseData: any = [];
  OnLeaveEvent: any = [];
  reporteeData: any;
  allYearReporteeStatus: any = [];
  allReporteeLeaveStatus: any = [];
  todayReporteeLeaveData: any = [];
  monthReporteeLeaveData: any = [];

  constructor(
    public feedbackgroupservice: NewFeedbackGroupService,
    private userservice: UserService,
    private leaveApprovalService: LeaveApprovalService,
    public dialog: MatDialog,
    public userDetailsService: UserDetailsService,
    private attendanceService: AttendanceService,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
      }
    });
  }

  ngOnInit(): void {

    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }

    this.calendarOptions = {
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin],
      initialDate: new Date(),
      //events: this.newEvents,
      weekends: true,
      firstDay: 1,
      datesSet: event => {
        const dateObj = new Date(event.end);
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth();
        this.selectedMonth = month == 0 ? 'December' : this.months[month - 1];
        this.selectedYear = month == 0 ? year - 1 : year;
        // this.apiCallForStatus();
        this.onLeaveRespnseData = [];
        this.reporteeData = [];

        this.monthIndex = this.months.indexOf(this.selectedMonth) + 1;
        this.leaveApprovalService.getReporteeAttendance(this.selectedYear, this.reporteeId).subscribe((res) => {
          let response = res.body;
          this.todayReporteeLeaveData = [];
          this.monthReporteeLeaveData = [];
          this.allYearReporteeStatus = [];
          for (let i = 0; i < response.length; i++) {
            let date = response[i].attendanceDateTime;
            const formattedDate = moment(date).format('YYYY-MM-DD');
            let status = response[i].attendanceStatus;
            let bgColor = this.getStatusBg(status);
            const currentEvent: EventInput = {
              title: '',
              start: formattedDate,
              end: formattedDate,
              backgroundColor: bgColor
            };
            this.allYearReporteeStatus.push(currentEvent);

            // for today
            if (this.isToday(date) && status == this.status) {
              this.userservice.getUserByID(this.reporteeId).subscribe((result) => {
                let name = result.body.firstName + ' ' + result.body.lastName;
                let todayEmp = {
                  name: name,
                  date: date,
                  status: status,
                }
                this.todayReporteeLeaveData.push(todayEmp);
              })
            } else if (status == this.status) {
              this.userservice.getUserByID(this.reporteeId).subscribe((result) => {
                let name = result.body.firstName + ' ' + result.body.lastName;
                let todayEmp = {
                  name: name,
                  date: date,
                  status: status,
                }
                this.monthReporteeLeaveData.push(todayEmp);
                console.log(this.monthReporteeLeaveData);
              })
            } else {
              // nothing with other statuses
            }
          }

          this.calendarOptions.events = this.allYearReporteeStatus;

        }, (e) => { })

      },

      headerToolbar: {
        start: '',
        // center: 'title',
        // end: 'today next nextYear'
        end: 'today prev next'
      },

    };
  }

  getStatusBg(status) {
    if (status == 'PRESENT') {
      return '#28A745';
    }
    else if (status == 'REGULARIZED_PRESENT') {
      return '#FFA900';
    }
    else if (status == 'ABSENT' || status == 'UNMARKED_ATTENDANCE') {
      return '#EB0000';
    }
    else if (status == 'LEAVE') {
      return '#0045B9';
    }
    return ' #FAA8A9';
  }

  isToday(date: any): boolean {
    const currentDate = new Date();
    const formattedDate = new Date(date);

    if (isNaN(formattedDate.getTime())) {
      return false;
    }

    const isSameDate =
      currentDate.getFullYear() === formattedDate.getFullYear() &&
      currentDate.getMonth() === formattedDate.getMonth() &&
      currentDate.getDate() === formattedDate.getDate();

    return isSameDate;
  }

  isSameMonth(date: string, selectedMonth: string): boolean {
    const reporteeDate = new Date(date);
    const monthName = reporteeDate.toLocaleString('en-US', { month: 'long' });

    return monthName.toLowerCase() === selectedMonth.toLowerCase();
  }




  formatDateIntoDateMonth(date: any): string {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const parts = date.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;

    return `${day} ${months[month]}`;
  }

  isWeekend(dateString) {

    let date = new Date(dateString);
    return date.getDay() === 6 || date.getDay() === 0;
  }

  public onMonthYearChange() {
    if (this.fullCalendar) {
      const date = new Date(this.selectedYear, this.months.indexOf(this.selectedMonth), 1);
      this.fullCalendar.getApi().gotoDate(date);
    }
  }

}
