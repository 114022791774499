import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UnderConstructionPopupComponent } from '../../under-construction-popup/under-construction-popup.component';
import { RegularizeComponent } from '../../leave-management/regularize/regularize.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';

@Component({
  selector: 'app-leave-summary',
  templateUrl: './leave-summary.component.html',
  styleUrls: ['./leave-summary.component.scss']
})
export class LeaveSummaryComponent implements OnInit {

  constructor( 
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private leaveBalanceService: LeaveBalanceService,
       
    ) { }

  userId: any;
  leavebalanceData: any;
  paidLeave: any = 0;
  unplannedLeave: any = 0;
  lossOfPay: any = 0;
  compOff: any = 0;
  contigencyLeave: any = 0;
  paternityLeave: any = 0;
  restrictedHolidaysLeaves: any = 0;
  maternityLeave: any = 0;
  specialLeave: any = 0;
  totalAvailableBalance: any = 0;

  ngOnInit(): void {
    this.userId = this.userDetailService.getUserDetails().id;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    this.leaveBalanceService.getLeaveBalanceByIdAndYear(this.userId, currentYear).subscribe(res => {
      this.leavebalanceData = res.body.balanceLeave;
      //console.log(this.leavebalanceData)
      this.paidLeave = this.leavebalanceData.PAID_LEAVE;
      this.unplannedLeave = this.leavebalanceData.UNPLANNED_LEAVE;
      this.lossOfPay = this.leavebalanceData.LOSS_OF_PAY;
      this.compOff = this.leavebalanceData.COMP_OFF;
      this.contigencyLeave = this.leavebalanceData.CONTINGENCY_LEAVE;
      this.paternityLeave = this.leavebalanceData.PATERNITY_LEAVE;
      this.restrictedHolidaysLeaves = this.leavebalanceData.RESTRICTED_HOLIDAY;
      this.maternityLeave = this.leavebalanceData.MATERNITY_LEAVE;
      this.specialLeave = this.leavebalanceData.SPECIAL_LEAVE;
      this.totalAvailableBalance = this.paidLeave + this.unplannedLeave + this.lossOfPay + this.compOff + this.contigencyLeave + this.paternityLeave + this.maternityLeave + this.restrictedHolidaysLeaves + this.specialLeave;
    })
  }

  openUnderConstructionPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.height = "80%";
    dialogConfig.panelClass = "under-construction-popup"
    const dialogRef = this.dialog.open(UnderConstructionPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
