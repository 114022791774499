<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Professional Skills</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>
   
    </div>
</div>
<hr>
<div class="container-fluid">
    

    <div class="row">
        <div class="col-md-12 form_main_div">
            <div class="add_button_fix">
                <button class="add_button" (click)="addSkill()">+Add</button>
                <button class="plus_button mt-4" (click)="addSkill()"><span>+Add</span></button>
            </div>
            <form [formGroup]="editSpotlightDetails">
                <div class="row" formArrayName="skills" *ngFor="let skill of this.Skills.controls; let i = index">

                    <div class="col-md-4" [formGroupName]="i">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Title</mat-label>
                            <input matInput formControlName="technology" autocomplete="off" pattern="[a-zA-Z ]*">
                            <mat-error *ngIf="editSpotlightDetails.get('skills').controls[i].get('technology').hasError('pattern')">
                                Only alphabets and spaces are allowed
                              </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-8" [formGroupName]="i">
                        <ngb-rating formControlName="rate" class="ngb-rating" [max]="5" [readonly]="false">
                            <button class="remove_label">Remove</button>

                        </ngb-rating>
                        <button (click)="removeskill(skill.value.id,i)" class="remove_label">Remove</button>
                       
                    </div>




                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>