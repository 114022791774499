import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoalObjectivesService {

  private static readonly goalObjectives: any = [
    {
      name: 'BuildingTheBusinessIp',
      descriptor: ['Seeks opportunities to develop IP.', 'Generally produces IP without suggestion.'],
      successCriteriaAndMeasurement: ['Participate in Confluence, Conference, Meet ups, Networking events, WOW sessions and Fresh Bites Sessions.'],
      blueCareValues: ['Enhance my Company']
    },
    {
      name: 'ClientRelations',
      descriptor: ['Proficiently understands terms of agreement between client and BA, and operates within these terms.', 'Assists the client by providing basic solutions to problems.', 'Establishing a relationship of trust, respect, and influence with client.'],
      successCriteriaAndMeasurement: ['Client projects delivered on time, feedback from the client, feedback from supervisors."', 'Strategies are proposed, even if they are not ultimately used in the project.', 'The management of clients and solutions is a crucial aspect of the project.', 'Client feedback, requests, trusted advisor.'],
      blueCareValues: ['Value to my client']
    },
    {
      name: 'Communication',
      descriptor: ['Conveys ideas and thoughts in a clear and concise style using verbal and written communication skills.'],
      successCriteriaAndMeasurement: ['Builds a strong communication channel with peers and career manager using Slack and Confluence.', 'Proactively communicates information, delays, and issues on the project to the supervisor, career manager, and team.', 'Asks for assistance where required.'],
      blueCareValues: ['Enrich myself']
    },
    {
      name: 'CoreValues',
      descriptor: ['Observes and lives BA values.'],
      successCriteriaAndMeasurement: ['Displays any or all of the values regularly or mentions examples where these were displayed, such as openness, passion, empowerment, respect, integrity, adaptability, and diligence.', 'Shares new idea or participates in projects.', 'Follows BA standards, such as documentation, emails, and communication.', 'Managing change and Improvements: accepts and adapts to change and focuses on improvements.', 'Safety: Demonstrates safety awareness and actively contributes to personal and office safety.', 'Actively connected to Blue Altair on social network i.e LinkedIn, Facebook etc.'],
      blueCareValues: ['Enhance my Company']
    },
    {
      name: 'TeamWorkAndCollaboration',
      descriptor: ['Has established effective relationships with team members.', 'Works well with individuals who have different work styles.', 'Contributing to the enhancement of the overall team and company.'],
      successCriteriaAndMeasurement: ['Evidence of interactions and active participation in team engaging activities, social gatherings, and idea contributions.', 'Dependability: Timely communicates with the career manager, team members, and operations team regarding meetings, leave, or unavailability.'],
      blueCareValues: ['Enhance my Company']
    },
    {
      name: 'TechnicalCapability',
      descriptor: ['Practical and applied knowledge of Capability.', 'Able to efficiently apply knowledge and expertise to assigned tasks.', 'Quick to learn new technologies.'],
      successCriteriaAndMeasurement: ['Completed projects, further training, client delvierable success', 'New accreditations, involvement in other capabilities.', 'Contributes to hiring and training related activities within capabaility.', 'Researchers at least one new skill/technology per quarter.'],
      blueCareValues: ['Enrich myself']
    },
    {
      name: 'TimeManagement',
      descriptor: ['Developing awareness of time management.', 'Learning to prioritize and effectively manage competing priorities.'],
      successCriteriaAndMeasurement: ['Understanding how to plan work effectively.', 'Timely completion of set tasks.', 'Ontime for meetings, timesheets.', 'Mentoring team members if applicable.'],
      blueCareValues: ['-']
    },
    { 
      name: 'Compliance',
      descriptor: ['Completion of HIPAA & Infosec training by the deadline and regular submission of timesheets.'],
      successCriteriaAndMeasurement: ['-'],
      blueCareValues: ['Enhance my Company, Enhance myself']
    },
    {
      name: 'OverallProgress',
      descriptor: ['-'],
      successCriteriaAndMeasurement: ['-'],
      blueCareValues: ['-']
    }
  ];

  constructor() { }

  static getGoalObjectives(): any {
    return this.goalObjectives;
  }
}
