export class HolidayDTO {
    date: Date;
    clientID: string;
    holidayType: string;
    description: string;


    constructor() {
        this.date=undefined;
        this.clientID=undefined;
        this.holidayType=undefined;
        this.description=undefined;
    }
}