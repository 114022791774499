import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user/user.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AddAssetComponent } from '../../add-asset/add-asset/add-asset.component';
import { AssetManagementService } from 'src/app/services/asset-management/asset-management.service';
import { RevokeAssetComponent } from '../../revoke-asset/revoke-asset/revoke-asset.component';
import { ActivatedRoute } from '@angular/router';
import { AssignAssetComponent } from '../../assign-asset/assign-asset/assign-asset.component';
import { AssetCategories } from 'src/app/services/asset-management/asset-enum';

@Component({
  selector: 'app-view-asset',
  templateUrl: './view-asset.component.html',
  styleUrls: ['./view-asset.component.scss']
})
export class ViewAssetComponent implements OnInit {

  allEmployeeIdsWithNames = {};
  data: any;
  inititateRevoke: boolean;
  revokeComment: String;
  assetDetails;
  id: any;
  dataAvailable: boolean;
  empName: String = '';
  empImage: any;
  assetHistoryPresent: boolean;
  assetHistoryEmployeeImages = {};
  assetHistoryEmployeeNames = {};
  assetHistory;
  public getScreenWidth: any;
  public getScreenHeight: any;
  mobileview: boolean = false
  assetEnumCategories: any;

  constructor(public dialog: MatDialog,
    private userService: UserService,
    private assetService: AssetManagementService,
    private route: ActivatedRoute,
    private _location: Location) {
  }

  ngOnInit(): void {
    this.assetEnumCategories = AssetCategories
  
    this.assetHistoryEmployeeImages = {};
    this.assetHistoryEmployeeNames = {};
    this.empName = '';
    this.id = this.route.snapshot.paramMap.get('id');
    this.assetService.getAssetViewScreenDetails(this.id).subscribe(data => {
      this.assetDetails = data.body;
      if (this.assetDetails.employeeId != null) {
        this.populateEmployeeName(this.assetDetails.employeeId);
      }
      if (this.assetDetails.assetHistory.length > 0) {
        this.assetHistoryPresent = true;
        for (var history of this.assetDetails.assetHistory) {
          this.populateEmployeeNameForHistory(history.employeeId);
         this.getEmployeeImage(history.employeeId);
        }
      }
      this.dataAvailable = true;
    }, error => {
    });

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 600) {
      this.mobileview = false
    }
    if (this.getScreenWidth < 600) {
      this.mobileview = true
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 600) {
      this.mobileview = false
    }
    if (this.getScreenWidth < 600) {
      this.mobileview = true
    }
  }

  populateEmployeeName(id) {
    this.userService.getEmpNameFromEmpId(id).subscribe(data => {
      let employeeName = data.body.message;
      this.empName = employeeName;
    },
      error => {
      });;
  }

  populateEmployeeNameForHistory(id) {
    this.userService.getEmpNameFromEmpId(id).subscribe(data => {
      let employeeName = data.body.message;
      this.assetHistoryEmployeeNames[id] = employeeName;
    },
      error => {
      });;
  }

  editAsset() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.data = this.assetDetails;
    const dialogRef = this.dialog.open(AddAssetComponent, dialogConfig).afterClosed().
      subscribe(result => {
        if (result.event === 'Updated'){
        this.ngOnInit();
      }
      });
  }

  setHistoryList() {
    return Object.assign([], this.data.assetHistory);
  }

  getEmployeeImage(id) {
    this.userService.getUserThumbnailFromEmpId(id).subscribe(data => {
      let employeeImage = data.body.userImageThumbnail;
      if (employeeImage === undefined || employeeImage === null || employeeImage === ''){
        this.assetHistoryEmployeeImages[id] = '';
      }
      else{
      this.assetHistoryEmployeeImages[id] = employeeImage;
    }
    },
      error => {
      });;
  }

  openRevokeAssetDialog(assetDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.data = { 'assetDto': assetDetail }
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RevokeAssetComponent, dialogConfig).afterClosed().
      subscribe(result => {
        if (result.event != 'Closed'){
          this.ngOnInit();
      }}
    )
    }

  openAssignAssetDialog(assetDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { 'assetDto': assetDetail }
    const dialogRef = this.dialog.open(AssignAssetComponent, dialogConfig).afterClosed().
      subscribe(result => {
        // this.assetDetails = result.body;
        if(result.event != "Closed"){
        this.ngOnInit();
      }
      }
        );
  }

  backClicked() {
    this._location.back();
  }

}
