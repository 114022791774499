import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/services/project.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { ResignationServiceService } from 'src/app/services/resignationService/resignation-service.service'; 
import { TalentService } from 'src/app/services/talent/talent.service';
import { ktUserDTO } from 'src/app/dtos/ktUserDTO';
import { RelievingEmployeeDTO } from 'src/app/dtos/ktUserDTO';
import { KnowledgeTransferField, KnowledgeTransferFieldsValue, KnowledgeTransfersDTO, knowledgeTransferDTO } from 'src/app/dtos/KnowledegeTransferDTO';
import { getDirectChildElement } from 'ngx-drag-drop/dnd-utils';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
@Component({
  selector: 'app-knowledge-transfer',
  templateUrl: './knowledge-transfer.component.html',
  styleUrls: ['./knowledge-transfer.component.scss']
})
export class KnowledgeTransferComponent implements OnInit {
  newEmployeeDetails: FormGroup;
  ifCM: boolean = false;

  userIdNameMapping = {}
  projectIDMapping = {};
  projectNameMapping = {}
  projectList;
  userForm: FormGroup;
  exitDetailsForm: FormGroup;
  fianlKtForm: FormGroup;

  projectName: any;
  directReporteeId: any;
  userId: any;
  userNameIDMapping = {};
  temp = {}
  userList;
  uList: any;
  relievingEmployee = new RelievingEmployeeDTO();
  knowledgeTransferFields: any = [];
  knowledgeTransferList: any = [];
  otherTaskList: any = ['Capability knowledge or information transfer', 'Direct reportee transition to new career manager - work, goal and objective', 'Operations related'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<KnowledgeTransferComponent>,
    public userDetailService: UserDetailsService,
    private userservice: UserService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public fb: FormBuilder,
    public toastr: ToastrService,
    private resignationService: ResignationServiceService,
    private projectService: ProjectService,
    public talentservice: TalentService,



  ) { }

  ngOnInit(): void {
    console.log(this.data)
    this.setForm();
    this.ifCM = this.userDetailService.hasRole(['ROLE_CAREER_MANAGER']);

    this.getExitDetails();
    this.getuserList();
    this.showQuestionsBox();

    this.exitDetailsForm = this.fb.group({});
    this.fianlKtForm = this.fb.group({});
  }

  setForm() {
    this.userForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      userName: ['', Validators.required],
      projectName: ['', Validators.required]
    });
  }
  getExitDetails() {
    // console.log(this.data)
    this.resignationService.getExitDetails(this.data.taskTransactionId).subscribe(result => {
      this.directReporteeId = result.body.userId;
      this.userservice.getUserByID(this.directReporteeId).subscribe((res) => {
        console.log(res.body);
        // console.log(result.body);
        this.relievingEmployee.name = res.body.firstName + " " + res.body.lastName;
        this.relievingEmployee.mail = res.body.email;
        this.relievingEmployee.capablity = res.body.department;
        this.relievingEmployee.title = res.body.jobTitle;
        this.relievingEmployee.cmUserId = res.body.careerManagerId;
        this.relievingEmployee.baseLocation = res.body.baseLocation;
        this.getCmDetails(this.relievingEmployee.cmUserId);
      })
      this.getprojectList();
    }, (e) => { })

  }
  getCmDetails(userId) {
    this.userservice.getUserByID(userId).subscribe((res) => {
      this.relievingEmployee.cmName = res.body.firstName + " " + res.body.lastName;
    })
  }

  getuserList() {

    this.userservice.getAllUserForDropDown().subscribe(usrData => {

      usrData.body.forEach(element => {

        if (element.userID != this.directReporteeId) {
          this.userNameIDMapping[element.firstName + " " + element.lastName + "(" + element.employeeId + ")"] = element.userID;
          this.userIdNameMapping[element.userID] = element.firstName + " " + element.lastName + "(" + element.employeeId + ")";
          this.temp[element.firstName + " " + element.lastName] = element.userID;
        }
      });


      this.userList = Object.keys(this.userNameIDMapping);
      this.uList = Object.keys(this.temp);

    });
  }

  getUserID(username) {
    this.userId = this.userNameIDMapping[username] || this.temp[username];
  }
  getprojectList() {
    this.talentservice.getTalentByUserIdSubscribe(this.directReporteeId).subscribe(res => {
      for (let r of res.body) {
        this.projectService.getProjectById(r.projectId).subscribe(proList => {
          this.projectIDMapping[proList.body.projectName] = proList.body.id;
          this.projectNameMapping[proList.body.id] = proList.body.projectName;
          this.projectList = Object.keys(this.projectIDMapping);
        })
      }
    })

  }

  getProjectID(projectid) {
    this.projectName = this.projectIDMapping[projectid];
    return this.projectName
  }
  checkError = (controlName: string, errorName: string) => {
    return this.newEmployeeDetails.controls[controlName].hasError(errorName);
  }


  onAdd() {
    console.log(this.userForm.controls)
    if (this.userForm.valid) {

      console.log(this.data.taskTransactionId)

      let ktUser = new ktUserDTO();
      ktUser.startDate = this.userForm.controls.startDate.value;
      ktUser.endDate = this.userForm.controls.endDate.value;
      ktUser.knowledgeTransferedTo = this.userId;
      ktUser.taskType = "PROJECT";
      ktUser.projectId = this.projectName;
      console.log(ktUser)
      this.resignationService.addKtUser(this.data.taskTransactionId, ktUser).subscribe(res => {
        console.log(res.body);
        this.showQuestionsBox();
        // this.userForm.reset({});
      })

    } else {
      this.markFormGroupTouched(this.userForm);
    }
  }

  createFormControlName(i: number, fieldKey: string): string {
    return `knowledgeTransferFields_${i}_${fieldKey}`;
  }

  showQuestionsBox() {
    this.knowledgeTransferFields = [];
    this.knowledgeTransferList = [];
    this.resignationService.getExitDetails(this.data.taskTransactionId).subscribe(res => {
      // for (let i = 0; i < res.body.knowledgeTransfersDTO.length; i++) {
      //   // const knowledgeTransferFields = res.body.knowledgeTransfersDTO[i].knowledgeTransferFields;
      //   // this.initFormControls(knowledgeTransferFields, i);
      // }

      for (let i = 0; i < res.body.knowledgeTransfersDTO.length; i++) {
        console.log(res.body.knowledgeTransfersDTO[i])
        this.getKtName(this.directReporteeId, res.body.knowledgeTransfersDTO[i]);
        debugger
        this.knowledgeTransferFields.push(res.body.knowledgeTransfersDTO[i]);
      }
      console.log(this.knowledgeTransferFields)
    })
  }

  initFormControls(knowledgeTransferFields: any, i: number): void {
    for (const key in knowledgeTransferFields) {
      if (knowledgeTransferFields.hasOwnProperty(key)) {
        const formControlName = this.createFormControlName(i, key);
        this.exitDetailsForm.addControl(formControlName, new FormControl(''));
      }
    }
  }
  logFormValues() {
    console.log(this.exitDetailsForm.value);
  }

  //to collapse the table on clicking the drop-down arrow 
  isTablecollapse = false;

  collapse() {
    this.isTablecollapse = !this.isTablecollapse;
  }

  // to add a dynamic row to 1st table
  addRow(questions: any) {
    //debugger
    var lastkey = Object.keys(questions.knowledgeTransferFields).length + 1;
    var temp = {
      title: "Others",
      knowledgeTransferFieldsValue: {
        CARRER_MANAGER: {
          value: '',
          disabled: false
        },
        RESIGNEE: {
          value: '',
          disabled: false
        },
        TRANSFERED_TO: {
          value: '',
          disabled: false
        }
      }
    };
    questions.knowledgeTransferFields[lastkey] = temp
  }
  //To add the row in the other tasks table
  addRow1() {
    //debugger
    this.otherTaskList.push('')
  }

  buildDtoForSubmission() {
    //debugger
    const dto: knowledgeTransferDTO = new knowledgeTransferDTO();
    console.log(dto);
    //add code to map the form data
    for (let i = 0; i < this.knowledgeTransferFields.length; i++) {
      console.log(this.knowledgeTransferFields[i])
      dto[i]=this.knowledgeTransferFields[i];
      debugger
       this.resignationService.submitExitDetails(this.data.taskTransactionId,dto[0]).subscribe(response => {
       console.log('successful');
     });
    this.closeDialog();
    } 
  }


  getKtName(userId, DTO) {
    //debugger
    this.userservice.getUserByID(DTO.knowledgeTransferedTo).subscribe((res) => {
      //debugger
      let currentEmployee = {
        knowledgeTransferedTo: res.body.firstName + " " + res.body.lastName,
        relievingEmployee: this.relievingEmployee.name,
        startDate: DTO.startDate,
        endDate: DTO.endDate,
        ktId: DTO.id,
        exitDetailId: this.data.taskTransactionId
      }
      this.knowledgeTransferList.push(currentEmployee);
    })
  }

  removeKtUser(detail) {
    console.log(detail);
    this.resignationService.deleteKtUser(detail.exitDetailId, detail.ktId).subscribe(res => {
      this.showQuestionsBox();
    })
  }


  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }


}
