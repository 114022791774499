<!-- <button type="button" class="btn btn-primary"
style="margin-top: 10px;margin-left: 10px;background-color: #00BFD5;" (click)="openDialog()">Create Form <i class="pl-2 fas fa-plus"></i></button>
<button type="button" class="btn btn-primary" style="margin-left: 20px;background-color: #00BFD5; margin-top: 10px;" (click)="editDialog()">Edit Form  <i class="fas fa-edit"></i></button> -->
<!-- <div *ngIf="dialogValue">
  <b>Value From Dialog</b>: {{dialogValue}}
</div>
<h3>{{data}}</h3> -->
<!-- <div class="pageCenter" *ngIf="isPresent">
  <app-dynamic-form [parentData]="data" aria-disabled="true"></app-dynamic-form>
</div> -->

<!-- Corousel Start -->
<!-- <button type="submit"
                    (click)="previousState()"
                    class="btn btn-info" style="margin-top: 20px;margin-left: 20px;"><span>Back</span>
            </button> -->

<div class="pageCenter">
<ngb-carousel *ngIf="data">
  <ng-template ngbSlide *ngFor="let dataitem of data">
    <app-dynamic-form [parentData]="dataitem"></app-dynamic-form>
  </ng-template>
</ngb-carousel>
</div>
<!-- Corousel End -->