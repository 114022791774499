import { Component, OnInit, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.scss']
})
export class MyProjectsComponent implements OnInit {

  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;
  projects : any[] = [];

  // @Input() projects;
  constructor(
    private projectService : ProjectService
  ) { }

  ngOnInit(): void {
    const todayDate: Date = new Date();
    //todayDate.setHours(0, 0, 0, 0);
    this.projectService.getAllProjects().subscribe((res)=>{
      for(let r of res){
        const endDate = new Date(r.endDate);
        //endDate.setHours(0, 0, 0, 0);
        if(endDate >= todayDate){
          this.projects.push(r);
        }
      }
      //console.log(this.projects);
    },
    (error) => {
      console.error('Error fetching projects:', error);
    }
    )
  }

}
