<mat-card class="main-card pr-0 pl-0">
  <div class="row">
    <div class="col-md-12">
      <label class="head ml-2">Upcoming Holidays</label>

    </div>
  </div>
  <div class="row">
    <div class="col-md-12 img_div">
      <!-- <img class="mt-3 work_in_progress_img" src="assets/work_in_progress.png">
      <label class="mt-3 work_in_progress_label">Work in progress..</label> -->


      <div class="tableFixHead">
        <div *ngIf="restrictedHolidays.length==0" class="heading headingOne">
          No Holidays Available
        </div>

        <div *ngFor="let holiday of restrictedHolidays">
          <div class="row mt-3">
            <div class="col-8">
              <div class="holidays">
                <span class="holiday_date">{{holiday.date}}</span>
                <br>
                <span class="holiday_name ">{{holiday.desc}}</span>
              </div>
            </div>
            <div class="col-4" *ngIf="holiday.status">
              <div class="apply applyLeaveBtn-dark">Applied</div>
            </div>
            <div class="col-4" *ngIf="!holiday.status">
              <div class="apply applyLeaveBtn" (click)="applyRestrictedHoliday(holiday.desc, holiday.originalDate)">Apply</div>
            </div>
          </div>

        </div>

        
        
      </div>

    </div>
  </div>

</mat-card>