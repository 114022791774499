<div class="container1" *ngIf="!this.loggedInProfile">
  <div class="year-align">
    <button class="btn-real-time-feedback" (click)="AssignFeedback()">Real-time Feedback</button>
    <div class="year-filter">
      <mat-select [(ngModel)]="selectedYear" (ngModelChange)="getFeedbackDetails()">
        <mat-option *ngFor="let year of years" [value]="year">
          {{year}}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="row mt-3 full-screen-bg-white">
    <div class="col-md-12">
      <div class="no-feedbacks" *ngIf="feedback.length==0">
        No feedbacks Submitted.
      </div>
      <div class="table-fixed">
        <div class="record-card" *ngFor="let f of feedback  | orderBy: 'date' :'asc'">
          <label>
            <div>
              <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
            </div>
          </label>
          <label class="user_name">{{f.name}}</label>
          <label class="user_role">Feedback title <br> {{f.title}}</label>
          <label class="user_role">Score <br> <span class="blue-col">{{f.score}}</span></label>
          <label class="user_role">Given feedback as <br> <span class="blue-col">{{f.role | underScoreToSpace| titlecase
              }}</span></label>
          <!-- <label class="user_role"> <br>
          <div *ngIf="feedback.isContinue" class="blue-col" (click)="redirectToTask()">Continue</div>
        </label> -->
          <div [matMenuTriggerFor]="beforeMenu1">
            <img src="assets/3dots.png" class="threedots">
          </div>
          <mat-menu class="matmenu" #beforeMenu1="matMenu" xPosition="before">

            <button mat-menu-item *ngIf="f.isContinue" (click)="redirectToTask(f.id)">
              <span class="bold">Continue editing</span>
            </button>
            <!-- feedback submitted=true, updated within<48hrs -->
              <button mat-menu-item *ngIf="f.isSubmitted && f.isEditable" (click)="editFeedback(f)">
                <span class="bold">Edit</span>
              </button>

            <button mat-menu-item (click)="deleteFeedback(f)">
              <span class="bold">Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3 full-screen-bg-white" *ngIf="this.loggedInProfile">

  <div class="col-md-12">
    <div class="real-time-year">
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Year</mat-label>
        <mat-select [(ngModel)]="selectedYear" (ngModelChange)="getFeedbacks()">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="table-fixed">
      <div class="record-card" *ngFor="let feedback of feedbackList | orderBy: 'date' :'asc'">
        <label>
          <div>
            <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
          </div>
          <!-- <div *ngIf="feedback.userImageThumbnail !== ''">
            <img class="employee_img" [(src)]="feedback.userImageThumbnail" alt="">
          </div> -->
        </label>
        <label class="user_name">{{feedback.name}}</label>
        <label class="user_role">Feedback title <br> {{feedback.title}}</label>
        <label class="user_role">Score <br> <span class="blue-col">{{feedback.score}}</span></label>
        <label class="user_role">Given feedback as <br> <span class="blue-col">{{feedback.role | underScoreToSpace|
            titlecase
            }}</span></label>
        <!-- <label class="user_role"> <br>
          <div *ngIf="feedback.isContinue" class="blue-col" (click)="redirectToTask()">Continue</div>
        </label> -->
        <div [matMenuTriggerFor]="beforeMenu">
          <img src="assets/3dots.png" class="threedots">
        </div>
        <mat-menu class="matmenu" #beforeMenu="matMenu" xPosition="before">

          <button mat-menu-item *ngIf="feedback.isContinue" (click)="redirectToTask(feedback.id)">
            <span class="bold">Continue editing</span>
          </button>
          <!-- feedback submitted, updated within<48hrs -->
          <button mat-menu-item *ngIf="feedback.isSubmitted && feedback.isEditable" (click)="editFeedback(feedback)">
            <span class="bold">Edit</span>
          </button>

          <button mat-menu-item (click)="deleteFeedback(feedback)">
            <span class="bold">Delete</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>