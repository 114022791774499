import { Injectable } from '@angular/core';
import { RestAPIService } from './restAPI/rest-api.service';
import { UserDocumentURLService } from './restAPI/userdocument-url.service';

@Injectable({
  providedIn: 'root'
})
export class UserDocumentService {

  constructor( private restApiService: RestAPIService,
    private userdocumenturl : UserDocumentURLService) { }



  postdocument(payload) {
   
    return this.restApiService.post(
      this.userdocumenturl.getBaseUrl(),
      payload
    )
  }
  getuserdocumentbyuserId(documentName,userId){
    return this.restApiService.get(
      this.userdocumenturl.getUserUrl(documentName,userId)
    )
  }
  getuserdocumentlistuserId(documentName,userId){
    return this.restApiService.get(
      this.userdocumenturl.getUserdoclist(documentName,userId)
    )
  }
  putdocument(payload) {
    return this.restApiService.put(
      this.userdocumenturl.getBaseUrl(),
      payload
    )
  }



}
