


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExitChecklistUrlsService {


  private readonly exitDetailsBaseApi = '/api/exit-details';
  private readonly exitCheclist = '/create/exit-checklist'
  private readonly updateCheclist = '/exit-checklist'
  
  constructor() { }

  getBaseUrl(){
    return this.exitDetailsBaseApi;
  }

  getDetails(id){
    return this.exitDetailsBaseApi + '/' + id + this.exitCheclist ;
  }

  updateDetails(id){
    return this.exitDetailsBaseApi + '/' + id + this.updateCheclist;
  }

 

}
