<div class="main-div">
    <div>
        <div class="container">
            <h1 class="heading1">Real-time Feedback</h1>
            <button class="btn-submit" (click)="closeDialogue()">Close</button>
        </div>
        <hr>
        <div>

            <form [formGroup]="form" (ngSubmit)="submitForm()">
                <div class="form-element">
                    <!-- <div>
                    <input class="feedbackTitle" type="text" id="feedbackTitle" name="feedbackTitle" formControlName="feedbackTitle"
                      placeholder="Feedback Title*" required  pattern="[a-zA-Z\s]*" maxlength="32">
                    <mat-error *ngIf="form.get('feedbackTitle').hasError('required') && form.get('feedbackTitle').touched">
                      Feedback Title is required.
                    </mat-error>
                  </div> -->
                    <div class="fb-title">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Feedback Title</mat-label>
                            <input matInput type="text" autocomplete="off" formControlName="feedbackTitle"
                                pattern="[a-zA-Z\s]*" maxlength="32" required>
                            <mat-error *ngIf="form.get('feedbackTitle').hasError('required')">
                                required
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('feedbackTitle').hasError('pattern') && form.get('feedbackTitle').touched">
                                Only alphabets and spaces are allowed
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('feedbackTitle').hasError('maxlength') && form.get('feedbackTitle').touched">
                                Feedback Title should not exceed 32 characters
                            </mat-error>
                        </mat-form-field>

                    </div>



                    <div>
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Year</mat-label>
                            <mat-select formControlName="selectedYear" required>
                                <mat-option *ngFor="let year of years" [value]="year">
                                    {{ year }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="form.get('selectedYear').hasError('required') && form.get('selectedYear').touched">
                                required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <mat-form-field class="feedbackTitle" appearance="outline" class="example-full-width">
                            <mat-label>Giving feedback as</mat-label>
                            <mat-select formControlName="selectedRole" (selectionChange)="selectedRole($event.value)"
                                name="selectedRole" required>
                                <mat-option *ngFor="let role of FeedbackRole" [value]="role">
                                    {{ role | underScoreToSpace | titlecase }}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="form.get('selectedRole').hasError('required') && form.get('selectedRole').touched">
                                required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="button-container">
                    <button class='btn-sub' type="submit">Next</button>
                </div>
            </form>

        </div>
    </div>
</div>

<!-- commented and added new method -->
<!-- <form (submit)="submitForm()">
                <div class="form-element">
                    <div>
                        <input class="feedbackTitle" type="text" id="feedbackTitle" name="feedbackTitle"
                            [(ngModel)]="feedbackTitle" placeholder="Feedback Title*" required>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Year</mat-label>
                            <mat-select [(ngModel)]="selectedYear" required="true">
                                <mat-option *ngFor="let year of years" [value]="year" >
                                    {{year}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="feedbackTitle" appearance="outline" class="example-full-width">
                            <mat-label>Role</mat-label>
                            <mat-select [(ngModel)]="role" (selectionChange)="selectedRole($event.value)"
                                name="selectedRole">
                                <mat-option *ngFor="let role of this.FeedbackRole" [value]="role">
                                    {{ role | underScoreToSpace | titlecase }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="button-container">
                    <button class='btn-sub' type="submit">Next</button>
                </div>
            </form> -->