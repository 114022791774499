import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectService } from 'src/app/services/project.service';
import { TalentService } from 'src/app/services/talent/talent.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddAssetComponent } from '../../asset-management/add-asset/add-asset/add-asset.component';
import { AssetCardComponent } from '../../asset-management/asset-card/asset-card/asset-card.component';
import { AddEditClientComponent } from '../../client-details/add-edit-client/add-edit-client.component';
import { ResignationemailComponent } from '../../resignationemail/resignationemail.component';
import { AddProjectCardComponent } from '../add-project-card/add-project-card.component';
import { ProjectCardComponent } from '../project-card/project-card.component';

@Component({
  selector: 'app-main-content-project',
  templateUrl: './main-content-project.component.html',
  styleUrls: ['./main-content-project.component.scss'],
})
export class MainContentProjectComponent implements OnInit {
  empDetail;
  searchText: string;
  matTabsClickedIndex = 0;
  hasRoleBased: any;
  userRoles: any;
  projectList=[]
  @ViewChild(AssetCardComponent) assetCardComponent: AssetCardComponent;
  @ViewChild(ProjectCardComponent) projectCardComponent: ProjectCardComponent;

  constructor(
    public projectService: ProjectService,
    public userDetailService: UserDetailsService,
    public employeeServie: UserService,
    private dialog: MatDialog,private userDetailsService: UserDetailsService, private talentservice: TalentService,
    private projectservice: ProjectService
  ) {}
  ngOnInit(): void {
    this.getProject();
    this.hasRoleBased = this.userDetailService.hasRole(['ROLE_USER']);
    this.employeeServie.fetchUserRoles().subscribe(res => {
      this.userRoles = res.body;
}
    )
  }
  OnPageClick(event) {
    this.projectService.pageCount = event.pageIndex;
    this.projectService.pageCount += 1;
    this.projectService.getProjectPagination();
  }

  onSearchTextTyped(event) {
    if (event == '') {
      event = '';
      this.searchText = '';
      this.projectService.projects = [];
      this.projectService.getProjectPagination();
    } else {
      this.projectService.projects = [];
      this.projectService.getProjectBySearch(event);
    }
  }
  getProject() {
    this.talentservice.getTalentByUserIdSubscribe(this.userDetailsService.getUserDetails().id).subscribe(res => {
      for (let r of res.body) {
        this.projectservice.getProjectById(r.projectId).subscribe(resp => {
          this.projectList.push(resp.body)
        })
      }})
  }

  addProjectDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "70%";
    
    const dialogRef = this.dialog.open(AddProjectCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.projectService.getProjectPagination();
      this.projectCardComponent.ngOnInit();
         
    });
  }

  addAssetDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '75%';
    dialogConfig.width = '100%';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(AddAssetComponent, dialogConfig).afterClosed().
      subscribe(result => {
        
        if (result.event != 'Closed'){
          this.assetCardComponent.ngOnInit();
        }});
  }

  onTabChanged($event) {
    this.matTabsClickedIndex = $event.index;
  }

  addEditClientDialog() {
     
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "80%";
    const dialogRef = this.dialog.open(AddEditClientComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  AddResignationEmailDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = 'auto';
    dialogConfig.height = 'auto';
    const dialogRef = this.dialog.open(ResignationemailComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
