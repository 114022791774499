import { FormGroup } from "@angular/forms";

interface IVISA {
    country:  string;
    visaType: string;
    validFrom : Date;
    validTo :Date;
    userId:String
    id:string
    active:string
}

export class Visa{
    processDate(FormGroup: FormGroup): any {
      throw new Error('Method not implemented.');
    }
    public country: string;
    public visaType : string
    public  validFrom: Date
    public validTo :Date
    public userId :string
    public id:string
    public active:string
    constructor(){
        this.id=undefined
        this.country = ''
        this.visaType = ''
         this.active = ''
        this.userId = undefined
        this.validFrom=undefined
        this.validTo=undefined
       
    }

    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.country = httpResponse && httpResponse.country || undefined
        this.visaType = httpResponse && httpResponse.visaType || undefined
        this.validFrom = httpResponse && new Date(httpResponse.validFrom) || undefined
        this.validTo = httpResponse && new Date(httpResponse.validTo) || undefined 
        this.userId = httpResponse && httpResponse.userId || undefined 
        return this;     
    }
}