<ng-container *ngIf="this.nodata == true; else elseTemplate">
    <div class="nothing-data">
        <img class="no-asset-img" src="assets/no-data-project.png">
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="main">
        <div class="col-md-12 pl-0">
            <mat-card class="mat_card">
                <div class="search_main ">
                    <mat-icon class="search-icon  mt-1 mr-4">search</mat-icon>
                    <input class="search_input" type="search" placeholder="Search" [(ngModel)]="searchText" value="">
                </div>
                <!-- <div class="mt-1 items">
                <img src="assets/download.png" class="download-img">
                <img (click)="redirectToBulkEmployeeComponent()" src="assets/export.png" class="export-img" title="Bulk Employee Import">
            </div> -->
            </mat-card>
        </div>

        <div *ngIf="this.mobileview==false" class="card-container" fxLayout="row wrap" fxLayout.xs="column">
            <mat-card class="matcardprojectdetail" fxFlex="100%" fxFlex.lt-sm="0 1 calc(33.3% - 32px)"
                *ngFor="let assetDetail of this.allAssetDetails | filter:searchText | paginate: {id:'asset_id' , itemsPerPage: 4, currentPage: p } ">
                <mat-card-content>
                    <div class="row">
                        <img (click)="redirectToViewAsset(assetDetail.id)"
                            *ngIf="assetDetail.assetPrimaryThumbnail !== ''"
                            src="data:image/png;base64,{{assetDetail.assetPrimaryThumbnail}}"
                            class="img-container clicking_hover_img">
                        <img *ngIf="assetDetail.assetPrimaryThumbnail === ''"
                            (click)="redirectToViewAsset(assetDetail.id)" src="assets/blue_altair_logo.png"
                            class="img-container clicking_hover_img">
                        <div class="col ml-4">
                            <div class="row">
                                <h5 class="asset-category clicking_hover" (click)="redirectToViewAsset(assetDetail.id)">
                                    {{this.assetEnumCategories[assetDetail.assetCategory]}} </h5>
                            </div>
                            <div class="row">
                                <h5 class="asset-common clicking_hover" (click)="redirectToViewAsset(assetDetail.id)">
                                    {{assetDetail.assetID}} </h5>
                            </div>
                        </div>
                        <div class="col coloums">
                            <h5 class="asset-common">Asset Name </h5>
                            <p class="asset-name"> {{assetDetail.assetName | titlecase}}</p>
                        </div>
                        <div class="col coloums">
                            <h5 class="asset-common">Asset Status </h5>
                            <p  class="asset-name">{{assetDetail.assetStatus | titlecase}}</p>
                        </div>
                        <div class="col coloums">
                            <h5 class="asset-common">Date of Purchase</h5>
                            <p  class="asset-name">{{assetDetail.purchasedOn | date: 'dd-MM-yyyy'}}</p>
                        </div>
                        <div class="col coloums">
                            <h5 class="asset-common">Condition</h5>
                            <p  class="asset-name">{{assetDetail.assetCondition | titlecase}}</p>
                        </div>
                        <div class="col coloums">
                            <button *ngIf="assetDetail.assetStatus == 'AVAILABLE'" type="button "
                                (click)="openAssignAssetDialog(assetDetail)" class="btn btn-default ">
                                Assign</button>
                            <button *ngIf="assetDetail.assetStatus == 'ISSUED'" type="button "
                                (click)="openRevokeAssetDialog(assetDetail)" class="btn btn-default ">
                                Revoke</button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
           
        </div>
        <div *ngIf="this.mobileview==true" class="card-container" fxLayout="row wrap" fxLayout.xs="column">
            <mat-card class="matcardprojectdetail" fxFlex="100%" fxFlex.lt-sm="0 1 calc(33.3% - 32px)"
                *ngFor="let assetDetail of this.allAssetDetails | filter:searchText | paginate: { itemsPerPage: 5, currentPage: p } ">
                <mat-card-content>
                    <div class="row">
                        <div class="col">
                            <img (click)="redirectToViewAsset(assetDetail.id)"
                                *ngIf="assetDetail.assetPrimaryThumbnail !== ''"
                                src="data:image/png;base64,{{assetDetail.assetPrimaryThumbnail}}"
                                class="img-container clicking_hover_img">
                            <img *ngIf="assetDetail.assetPrimaryThumbnail === ''"
                                (click)="redirectToViewAsset(assetDetail.id)" src="assets/blue_altair_logo.png"
                                class="img-container clicking_hover_img">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col coloums">
                            <h5 class="asset-common">Asset Category </h5>
                        </div>
                        <div class="col">
                            <p class="asset-name"> {{this.assetEnumCategories[assetDetail.assetCategory]}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col coloums">
                            <h5 class="asset-common">Asset Name </h5>
                        </div>
                        <div class="col">
                            <p class="asset-name"> {{assetDetail.assetName | titlecase}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col coloums">
                            <h5 class="asset-common">Asset Status </h5>
                        </div>
                        <div class="col">
                            <p class="asset-name">{{assetDetail.assetStatus | titlecase}}</p>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col coloums">
                            <h5 class="asset-common">Status </h5>
                        </div>
                        <div class="col">
                            <p>{{assetDetail.assetStatus}}</p>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col coloums">
                            <h5 class="asset-common">Condition </h5>
                        </div>
                        <div class="col">
                            <p class="asset-name">{{assetDetail.assetCondition}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col coloums">
                            <h5 class="asset-common">Date of Purchase</h5>
                        </div>
                        <div class="col">
                            <p class="asset-name">{{assetDetail.purchasedOn | date: 'dd-MM-yyyy'}}</p>
                        </div>
                    </div>
                    <div class="col coloums" style="    display: flex;justify-content: end;">
                        <button *ngIf="assetDetail.assetStatus == 'AVAILABLE'" type="button "
                            (click)="openAssignAssetDialog(assetDetail)" class="btn btn-default mt-4">
                            Assign</button>
                        <button *ngIf="assetDetail.assetStatus == 'ISSUED'" type="button "
                            (click)="openRevokeAssetDialog(assetDetail)" class="btn btn-default mt-4">
                            Revoke</button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        
    </div>
    <pagination-controls id="asset_id" class="pagination" (pageChange)="p = $event"></pagination-controls>

</ng-template>