<div class="container-fluid">
    <div class="main_header_div">
        <div class="child">
            <h1 class="header_name">{{subGoal[0].goalType}}</h1>
        </div>
        <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
            <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
        </div>
    </div>
    <hr>
    <form [formGroup]="AssignSubGoal" (ngSubmit)="onSubmit()">
        <div class="container-box">
            <div class="box boxOne box-desc">
                <div class="descriptor">
                    Descriptor
                </div>
                <div class="discContent-li">
                    <div class="descContent" *ngFor="let desc of descriptor">

                        <li>{{desc}}</li>
                        
                    </div>

                </div>
            </div>
            <div class="box boxOne box-success">
                <div class="successCriteria">
                    Success Criteria and Measurement

                </div>
                <div class="successDesc-li">
                    <div class="successDesc" *ngFor="let success of successCriteria">
                        <li>{{success}}</li>
                    </div>
                </div>
            </div>
            <div class="box boxBlue">
                <div class="blueCare">
                    Blue care

                </div>
                <div class="BlueDesc">
                    {{blueCare}}

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Sub Goal Name</mat-label>
                    <input matInput formControlName="subGoalName" autocomplete="off">
                    <mat-error *ngIf="checkError('subGoalName', 'Required')">Required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Current Value 0~100 </mat-label>
                    <input matInput formControlName="currentValue" autocomplete="off">
                    <mat-error *ngIf="checkError('currentValue', 'Required')">Required</mat-error>
                    <mat-error *ngIf="checkError('currentValue','pattern')">value should be between 1~100</mat-error>

                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Description </mat-label> <textarea matInput cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="8"
                        formControlName="description"></textarea>
                    <mat-error *ngIf="checkError('description', 'Required')">Required</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="submit_button_main">
                    <button class="assign_goal_btn" type="submit">Submit </button>
                </div>
            </div>
        </div>
    </form>
</div>