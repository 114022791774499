<div class="container-fluid ">
    <div class="main_header_div">
        <div class="child">
            <form [formGroup]="feedbackClosure">
                <div class="header-div ">
                    <div class="flex">


                        <div class=" coloums ">
                            <img src="assets/default-avatar.jpg" class="img-container">
                        </div>
                        <div class=" col-9">

                            <label class="header_name">{{this.userIdYear.userdto.firstName + ' ' +
                                this.userIdYear.userdto.lastName}}</label>
                            <p class="count"> ID {{this.userIdYear.userdto.employeeId}}</p>
                        </div>

                        <div class="col-3 feedback-group-div">
                            <label class="asset-common">Score </label>
                            <p class="reportee-score">{{this.userIdYear.score | number:'1.2-2'}}/5
                            </p>

                        </div>
                        
          
                    </div>


                    <div class="child_close_btn">
                        <button class="closeButton" (click)="closeDialog()">
                            <mat-icon>close</mat-icon>
                        </button>

                    </div>
                </div>
                <hr>

                <!-- <div class=" ckeditor">
                    <ckeditor [config]="config" [editor]="Editor" formControlName="note" class="ck-text"></ckeditor>
                </div> -->
                <div>
                    <div class="title-label"> Areas of strength</div> 
                    <textarea type="text" formControlName="strengthAreas" class="notes-box mt-3 ml-5"
                        placeholder=""></textarea>
                </div>
                <div>
                    <div class="title-label"> Areas of development </div> 
                    <textarea type="text" formControlName="developmentAreas" class="notes-box mt-3 ml-5"
                        placeholder=""></textarea>
                </div>
                <div>
                    <div class="title-label"> Action item if any </div> 
                    <textarea type="text" formControlName="action" class="notes-box mt-3 ml-5"
                        placeholder=" "></textarea>
                </div>


                <div class="save-btn-div">
                    <button mat-raised-button class="draft-btn button" (click)="callSaveApi(false)">Save as
                        Draft</button>
                    <button mat-raised-button class="save-btn button" (click)="callSaveApi(true)">Preview</button>
                </div>
            </form>
        </div>
    </div>
</div>