import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { EditProfilesComponent } from '../edit-profiles.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ToastrService } from 'ngx-toastr';
import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressDetailsService } from 'src/app/services/address-details/address-details.service';
import { AddressDetailsDTO } from 'src/app/dtos/AddressDetailsDTO';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { EmergencycontactService } from 'src/app/services/emergency-contact/emergencycontact.service';
import { UserDTO } from 'src/app/dtos/UserDTO';
import {
  BloodGroup,
  Relation,
  RelationshipStatus,
} from 'src/app/services/user/user-enum';
import { Family } from 'src/app/models/familydetails';
import { FamilyDetailsService } from 'src/app/services/familyDetails/family-details.service';

@Component({
  selector: 'app-tab3-correspondence',
  templateUrl: './tab3-correspondence.component.html',
  styleUrls: ['./tab3-correspondence.component.scss'],
})
export class Tab3CorrespondenceComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  editCorrespondenceDetails: FormGroup;
  permanentAddress: FormGroup;
  emergencyContactOne: FormGroup;
  emergencyContactTwo: FormGroup;
  personalDetails: FormGroup;
  familyForm: FormGroup;
  emergencycontactone = new EmergencyContact();
  emergencycontacttwo = new EmergencyContact();
  isClicked = false;
  public rating;
  panelOpenState = false;
  addresscurr = new AddressDetailsDTO();
  address = new AddressDetailsDTO();
  userDto = new UserDTO();
  relationshipstatus = [];
  bloodgroup = [];
  relation = [];
  removedFamily = [];

  Familygroup: FormArray;
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<EditProfilesComponent>,
    public userDetailService: UserDetailsService,
    public userService: UserService,
    public addressdetailservice: AddressDetailsService,
    public emergencycontactservice: EmergencycontactService,
    public familyservice: FamilyDetailsService,
    public toaster: ToastrService,

    @Inject(MAT_DIALOG_DATA) public data: UserDTO
  ) {
    this.editCorrespondenceDetails = this.fb.group({
      addressLineOne: ['', [Validators.required]],
      addressLineTwo: [''],
      country: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      city: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      state: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      pincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{6}$'),
        ]),
      ],
    });

    this.permanentAddress = this.fb.group({
      addressLineOne: ['', [Validators.required]],
      addressLineTwo: [''],
      country: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      city: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      state: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z]*')]
      )],
      pincode: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{6}$'),
        ]),
      ],
    });
    this.personalDetails = this.fb.group({
      blood_group: ['', Validators.compose([Validators.required])],
      dob: ['', [Validators.required]],
      place_of_birth: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z ]*$'),
        ]),
      ],
      marital_status: ['', [Validators.required]],
    })
    this.emergencyContactOne = this.fb.group({
      name: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z ]*')]
      )],
      relationship: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z -]*')]
      )],
      addressLine: ['',[Validators.required]],
      phoneNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        ]),
      ],
    });

    this.emergencyContactTwo = this.fb.group({
      name: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z ]*')]
      )],
      relationship: ['', Validators.compose(
        [Validators.required,
        Validators.pattern('[a-zA-Z -]*')]
      )],
      emergencyContactType: ['', [Validators.required]],
      addressLine: ['', [Validators.required]],
      phoneNumber: ['',Validators.compose([Validators.required,
          Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        ]),
      ],
    });
    this.familyForm = this.fb.group({
      family: this.fb.array([], [Validators.required]),
    });
  }

  ngOnInit(): void {
    // this.userDto = this.userDetailService.getUserDetails();

    // this.userDetailService.getUserDetails().dateOfBirth;
    let userid = this.userDetailService.getUserDetails().id;
    this.addressdetailservice
      .getaddressbyuserId('PERMANENT', userid)
      .subscribe((res) => {
        this.address = res.body;
        this.permanentAddress.patchValue(this.address);
      });
    this.addressdetailservice
      .getaddressbyuserId('CURRENT', userid)
      .subscribe((res) => {
        this.addresscurr = res.body;
        this.editCorrespondenceDetails.patchValue(this.addresscurr);
      });

    this.emergencycontactservice
      .getemerbyuserId('Emergencycontactone', userid)
      .subscribe((res) => {
        this.emergencycontactone = res.body;
        this.emergencyContactOne.patchValue(this.emergencycontactone);
      });
    this.emergencycontactservice
      .getemerbyuserId('Emergencycontacttwo', userid)
      .subscribe((res) => {
        this.emergencycontacttwo = res.body;
        this.emergencyContactTwo.patchValue(this.emergencycontacttwo);
      });

    this.familyservice
      .getfamilydetailbyuserIdSubscribe(
        this.userDetailService.getUserDetails().id
      )
      .subscribe((res) => {
        this.setFamilyFormArray(res.body);
      });

    this.setFormData();
    this.processEnumValuesToArray();
  }

  addToSummary(content) {
    content.isSelected = true;
  }
  checkError = (controlName: string, errorName: string) => {
    return this.editCorrespondenceDetails.controls[controlName].hasError(
      errorName
    );
  };
  checkError1 = (controlName: string, errorName: string) => {
    const formgrp = this.permanentAddress as FormGroup;
    return formgrp.controls[controlName].hasError(errorName);
  };
  checkError2 = (controlName: string, errorName: string) => {
    return this.personalDetails.controls[controlName].hasError(errorName);
  };
  checkError3 = (controlName: string, errorName: string) => {
    return this.emergencyContactOne.controls[controlName].hasError(errorName);
  };
  checkError4 = (controlName: string, errorName: string) => {
    return this.emergencyContactTwo.controls[controlName].hasError(errorName);
  };

  createfamily() {
    this.Family;

    let formGroup = this.fb.group(new Family());
    formGroup.controls.firstName.setValidators([Validators.required]);
    formGroup.controls.relation.setValidators([Validators.required]);
    formGroup.controls.dateOfBirth.setValidators([Validators.required]);
    formGroup.controls.nominees.setValidators([Validators.required]);
    return formGroup;
  }

  setFamilyFormArray(response) {
    this.Familygroup = this.familyForm.get('family') as FormArray;
    let familyList: Family[] = this.familyservice.setFamilyResponse(response);
    for (let s of familyList) {
      this.Familygroup.push(this.fb.group(s));
    }
  }
  Family(): FormArray {
    return this.familyForm.get('family') as FormArray;
  }
  addFamily() {
    this.Familygroup = this.familyForm.get('family') as FormArray;
    this.Familygroup.insert(0, this.createfamily());
    // this.Familygroup.push(this.createfamily());
  }

  removefamily(id, i) {
    this.removedFamily.push(id);
    this.Family().removeAt(i);
  }
  setFormData() {
    this.userService
      .getUserPersonalInformationDetails(
        this.userDetailService.getUserDetails().id
      )
      .subscribe((res) => {
        this.userDto = res.body;

        this.personalDetails
          .get('dob')
          .setValue(
            this.userDto.dateOfBirth ? new Date(this.userDto.dateOfBirth) : ''
          );

        this.personalDetails
          .get('place_of_birth')
          .setValue(this.userDto.placeOfBirth);

        var bg = this.getEnumValueByEnumKey(
          BloodGroup,
          this.userDto.bloodGroup
        );
        this.personalDetails
          .get('blood_group')
          .setValue(this.userDto.bloodGroup ? bg : '');

        var ms = this.getEnumValueByEnumKey(
          RelationshipStatus,
          this.userDto.relationshipStatus
        );
        this.personalDetails
          .get('marital_status')
          .setValue(this.userDto.relationshipStatus ? ms : '');
      });

    // if (this.data) {
    //   this.personalDetails
    //     .get('dob')
    //     .setValue(
    //       this.userDto.dateOfBirth ? new Date(this.userDto.dateOfBirth) : ''
    //     );

    //   this.personalDetails
    //     .get('place_of_birth')
    //     .setValue(this.data.placeOfBirth);
    //   var bg = this.getEnumValueByEnumKey(BloodGroup, this.data.bloodGroup);
    //   this.personalDetails
    //     .get('blood_group')
    //     .setValue(this.data.bloodGroup ? bg : '');
    //   var ms = this.getEnumValueByEnumKey(
    //     RelationshipStatus,
    //     this.data.relationshipStatus
    //   );
    //   this.personalDetails
    //     .get('marital_status')
    //     .setValue(this.data.relationshipStatus ? ms : '');
    // }
  }
  processEnumValuesToArray() {
    for (let relation of Object.values(RelationshipStatus)) {
      if (isNaN(Number(relation))) this.relationshipstatus.push(relation);
    }
    for (let bloodGroup of Object.values(BloodGroup)) {
      if (isNaN(Number(bloodGroup))) this.bloodgroup.push(bloodGroup);
    }
    for (let relation of Object.values(Relation)) {
      if (isNaN(Number(relation))) this.relation.push(relation);
    }
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  getauthoritiesKeyByEnumValueArray(myEnum, enumValue) {
    return Object.keys(myEnum).filter((k) => enumValue.includes(myEnum[k]));
  }
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }
  getauthorityValueByEnumKeyArray(myEnum, enumKeyArr) {
    return enumKeyArr ? enumKeyArr.map((k) => myEnum[k]) : '';
  }
  onSubmit1() {
    if (this.familyForm.valid) {
      for (let familyy of this.familyForm.value.family) {
        if (
          familyy &&
          !familyy.id &&
          familyy.firstName != '' &&
          familyy.relation != '' &&
          familyy.location != ''
        ) {
          familyy.userId = this.userDetailService.getUserDetails().id;
          this.familyservice.create(familyy).subscribe((res) => {
            this.toaster.success('Successfully Saved' ,'')
          });
        } else if (
          familyy &&
          familyy.id &&
          familyy.firstName != '' &&
          familyy.relation != '' &&
          familyy.location != ''
        ) {
          familyy.userId = this.userDetailService.getUserDetails().id;
          this.familyservice.update(familyy).subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')
          });
        }
      }
    }

    for (let i in this.removedFamily) {
      this.familyservice.delete(this.removedFamily[i]);
    }
  }
  onSubmit() {
    if (this.personalDetails.valid) {
      this.userDto.bloodGroup = this.getEnumKeyByEnumValue(
        BloodGroup,
        this.personalDetails.get('blood_group').value
      );

      this.userDto.relationshipStatus = this.getEnumKeyByEnumValue(
        RelationshipStatus,
        this.personalDetails.get('marital_status').value
      );
      this.userDto.placeOfBirth =
        this.personalDetails.get('place_of_birth').value;
      this.userDto.dateOfBirth = this.personalDetails.get('dob').value;

      this.userService
        .setUserPersonalInformationDetails(
          this.userDetailService.getUserDetails().id,
          this.userDto
        )
        .subscribe((res) => {
          this.toaster.success('Successfully Saved');
          // this.dialogRef.close({ event: 'Closed'})
        });
    }
  }
  // if (this.userDto) {
  //   if (this.userDto.id) this.userDto.id = this.userDto.id;
  //   this.userService.updateUser(this.userDto).subscribe((res) => {
  //     this.userService.getUser('Asc');
  //     //this.notificationService.sucess("User Data Edited")
  //   });
  // } else {
  //   this.userService.addUser(this.userDto).subscribe((res) => {
  //     this.userService.getUser('Asc');
  //     //this.notificationService.sucess("New User Created")
  //   });
  // }
  onSubmit2() {
    let currentAddressDto = new AddressDetailsDTO();
    currentAddressDto.addressLineOne =
      this.editCorrespondenceDetails.get('addressLineOne').value;
    currentAddressDto.addressLineTwo =
      this.editCorrespondenceDetails.get('addressLineTwo').value;
    currentAddressDto.city = this.editCorrespondenceDetails.get('city').value;
    currentAddressDto.pincode =
      this.editCorrespondenceDetails.get('pincode').value;
    currentAddressDto.state = this.editCorrespondenceDetails.get('state').value;
    currentAddressDto.country =
      this.editCorrespondenceDetails.get('country').value;
    currentAddressDto.addressCategory = 'CURRENT';
    currentAddressDto.userId = this.userDetailService.getUserDetails().id;
    if (this.addresscurr.id != null) {
      if (this.addresscurr.addressCategory == 'CURRENT') {
        currentAddressDto.id = this.addresscurr.id;
        this.addressdetailservice
          .putdocument(currentAddressDto)
          .subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')

          });
      }
    } else {
      this.addressdetailservice
        .postdocument(currentAddressDto)
        .subscribe((res) => {
          this.toaster.success('Successfully Saved' ,'')
        });
    }
  }

  onSubmit3() {
    let permanentAddressDto = new AddressDetailsDTO();

    permanentAddressDto.addressLineOne =
      this.permanentAddress.get('addressLineOne').value;
    permanentAddressDto.addressLineTwo =
      this.permanentAddress.get('addressLineTwo').value;
    permanentAddressDto.city = this.permanentAddress.get('city').value;
    permanentAddressDto.pincode = this.permanentAddress.get('pincode').value;
    permanentAddressDto.state = this.permanentAddress.get('state').value;
    permanentAddressDto.country = this.permanentAddress.get('country').value;
    permanentAddressDto.addressCategory = 'PERMANENT';
    permanentAddressDto.userId = this.userDetailService.getUserDetails().id;
    if (this.address.id != null) {
      if (this.address.addressCategory == 'PERMANENT') {
        permanentAddressDto.id = this.address.id;
        this.addressdetailservice
          .putdocument(permanentAddressDto)
          .subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')


          });
      }
    } else {
      this.addressdetailservice
        .postdocument(permanentAddressDto)
        .subscribe((res) => {
          this.toaster.success('Successfully Saved' ,'')
        });
    }
  }

  onSubmit4() {
    let emergencyContactone = new EmergencyContact();

    emergencyContactone.emergencyContactType = 'Emergencycontactone';
    emergencyContactone.location =
      this.emergencyContactOne.get('addressLine').value;
    emergencyContactone.name = this.emergencyContactOne.get('name').value;
    emergencyContactone.phoneNumber =
      this.emergencyContactOne.get('phoneNumber').value;
    emergencyContactone.relationship =
      this.emergencyContactOne.get('relationship').value;
    emergencyContactone.userId = this.userDetailService.getUserDetails().id;

    if (this.emergencycontactone.id != null) {
      if (
        this.emergencycontactone.emergencyContactType == 'Emergencycontactone'
      ) {
        emergencyContactone.id = this.emergencycontactone.id;
        this.emergencycontactservice
          .putdocument(emergencyContactone)
          .subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')
              });
      }
    } else {
      this.emergencycontactservice
        .postdocument(emergencyContactone)
        .subscribe((res) => {
          this.toaster.success('Successfully Saved' ,'')

        });
    }
  }

  onSubmit5() {
    let emergencyContacttwo = new EmergencyContact();
    emergencyContacttwo.emergencyContactType = 'Emergencycontacttwo';
    emergencyContacttwo.location =
      this.emergencyContactTwo.get('addressLine').value;
    emergencyContacttwo.name = this.emergencyContactTwo.get('name').value;
    emergencyContacttwo.phoneNumber =
      this.emergencyContactTwo.get('phoneNumber').value;
    emergencyContacttwo.relationship =
      this.emergencyContactTwo.get('relationship').value;
    emergencyContacttwo.userId = this.userDetailService.getUserDetails().id;
    if (this.emergencycontacttwo.id != null) {
      if (
        this.emergencycontacttwo.emergencyContactType == 'Emergencycontacttwo'
      ) {
        emergencyContacttwo.id = this.emergencycontacttwo.id;
        this.emergencycontactservice
          .putdocument(emergencyContacttwo)
          .subscribe((res) => {
            this.toaster.success('Successfully Updated', 'Saved')
          });
      }
    } else {
      this.emergencycontactservice
        .postdocument(emergencyContacttwo)
        .subscribe((res) => {
          this.toaster.success('Successfully Saved' ,'')
                });
    }

}
}
