import { Component, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { UitoggleService } from 'src/app/services/uitoggle.service';
import { AddEmployeeCardComponent } from './add-employee-card/add-employee-card.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  isExpanded: Boolean
  empDetail;
  primary = "primary"
  searchText: string;
  display: string
  matTabsClickedIndex = 0
  hasRoleBased: any;
  constructor(
    public userDetailService: UserDetailsService,
    public employeeServie: UserService,
    public initialAvatarService: InitialAvatarService,
    private dialog: MatDialog,
    private interaction: InteractionService,
    private uitoggle: UitoggleService) { }
  ngOnInit(): void {
    this.hasRoleBased = this.userDetailService.hasRole(['ROLE_USER'])
    this.interaction.cast.subscribe(data => this.isExpanded = data);
    this.uitoggle.cast.subscribe(data => this.display = data);
  }
  changevalue() {
    this.isExpanded = !this.isExpanded;
    this.interaction.changestate(this.isExpanded);
  }
  changeDisplay(value: string) {
    this.display = value;
    this.uitoggle.changestate(this.display);
  }
  onSearchTextTyped(event) {
    if (event == "") {
      event = ""
      this.searchText = ""
      this.employeeServie.usersDto = [];
      this.empDetail = this.employeeServie.getUser("Asc");
    } else {
      this.employeeServie.usersDto = [];
      this.employeeServie.searchUser(event);
      this.empDetail = this.employeeServie.usersDto;
    }
  }
  addEmployeeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "100%";
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  onTabChanged($event) {
    this.matTabsClickedIndex = $event.index;
  }

  
  createpdf() {
    var data = document.getElementById('content');
    ;
  }
}
