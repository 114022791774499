import { Injectable } from '@angular/core';
import { Skill } from 'src/app/models/skill';
import { RestAPIService } from '../restAPI/rest-api.service';
import { SkillUrlService } from '../restAPI/skillURLs/skill-url.service';
@Injectable({
  providedIn: 'root'
})
export class SkillService {
  constructor(private restApiService: RestAPIService,
    private skillurl : SkillUrlService
  ) { }
  create(payload) {
    return this.restApiService.post(
      this.skillurl.getBaseUrl(),
      payload
    )
  }
  update(payload){
    return this.restApiService.put(
      this.skillurl.getBaseUrl(),
      payload
    )
  }
  getskillByUserIdSubscribe(userId) {
    return this.restApiService.get(
      this.skillurl.getUserUrl(userId)
    )
  }
  setSkillResponse(response):Skill[]{
    let skillList:Skill[] = []
    for(let data of response){
        let skill:Skill = new Skill()
        skillList.push(
          skill.bind(data)          
        )
    }
    return skillList
  }
  delete(id: any) {
    return this.restApiService.delete(
      this.skillurl.getByFieldUrl(id))
    // .subscribe(res=>{
    // })
  }
}
