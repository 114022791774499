import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrderPipe } from 'ngx-order-pipe';
import { User } from 'src/app/models/user';
import { AngularSpinnerService } from 'src/app/services/angular-spinner.service';
import { CareerManagerTeamService } from 'src/app/services/careerManager.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UitoggleService } from 'src/app/services/uitoggle.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddEmployeeCardComponent } from '../add-employee-card/add-employee-card.component';
import { DeleteEmployeeComponent } from '../delete-employee/delete-employee.component';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
import { BulkImportSummaryComponent } from '../../bulk-employee-import/bulk-import-summary/bulk-import-summary.component';
import { Departments, JobTitles } from 'src/app/services/user/user-enum';
import { DeactivateEmployeeComponent } from '../employee-activation/deactivate-employee/deactivate-employee.component';
import { ActivateEmployeeComponent } from '../employee-activation/activate-employee/activate-employee/activate-employee.component';
import { WorkBook, utils, write } from 'xlsx';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExportEmployeeDetailsComponent } from '../export-employee-details/export-employee-details.component';

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.scss'],
  providers: [PipeUnderScoreToSpace]
})
export class EmployeeCardComponent implements OnInit {
  @Input() empDetail;
  @Input() initialAvatar;
  @Input() public index;
  @Input() display;
  userDto: User[] = [];
  isVisible = false
  p: number = 1;
  userdata: User[] = [];
  userdto: User[] = [];
  usermobiledata:User[]=[];
  reversee: boolean = false;
  order: string;
  throttle=200
  distance = 2;
  page = 1;
  searchText: string;
  hasRoleBased: any;
  connectingBtn: Boolean
  mobileview:boolean=false
  public finalExcelFile: Blob;
  
  setngclass
  public getScreenWidth: any;
  public getScreenHeight: any;
  careerManagerIdName = {};
  careerManagerName;
  ascendingTrue: Boolean = true;
  reverseFlag = 1;
  jobTitles: typeof JobTitles;
  departments: typeof Departments;
  constructor(public userService: UserService,
    public userDetailService: UserDetailsService,
    private spinnerService: SpinnerService,
    private angularSpinnerService: AngularSpinnerService,
    private dialog: MatDialog,
    private orderPipe: OrderPipe,
    public initialAvatarService: InitialAvatarService,
    public careerservice: CareerManagerTeamService,
    private uitoggle: UitoggleService,
    private router: Router
    ) { 

    }

  ngOnInit(): void {
    this.jobTitles=JobTitles
    this.departments=Departments
    this.userService.displayEmployeeCards = false;
    this.mobileviewfunc()
    this.hasRoleBased = this.userDetailService.hasRole(['ROLE_ADMIN'])
    this.uitoggle.cast.subscribe(data => this.display = data);
    
  }
  
  // redirectToBulkEmployeeComponent(){
  //   this.router.navigate(['/bulk-employee-import']);
  // }


  redirecttoBulkEmployeeComponent()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.height = "70%";
    dialogConfig.panelClass = "bulk-employee"
    const dialogRef = this.dialog.open(BulkImportSummaryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  
  mobileviewfunc(){
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.getScreenWidth<600){
      this.mobileview=true;
      this.onScroll();
    }
    else if(this.getScreenWidth>600){
      this.function()
    }
  }
  function() {
    this.userService.getUser("Asc");

    // if(this.userService.usersDto.length!=0){
    //   this.isVisible=true
    // }

    // for (let user of this.userService.usersDto) {
    //   if (user.careerManagerId !== null)
    //   {
    //     var careerManagerRecord = this.userService.usersDto.find((obj) =>{
    //       return obj.id == user.careerManagerId
    //     })

    //     this.careerManagerIdName[user.careerManagerId] = careerManagerRecord.firstName;

    //     // this.userService.getUserByID(user.careerManagerId).subscribe(res => {
    //     //   this.careerManagerIdName[user.careerManagerId] = res.body.firstName;
    //     // })
    //   }
    // } 
  }

  reverse = false;
  sortList() {
    this.ascendingTrue = !this.ascendingTrue;
    if (this.ascendingTrue == true) {
      this.userService.getUser("Asc")
    }
    else {
      this.userService.getUser("Desc")
    }
  }

  reverseOrder(){
    this.reverseFlag = this.reverseFlag * -1;
    this.userService.usersDto = this.userService.usersDto.sort((a,b)=> {
      if (a.firstName < b.firstName){
        return -this.reverseFlag;
      }
      if (a.firstName > b.firstName){
        return this.reverseFlag;
      }
      return 0;
    });
  }

  
  viewEmployee(empDetail){
    this.router.navigate([`/employee/employee-profile/${empDetail.id}`]);
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  
    if(this.getScreenWidth>600){
      this.mobileview=false
      this.function();
    }
  
  }

  editEmployeeDialog(empData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "98%";
    dialogConfig.data = empData;
   
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  addEmployeeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "98%";
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      
      this.userService.getUser("Asc");
    });
  }

  onSearchTextTyped(event) {
    if (event == "") {
      event = ""
      this.searchText = ""
      this.userService.usersDto = [];
      this.userService.getUser("Asc");
    } else {
      this.userService.usersDto = [];
      this.userService.searchUser(event);
      for (let i of this.userService.usersDto) {
        this.userDto.push(i)
      }
    }
  }

  onScroll() {
    this.userService.getAllUserPagination(++this.page,3,"Asc").subscribe((response) => {
      if (response.body.length != 0) {
        for (let user of response.body) {
         this.usermobiledata.push(user)
         this.isVisible=true
        }
        
        for (let user of response.body) {
          //this.userService.getUserByID(user.careerManagerId).subscribe(res => {
            this.userService.getUserNameByID(user.careerManagerId).subscribe(res => {
            this.careerManagerIdName[user.careerManagerId] = res;
            //this.careerManagerIdName[user.careerManagerId] = res.body.firstName;
          })
        }
      }
    }) 
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reversee = !this.reversee;
    }
    this.order = value;
  }

  OnPageClick(event) {
    this.userService.pageCount = event.pageIndex
    this.userService.pageCount += 1
    this.userService.getUser("Asc");
  }

  // deleteEmployee(empData) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.width = "600px";
  //   dialogConfig.height = "300px";
  //   dialogConfig.data = empData;
  //   dialogConfig.panelClass = "delete-employee"
  //   const dialogRef = this.dialog.open(DeleteEmployeeComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.userService.getUser("Asc");
      
  //     if(this.userService.usersDto.length==0){
  //       this.isVisible=false
  //     }
  //   });
  // }

  deactivateEmployee(empData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "600px";
    dialogConfig.height = "300px";
    dialogConfig.data = empData;
    dialogConfig.panelClass = "deactivate-employee"
    const dialogRef = this.dialog.open(DeactivateEmployeeComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // this.userService.getUser("Asc");
      this.ngOnInit();
    });
  }

  activateEmployee(empData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "600px";
    dialogConfig.height = "300px";
    dialogConfig.data = empData;
    dialogConfig.panelClass = "activate-employee"
    const dialogRef = this.dialog.open(ActivateEmployeeComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // this.userService.getUser("Asc");
      this.ngOnInit();
    });
  }

  file() {
    const unmarkedEmployeesExcelList: any[] = [];
    const observables: Observable<any>[] = [];
  
    let serialNumber = 1; // Initialize the serial number
  
    for (const user of this.userService.usersDto) {
      const fullName = user.firstName + ' ' + user.lastName;
      const employeeId = user.employeeId;
      const jobTitle = user.jobTitle;
      const department = user.department;
  
      if (user.careerManagerId !== null) {
        const observable = this.userService.getUserNameByID(user.careerManagerId).pipe(
          map(res => {
            this.careerManagerIdName[user.careerManagerId] = res;
            const careerManagerName = this.careerManagerIdName[user.careerManagerId];
            return careerManagerName;
          })
        );
        observables.push(observable);
  
        observable.subscribe(careerManagerName => {
          const rowData = {
            'Sr. No.': serialNumber++,
            'Full Name': fullName,
            'Employee ID': employeeId,
            'Title': jobTitle,
            'Capability': department,
            'Career Manager': careerManagerName
          };
  
          unmarkedEmployeesExcelList.push(rowData);
        });
      }
    }
  
    forkJoin(observables).subscribe(() => {
      console.log(unmarkedEmployeesExcelList);
      const holidaysDataForExcel: any[] = [];
      // console.log(this.userService.usersDto)
      this.finalExcelFile = this.generateExcelFile(unmarkedEmployeesExcelList);
      console.table(this.finalExcelFile);
      this.saveAsExcelFile(this.finalExcelFile, 'all-employee-list.xlsx');
    });
  }

  generateExcelFile(formArrayData: any[]): Blob {
    const worksheet = utils.json_to_sheet(formArrayData);
    const workbook: WorkBook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Template');
    const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/octet-stream' });
  }

  saveAsExcelFile(buffer: any, filename: string) { // to download excel
    const data = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  openExportDetailsDilogue(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.height = "80%";
    dialogConfig.panelClass = "activate-employee"
    const dialogRef = this.dialog.open(ExportEmployeeDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => { });
  }

}
