import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { select } from '@ngrx/store';
import { pairwise, startWith } from 'rxjs/operators';
import { GoalStatus, GoalTypeLabel } from 'src/app/services/goalSheet/goal-sheet-enum';
import { SubgoalService } from 'src/app/services/subgoal/subgoal.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { AssignGoalComponent } from '../assign-goal/assign-goal.component';
import { ManageSubgoalsComponent } from './manage-subgoals/manage-subgoals.component';
import { ViewSubgoalComponent } from './view-subgoal/view-subgoal.component';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { error } from 'console';

@Component({
  selector: 'app-cm-goal-sheet',
  templateUrl: './cm-goal-sheet.component.html',
  styleUrls: ['./cm-goal-sheet.component.scss']
})
export class CmGoalSheetComponent implements OnInit {
  map = new Map<String, boolean>();
  //count of tempGoalStatus["ontrack","notstarted","atrisk","needattention","completed"]
  goalCount;
  assignCheck;
  tempGoalStatus;
  d = new Date();
  y = this.d.getFullYear();
  public selected: any = this.d.getFullYear();
  currentYear=new Date().getFullYear().toString()

  goalTypeMapping: any;
  subgoalcount: number;
  subgoalLength = {}
  goal = [];
  loginuserid;
  useridcheck = false;
  yearcheck;
  year;
  view = [160, 180]
  colorScheme = {
    domain: [
      '#F93154',
      '#FFA900',
      '#8102D1',
      '#28A745',
      '#0045B9',
    ]
  };

  userIdName = {};
  @Input() userid: String;
  selectedDropDownValue: string;
  control = new FormControl()
  selectedYear: number;
  years: number[] = [];
  constructor(private usergoalService: UserGoalService,
    private userDetailService: UserDetailsService,
    private userSubGoal: SubgoalService,
    private userService: UserService, public dialog: MatDialog,
    private feedbackGroupUrlService: NewFeedbackGroupService) { }
  ngOnInit(): void {
   
    this.usergoalService.getCurrentYear().subscribe(res => {
      this.selected = res
    });

    this.usergoalService.year.subscribe(year => {
      this.year = year;
      let feedbackStatus;
      this.feedbackGroupUrlService.getFeedbackOverallstatus(this.userid, year).subscribe(res => {
        feedbackStatus = res.body;
        this.assignCheck = (year == this.currentYear) || !feedbackStatus;
      }, error => {
        if (error.status == 400 && year == this.currentYear) {
          this.assignCheck = true;
        }
      });
    });
    
    //this.selected=2022
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2015; year--) {
      this.years.push(year);
    }

    this.userSubGoal.filter("Manage and delete");
    this.loginuserid = this.userDetailService.getUserDetails().id;
    if (this.loginuserid == this.userid) {
      this.useridcheck = false;
      this.yearcheck = false;
    }
    else {
      this.useridcheck = true;
      this.yearcheck = true;
    }

    this.goalTypeMapping = GoalTypeLabel;
    this.getgoalStatus()
    this.getGoal()
  }

  getgoalStatus() {

    this.tempGoalStatus = [0, 0, 0, 0, 0];
    this.subgoalcount = 0;
    let g = [];
    this.usergoalService.year.subscribe(res => {
      this.year = res;
      if (this.year != this.currentYear) {

        this.useridcheck = false;
      }
      else {
        if (this.loginuserid == this.userid) {
          this.useridcheck = false;
        }
        else {

          this.useridcheck = true;
        }
      }
    })

    this.usergoalService.getGoalByUserAndYear(this.userid, this.year).subscribe(res => {
      this.goalCount = res.body.length
      for (let i of res.body) {
        g.push(i)
        this.userService.getUserByID(i.userId).subscribe(response => {
          this.userIdName[i.userId] = response.body.firstName + " " + response.body.lastName;
        })
        this.userSubGoal.getSubgoalByUserGoal(i.id).subscribe(res => {
          for (let i of res.body) {
            this.returnGoalStatus(i.currentValue);
          }
          this.subgoalLength[i.id] = res.body.length
          this.subgoalcount += res.body.length;
        });
      }
      this.goal = g;
    })

  }

  returnGoalStatus(percentageValue: number) {

    let date = new Date();
    let month = date.getMonth() + 1;

    if (percentageValue == 0) {
      this.tempGoalStatus[1]++;
    }
    else if (percentageValue == 100) {
      this.tempGoalStatus[4]++;
    }
    else if (percentageValue >= ((100 / 12) * month)) {
      this.tempGoalStatus[0]++;
    }
    else if (percentageValue >= (((100 / 12) * month) - 8)) {
      this.tempGoalStatus[3]++;
    }
    else {
      this.tempGoalStatus[2]++;
    }
  }
  getGoal() {

    this.map.clear();

    this.usergoalService.getGoalByUserAndYear(this.userid, this.year).subscribe(res => {
      for (let temp of res.body) {
        this.userSubGoal.getSubgoalByUserGoal(temp.id).subscribe(r => {
          if (r.body.length == 0) {
            this.map.set(temp, false);
          }
          else {
            this.map.set(temp, true);
          }
        });
      }

    });
  }
  viewSubgoals(usergoalid) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = "manage-class";
    dialogConfig.data = usergoalid;
    const dialogRef = this.dialog.open(ViewSubgoalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getgoalStatus();
      this.getGoal();
    });
  }
  managesubgoals(usergoalid) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = "manage-class";
    dialogConfig.data = usergoalid;
    const dialogRef = this.dialog.open(ManageSubgoalsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getgoalStatus();
      this.getGoal();
    });
  }

  openAssignGoalDialogue() {
    // this.usergoalService.listen().subscribe((m:any)=>
    // {
    //   console.log(m);
    //  this.getGoal();
    // });
    //  this.getGoal();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "85%";
    let arr = [this.map, this.userid];
    dialogConfig.data = arr;
    const dialogRef = this.dialog.open(AssignGoalComponent, dialogConfig).afterClosed()
      .subscribe(result => {
        if (result.event == 'Closed') {
          this.map.clear()
          this.ngOnInit();
          // this.getGoal()
        }
      });
  }

  loadForm(event) {
    this.usergoalService.setCurrentYear(event.value);
    this.usergoalService.changeYear(event.value);
    //this.year=event.value;
    //this.selectedDropDownValue = this.selected;
    this.getgoalStatus();
  }
}
