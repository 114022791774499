import { TypeOfLeave } from "../services/leaveBalances/typeOfLeave-enum";

export class LeaveBalanceDTO {
    public id: string;
    public userId: string;
    public year: string;
    // public balanceLeave: Map<TypeOfLeave, number>;
    public balanceLeave: any;
    public isAdvanceLeaveAllowed: boolean;


    constructor() {
        this.id = "";
        this.userId = "";
        this.year = "";
        this.balanceLeave = null;
    }
}
