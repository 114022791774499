import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/project.service';
import { TalentService } from 'src/app/services/talent/talent.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.scss']
})
export class MyTeamComponent implements OnInit {
  empDetail=[];
  team = [];
  public flag:any=[];
  projectNameIdMapping = {};
  projectList: any = [];
  userid;
  talentImages = {};
  public talentData = [];
  constructor(private breakpointObserver: BreakpointObserver,
    public userService: UserService, private userDetailService: UserDetailsService, private talentService: TalentService, private projectservice: ProjectService) {}

  ngOnInit():void{
    this.userid = this.userDetailService.getUserDetails().id;
    this.userService.getUserSubscribe().subscribe(res => {
      this.userService.setUsersDto(res.body);
      this.empDetail = this.userService.usersDto;
    });
 
    if(this.userService.allUserData.length==0){
      this.userService.getAllUser()
    }

    this.getTeam();
    
  
    //this.getTalentImages();
  }
 
    getTeam() {
      
      this.talentService.getTalentByUserIdSubscribe(this.userid).subscribe(res => {
        for (let r of res.body) {
          this.projectservice.getProjectById(r.projectId).subscribe(resp => {
            this.projectList.push(resp.body)
            this.projectNameIdMapping[resp.body.id] = resp.body.projectName;
            this.talentService.getTalentByProjectIdSubscribe(resp.body.id).subscribe(response => {
              this.team.push(response.body)
            })
          })
        }
      })
     
        }
        
 
  
}
