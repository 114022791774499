import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/models/client';
import { ClientDTO } from 'src/app/dtos/ClientDTO';
import { ClientService } from 'src/app/services/client/client.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-edit-client',
  templateUrl: './add-edit-client.component.html',
  styleUrls: ['./add-edit-client.component.scss'],
})
export class AddEditClientComponent implements OnInit {
  newClientDetails: FormGroup;
  clientDTO = new ClientDTO();
  photo: any;
  binaryImgString: string;
  imageError: string;
  base64textString: any;
  isSaveOrSubmit=false;
 
  
  client_id = this.route.snapshot.params.id;
  noOnlySpecialCharactersValidator():ValidatorFn {
    // Regular expression to match only special characters
   
    const onlySpecialCharsRegex =/^[!#$%^&*(),.<>?":@?\{}|<_ /]+$/;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;

    if (onlySpecialCharsRegex.test(value)) {
      return { noOnlySpecialCharacters: true };
    }

    return null;
  };
  }
  constructor(
    public notificationService: NotificationService,
    public clientService: ClientService,
    public fb: FormBuilder,
    
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AddEditClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data:Client
  ) {
    this.newClientDetails = this.fb.group({
      clientId: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]],
      clientName: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]],
      clientLocation: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]],
      clientStartDate: ['', [Validators.required]],
      clientDesc: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]],
      imageBase: [''],
    });
  }

  ngOnInit(): void {
    this.setFormData();
   
  }

  checkError = (controlName: string, errorName: string) => {
    return this.newClientDetails.controls[controlName].hasError(errorName);
  };

  readURL(event: Event): void {
    
    this.photo = false;
    this.imageError = '';
    if (event && event[0]) {
      const file = event[0];
      const reader = new FileReader();
      const max_size = 20971520;
      const max_height = 15200;
      const max_width = 25600;

      if (event[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
      }
      reader.onload = (e) => {
        this.photo = true;
        this.binaryImgString = e.target.result as string;
        this.base64textString = btoa(this.binaryImgString);
        this.newClientDetails.patchValue({
          imageBase: this.binaryImgString.split(',')[1],
        });
      };
      reader.readAsDataURL(file);
    }
  }

  setFormData() {
    
   
    if (this.data) {
      
      this.isSaveOrSubmit=true;
      this.newClientDetails
        .get('clientId')
        .setValue(this.data.clientId ? this.data.clientId : '');

      this.newClientDetails
        .get('clientName')
        .setValue(this.data.clientName ? this.data.clientName : '');

      this.newClientDetails
        .get('clientLocation')
        .setValue(this.data.clientLocation ? this.data.clientLocation : '');

      this.newClientDetails
        .get('clientStartDate')
        .setValue(this.data.createdDate ? this.data.createdDate : '');

      this.newClientDetails
        .get('clientDesc')
        .setValue(
          this.data.clientDescription ? this.data.clientDescription : ''
        );

        if (this.data.clientImageThumbnail !== ''){
         
          
           this.binaryImgString = "data:image/png;base64," + this.data.clientImageThumbnail;
           this.photo = true;
        }
        else{
          this.photo = false;
          
        }

  
    }
  }

  setDefaultImg() {
    
    this.photo = false;
  
    this.binaryImgString = ''
    this.newClientDetails.patchValue({
      imageBase: null
      
    });
  }

  onSubmit() {
        
    if (this.newClientDetails.valid) {
      
      //this.clientDTO = this.data
      this.clientDTO.clientId = this.newClientDetails.get('clientId').value;
      this.clientDTO.clientName = this.newClientDetails.get('clientName').value;
      this.clientDTO.clientLocation =
        this.newClientDetails.get('clientLocation').value;
      this.clientDTO.createdDate =
        this.newClientDetails.get('clientStartDate').value;
      this.clientDTO.clientDescription =
        this.newClientDetails.get('clientDesc').value;
     

      if(this.photo){
        this.newClientDetails.patchValue({
          imageBase: this.binaryImgString.split(",")[1],
        });
        if (this.data && (this.newClientDetails.get('imageBase').value === this.data.clientImageThumbnail)){
          this.clientDTO.imageBase = 'Unchanged';
        }
        else{
          this.clientDTO.clientImageThumbnail = this.newClientDetails.get('imageBase').value
        }
      }
      else{
        this.clientDTO.clientImageThumbnail = "";
      }

      if (this.data) {
         
       if (this.data.clientId) this.clientDTO.clientId = this.data.clientId;
        this.clientDTO.id = this.data.id;
        this.clientService.updateClient(this.clientDTO).subscribe((res) => {
          this.clientService.getClients();
          this.dialogRef.close();
          this.notificationService.sucess('Client Data Edited');
        });
      } else {
         
        this.clientService.addClient(this.clientDTO).subscribe((res) => {
          this.notificationService.sucess('New Client Added');
          this.clientService.filter("Refresh");
          this.dialogRef.close();
        });
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
