import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { Rating } from 'primeng/rating';
import { AddressDetailsDTO } from 'src/app/dtos/AddressDetailsDTO';
import { FamilyDetailsDTO } from 'src/app/dtos/FamilyDetailsDTO';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { AddressDetails } from 'src/app/models/addressdetails';
import { EmergencyContact } from 'src/app/models/emergencycontact';
import { Family } from 'src/app/models/familydetails';
import { AddressDetailsService } from 'src/app/services/address-details/address-details.service';
import { EmergencycontactService } from 'src/app/services/emergency-contact/emergencycontact.service';
import { FamilyDetailsService } from 'src/app/services/familyDetails/familydetails.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { BloodGroup } from 'src/app/services/user/user-enum';
import { UserService } from 'src/app/services/user/user.service';
import { threadId } from 'worker_threads';
import { CurrentAddressComponent } from './current-address/current-address.component';
import { EmergencyContact1Component } from './emergency-contact1/emergency-contact1.component';
import { EmergencyContact2Component } from './emergency-contact2/emergency-contact2.component';
import { NomineeDetailsComponent } from './nominee-details/nominee-details.component';
import { PermanentAddressComponent } from './permanent-address/permanent-address.component';
import { PersonalInformationComponent } from './personal-information/personal-information.component';

@Component({
  selector: 'app-correspondence',
  templateUrl: './correspondence.component.html',
  styleUrls: ['./correspondence.component.scss'],
})
export class CorrespondenceComponent implements OnInit {
  userDto: any;
  birth: any;
  bloodGroup: any = [];
  relationstatus: any;
  placebirth: any;
  mobileNumber: any;
  addressdetailsdto: AddressDetails[] = [];
  familydetailsdto: Family[] = [];
  address: AddressDetailsDTO[] = [];
  addresscurr: AddressDetailsDTO[] = [];
  emergencycontactone: EmergencyContact[] = [];
  emergencycontacttwo: EmergencyContact[] = [];
  bloodgroups: any;
  ngbrating: any;

  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  emergencyContactOne: FormGroup;
  emergencyContactTwo: FormGroup;
  emergencycontact1_loading = false
  emergencycontact2_loading = false
  currentaddress_loading = false
  permanentaddress_loading = false
  family_details_loading = false
  personalinformation_loading = false;
  personalInformationPresentCheck: boolean = true;
  userid: any;
  flag=[false,false,false,false,false,false];
  allowEditToOthers: boolean = false;
  @Output() dummyfunc = new EventEmitter();
  @Input() data: UserDTO;
  constructor(
    public dialog: MatDialog,
    public userDetailService: UserDetailsService,
    public userService: UserService,
    public addressdetailservice: AddressDetailsService,
    public emergencycontactservice: EmergencycontactService,
    public familydetailservice: FamilyDetailsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.bloodGroup = BloodGroup
    this.userid = this.route.snapshot.paramMap.get('id');

    if (this.userid == null) {
      this.userid = this.userDetailService.getUserDetails().id;
      this.allowEditToOthers = (this.userid == this.userDetailService.getUserDetails().id);
    }
    this.allowEditToOthers = (this.userid == this.userDetailService.getUserDetails().id);

    // this.userService.getUserPersonalInformationDetails(this.userid).subscribe((res) => {

    //   this.userDto = res.body;

    //   if (
    //     (this.userDto.bloodGroup == null || this.userDto.bloodGroup == undefined) &&
    //     (this.userDto.dateOfBirth == null || this.userDto.dateOfBirth == undefined) &&
    //     (this.userDto.placeOfBirth == '' || this.userDto.placeOfBirth == undefined) &&
    //     (this.userDto.relationshipStatus == null || this.userDto.relationshipStatus == undefined)
    //   ) {
    //     this.personalInformationPresentCheck = false;
    //   }

    // })
    this.getPersonalInformation();

    // this.userDto = this.userDetailService.getUserDetails();
    // this.birth = this.userDetailService.getUserDetails().dateOfBirth;
    // this.relationstatus =
    //   this.userDetailService.getUserDetails().relationshipStatus;
    // this.placebirth = this.userDetailService.getUserDetails().placeOfBirth;
    // this.mobileNumber = this.userDetailService.getUserDetails().mobileNumber;
    // this.userDetailService.getUserDetails().dateOfBirth;
    // let userid = this.userDetailService.getUserDetails().id;

    // this.addressdetailservice
    //   .getaddressbyuserId('PERMANENT', this.userid)
    //   .subscribe((res) => {
    //       this.address = res.body;
    //   });
    this.getPermanentAddress();

    // this.addressdetailservice
    //   .getaddressbyuserId('CURRENT', this.userid)
    //   .subscribe((res) => {
    //     this.addresscurr = res.body;
    //   });
    this.getCurrentAddress();

    // this.familydetailservice
    //   .getfamilyByUserIdSubscribe(this.userid)
    //   .subscribe((res) => {

    //     this.familydetailsdto = this.familydetailservice.setfamilyResponse(
    //       res.body
    //     );
    //   });
    this.getFamilyNomineeDetails();

    // this.emergencycontactservice
    //   .getemerbyuserId('Emergencycontactone', this.userid)
    //   .subscribe((res) => {

    //     this.emergencycontactone = res.body;
    //   });
    this.getEmergencyContactOneDetails();

    // this.emergencycontactservice
    //   .getemerbyuserId('Emergencycontacttwo', this.userid)
    //   .subscribe((res) => {

    //     this.emergencycontacttwo = res.body;
    //   });
    this.getEmergencyContactTwoDetails();

    //   this.emergencycontactservice
    //   .getemerbyuserId('Emergencycontactone', userid)
    //   .subscribe((res) => {
    //     this.emergencycontactone = res.body;
    //     this.emergencyContactOne.patchValue(this.emergencycontactone);
    //   });
    // this.emergencycontactservice
    //   .getemerbyuserId('Emergencycontacttwo', userid)
    //   .subscribe((res) => {
    //     this.emergencycontacttwo = res.body;
    //     this.emergencyContactTwo.patchValue(this.emergencycontacttwo);
    //   });

  }

  getCurrentAddress() {
    this.addressdetailservice
      .getaddressbyuserId('CURRENT', this.userid)
      .subscribe((res) => {
        if(res.body.id!=""&&!this.flag[2])
        {
          this.dummyfunc.emit(4);
          this.flag[2]=true;
        }
        this.addresscurr = res.body;
      });
  }

  getPermanentAddress() {
    this.addressdetailservice
      .getaddressbyuserId('PERMANENT', this.userid)
      .subscribe((res) => {
        if(res.body.id!=""&&!this.flag[1])
        {
          this.dummyfunc.emit(4);
          this.flag[1]=true;
        }
        this.address = res.body;
      });
  }

  getPersonalInformation(){
    this.userService.getUserPersonalInformationDetails(this.userid).subscribe((res) => {

      this.userDto = res.body;
      if(res.body.id && !this.flag[0])
      {

        this.dummyfunc.emit(4);
        this.flag[0]=true;
      }

      if (
        (this.userDto.bloodGroup == null || this.userDto.bloodGroup == undefined) &&
        (this.userDto.dateOfBirth == null || this.userDto.dateOfBirth == undefined) &&
        (this.userDto.placeOfBirth == '' || this.userDto.placeOfBirth == undefined) &&
        (this.userDto.relationshipStatus == null || this.userDto.relationshipStatus == undefined)
      ) {
        this.personalInformationPresentCheck = false;
      }

    })
  }

  getFamilyNomineeDetails(){
    this.familydetailservice
      .getfamilyByUserIdSubscribe(this.userid)
      .subscribe((res) => {
        if(res.body.length>0&&!this.flag[3])
        {
          this.dummyfunc.emit(5);
          this.flag[3]=true;
        }
        if(res.body.length==0&&this.flag[3])
        {
          this.dummyfunc.emit(-5);
          this.flag[3]=false;
        }
        this.familydetailsdto = this.familydetailservice.setfamilyResponse(
          res.body
        );
      });
  }

  getEmergencyContactOneDetails(){
    this.emergencycontactservice
      .getemerbyuserId('Emergencycontactone', this.userid)
      .subscribe((res) => {
        if(res.body.id && !this.flag[4])
        {
          this.dummyfunc.emit(4);
          this.flag[4]=true;
        }
        this.emergencycontactone = res.body;
      });
  }

  getEmergencyContactTwoDetails(){
    this.emergencycontactservice
      .getemerbyuserId('Emergencycontacttwo', this.userid)
      .subscribe((res) => {
        if(res.body.id && !this.flag[5])
        {
          this.dummyfunc.emit(4);
          this.flag[5]=true;
        }
        this.emergencycontacttwo = res.body;
      });
  }

  editCurrentAddressDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'currentaddress-class';
    //dialogConfig.data=this.userdto;
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog.open(CurrentAddressComponent, dialogConfig).afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          //this.ngOnInit();
          this.getCurrentAddress();
        }
      });
  }
  editPermanentAddressDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'permanentaddress-class';
    //dialogConfig.data=this.userdto;
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog.open(PermanentAddressComponent, dialogConfig).afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          // this.ngOnInit();
          this.getPermanentAddress();
        }
      });
  }

  editPersonalInformationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'personalinformation-class';
    //dialogConfig.data=this.userdto;
    dialogConfig.data = [this.userid, this.userDto];
    const dialogRef = this.dialog.open(
      PersonalInformationComponent,
      dialogConfig
    ).afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          // this.ngOnInit();
          // this.userDto = this.userDetailService.getUserDetails();
          this.getPersonalInformation();
        }
      });
  }

  editNomineeDetailsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'nominee-class';
    //dialogConfig.data=this.userdto;
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog.open(NomineeDetailsComponent, dialogConfig).afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          // this.ngOnInit();
          this.getFamilyNomineeDetails();
        }
      });
  }
  editEmergencyContact1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'emergencycontact-class';
    //dialogConfig.data=this.userdto;
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog.open(
      EmergencyContact1Component,
      dialogConfig
    ).afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          // this.ngOnInit();
          this.getEmergencyContactOneDetails();
        }
      });
  }
  editEmergencyContact2() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '55%';
    dialogConfig.width = '100%';
    dialogConfig.panelClass = 'emergencycontact-class';
    //dialogConfig.data=this.userdto;
    dialogConfig.data = this.userid;
    const dialogRef = this.dialog.open(
      EmergencyContact2Component,
      dialogConfig
    ).afterClosed()
      .subscribe((res) => {
        if (res.event == 'Closed') {
          // this.ngOnInit();
          this.getEmergencyContactTwoDetails();
        }
      });
  }
}
