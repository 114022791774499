<div class="container-fluid">
    <div class="main_header_div">
        <div class="child">
            <h3 class="header_name">Edit Holiday</h3>
        </div>
        <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
        </div>
    </div>
    <hr>

    <div class="row form_main_div">
        <div class="col-md-12">
            <form [formGroup]="newLeaveDetails">
                <div class="row">
                     <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>ClientName <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="projectName" (selectionChange)="onClientNameChange()">
                                <mat-option *ngFor="let project of this.clientProject" [value]="project">
                                    {{ project }}
                                </mat-option>
                            </mat-select>
                           
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Date  <span class="contentRed"></span></mat-label>
                            <input matInput [matDatepicker]="picker"
                                placeholder="mm/dd/yyyy" formControlName="date" autocomplete="off" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    
                    <div class="col-md-4" >
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Holiday Type<span class="contentRed"></span></mat-label>
                            <mat-select formControlName="LeaveType">
                                <mat-option *ngFor="let leave of LeaveType" [value]="leave">
                                    {{ leave | titlecase | underScoreToSpace}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-5">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>
                                Holiday Name <span class="contentRed"></span>
                            </mat-label>
                            <input matInput formControlName="holidayName" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div>

                
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button (click)="details()"class="add_Emp_btn">
                                <span class="add_em_name">Update</span>
                            </button>
                            
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
    
            
    

