import { Relation } from "../services/user/user-enum";
interface IFAMILYDETAILS{
    firstName:  string;
   lastName: string;
    relation : string;
    nominee:string
    location:string
    dateOfBirth:string
    userId:String
    id:string
    nominees:Number
}
export class Family{
    public firstName: String;
    //public lastName : string
    public userId:string
    public nominee:string
     public dateOfBirth:string
    public location:string
    public relation :string
    public id:string
    public nominees:Number
   // public relationType : string
    constructor(){
      
        this.location=''
        this.firstName = ''
       // this.lastName = ''
        this.id = undefined
        this.userId = undefined
        this.relation= ''
        this.nominee= ''
        this.dateOfBirth=undefined
        this.nominees = 0
      //  this.relationType = ''
    }
    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.firstName = httpResponse && httpResponse.firstName || undefined
       // this.lastName = httpResponse && httpResponse.lastName || undefined
        this.userId = httpResponse && httpResponse.userId || undefined
        this.relation = httpResponse && httpResponse.relation || undefined 
        this.nominee=httpResponse && httpResponse.nominee || undefined
        this.location=httpResponse && httpResponse.location || undefined
        this.dateOfBirth=httpResponse && httpResponse.dateOfBirth || undefined
        this.nominees=httpResponse && httpResponse.nominees || undefined
        
        return this;     
    }
}
