import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { debug } from 'console';
import { resolve } from 'dns';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpecifiInfoUser } from 'src/app/models/SpecificInfoUser';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { AngularSpinnerService } from '../angular-spinner.service';
import { InitialAvatarService } from '../initialAvatar/initial-avatar.service';
import { RestAPIService } from '../restAPI/rest-api.service';
import { UserUrlService } from '../restAPI/userURLs/user-url.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public users = [];
  public usersDto: User[];
  public userdata: User[];
  public directReportees: User[];
  public userName: String;
  public totalCount;
  public page: PageEvent
  public pageCount = 1
  public pageSize = 500
  public allUserData = [];
  public careerManagerIdName = {};
  displayEmployeeCards: boolean;
  public employeeNames = [];
  public usersDtoForCMTBList: SpecifiInfoUser[] = [];

  public totalEmployeesCount : any;
user_details = new Subject<boolean>();
  constructor(private restApi: RestAPIService,
    private userUrl: UserUrlService, private http: HttpClient,
    private angularSpinnerService: AngularSpinnerService,
    private initialAvatarService: InitialAvatarService) { }


  getAllUser(): any {
    this.restApi.get(
      this.userUrl.getBaseUrl() + "?size=200"
    ).subscribe(res => {
      this.setAllUsers(res.body);
    })
  }
  getAllUsers(): any {
    return this.restApi.get(
      this.userUrl.getBaseUrl() + "?size=200"
    )
  }
  getAllUserForDropDown()
  {
    return this.restApi.get(
      this.userUrl.getBaseUrlForDropDown() + "?size=200"
    )
  }

  getAllEmployeeNames(){
    
    return this.restApi.get('/api/users/getAllEmployeeNames').subscribe(res =>{
      
      this.setUsersToPopulateCareerManagerAndTalentBuddyLists(res.body);
    })
  }

  validateEmail(email) {
    return this.restApi.get(this.userUrl.getValidateEmailUrl(email));
  }
  getAllUserPagination(pageCount, pageSize, sort) {

    return this.restApi.get(
      this.userUrl.getPaginationUrl(pageCount, pageSize, sort)
    )
  }
  getData() {
    return this.http.get(environment.BASE_API_URL_BACKEND + '/api/users')
      .pipe(
        map((response: []) => response.map(item => item['firstName'] + " " + item['lastName']))
      )
  }
  userUpdateForProfileDetails(userDto)
  {

    return this.restApi.put('/api/users/update/user-profile',userDto);
    
  }
  validateEmployeeId(employeeId) {
    return this.restApi.get(this.userUrl.getValidateEmployeeIdUrl(employeeId));
  }
  setAllUsers(users) {
    this.allUserData = [];
    for (let user of users) {
      this.allUserData.push(user);
    }
  }
  getUser(sort) {
    
    this.restApi.get(
      this.userUrl.getPaginationUrl(this.pageCount, this.pageSize, sort)
    ).subscribe(res => {
      
      this.setUsers(res.body);
      this.setUsersDto(res.body);
      if(this.usersDto.length!=0){
      this.displayEmployeeCards=true;
      }

       //Populate Career Managers names
       for (let user of this.usersDto) {
        
        if (user.careerManagerId !== null)
        {
          var careerManagerRecord = this.usersDto.find((obj) =>{
            return obj.id == user.careerManagerId
          })
          this.careerManagerIdName[user.careerManagerId] = careerManagerRecord.firstName + ' ' + careerManagerRecord.lastName;
        }
      } 

      this.totalCount = Number(res.headers.getAll('X-Total-Count'));
      this.allUserData
      this.angularSpinnerService.stop();
    })
  }
  getUserSubscribe() {
    return this.restApi.get(
      this.userUrl.getBaseUrl()
    )
  }
  getUserDetails() {
    return this.restApi.get("/api/users");
  }
  setUsers(users) {
    this.users = [];
    for (let user of users) {
      this.users.push(user);
    }
  }
  setUsersDto(users) {
    this.usersDto = [];
    for (let user of users) {
      if (user.id.length > 6) {
        if(user.userImageThumbnail !== "" ){
        user.userImageThumbnail = "data:image/png;base64," + user.userImageThumbnail;
      }
        this.usersDto.push(new User(user))
      }
    }
  }
  setMobileUsersDto(users) {

    this.userdata = [];
    for (let user of users) {
      if (user.id.length > 6) {
        this.userdata.push(new User(user))
      }
    }
  }
  getUserByActivatedStatus(activated: boolean) {
    this.restApi.get(
      this.userUrl.getUserByActivatedStatusUrl(activated)
    ).subscribe(res => {
      this.setUsers(res.body);
      this.setUsersDto(res.body);
    })
  }
  getUserByActivatedStatusSubscribe(activated: boolean) {
    return this.restApi.get(
      this.userUrl.getUserByActivatedStatusUrl(activated)
    )
  }
  getUserByID(id) {
    return this.restApi.get(this.userUrl.getByFieldUrl(id))
  }
  getConfirmationDate(id){
    return this.restApi.get(this.userUrl.getConfirmationDate(id))
  }
  assinedUserAfterParse
  assinedManagerUseAfterParse
  async getUserByIdAsync(id) {
    let promise3 = new Promise((resolve, reject) => {
      this.getUserByID(id).subscribe(user => {
        resolve(user.body)
      })
    })
    var assinedUser = await promise3;
    var assinedUserAsString = JSON.stringify(assinedUser);
    this.assinedUserAfterParse = JSON.parse(assinedUserAsString);
   let promisemanager=new Promise((resolve, reject) => {
    this.getUserByID(this.assinedUserAfterParse.careerManagerId).subscribe(user => {
      resolve(user.body)
    })
  })
   var assinedManagerUse=await promisemanager;
   var assinedManagerUseAsString=JSON.stringify(assinedManagerUse);
   this.assinedManagerUseAfterParse=JSON.parse(assinedManagerUseAsString);
  }
  getabout() {
    return this.restApi.get(
      this.userUrl.getBaseUrl()
    )
  }
  getUserNameByID(id): Observable<any> {
    return this.http.get(environment.BASE_API_URL_BACKEND + '/api/users/userName/' + id, { responseType: 'text' });
  }
  searchUser(searchText) {
    let temp = this.userUrl.getSearchUrl(searchText)
    this.restApi.get(
      this.userUrl.getSearchUrl(searchText)
    ).subscribe(res => {
      this.setUsersDto(res.body)
    })
  }
  setDirectReportees(directReportees) {
    this.directReportees = [];
    for (let user of directReportees) {
      this.directReportees.push(new User(user))
    }
  }
  getAllDirectReportees(careerManagerId) {
    this.restApi.get(
      this.userUrl.getDirectReportees(careerManagerId)
    )
      .subscribe(res => {
        this.setDirectReportees(res.body)
      })
  }
  getAllDirectReporteesSubscribe(careerManagerId) {
    return this.restApi.get(
      this.userUrl.getDirectReportees(careerManagerId)
    )
  }
  addUser(userDto) {

    return this.restApi.post(
      this.userUrl.getBaseUrl(),
      userDto
    )
  }

  deleteUser(id) {
    this.restApi.delete(
      this.userUrl.getDeleteUrl(id)
    ).subscribe(res => {
      this.getUser("Asc")
    })
  }

  updateUser(payload) {
    return this.restApi.put(
      this.userUrl.getBaseUrl(),
      payload
    )
  }
  processName(user) {
    let name;
    if (user && user.firstName)
      name = user.firstName
    if (user && user.lastName) {
      name ? name += " " + user.lastName : name = user.lastName;
    }
    return name;
  }

  getAllAssetUsers(): any {
    return this.restApi.get(
      this.userUrl.getBaseUrl() + "?size=200"
    )
  }

  getEmpNameFromEmpId(empId) {
    return this.restApi.get("/api/employeeName/EmpId/" + empId)
  }

  getAllEmpIds() {
    return this.restApi.get("/api/getAllEmpIds")
  }
  getAllUsersMailId() {
    return this.restApi.get("/api/getAllUsersMailId")
  }

  getUserThumbnailFromEmpId(empId) {
    return this.restApi.get("/api/getUserThumbnailFromEmpId/" + empId)
  }

  getUserThumbnailFromUserId(id) {
    return this.restApi.get("/api/getUserThumbnailFromUserId/" + id)
  }

  getCareerManagerEmailId() {
    return this.restApi.get("/api/user/career-manager-email-id");
  }
  getCapablityLeadEmailId(capablity) {
    return this.restApi.get("/api/getCapabilityLeadMail/"+capablity);
  }

  getUserRelievedStatus(id) {
    return this.restApi.get(`/api/users/relieved-status/${id}`)
  }

  changeRelievedStatus(id) {
    return this.restApi.put(`/api/users/change-relieved-status/`, id)
  }

  fetchUserRoles() {
    return this.restApi.get('/api/users/fetch-user-roles');
  }

  setUsersToPopulateCareerManagerAndTalentBuddyLists(users) {
        
    this.usersDtoForCMTBList = [];
    for (let user of users) {
      if (user.userID.length > 6) {
        this.usersDtoForCMTBList.push(new SpecifiInfoUser(user))
      }
    }
    
  }

 
  getEmployeeById(id){
    return this.restApi.get(this.userUrl.getBaseUrl() + this.userUrl.getByField(id));
  }

  fetchEmployeesCount(){
    return this.restApi.get('/api/users/getAllEmployeesCount');
  }

  getEmployeesCount(){
    return this.totalEmployeesCount;
  }

  setEmployeesCount(count) {
    this.totalEmployeesCount = count;
  }

  getUserAbout(id){
    return this.restApi.get('/api/users/getUserAbout/' + id);
  }

  setUserAbout(id, about){
    return this.restApi.put('/api/users/updateUserAbout/' + id, about);
  }

  getUserPersonalInformationDetails(id){
    return this.restApi.get('/api/getUserPersonalDetails/' + id)
  }

  setUserPersonalInformationDetails(id, userDto){
    return this.restApi.put('/api/setUserPersonalDetails/' + id, userDto)
  }

  getEmployeeIdById(id){
    return this.restApi.get('/api/getEmployeeId/' + id);
  }

  getAuthorizationCheck(){
    return this.restApi.get('/api/authorizationCheck');
  }
setpositionHistroy(id,data)
{
  return this.restApi.put('/api/setPositionHistory/' + id,data)

}
getpositionHistroy(id)
{
  return this.restApi.get('/api/getPositionHistory/'+id);

}
getcapabilityDetails(id)
{
  
  return this.restApi.get('/api/getCapabilityDetails/'+id);

}
setcapabilityHistory(id,data)
{
  return this.restApi.put('/api/setCapabilityHistory/' + id,data)

}

deactivateUser(id) {
  return this.restApi.get('/api/users/deactivate/' + id);
}

activateUser(id) {
  return this.restApi.get('/api/users/activate/' + id);
}

  getAllUsersDetails() {
    return this.restApi.get(
      this.userUrl.getAllUsersDetails()
    );
  }
}
