import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';


@Injectable({
  providedIn: 'root'
})
export class AttendanceHistoryUrlServiceService {
  private readonly attendaceHistoryBaseUrl='/api/attendances/calendar-attendance';
  private readonly reporteeOnLeaveBaseUrl='/api/attendances/user/status'
  constructor(private restApiService: RestAPIService,
    ) { }

  getAttendanceHistoryByUserId(year){
    return this.attendaceHistoryBaseUrl+'/'+year;
  }
  getOnLeaveUrl(status){
    return this.reporteeOnLeaveBaseUrl+'/'+status;
  }
}
