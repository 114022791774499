import {
  Component,
 
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { EditProfilesComponent } from '../edit-profiles.component';
import { User } from 'src/app/models/user';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { ApiService } from 'src/app/services/api.service';
import { ProfileEmployeeComponent } from '../../profile-employee/profile-employee.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-tab1-profile',
  templateUrl: './tab1-profile.component.html',
  styleUrls: ['./tab1-profile.component.scss'],
})
export class Tab1ProfileComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  editEmployeeDetails: FormGroup;
  btnDisabled = false;
  userDto: UserDTO;
  imageError: string;
  photo: any;
  saved: Boolean = false;
  base64textString: any;
  percentage: number;
  isImageSaved: boolean;
  cardImageBase64: string;
  binaryImgString: string;
  savebtnlbl= "Save";
  countrySelectEvent: any;

@ViewChild(ProfileEmployeeComponent) profileEmployeeComponent:ProfileEmployeeComponent;
  userid: string;
  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<EditProfilesComponent>,
    public userDetailService: UserDetailsService,
    public userService: UserService,
public apiService:ApiService,
public toaster:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editEmployeeDetails = this.fb.group({
      firstName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[A-Za-z ]{1,}'),
        ]),
      ],
      lastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[A-Za-z ]{1,}'),
        ]),
      ],
      mobileNo: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))s*[)]?[-s.]?[(]?[0-9]{1,3}[)]?([-s.]?[0-9]{3})([-s.]?[0-9]{3,4})'
          ),
        ]),
      ],
      imageBase: [''],
    });
  }

  ngOnInit(): void {
    this.setFormData();
  }

 

  closebutton() {
    this.saved = false;
  }
  setDefaultImg() {
    this.photo = false;
    this.userid = '';
    this.editEmployeeDetails.patchValue({
      imageBase: null,
    });
  }
  checkError = (controlName: string, errorName: string) => {
    return this.editEmployeeDetails.controls[controlName].hasError(errorName);
  };
  readURL(event: Event): void {
    
    this.photo = false;
    this.imageError = '';
    if (event && event[0]) {
      const file = event[0];
      const reader = new FileReader();
      const max_size = 1000000;
      const max_height = 15200;
      const max_width = 25600;

      if (event[0].size > max_size) {
        this.imageError =
          'Maximum allowed size is ' + max_size / 1000000 + 'MB';
        return;
      }
      reader.onload = (e) => {
        this.photo = true;
        this.userid = e.target.result as string;
        this.base64textString = btoa(this.userid);
        this.editEmployeeDetails.patchValue({
          imageBase: this.userid.split(',')[1],
        });
      };
      reader.readAsDataURL(file);
    }
  }
  setFormData() {
    this.data = this.userDetailService.getUserDetails();
    if (this.data) {
      this.data = this.userDetailService.userDetails;
      this.editEmployeeDetails
        .get('firstName')
        .setValue(this.data.firstName ? this.data.firstName : '');
      this.editEmployeeDetails
        .get('lastName')
        .setValue(this.data.lastName ? this.data.lastName : '');
      this.editEmployeeDetails
        .get('mobileNo')
        .setValue(this.data.mobileNumber ? this.data.mobileNumber.trim() : '');
      if (this.data.userImageThumbnail !== '') {
        this.photo = true;

        this.userid =  this.data.userImageThumbnail;
      } else {
        this.photo = false;
      }
    }
  }
  onSubmit() {
    
    if (this.editEmployeeDetails.valid) {
      this.userDto = this.data
      this.userDto.firstName = this.editEmployeeDetails.get('firstName').value;

      this.userDto.lastName = this.editEmployeeDetails.get('lastName').value;
      this.userDto.mobileNumber =
        this.editEmployeeDetails.get('mobileNo').value;

      if (this.photo) {
        this.editEmployeeDetails.patchValue({
          imageBase: this.userid.split(',')[1],
        });

        if (this.data &&
          this.editEmployeeDetails.get('imageBase').value ===
            this.data.userImageThumbnail
        ) {
          this.userDto.userImage = 'Unchanged';
        } else {
          this.userDto.userImage =
            this.editEmployeeDetails.get('imageBase').value;
        }
      } else {
        this.userDto.userImage = '';
      }

      if (this.data) {
        

        if (this.data.id) this.userDto.id = this.data.id;
        // this.savebtnlbl = "Saving"
        this.userService.userUpdateForProfileDetails(this.userDto).subscribe((res) => {

        
           this.apiService.getUserAccount().subscribe(res => {
           this.userDetailService.setUserDetails(res);
            this.savebtnlbl = "Save"
            
          })
                 
          // this.profileEmployeeComponent.data2
          this.saved = true;
          this.toaster.success("Successfully Saved")

        });
      } else {
        this.userService.updateUser(this.userDto).subscribe((res) => {
          this.userService.getUser('Asc');
          this.saved = true;
          this.toaster.success("Successfully Saved")
        });
      }
    }
    this.userDetailService.setUserProfile(this.userDto);
  }

  countryChangedEvent($event){
    this.countrySelectEvent = $event;
   this.editEmployeeDetails.controls["mobileNo"].setValidators([Validators.maxLength(this.countrySelectEvent.placeHolder.length)]);
  
  }

  focusOutMobileField(){

    if(this.editEmployeeDetails.get('mobileNo').value.length === 0){
      this.setFormControlError('mobileNo', 'ZeroLength', 'required');
    }

    else if(this.editEmployeeDetails.get('mobileNo').value.length !== this.countrySelectEvent.placeHolder.length){
      this.setFormControlError('mobileNo', 'InvalidNumber', 'Invalid Pattern');
    }

    else{
    }
  }

  setFormControlError(controlName:string, controlError:string, errorMsg:string) {
    
    this.editEmployeeDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.editEmployeeDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.editEmployeeDetails.get(controlName).setErrors({ [controlError]: true });
  }

 

}
