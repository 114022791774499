<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Bulk Import</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog()">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>

    </div>
</div>
<hr>

<div class="main_class">
    <img *ngIf="!fileName && !badFileFormat " class="bulk_import_img" src="assets/bulk_import.png">

    <div class="invalidFile" *ngIf="badFileFormat">
        <img class="invalid_file_import_img" src="assets/cancel_logo.png">
        <label class="invalid_file_label"> Select and upload the supported file </label>

    </div>
    <img *ngIf="fileName && hidebutton " class="file_import_img" src="assets/file_logo.png">

</div>
<div class="main_class2">
 <label *ngIf="!fileName && !badFileFormat" class="file_name">Make sure you select the excel .xlsx file </label>
    <label *ngIf="!fileName && !loading" class="rectangle_lines center" (click)="fileInput.click()">{{this.uploadBtnLabel}}</label>
    <input type="file" id="my_file" [(ngModel)]="this.fileName" style="display: none;" accept=".xlsx"
        (change)="readFile($event.target.files)" #fileInput />

    <!-- Display file name is file is selected, else display no file uploaded.
    Display upload button only when a file is selected and upon clicking it, 
    invoke uploadFile function 
    -->

   
    <label *ngIf="hidebutton" class="file_name"> {{fileName}}</label>
     <!-- <img class="file_import_img" *ngIf="fileName && fileName!=='Please wait...' && hidebutton" src="assets/loading.png"> 
 -->

 
    <button *ngIf="fileName && fileName!=='please wait we are processing your request' && hidebutton " class="upload_btn"  (click)="openPopup()" >
        <span class="upload_btn_name">Upload</span> </button>

        <img *ngIf="loading " class="loading" src="assets/loading.png"> 
 <label class="loading_label" *ngIf="loading ">Please wait we are processing your request</label>

    <!-- Dialog  for confirming the import when import users button is clicked-->


    <div *ngIf="!hidebutton && !badFileFormat && !loading" class="row abcde">
        <div   class="button_main_div" >
            <label  class="confirmation_text_file"> {{fileName}}</label>
            <label   class="confirmation_text">Are you sure you want to import the users?</label>
          
            <div class="button_flex">
                <button class="yes-btn mr-2" (click)="uploadFile()">Yes
                </button>

                <button class="no-btn ml-2" (click)="closePopup()">No
                </button>

            </div>

        </div>
        <div *ngIf="!hidebutton && !badFileFormat && !loading" class="button_main_div2">
            <label class="confirmation_text_file1"> {{fileName}}</label>
            <label class="confirmation_text1">Are you sure you want to import the users?</label>
          
            <div>
                <button  (click)="uploadFile()">Yes
                </button>

                <button   (click)="closePopup()">No
                </button>

            </div>

        </div>
    </div>






</div>
