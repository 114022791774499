

<div class="main">
    <div class="header">
      <div class="mt-2 div-left">
        <span>Exit Checklist</span>
      </div>
      <div class="mt-2 div-right">
        <a (click)="closeDialog()">close</a>
      </div>
    </div>
    <div>
      <div>
        <mat-stepper labelPosition="bottom" #stepper>
          <ng-template matStepperIcon="number" let-index="index">
            {{ index + 1 }}/5
          </ng-template>
          <ng-template matStepperIcon="edit" let-index="index">
            {{ index + 1 }}/5
          </ng-template>
          <mat-step click="stepClick(index)">
            <ng-template matStepLabel
              ><span class="txt">Employee</span></ng-template
            >
            <form [formGroup]="userFormGroup">
              <div class="user">
                <div class="first-user">
                  <mat-icon class="personicon">person</mat-icon>
                  <div class="userdiv">
                    <mat-form-field appearance="outline">
                      <mat-label> Employee </mat-label>
                      <input
                        formControlName="user"
                        matInput
                        type="text"
                        [matAutocomplete]="auto"
                      />
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                          *ngFor="let item of filteredOptions"
                          [value]="item"
                        >
                          {{ item }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="editicon">
                    <mat-icon (click)="changeEditable()">edit</mat-icon>
                  </div>
                </div>
                <div class="second-user">
                  <table class="table">
                    <thead>
                      <div class="flex">
                        <th>Sr.no</th>
                        <th class="action">Action</th>
                      </div>
                      <th>Must Completed by</th>
                      <th>Action Completion Date</th>
                      <th>Comments</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let f of stages.USER_STAGE.fields | keyvalue">
                        <span class="flex">
                          <td class="first">
                            {{ stages.USER_STAGE.fields[f.key].id }}
                          </td>
                          <td class="mid" data-label="Action">
                            <div
                              id="divedit"
                              class="txtbox1 head"
                              contentEditable="false"
                            >
                              {{ stages.USER_STAGE.fields[f.key].title }}
                            </div>
                          </td>
                        </span>
                        <td class="colu" data-label="Must completed by">
                          <div class="txtbox">
                            {{ stages.USER_STAGE.fields[f.key].mustComplete }}
                          </div>
                        </td>
                        <td class="colu" data-label="Actual Completion Date">
                          <div class="txtbox">
                            {{ stages.USER_STAGE.fields[f.key].actionCompletionDate }}
                          </div>
                        </td>
                        <td class="colu" data-label="Comments">
                          <div class="txtbox txtlbl">
                            {{ stages.USER_STAGE.fields[f.key].comments }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="btn">
                    <button class="assignbtn" (click)="assignUser()">
                      Assign Employee
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>PnT</ng-template>
            <form [formGroup]="hrFormGroup">
              <div class="user">
                <div class="first-user">
                  <mat-icon class="personicon">person</mat-icon>
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label> PnT </mat-label>
                      <input
                        formControlName="hr"
                        matInput
                        type="text"
                        [matAutocomplete]="auto"
                      />
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                          *ngFor="let item of filteredOptions"
                          [value]="item"
                        >
                          {{ item }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="editicon">
                    <mat-icon>edit</mat-icon>
                  </div>
                </div>
                <div class="second-user">
                  <table class="table">
                    <thead>
                      <div class="flex">
                        <th>sr.no</th>
                        <th class="action">Action</th>
                      </div>
                      <th>Must Completed by</th>
                      <th>Action Completion Date</th>
                      <th>Comments</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of HrCheckList; let i = index">
                        <span class="flex">
                          <td class="first">{{ i + 1 }}</td>
                          <td class="mid" data-label="Action">
                            <div
                              id="divedit"
                              class="txtbox1 head"
                              contentEditable="false"
                            >
                              {{ data.action }}
                            </div>
                          </td>
                        </span>
                        <td class="colu" data-label="Must be Completed By">
                          <div class="txtbox">
                            {{ data.mustCompleted }}
                          </div>
                        </td>
                        <td class="colu" data-label="Action completion Date">
                          <div class="txtbox">
                            {{ data.actionCompletionDate }}
                          </div>
                        </td>
                        <td class="colu" data-label="Comments">
                          <div class="txtbox txtlbl">
                            {{ data.comments }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="btn">
                <button class="assignbtn" (click)="assignHr()">
                  Assign HR Owner
                </button>
              </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Admin</ng-template>
            <div class="tab">
              <mat-tab-group class="operation-owner-tab" mat-align-tabs="center">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="oo-label">
                      <div class="part">1/2</div>
                      <div class="part-label">Admin</div>
                    </div>
                  </ng-template>
                  <form [formGroup]="oo1FormGroup">
                    <div class="user">
                      <div class="first-user">
                        <mat-icon class="personicon">person</mat-icon>
                        <div>
                          <mat-form-field appearance="outline">
                            <mat-label> Operation Owner </mat-label>
                            <input
                              formControlName="op_owner1"
                              matInput
                              type="text"
                              [matAutocomplete]="auto"
                            />
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option
                                *ngFor="let item of filteredOptions"
                                [value]="item"
                              >
                                {{ item }}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                        <div class="editicon">
                          <mat-icon>edit</mat-icon>
                        </div>
                      </div>
                      <div class="second-user">
                        <table class="table">
                          <thead>
                            <div class="flex">
                              <th>sr.no</th>
                              <th class="action">Action</th>
                            </div>
                            <th>Must Completed by</th>
                            <th>Action Completion Date</th>
                            <th>Comments</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of OOCheckList1; let i = index">
                              <span class="flex">
                                <td class="first">{{ i + 1 }}</td>
                                <td class="mid" data-label="Action">
                                  <div
                                    id="divedit"
                                    class="txtbox1 head"
                                    contentEditable="false"
                                  >
                                    {{ data.action }}
                                  </div>
                                </td>
                              </span>
                              <td class="colu" data-label="Must be Completed By">
                                <div class="txtbox">
                                  {{ data.mustCompleted }}
                                </div>
                              </td>
                              <td
                                class="colu"
                                data-label="Action completion Date"
                              >
                                <div class="txtbox">
                                  {{ data.actionCompletionDate }}
                                </div>
                              </td>
                              <td class="colu" data-label="Comments">
                                <div class="txtbox txtlbl">
                                  {{ data.comments }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="btn">
                      <button class="assignbtn" (click)="assignOO1()">
                        Assign Operation Owner
                      </button>
                    </div>
                  </form>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div class="oo-label">
                      <div class="part">2/2</div>
                      <div class="part-label">Operation Owner</div>
                    </div>
                  </ng-template>
                  <form [formGroup]="oo2FormGroup">
                    <div class="user">
                      <div class="first-user">
                        <mat-icon class="personicon">person</mat-icon>
                        <div>
                          <mat-form-field appearance="outline">
                            <mat-label> Operation Owner </mat-label>
                            <input
                              formControlName="op_owner2"
                              matInput
                              type="text"
                              [matAutocomplete]="auto"
                            />
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option
                                *ngFor="let item of filteredOptions"
                                [value]="item"
                              >
                                {{ item }}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                        <div class="editicon">
                          <mat-icon>edit</mat-icon>
                        </div>
                      </div>
                      <div class="second-user">
                        <table class="table">
                          <thead>
                            <div class="flex">
                              <th>sr.no</th>
                              <th class="action">Action</th>
                            </div>
                            <th>Must Completed by</th>
                            <th>Action Completion Date</th>
                            <th>Comments</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of OOCheckList2; let i = index">
                              <span class="flex">
                                <td class="first">{{ i + 1 }}</td>
                                <td class="mid" data-label="Action">
                                  <div
                                    id="divedit"
                                    class="txtbox1 head"
                                    contentEditable="false"
                                  >
                                    {{ data.action }}
                                  </div>
                                </td>
                              </span>
                              <td class="colu" data-label="Must be Completed By">
                                <div class="txtbox">
                                  {{ data.mustCompleted }}
                                </div>
                              </td>
                              <td
                                class="colu"
                                data-label="Action completion Date"
                              >
                                <div class="txtbox">
                                  {{ data.actionCompletionDate }}
                                </div>
                              </td>
                              <td class="colu" data-label="Comments">
                                <div class="txtbox txtlbl">
                                  {{ data.comments }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="btn">
                      <button class="assignbtn" (click)="assignOO2()">
                        Assign Operation Owner
                      </button>
                    </div>
                  </form>
                </mat-tab>
              </mat-tab-group>
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Account</ng-template>
            <form [formGroup]="foFormGroup">
              <div class="user">
                <div class="first-user">
                  <mat-icon class="personicon">person</mat-icon>
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label> Account </mat-label>
                      <input
                        formControlName="finance_owner"
                        matInput
                        type="text"
                        [matAutocomplete]="auto"
                      />
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                          *ngFor="let item of filteredOptions"
                          [value]="item"
                        >
                          {{ item }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="editicon">
                    <mat-icon>edit</mat-icon>
                  </div>
                </div>
                <div class="second-user">
                  <table class="table">
                    <thead>
                      <div class="flex">
                        <th>sr.no</th>
                        <th class="action">Action</th>
                      </div>
                      <th>Must Completed by</th>
                      <th>Action Completion Date</th>
                      <th>Comments</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of FOCheckList; let i = index">
                        <span class="flex">
                          <td class="first">{{ i + 1 }}</td>
                          <td class="mid" data-label="Action">
                            <div
                              id="divedit"
                              class="txtbox1 head"
                              contentEditable="false"
                            >
                              {{ data.action }}
                            </div>
                          </td>
                        </span>
                        <td class="colu" data-label="Must be Completed By">
                          <div class="txtbox">
                            {{ data.mustCompleted }}
                          </div>
                        </td>
                        <td class="colu" data-label="Action completion Date">
                          <div class="txtbox">
                            {{ data.actionCompletionDate }}
                          </div>
                        </td>
                        <td class="colu" data-label="Comments">
                          <div class="txtbox txtlbl">
                            {{ data.comments }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="btn">
                <button class="assignbtn" (click)="assignFinanceOwner()">
                  Assign Finance Owner
                </button>
              </div>
            </form>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Career Manager</ng-template>
            <form [formGroup]="cmFormGroup">
              <div class="user">
                <div class="first-user">
                  <mat-icon class="personicon">person</mat-icon>
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label> Career Manager </mat-label>
                      <input
                        formControlName="cm"
                        matInput
                        type="text"
                        [matAutocomplete]="auto"
                      />
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                          *ngFor="let item of filteredOptions"
                          [value]="item"
                        >
                          {{ item }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div class="editicon">
                    <mat-icon>edit</mat-icon>
                  </div>
                </div>
                <div class="second-user">
                  <table class="table">
                    <thead>
                      <div class="flex">
                        <th>sr.no</th>
                        <th class="action">Action</th>
                      </div>
                      <th>Must Completed by</th>
                      <th>Action Completion Date</th>
                      <th>Comments</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of CMCheckList; let i = index">
                        <span class="flex">
                          <td class="first">{{ i + 1 }}</td>
                          <td class="mid" data-label="Action">
                            <div
                              id="divedit"
                              class="txtbox1 head"
                              contentEditable="false"
                            >
                              {{ data.action }}
                            </div>
                          </td>
                        </span>
                        <td class="colu" data-label="Must be Completed By">
                          <div class="txtbox">
                            {{ data.mustCompleted }}
                          </div>
                        </td>
                        <td class="colu" data-label="Action completion Date">
                          <div class="txtbox">
                            {{ data.actionCompletionDate }}
                          </div>
                        </td>
                        <td class="colu" data-label="Comments">
                          <div class="txtbox txtlbl">
                            {{ data.comments }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="btn">
                <button class="assignbtn" (click)="assignCarreerManager()">
                  Assign Career Manager
                </button>
              </div>
            </form>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
  