import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbackNotesDTO } from 'src/app/dtos/FeedbackNotesDTO';
import { FeedbackNotesService } from 'src/app/services/feedback-notes/feedback-notes.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { MainContentProjectComponent } from '../../project/main-content-project/main-content-project.component';

@Component({
  selector: 'app-feedback-closure-note',
  templateUrl: './feedback-closure-note.component.html',
  styleUrls: ['./feedback-closure-note.component.scss']
})
export class FeedbackClosureNoteComponent implements OnInit {
  feedbackNotes: FormGroup;
  feedbackNotesDto= new FeedbackNotesDTO();
  data:any;
  feedbackClosureNotes;
  feedbackdto:any
  constructor(public dialogRef: MatDialogRef<MainContentProjectComponent>,public fb: FormBuilder,   public toastr: ToastrService
,    public feedbackNotesService: FeedbackNotesService,@Inject(MAT_DIALOG_DATA) public notes:any, private feedbackgroup:NewFeedbackGroupService) { 
    this.feedbackNotes = this.fb.group({
      note: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.setFormData();
  }
  closeDialog() {
    this.dialogRef.close()
  }
  setFormData() {
    
    this.feedbackNotesService.getFeedbackSummary(this.notes.directReporteeId,this.notes.selected).subscribe(res=>{
      
       this.feedbackdto=res.body
       this.feedbackClosureNotes=this.feedbackdto.feedbackSummary
      //  this.feedbackNotes.get('note').setValue();

    })
    }
  // setFormData() {
  //   this.feedbackNotesService.getnotesbyuserandyear(this.notes.directReporteeId,this.notes.selected).subscribe(res=>{
  //      this.feedbackdto=res.body
  //      this.feedbackNotes.get('note').setValue(this.feedbackdto.cmNote);
  //   })
  //   }
  save() {
   
      this.feedbackgroup.closeFeedBackSaveNote(this.notes.directReporteeId,this.notes.selected,true,this.feedbackNotes.value.note).subscribe(res=>{
      
      if(res.body==0)
      {
        
        this.toastr.success("Success",'Success');
        this.dialogRef.close()

      }
      else if(res.body==1)
      {
        this.toastr.error('All Feedback Group weightage Sum should be 100%','failed')
        this.dialogRef.close()


      }
      else if(res.body==2)
      {
        this.toastr.error('All feedback must be submitted','failed')
        this.dialogRef.close()


      }
      else if(res.body==3)
      {
        this.toastr.error('All feedback weightage sum should be 100%','failed')
        this.dialogRef.close()

      }
      else if(res.body==4)
      {
        this.toastr.success('Successfully Saved','success')
        this.dialogRef.close()


      }
     
    },error=>{
      
      this.toastr.error("No feedback generated for user for the year",'failed')
    })
  }
}
