import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout'
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LoginService } from 'src/app/services/login.service';
import { AttendanceService } from 'src/app/services/attendance-extra/attendance.service';
import { Attendance } from 'src/app/models/attendance';
import { AttendanceStatus } from '../../services/attendance-extra/attendance-enum';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditProfilesComponent } from '../edit-profiles/edit-profiles.component';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user/user.service';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { LeaveAttendanceComponent } from '../leave-management/leave-attendance/leave-attendance.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PublicProfileComponent } from '../profile-employee/public-profile/public-profile.component'; 
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, AfterViewInit {
  // @ViewChild(LeaveAttendanceComponent) private leaveAttandance: LeaveAttendanceComponent;

  mode = new FormControl('side' as MatDrawerMode);
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  mark_Attendance;
  attendance_Marked;
  disableState: boolean = false;
  data: Attendance = new Attendance();
  attendanceStatus: boolean = false;
  opened: boolean = false;
  userdata: User;
  searchText = '';
  filteredUsers: Observable<User[]>;
  allUsers: User[];
  selectedUser: any;

  userControl: FormControl = new FormControl();


  @Input() disableClose
  @ViewChild('searchbar') searchbar: ElementRef;
  toggleSearch: boolean = false;
  constructor(
    private observer: BreakpointObserver,
    public loginService: LoginService,
    private authguardService: AuthGuardService,
    public userDetatilsService: UserDetailsService,
    private attendanceService: AttendanceService,
    private notificationService: NotificationService,
    private _router: Router,
    private dialog: MatDialog,
    private userservice: UserService,
    public userDetailService: UserDetailsService,
    private router: Router,
    private feedbackService:FeedbackService
    ) {
  }
  ngOnInit(): void {
    this.checkAttendanceStatus()
    this.userdata = this.userDetailService.getUserDetails().userImageThumbnail
    this.userservice.getAllUsers().subscribe((res) => {
      this.allUsers = res.body;
      // console.log(res.body)
      this.filteredUsers = this.userControl.valueChanges.pipe(
        startWith(''),
        map(value => this.filterUsers(value))
      );
    });
  }

  filterUsers(value: string): User[] {
    const filterValue = value.toLowerCase().trim();
    if (this.selectedUser) {
      return this.allUsers.filter(user =>
        user.firstName.toLowerCase().includes(filterValue) && user !== this.selectedUser
      );
    } else {
      return this.allUsers;
    }
  }
  checkAttendanceStatus() {
    this.attendanceService.getAttendanceForLoggerInUser().subscribe(res => {
      if (res.body == false) {
        this.mark_Attendance = true;
        this.attendance_Marked = false;
      }
      else {
        this.mark_Attendance = false;
        this.attendance_Marked = true;
        this.disableState = false;
      }
    });
  }

  selectUser(user: any) {
    if (user) {
      this.selectedUser = user;
      // console.log('Selected user:', this.selectedUser);
      this.openEmployeeView()
      this.userControl.setValue('');
    } else {
      this.selectedUser = null;
      this.userControl.setValue('');

      return;
    }
  }

  openEmployeeView(){
    // const dialogConfig = new MatDialogConfig();
    // // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.width = "30%";
    // dialogConfig.height = "80%";
    // dialogConfig.data=this.selectedUser.id;
    // const dialogRef = this.dialog.open(PublicProfileComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(result => {
    // });
    // this.router.navigate(['/myprofile']);
    this.feedbackService.sendData(this.selectedUser.id);

   this.router.navigate([`/employee/employee-profile/${this.selectedUser.id}`]);

    // this.router.navigateByUrl("/employee/employee-profile/${this.selectedUser.id}",{skipLocationChange : true}).then(()=>{
    //   this._router.navigate([decodeURI()])
    // });

  }

  openSearch() {
    this.toggleSearch = true;
    this.searchbar.nativeElement.focus();
  }
  searchClose() {
    this.searchText = '';
    this.toggleSearch = false;
  }
  editEmployeeProfileDialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "100%";
    dialogConfig.panelClass = "dialog-class";
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(EditProfilesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  markAttendance() {
    // this.leaveAttandance.onMonthYearChange();
    this.checkAttendanceStatus();
    if (!this.disableState) {
      this.data.createPayload(new Date(), AttendanceStatus.PRESENT, this.userDetatilsService.getUserDetails().id)
      this.attendanceService.SendAttendance(this.data).subscribe(res => {
        this.disableState = true;
        this.mark_Attendance = false;
        this.attendance_Marked = true;
        this.notificationService.sucess("Your Attendance is marked Successfully")
      })
    }
    this.disableState = true;
  }
  ngAfterViewInit(): void {
    this.observer.observe(['(max-width: 590px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close()
      }
      else {
        this.sidenav.mode = 'side';
        this.sidenav.open()
      }
    })
    // logOut() {
    //   this.authguardService.signOut()
    // }
  }
  logOut() {
    this.authguardService.signOut()
  }
  toggleMenu() {
    this.opened = !this.opened;
  }
}