import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddHolidaysURLsService {
  private readonly updateLeave  = '/api/single-import-leaves';
  private readonly editLeave = '/api/update-leaves-by-ID';

  constructor() { }
  getBaseUrl(){
    return this.updateLeave;
  }

  updateLeavesByYear(year){
    return this.updateLeave;
  }

  editLeavesById(year,Id){
    return this.editLeave + '/' + year + '/' + Id ;
  }
}
