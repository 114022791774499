import { Injectable } from '@angular/core';
import { RestAPIService } from '../restAPI/rest-api.service';
import { ClientHolidaysURLsService } from '../restAPI/clientHolidaysURLs/client-holidays-urls.service';


@Injectable({
  providedIn: 'root'
})
export class ClientHolidayService {

  constructor(public clientHoliday : ClientHolidaysURLsService,
    public restApiService : RestAPIService) { }

  getHolidaysByIdandYear(clientId,Year,leaveType){
    return this.restApiService.get(
      this.clientHoliday.getLeavesByClientIdAndYear(clientId,Year,leaveType)
    )
  }

  getBothHolidaysbyyear(year){
    return this.restApiService.get(
      this.clientHoliday.getGeneralandRestricted(year)
    )
  }
}
