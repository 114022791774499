<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Account Details</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>

    </div>
</div>
<hr>
<div class="container-fluid">
    <div class="row">

        <div class="col-md-12 ">
            <form [formGroup]="editVaultDetails">
                <div class="row mt-3">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Account Type</mat-label>
                            <input matInput formControlName="accountType" autocomplete="off">
                            <mat-error
                                *ngIf="editVaultDetails.get('accountType').hasError('noOnlySpecialCharacters')">Invalid
                                characters</mat-error>
                            <mat-error *ngIf="checkError('accountType', 'required')">Required</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Bank Name * <span class="contentRed"></span></mat-label>
                            <mat-error *ngIf="checkError('bankName', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('bankName', 'pattern')">Enter valid bank
                                name</mat-error>

                            <input matInput formControlName="bankName" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>UAN - 12-Digit Number *<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="uanNumber" autocomplete="off">                          
                            <mat-error *ngIf="checkError('uanNumber', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('uanNumber', 'pattern')">Enter valid UAN number</mat-error>

                        </mat-form-field>
                    </div>



                </div>
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>IFSC Code </mat-label>
                            <input matInput formControlName="ifsc" autocomplete="off">

                            <mat-error *ngIf="checkError('ifsc', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('ifsc', 'pattern')">Enter valid IFSC code</mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Account Number *<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="accountNumer" autocomplete="off">

                            <mat-error *ngIf="checkError('accountNumer', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('accountNumer', 'pattern')">Enter valid Account
                                number</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>PF Number *<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="pfNumber" autocomplete="off">
                            <mat-error *ngIf="checkError('pfNumber', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('pfNumber', 'pattern')">Enter valid PF number</mat-error>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>PAN Card Number<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="pancardNumber" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                                autocomplete="off">
                            <mat-hint class="pan-example">
                                <label>
                                    E.g. : ABCDE1234F
                                </label>
                            </mat-hint>
                            <mat-error *ngIf="checkError('pancardNumber', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('pancardNumber', 'pattern')">Enter valid PAN card
                                number</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 certificate">
                        <img class="img_size" src="assets/Certificate.png">
                        <label *ngIf="!isPanSaved" for="my_file" class="certificate_label">Attach PAN Card Copy</label>


                        <input *ngIf="!isPanSaved" type="file" id="my_file" style="display: none;" multiple
                            accept=".png, .jpg, .pdf" (change)="uploadpan($event)" #fileInput />


                        <ng-container *ngIf="isPanSaved ; else elseBlock">
                            <label class="certificate_label" (click)="open(content5)">
                                Preview PAN Card Copy</label>

                            <button class="remove_label" (click)="removeskill()">Remove</button>
                        </ng-container>
                        <ng-template #content5 let-modal>
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="modal-body">
                                <pdf-viewer *ngIf="this.panDocumentType=='application/pdf' " [src]="this.temp"
                                    [rotation]="0" [fit-to-page]="false" [original-size]="false" [show-all]="true">
                                </pdf-viewer>
                                <img *ngIf="this.panDocumentType == 'image/png' || this.panDocumentType == 'image/jpeg'"
                                    class="preview-img" [src]="this.temp">
                            </div>
                        </ng-template>
                        <ng-template #elseBlock>
                            <!-- <p class="certificate_label">No file Selected</p> -->
                        </ng-template>
                    </div>


                </div>


                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>