import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Departments, ProjectRole, ProjectCategory, ProjectType } from 'src/app/services/user/user-enum';
import { ClientService } from 'src/app/services/client/client.service';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { Talent } from 'src/app/models/talent';
import { TalentService } from 'src/app/services/talent/talent.service';
import { UserService } from 'src/app/services/user/user.service';
import { ProjectService } from 'src/app/services/project.service';
import { Project } from 'src/app/models/project';
import { MainContentProjectComponent } from '../main-content-project/main-content-project.component';
import { PipeUnderScoreToSpace } from 'src/app/services/pipes/pipe-underscore-to-space';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { SpecifiInfoUser } from 'src/app/models/SpecificInfoUser';
import { User } from 'src/app/models/user';
@Component({
  selector: 'app-add-project-card',
  templateUrl: './add-project-card.component.html',
  styleUrls: ['./add-project-card.component.scss'],
  providers: [PipeUnderScoreToSpace]
})
export class AddProjectCardComponent implements OnInit {
  projectCategory = [];
  projectType = [];
  departments = [];
  projectid: string;
  newProjectDetails: FormGroup;
  skills: FormArray;
  talents: FormArray;
  clientList;
  userList;
  userName;
  userID;
  talentdto=new Talent();
  projectDto = new ProjectDTO();
  project = new ProjectDTO()
  removedTalentList = [];
  userDetailService: any;
  userNameIDMapping = {};
  projectRoleList = [];
  skillget = [];
  skillFormArray: FormArray
  username;
  allProjectCodes = [];
  talentList = [];
  releaseTalent = [];
  filteredCareerManager: Observable<any[]>;
  emailError: string;
  
  isSaveClicked: boolean = false;
  noOnlySpecialCharactersValidator():ValidatorFn {
    
   
    const onlySpecialCharsRegex = /^[!#$%^&*(),.<>?":@?\{}|<_ /]+$/;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as string;

    if (onlySpecialCharsRegex.test(value)) {
      return { noOnlySpecialCharacters: true };
    }

    return null;
  };
  }
  errorMessage:string;


  constructor(public dialogRef: MatDialogRef<MainContentProjectComponent>,
    public fb: FormBuilder,
    private clientService: ClientService,
    private userService: UserService,
    private talentservice: TalentService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) public projects) {
    this.newProjectDetails = this.fb.group({
      projectName: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]],
      projectCode: ['', [Validators.required, this.noOnlySpecialCharactersValidator()]],
      projectDescription: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]],
      projectCategory: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      capability: ['', [Validators.required]],
      client: ['', [Validators.required]],
      projectManager: ['', [Validators.required,this.noOnlySpecialCharactersValidator()]],
      projectType: ['', [Validators.required]],
    });
  }

  checkLBandLAerrorFcn() {
    const endDateControl = this.newProjectDetails.get('endDate');
    const startDateControl = this.newProjectDetails.get('startDate');
    const startDate1 = this.newProjectDetails.get('startDate').value;
    const endDate1 = this.newProjectDetails.get('endDate').value;
  
    if ((startDate1!=null && endDate1!=null) && (startDate1 > endDate1 )) {
      endDateControl.addValidators([Validators.pattern('[@]$')]);
      this.errorMessage = "End date should be greater than Start date";
      //this.submitButtonDisabled = true;
    }
    else {
      endDateControl.clearValidators();
      endDateControl.setValidators([Validators.required]);
      // this.submitButtonDisabled = false;
    }
    endDateControl.updateValueAndValidity();
  }
  
  checkError = (controlName: string, errorName: string) => {
    return this.newProjectDetails.controls[controlName].hasError(errorName);
  }
  setFormData() {
    
    this.projectService.getProjects();
    this.getclientList();
    this.getuserList();
    if (this.projects) {
      this.gettalentList();
      
      this.userService.getUserByID(this.projects.managerId).subscribe(res=>{
        
        this.userName=res.body.firstName +" "+res.body.lastName
        this.newProjectDetails.get('projectManager').setValue(new User(res.body));
      })
     
      
      this.newProjectDetails.get('projectName').setValue(this.projects.projectName ? this.projects.projectName : '');
      this.newProjectDetails.get('projectDescription').setValue(this.projects.projectDescription ? this.projects.projectDescription : '');
      this.newProjectDetails.get('projectCode').setValue(this.projects.projectCode ? this.projects.projectCode : '');
      this.newProjectDetails.get('capability').setValue(this.projects.department ? this.projects.department : '');
      this.newProjectDetails.get('projectCategory').setValue(this.projects.projectCategory ? this.projects.projectCategory : '');
      this.newProjectDetails.get('startDate').setValue(this.projects.startDate ? this.projects.startDate : '');
      this.newProjectDetails.get('endDate').setValue(this.projects.endDate ? this.projects.endDate : '');
      this.newProjectDetails.get('client').setValue(this.projects.clientId ? this.projects.clientId : '');
      this.newProjectDetails.get('projectType').setValue(this.projects.projectType ? this.projects.projectType : '');
    }
  }
  ngOnInit() {
    
    this.processEnumValuesToArray();
    // this.updateFormStatus();
    this.setFormData();
    this.filteredCareerManager = this.newProjectDetails.get('projectManager').valueChanges.pipe(
      startWith(''),
      map(state => this.filterManager(state))
    )
    this.displayFnCareerManager(new UserDTO);

  }
  displayFnCareerManager(user): string {
    
    return user && (user.firstName || user.lastName) ? user.firstName + ' ' + user.lastName : '';
  }
  filterManager(name: string) {
    
    if (typeof name !== 'object')
      return this.userService.usersDtoForCMTBList.filter(user =>
        user.firstName.toLowerCase().indexOf(name.toLowerCase()) ===0 ||
        user.lastName.toLowerCase().indexOf(name.toLowerCase()) ===0)
            else
      return
  }
  ngOnchanges(){
    this.setFormData()
  }
  processEnumValuesToArray() {
    for (let department of Object.values(Departments)) {
      if (isNaN(Number(department)))
        this.departments.push(department);
    }
    for (let projectRole of Object.values(ProjectRole)) {
      if (isNaN(Number(projectRole)))
        this.projectRoleList.push(projectRole);
    }
    for (let projectCat of Object.values(ProjectCategory)) {
      if (isNaN(Number(projectCat)))
        this.projectCategory.push(projectCat);
    }
    for (let projectTyp of Object.values(ProjectType)) {
      if (isNaN(Number(projectTyp)))
        this.projectType.push(projectTyp);
    }
  }
  getclientList(){
    this.clientService.getClientsSubscribe().subscribe(cliList => {
      this.clientList = cliList.body;
    });
  }
  getuserList(){
    
    this.userService.getAllUserForDropDown().subscribe(usrData => {
      
      this.userService.setUsersToPopulateCareerManagerAndTalentBuddyLists(usrData.body)

      usrData.body.forEach(element => {
        

        this.userNameIDMapping[element.firstName + " " + element.lastName] = element.userID;
      });
      this.userList = Object.keys(this.userNameIDMapping);
    });
  }
  gettalentList(){
    this.talentservice.getTalentByProjectIdSubscribe(this.projects.id).subscribe(res => {
      this.talentList = res.body;
      });
  }
  getProjectCode() {
    this.projectService.getProjects();
    let projectList: Array<Project> = this.projectService.projects
    projectList.forEach(i => { this.allProjectCodes.push(i.projectCode) })
  }
  setFormControlError(
    controlName: string,
    controlError: string,
    errorMsg: string
  ) {
    this.newProjectDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.newProjectDetails.get(controlName).markAsDirty({ onlySelf: true });
    this.newProjectDetails.get(controlName).setErrors({ [controlError]: true });
    this.emailError = errorMsg;
  }
  getUserID(username) {
    
    this.userID = this.userNameIDMapping[username];
  }
  onProjectCodeTextTyped(event) {
    if (
      this.allProjectCodes.includes(this.newProjectDetails.get('projectCode').value)) {
      this.setFormControlError('projectCode','InvalidProjectCode','Project code Already Exist');
    }
  }
  save() {
    // this.isSaveClicked = true;
    if(this.newProjectDetails.valid){
    this.projectDto.projectName = this.newProjectDetails.get('projectName').value;
    this.projectDto.projectCode = this.newProjectDetails.get('projectCode').value;
    this.projectDto.projectDescription = this.newProjectDetails.get('projectDescription').value;
    this.projectDto.startDate = this.newProjectDetails.get('startDate').value;
    this.projectDto.endDate = this.newProjectDetails.get('endDate').value;
    this.projectDto.department = this.newProjectDetails.get('capability').value;
    this.projectDto.projectCategory = this.newProjectDetails.get('projectCategory').value;
    this.projectDto.projectType = this.newProjectDetails.get('projectType').value;
    this.projectDto.clientId = this.newProjectDetails.get('client').value;
    this.projectDto.managerId=this.newProjectDetails.get('projectManager').value.userID;
    this.userID=this.projectDto.managerId;
    if(this.userID!=null){
      this.talentdto.userId=this.userID
     this.userService.getUserByID(this.userID).subscribe(res=>{
      this.talentdto.talentName=res.body.firstName+ " " +res.body.lastName
      })
    }
      if (this.projects!=null)
      { 
        
        if(this.projects.managerId){
         this.talentservice.gettalentbyuserIdandprojectid(this.projects.id,this.projects.managerId).subscribe(res=>{
          if(this.userID==null)
          {this.talentdto.userId=this.projects.managerId
          this.talentdto.talentName=res.body.talentName}
          this.talentdto.allocationTime=res.body.allocationTime
          this.talentdto.projectId=this.projects.id
          this.talentdto.id=res.body.id
          this.talentdto.role= 'PROJECT_MANAGER'
          this.talentservice.update(this.talentdto).subscribe(res=>{
          })
         })
        }
        this.projectDto.id = this.projects.id
      this.projectService.updateProjectSubscribe(this.projectDto).subscribe(res => {
        this.projectService.getProjects();
        this.dialogRef.close()
      })
    }
    else {
      
      this.projectService.postProjectData(this.projectDto).subscribe(res => {
        
        let temp=this.newProjectDetails.get('projectManager').value;
        let name =temp.firstName+" "+temp.lastName
        this.talentdto.userId=this.userID
        this.talentdto.projectId=res.body.id
        this.talentdto.talentName=name;
        this.talentdto.role="PROJECT_MANAGER"
        this.talentservice.create(this.talentdto).subscribe(res=>{
        })
        this.projectService.getProjects();
        this.dialogRef.close();
      });
    }
  }else{

    this.newProjectDetails.markAllAsTouched();
  }
  }
  closeDialog() {
    this.dialogRef.close()
  }
}
