import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientDTO } from 'src/app/dtos/ClientDTO';
import { Activity } from 'src/app/services/activity/activity-enum';
import { ClientService } from 'src/app/services/client/client.service';
import { ProjectService } from 'src/app/services/project.service';
import { TalentService } from 'src/app/services/talent/talent.service';
import { TransferDetailsService } from 'src/app/services/transferDetails/transfer-details.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddTalentComponent } from '../../project/project-detail-view/add-talent/add-talent/add-talent.component';
import { AddEditClientComponent } from '../add-edit-client/add-edit-client.component';

@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styleUrls: ['./view-client.component.scss'],
})
export class ViewClientComponent implements OnInit {
  clientDto= new ClientDTO();
  isDataPresent=false;
  temp=[];
  
  constructor(
    public projectService: ProjectService,
    public talentservice: TalentService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public transferDetails: TransferDetailsService,
    private router: Router,
    public clientService: ClientService,
    private userDetailService: UserDetailsService,
    public userService: UserService
    
  ) {}
  public clientData
  nodata: boolean = true;
  projectList = [];
  public getScreenWidth: any;
  public getScreenHeight: any;
  mobileview: boolean = false;
  clientIdName = {};
  userIdName = {};
  public projectId;
  projectidtalent = {};
  public talentData = [];
  clientName;
  map=new Map<Number,String[]>();
  public flag:any=[];
  client_id = this.route.snapshot.params.id;
  positionOptions: TooltipPosition[] = ['below'];
  position = new FormControl(this.positionOptions[0]);
  
  todayDate= new Date().toISOString()
  ngOnInit(): void {
    
    this.callfunc();
    this.clientService
      .getClientByIdSubscribe(this.client_id)
      .subscribe((res) => {
        
        this.clientDto = res.body;
    
      });
     
         this.getAllProjects();
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 768) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 768) {
      this.mobileview = true;
    }
    
  }
  callfunc() {
    
    this.projectService.getProjectPagination();
    this.projectService.getAllProjects().subscribe((response) => {
      if(response.length!==0)
        this.isDataPresent=true;
      if (this.projectService.projects.length != 0) {
        this.nodata = false
      }
      let  j=0
      for (let r in response) {
        
        if(this.userDetailService.getUserDetails().authorities=="ROLE_SUPER_ADMIN"||"ROLE_PROJECT_ADMIN"){
           this.projectList[r] = response[r];
        }
        this.clientService.getClientByIdSubscribe(response[r].clientId).subscribe(resp => {
          this.clientName = resp.body.clientName;
          this.clientIdName[response[r].clientId] = this.clientName;
          this.transferDetails.saveIds(resp.body)
        })
        this.talentservice.getTalentByProjectIdSubscribe(response[r].id).subscribe(res => {
          
          
          this.projectidtalent[response[r].id] = res.body.length;
          this.temp=[]
          for(let i of res.body)
          {
            //this.projecttalentname[j]=i.talentName;j++;
            this.temp.push(i.talentName);
          }
          this.map.set(response[r].id,this.temp);
          j++;
        });
      }
    });
  }
  onViewProject(project) {
    this.router.navigate(['project/projectDetailView', project.id]);
  }
  addTalent(project) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      projectId: project.id,
      activityType: Activity.ADD,
      endDate:project.endDate
    }
    const dialogRef = this.dialog.open(AddTalentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getTalent()
    });
  }
  getTalent(){
    this.flag.length=0;
  let talentlocal=[]
    this.talentservice.getTalentByProjectIdSubscribe(this.projectId).subscribe(resptalent => {
      let j=0;
      for (let i of resptalent.body) {
        talentlocal.push(i)
        let toDaysDate=new Date();
        let endDateInZoneFormat=new Date(i.endDate);
        if((endDateInZoneFormat)<(toDaysDate)){
          this.flag.push(false);
        }
        else{
          this.flag.push(true)
        }
        this.userService.getUserByID(i.userId).subscribe(response => {
          this.userIdName[i.userId] = response.body.employeeId;
        })
      }
      this.talentData=talentlocal;
    });
  }
 
  getAllProjects() {
    const _id = this.route.snapshot.params.id;
    this.projectService.getprojectfromclient(_id).subscribe((response) => {
      if (response.body?.length != 0) {
        this.nodata = false;
      }

      this.projectList = response.body;
      for (let res in this.projectList) {
        this.talentservice
          .getTalentByProjectIdSubscribe(this.projectList[res].id)
          .subscribe((r) => {
            this.projectidtalent[this.projectList[res].id] = r.body.length;
          });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 768) {
      this.mobileview = false;
    }
    if (this.getScreenWidth < 768) {
      this.mobileview = true;
    }
  }


  AddEditClientDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.height = '80%';
    dialogConfig.data = this.clientDto;
    const dialogRef = this.dialog.open(AddEditClientComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      // 
      this.ngOnInit()
    });
  }
}
