import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectService } from 'src/app/services/redirect.service';
import { TaskService } from 'src/app/services/task.service';
import { TaskStatus } from 'src/app/services/task/task-enums';
import { UserDetailsService } from 'src/app/services/user-details.service';



@Component({
  selector: 'app-my-task',
  templateUrl: './my-task.component.html',
  styleUrls: ['./my-task.component.scss']
})
export class MyTaskComponent implements OnInit {
  index:number;
  taskStatus=[0,0,0,0]

  constructor( private router:Router,private add:RedirectService, private taskService: TaskService, private userDetailService:UserDetailsService) { }

  ngOnInit(): void {
    this.taskService.getAllByUserId(this.userDetailService.getUserDetails().id).subscribe(res=>{
      for (let i of res){
        if(i.taskStatus==TaskStatus.PENDING){
          this.taskStatus[0]++
        }
        else if(i.taskStatus==TaskStatus.INPROGRESS){
          this.taskStatus[1]++
        }
        else if(i.taskStatus==TaskStatus.OVERDUE){
          this.taskStatus[2]++
        }
        else if(i.taskStatus==TaskStatus.COMPLETED){
          this.taskStatus[3]++
        }

      }
    })
  }
  navigate(value) {
    this.index=value
    this.add.getIndex(value)
    this.router.navigate(['/task']);
}

}
