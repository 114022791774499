import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CmReporteesComponent } from '../components/career-manager/cm-reportees/cm-reportees.component';

import { NewFeedbackDTO } from '../dtos/newFeedbackDTO';

import { FeedbackgroupUrlService } from './restAPI/feedbackGroupURLs/feedbackgroup-url.service';
import { RestAPIService } from './restAPI/rest-api.service';
import { G } from '@angular/cdk/keycodes';
@Injectable({
  providedIn: 'root'
})
export class NewFeedbackGroupService {
  private data = new BehaviorSubject<CmReporteesComponent>(null);
  constructor(private restApiService: RestAPIService,
    private feedbackgroupurl: FeedbackgroupUrlService) { }
  create(payload) {
    return this.restApiService.post(
      this.feedbackgroupurl.getBaseUrl(),
      payload
    )
  }

  delete(id) {
    return this.restApiService.delete(
      this.feedbackgroupurl.delete(id))
  }
  closeFeedBack(userId, year) {
    return this.restApiService.post(
      this.feedbackgroupurl.closeFeedBack(userId, year), null)
  }
  closeFeedBackSaveNote(userId, year, saveAsDraft, feedbackNote) {
    return this.restApiService.post(
      this.feedbackgroupurl.closeFeedBackSaveNote(userId, year, saveAsDraft),
      feedbackNote
    )
  }

  //for probation
  closeProbationFeedBackSaveNote(userId, year, saveAsDraft, feedbackNote) {
    return this.restApiService.post(
      this.feedbackgroupurl.closeProbationFeedBackSaveNote(userId, year, saveAsDraft),
      feedbackNote
    )
  }
  getProbationFeedbackOverallstatus(userId, year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getProbationFeedbackOverallstatus(userId, year)
    )
  }
  getFeedbackOverallstatus(userId, year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getFeedbackOverallstatus(userId, year)
    )
  }
  // updateCalibratedScore(groupId,id,calibratedScore,weight,payload){
  //   return this.restApiService.put(this.feedbackgroupurl.updateCalibratedScore(groupId,id,calibratedScore,weight),payload)
  // }

  updateCalibratedScore(groupId, feedbackArray) {
    return this.restApiService.put(this.feedbackgroupurl.updateCalibratedScore(groupId), feedbackArray)
  }

  private _listners = new Subject<any>();
  listen(): Observable<any> {
    return this._listners.asObservable();
  }
  filter(filterBy: string) {
    this._listners.next(filterBy);
  }
  update(payload, id, weightage) {
    return this.restApiService.put(
      this.feedbackgroupurl.updateUrl(id, weightage),
      payload
    )
  }
  getfeedbackbyuserandyear(userid, year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getfeedbackbyuserandyear(userid, year)
    )
  }
  getProbationeedbackbyuser(userid, year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getProbationeedbackbyuser(userid, year)
    )
  }
  getAllFeedback(year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getAllFeedback(year)
    )
  }
  getAllProbationFeedback(year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getAllProbationFeedback(year)
    )
  }
  getdirectreporteebyyear(year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getdirectreporteebyyear(year)
    )
  }

  getdirectreporteebyuserId(userid, year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getdirectReporteebyUserId(userid, year)
    )
  }
  getdirectreporteebyyearforHr(year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getdirectreporteebyyearforHr(year)
    )
  }
  getProbationdirectreporteebyyearforHr(year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getProbationdirectreporteebyyearforHr(year)
    )
  }
  setopinionResponse(response): NewFeedbackDTO[] {
    let opList: NewFeedbackDTO[] = []
    for (let data of response) {
      let opinion: NewFeedbackDTO = new NewFeedbackDTO()
      opList.push(
        opinion.bind(data)
      )
    }
    return opList;
  }
  private refreshClose = new Subject<any>();
  getRefresh(): Observable<any> {
    return this.refreshClose.asObservable();
  }
  setRefresh(filterBy: string) {
    this.refreshClose.next(filterBy);
  }
  getAllCmReport(geoLocation, year) {
    return this.restApiService.get(
      this.feedbackgroupurl.getAllCmReport(geoLocation, year)
    )
  }

  // add real time feedback

  setRealTimeFeedback(groupId, feedbackList){
    debugger  
    let path =this.feedbackgroupurl.setRealTimeFeedback(groupId)
    return this.restApiService.put(path,
    feedbackList)
  }
}