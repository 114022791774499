<div class="col-md-12 pl-0 pr-0">
    <mat-card class="mat_card_leave">
        <div class="search_main_leave ">
            <mat-icon class="search_icon_leave">search</mat-icon>
            <input class="search_input_leave" type="search" placeholder="Search" [(ngModel)]="searchText" value="">
        </div>
    </mat-card>
</div>

<div class="card-container-lm">
    <div class="no-leave" *ngIf="this.leaveApplicationsList.length==0">
        No Leave Applications.
    </div>
    <mat-card class="matcardleavedetail"
        *ngFor="let leave of this.leaveApplicationsList  | filter:searchText| orderBy:'startDate' | paginate: {id:'leave-approve-pagination', itemsPerPage: 6, currentPage: page }; index as i">
        <mat-card-content>
            <div>
                <div class="row first-row">
                    <!-- <div class="col">  </div> -->
                    <div class="col">
                        <img class="noemployee_img" src="assets/default-avatar.jpg" alt="">
                    </div>
                    <div class="col">
                        <h5 class="leave-detail-name">{{leave.name | titlecase}}</h5>
                        <p class="leave-detail-content">{{leave.empId | uppercase}} </p>
                    </div>
                    <div class="col">
                        <div class="leavetype-container">
                            <h5 class="leave-detail-name">Leave Type </h5>
                        </div>
                        <p class="leave-detail-content">{{leave.leaveType | underScoreToSpace | titlecase}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h5 class="leave-detail-name">Start Date </h5>
                        <p class="leave-detail-content">{{leave.startDate | date: "dd-MM-yyyy"}}</p>
                    </div>
                    <div class="col">
                        <h5 class="leave-detail-name">End Date </h5>
                        <p class="leave-detail-content">{{leave.endDate | date: "dd-MM-yyyy"}}</p>
                    </div>
                    <div class="col">
                        <h5 class="leave-detail-name">Number Of Days</h5>
                        <p class="leave-detail-content number-of-days">{{leave.noOfDays}}</p>
                    </div>
                </div>
                <div class="row reason-row">
                    <div class="col-md" *ngIf="leave.reason!=''">
                        <h5 class="leave-detail-name">Reason Leave</h5>
                        <p class="leave-detail-content reasonLeave">{{leave.reason}}</p>
                    </div>
                    <div class="col-md" *ngIf="leave.reason==''">
                        <h5 class="leave-detail-name">Reason Leave</h5>
                        <p class="leave-detail-content reasonLeave">No Reason Provided.
                        </p>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col">
                        <mat-icon><img class="edit_logo_lm" src="assets/DoctorPrescription.png"></mat-icon>
                        <label class="leave-prescription" *ngIf="leave.attachment" (click)="open(content5)">Attachment </label>
                        <label class="leave-prescription" *ngIf="!leave.attachment">No Attachment </label>
                        <mat-icon class='arrow-icon'>arrow_drop_down</mat-icon>
                    </div>
                    <ng-template #content5 let-modal>
                        <button type="button" class="close" aria-label="Close"
                            (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        
                    </ng-template>
                </div> -->

                <div class="row">
                    <div class="col">
                        <mat-icon><img class="edit_logo_lm" src="assets/DoctorPrescription.png"></mat-icon>
                        <ng-container *ngIf="leave.attachment">
                            <button class="leave-prescription" (click)="open(content10, leave.attachment)">
                                <label class="leave-prescription attachment-curser">View Attachment</label></button>
                                <ng-template #content10 let-modal>
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div class="modal-body">
                                      <div *ngIf="leave.attachmentType == 'application/pdf'">
                                        <pdf-viewer [src]= "leave.attachment" [rotation]="0" [fit-to-page]="false"
                                          [original-size]="false" [show-all]="true" class="pdf-attachment"></pdf-viewer>
                                          <!-- <ngx-extended-pdf-viewer [src]="leave.attachment" [height]="'100%'" [width]="'100%'"></ngx-extended-pdf-viewer> -->
                                      </div>
                                      <div *ngIf="leave.attachmentType === 'image/png' || leave.attachmentType === 'image/jpeg'">
                                        <img class="preview-img" [src]="leave.attachment">
                                      </div>
                                    </div>
                                  </ng-template>
                        </ng-container>
                        <ng-container *ngIf="!leave.attachment">
                            <label class="leave-prescription">No Attachment</label>
                        </ng-container>
                        <mat-icon class='arrow-icon'>arrow_drop_down</mat-icon>
                    </div>
                    
                    

                </div>
               




                <div class="row revoke-btn-container">
                    <div class="col reject-div">
                        <button class="reject-btn" (click)="revokeBox(leave)">Reject</button>
                    </div>

                    <div class="col revoke-div">
                        <button class="revoke-btn" (click)="approveLeave(leave)">Approve</button>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>


</div>

<pagination-controls id="leave-approve-pagination" class="pagination" (pageChange)="page = $event">
</pagination-controls>