<!-- Author: Mayur Madhekar -->
<div class="container-fluid">
    <div class="main_header_div">
        <div class="child">
            <h1 class="header_name">Bulk Import Summary</h1>
        </div>
        <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
            <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>

        </div>
    </div>
    <hr />

    <!-- Show bulk import summary in table form in a mat-dialog popup -->


    <div class="row form_main_div">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4">
                    <label class="imports_title">Number of Successful Imports</label>
                    <br>
                    <span class="input_data mt-1">{{data.Successful}}</span>
                </div>
                <div class="col-md-4">
                    <label class="imports_title">Number of Unsuccessful Imports</label>
                    <br>
                    <span class="input_data mt-1">{{data.Failed}}</span>
                </div>
            </div>
            <!-- <div class="row mt-4" >
                <div class="col-md-4">
                    <label class="imports_title">Already Existing Users</label>
                    <br>
                    <span class="input_data mt-2">{{data.Already_Existing_Email_Ids}}</span>
                </div>
                <div class="col-md-4">
                    <label class="imports_title">Invalid Career Manager IDs</label>
                    <br>
                    <span class="input_data mt-2">{{data.Invalid_CM_Email_Ids}}</span>
                </div>
                <div class="col-md-4">
                    <label class="imports_title">Invalid Talent Buddy Email IDs</label>
                    <br>
                    <span class="input_data mt-2">{{data.Invalid_TB_Email_Ids}}</span>
                </div>
            </div> -->
        </div>
    </div>
</div>
