export class UserDocumentDTO {
   public id : String
    public documentName: string;
    public documentBase :string
    public document: BinaryType;
    public documentContentType: string;
    public creationDate:Date
    public userId :string
    constructor() {
        this.document = null;
        this.userId="";
    }
}     