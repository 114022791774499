import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { EmployeeReviewService } from 'src/app/services/employeeReview/employee-review.service';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { OneOnOneMeetingService } from 'src/app/services/oneOnOneMeeting/one-on-one-meeting.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { Router } from '@angular/router';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { UserGoalService } from 'src/app/services/usergoal/user-goal.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReopenedConfirmationScreenComponent } from 'src/app/reopened-confirmation-screen/reopened-confirmation-screen.component';
import { ToastrService } from 'ngx-toastr';
import { NoDataAvailableComponent } from './no-data-available/no-data-available.component';

@Component({
  selector: 'app-calibrated-report',
  templateUrl: './calibrated-report.component.html',
  styleUrls: ['./calibrated-report.component.scss']
})
export class CalibratedReportComponent implements OnInit {
  public employeeReviewCompleted: boolean = false;
  userdto;
  p: number = 1;
  itemsPerPage = 8;
  searchText: string;
  public getScreenWidth: any;
  public getScreenHeight: any;
  selectedYear: number;
  nodata: Boolean = true
  selected;
  hide: boolean = true;
  years: number[] = [];
  mobileview: boolean = false
  excelArray = [];
  excelArrayOpen = [];
  excelArrayClosed = [];
  t1 = []
  t2 = []
  t3 = []
  hasHrRole: boolean
  index = 0;
  reportingManager: any;
  allEmployeeIds = {};

  constructor(public userService: UserService,
    public userDetailService: UserDetailsService,
    public initialAvatarService: InitialAvatarService,
    public employeeReviewService: EmployeeReviewService,
    public oneOnOneMeetingService: OneOnOneMeetingService,
    public feedbackService: FeedbackService,
    public userDetailsService: UserDetailsService,
    public feedbackgroupservice: NewFeedbackGroupService,
    public userGoalService: UserGoalService,
    private router: Router,
    public toastr: ToastrService,
    private feedbackgroup: NewFeedbackGroupService,
    private dialog: MatDialog,

  ) { }
  ngOnInit(): void {

    this.selected = new Date().getFullYear();
    this.selectedYear = new Date().getFullYear();

    // this.userGoalService.setCurrentYear(this.selected);
    for (let year = this.selectedYear; year >= 2015; year--) {
      this.years.push(year);
    }
    this.getAllEmployeeForCalibratedScore();
    this.getAllEmployees();
  }

  ngAfterViewChecked(): void {
    this.itemsPerPage = 10;
    if (window.innerWidth > 960) {
      this.itemsPerPage = 6;
    }
    if (window.innerWidth > 1187) {
      this.itemsPerPage = 9;
    }
    if (window.innerWidth > 1530) {
      this.itemsPerPage = 12;
    }
    if (window.innerWidth > 1877) {
      this.itemsPerPage = 15;
    }
  }
  onViewReportee(id) {

    this.feedbackgroup.getfeedbackbyuserandyear(id, this.selected).subscribe(res => {

      if (res.body.length > 0) {
        this.feedbackService.setIndex(1);
        this.router.navigate(['profiledetail', id]);

      }
      else {
        // this.feedbackService.setIndex(1);
        this.router.navigate(['profiledetail', id]);
        // this.noData();
      }
    })
  }
  processDesignation(user) {
    let designation;
    if (user && user.firstName)
      return designation = user.jobTitle
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 800) {
      this.mobileview = false
    }
    // if (this.getScreenWidth < 800) {
    //   this.mobileview = true
    // }
  }
  loadForm(event) {
    this.userGoalService.setCurrentYear(event.value);
    this.t1.length = 0; this.t2.length = 0; this.t3.length = 0; this.userdto.length = 0;
    this.excelArray.length = 0; this.excelArrayClosed.length = 0; this.excelArrayOpen.length = 0;
    this.getAllEmployeeForCalibratedScore();
  }

  getAllEmployees() {
    this.userService.getAllUsers().subscribe(res => {
      for (let i in res.body) {
        this.allEmployeeIds[res.body[i].id] = res.body[i];
      }
    })
  }

  downloadAllExcel() {
    this.feedbackgroup.getAllFeedback(this.selected).subscribe(async res => {

      let first_row_of_excel = [];
      first_row_of_excel.push('Sr.No.');
      first_row_of_excel.push('Employee Number');
      first_row_of_excel.push('Job Title');
      first_row_of_excel.push('Department');
      first_row_of_excel.push('Reviewer Name');
      first_row_of_excel.push('Reviewer Type Name');
      first_row_of_excel.push('Question');
      first_row_of_excel.push('Rating');
      first_row_of_excel.push('Performance Comments');
      let excelArrayForAllExport = []
      excelArrayForAllExport.push(first_row_of_excel);
      let reportingManager = 1;

      for (let i of res.body) {
        let employee: any = this.allEmployeeIds[i.feedbackFor];

        for (let feedback of i.feedbackFrom) {
          console.log(feedback.feedbackFrom)
          var submissions = JSON.parse(feedback.submission);
          if (submissions != null) {
            for (let submission of submissions) {
              if (submission.hasOwnProperty('attributes')) {
                for (let submissionAttributes of submission.attributes) {
                  let excelrow = [];
                  excelrow.push(reportingManager++);

                  // excelrow.push(this.getHash(employee.firstName + " " + employee.lastName));
                  excelrow.push(employee.firstName + " " + employee.lastName);

                  let normalisedJobTitle = this.titleCase(employee.jobTitle.replaceAll("_", " "))

                  excelrow.push(normalisedJobTitle);

                  let normalisedDepartment = this.titleCase(employee.department.replaceAll("_", " "))
                  excelrow.push(normalisedDepartment);

                  let reviewer = this.allEmployeeIds[feedback.feedbackFrom];
                  // excelrow.push(this.getHash(reviewer.firstName + " " + reviewer.lastName));
                  excelrow.push( reviewer.firstName + " " + reviewer.lastName);

                  let normalisedFeedbackRole = this.titleCase(feedback.feedbackRole.replaceAll("_", " "))
                  excelrow.push(normalisedFeedbackRole);

                  excelrow.push(submissionAttributes.label);
                  excelrow.push(submissionAttributes.value);
                  excelrow.push(submissionAttributes.description);
                  excelArrayForAllExport.push(excelrow);
                }
              }
            }
          }
        }
      }

      var csvString = '';
      var temp;

      temp = excelArrayForAllExport;
      console.log(temp)

      for (let i = 1; i < temp.length; i++) {
        temp[i][0] = i;
      }
      temp.forEach((RowItem: any, RowIndex: any) => {
        RowItem.forEach((colItem: any, colIndex: any) => {


          csvString += "\"" + colItem + "\"" + ',';
        });
        csvString += '\r\n';
      });
      csvString = 'data:application/csv, ' + encodeURIComponent(csvString);
      var x = document.createElement('A');
      x.setAttribute('href', csvString);
      x.setAttribute('download', 'Employees_Rating_Report.csv');
      document.body.appendChild(x);
      x.click();
    })
  }

  getHash(reviewerNameOriginal) {
    let hash = 0
    for (let i = 0; i < reviewerNameOriginal.length; ++i) {
      let char = reviewerNameOriginal.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return hash
  }

  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }


  file() {

    var csvString = "";
    var temp;
    if (this.index == 0) {
      temp = this.excelArray;
    }
    else if (this.index == 1) {
      temp = this.excelArrayOpen;
    }
    else {
      temp = this.excelArrayClosed;
    }
    for (let i = 1; i < temp.length; i++) {

      temp[i][0] = i;
    }
    temp.forEach((RowItem: any, RowIndex: any) => {
      RowItem.forEach((colItem: any, colIndex: any) => {
        csvString += colItem + ',';
      }
      )
      csvString += "\r\n"
    }
    )
    csvString = "data:application/csv, " + encodeURIComponent(csvString);
    var x = document.createElement("A");
    x.setAttribute("href", csvString);
    x.setAttribute("download", "Calibrated_Report.csv")
    document.body.appendChild(x);
    x.click();
  }
  open() {

    this.userdto = this.t2;
    this.index = 1;
  }
  closed() {

    this.userdto = this.t3;
    this.index = 2
  }
  all() {
    this.userdto = this.t1;
    this.index = 0
  }
  reopened(userid, year) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    dialogConfig.data = [userid, year, false]
    const dialogRef = this.dialog.open(ReopenedConfirmationScreenComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'Success') {
        this.t1.length = 0; this.t2.length = 0; this.t3.length = 0; this.userdto.length = 0;
        this.excelArray.length = 0; this.excelArrayClosed.length = 0; this.excelArrayOpen.length = 0;
        this.getAllEmployeeForCalibratedScore();
      }
    });
  }
  getAllEmployeeForCalibratedScore() {

    this.feedbackgroupservice.getdirectreporteebyyearforHr(this.selected).subscribe(res => {

      let first_row_of_excel = []
      first_row_of_excel.push('Sr.No')
      first_row_of_excel.push('Emp.ID')
      first_row_of_excel.push('Employee Name')
      first_row_of_excel.push('Score')
      first_row_of_excel.push('Year')
      first_row_of_excel.push('Status')
      this.excelArray.push(first_row_of_excel)
      this.excelArrayOpen.push(first_row_of_excel)
      this.excelArrayClosed.push(first_row_of_excel)
      let c = 1;
      for (let i of res.body) {
        let excelArrayTemp = [];
        excelArrayTemp.push((c++))
        excelArrayTemp.push(i.employeeId);
        excelArrayTemp.push(i.firstName + " " + i.lastName);
        excelArrayTemp.push(i.totalScore)
        excelArrayTemp.push(this.selected);
        if (i.closed) {
          excelArrayTemp.push('Closed');
        }
        else {
          excelArrayTemp.push('Open');
        }
        this.excelArray.push(excelArrayTemp);
        if (!i.closed) {
          this.t2.push(i)
          this.excelArrayOpen.push(excelArrayTemp)
        }
        else {
          this.t3.push(i)
          this.excelArrayClosed.push(excelArrayTemp)
        }
        this.t1.push(i)

      }
      if (this.index == 0) {
        this.userdto = this.t1;
      }
      else if (this.index == 1) {
        this.userdto = this.t2;
      }
      else {

        this.userdto = this.t3;
      }
      if (res.body.length != 0) {
        this.nodata = false
      }
    });
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.getScreenWidth > 800) {
      this.mobileview = false
    }
    if (this.getScreenWidth < 800) {
      this.mobileview = true
    }

  }
  noData() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "500px";
    dialogConfig.height = "400px";

    const dialogRef = this.dialog.open(NoDataAvailableComponent, dialogConfig);
  }

}
