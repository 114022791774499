import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError,of, Subject, BehaviorSubject, merge} from 'rxjs';
import {catchError, finalize, retry,mergeMap,map, switchMap, concatAll, share, switchAll, mergeAll, tap} from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { StorageService } from './storage.service';
import { HandleErrorService } from './HandleErrorService';
import { SpinnerService } from './spinner.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification/notification.service';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";


@Injectable()
export class FgHttpInterceptor implements HttpInterceptor {

  baseUrl = environment.BASE_API_URL_BACKEND+'/'
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken = false;
  
  constructor(private error :HandleErrorService, 
    private notificationService:NotificationService,
    private loadingService: LoadingService,private http: HttpClient,
    private storageService:StorageService, private spinnerService: SpinnerService,
    private router: Router,
    private toastr: ToastrService){}
 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var subject = new Subject<any>();
    
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
           
            case 401:
              let splittedURL = request.url.split("/");
              splittedURL = splittedURL.reverse();
              
              if(splittedURL.indexOf('api') == 1 && splittedURL.indexOf('authenticate') == 0){
                return throwError(err);
              }
              else{
                  // return this.handle401Error(request, next,err);
                  localStorage.clear();
                  this.router.navigate(['/sessionTimeout']);
                  //this.toastr.info("Please re-login", "Session Expired");
                  break;
              }
              
            case 500:
              this.notificationService.error("Server Error");
              break;
            default:
              return throwError(err);
              break;
          }
        } else {
          return throwError(err);
        }
      })
    );
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler,error:HttpErrorResponse ){

 
    return this.error.handleError(error).pipe(mergeMap(res =>{
              this.storageService.setItem(StorageService.JWT_TOKEN,res.body.id_token)
              this.storageService.setItem(StorageService.REFRESH_TOKEN,res.body.refresh_token)      
              return next.handle(request)   
            })
            // , catchError(err => {
            //   console.log(err);
            //   localStorage.clear();
            //   this.router.navigate(['/login']);
            //   return next.handle(request)
            // })
            );
       
  } 
}