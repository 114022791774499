import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarOptions, EventInput, EventSourceInput } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Router } from '@angular/router';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { UserService } from 'src/app/services/user/user.service';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { OnLeaveReporteeComponent } from './on-leave-reportee/on-leave-reportee.component';
import { AttendanceService } from 'src/app/services/attendance-extra/attendance.service';

@Component({
  selector: 'app-cm-reportee',
  templateUrl: './cm-reportee.component.html',
  styleUrls: ['./cm-reportee.component.scss']
})

export class CmReporteeComponent implements OnInit {
  @Output() handleClick = new EventEmitter<void>();
  @ViewChild('fullCalendar') fullCalendar!: FullCalendarComponent;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  years: number[] = [];
  selectedMonth: string = this.months[new Date().getMonth()];
  selectedYear: number = new Date().getFullYear();
  selectedLeaveYear: number = new Date().getFullYear();
  reporteeAllData: any = [];
  reporteesId: any = [];
  allReporteeLeaveBalance: any = [];
  status = 'LEAVE';
  cmId: any;
  monthIndex: any;
  onLeaveRespnseData: any = [];
  OnLeaveEvent: any = [];
  reporteeData: any;
  allReporteeLeaveStatus: any = [];
  todayReporteeLeaveData: any = [];
  monthReporteeLeaveData: any = [];

  constructor(private router: Router,
    public feedbackgroupservice: NewFeedbackGroupService,
    private leaveBalanceService: LeaveBalanceService,
    private userservice: UserService,
    private leaveApprovalService: LeaveApprovalService,
    public dialog: MatDialog,
    public userDetailsService: UserDetailsService,
    private attendanceService: AttendanceService,

  ) { }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }
    this.cmId = this.userDetailsService.getUserDetails().id;
    // this.onClick();
    this.feedbackgroupservice.getdirectreporteebyyear(this.selectedLeaveYear).subscribe((res) => {
      for (let i = 0; i < res.body.length; i++) {
        this.reporteeAllData.push(res.body[i]);
        this.reporteesId.push(res.body[i].userId);
      }
      this.gatLeaveBalance(this.reporteesId, this.selectedLeaveYear);
    }), () => {
      console.log("No Reportees Data Available")
    }

  }
  getCurrentDate(): string {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    return `${day}-${month}-${year}`;
  }

  gatLeaveStatus(reporteeId, date, currentReportee) {
    this.attendanceService.getReporteeAttendance(reporteeId, date).subscribe(res => {
      const leaveStatus = res.body[0].attendanceStatus;
      currentReportee.status = leaveStatus;
      this.allReporteeLeaveBalance.push(currentReportee)
    }, (e) => {
      currentReportee.status = 'Unmarked';
      this.allReporteeLeaveBalance.push(currentReportee)
    });
  }

  gatLeaveBalance(reportees, year) {
    this.allReporteeLeaveBalance = [];
    for (let i = 0; i < reportees.length; i++) {
      let name = '';
      this.userservice.getUserByID(reportees[i]).subscribe((result) => {
        name = result.body.firstName + ' ' + result.body.lastName;
        let date = this.getCurrentDate();

        this.leaveBalanceService.getLeaveBalanceByIdAndYear(reportees[i], year).subscribe(res => {

          const leavebalanceData = res.body.balanceLeave;
          const currentReportee = {
            reporteeName: name,
            reporteeId: reportees[i],
            floater: leavebalanceData.RESTRICTED_HOLIDAY,
            available: leavebalanceData.AVAILABLE_ADVANCED_LEAVE,
            unplannedLeave: leavebalanceData.UNPLANNED_LEAVE,
            paidLeave: leavebalanceData.PAID_LEAVE,
            maternityLeave: leavebalanceData.MATERNITY_LEAVE,
            paternityLeave: leavebalanceData.PATERNITY_LEAVE,
            compOff: leavebalanceData.COMP_OFF,
            restrictedLeave: leavebalanceData.RESTRICTED_HOLIDAY,
            specialLeave: leavebalanceData.SPECIAL_LEAVE,
            contingencyLeave: leavebalanceData.CONTINGENCY_LEAVE,
            leaveBalanceId: res.body.id,
            status: '-'

          }
          this.gatLeaveStatus(reportees[i], date, currentReportee);
          this.allReporteeLeaveBalance.sort((a, b) => {
            return a.reporteeName.toLowerCase() > b.reporteeName.toLowerCase() ? 1 : -1
          })
        },
          (e) => {
            const currentReportee = {
              reporteeName: name,
              reporteeId: reportees[i],
              floater: 0,
              available: 0,
              unplannedLeave: 0,
              paidLeave: 0,
              maternityLeave: 0,
              paternityLeave: 0,
              compOff: 0,
              leaveBalanceId: null,
              status:0
            }
            this.gatLeaveStatus(reportees[i], date, currentReportee);
            this.allReporteeLeaveBalance.sort((a, b) => {
              return a.reporteeName.toLowerCase() > b.reporteeName.toLowerCase() ? 1 : -1
            })
          }
        )
      }
      )
    }
  };

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin],
    initialDate: new Date(),
    editable: true,
    locale: 'en',
    timeZone: 'UTC',
    nextDayThreshold: '00:00:00',

    eventClick: (info) => {
      // console.log('Clicked on event:', info.event);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '65%';
      dialogConfig.width = '60%';
      dialogConfig.panelClass = "manage-class";
      dialogConfig.data = this.reporteeData;
      const dialogRef = this.dialog.open(OnLeaveReporteeComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {

      }, () => {

      });
    },
    weekends: true,
    firstDay: 1,
    datesSet: event => {
      const dateObj = new Date(event.end);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      this.selectedMonth = month == 0 ? 'December' : this.months[month - 1];
      this.selectedYear = month == 0 ? year - 1 : year;
      this.onLeaveRespnseData = [];
      this.reporteeData = [];

      this.monthIndex = this.months.indexOf(this.selectedMonth) + 1;
      this.leaveApprovalService.getOnLeaveReportees(this.monthIndex, this.selectedYear).subscribe((res) => {
        let data = res.body;
        this.todayReporteeLeaveData = [];
        this.monthReporteeLeaveData = [];
        for (let key of Object.keys(data)) {
          if (this.isToday(key)) {
            let userArrayForToday = data[key];
            let date = this.formatDateIntoDateMonth(key);
            let name = '';
            for (let i = 0; i < userArrayForToday.length; i++) {
              this.userservice.getUserByID(userArrayForToday[i]).subscribe((result) => {
                name = result.body.firstName + ' ' + result.body.lastName;
                let todayEmp = {
                  name: name,
                  date: date,
                  status: 'LEAVE',
                  userId: userArrayForToday[i]
                }
                this.todayReporteeLeaveData.push(todayEmp);
              })
            }
          } else {
            let userArrayForToday = data[key];
            let date = this.formatDateIntoDateMonth(key);
            let name = '';
            for (let i = 0; i < userArrayForToday.length; i++) {
              this.userservice.getUserByID(userArrayForToday[i]).subscribe((result) => {
                name = result.body.firstName + ' ' + result.body.lastName;
                let todayEmp = {
                  name: name,
                  date: date,
                  status: 'LEAVE',
                  userId: userArrayForToday[i]
                }
                this.monthReporteeLeaveData.push(todayEmp);
              })
            }
          }
          let currData = {
            date: key,
            desc: data[key]
          }
          this.reporteeData.push(currData);
          let event: EventInput = {
            // title: (data[key].length),
            title: '',
            start: key,
            backgroundColor: '#0045B9'
          };
          if (!this.isWeekend(event.start)) {
            this.onLeaveRespnseData.push(event);
          }
        }
        this.calendarOptions.events = this.onLeaveRespnseData;

      }, (e) => { })



    },
    headerToolbar: {
      start: 'prevYear prev',
      center: 'title',
      end: 'today next nextYear'
      // start: 'prev',
      // center: 'title',
      // end: 'today next'
    },
  };

  onMonthYearChange() {
    if (this.fullCalendar) {
      const date = new Date(this.selectedYear, this.months.indexOf(this.selectedMonth), 1);
      this.fullCalendar.getApi().gotoDate(date);
    }
  }

  isWeekend(dateString) {

    let date = new Date(dateString);
    return date.getDay() === 6 || date.getDay() === 0;
  }

  onLeaveYearChange() {
    this.gatLeaveBalance(this.reporteesId, this.selectedLeaveYear);
  }

  isToday(date: any): boolean {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().substr(0, 10);
    return date === formattedDate;
  }

  formatDateIntoDateMonth(date: any): string {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const parts = date.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;

    return `${day} ${months[month]}`;
  }

  onClick() {
    this.fullCalendar.getApi().render();
    this.monthIndex = this.months.indexOf(this.selectedMonth) + 1;
    this.onLeaveRespnseData = [];
    this.reporteeData = [];


    this.leaveApprovalService.getOnLeaveReportees(this.monthIndex, this.selectedYear).subscribe((res) => {
      this.todayReporteeLeaveData = [];
      this.monthReporteeLeaveData = [];
      let data = res.body
      for (let key of Object.keys(data)) {
        if (this.isToday(key)) {
          let userArrayForToday = data[key];
          let date = this.formatDateIntoDateMonth(key);
          let name = '';
          for (let i = 0; i < userArrayForToday.length; i++) {
            this.userservice.getUserByID(userArrayForToday[i]).subscribe((result) => {
              name = result.body.firstName + ' ' + result.body.lastName;
              let todayEmp = {
                name: name,
                date: date,
                status: 'LEAVE',
                userId: userArrayForToday[i]
              }
              this.todayReporteeLeaveData.push(todayEmp);
            })
          }
        } else {
          let userArrayForToday = data[key];
          let date = this.formatDateIntoDateMonth(key);
          let name = '';
          for (let i = 0; i < userArrayForToday.length; i++) {
            this.userservice.getUserByID(userArrayForToday[i]).subscribe((result) => {
              name = result.body.firstName + ' ' + result.body.lastName;
              let todayEmp = {
                name: name,
                date: date,
                status: 'LEAVE',
                userId: userArrayForToday[i]
              }
              this.monthReporteeLeaveData.push(todayEmp);
            })
          }
        }
        let currData = {
          date: key,
          desc: data[key]
        }
        this.reporteeData.push(currData);
        let event: EventInput = {
          // title: (data[key].length),
          title: '',
          start: key,
          backgroundColor: '#0045B9'
        };
        if (!this.isWeekend(event.start)) {
          this.onLeaveRespnseData.push(event);
        }
      }
      this.calendarOptions.events = this.onLeaveRespnseData;

    }, (e) => {
    })


  }

  viewDetails(reportee) {
    this.router.navigate(['/leaveManagement/reporteeDetails'], { queryParams: { reportee: JSON.stringify(reportee) } });
  }

  viewAttendance(reportee) {
    for (let i = 0; i < this.allReporteeLeaveBalance.length; i++) {
      if (reportee.userId == this.allReporteeLeaveBalance[i].reporteeId) {
        this.router.navigate(['/leaveManagement/reporteeDetails'], { queryParams: { reportee: JSON.stringify(this.allReporteeLeaveBalance[i]) } });
        break;
      }
    }
  }

}
