import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AddHolidayService } from 'src/app/services/addHoliday/add-holiday.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';

@Component({
  selector: 'app-addholidays',
  templateUrl: './addholidays.component.html',
  styleUrls: ['./addholidays.component.scss']
})
export class AddholidaysComponent implements OnInit {
  LeaveType = [];
  clientProject = [];
  selectedClient: any;
  availableHolidayTypes: string[] = ['CLIENT_HOLIDAY'];
  newLeaveDetails: FormGroup;
  clientDetails = []

  constructor(public dialogRef: MatDialogRef<AddholidaysComponent>,
    public addHolidayService: AddHolidayService,
    public clientService: ClientService,
    public toaster: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
      this.newLeaveDetails = this.fb.group({
        LeaveType: ['', [Validators.required]],
        date: ['', [Validators.required]],
        projectName: ['', [Validators.required]],
        holidayName: ['', [Validators.required]],
      });
  }

  ngOnInit(): void {
    // console.log(this.data.existingHoliday)
    //  this.LeaveType = ['CLIENT_HOLIDAY', 'GENERAL_HOLIDAY', 'RESTRICTED_HOLIDAY'];
    this.LeaveType = ['CLIENT_HOLIDAY'];
    // this.clientProject =["anchal","disha","BA"];
    this.clientProject = [];
    this.clientService.getClientsSubscribe().subscribe((res) => {

      const clientRes = res.body;
      this.clientProject.push('BA')

      for (let i = 0; i < clientRes.length; i++) {
        this.clientProject.push(clientRes[i].clientName)
        this.clientDetails.push(clientRes[i]);
      }
    });
    // this.selectedClient=this.clientProject[0];
    this.selectedClient = 'BA';

  }
  onClientNameChange(): void {
    const selectedClient = this.newLeaveDetails.get('projectName').value;
    if (selectedClient === 'BA') {
      this.availableHolidayTypes = ['GENERAL_HOLIDAY', 'RESTRICTED_HOLIDAY'];
    } else {
      this.availableHolidayTypes = ['CLIENT_HOLIDAY'];
    }
    // Reset the LeaveType form control
    this.newLeaveDetails.get('LeaveType').setValue('');
  }

  closeDialog() {
    this.dialogRef.close();
  }
  correctZoneOffset(date: Date) {
    const timezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - timezoneOffset);
    return date;
  }
  addHoliday() {
    debugger
    const clientName = this.newLeaveDetails.get('projectName').value;
    var clientID;
    for (let i = 0; i < this.clientDetails.length; i++) {
      if (this.clientDetails[i].clientName === clientName) {
        clientID = this.clientDetails[i].clientId;
        break;
      }
      if (clientName == "BA") {
        clientID = 'BA';
        break;
      }
    }
    let tempDate = this.newLeaveDetails.get('date').value
    const BACalendar = {
      calendarLeaveType: this.newLeaveDetails.get('LeaveType').value,
      clientId: clientID,
      date: this.correctZoneOffset(tempDate),
      leaveDescription: this.newLeaveDetails.get('holidayName').value
    }
    // console.log(BACalendar)
    this.addHolidayService.addHoliday(BACalendar, BACalendar.date.getFullYear()).subscribe((e) => {
      // console.log(BACalendar,BACalendar.date.getFullYear());
      // console.log(e);
      this.toaster.success('Successfully Applied', 'Saved', {});
      this.dialogRef.close();
    }, () => {
      this.toaster.success('Cannot Applied, Try Again', '', {});
    })
  }

}
