<div class="leave-header">
    <h2 mat-dialog-title>Blue Altair Leave Policy</h2>

</div>
<hr>

<!-- table 1 -->
<div class="table-2">
    <table class="table policy-det-tbl">
        <thead class="thead-light">
            <th colspan="2" class="table-heading">Policy Detail </th>
        </thead>
        <tbody>
            <tr>
                <td>Name </td>
                <td>Leave Policy </td>
            </tr>
            <tr>
                <td>Category</td>
                <td>
                    People & Talent
                </td>
            </tr>
            <tr>
                <td>Policy Owner/s </td>
                <td>People & Talent Team </td>
            </tr>
            <tr>
                <td>Version</td>
                <td>1.0 </td>
            </tr>
            <tr>
                <td>Date Last Updated </td>
                <td>March 2023</td>
            </tr>
            <tr>
                <td>Email For Queries </td>
                <td><a href="mailto:Pnt-india@bluealtair.com ">Pnt-india@bluealtair.com </a></td>
            </tr>
            <tr>
                <td>Related Policies </td>
                <td><a target="_blank"
                        href="https://bluealtair.sharepoint.com/:w:/r/sites/humanresources/Shared%20Documents/01%20India/00%20Processes%20%26%20Policies/02%20Accounts/WIP%20-%20Accounts%20Policies%20-%20Editable%20version/IBA%20-%20Accounts%20-%20Time%20Reporting.docx?d=wcf2cc33db73d46d5b4c0d4065bbd830c&csf=1&web=1&e=cYI104">Time
                        Reporting</a> </td>
            </tr>
        </tbody>
    </table>

</div>

<!-- table 2 -->
<div class="table-2">
    <table class="table policy-det-tbl">
        <thead class="thead-light">
            <th colspan="2" class="table-heading">Policy Note </th>
        </thead>
        <tbody>
            <tr>
                <td>
                    <li>Blue Altair India follows the calendar year (January - December) for time off / leave. </li>

                    <li>Periodic leave can be availed depending on project dependency, Avoid accumulation of leave at
                        the end of year. </li>

                    <li>Leave requirement should be discussed well in advance with project supervisor, on supervisor's
                        approval on email.</li>
                    <li> Leave can be requested with Career Manager on greytHR (note: section should mention that
                        the leave is discussed & approved by project supervisor).</li>
                    <li>As soon as the leave is approved, it should be shared with team members, client & required
                        stakeholders in advance. </li>

                    <li>Leave dependency should be discussed & published on respective project channels, send OOO,
                        calendar invite on outlook etc.</li>
                </td>
            </tr>
            <tr>
                <td>
                    <li class="list-group-item">Leave Encashment/ Carry forward summary:</li>
                    <li>Paid Leave - In case of available paid leave balance, a maximum of 5 leaves will be carried
                        forward to next calendar year. </li>
                    <li>Unplanned Leave (emergency, sick, career etc.) - Cannot be carried forward nor encashed and will
                        lapse at the end of every year. </li>
                    <li>Floating Leave - Cannot be carried forward nor encashed.</li>
                    <li>It is important to discuss your leave plan with your project supervisor & career manager prior
                        to leave
                        application. Ensure the client is informed in advance and communicated with other team members.
                    </li>
                    <li>A continuous period of absence including floating holiday, unplanned leave, half day or any type
                        of leave should not exceed 10 working days unless there are extenuating
                        circumstances.</li>

                </td>
            </tr>

        </tbody>
    </table>
</div>


<!-- table 3 -->
<div class="table-2">
    <table class="table policy-det-tbl">
        <thead class="thead-light">
            <th colspan="3" class="table-heading">Policy Details </th>
        </thead>
        <thead class="">
            <th colspan="3">List of available time off / types of leave are as follows - </th>
        </thead>
        <thead class="">
            <th>Type of Time Off</th>
            <th>Number (working days) </th>
            <th>Description </th>
        </thead>
        <tbody>
            <tr>
                <td>Holidays </td>
                <td>10 Days (8 Fixed + 2 Floating) </td>
                <td>
                    <li>The list of holidays for each year is published by the last day of the previous year. </li>
                    <li>Holiday calendar is available here - <b>&lt;&lt;&lt;&lt; Holiday calendar Intranet link to be
                            added &gt;&gt;&gt;&gt; </b> </li>
                    <li>You must avail 1 floating holiday (from the floating holiday list for the current year that is
                        available at Holiday calendar page link) before June 30 and the other one before December 31.
                    </li>
                </td>
            </tr>
            <tr>
                <td>Planned Time Off (PTO) </td>
                <td>21 Days </td>
                <td>
                    <li>Applicable only to permanent employees. </li>
                    <li>1.75 number of days gets accumulated as leave balance per month.</li>
                    <li>A maximum of 5 days of leave will be carried forward to next year.</li>
                    <li>A maximum of 3 days of leave will be encashed at the end of every year.</li>
                    <li>Compensatory off, half day, away time can only be availed by your project supervisor /
                        career managers prior approval.</li>
                    <li>Any leave beyond the entitlement will be treated as an exception and will need to be approved by
                        the P&T team.</li>
                    <li>Any absence without valid reason and/or approval will warrant disciplinary/administrative action
                        against the employee.</li>
                    <li>You must plan/discuss your PTO at least one month in advance.</li>
                    <b>Note - </b>You cannot apply for PTO during the first 3 months of your employment, during
                    performance improvement plan and during your notice period.
                </td>
            </tr>
            <tr>
                <td> Unplanned Leave (emergency, sick, career etc.) </td>
                <td> 7 Days </td>
                <td>
                    <li>Applicable only to permanent employees. </li>
                    <li>7 days of leave balance is accumulated at the start of the year.</li>
                    <li>Cannot be carried forward or encashed.</li>
                    <li>Applicable to take care of your own health condition which prevents you from performing your
                        position's functions or to care for your children, spouse, or parents with a serious health
                        condition.</li>
                    <b>Note -</b> A medical certificate must be produced if you need to be away for more than 2
                    consecutive days.
                </td>
            </tr>
            <tr>
                <td> Contingency Leave </td>
                <td>5 Days </td>
                <td>
                    <li>Applicable only to permanent employees. </li>
                    <li>Applicable in case of hospitalization of the employee or his/her immediate family members i.e.,
                        your dependent parents, spouse, and children.</li>
                    <li> Documents related to hospitalization must be produced. </li>
                    <li>Can be applied only after entire PTO & Unplanned leave balance is exhausted. </li>
                    <li>Can be availed in case of death of immediate family member i.e. - spouse, children, or parents.
                    </li>
                    <b>Note - </b>
                    <li>Pregnancy/delivery related hospitalization is excluded from this as they are adequately catered
                        for in section Maternity/Paternity leave. </li>
                    <li>This leave benefit is applicable only if you exhaust all your PTO's & Unplanned leave. The
                        actual calculation and accounting for this leave will be done at the end of the year. </li>
                </td>
            </tr>
            <tr>
                <td>Maternity Leave </td>
                <td>26 Weeks </td>
                <td>
                    <li>Applicable only to permanent female employees only after they have worked with Blue Altair for
                        at least 80 days (about 2 and a half months). </li>
                    <li>Can commence up to 6 weeks (about 1 and a half months) prior to and including the due day of
                        your delivery. </li>
                    <b> Note - </b>
                    <li>If you plan to avail maternity leave, you should discuss your plan at least after 12 weeks
                        (about 3 months) of your pregnancy. </li>
                    <li>If you plan to return to work, you are requested to submit a fitness certificate from a
                        physician. </li>
                    <li>According to the latest amendment to the Maternity Benefit Act 2017, women who are expecting
                        after having 2 children, the duration of paid maternity leave shall be 12 weeks (about 3
                        months). </li>
                    <li> Maternity leave for adoptive and commissioning mothers: The MB Amendment Act extends certain
                        benefits to adoptive mothers as well and provides that every woman who adopts a child shall be
                        entitled to 12 weeks (about 3 months) of maternity leave, from the date of adoption. In such
                        cases, the leave starts up to 1 week before the adoption date. You will need to inform the People  and Talent 
                        head about your plans of adoption as soon as you apply for such an arrangement. You will need to
                        submit a copy of an adoption certificate in such cases. </li>
                </td>
            </tr>
            <tr>
                <td>Paternity Leave </td>
                <td>10 Days </td>
                <td>
                    <li>Applicable only to permanent male employees only after they have worked with Blue Altair for at
                        least 6 months. </li>
                    <li>Can be taken any time between one month before and three months after the delivery. </li>
                    <li>Cannot be taken into parts.</li>
                </td>
            </tr>
            <tr>
                <td>Special Leave </td>
                <td>3 Days </td>
                <td>
                    <li>Applicable only to permanent employees after they have worked with Blue Altair for at least 1
                        year. </li>
                    <li> Applicable only in the case of your own wedding. </li>
                    <li>Leave request to be made three (3) months in advance through greytHR portal. </li>
                    <li>3 days of additional leave will be added to your existing leave balance.</li>
                    <li>If you happen to leave Blue Altair within 6 months of availing this leave, it would be adjusted
                        against your PTO balance or equivalent amount will be deducted in the full and final settlement.
                    </li>
                    <b>Note - </b>
                    <li>In total you can avail 15 continuous working days of leave </li>
                </td>
            </tr>
            <tr>
                <td>Away day/compensatory off </td>
                <td> </td>
                <td>
                    <li>Away day/Comp. off is awarded taking work & contribution extended for a period into consideration and not on a 1:1 basis.  </li>
                    <li>Applicable only to permanent employees after they have completed 6 months of employment at
                        Blue Altair. </li>
                    <li>Subjective to approval from project supervisor and capability lead. </li>
                    <li>Cannot be carried forward nor encashed. </li>
                    <li>No sandwich rule applicable to club Away Day with Planned Leave or Unplanned Leave.</li>
                    <li> Employees opting for leave are expected to be available in case of work emergency. </li>
                </td>
            </tr>
            <!-- <tr>
                <th colspan="3">Contractor Team Members
                </th>
            </tr> -->
            <tr>
                <td colspan="3">
                    <li class="list-group-item">Contractor Team Members:</li>
                    <li>Contractors are entitled to 1 paid leave a month. Cannot be carried forward or encashed. </li>
                    <li>Any time off plans must be discussed with your project supervisor in advance. If you are away due to
                        sickness, you must produce a medical certificate. Your professional fees will be calculated
                        based on the number of days you have worked for in any given month. </li>
                </td>
            </tr>

        </tbody>
    </table>
</div>


<!-- last table -->


<!-- table 3 -->
<div class="table-2">
    <table class="table policy-det-tbl">
        <thead class="thead-light">
            <th colspan="5" class="table-heading">Process </th>
        </thead>
        <thead>
            <th colspan="5" class="note-field">
                <b>Note -</b>
                This process is applicable to all types of leave. The duration of advance notice of leave will depend on
                the type of leave as per the policy. In case of sickness/illness or emergency conditions (applicable to
                contingency leave), the leave can be applied after you resume your work.
            </th>
        </thead>
        <thead class="">
            <th></th>
            <th>Step </th>
            <th>Description </th>
            <th>Owner/s </th>
            <th>Timeline </th>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>Leave application </td>
                <td>
                    <li>Discuss your leave plan with your project supervisor based on the type of leave. </li>
                    <li> Check for leave balance & eligibility </li>
                    <li>Apply the leave on greytHR portal</li>
                </td>
                <td>Employee </td>
                <td>Depending upon the type of leave </td>
            </tr>

            <tr>
                <td>2</td>
                <td>Approval </td>
                <td>
                    <li>Discuss your team member's leave plan with all the stakeholders/client </li>
                    <li>Check for leave balance & eligibility</li>
                    <li>Approve the leave request on greytHR upon email confirmation from all the stakeholders </li>
                    <li>Nominate an alternative team member if the leave is for a longer duration</li>
                </td>
                <td>Project Supervisor along with Career Manager </td>
                <td>Within one week of the application </td>
            </tr>

            <tr>
                <td>3</td>
                <td>Project work allocation</td>
                <td>
                    <li>Ensure that you assign all the pending tasks to the nominated team member.</li>
                    <li>Ensure that you complete all your tasks before you are set to take your time off.</li>
                    <li>Plan your day of return in advance so that you can take over your work avoiding delays in
                        day-to-day project operation.</li>
                </td>
                <td>Employee</td>
                <td>1 week prior to your time off</td>
            </tr>

            <tr>
                <td>4</td>
                <td>Leave communication</td>
                <td>It is recommended that you notify all your team members about your leave plans -
                    <li>Set OOO & calendar invite - free booking should be shared with required stakeholders.</li>
                    <li>Share a slack message with respective stakeholders like your project team members, career manager,
                        capability lead etc. before you are set for your time off.</li>
                    <li>It is recommended that you provide us with your contact number during your time off (only if
                        there are critical dependencies)</li>
                    <li>Before you plan a long leave, check if any other team member is also available leave during this
                        period and contact your supervisor for leave approval. </li>
                </td>
                <td>Employee</td>
                <td>1 day prior to your time off</td>
            </tr>

            <tr>
                <td>5</td>
                <td>Harvest timesheet</td>
                <td>
                    <li>Fill in your timesheet without fail before you go for your planned time off.</li>
                    <li>In case of un-planned time off, you must submit it as soon as you resume your work. </li>
                </td>
                <td>Employee</td>
                <td>1 day prior to your time off</td>
            </tr>

        </tbody>
    </table>
</div>


<hr>
<mat-dialog-actions align="end">
    <button class="close_label" mat-button [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>