<mat-accordion class="example-headers-align">
    <mat-expansion-panel [@.disabled]="true">
        <mat-expansion-panel-header>
            <mat-panel-title class="profile_label">Profile</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container-fluid">
            
            <div class="row">
                <div class="col-md-3">
                    <div class="upload_img_maindiv">
                        <figure>
                            <div class="upload_img_cation_alignment" (click)="fileInput.click()">
                                <img class="upload_img" [(src)] = "userid" *ngIf="photo" />
                                <img class="upload_img" src="assets/blue_altair_logo.png" alt="" *ngIf="!photo">
                                <label class="remove_img_text"
                                *ngIf="this.imageError && !this.photo">{{this.imageError}}</label>
                                <figcaption class="upload_text" *ngIf="!photo">Upload</figcaption>
                                <input type="file" id="my_file" style="display: none;" accept="image/*"
                                    (change)="readURL($event.target.files)" #fileInput />
                            </div>
                            <figcaption class="remove_img_text" *ngIf="photo" (click)="setDefaultImg()">Remove File</figcaption>
                        </figure>
                    </div>
                    <!-- <div class="upload_img_maindiv">
                        <figure>
                            <div class="upload_img_cation_alignment" (click)="fileInput.click()">
                                <input id="image" type="file" (change)="onChange($event)" class="fileInputProfile"
                                    style="visibility: hidden;display:none" #fileInput>



                                <ng-container *ngIf="isImageSaved; else elseTemplate">
                                    <img class="upload_img" [src]="cardImageBase64" />

                                </ng-container>
                                <ng-template #elseTemplate>

                                    <img class="upload_img" src="assets/blue_altair_logo.png" *ngIf="!photo">
                                    <figcaption class="upload_text">Upload</figcaption>

                                </ng-template>

                            </div>
                            <figcaption *ngIf="isImageSaved" (click)="removeImage()" class="remove_img_text">Remove
                                Image
                            </figcaption>

                        </figure>
                    </div> -->
                </div>
                <div class="col-md-9 form_main_div">
                    <form [formGroup]="editEmployeeDetails">

                        <div class="row">
                            <div class="col-md-5">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>First Name </mat-label>
                                    <input matInput formControlName="firstName" autocomplete="off">
                                    <mat-error *ngIf="checkError('firstName', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('firstName', 'pattern')">Enter Valid Name</mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-md-5">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Last Name </mat-label>
                                    <input matInput formControlName="lastName" autocomplete="off">
                                    <mat-error *ngIf="checkError('lastName', 'required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('lastName', 'pattern')">Enter Valid Last Name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row mt-2">

                            <div class="col-md-5">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Mobile Number <span class="contentRed"></span></mat-label>
                                    <!-- <input matInput formControlName="mobileNo" autocomplete="off"> -->
                                    <ngx-mat-intl-tel-input [preferredCountries]="['in', 'us']" [enablePlaceholder]="true"
                                        [enableSearch]="true" name="phone"
                                        (countryChanged)="countryChangedEvent($event)" formControlName="mobileNo"
                                        #phone (focusout)="focusOutMobileField()"></ngx-mat-intl-tel-input>
                                        <mat-hint>Eg : {{phone.selectedCountry.placeHolder}}</mat-hint>
                                        <mat-error *ngIf="this.editEmployeeDetails.get('mobileNo').errors?.ZeroLength">
                                            Required
                                        </mat-error>
                                        <mat-error *ngIf="this.editEmployeeDetails.get('mobileNo').errors?.InvalidNumber">
                                            Invalid Pattern
                                        </mat-error>
                                    <!-- <mat-error *ngIf="checkError('mobileNo', 'required')">required</mat-error> -->
                                </mat-form-field>
                            </div>

                            <!-- <div class="col-md-5">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Mobile Number </mat-label>
                                    <input matInput formControlName="mobileNo" autocomplete="off">
                                    <mat-error *ngIf="checkError('mobileNo', 'Required')">Required</mat-error>
                                    <mat-error *ngIf="checkError('mobileNo', 'pattern')">Valid Mobile Number</mat-error>

                                </mat-form-field>
                            </div> -->

                        </div>


                        <div class="row">
                            <div class="col-md-12">
                                <div class="submit_button_main">
                                    <button class="save_Emp_btn" type="submit" (click)="onSubmit()">
                                        <span>{{this.savebtnlbl}}</span></button>

                                </div>
                            </div>

                        </div>
                    </form>


                </div>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>