<div class="row">
  <div class="col-lg-4 col-md-12">
    <mat-card class="main-card" *ngIf="this.addresscurr.length !== 0">
      <div class="header">
        <div>
          <h2>Current Adderss </h2>
        </div>
        <div>
          <mat-icon (click)="editCurrentAddressDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="addressfixed">
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">Adderss Line 1</span>
            <br>
            <label class="address_name mt-1">{{addresscurr.addressLineOne}}</label>
          </div>
        </div>
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">Address Line 2</span>
            <br>
            <label class="address_name mt-1">{{addresscurr.addressLineTwo}}</label>
          </div>
        </div>

        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">Pincode</span>
            <br>
            <label class="address_name mt-1">{{addresscurr.pincode}}</label>
          </div>
        </div>
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">City</span>
            <br>
            <label class="address_name mt-1">{{addresscurr.city | titlecase}}</label>
          </div>
        </div>
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">State</span>
            <br>
            <label class="address_name mt-1">{{addresscurr.state | titlecase}}</label>
          </div>
        </div>
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">Country</span>
            <br>
            <label class="address_name mt-1">{{addresscurr.country | titlecase}}</label>
          </div>
        </div>
      </div>


    </mat-card>

    <mat-card class="main-card" *ngIf="this.addresscurr.length == 0">
      <div class="header">
        <div>
          <h2>Current Adderss </h2>
        </div>
        <div>
          <mat-icon (click)="editCurrentAddressDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>

    </mat-card>
  </div>

  <div class="col-lg-4 col-md-12">
    <mat-card class="main-card" *ngIf="this.address.length !== 0">
      <div class="header">
        <div>
          <h2>Permanent Address </h2>
        </div>
        <div>
          <mat-icon (click)="editPermanentAddressDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="addressfixed">
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">Adderss Line 1</span>
            <br>
            <label class="address_name mt-1">{{address.addressLineOne}}</label>
          </div>
        </div>

        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">Address Line 2</span>
            <br>
            <label class="address_name mt-1">{{address.addressLineTwo}}</label>
          </div>
        </div>

        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">Pincode</span>
            <br>
            <label class="address_name mt-1">{{address.pincode}}</label>
          </div>
        </div>
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">City</span>
            <br>
            <label class="address_name mt-1">{{address.city | titlecase}}</label>
          </div>
        </div>
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">State</span>
            <br>
            <label class="address_name mt-1">{{address.state | titlecase}}</label>
          </div>
        </div>
        <div class="address_mat_body mt-2">
          <div>
            <span class="address_heder">Country</span>
            <br>
            <label class="address_name mt-1">{{address.country | titlecase}}</label>
          </div>
        </div>
      </div>
      <div class="addressfixed" *ngIf="this.address == null">
        abcdefs
      </div>
    </mat-card>
    <mat-card class="main-card" *ngIf="this.address.length == 0">
      <div class="header">
        <div>
          <h2>Permanent Address </h2>
        </div>
        <div>
          <mat-icon (click)="editPermanentAddressDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>

  <div class="col-lg-4 col-md-12">
    <!-- <mat-card class="main-card" *ngIf="this.userDto.length !== 0"> -->
    <mat-card class="main-card" *ngIf="this.personalInformationPresentCheck">
      <div class="header">
        <div>
          <h2>Personal Information </h2>
        </div>
        <div>
          <mat-icon (click)="editPersonalInformationDialog()" *ngIf="allowEditToOthers"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="information_mat_body mt-2">
        <div>
          <span class="information_heder">
            <svg class="star" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z"
                fill="url(#paint0_radial_1891_6521)" />
              <defs>
                <radialGradient id="paint0_radial_1891_6521" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(6 6) rotate(90) scale(5)">
                  <stop stop-color="#FF2525" />

                </radialGradient>
              </defs>
            </svg>
            Blood Group </span>
          <br>
          <!-- <label *ngIf="(this.userDto?.bloodGroup !== null) && (this.userDto?.bloodGroup !== undefined)" class="information_name mt-1">{{this.bloodGroup[this.userDto.bloodGroup]}}</label> -->
          <label *ngIf="(this.userDto?.bloodGroup?.length > 0)"
            class="information_name mt-1">{{this.bloodGroup[this.userDto.bloodGroup]}}</label>
        </div>
        <div>
          <!-- <span class="information_heder"> Contact Details</span> -->
          <span class="information_heder"> Date of Birth </span>
          <br>
          <!-- <label class="information_name mt-1">{{this.mobileNumber}}</label> -->
          <!-- <label class="information_name mt-1">{{this.userDto.mobileNumber}}</label> -->
          <label *ngIf="(this.userDto?.dateOfBirth.length > 0)" class="information_name mt-1">{{this.userDto.dateOfBirth
            | date : 'dd-MM-yyyy'}}</label>
          <!-- <label class="information_name mt-1">{{this.userDto.dateOfBirth | date : 'dd-MM-yyyy'}}</label> -->
        </div>
      </div>

      <div class="information_mat_body mt-2">
        <div>
          <span class="information_heder"> Relationship Status </span>
          <br>
          <!-- <label class="information_name mt-1">{{this.relationstatus}}</label> -->
          <label *ngIf="(this.userDto?.relationshipStatus?.length > 0)"
            class="information_name mt-1">{{this.userDto.relationshipStatus}}</label>
          <!-- <label class="information_name mt-1">{{this.userDto.relationshipStatus}}</label> -->
        </div>
        <div>
          <span class="information_heder"> Place of Birth</span>
          <br>
          <!-- <label class="binformation_name mt-1">{{this.placebirth }}</label> -->
          <label *ngIf="(this.userDto?.placeOfBirth.length > 0)" class="binformation_name mt-1">{{
            this.userDto.placeOfBirth }}</label>
          <!-- <label class="binformation_name mt-1">{{ this.userDto.placeOfBirth }}</label> -->
        </div>
      </div>
    </mat-card>
    <!-- <mat-card class="main-card" *ngIf="this.userDto.length == 0"> -->
    <mat-card class="main-card" *ngIf="!this.personalInformationPresentCheck">
      <div class="header">
        <div>
          <h2>Personal Information </h2>
        </div>
        <div>
          <mat-icon (click)="editPersonalInformationDialog()" *ngIf="allowEditToOthers"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>

      <!-- <div>
          <span class="information_heder"> Relationship Status </span>
          <br>
          <label class="information_name mt-1">{{this.userDto.relationshipStatus}}</label>
        </div>
        <div class=" mr-2">
          <span class="information_heder"> Place Of Birth</span>
          <br>
          <label class="binformation_name mt-1">{{this.userDto.placeOfBirth }}</label>
        </div> -->

    </mat-card>
  </div>

  <div class="col-lg-4 col-md-12">
    <mat-card class="emergency_main-card" *ngIf="this.emergencycontactone.length !== 0">
      <div class="header">
        <div>
          <h2>Emergency Contact 1 </h2>
        </div>
        <div>
          <mat-icon (click)="editEmergencyContact1()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="emergency_mat_body mt-2">
        <div>
          <span class="emergency_heder"> Name </span>
          <br>
          <label class="emergency_name1 mt-1">{{emergencycontactone.name | titlecase}}</label>
        </div>
        <div>
          <span class="emergency_heder"> Contact Details</span>
          <br>
          <label class="emergency_name mt-1">{{emergencycontactone.phoneNumber}}</label>
        </div>
      </div>

      <div class="emergency_mat_body mt-2">
        <div>
          <span class="emergency_heder"> Relationship </span>
          <br>
          <label class="emergency_name1 mt-1">{{emergencycontactone.relationship}}</label>
        </div>
      </div>
      <div class="emergency_mat_body mt-2">
        <div>
          <span class="emergency_heder"> Adderss Line 1 </span>
          <br>
          <label class="emergency_name1 mt-1">{{emergencycontactone.location}}</label>
        </div>
      </div>
    </mat-card>
    <mat-card class="emergency_main-card" *ngIf="this.emergencycontactone.length == 0">
      <div class="header">
        <div>
          <h2>Emergency Contact 1 </h2>
        </div>
        <div>
          <mat-icon (click)="editEmergencyContact1()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>

  </div>

  <div class="col-lg-4 col-md-12">
    <mat-card class="emergency_main-card" *ngIf="this.emergencycontacttwo.length !== 0">
      <div class="header">
        <div>
          <h2>Emergency Contact 2</h2>
        </div>
        <div>
          <mat-icon (click)="editEmergencyContact2()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div>
        <div class="emergency_mat_body mt-2">
          <div>
            <span class="emergency_heder"> Name </span>
            <br>
            <label class="emergency_name1 mt-1">{{emergencycontacttwo.name | titlecase}}</label>
          </div>
          <div>
            <span class="emergency_heder"> Contact Details</span>
            <br>
            <label class="emergency_name mt-1">{{emergencycontacttwo.phoneNumber}}</label>
          </div>
        </div>

        <div class="emergency_mat_body mt-2">
          <div>
            <span class="emergency_heder"> Relationship </span>
            <br>
            <label class="emergency_name1 mt-1">{{emergencycontacttwo.relationship | titlecase}}</label>
          </div>
        </div>
        <div class="emergency_mat_body mt-2">
          <div>
            <span class="emergency_heder"> Adderss Line 1 </span>
            <br>
            <label class="emergency_name1 mt-1">{{emergencycontacttwo.location | titlecase}}</label>
          </div>
        </div>
      </div>

    </mat-card>

    <mat-card class="emergency_main-card" *ngIf="this.emergencycontacttwo.length == 0">
      <div class="header">
        <div>
          <h2>Emergency Contact 2</h2>
        </div>
        <div>
          <mat-icon (click)="editEmergencyContact2()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>

    </mat-card>
  </div>

  <div class="col-lg-4 col-md-12">
    <mat-card class="family_details_main-card" *ngIf="this.familydetailsdto.length !== 0">
      <div class="header">
        <div>
          <h2>Family / Nominee Details</h2>
        </div>
        <div>
          <mat-icon (click)="editNomineeDetailsDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>

      <div class="nomieefixed">
        <div *ngFor="let f of familydetailsdto" class="family_mat_body mt-2">
          <div>


            <ngb-rating *ngIf="f.nominees == 1" class="ngb-rating" [max]="1" [(rate)]="f.nominees" [readonly]="true">
            </ngb-rating>



            <span class="family_heder"> Name </span>
            <br>
            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              matTooltip="{{f.firstName | titlecase}}" class="family_lbl family_name mt-1">{{f.firstName +" "+
              f.lastName}} </label>
          </div>
          <div>
            <span class="family_heder"> Relationship</span>
            <br>
            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              matTooltip="{{f.relation | titlecase}}" class=" family_name mt-1">{{f.relation}}</label>
          </div>
          <div>
            <span class="family_heder"> Location</span>
            <br>
            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
              matTooltip="{{f.location | titlecase}}" class=" family_name mt-1">{{f.location}}</label>
          </div>
          <div>
            <span class="family_heder"> DOB</span>
            <br>
            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value" matTooltip="{{f.dateOfBirth | date : 'dd-MM-yyyy'
          }}" class="family_lbl1 family_name mt-1">{{f.dateOfBirth | date : 'dd-MM-yyyy'
              }}</label>
          </div>
        </div>

      </div>


    </mat-card>
    <mat-card class="family_details_main-card" *ngIf="this.familydetailsdto.length == 0">
      <div class="header">
        <div>
          <h2>Family / Nominee Details</h2>
        </div>
        <div>
          <mat-icon (click)="editNomineeDetailsDialog()"><img class="edit_logo" src="assets/edit.png"></mat-icon>
        </div>
      </div>
      <div class="no_data_div">
        <img class="img" src="assets/no-data-available.png">
        <label class="no_data_label mt-2">No Data Available</label>
      </div>
    </mat-card>
  </div>

</div>