<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Capability Details</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>
   
    </div>
</div>
<hr>

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 form_main_div">
            <div class="add_button_fix">
                <button class="add_button" (click)="addPreEmployment()">+Add</button>
                <button class="plus_button mt-4" (click)="addPreEmployment()"><span>+Add</span></button>
            </div>
            <form [formGroup]="editSpotlightDetails">
                <div formArrayName="previousEmployment" *ngFor="let work of this.PreviousEmp().controls ;let i=index">
                    <div class="row">
                        <div class="col-md-2" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Start Date <span class="contentRed"></span></mat-label>
                                <input matInput [matDatepicker]="picker2" placeholder="mm/dd/yyyy"  formControlName="positionStartDate">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                       
                        <div class="col-md-2" [formGroupName]="i" >
                             <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>End Date <span class="contentRed"></span></mat-label>
                                <input matInput [matDatepicker]="picker3" placeholder="mm/dd/yyyy" formControlName="positionEndDate" >
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                        </div>
                      
                        <!-- <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Job Title</mat-label>
                                <input matInput autocomplete="off" formControlName="jobTitle">
                            </mat-form-field>
                        </div> -->
                        <div  class="col-md-3 " [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Job Title <span class="contentRed"></span></mat-label>
                                <mat-select formControlName="jobTitle">
                                    <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle">
                                        {{jobTitle}}
                                    </mat-option>
                                </mat-select>
                                <mat-error >Required</mat-error>
                            </mat-form-field>
                        </div>
                        
                        <!-- <div class="col-md-3" [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Company</mat-label>
                                <input matInput autocomplete="off" formControlName="comapanyName">
                            </mat-form-field>
                        </div> -->
                        <div  class="col-md-3 " [formGroupName]="i">
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Capability<span class="contentRed"></span></mat-label>
                                <mat-select formControlName="capability">
                                    <mat-option *ngFor="let capability of this.departments" [value]="capability">
                                        {{capability}}
                                    </mat-option>
                                </mat-select>
                                <mat-error >Required</mat-error>
                            </mat-form-field>
                        </div>
                        
                        <div class="col-md-2">
                            <button class="remove_label" (click)="removePreEmployment(work.value.id,i)">Remove</button>

                           
                        </div>

                    </div>




                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>