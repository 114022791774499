<div class="container-fluid ml-4 mt-4" *ngIf="dataAvailable">
    <div class="row inner-div" >
        <!-- ##commented_BackButton -->
        <figure (click)="backClicked()">
            <mat-icon><img class="upload_img" src="assets/back.png" alt=""></mat-icon>
            <label class="upload_text">Back</label>
        </figure>
    </div>
    <div class="row main-detail-div">
        <div class="col-md-12 row asset-detail-div">
            <div class="col-md-2 asset-imgs">
                <div class="primary-img-align">
                    <img *ngIf="this.assetDetails.assetPrimaryThumbnailViewScreen !== '' "
                        src="data:image/png;base64,{{this.assetDetails.assetPrimaryThumbnailViewScreen}}"
                        class="primary-img-container">
                    <img *ngIf="this.assetDetails.assetPrimaryThumbnailViewScreen ===  '' "
                        class="primary-img-container" src="assets/blue_altair_logo.png">
                </div>
                <label class="asset-noimg-value" *ngIf="this.assetDetails.assetPrimaryThumbnailViewScreen.length===0">No Primary Image Available</label>
                <div class="flex secondary-imgs-align">
                    <div *ngIf="this.assetDetails.assetSecondaryThumbnail1 !== '' ">
                        <img src="data:image/png;base64,{{this.assetDetails.assetSecondaryThumbnail1}}"
                            class="secondary-img-container">
                    </div>
                    <div *ngIf="this.assetDetails.assetSecondaryThumbnail2  !== '' ">
                        <img src="data:image/png;base64,{{this.assetDetails.assetSecondaryThumbnail2}}"
                            class="secondary-img-container">
                    </div>
                    <div *ngIf="this.assetDetails.assetSecondaryThumbnail3 !== ''">
                        <img src="data:image/png;base64,{{this.assetDetails.assetSecondaryThumbnail3}}"
                            class="secondary-img-container">
                    </div>
                    <div *ngIf="this.assetDetails.assetSecondaryThumbnail4  !== '' ">
                        <img src="data:image/png;base64,{{this.assetDetails.assetSecondaryThumbnail4}}"
                            class="secondary-img-container">
                    </div>
                </div>
            </div>
            <div class="col-md-10">
                <div class="row flex details-align">
                    <div class="col-md-2 mr-2 row-flex">
                        <div class="width">
                            <div class="asset-section-label">Asset Category</div>
                            <div class="asset-section-value">{{this.assetEnumCategories[this.assetDetails.assetCategory]}}</div>
                        </div>
                        <div>
                            <div class="asset-section-label">Condition</div>
                            <div class="asset-section-value">{{this.assetDetails.assetCondition | titlecase}}</div>
                        </div>

                    </div>
                    <div class="col-md-2 mr-2 row-flex">
                        <div class="width">
                            <div class="asset-section-label">Asset Name</div>
                            <div class="asset-section-value">{{this.assetDetails.assetName | uppercase}}</div>
                        </div>
                        <div>
                            <div class="asset-section-label">Date of Purchase</div>
                            <div class="asset-section-value">{{this.assetDetails.purchasedOn | date: 'dd-MM-yyyy'}}
                            </div>
                        </div>

                    </div>
                    <div class="col-md-2 mr-2 row-flex">
                        <div class="width">
                            <div class="asset-section-label">Asset ID</div>
                            <div class="asset-section-value">{{this.assetDetails.assetID}}</div>
                        </div>
                        <div>
                            <div class="asset-section-label">Asset Status</div>
                            <div class="asset-section-value">{{this.assetDetails.assetStatus | titlecase}}</div>
                        </div>
                    </div>
                    <div class="col-md-2 mr-2 row-flex">
                        <div>
                            <div class="asset-section-label">Location</div>
                            <div class="asset-section-value">{{this.assetDetails.assetLocation | titlecase}}</div>
                        </div>
                        <div>
                            <div class="asset-section-label" *ngIf="this.empName !=''">Issued To</div>
                            <div class="asset-section-value">{{this.empName | titlecase}}</div>
                        </div>
                    </div>
                    <div class="">
                        <label class="edit-btn" (click)="editAsset()">Edit</label>
                    </div>
                </div>
                <div class="row details-align-desc">
                    <div class="col-12">
                        <div class="asset-section-label">Description</div>
                        <div class="asset-desc-value" id="text_desc"
                            *ngIf="this.assetDetails.assetDescription.length != 0">
                            {{this.assetDetails.assetDescription}}</div>
                        <div class="asset-nodesc-value" id="text_desc"
                            *ngIf="this.assetDetails.assetDescription.length === 0">
                            No Description Available
                        </div>
                    </div>
                </div>
            </div>
            <div class="addasset col-12">
                <button class=" assign-revoke-btn assign-revoke-btn-label"
                    (click)="openRevokeAssetDialog(this.assetDetails)"
                    *ngIf="this.assetDetails.assetStatus==='ISSUED'">Revoke Asset</button>
                <button class=" assign-revoke-btn assign-revoke-btn-label"
                    (click)="openAssignAssetDialog(this.assetDetails)"
                    *ngIf="this.assetDetails.assetStatus==='AVAILABLE'">Assign Asset</button>
            </div>
        </div>
        <div class="asset-history-content">
            <label class="asset-history-label">Asset History</label>
            <div class="history-div">

                <ng-container *ngIf="!this.assetHistoryPresent; else elseTemplate">
                    <div>
                        <img class="no-asset-img" src="assets/no-asset-history.png">
                    </div>
                    <div class="no-history-label" *ngIf="this.assetDetails.assetHistory.length===0">
                        No History Available</div>
                </ng-container>

                <ng-template #elseTemplate>
                    <mat-card class="matcardprojectdetail"
                        *ngFor="let history of this.assetDetails.assetHistory | orderBy: '-assetReturnedDate'">
                        <mat-card-content>
                            <div class="emp-img-center " >
                                <div>
                                    <ng-container
                                        *ngIf="this.assetHistoryEmployeeImages[history.employeeId] !== ''; else elseTemplate">
                                        <img src="data:image/png;base64,{{this.assetHistoryEmployeeImages[history.employeeId]}}"
                                            class="img-container clicking_hover_img" alt="">
                                    </ng-container>


                                    <ng-template #elseTemplate>
                                        <div class="col-md-2">
                                            <img src="assets/default-avatar.jpg" class="img-container ">
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="emp-history">
                                    <div class="col">
                                        <label class="asset-section-label">Issued To</label>
                                        <p class="asset-section-value">{{assetHistoryEmployeeNames[history.employeeId]}}
                                        </p>
                                    </div>
                                    <div class="col">
                                        <label class="asset-section-label">Issued On</label>
                                        <p class="asset-section-value">{{history.assetAssignedDate | date:
                                            'dd-MM-yyyy'}}
                                        </p>
                                    </div>
                                    <div class="col">
                                        <label class="asset-section-label ">Revoke Date</label>
                                        <p class="asset-section-value">{{history.assetReturnedDate | date:
                                            'dd-MM-yyyy'}}
                                        </p>
                                    </div>
                                    <div class="col" >
                                        <label class="asset-section-label">Revoke Reason</label>
                                        <p class="asset-section-value">{{history.comment}}</p>
                                    </div>
                                </div>
                            </div>


                        </mat-card-content>
                    </mat-card>
                </ng-template>
            </div>
        </div>
    </div>
</div>