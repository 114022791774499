<div class="main_header_div">
    <div class="child">
        <h1 class="header_name ">Edit Passport Details</h1>
    </div>
    <div>
        <button class="closeButton" (click)="closeDialog($event)">Close</button>
        <mat-icon class="close-icon" (click)="closeDialog($event)">close</mat-icon>
   
    </div>
</div>
<hr>

<div class="container-fluid">
  
    <div class="row">

        <div class="col-md-12 form_main_div">
            <div class="add_button_fix">
                <button class="add_button" (click)="addPassport()">+Add</button>
                <button class="plus_button mt-4" (click)="addPassport()"><span>+Add</span></button>
            </div>
            <form [formGroup]="editBusinesstravel1">
                <div class="row" formArrayName="passports"
                    *ngFor="let pass of this.PassportDetails().controls; let i = index">

                    <div class="col-md-3" [formGroupName]="i">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Expiration Date <span class="contentRed"></span></mat-label>
                            <input matInput [matDatepicker]="picker3" placeholder="mm/dd/yyyy"
                                formControlName="expiryDate">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4" [formGroupName]="i">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Passport Number<span class="contentRed"></span></mat-label>
                            <input matInput formControlName="passportNumber" autocomplete="off" (input)="onInputToUppercase($event)">
                            <mat-error>
                                Must be alphanumeric
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4" [formGroupName]="i">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Country<span class="contentRed"></span></mat-label>
                                <mat-select Required formControlName="country" >
                                    <mat-option *ngFor="let option of countries" [value]="option">{{option}}</mat-option>
                                </mat-select>
                        </mat-form-field>
                    </div>




                    <!-- <div class="col-md-6 certificate"> -->
                        <!-- <label for="my_file" class="certificate_label">Attach Relevant Document</label>
                       -->
                        <!-- <ng-container [formGroupName]="i"> <input  type="file" id="my_file"
                                (change)="onChange1($event,i)" name="passportb">
                            <ng-container *ngIf="passportb && passportb.length else elseBlock "
                                formArrayName="passportb">

                               
                               

                                <div *ngFor="let image of passportb.controls; let i = index">
                                    <pdf [src]="image.value">
                                       </pdf>
                                    
                                </div>

                          
                                
                              
                            </ng-container>  
                            <ng-template #elseBlock>
                                    <p class="certificate_label">No file Selected</p>

                                </ng-template>
                        </ng-container> -->
                    

                        <!-- <img class="img_size" src="assets/Certificate.png" >
                        <label for="my_file" class="certificate_label">Attach the Passport copy</label> -->

                        <!-- <input type="file" id="my_file" style="display:none" type="file" #fileInput
                            (change)="onChange1($event,i)" >
                        <ng-container  *ngIf="this.passportbase64array[i]!=null">
                            <label *ngIf="!this.passportbase64array[i]!=null"class="preview_pdf_label" (click)="open(content)">Watch Preview</label>

                            <ng-template #content let-modal>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="modal-body">
                                    <pdf-viewer [src]="this.passportbase64array[i]" [rotation]="0"
                                        [fit-to-page]="true" [original-size]="true" [show-all]="true"
                                        style="display: block;">
                                    </pdf-viewer>
                                </div>
                            </ng-template>
                            <ng-template #elseBlock>
                                <p class="certificate_label">No file Selected</p>

                            </ng-template>
                        </ng-container> -->


                    <!-- </div> -->

                    <div class="col-md-4 visa_properties">

                        <button (click)="removepassport(pass.value.id,i)"
                            class="remove_passport_label">Remove</button>



                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="save_Emp_btn" type="submit" (click)="onSubmit()"> <span>Save</span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>