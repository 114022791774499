import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FeedbackNotesService } from 'src/app/services/feedback-notes/feedback-notes.service';
import { NewFeedbackGroupService } from 'src/app/services/new-feedback-group.service';
import { MainContentProjectComponent } from '../../project/main-content-project/main-content-project.component';
import { CloseFeedbackComponent } from '../profile-detail/close-feedback/close-feedback.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { FeedbackDTO } from 'src/app/dtos/FeedbackDTO';
import { FeedbackNotesDTO } from 'src/app/dtos/FeedbackNotesDTO';
import { PreviewFeedbackComponent } from '../preview-feedback/preview-feedback.component';

@Component({
  selector: 'app-feedback-closure',
  templateUrl: './feedback-closure.component.html',
  styleUrls: ['./feedback-closure.component.scss']
})
export class FeedbackClosureComponent implements OnInit {
  public Editor = ClassicEditor;
  feedbackClosure: FormGroup;
  userdto: UserDTO;
  feedbackdto = new FeedbackNotesDTO()
    ;
  feedbackNotesDto = new FeedbackNotesDTO();
  data: any;
  config = {
    toolbar: [
      'undo',
      'redo',
      '|',
      'heading',
      'fontFamily',
      'fontSize',
      '|',
      'bold',
      'italic',
      'underline',
      'fontColor',
      'fontBackgroundColor',
      'highlight',
      'NumberedList',
      'BulletedList', 'RemoveFormat',
      '|',

    ]
  }

  constructor(public dialogRef: MatDialogRef<MainContentProjectComponent>, public fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public userIdYear: any, private dialog: MatDialog, private feedbackgroup: NewFeedbackGroupService,
    public feedbackNotesService: FeedbackNotesService,
    public toastr: ToastrService
  ) {

    this.feedbackClosure = this.fb.group({
      note: ['']
    })
  }

  ngOnInit(): void {
    this.feedbackNotesService.getnotesbyuserandyear(this.userIdYear.directReporteeId, this.userIdYear.selected).subscribe(res => {

      this.feedbackdto = res.body
      this.setFeedbackClosureNote();
    })

  }
  setFeedbackClosureNote() {

    this.feedbackNotesService.getFeedbackSummary(this.userIdYear.directReporteeId, this.userIdYear.selected).subscribe(res => {

      this.feedbackClosure.get('note').setValue(res.body.feedbackSummary);
    })
  }
  closeDialog() {
    this.dialogRef.close()
  }

  preview() {

    (this.feedbackClosure)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70rem";
    dialogConfig.height = "auto";
    dialogConfig.data = {
      directReporteeId: this.userIdYear.directReporteeId,
      selected: this.userIdYear.selected,
      userdto: this.userIdYear.userdto,
      isClosedVisible: false,
      isProbation: false,

    }
    const dialogRef = this.dialog.open(PreviewFeedbackComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // this.weightSum = 0
      // this.getoverallstaus()
      // this.ngOnInit();

    });
  }

  callSaveApi(isPreview) {
    if (this.feedbackClosure.value.note === "") {
      this.toastr.error('Please add Feedback Summary ', 'Failed')
    }
    else {
      var objForPayload = {};
      var data = [];
      objForPayload['feedbackSummary'] = this.feedbackClosure.value.note;
      objForPayload['ccEmails'] = data;

      this.feedbackgroup.closeFeedBackSaveNote(this.userIdYear.directReporteeId, this.userIdYear.selected, true, objForPayload).subscribe(res => {
        if (res.body == 0) {
          this.toastr.success("Success", 'Success');
        }
        else if (res.body == 1) {
          this.toastr.error('All Feedback Group weightage Sum should be 100%', 'failed')
          this.dialogRef.close()
        }
        else if (res.body == 2) {
          this.toastr.error('All feedback must be submitted', 'failed')
          this.dialogRef.close()
        }
        else if (res.body == 3) {
          this.toastr.error('All feedback weightage sum should be 100%', 'failed')
          this.dialogRef.close()
        }
        else if (res.body == 4) {
          this.toastr.success('Successfully Saved', 'success')
          if (isPreview) {
            this.preview();
          }
          this.dialogRef.close()
        }
      }, error => {
        this.toastr.error("No feedback generated for user for the year", 'failed')
      })
    }

  }
}
