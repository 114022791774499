import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CareerManagerComponent } from './components/career-manager/career-manager.component';
import { EditAppComponent } from './components/edit-app/edit-app.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { EvaluationConfigurationComponent } from './components/evaluation-configuration/evaluation-configuration.component';
import { FeedbackFormGenerationComponent } from './components/feedback-form-generation/feedback-form-generation.component';
import { FeedbackGenerationComponent } from './components/feedback-generation/feedback-generation.component';
import { ProjectAccordianComponent } from './components/feedback-generation/project-accordian/project-accordian.component';
import { FeedbackTabsComponent } from './components/feedback-submission/feedback-tabs/feedback-tabs.component';
import { LoginComponent } from './components/login/login.component';
import { TestParentComponent } from './components/OpenModal/test-parent/test-parent.component';
import { PagesComponent } from './components/pages/pages.component';
import { ProjectDetailViewComponent } from './components/project/project-detail-view/project-detail-view.component';
import { ProjectComponent } from './components/project/project.component';
import { AuthGuardService } from './services/auth-guard.service';
import { HomeComponent } from './components/home/home.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { AssetMainComponent } from './components/asset-management/asset-main/asset-main.component';
import { ProfileEmployeeComponent } from './components/profile-employee/profile-employee.component';
import { ViewAssetComponent } from './components/asset-management/view-asset/view-asset/view-asset.component';
import { ViewClientComponent } from './components/client-details/view-client/view-client.component';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page/unauthorized-page.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { LeaveManagementComponent } from './components/leave-management/leave-management.component';
import { ProfileDetailComponent } from './components/career-manager/profile-detail/profile-detail.component';
import { DirectReporteesCMComponent } from './components/career-manager/direct-reportees-cm/direct-reportees-cm.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { CmReporteesComponent } from './components/career-manager/cm-reportees/cm-reportees.component';
import { ConfigureComponent } from './components/configure/configure.component';
import { CmReporteeDetailComponent } from './components/leave-management/cm-reportee-detail/cm-reportee-detail.component';
import { ConfigureMenuComponent } from './components/configure-menu/configure-menu.component';
import { RoleGuardService } from './services/role-guard/role-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sessionTimeout', component: SessionTimeoutComponent },
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'edit', component: EditAppComponent },
      { path: 'feedback-forms/:id', component: TestParentComponent, canActivate: [AuthGuardService] },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
      { path: 'feedback-generation', component: FeedbackGenerationComponent, canActivate: [AuthGuardService] },
      { path: 'feedback-generation-accordian', component: ProjectAccordianComponent, canActivate: [AuthGuardService] },
      { path: 'feedback-submission', component: FeedbackTabsComponent, canActivate: [AuthGuardService] },
      { path: 'employee', component: EmployeeComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'project', component: ProjectComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'project/projectDetailView/:id', component: ProjectDetailViewComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'careerManager', component: CareerManagerComponent, canActivate: [AuthGuardService] },
      { path: 'formGeneration', component: FeedbackFormGenerationComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'evaluationConfig', component: EvaluationConfigurationComponent, canActivate: [AuthGuardService] },
      { path: 'myprofile', component: ProfileEmployeeComponent, canActivate: [AuthGuardService] },
      { path: 'employee-profile', component: ProfileEmployeeComponent, canActivate: [AuthGuardService] },
      { path: 'task', component: TasksComponent, canActivate: [AuthGuardService] },
      { path: 'assetManagement', component: AssetMainComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'viewAsset/:id', component: ViewAssetComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'unauthorized', component: UnauthorizedPageComponent, canActivate: [AuthGuardService] },
      { path: 'underconstruction', component: UnderConstructionComponent, canActivate: [AuthGuardService] },
      { path: 'leaveManagement', component: LeaveManagementComponent, canActivate: [AuthGuardService] },
      { path: 'configure', component: ConfigureComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'configure-menu', component: ConfigureMenuComponent, canActivate: [AuthGuardService, RoleGuardService] },
      { path: 'leaveManagement/reporteeDetails', component: CmReporteeDetailComponent, canActivate: [AuthGuardService] },
      {
        path: 'directreportees',
        component: DirectReporteesCMComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'profiledetail/:id',
        component: ProfileDetailComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'evaluationConfig',
        component: EvaluationConfigurationComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'employee/employee-profile/:id',
        component: ProfileEmployeeComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'client_details/:id',
        component: ViewClientComponent,
        canActivate: [AuthGuardService, RoleGuardService],
      },
      {
        path: 'cm-reportees/:id',
        component: CmReporteesComponent,
        canActivate: [AuthGuardService, RoleGuardService],
      },
    ],
  },
  { path: '**', component: LoginComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
