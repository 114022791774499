import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';
import { LeaveBalanceService } from 'src/app/services/leaveBalances/leave-balance.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveApplicationDTO } from 'src/app/dtos/LeaveApplicationDTO';
import { ApprovalStatus, TypeOfLeave } from 'src/app/services/leaveManagment/leave-enum';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apply-holiday',
  templateUrl: './apply-holiday.component.html',
  styleUrls: ['./apply-holiday.component.scss']
})
export class ApplyHolidayComponent implements OnInit {
  public careerManagerEmailId: any;
  public userId: any;
  public currentYear: any;
  leavebalanceData: any;
  restrictedHolidays: any;
  myRemark: any;
  leaveApplicationDTO: LeaveApplicationDTO
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApplyHolidayComponent>,
    public toaster: ToastrService,
    public userService: UserService,
    public leaveBalanceService: LeaveBalanceService,
    public userDetailsService: UserDetailsService,
    public leaveApplicationService: LeaveApplicationService
  ) { }

  ngOnInit() {
    // console.log(this.data.holidayDescription);
    // console.log(this.data.holidayDate);
    this.currentYear = new Date(this.data.holidayDate).getFullYear();

    this.userId = this.userDetailsService.getUserDetails().id;
    this.userService.getCareerManagerEmailId().subscribe(data =>
      this.careerManagerEmailId = data.body.email

    );

    this.getLeaveBalance();
  }

  onSubmit() {
    this.leaveApplicationDTO = new LeaveApplicationDTO()
    this.leaveApplicationDTO.userId = this.userId;
    this.leaveApplicationDTO.typeOfLeave = "RESTRICTED_HOLIDAY"
    this.leaveApplicationDTO.careerManagerId = this.userDetailsService.getUserDetails().careerManagerId
    this.leaveApplicationDTO.approvalStatus = ApprovalStatus.PENDING
    this.leaveApplicationDTO.contactNumber = this.userDetailsService.getUserDetails().contactNumber
    this.leaveApplicationDTO.startDate = this.data.holidayDate
    this.leaveApplicationDTO.endDate = this.data.holidayDate
    this.leaveApplicationDTO.startSession = 1
    this.leaveApplicationDTO.endSession = 2
    this.leaveApplicationDTO.reasonForLeave = this.myRemark
    this.leaveApplicationDTO.transactionDateTime = new Date().getTime();
    this.leaveApplicationService.createRestricted(this.leaveApplicationDTO).subscribe((res) => {
      // console.log(res.body);
      this.toaster.success('Successfully Applied', 'Saved', {
      });
      this.dialogRef.close();
    }, (error) => {
      this.toaster.success('Cannot Apply For this Holiday as 1 Floater is already consumed in First half of the year.', '', {});
      console.log(error)
    });

  }
  getLeaveBalance() {
    this.leaveBalanceService.getLeaveBalanceByIdAndYear(this.userId, this.currentYear).subscribe((res) => {
      console.log(res.body);
      this.leavebalanceData = res.body.balanceLeave

      this.restrictedHolidays = this.leavebalanceData.RESTRICTED_HOLIDAY;
    }, (e) => {

    })
  }


  closeDialog() {
    this.dialogRef.close({ event: 'Closed' });
  }
}
