<div class="container-fluid ">
    <div class="main_header_div">
        <div class="child">
            <label *ngIf="this.data == null" class="header_name">Add New Employee</label>
            <label *ngIf="this.data" class="header_name ">Edit Employee</label>
        </div>
        <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12">
            <div class="upload_img_maindiv">
                <figure>
                    <div class="upload_img_cation_alignment" (click)="fileInput.click()">
                        <img class="upload_img" [(src)]="binaryImgString" *ngIf="photo" />
                        <img class="upload_img" src="assets/blue_altair_logo.png" alt="" *ngIf="!photo">
                        <label class="remove_img_text"
                            *ngIf="this.imageError && !this.photo">{{this.imageError}}</label>
                        <figcaption class="upload_text" *ngIf="!photo">Upload</figcaption>
                        <input type="file" id="my_file" style="display: none;" accept=".jpg,.png"
                            (change)="readURL($event.target.files)" #fileInput />
                    </div>
                    <figcaption class="remove_img_text" *ngIf="photo" (click)="setDefaultImg()">Remove File</figcaption>
                </figure>
            </div>
        </div>
    </div>

    <div class="row form_main_div">
        <div class="col-md-12">

            <form [formGroup]="newEmployeeDetails" (ngSubmit)="onSubmit()">

                <div class="row">
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>First Name </mat-label>
                            <input matInput formControlName="firstName" autocomplete="off"
                                style="text-transform: capitalize" pattern="^[a-zA-Z ]*$">
                            <mat-error *ngIf="checkError('firstName', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('firstName','pattern')">Only alphabets and spaces are
                                allowed</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Last Name</mat-label>
                            <input matInput formControlName="lastName" autocomplete="off"
                                style="text-transform: capitalize" pattern="^[a-zA-Z ]*$">
                            <mat-error *ngIf="checkError('lastName', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('lastName','pattern')">Only alphabets and spaces are
                                allowed</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Employee ID </mat-label>
                            <input matInput formControlName="employeeId" autocomplete="off"
                                style="text-transform:uppercase" (ngModelChange)="onEmployeeIdTextTyped($event)">
                            <mat-error *ngIf="checkError('employeeId', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Email <span class="contentRed"></span></mat-label>
                            <input matInput formControlName="email" autocomplete="off"
                                (ngModelChange)="onEmailTextTyped($event)" required>
                            <!-- <mat-error *ngIf="this.newEmployeeDetails.get('email').errors?.InvalidEmail">
                                {{emailError}}
                            </mat-error> -->
                            <mat-error *ngIf="checkError('email', 'pattern')">Invalid email</mat-error>
                            <mat-error *ngIf="checkError('email', 'required')">Required</mat-error>
                            <mat-error *ngIf="!this.isValidEmail">Email is already in use!</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Mobile Number <span class="contentRed"></span></mat-label>
                            <!-- <input matInput formControlName="mobileNo" autocomplete="off"> -->
                            <ngx-mat-intl-tel-input [preferredCountries]="['in', 'us']" [enablePlaceholder]="true"
                                [enableSearch]="true" name="phone" (countryChanged)="countryChangedEvent($event)"
                                formControlName="mobileNo" #phone (focusout)="focusOutMobileField()"
                                required></ngx-mat-intl-tel-input>
                            <mat-hint>
                                <label>
                                    E.g. : {{phone.selectedCountry.placeHolder}}
                                </label>
                            </mat-hint>
                            <mat-error *ngIf="this.newEmployeeDetails.get('mobileNo').errors?.ZeroLength">
                                Required
                            </mat-error>
                            <mat-error *ngIf="this.newEmployeeDetails.get('mobileNo').errors?.InvalidNumber">
                                Invalid Mobile Number
                            </mat-error>
                            <!-- <mat-error *ngIf="checkError('mobileNo', 'Required')">Required</mat-error> -->
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Capability <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="department">
                                <mat-option *ngFor="let department of this.departments" [value]="department">
                                    {{department}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('department', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Job Title <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="jobTitle">
                                <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle">
                                    {{jobTitle}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('jobTitle', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Gender <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="gender">
                                <mat-option *ngFor="let gender of this.genders" [value]="gender">
                                    {{gender}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('gender', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Talent Buddy</mat-label>
                            <input matInput [matAutocomplete]="talentBuddyAuto" formControlName="talentBuddy" Required
                                style="text-transform: capitalize">
                            <mat-autocomplete #talentBuddyAuto="matAutocomplete" [displayWith]="displayFnCareerManager">
                                <mat-option *ngFor="let talentBuddy of filteredTalentBuddy | async"
                                    [value]="talentBuddy">
                                    <span style="margin-left: 10px;">{{ talentBuddy.firstName + ' ' +
                                        talentBuddy.lastName+' ('+talentBuddy.employeeId+')'
                                        }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="checkError('talentBuddy', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Career Manager</mat-label>
                            <input matInput [matAutocomplete]="careerManagerAuto" formControlName="careerManager"
                                Required style="text-transform: capitalize">
                            <mat-autocomplete #careerManagerAuto="matAutocomplete"
                                [displayWith]="displayFnCareerManager">
                                <mat-option *ngFor="let manager of filteredCareerManager | async" [value]="manager">
                                    <span style="margin-left: 10px;">{{ manager.firstName + ' ' + manager.lastName+' '+'
                                        ('+manager.employeeId+')'
                                        }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="checkError('careerManager', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Date of Joining <span class="contentRed"></span></mat-label>
                            <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy"
                                formControlName="dateOfJoining" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="checkError('dateOfJoining', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Roles</mat-label>
                            <mat-select formControlName="roles" multiple>
                                <mat-option *ngFor="let role of rolesList" [value]="role"
                                    [disabled]="role === 'Role User' || role === 'Role Career Manager'">{{role}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('roles', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Probation Period <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="probationPeriod">
                                <mat-option *ngFor="let probationPeriod of this.probationPeriods"
                                    [value]="probationPeriod" (click)=" onProbationPeriodSelect(probationPeriod)">
                                    {{probationPeriod}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('jobTitle', 'required')">Required</mat-error>
                            <!-- <input matInput formControlName="probationPeriod" autocomplete="off" readonly Required>
                            <mat-error *ngIf="checkError('probationPeriod', 'Required')">Required</mat-error> -->
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Confirmation Date <span class="contentRed"></span></mat-label>
                            <input matInput [matDatepicker]="picker1" placeholder="mm/dd/yyyy"
                                formControlName="confirmationDate">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="checkError('confirmationDate', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Geo Location <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="geoLocation">
                                <mat-option *ngFor="let geoLocation of this.geoLocations" [value]="geoLocation">
                                    {{geoLocation}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('geoLocation', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-2">
                      <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Base Location <span class="contentRed"></span></mat-label>
                        <mat-select formControlName="baseLocation">
                          <mat-option *ngFor="let location of locations" [value]="location">
                            {{ location }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="checkError('baseLocation', 'required')">Required</mat-error>
                      </mat-form-field>
                    </div>
                  
                    <div class="col-md-4 mb-2">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Current Location <span class="contentRed"></span></mat-label>
                            <input matInput formControlName="officeLocation" autocomplete="off"
                                style="text-transform: capitalize" pattern="[A-Za-z]+">
                            <mat-error *ngIf="checkError('officeLocation', 'required')">Required</mat-error>
                            <mat-error *ngIf="checkError('baseLocation', 'pattern')">Only letters are
                                allowed</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="add_Emp_btn" type="submit"> <span
                                    class="add_em_name">{{this.saveBtnLabel}}</span> </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>