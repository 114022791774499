<div class="container-fluid " >
    <div class="main_header_div">
        <div class="child" *ngIf="!this.isOrphanFeedback">
            <h1 class="header_name">Reason for decline</h1>
        </div>
        <div class="child" *ngIf="this.isOrphanFeedback">
            <h1 class="header_name">Reason for cancel</h1>
        </div>

    </div>
    <hr>
    <form [formGroup]="feedbackDecline">
        <div class="descriptionMessage">
            <textarea type="text" formControlName="description" class="notes-box mt-3 ml-5"
                placeholder="Enter your reason"></textarea>
            <mat-error class="errorMessage"
                *ngIf="feedbackDecline.get('description').hasError('required') && feedbackDecline.get('description').touched">
                Reason is required.
            </mat-error>
        </div>

        <div class="save-btn-div">
            <button mat-raised-button class=" close" (click)="closeDialog()">No</button>
            <button mat-raised-button class="save-btn" (click)="save()">Yes</button>
        </div>
    </form>
</div>
<!-- <div class="container-fluid " *ngIf="this.isOrphanFeedback">
    <div class="main_header_div">
        <div class="child">
            <h1 class="header_name">You cannot decline this feedback!</h1>
        </div>

    </div>
    <hr>
    <div class="save-btn-div">
        <button mat-raised-button class=" close" (click)="closeDialog()">Close</button>
    </div>
</div> -->