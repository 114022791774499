<div class="container-fluid">
    <div class="main_header_div">
        <div class="child">
            <h1 *ngIf="!inEditMode" class="header_name">Add New Asset</h1>
            <h1 *ngIf="inEditMode" class="header_name">Manage Asset</h1>
        </div>
        <div class="child_close_btn">
            <button class="closeButton" (click)="closeDialog()">Close</button>
        </div>
    </div>
    <hr>

    <div class="row form_main_div">
        <div class="col-md-12">
            <form [formGroup]="newAssetDetails">
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Asset Category <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="assetCategory">
                                <mat-option *ngFor="let category of this.allAssetCategoriesList" [value]="category">
                                    {{ category }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('assetCategory', 'required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>
                                Asset Name  <span class="contentRed"></span>
                            </mat-label>
                            <input matInput formControlName="assetName" autocomplete="off" />
                            <mat-error *ngIf="checkError('assetName', 'required')">Required</mat-error>
                            <mat-error *ngIf="newAssetDetails.get('assetName').hasError('noOnlySpecialCharacters')">Invalid characters</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Asset ID <span class="contentRed"></span></mat-label>
                            <input matInput formControlName="assetID" autocomplete="off"
                                (ngModelChange)="onAssetIdTextTyped($event)" />
                            <mat-error *ngIf="
                  this.newAssetDetails.get('assetID').errors?.InvalidAssetId
                ">
                                {{ emailError }}
                            </mat-error>
                            <mat-error *ngIf="newAssetDetails.get('assetID').hasError('noOnlySpecialCharacters')">Invalid characters</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Asset Location <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="assetLocation">
                                <mat-option *ngFor="let location of this.allLocationList" [value]="location">
                                    {{ location }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('assetLocation', 'required')">Required</mat-error>
                            <mat-error *ngIf="newAssetDetails.get('assetLocation').hasError('noOnlySpecialCharacters')">Invalid characters</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Asset Condition <span class="contentRed"></span></mat-label>
                            <mat-select formControlName="assetCondition">
                                <mat-option *ngFor="let condition of this.allAssetConditionsList" [value]="condition">
                                    {{ condition }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('assetCondition', 'required')">Required</mat-error>
                            <mat-error *ngIf="newAssetDetails.get('assetCondition').hasError('noOnlySpecialCharacters')">Invalid characters</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Date of Purchase  <span class="contentRed"></span></mat-label>
                            <input matInput [matDatepicker]="picker" (dateChange)="setNewMinAssignDate()"
                                placeholder="mm/dd/yyyy" formControlName="purchasedOn" autocomplete="off" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                             <!-- <mat-error *ngIf="checkError('purchasedOn', 'required')">Required</mat-error>   -->
                             <!-- <mat-error *ngIf="newAssetDetails.get('purchasedOn').hasError('invalidDateField')">Invalid characters</mat-error> -->
                             <mat-error *ngIf="newAssetDetails.get('purchasedOn').errors?.required">Required</mat-error>

                           </mat-form-field>
                           
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Asset Description</mat-label>
                            <textarea matInput formControlName="assetDescription" cols="35" rows="2" autocomplete="off">
                        </textarea>
                            <mat-error *ngIf="checkError('assetDescription', 'required')">Required</mat-error>
                            <mat-error *ngIf="newAssetDetails.get('assetDescription').hasError('noOnlySpecialCharacters')">Invalid characters</mat-error>

                        </mat-form-field>
                    </div>

                </div>

                <!--Uncomment if later Relevant document is also needed-->
                <!-- <div class="row">
                    <div class="col-md-6">
                        <div class="upload_img_maindiv">
                            <figure>
                                <div class="upload_img_cation_alignment" (click)="fileInput.click()">
                                    <img class="upload_img" src="assets/vector3.png" alt="">
                                    <label class="upload_text" *ngIf="!relevantDoc">Attach Relevant Document</label>
                                    <label class="upload_text" *ngIf="relevantDoc">{{relevantDocName}}</label>
                                    <input type="file" id="my_file" style="display: none;" accept=".xlsx, .pdf"
                                        (change)="readRelevantDoc($event.target.files)" #fileInput />
                                </div>
                                <label class="remove_img_text" *ngIf="relevantDoc" (click)="removeRelevantDoc()">
                                    Remove file</label>
                            </figure>
                        </div>
                    </div>
                </div> -->

                <div class="row col-10">
                    <div class="col-2">
                        <div class="rectangle_lines">
                            <label class="rect_text" (click)="fileInputAssetImage1.click()"
                                *ngIf="!assetImage[0]">+</label>
                            <img class="upload_asset_img" [(src)]="binaryAssetImageString[0]" *ngIf="assetImage[0]" />
                            <input type="file" style="display: none;" accept="image/*"
                                (change)="readImage($event.target.files, 0)" #fileInputAssetImage1 />
                        </div>
                        <label class="remove_asset_img" *ngIf="this.assetImageError[0] && !assetImage[0]">
                            {{this.assetImageError[0]}}</label>
                        <label class="remove_asset_img" *ngIf="assetImage[0]"
                            (click)="removeAssetImage(0)">Remove</label>
                    </div>
                    <div class="col-2">
                        <div class="rectangle_lines">
                            <label class="rect_text" (click)="fileInputAssetImage2.click()"
                                *ngIf="!assetImage[1]">+</label>
                            <img class="upload_asset_img" [(src)]="binaryAssetImageString[1]" *ngIf="assetImage[1]" />
                            <input type="file" style="display: none;" accept="image/*"
                                (change)="readImage($event.target.files, 1)" #fileInputAssetImage2 />
                        </div>
                        <label class="remove_asset_img" *ngIf="this.assetImageError[1] && !assetImage[1]">
                            {{this.assetImageError[1]}}</label>
                        <label class="remove_asset_img" *ngIf="assetImage[1]"
                            (click)="removeAssetImage(1)">Remove</label>
                    </div>
                    <div class="col-2">
                        <div class="rectangle_lines">
                            <label class="rect_text" (click)="fileInputAssetImage3.click()"
                                *ngIf="!assetImage[2]">+</label>
                            <img class="upload_asset_img" [(src)]="binaryAssetImageString[2]" *ngIf="assetImage[2]" />
                            <input type="file" style="display: none;" accept="image/*"
                                (change)="readImage($event.target.files, 2)" #fileInputAssetImage3 />
                        </div>
                        <label class="remove_asset_img" *ngIf="this.assetImageError[2] && !assetImage[2]">
                            {{this.assetImageError[2]}}</label>
                        <label class="remove_asset_img" *ngIf="assetImage[2]"
                            (click)="removeAssetImage(2)">Remove</label>
                    </div>
                    <div class="col-2">
                        <div class="rectangle_lines">
                            <label class="rect_text" (click)="fileInputAssetImage4.click()"
                                *ngIf="!assetImage[3]">+</label>
                            <img class="upload_asset_img" [(src)]="binaryAssetImageString[3]" *ngIf="assetImage[3]" />
                            <input type="file" style="display: none;" accept="image/*"
                                (change)="readImage($event.target.files, 3)" #fileInputAssetImage4 />
                        </div>
                        <label class="remove_asset_img" *ngIf="this.assetImageError[3] && !assetImage[3]">
                            {{this.assetImageError[3]}}</label>
                        <label class="remove_asset_img" *ngIf="assetImage[3]"
                            (click)="removeAssetImage(3)">Remove</label>
                    </div>
                    <div class="col-2">
                        <div class="rectangle_lines">
                            <label class="rect_text" (click)="fileInputAssetImage5.click()"
                                *ngIf="!assetImage[4]">+</label>
                            <img class="upload_asset_img" [(src)]="binaryAssetImageString[4]" *ngIf="assetImage[4]" />
                            <input type="file" style="display: none;" accept="image/*"
                                (change)="readImage($event.target.files, 4)" #fileInputAssetImage5 />
                        </div>
                        <label class="remove_asset_img" *ngIf="this.assetImageError[4] && !assetImage[4]">
                            {{this.assetImageError[4]}}</label>
                        <label class="remove_asset_img" *ngIf="assetImage[4]"
                            (click)="removeAssetImage(4)">Remove</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <label class="upload_asset_image_label">Attach Asset Images</label>
                    </div>
                </div>

                <!--Uncomment if assign asset is needed while adding asset only-->
                <!-- <div class="row">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Asset Status <span class="contentRed">*</span></mat-label>
                            <mat-select formControlName="assetStatus" (selectionChange)="changeStatus($event.value)"
                                [disabled]="
                  inEditMode &&
                  this.newAssetDetails.get('assetStatus').value == 'Issued'
                ">
                                <mat-option *ngFor="let status of this.allAssetStatusesList" [value]="status"
                                    [disabled]="status == 'Issued'">
                                    {{ status }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('assetStatus', 'Required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Asset Description</mat-label>
                            <textarea matInput formControlName="assetDescription" cols="35" rows="8" autocomplete="off">
              </textarea>
                            <mat-error *ngIf="checkError('assetDescription', 'Required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div> -->
                <!-- <div class="row">
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Asset Condition <span class="contentRed">*</span></mat-label>
                            <mat-select formControlName="assetCondition">
                                <mat-option *ngFor="let condition of this.allAssetConditionsList" [value]="condition">
                                    {{ condition }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('assetCondition', 'Required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div> -->


                <!-- <div class="row" *ngIf="
            statusNotAvailable ||
            (inEditMode &&
              this.newAssetDetails.get('assetStatus').value == 'Not Available')
          ">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Unavailability Reason</mat-label>
                            <input matInput formControlName="unavailabilityReason" autocomplete="off" />
                        </mat-form-field>
                    </div>
                </div> -->

                <!-- Show block when status is available-->
                <!-- <div class="row" *ngIf="
            statusAvailable ||
            this.newAssetDetails.get('assetStatus').value == 'Available'
          ">
                    <mat-checkbox (change)="changeAssignAssetChecked()">Issue this asset to an employee</mat-checkbox>
                </div>

                <div class="row" *ngIf="statusIssued">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-4 col-sm-12 col-xs-12">
                                <h1 class="add_emp_text mt-2">Asset Issued Details</h1>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="row" *ngIf="assignAssetchecked">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Employee Id <span class="contentRed">*</span></mat-label>
                            <mat-select formControlName="employeeId"
                                (selectionChange)="populateEmployeeName($event.value)">
                                <input (keyup)="onKey($event.target.value)" />
                                <mat-option *ngFor="let empId of this.empIds" [value]="empId">
                                    {{ empId }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('employeeId', 'Required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Employee Name <span class="contentRed">*</span></mat-label>
                            <input matInput formControlName="employeeName" autocomplete="off" readonly />
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Date of Asset Assignment
                                <span class="contentRed">*</span>
                            </mat-label>
                            <input matInput [matDatepicker]="picker" [min]="minAssignDate" placeholder="mm/dd/yyyy"
                                formControlName="assetAssignedDate" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="checkError('assetAssignedDate', 'Required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="submit_button_main">
                            <button class="add_Emp_btn" *ngIf="!inEditMode && !this.showCreatingAsset" (click)="onSubmit()">
                                <span class="add_em_name">Save</span>
                            </button>
                            <button class="add_Emp_btn" *ngIf="!inEditMode && this.showCreatingAsset" disabled>
                                <span class="add_em_name">Creating...</span>
                            </button>
                            <button class="add_Emp_btn" *ngIf="inEditMode && !this.showUpdating" (click)="onSubmit()">
                                <span class="add_em_name">Update</span>
                            </button>
                            <button class="add_Emp_btn" *ngIf="inEditMode && this.showUpdating" disabled>
                                <span class="add_em_name">Updating...</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>