import { Injectable } from '@angular/core';
import { PfdetailsUrlService } from '../restAPI/pfDetailsURLs/pfdetails-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class PfDetailsService {
  constructor(private restApiService: RestAPIService,
    private pfdetailsurl : PfdetailsUrlService
  ) { }
  create(payload) {
    return this.restApiService.post(
      this.pfdetailsurl.getBaseUrl(),
      payload
    )
  }
  update(payload){
    return this.restApiService.put(
      this.pfdetailsurl.getBaseUrl(),
      payload
    )
  }
  getpfByUserIdSubscribe(userId) {
    return this.restApiService.get(
      this.pfdetailsurl.getUserUrl(userId)
    )
  }
  delete(id: any) {
    this.restApiService.delete(
      this.pfdetailsurl.getByFieldUrl(id)
    ).subscribe(res=>{
    
    })
  }
}
