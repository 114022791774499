import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LeaveConfigurationComponent } from '../leave-configuration/leave-configuration.component';
import { LeaveApprovalDTO } from 'src/app/dtos/leaveApprovalDTO';
import { LeaveApplicationsService } from 'src/app/services/cm-leave-applications/leave-applications.service';
import { UserService } from 'src/app/services/user/user.service';
import { LeaveApprovalService } from 'src/app/services/leave-approval-cm/leave-approval.service';
import { CmLeaveRejectComponent } from '../cm-leave-reject/cm-leave-reject.component';
import { ActivatedRoute } from '@angular/router';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { ApprovalStatus } from 'src/app/services/leaveManagment/leave-enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTabGroup } from '@angular/material/tabs';
@Component({
  selector: 'app-cm-reportee-detail',
  templateUrl: './cm-reportee-detail.component.html',
  styleUrls: ['./cm-reportee-detail.component.scss']
})
export class CmReporteeDetailComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  rolesList: string[] = [];
  reportee: any;
  reporteeId: any;
  reporteeCMId: any;
  loadingFlag: number =2;
  searchText: string;
  cmId: any;
  leaveApplicationsList: any = [];
  leaveApplications = new LeaveApprovalDTO();
  page: number = 1;
  reporteeAttendanceTab:boolean = true;
  cmReporteeDetailsTab:boolean = false;

  constructor(
    public router: Router,
    private dialog: MatDialog,
    public userDetailService: UserDetailsService,
    private leaveApplicationsService: LeaveApplicationsService,
    private userservice: UserService,
    private leaveApprovalservice: LeaveApprovalService,
    private activatedRoute: ActivatedRoute,
    public leaveApplicationService: LeaveApplicationService,
    private modalService: NgbModal,
    public leaveapplicationervice: LeaveApplicationService,
  ) { }

  ngOnInit(): void {



    const reporteeParam = this.activatedRoute.snapshot.queryParams['reportee'];
    this.reportee = JSON.parse(reporteeParam);
    console.log(this.reportee)

    this.userservice.getUserByID(this.reportee.reporteeId).subscribe((result) => {
      this.reporteeId = result.body.employeeId;
      this.reporteeCMId=result.body.careerManagerId;  
      this.loadingFlag--;    
    })
    this.isCareerManager();
    this.leaveApplicationsList = [];
    this.cmId = this.userDetailService.getUserDetails().id;
    this.loadingFlag--;
    this.leaveApplicationsService.getCMLeaveApplications().subscribe((res) => {
      let curr = res.body;

      for (let i = 0; i < curr.length; i++) {

        this.userservice.getUserByID(curr[i].userId).subscribe((result) => {
          let days = 'calculating';
          this.leaveapplicationervice.getLeaveTotalCount(curr[i]).subscribe((res) => {
            days = res.body;
            const currLeave = {
              name: result.body.firstName + ' ' + result.body.lastName,
              empId: result.body.employeeId,
              userId: curr[i].userId,
              leaveType: curr[i].typeOfLeave,
              cmId: this.cmId,
              startDate: curr[i].startDate,
              endDate: curr[i].endDate,
              noOfDays: days,
              reason: curr[i].reasonForLeave,
              leaveId: curr[i].id,
              attachment: curr[i].doc_base?atob(curr[i].doc_base).toString():null,
              attachmentType:curr[i].doc_base?this.getDocumentType(atob(curr[i].doc_base).toString()):null,
              approvalStatus: curr[i].approvalStatus,
              dataUrl: null
            };

            if (this.reportee.reporteeId == currLeave.userId) {
              this.leaveApplicationsList.push(currLeave);
            }
            // if (curr[i].doc_base) {
            //   //
            //     this.leaveApplicationsList.push(currLeave);
            // } else {
            //   this.leaveApplicationsList.push(currLeave);
            // }

          });
        });
      }
    }, () => { })

  }

  open(content10,temp) {
    
    this.modalService.open(content10, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          console.log(result);
         },
        (reason) => {
          console.log(reason);
         }
      );
  }

  getDocumentType(temp): string {
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf'];
    for (let type of allowed_types) {
      if (temp.indexOf(type) != -1) {
        return type;
      }
    }
  }

  // backToLeaveManagement() {
  //   this.router.navigate(['leaveManagement'] , { fragment: 'myReportees' });
  // }
  backToLeaveManagement() {
    this.router.navigate(['leaveManagement'], { fragment: 'myReportees' }).then(() => {
      this.tabGroup.selectedIndex = 3;
    });
  }
  

  isCareerManager() {
    this.rolesList = this.userDetailService.getUserDetails().authorities;
    for (let role of this.rolesList) {
      if (role == "ROLE_CAREER_MANAGER") {
        return true;
      }
    }
    return false;
  }

  leaveConfigurationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.panelClass = "leave-configuration";
    dialogConfig.data = { reportee: this.reportee }; // Pass reportee as data to dialog

    const dialogRef = this.dialog.open(LeaveConfigurationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  revokeBox(leave) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.data = { leave };
    const dialogRef = this.dialog.open(CmLeaveRejectComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  approveLeave(leave) {
    this.leaveApplications.approvalStatus = 'APPROVED';
    this.leaveApplications.leaveApplId = leave.leaveId;

    const leaveApprove = {
      leaveApplicationId: leave.leaveId,
      approvalStatus: ApprovalStatus.APPROVED,
      reasonForRejection: ''
    }
    this.leaveApprovalservice.update(leaveApprove).subscribe((res) => {
      this.ngOnInit();

    }, (e) => {
    })
  }

  getCurrentDenominator(): number {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 to get month starting from 1
    return currentMonth < 7 ? 11 : 10;
  }

  openReporteeDetails(){
    this.cmReporteeDetailsTab=true;
    this.reporteeAttendanceTab=false;
  }

  openReporteeAttendance(reporteeId){
    this.reporteeAttendanceTab=true;
    this.cmReporteeDetailsTab=false;
  }

}

