



import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RestAPIService } from '../restAPI/rest-api.service';

import { ExitChecklistUrlsService} from '../restAPI/exitChecklistURLs/exit-checklist-urls.service';
import { identity } from '@fullcalendar/core/internal';
@Injectable({
  providedIn: 'root'
})
export class ExitChecklist {

  constructor(private restApi: RestAPIService,
    private checklistUrl:ExitChecklistUrlsService) { }

    
    createChecklist(payload,id) {
      
      return this.restApi.post(
        this.checklistUrl.getDetails(id),
        payload
      )
    }

    updateChecklist(payload,id) {
      return this.restApi.put(
        this.checklistUrl.updateDetails(id),
        payload
      )
    }
    
   

}
