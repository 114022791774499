import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LeaveApplicationService } from 'src/app/services/leave-application/leave-application.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RevokeLeaveApplicationComponent } from '../revoke-leave-application/revoke-leave-application.component';
import { transition } from '@angular/animations';
import { ApplyNewleaveComponent } from '../apply-newleave/apply-newleave.component';

@Component({
  selector: 'app-leave-details',
  templateUrl: './leave-details.component.html',
  styleUrls: ['./leave-details.component.scss']
})
export class LeaveDetailsComponent implements OnInit {
  p: number;
  LeaveDetailsList = [];
  totalLeaveDaysList: string[] = [];
  totalDays: any;
  searchText: string;
  userid: string;
  years: number[] = [];
  selectedYear: any= new Date().getFullYear();

  constructor(
    public leaveApplicationService: LeaveApplicationService,
    public userDetailService: UserDetailsService,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear + 20; i > currentYear - 20; i--) {
      this.years.push(i);
    }
    this.p = 1;
    this.userid = this.userDetailService.getUserDetails().id;
    this.getAllLeaves();
  }

  getAllLeaves() {
    this.LeaveDetailsList = [];
    this.leaveApplicationService.getAllLeaveApplication().subscribe((res) => {

      for (let r of res.body) {
        let currentLeave: any = r;

        let days = 'calculating';
        this.leaveApplicationService.getLeaveTotalCount(r).subscribe((res) => {
          days = res.body;
          currentLeave.no_ofDays = days;
          console.log(currentLeave)
          if(new Date(currentLeave.startDate).getFullYear() == this.selectedYear){
            this.LeaveDetailsList.push(currentLeave);
          }
        })

      }

    },
      (e) => {
        console.log(e)
      })
  }

  revokeBox(leaveDetail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "50%";
    dialogConfig.data = { leaveDetail };
    const dialogRef = this.dialog.open(RevokeLeaveApplicationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log("success")
      this.getAllLeaves();
    }, () => {
      console.log("not success")

    });
  }

  editLeave(detail) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "90%"
    dialogConfig.panelClass = "apply-leave"
    // Pass the detail as data to the ApplyNewleaveComponent
    dialogConfig.data = { existingLeave: detail };
    const dialogRef = this.dialog.open(ApplyNewleaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getAllLeaves();
    });
  }

  applyNewLeave(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "90%"
    dialogConfig.panelClass = "apply-leave"
    const dialogRef = this.dialog.open(ApplyNewleaveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getAllLeaves();
    });
  }

  isDateYetToPass(date): boolean {
    const currentDate = new Date();
    const approvalDate = new Date(date); // Assuming the approval date is stored in the 'approvalDate' property
  
    return approvalDate > currentDate;
  }

}
