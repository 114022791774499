<div class="row">
    <div class="col-lg-6 col-md-12 col-sm-10">
        <mat-card class="main-card"
            *ngIf="this.pfdto.length !== 0 &&  this.bankdto.length !== 0 && this.pandtoo.length !== 0">
            <div class="header">
                <div>
                    <h2>Account Details</h2>
                </div>
                <div>
                    <mat-icon (click)="editAccountDetailsDialog()"><img class="edit_logo" src="assets/edit.png">
                    </mat-icon>
                </div>
            </div>
            <div class="contentscroll">
                <div class="row">


                    <div class="col-md-4">
                        <div>
                            <span class="address_heder">Account Type</span>
                            <br>
                            <label class="address_name mt-1">{{this.bankdto.accountType | titlecase}}</label>

                        </div>

                    </div>
                    <div class="col-md-4">
                        <span class="address_heder">Bank Name</span>
                        <br>
                        <label class="address_name mt-1">{{this.bankdto.bankName | titlecase}}</label>


                    </div>
                    <div class="col-md-4">
                        <span class="address_heder">UAN - 12-Digit Number</span>
                        <br>
                        <label class="address_name mt-1">{{this.pfdto.uanNumber}}</label>


                    </div>
                </div>
                <div class="row mt-2">


                    <div class="col-md-4">
                        <div>
                            <span class="address_heder">IFSC Code</span>
                            <br>
                            <label class="address_name mt-1">{{this.bankdto.ifsc}}</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                            <span class="address_heder">Account Number </span>
                            <br>
                            <label class="accountnumber mt-1">{{this.bankdto.accountNumer}}</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                            <span class="address_heder">PF Number</span>
                            <br>
                            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                matTooltip="{{this.pfdto.pfNumber}}"
                                class="pfnumber  mt-1">{{this.pfdto.pfNumber}}</label>
                        </div>

                    </div>
                </div>
                <div class="row mt-2">


                    <div class="col-md-4">
                        <span class="address_heder">PAN Card Number</span>
                        <br>
                        <label class="address_name mt-1">{{this.pandtoo.pancardNumber}}</label>
                    </div>
                    <div class="col-md-7 pan_details">

                        <span class="pan"><img src="\assets\pancard.png" alt=""></span>
                        <span class="pan_name pan">
                            PAN Card
                        </span>
                        <!-- <span class="btn-div mt-1"><button class="btn-revoke">Revoke</button></span> -->
                    </div>



                </div>
            </div>


        </mat-card>
        <mat-card class="main-card-1"
            *ngIf="this.pfdto.length == 0 &&  this.bankdto.length == 0 && this.pandtoo.length == 0">
            <div class="header">
                <div>
                    <h2>Account Details </h2>
                </div>
                <div>
                    <mat-icon (click)="editAccountDetailsDialog()"><img class="edit_logo" src="assets/edit.png">
                    </mat-icon>
                </div>

            </div>
            <div class="no_data_div">
                <img class="img" src="assets/no-data-available.png">
                <label class="no_data_label mt-2">No Data Available</label>
            </div>
        </mat-card>
    </div>
    <div class="col-lg-5 col-md-12 col-sm-12">
        <mat-card class="main-card-1" *ngIf="this.assetdto.length !== 0">
            <div class="header">
                <div>
                    <h2>Company Asset Details </h2>
                </div>

            </div>
            <div class="address_mat_body ">
                <div *ngFor="let a of assetdto" class="asset_details mt-2">
                    <img src="data:image/png;base64,{{a.assetPrimaryThumbnail}}" alt="">
                    <div class="asset">
                        <span class="address_heder">{{this.assetcategories[a.assetCategory]}}</span>
                        <br>
                        <label class="address_name mt-1">{{a.assetID}}</label>
                    </div>

                </div>
            </div>
        </mat-card>

        <mat-card class="main-card-1" *ngIf="this.assetdto.length == 0">
            <div class="header">
                <div>
                    <h2>Company Asset Details </h2>
                </div>

            </div>
            <div class="no_data_div">
                <img class="img" src="assets/no-data-available.png">
                <label class="no_data_label mt-2">No Data Available</label>
            </div>
        </mat-card>
    </div>
</div>