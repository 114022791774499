import { GoalType } from "../services/goalSheet/goal-sheet-enum"

interface IUsergoal {
    id?:String
    goalType?:GoalType
    progressValue?:number
    createddate?:Date
    year?:String
    userId?:String
}

export class Usergoal {
    public id:String
    public goalType:GoalType
    public progressValue:number
    public createddate:Date
    public year:String
    public userId:String

    constructor(usergoal:IUsergoal){
        if (usergoal && usergoal.id) {
            this.id = usergoal.id;
          }
          this.goalType = (usergoal && usergoal.goalType) || null;
          this.progressValue = (usergoal && usergoal.progressValue) || 0;
          this.userId = (usergoal && usergoal.userId) || null;
          this.createddate = (usergoal && usergoal.createddate) || null;
          this.year = (usergoal && usergoal.year) || null;
         
    // this.id=undefined
    //     this.goalType=null
    //     this.progressValue=0
    //     this.createddate=null
    //     this.year=null
    //     this.userId=null
    }

    bind(httpResponse:any){
        this.id = httpResponse && httpResponse.id || undefined
        this.goalType = httpResponse && httpResponse.goalType || undefined
        this.progressValue = httpResponse && httpResponse.progressValue || undefined
        this.createddate = httpResponse && new Date(httpResponse.createddate) || undefined
        this.year = httpResponse && httpResponse.year || undefined 
        this.userId = httpResponse && httpResponse.userId || undefined 
        return this;     
    }
}
