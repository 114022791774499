<div class="row">

    <div class="col-lg-7 col-md-12">

        <mat-card class="main-card1 " *ngIf="this.visadto.length !== 0">
            <div class="main_header_div">
                <div>
                    <h2 class="h2">Visas Available</h2>
                </div>
                <div>
                    <mat-icon (click)="editVisaDetails()" class="mr-4 mt-3"><img class="edit_logo"
                            src="assets/edit.png"></mat-icon>
                </div>


            </div>


            <table class="table">
                <thead>
                    <th>Visa Type</th>
                    <th>Country</th>
                    <th>Issue Date</th>
                    <th>Expire Date</th>
                    <th>Visa Status</th>
                </thead>

                <tbody>
                    <tr *ngFor="let v of visadto">
                        <td data-label="VisaType">
                            <label class="content_color1">{{ v.visaType | titlecase }}</label>
                        </td>
                        <td data-label="Country">
                            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                matTooltip="{{ countriesEnum[v.country]}}" class="content_color2">{{
                                countriesEnum[v.country]}}</label>
                        </td>
                        <td data-label="Issue Date">
                            <label class="content_color">{{ v.validFrom | date : 'dd-MM-yyyy'}}</label>
                        </td>
                        <td data-label="Expiration Date">
                            <label class="content_color">{{ v.validTo | date : 'dd-MM-yyyy'}}</label>
                        </td>
                        <td data-label="Visa status">
                            <label *ngIf="visastatus[v.id] === 'Active'"
                                class="status_color1 ">{{this.visastatus[v.id]}}</label>
                            <label *ngIf="visastatus[v.id] === 'Inactive'"
                                class="content_color ">{{this.visastatus[v.id]}}</label>

                        </td>
                    </tr>
                </tbody>

            </table>







        </mat-card>

        <mat-card class="no_data-card" *ngIf="this.visadto.length == 0">

            <div class="main_header_div">
                <div>
                    <h2 class="h2">Visas Available</h2>
                </div>
                <div>
                    <mat-icon (click)="editVisaDetails()" class="mr-3"><img class="edit_logo" src="assets/edit.png">
                    </mat-icon>
                </div>


            </div>
            <div class="no_data_div">
                <img class="img" src="assets/no-data-available.png">
                <label class="no_data_label mt-2">No Data Available</label>
            </div>

        </mat-card>
    </div>

    <div class="col-lg-5 ">
        <mat-card class="main-card" *ngIf="this.passportdto.length !== 0">
            <div class="header">
                <div>
                    <h2>Passport Details</h2>
                </div>
                <div>
                    <mat-icon (click)="editPassportDetailsDialog()"><img class="edit_logo" src="assets/edit.png">
                    </mat-icon>
                </div>
            </div>
            <div class="passportfixed">
                <div *ngFor="let p of passportdto">
                    <div class="address_mat_body mt-3">

                        <div>
                            <span class="address_heder">Passport Number</span>
                            <br>
                            <label class="address_name mt-1">{{p.passportNumber}}</label>
                        </div>
                        <div class="mr-2">
                            <span class="address_heder">Expire Date
                            </span>
                            <br>
                            <label class="address_name1 mt-1">{{p.expiryDate | date: 'dd-MM-yyyy'}}</label>
                        </div>
                    </div>
                    <div class="address_mat_body mt-2">

                        <div>
                            <span class="address_heder">Country </span>
                            <br>
                            <label class="address_name mt-1">{{countriesEnum[p.country] | titlecase}}</label>
                        </div>
                        <div class="mr-2">
                            <span class="address_heder">Passport Status</span>
                            <br>
                            <label *ngIf="passportstatus[p.id] === 'Active'"
                                class="status_color mt-1">{{this.passportstatus[p.id]}}</label>

                            <label *ngIf="passportstatus[p.id] === 'Inactive'"
                                class="status_color2 ">{{this.passportstatus[p.id]}}</label>
                        </div>
                    </div>


                    <!-- <div class="address_mat_body mt-2">

                        <div class="pan_details">
                            <span class="pan"><img src="\assets\pancard.png" alt=""></span>
                            <span class="pan_name pan">
                                Passport
                            </span>
                            <span class="btn-div mt-1"><button class="btn-revoke">Revoke</button></span>
                        </div>
                        <div></div>
                    </div> -->
                </div>
            </div>


        </mat-card>
        <mat-card class="main-card" *ngIf="this.passportdto.length == 0">
            <div class="header">
                <div>
                    <h2>Passport Details</h2>
                </div>
                <div>
                    <mat-icon (click)="editPassportDetailsDialog()"><img class="edit_logo" src="assets/edit.png">
                    </mat-icon>
                </div>
            </div>
            <div class="no_data_div">
                <img class="img" src="assets/no-data-available.png">
                <label class="no_data_label mt-2">No Data Available</label>
            </div>

        </mat-card>

    </div>
</div>
<div class="row">

    <div class="col-lg-9">

        <mat-card class="main-card1" *ngIf="this.travelhistorydto.length !== 0">
            <div class="main_header_div">
                <div>
                    <h2 class="h2">Travel History With BlueAltair</h2>
                </div>
                <div>
                    <mat-icon (click)="editBAtravelDialog()" class="mr-4 mt-3"><img class="edit_logo"
                            src="assets/edit.png"></mat-icon>
                </div>


            </div>


            <table class="table">
                <thead>
                    <th>From Country</th>
                    <th>To Country</th>
                    <th>Travel Date</th>
                    <th>Reason of Travel</th>
                    <th>Additional Info</th>


                </thead>

                <tbody>
                    <tr *ngFor="let t of travelhistorydto">
                        <td data-label="From Country">
                            <!-- <label class="content_color3">{{ countriesEnum[t.fromCounry] }}</label> -->
                            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                matTooltip="{{ countriesEnum[t.fromCounry]}}" class="content_color3">{{
                                countriesEnum[t.fromCounry]}}</label>
                        </td>
                        <td data-label="To Country">
                            <!-- <label class="content_color4">{{ countriesEnum[t.toCountry]}}</label> -->
                            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                matTooltip="{{ countriesEnum[t.toCountry]}}" class="content_color4">{{
                                countriesEnum[t.toCountry]}}</label>
                        </td>
                        <td data-label="Travel Date">
                            <label class="content_color">{{ t.travelDate | date : 'dd-MM-yyyy'}}</label>
                        </td>
                        <td data-label="Reason Of Travel">
                            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                matTooltip="{{ t.reasonOfTravel}}" class="content_color5">{{ t.reasonOfTravel}}</label>
                        </td>
                        <td data-label="Additional Info">
                            <label [matTooltipPosition]="position.value" [matTooltipClass]="position.value"
                                matTooltip="{{t.additionalInfo}}" class="content_color6">{{t.additionalInfo}}</label>
                        </td>
                    </tr>
                </tbody>

            </table>


        </mat-card>
        <mat-card class="no_data-card" *ngIf="this.travelhistorydto.length == 0">
            <div class="main_header_div">
                <div>
                    <h2 class="h2">Travel History With BlueAltair</h2>
                </div>
                <div>
                    <mat-icon (click)="editBAtravelDialog()" class="mr-4 mt-3"><img class="edit_logo"
                            src="assets/edit.png"></mat-icon>
                </div>


            </div>
            <div class="no_data_div">
                <img class="img" src="assets/no-data-available.png">
                <label class="no_data_label mt-2">No Data Available</label>
            </div>
        </mat-card>
    </div>
</div>