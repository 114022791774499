<ng-container *ngIf="this.nodata == true; else elseTemplate">
    <div class="nothing-data">
        <img class="no-reportee-img" src="assets/no-data-project.png">
    </div>
</ng-container>
<ng-template #elseTemplate>


    <div class="row">
        <div class="col-md-12 pl-0">
            <mat-card class="mat_card">
                <div class="search_main ">
                    <mat-icon class="search-icon  mt-1 mr-4">search</mat-icon>

                    <input class="search_input" type="search" placeholder="Search" [(ngModel)]="searchText" value="">

                </div>
                <div class="col show">
                    <label>
                        <button mat-icon-button matSuffix type="button" (click)="this.hide = !this.hide"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                            class="">{{hide? "Show Scores": "Hide Scores"}}
                            <mat-icon style="color: #999999" Pass through>{{ hide ? "visibility_off" : "visibility"
                                }}
                            </mat-icon>
                        </button>
                    </label>
                </div>
                <div class="year-div">
                    <div class="reportee-common ">
                        Year
                    </div>
                    <div>
                        <mat-select class="year" [(value)]="selected" (selectionChange)="loadForm($event)">
                            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                        </mat-select>
                    </div>

                </div>
            </mat-card>
        </div>
    </div>
    <div *ngIf="this.mobileview==false">
        <div class="card-container ">
            <div
                *ngFor="let directreportee of this.userdto | filter:searchText  | paginate: { id:'direct-reportee-id1', itemsPerPage: 8, currentPage: p } ">
                <div class="matcardprojectdetail" 
                    (click)="onViewReportee(directreportee.userId)">

                    <div class="" style="display: flex;">
                        <div>
                            <img src="assets/default-avatar.jpg" class="img-container">
                        </div>
                        <div class="name-div">
                            <div class="emp-name">
                                <label>
                                    {{directreportee.firstName }}
                                    {{directreportee.lastName}}
                                </label>
                            </div>
                            <div class="emp-id">
                                <label>

                                    ID {{directreportee.employeeId}}
                                </label>
                            </div>
                        </div>

                    </div>

                    <div class=" coloums-div color">
                        <div class="col-3 project-name">
                            Score
                        </div>

                        <div *ngIf="this.hide==!true" class="col-3 count">
                            {{directreportee.totalScore}}/5
                        </div>
                        <div *ngIf="this.hide==true" class="col-3 hide">
                            ●●●
                        </div>
                    </div>
                    <div class=" coloums-div ">
                        <div class="col project-name">
                            Feedback Count
                        </div>

                        <div *ngIf="this.hide==!true" class="col-3 count ">
                            {{directreportee.feedbackForms}}

                        </div>
                        <div *ngIf="this.hide==true" class="col-3 hide">
                            ●●●
                        </div>
                    </div>
                    <div class="coloums-div color">
                        <div class="col-3  project-name">
                            <!-- Feedback  -->
                            Status
                        </div>

                        <div class="col-3 ">
                            <div *ngIf="directreportee.closed!=true" class="count green">Open</div>
                            <div *ngIf="directreportee.closed==true" class="count red">Closed</div>
                        </div>
                    </div>
                    <!-- <div class="coloums-div ">
                        <div class="col-3  project-name">
                            Probation Status
                        </div>
                        <div class="col-3 ">
                            <div *ngIf="directreportee.closed!=true" class="count green">Open</div>
                            <div *ngIf="directreportee.closed==true" class="count red">Closed</div>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
        <pagination-controls id="direct-reportee-id1" class="pagination" (pageChange)="p = $event"></pagination-controls>
    </div>
    <div *ngIf="this.mobileview==true" class="card-container">


        <mat-card class="matcardprojectdetail" (click)="onViewReportee(directreportee.userId)"
           
            *ngFor="let directreportee of this.userdto | filter:searchText  | paginate: { id: 'direct-reportee-id' ,itemsPerPage: 4, currentPage: p }  ">
            <mat-card-content>
                <div class="row mt-3">
                    <div class="col">
                        <img src="assets/default-avatar.jpg" class="img-container">
                    </div>
                    <div class="col">
                        {{directreportee.firstName }}
                        {{directreportee.lastName}}
                    </div>
                </div>
                <div class="row mt-3 ">
                    <div class="col">
                        <label class="project-name">Score </label>
                    </div>
                    <div class="col">
                        <p> {{directreportee.totalScore}}/5</p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <label class="project-name">Feedback Count </label>
                    </div>
                    <div class="col">
                        <p>{{directreportee.feedbackForms}}</p>
                    </div>
                </div>
                <div class="row mt-3 ">
                    <div class="col">
                        <label class="project-name">Status </label>
                    </div>
                    <div class="col">
                        <div *ngIf="directreportee.closed==false" class="count green">Open</div>
                        <div *ngIf="directreportee.closed==true" class="count red">Closed</div>
                    </div>
                </div>


            </mat-card-content>
        </mat-card>
        <pagination-controls id="direct-reportee-id" class="pagination" (pageChange)="p = $event"></pagination-controls>

    </div>



</ng-template>