<div class="row page-row">

  <div>
    <!-- <mat-card class="calendar-card">
            <full-calendar class=" full-calendar" [options]="calendarOptions"></full-calendar>
        </mat-card> -->


    <mat-card class="calendar-card">
      <div class="month-year-div">
        <div class="month-select">
          <mat-select class="year-month-select" [(ngModel)]="selectedMonth" (ngModelChange)="onMonthYearChange()">
            <mat-option *ngFor="let month of months" [value]="month">
              {{month}}
            </mat-option>
          </mat-select>
        </div>

        <div class="year-select">
          <mat-select class="year-month-select" [(ngModel)]="selectedYear" (ngModelChange)="onMonthYearChange()">
            <mat-option *ngFor="let year of years" [value]="year">
              {{year}}
            </mat-option>
          </mat-select>
        </div>

      </div>
      <full-calendar class=" full-calendar" #fullCalendar [options]="calendarOptions"></full-calendar>
    </mat-card>


  </div>
  <div class="color-legend">
    <div class="colour-main-div">
      <div class="general colour-div"></div>

      <div class="colour-div-label">
        <label>General Holiday</label>
      </div>
    </div>

    <div class="colour-main-div">
      <div class="Restricted colour-div"></div>

      <div class="colour-div-label">
        <label>Restricted Holiday</label>
      </div>
    </div>

    <!-- <div class="colour-main-div">
            <div class="Unmarked colour-div"></div>

            <div class="colour-div-label">
                <label>Unmarked Attendants</label>
            </div>
        </div>

        <div class="colour-main-div">
            <div class="Leave colour-div"></div>

            <div class="colour-div-label">
                <label>On Leave</label>
            </div>
        </div> -->

    <div class="colour-main-div">
      <div class="Client colour-div"></div>

      <div class="colour-div-label">
        <label>Client Holiday</label>
      </div>
    </div>
  </div>
  <div class="col-md-5 flex-col">

    <div class="containerOne">
      <div class="i-btn-container">
        <div>
          <h2>Leave Balances</h2>
        </div>
        <div class="i-btn">
          <button class="i-button" title="Leave Policy" (click)="openLeavePolicy()">i</button>
        </div>
      </div>
      <div class="year-div-calendar">
        <div>
          <mat-label>Year</mat-label>
          <mat-select class="year" [(ngModel)]="leaveBalanceYear" (ngModelChange)="onLeaveBalanceChange()">
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="leaveBalanceBoxes">
      <div class="col-md-4 leave-balance-bg" *ngIf="this.paidLeave">
        <div class="flex-container">
          <div class="flex-item">
            <div class="row">
              <div class="leave-type-font">Paid</div>
            </div>
          </div>
          <div class="flex-item balance-div">
            <div class="row">
              <div class="col-md-12">{{this.paidLeave | number:'1.2-2'}}/ 21.00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 leave-balance-bg" *ngIf="this.unplannedLeave">
        <div class="flex-container">
          <div class="flex-item">
            <div class="row">
              <div class="leave-type-font">Unplanned</div>
            </div>
          </div>
          <div class="flex-item balance-div">
            <div class="row">
              <div class="col-md-12">{{this.unplannedLeave | number:'1.2-2'}}/7.00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 leave-balance-bg" *ngIf="this.contigencyLeave">
        <div class="flex-container">
          <div class="flex-item">
            <div class="row">
              <div class="leave-type-font">Contingency</div>
            </div>
          </div>
          <div class="flex-item balance-div">
            <div class="row">
              <div class="col-md-12">{{this.contigencyLeave | number:'1.2-2'}}/5.00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 leave-balance-bg" *ngIf="this.compOff">
        <div class="flex-container">
          <div class="flex-item">
            <div class="row">
              <div class="leave-type-font">Comp-off</div>
            </div>
          </div>
          <div class="flex-item balance-div">
            <div class="row">
              <div class="col-md-12">{{this.compOff | number:'1.2-2'}}/{{this.compOff | number:'1.2-2'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 leave-balance-bg" *ngIf="this.maternityLeave">
        <div class="flex-container">
          <div class="flex-item">
            <div class="row">
              <div class="leave-type-font">Maternity</div>
            </div>
          </div>
          <div class="flex-item balance-div">
            <div class="row">
              <div class="col-md-12">{{this.maternityLeave | number:'1.2-2'}}/180.00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 leave-balance-bg" *ngIf="this.paternityLeave">
        <div class="flex-container">
          <div class="flex-item">
            <div class="row">
              <div class="leave-type-font">Paternity</div>
            </div>
          </div>
          <div class="flex-item balance-div">
            <div class="row">
              <div class="col-md-12">{{this.paternityLeave | number:'1.2-2'}}/10.00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 leave-balance-bg" *ngIf="this.restrictedHolidaysLeaves">
        <div class="flex-container">
          <div class="flex-item">
            <div class="row">
              <div class="leave-type-font">Restricted</div>
            </div>
          </div>
          <div class="flex-item balance-div">
            <div class="row">
              <div class="col-md-12">{{this.restrictedHolidaysLeaves | number:'1.2-2'}}/1.00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 leave-balance-bg" *ngIf="this.specialLeave">
        <div class="flex-container">
          <div class="flex-item">
            <div class="row">
              <div class="leave-type-font">Special</div>
            </div>
          </div>
          <div class="flex-item balance-div">
            <div class="row">
              <div class="col-md-12">{{this.specialLeave | number:'1.2-2'}}/3.00</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="container-3 ">
      <h2>{{selectedMonth}} - {{selectedYear}}</h2>
      <hr>
      <div *ngIf="restrictedHolidays.length==0" class="heading heading-1">
        No Restricted Holidays Available
      </div>
      <div *ngFor="let holiday of restrictedHolidays">

        <div class="flex">
          <div class="heading heading-1">
            {{holiday.desc}} {{holiday.date}}
          </div>
          <div class="blue">
            <button class="applyLeaveBtn-dark" *ngIf="holiday.status">Applied</button>
          </div>
          <div class="blue" *ngIf="!holiday.status">
            <button class="applyLeaveBtn"
              (click)="applyRestrictedHoliday(holiday.desc, holiday.originalDate)">Apply</button>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</div>