<div class="main_header_div">
  <h3 mat-dialog-title>Reportees On Leave</h3>
  <div class="child_close_btn">
    <button class="closeButton" (click)="closeDialog()"><mat-icon>close</mat-icon></button>
  </div>
</div>
<hr />


<table class="table">
  <thead>
    <tr>
      <th>Date</th>
      <th>Name</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of allOnLeaveData">
      <td>{{data.date}}</td>
      <td>{{data.name}}</td>
    </tr>
  </tbody>
</table>

<div class="submit_button_main ">
  <!-- <button class="add_Emp_btn" type="submit" (click)="closeDialog()">
    <span>Ok</span>
  </button> -->
</div>