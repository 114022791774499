
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { BlueAltairTravel } from 'src/app/models/bluealtairtravel';
import { User } from 'src/app/models/user';
import { BluealtairtravelService } from 'src/app/services/blue-altair-travel/bluealtairtravel.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { Countries,} from 'src/app/services/country/country-enum';
@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.scss']
})
export class TravelComponent implements OnInit {
  editBusinesstravel2: FormGroup;
  userDto = new UserDTO();
  travelBlueAltairArray: FormArray;
  removebluealtairtravel = [];
  today;
  countries=[];
  constructor(
    public dialogRef: MatDialogRef<TravelComponent>,
    public userDetailService: UserDetailsService,
    public bluealtairtravelservice: BluealtairtravelService,
    public fb: FormBuilder,
    public toaster:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { this.editBusinesstravel2 = this.fb.group({
    bluealtairarray: this.fb.array([], [Validators.required]),
  });}

  ngOnInit(): void {
    //this.data contains userid
    // this.userDto = this.userDetailService.getUserDetails();
    this.today = new Date();
    this.countries=Object.values(Countries);
    this.bluealtairtravelservice
    .gettravelByUserIdSubscribe(this.data)
    .subscribe((res) => {
      if (res.body[0] == null) {
        this.addbluealtairtravel();
      }
      this.setBluealtairtravelFormArray(res.body);
    });
  }

  createbluealtairtravel() {
    let formGroup = this.fb.group(new BlueAltairTravel());
    formGroup.controls.toCountry.setValidators([Validators.required]);
    formGroup.controls.fromCounry.setValidators([Validators.required]);
   
    formGroup.controls.travelDate.setValidators([Validators.required]);
    formGroup.controls.reasonOfTravel.setValidators([Validators.required]);
    return formGroup;
  }

  setBluealtairtravelFormArray(response) {
    this.travelBlueAltairArray = this.editBusinesstravel2.get(
      'bluealtairarray'
    ) as FormArray;
    let travelList: BlueAltairTravel[] =
      this.bluealtairtravelservice.settravelResponse(response);
    for (let s of travelList) {
      let v: BlueAltairTravel = new BlueAltairTravel().bind(s);
      let travelGroup = this.createbluealtairtravel();
      travelGroup.controls.toCountry.setValue(Countries[v.toCountry]);
      travelGroup.controls.fromCounry.setValue(Countries[v.fromCounry]);
      travelGroup.controls.additionalInfo.setValue(v.additionalInfo);
      travelGroup.controls.travelDate.setValue(v.travelDate);
      travelGroup.controls.reasonOfTravel.setValue(v.reasonOfTravel);

      travelGroup.controls.id.setValue(v.id);
      this.travelBlueAltairArray.insert(0, travelGroup);
    }
  }
  BlueAltairTravel(): FormArray {
    return this.editBusinesstravel2.get('bluealtairarray') as FormArray;
  }
  addbluealtairtravel() {
    this.travelBlueAltairArray = this.editBusinesstravel2.get(
      'bluealtairarray'
    ) as FormArray;
    this.travelBlueAltairArray.insert(0, this.createbluealtairtravel());
  }
  removebluealtair(id, i: number) {
    this.removebluealtairtravel.push(id);
    this.BlueAltairTravel().removeAt(i);
  }
  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  closeDialog(event:Event) {
    this.dialogRef.close({ event: 'Closed'})
  }

  checkError = (controlName: string, errorName: string) => {
    // console.log(this.travelBlueAltairArray.controls[controlName])
    // return this.travelBlueAltairArray.controls[controlName].hasError(errorName)
    return true;
  }

  onSubmit()
  {
    if (this.editBusinesstravel2.valid) {
      for (let travel of this.editBusinesstravel2.value.bluealtairarray) {
        travel.toCountry = this.getEnumKeyByEnumValue(Countries,travel.toCountry);
        travel.fromCounry = this.getEnumKeyByEnumValue(Countries,travel.fromCounry);
        if (
          travel &&
          !travel.id &&
          travel.fromCounry != '' &&
          travel.toCountry != '' &&
          travel.reasonOfTravel != '' &&
          // travel.additionalInfo != '' &&
          travel.travelDate != undefined
        ) {
          travel.userId = this.data;
          this.bluealtairtravelservice.create(travel).subscribe((res) => {
            // this.notificationService.sucess("done")
            this.toaster.success("Successfully Saved")
            this.dialogRef.close({ event: 'Closed'})
          });
        } else if (
          travel &&
          travel.id &&
          travel.fromCounry != '' &&
          travel.toCountry != '' &&
          travel.reasonOfTravel != '' &&
          // travel.additionalInfo != '' &&
          travel.travelDate != undefined
        )
          travel.userId = this.data;
        this.bluealtairtravelservice.update(travel).subscribe((res) => {
          //this.notificationService.sucess("done")
          this.toaster.success("Successfully Saved")
          this.dialogRef.close({ event: 'Closed'})
        });
      }
    }
    for (let i in this.removebluealtairtravel) {
      this.bluealtairtravelservice.delete(this.removebluealtairtravel[i]);
    }
  }
}

